// @ts-nocheck

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { GlobalWindow, Nullable, unixTimer$ } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { CashierDataService } from '@portal/cashier/data';
import { CashierRequestStatus } from '@portal/cashier/types/enums/cashier-request-status.enum';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { OctopayInfo } from '@portal/cashier/types/interfaces/octopay-info.interface';
import { UserQuery } from '@portal/user';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pd-octopay-deposit-block',
  templateUrl: './octopay-deposit-block.component.html',
  styleUrls: [ './octopay-deposit-block.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OctopayDepositBlockComponent implements OnChanges, OnDestroy {
  private checkPaymentStatus$: Subscription;
  readonly currency$: Observable<string>;

  @Input() octopayInfo: Nullable<OctopayInfo>;
  timer: string;

  constructor(
    private readonly navigationService: SpNavigation,
    private readonly userInfoQuery: UserQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly cd: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: GlobalWindow
  ) {
    this.currency$ = this.userInfoQuery.currency$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.octopayInfo.currentValue.expireTime) {
      unixTimer$(changes.octopayInfo.currentValue.expireTime).subscribe(timer => {
        if (timer.expired) {
          this.navigationService.navigate('/cashier/deposit');
        }
        this.timer = timer.time.format('mm:ss');
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    !!this.checkPaymentStatus$ && this.checkPaymentStatus$.unsubscribe();
  }

  checkTransfer(): void {
    this.checkPaymentStatus$ = this.cashierCommands.checkPaymentStatus(this.octopayInfo.orderID).subscribe(
      (statusRes) => {
        if (this.window && statusRes.data.url) {
          this.window.location.href = statusRes.data.url;
        } else if (statusRes.status === CashierRequestStatus.Pending && this.window) {
          this.navigationService.navigate([ 'cashier', CashierSections.Deposit, 'transaction', CashierRequestStatus.Going ], {
            invoice: statusRes.data.initiatorId
          });
        } else if (statusRes.status === CashierRequestStatus.Error && this.window) {
          this.navigationService.navigate([ 'cashier', CashierSections.Deposit, 'transaction', CashierRequestStatus.Error ]);
        } else if (statusRes.status === CashierRequestStatus.Success && this.window) {
          this.navigationService.navigate([ 'cashier', CashierSections.Deposit, 'transaction', CashierRequestStatus.Success ]);
        }
      }
    );
  }

  backPage(): void {
    this.navigationService.back([ 'cashier', 'deposit' ]);
  }
}
