import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpLocalization, SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { BonusAction } from '@portal/bonuses/shared';
import { ConfigQuery } from '@portal/config';

import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'gg-bonuses-welcome-container',
  templateUrl: './welcome-container.component.html',
  styleUrls: [ './welcome-container.component.scss', 'purple.welcome-container.scss', 'silver.welcome-container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeContainerComponent {
  private readonly navigation = inject(SpNavigation);
  private readonly bonusesData = inject(BonusesData);
  private readonly isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(true);

  readonly theme = inject(ConfigQuery).project.name || '';
  readonly language = inject(SpLocalization).currentLanguage;
  readonly isExpanded$ = this.isExpanded.asObservable();
  readonly container$: Observable<Nullable<Bonus>> = inject(BonusesQuery).welcomeContainer$;

  activate(container: Bonus, event: BonusAction): void {
    if (event === 'activate') {
      this.bonusesData.selectBonus(container, true);
    } else if (event === 'expire') {
      this.bonusesData.synchronize();
    }
  }

  showText(): void { this.isExpanded.next(!this.isExpanded.getValue()); }
}
