import { Nullable } from '@libs/utils';
import { PaymentContainerDto, PaymentGroup, PaymentGroupDto, PaymentMethod, PaymentMethodDto } from '@portal/payment/data';
import { PaymentContainer } from '@portal/payment/data/entities/payment-container';
import { FeeOption } from '@portal/payment/shared';

const createRule = (rules: Nullable<Array<FeeOption>>): Nullable<Array<FeeOption>> => {
  return rules?.filter((rule) => !!rule.left && rule.left > 0)
    .map((rule) => ({ ...rule, rate: Number(rule.rate) * 100 }));
};

function createPaymentMethod(dto: PaymentMethodDto): PaymentMethod {
  const limits = dto.limits ? dto.limits : null;
  const min = limits?.min ?? null;
  const max = limits?.max ?? null;
  const { id, fee, paymentData: payment, form: additional } = dto;
  const { maxWithdrawalAmount, maxInputAmount, cvv, rounding, cryptoFields } = dto.additionalFields;
  const { logoPrimary, logoSecondary, customName, infoBannerKey } = dto;
  const checkedLimits = { min: Number(min), max: Number(max), inputMax: Number(maxInputAmount) };
  const commission = { rules: createRule(fee?.rules), current: Number(fee?.now?.rate) * 100 };
  const logo = { primary: logoPrimary, secondary: logoSecondary };

  return new PaymentMethod(
    id.toString(),
    { fee, rounding, limits: checkedLimits, maxWithdrawal: Number(maxWithdrawalAmount) },
    { payment, additional, wallet: cryptoFields, cvv: Boolean(cvv) },
    { limits, logo, name: customName, banner: infoBannerKey, commission },
  );
}

export const createPaymentGroup = (group: PaymentGroupDto): PaymentGroup => {
  const { translationKey, iconUrl, paymentMethods, styleType, groupId, maxInputAmount, limits: { min, max } } = group;
  const limits = { min: Number(min), max: Number(max), inputMax: Number(maxInputAmount) };

  return new PaymentGroup(styleType, groupId, iconUrl, translationKey, limits, paymentMethods.map(createPaymentMethod));
};

export const createPaymentContainer = (superGroup: PaymentContainerDto): PaymentContainer => {
  const { translationKey, iconUrl, superGroupId, groups, maxInputAmount, limits: { min, max } } = superGroup;
  const limits = { min: Number(min), max: Number(max), inputMax: Number(maxInputAmount) };

  return new PaymentContainer(superGroupId, iconUrl, translationKey, limits, groups.map(createPaymentGroup));
};
