<ng-container *spLet="isBankCard$ | spAsync as isBankCard">
  <section class="status-error">
    <img class="status-error__icon" alt="status-icon" src="./assets/images/cashier/statuses/{{isBankCard ? 'error-by-bank-card' : 'error'}}.svg"/>
    <ng-container *ngIf="isBankCard else default">
      <span class="status-error__text" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_TITLE" spaces="end"></span>
      <span class="status-error__text status-error__middle-text" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_QUESTION" spaces="end"></span>
      <a [href]="cardPdf" class="status-error__support-link" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_HOW_TO_GET_NEW_CARD" target="_blank"></a>
    </ng-container>
    <ng-template #default>
      <div class="status-error__info">
        <span class="status-error__text" spMsg="CASHIER.STATUS.ERROR" spaces="end"></span>
      </div>
      <div class="status-error__support">
        <sp-msg spMsg="CASHIER.STATUS.ERROR_AGAIN" spaces="end"/>
        <a class="status-error__support-link" href="mailto:{{ supportEmail }}" spMsg="CASHIER.STATUS.SUPPORT_LINK"></a>.
      </div>
      <gg-button (click)="retry()" [settings]="retryButtonSettings"><sp-msg spMsg="CASHIER.STATUS.RETRY"/></gg-button>
    </ng-template>
  </section>
</ng-container>
