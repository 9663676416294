import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SpCDKModule, SpNotification } from '@libs/cdk';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentTransfersData } from '@portal/payment/data';
import { PaymentService } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { IButton } from '@portal/shared/components/controls';
import { validationRules } from '@portal/shared/constants';
import { ErrorManager } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import { BehaviorSubject, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, CurrenciesModule ],
  templateUrl: './payment-transfer.component.html',
  styleUrl: './payment-transfer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ErrorManager ],
})
export class PaymentTransferComponent implements OnInit {
  private readonly userQuery = inject(UserQuery);
  private readonly notification = inject(SpNotification);
  private readonly transfersData = inject(PaymentTransfersData);
  private readonly isTransferSent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly paymentService = inject(PaymentService);

  readonly isTransferSent$ = this.isTransferSent.asObservable();
  readonly currency$ = this.userQuery.currency$;

  readonly button: IButton = { loading: false };

  form = this.createTransferForm();

  readonly errors = inject(ErrorManager).setUp({ form: this.form });

  ngOnInit(): void {
    this.paymentService.selfBlockedSection({ section: 'transfers' });
  }

  back(): void { this.isTransferSent.next(false); }

  makeTransfer(): void {
    if (this.form.invalid) { return; }
    this.button.loading = true;

    this.transfersData.createTransfer(this.form.value).pipe(
      tap({
        next: () => this.form = this.createTransferForm(),
        error: (errors) => this.errors.setServerErrors(errors),
        finalize: () => this.button.loading = false,
      }),
    ).subscribe({
      next: () => this.isTransferSent.next(true),
      error: () => this.notification.error('NOTIFICATIONS.MESSAGES.CASHIER_TRANSFER_FAIL'),
    });
  }

  private createTransferForm(): FormGroup<{ nick: FormControl<string>; amount: FormControl<string> }> {
    return new FormGroup({
      nick: new FormControl<string>('', { nonNullable: true, validators: validationRules.nick }),
      amount: new FormControl<string>('', { nonNullable: true, validators: validationRules.amount }),
    });
  }
}
