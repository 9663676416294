import { Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';

export const USER_CARD_INFO = {
  account: [ '', [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(19),
    CustomValidators.amount('intAndAsterisk')
  ] ],
  expM: [ '', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(2),
    CustomValidators.amount('int'),
    CustomValidators.expM
  ] ],
  expY: [ '', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(2),
    CustomValidators.amount('int'),
    CustomValidators.expY
  ] ],
  cvv: [ '', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(3),
    CustomValidators.amount('int')
  ] ]
};
