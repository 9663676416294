<section class="verification">
  <div class="verification__wrapper">
    <h4 class="verification__text" spMsg="CASHIER.VERIFICATION.END_VERIFICATION"></h4>
    <div class="verification__checklist" *ngIf="verificationState$ | async as verification">
      <div class="verification__number">
        <span class="verification__green">{{ verification.stat.count }}</span> / {{ verification.stat.of }}
        <span class="verification__ended" spMsg="CASHIER.VERIFICATION.ENDED"></span>
      </div>
      <div class="verification__options">
        <div class="verification__option">
          <div class="verification__option-title" [class.verification__option-title_complete]="verification.email.confirmed">
            <sp-msg msg="PROFILE.CONTACT_DETAILS.EMAIL"/>
          </div>
          <ng-container *ngIf="!verification.email.confirmed">
            <gg-button *ngIf="!isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button">
              <sp-msg msg="CASHIER.VERIFICATION.GO_TO_VERIFICATION"/>
            </gg-button>
            <div *ngIf="isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button-mobile">
              <img src="assets/images/icons/plus.svg" class="verification__option-button-mobile-icon icon__plus" alt=""/>
            </div>
          </ng-container>
        </div>
        <div *ngIf="verification.phone.enabled"
             class="verification__option">
          <div class="verification__option-title" [class.verification__option-title_complete]="verification.phone.confirmed">
            <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"/>
          </div>
          <ng-container *ngIf="!verification.phone.confirmed">
            <gg-button *ngIf="!isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button">
              <sp-msg msg="CASHIER.VERIFICATION.GO_TO_VERIFICATION"/>
            </gg-button>
            <div *ngIf="isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button-mobile">
              <img src="assets/images/icons/plus.svg" class="verification__option-button-mobile-icon icon__plus" alt=""/>
            </div>
          </ng-container>
        </div>
        <div *ngIf="verification.kyc.enabled" class="verification__option" [ngClass]="{'verification__option_complete': verification.kyc.confirmed}">
          <div class="verification__option-status">
            <div class="verification__option-title" [class.verification__option-title_pending]="verification.kyc.status === 'pending'" [class.verification__option-title_complete]="verification.kyc.confirmed">
              <sp-msg msg="PROFILE.CONTACT_DETAILS.USER_VERIFICATION"/>
            </div>
            <p *ngIf="verification.kyc.status === 'pending'" class="verification__option-sub-title">
              <sp-msg msg="PROFILE.KYCAID.USER_VERIFICATION_IN_PROGRESS"/>
            </p>
          </div>
          <ng-container *ngIf="!verification.kyc.confirmed">
            <gg-button *ngIf="!isMobile" class="verification__option-button" [routerLink]="['/user', 'verification']">
              <sp-msg msg="CASHIER.VERIFICATION.GO_TO_VERIFICATION"/>
            </gg-button>
            <div *ngIf="isMobile" [routerLink]="['/user', 'verification']" class="verification__option-button-mobile">
              <img src="assets/images/icons/plus.svg" class="verification__option-button-mobile-icon icon__plus" alt=""/>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
