import { DOCUMENT, ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpPlatform } from '@libs/cdk';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: [ './settings.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly scroller = inject(ViewportScroller);
  private readonly document = inject(DOCUMENT);
  private readonly topIndent = inject(SpPlatform).type.mobile ? 110 : 130;
  urlSection: string = '';

  ngOnInit(): void {
    this.urlSection = this.activatedRoute.snapshot.params[ 'section' ];
    this.urlSection && this.scrollToItem(this.urlSection);
  }

  scrollToItem(item: string): void {
    this.urlSection = item;
    timer(10).pipe(first()).subscribe(() => {
      const el: HTMLElement | null = this.document.getElementById(item);
      el && this.scroller.scrollToPosition([ 0, el.offsetTop - this.topIndent ]);
    });
  }
}
