<div class="status-success" *spLet="operation$ | spAsync as operation">
  <img class="status-success__icon" alt="status-icon" src="assets/images/cashier/statuses/success.svg"/>

  <ng-container *ngIf="operation === 'deposit' else withdrawal">
    <section class="status-success__deposit">
      <div class="status-success__deposit-content">
        <span class="status-success__deposit-text" spMsg="CASHIER.STATUS.MONEY_ADDED" spaces="end"></span>
        <span class="status-success__deposit-text" spMsg="CASHIER.STATUS.GOOD_GAME"></span>
      </div>
      <gg-button [settings]="okButton" (click)="close()">OK</gg-button>
    </section>
  </ng-container>
  <ng-template #withdrawal>
    <section class="status-success__withdrawal">
      <div class="status-success__withdrawal-content">
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="isPartialWithdraw$ | spAsync">
            <span class="status-success__withdrawal-text status-success__withdrawal-text_success" spMsg="CASHIER.STATUS.REQUEST_ACCEPTED" spaces="end"></span>
            <span class="status-success__withdrawal-text_no-bold status-success__withdrawal-text_wide">
              <span class="status-success__withdrawal-text_important" spMsg="CASHIER.STATUS.IMPORTANT"></span>
              <sp-msg spMsg="CASHIER.STATUS.WITHDRAWAL_SPLIT_INFO" spaces="start"/>
            </span>
            <span class="status-success__withdrawal-text status-success__withdrawal-text_wide" spMsg="CASHIER.STATUS.TRACK_IN_HISTORY"></span>
          </ng-container>
          <ng-container *ngSwitchCase="isCashWithdraw$ | spAsync">
            <span class="status-success__withdrawal-text status-success__withdrawal-text_success" spMsg="CASHIER.CACTUS.TITLE" spaces="end"></span>
            <span class="status-success__withdrawal-text_no-bold" spMsg="CASHIER.CACTUS.SUBTITLE" spaces="end"></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="status-success__withdrawal-text status-success__withdrawal-text_success" spMsg="CASHIER.STATUS.REQUEST_ACCEPTED" spaces="end"></span>
            <span class="status-success__withdrawal-text_no-bold">
              <sp-msg spMsg="CASHIER.STATUS.REQUEST_ACCEPTED_AND_PROCESSED" spaces="end"/>
              <span><sp-msg spMsg="CASHIER.STATUS.REQUEST_24" spaces="end"/></span>
              <sp-msg spMsg="CASHIER.STATUS.REQUEST_TRACK" spaces="end"/>
              <a (click)="goToWithdrawal()" spMsg="CASHIER.STATUS.REQUEST_TRACK_HISTORY"></a>
            </span>
          </ng-container>
        </ng-container>
      </div>
      <gg-button (buttonClicked)="goToWithdrawal()" [settings]="withdrawalButton"><sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.GO_TO_HISTORY"/></gg-button>
    </section>
  </ng-template>
</div>

