import { NotificationAnimationType } from '../enums';
import { NotificationOptions } from '../types';
import { DEFAULT_ICONS } from './icons';

export const DEFAULT_OPTIONS: NotificationOptions = {
  position: [ 'bottom', 'right' ],
  timeOut: 0,
  showProgressBar: true,
  pauseOnHover: true,
  lastOnBottom: true,
  clickToClose: true,
  clickIconToClose: false,
  maxLength: 0,
  maxStack: 8,
  preventDuplicates: false,
  preventLastDuplicates: false,
  theClass: '',
  rtl: false,
  animate: NotificationAnimationType.FromRight,
  icons: DEFAULT_ICONS
};
