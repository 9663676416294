import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SelectFiatCurrencies } from '@portal/currencies/commands';

@Component({
  selector: 'gg-currencies-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrl: './exchange-rates.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeRatesComponent {
  private readonly selectFiatCurrencies = inject(SelectFiatCurrencies);
  protected readonly rates = toSignal(this.selectFiatCurrencies.receive({ extractDefault: true }));
}
