@if (visible$ | spAsync) {
  <div class="toolbar" [ngClass]="{ 'toolbar-special': inPWA }">
    <div class="toolbar__container" ggCreateThemeClass="toolbar">
      <gg-layout-toolbar-item [item]="menuItem" (click)="open()" class="toolbar__section"/>
      @for (item of navigationItems; track item.label) {
        <gg-layout-toolbar-item [item]="item" class="toolbar__section"/>
      }
    </div>
  </div>
}
