<ng-container *spLet="context | spAsync as context">
  <div class="page-header" *spLet="context.menu | spAsync as isTablet">
    <div class="page-header__container">
      <gg-layout-logo/>
      @if (!isTablet) {
        @if (context.rewards && isTablet) {
          <gg-user-rewards-icon class="page-header__rewards-icon"/>
        }
        @if (context.authorization) {
          <gg-layout-authorization class="page-header__authorization"/>
        }
        @if (context.balance) {
          @if (isTablet) {
            <gg-user-quick-access-links/>
          }
          <gg-layout-user-balance withDepositButton="true" class="page-header__user-balance"/>
        }
        @if (isTablet) {
          <gg-layout-sidebar-icon (click)="context.showSidebar()"/>
        } @else if (!isTablet && context.rewards) {
          <gg-user-rewards-icon class="page-header__rewards-icon"/>
        }
      } @else {
        <div class="page-header__navigation">
          @if (context.rewards && isTablet) {
            <gg-user-rewards-icon class="page-header__rewards-icon"/>
          }
          @if (context.authorization) {
            <gg-layout-authorization class="page-header__authorization"/>
          }
          @if (context.balance) {
            @if (isTablet) {
              <gg-user-quick-access-links/>
            }
            <gg-layout-user-balance withDepositButton="true" class="page-header__user-balance"/>
          }
          @if (isTablet) {
            <gg-layout-sidebar-icon (click)="context.showSidebar()"/>
          } @else if (!isTablet && context.rewards) {
            <gg-user-rewards-icon class="page-header__rewards-icon"/>
          }
        </div>
      }
    </div>
    @if (isMobileNav?.length) {
      <gg-header-mobile-nav class="page-header__mobile-nav"/>
    }
  </div>
</ng-container>
