import {Validators} from '@angular/forms';
import { USER_DATA_INFO_NAME_PART } from './user-data-form-part-name.constant';

export const USER_DATA_NAME_PART_ZIPCODE_ADDRESS = {
  ...USER_DATA_INFO_NAME_PART,
  address: [
    '', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  zip_code: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(10)
    ]
  ]
};
