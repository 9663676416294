import { ChangeDetectionStrategy, Component } from '@angular/core';
import { VerificationSectionType } from '@portal/verification/shared/enums';

@Component({
  selector: 'gg-card-verification',
  templateUrl: './card-verification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardVerificationComponent {
  readonly currentTab: typeof VerificationSectionType = VerificationSectionType;
}
