import { inject, Injectable } from '@angular/core';
import { LayoutState, LayoutStore } from '../layout.store';

@Injectable({ providedIn: 'root' })
export class LayoutData {
  private readonly store = inject(LayoutStore);

  toggleSidebar(state?: boolean): void { this.store.update({ sidebar: state || !this.store.get('sidebar') }); }

  toggleLoading(state?: boolean): void { this.store.update({ loading: state }); }

  setAppState(state: LayoutState['app']): void { this.store.update({ app: state }); }
}
