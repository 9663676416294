<gg-banner-socket page="deposit" section="notification" class="banner-notification"></gg-banner-socket>
<gg-banner-socket *ngIf="isOldCardSelected" page="deposit" section="top" class="banner-notification"></gg-banner-socket>
<div *ngIf="isCashierLoading$ | async" class="preloader">
  <img
    class="preloader-img"
    src="assets/images/loader/preloader.png"
    alt="preloader"
  />
</div>
<pd-grid
  [showBankCardMethodsButton]="isBankCardsButtonShown"
  [section]="section"
  [paymentMethods]="paymentMethods$"
></pd-grid>

