import { BonusInStatus, BonusStatus } from '@portal/bonuses/shared';

export const mapInStatus = (current: BonusStatus): BonusInStatus => {
  return {
    current,
    is: {
      waiting: current === BonusStatus.New || current === BonusStatus.Waiting,
      freeze: current === BonusStatus.Freeze,
      expired: current === BonusStatus.Expired,
      active: current === BonusStatus.Active,
      inProgress: current === BonusStatus.Active || current === BonusStatus.Wager,
      success: current === BonusStatus.Gained || current === BonusStatus.Completed,
      failed: current === BonusStatus.Used || current === BonusStatus.Canceled
    }
  };
};
