import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
@Component({
  standalone: true,
  imports: [ SpCDKModule, ThemeModule ],
  selector: 'gg-payment-loader',
  templateUrl: './loader.component.html',
  styleUrls: [ './loader.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() title: string = ''
}
