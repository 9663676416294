import { firstTruthy, tapOnce } from '@libs/utils';
import { map, Observable, switchMap } from 'rxjs';

type Syncable = { synchronize(): void };
type WithSync = { synchronized$: Observable<boolean> };

const sync$ = (data: Syncable, query: WithSync): Observable<boolean> => {
  return query.synchronized$.pipe(tapOnce((sync) => !sync && data.synchronize()), firstTruthy());
};

export function synchronize(data: Syncable, query: WithSync): void {
  sync$(data, query).subscribe();
}

export function synchronize$<T>(data: Syncable, query: WithSync): (source$: Observable<T>) => Observable<T> {
  return (source$) => source$.pipe(
    switchMap((val) => sync$(data, query).pipe(map(() => val)))
  );
}
