import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { BONUS_STATUS_MESSAGES, BonusMessage } from './bonus-messages';

@Component({
  selector: 'gg-bonus-card-message',
  templateUrl: './message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent {

  @Input() bonus: Nullable<Bonus>;

  get statusMessage(): BonusMessage | null {
    if (!this.bonus) { return null; }
    return BONUS_STATUS_MESSAGES[this.bonus.status.current] || null;
  }

}
