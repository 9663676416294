import { excludeNullish, GlobalWindow } from '@libs/utils';
import { CreateWithdrawDto } from '@portal/payment/data';
import { collectBrowserInfo, Payment } from '@portal/payment/shared';

export function createWithdrawRequest(payment: Payment, window: GlobalWindow): CreateWithdrawDto {
  const location = `${window.location.origin}/?section=withdrawal&method=${payment.method?.id}&amount=${payment.amount?.toString()}`;

  return {
    payment_method: Number(payment.method?.id),
    account: payment.data?.additional?.account || null,
    amount: payment.amount?.toString(),
    browser_info: collectBrowserInfo(window, payment.data?.additional?.fingerprint),
    pending_url: `${location}&status=pending`,
    payment_data: { ...payment.data?.payment },
    page_url: window.location.origin,
    card_data: excludeNullish({
      exp_month: payment.data?.additional?.expiry_month,
      exp_year: payment.data?.additional?.expiry_year,
      first_name: payment.data?.payment?.first_name,
      last_name: payment.data?.payment?.last_name
    }),
    cardholder_name: payment.data?.additional?.holder,
    expiry_month: payment.data?.additional?.expiry_month,
    expiry_year: payment.data?.additional?.expiry_year,
    bank_id: payment.data?.additional?.bank_id,
    operator_id: payment.data?.additional?.operator_id,
    verification_code: payment.data?.additional?.verification_code,
    cryptocurrency: payment.data?.additional?.cryptocurrency,
    city: payment.data?.additional?.city,
    address: payment.data?.additional?.address,
    messenger: payment.data?.additional?.messenger,
    username: payment.data?.additional?.username
  };
}
