@if (card) {
  <h4 class="p2p-card__title" spMsg="CARD.MAKE_DEPOSIT_TRANSFER"></h4>
  <div class="p2p-card" [ngClass]="'p2p-card_' + card.type">
    <div class="p2p-card__account">
      <div class="p2p-card__account-container">
        <sp-msg class="p2p-card__item-title" [spMsg]="'CASHIER.CARD.P2P.' + card.type | uppercase"></sp-msg>
        <span
          class="p2p-card__number"
          [ngClass]="{ 'p2p-card__number_card': card.type === 'p2p_card' && !card.cardHolder && !card.bankCardName}"
        >
          {{ accountTransform(card.type, card.account) }}
        </span>
      </div>
      <gg-clipboard-copy
        class="p2p-card__account-copy"
        [copyValue]="card.account"
        [isSmallButton]="true"
      />
    </div>
    @if (card.cardHolder || card.bankCardName) {
      <div class="p2p-card__info">
        @if (card.cardHolder) {
          <div class="p2p-card__info-item">
            <sp-msg class="p2p-card__item-title" spMsg="CASHIER.CARD.P2P.HOLDER"></sp-msg>
            <span class="p2p-card__info-item-data">{{ card.cardHolder }}</span>
          </div>
        }
        @if (card.bankCardName) {
          <div class="p2p-card__info-item">
            <sp-msg class="p2p-card__item-title" spMsg="CASHIER.CARD.P2P.BANK_NAME"></sp-msg>
            <span class="p2p-card__info-item-data">{{ card.bankCardName }}</span>
          </div>
        }
      </div>
    }
    @if (timer) {
      <div class="p2p-card__timer-container">
        <sp-msg spMsg="CASHIER.CARD.TIME_LEFT" class="p2p-card__item-title"></sp-msg>
        <div class="p2p-card__timer">{{ timer.time | amDateFormat: 'mm:ss' }}</div>
      </div>
    }
  </div>
  <ul class="p2p-card__hints">
    <li class="p2p-card__hint" spMsg="CASHIER.CARD.P2P.DESCRIPTION_ONE"></li>
    <li class="p2p-card__hint" spMsg="CASHIER.CARD.P2P.DESCRIPTION_TWO"></li>
    <li class="p2p-card__hint" [spMsg]="card.type === 'p2p_sbp' ? 'CASHIER.CARD.SBP.DESCRIPTION_THREE' :
     'CASHIER.CARD.P2P.DESCRIPTION_THREE'"></li>
  </ul>
}
