import { ReceivedBannerDto } from '@portal/banners/data';

export const APP_TOP_BANNER: ReceivedBannerDto = {
  _id: 'app-top-banner',
  type: 'download_app_header',
  template: { page: 'all', section: 'top', order: 0 },
  showToAnonymous: true,
  showToAuthorized: true,
  url: '',
  additionalUrl: '',
  dateExp: null,
  title: null,
  label: null,
  description: null,
  image: null,
  mobileImage: null,
  callToAction: null,
  additionalAction: null,
  imageAlt: null
};
