import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import {
  findGamesBySource,
  findSectionsByDisplay,
  Game,
  GameProvider,
  GameSection,
  GamesState,
  GamesStore,
  getSource
} from '@portal/games/data';
import { GameSourceInfo } from '@portal/games/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GamesQuery extends SteroidQuery<GamesState> {
  private readonly games = this.entity('games');
  private readonly sections$: Observable<Array<GameSection>> = this.select('sections');

  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly loading$: Observable<Nullable<boolean>> = this.games.select('loading');
  readonly topMenuSections$: Observable<Array<GameSection>> = this.sections$.pipe(
    map((sections) => findSectionsByDisplay(sections, 'topMenu'))
  );

  readonly sidebarSections$: Observable<Array<GameSection>> = this.sections$.pipe(
    map((sections) => findSectionsByDisplay(sections, 'sidebar'))
  );

  readonly gamesRecent$: Observable<Array<Game>> = this.select('recent');
  readonly providers$: Observable<Array<GameProvider>> = this.select('providers');
  readonly allGames$: Observable<Array<Game>> = this.games.selectAll();

  constructor(store: GamesStore) { super(store); }

  selectBySource<T extends GameSourceInfo>(source: T, limit?: number): Observable<Array<Game>> {
    const selectOptions = limit ? { limitTo: limit } : {};
    return this.games.selectAll(selectOptions).pipe(map((games) => findGamesBySource(games, source)));
  }

  countLoaded(source: GameSourceInfo | null): number {
    return !source ? 0 : findGamesBySource(this.games.getAll(), source).length;
  }

  canLoadMore(source: GameSourceInfo): boolean {
    return this.countLoaded(source) < this.countTotal(source);
  }

  getSource(source: GameSection['key'] | GameProvider['key']): GameProvider | GameSection | null {
    const { providers, sections } = this.get();
    return getSource(providers, sections, source);
  }

  checkSourceExists(source: GameSection['key'] | GameProvider['key']): boolean {
    return !!this.getSource(source);
  }

  getSectionLimit(source: string): Observable<number> {
    return this.select('limit').pipe(map((limit) => limit[ source ]));
  }

  countTotal(source: GameSourceInfo): number {
    return this.get('total')[ source.key ] || 0;
  }

}
