import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { extractNumbers, Nullable } from '@libs/utils';
import { ErrorManager, ErrorResponse } from '@portal/shared/helpers';
import { UserData } from '@portal/user/data';
import {
    createConfirmationCodeForm,
    createEmailForm,
    createMobilePhoneForm,
    PhoneVerificationState
} from '@portal/user/shared';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ContactDetailsService {
  private readonly notifications = inject(SpNotification);
  private readonly errorManager = inject(ErrorManager);
  private readonly userSettingsCommands = inject(UserData);

  private readonly phoneVerificationState: BehaviorSubject<PhoneVerificationState> = new BehaviorSubject<PhoneVerificationState>(
    PhoneVerificationState.NotVerified
  );
  private readonly isEmailChanged: BehaviorSubject<Nullable<boolean>> = new BehaviorSubject<Nullable<boolean>>(false);
  readonly phoneVerificationState$: Observable<PhoneVerificationState> = this.phoneVerificationState.asObservable();
  readonly mobilePhoneForm: FormGroup = createMobilePhoneForm();
  readonly changeEmailForm: FormGroup = createEmailForm();
  readonly mobilePhoneErrors: ErrorManager = this.errorManager.setUp({ form: this.mobilePhoneForm });
  readonly changeEmailErrors: ErrorManager = this.errorManager.setUp({ form: this.changeEmailForm });
  readonly confirmationCodeForm: FormGroup = createConfirmationCodeForm();
  readonly confirmationCodeErrors: ErrorManager = this.errorManager.setUp({ form: this.confirmationCodeForm });
  readonly isEmailChanged$: Observable<Nullable<boolean>> = this.isEmailChanged.asObservable();

  updateVerificationState(state: PhoneVerificationState): void {
    this.phoneVerificationState.next(state);
  }

  verifyPhone(): void {
    const credentials = {
      ...this.mobilePhoneForm.value,
      phone: extractNumbers(this.mobilePhoneForm.value.phone, '+')
    };

    this.userSettingsCommands.verifyPhone(credentials).subscribe({
      next: () => {
        this.notifications.success('NOTIFICATIONS.MESSAGES.CONTACT_DETAILS_SMS_SENT');
        this.phoneVerificationState.next(PhoneVerificationState.CodeResent);
        this.confirmationCodeForm.reset({ code: '' });
      },
      error: (err: ErrorResponse) => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.CONTACT_DETAILS_WRONG_PHONE');
        this.mobilePhoneErrors.setServerErrors(err);
      }
    });
  }

  changeEmail(): void {
    const { email, recaptchaToken } = this.changeEmailForm.value;
    this.userSettingsCommands.changeEmail(email, recaptchaToken).subscribe({
      next: () =>
      {
        this.toggleChangeEmail(true);
        this.changeEmailForm.reset();
        this.notifications.success('NOTIFICATIONS.MESSAGES.EMAIL_CONFIRMATION_EMAIL_CHANGED')
      },
      error: () => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.EMAIL_CONFIRMATION_EMAIL_CHANGE_ERROR')
      },
    });
    }

  toggleChangeEmail(state?: boolean): void {
    this.isEmailChanged.next(state);
  }
}
