import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { SpTranslationLoader, Translation } from '@libs/cdk';
import { catchError, Observable, of } from 'rxjs';

export class LocalizationLoader implements SpTranslationLoader {
  private readonly http = inject(HttpClient);

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/translate/translations/${lang}`).pipe(
      catchError(() => of({}))
    );
  }
}
