<div class="recaptcha-form">
  <section class="recaptcha-form__wrapper">
    <div class="recaptcha-form__title" spMsg="COMMON.APPLY_RECAPTCHA"></div>
    <re-captcha [formControl]="recaptchaToken"></re-captcha>
    <gg-button
      class="recaptcha-form__submit"
      [fullWidth]="true"
      [disabled]="recaptchaToken.invalid"
      (buttonClicked)="confirm()"
    >
      <sp-msg msg="COMMON.APPROVE"></sp-msg>
    </gg-button>
  </section>
  <img src="assets/images/icons/cross2.svg" class="recaptcha-form__close" (click)="close()" alt=""/>
</div>
