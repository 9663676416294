<div class="two-factor-auth">
  <ng-container *spLet="twoFactorState$ | async as twoFactorState">
    <ng-container *ngIf="twoFactorState === 'disabled' else methodStatus">
      <div *ngIf="isYaMethodEnabled" class="two-factor-auth__methods-types">
        <h4 class="two-factor-auth__description" spMsg="PROFILE.TWO_FACTOR_AUTH.DESCRIPTION"></h4>
        <h4 class="two-factor-auth__sub-description" spMsg="PROFILE.TWO_FACTOR_AUTH.CHOOSE_AUTH"></h4>
        <div class="two-factor-auth__methods">
          <div
            *ngFor="let method of allMethods; index as i"
            class="two-factor-auth__method"
            [class.two-factor-auth__method_active]="i === methodIndex"
            (click)="changeMethod(method.value, i)"
          >
            <div class="two-factor-auth__method-title" [spMsg]="method.title"></div>
            <img class="two-factor-auth__method-icon" [src]="method.icon" alt="auth-method-icon">
          </div>
        </div>
      </div>
      <gg-auth-method
        *ngIf="isMethodShown | async"
        class="two-factor-auth__code-generation"
        [methodDetails]="currentMethod"
        [twoFactorSettings]="twoFactorSettings$ | async"
      ></gg-auth-method>
    </ng-container>
    <ng-template #methodStatus>
      <gg-method-status></gg-method-status>
    </ng-template>
  </ng-container>
</div>

