<div
  *ngIf="documentUploadingState$ | async as state"
  class="documents-verification"
  [ngClass]="state"
>
  <ng-container *ngIf="state === documentUploadingState.Upload">
    <form [formGroup]="documentUploadForm">
      <label for="downloadImgs" class="documents-verification__upload-documents">
        <gg-verification-upload-doc-info/>
      </label>
      <input
        class="info__input-file"
        (change)="fileSelected($event)"
        type="file"
        id="downloadImgs"
        accept=".jpg, .jpeg, .png"
      />
    </form>
    <gg-verification-uploaded-docs *ngIf="documents$ | async as docs" [documents]="docs"/>
  </ng-container>
  <ng-container *ngIf="selectedDocument$ | async as document">
    <ng-container *ngIf="state === documentUploadingState.TypeSelection">
      <img [src]="document.image" class="info__preview--img" alt="document-image" />
      <div class="info__preview--name info__uploaded--name">{{document.name}}</div>
      <form class="info__select-form" [formGroup]="documentTypeForm">
        <gg-documents-upload-selector
          formControlName="documentType"
          class="info__preview-selector"
          [documentList]="documentList"
        ></gg-documents-upload-selector>
        <gg-button
          class="info__button__upload"
          additionalClasses="info__preview--button info__button info__button--no-margin"
          [disabled]="documentTypeForm.invalid"
          [settings]="saveButton"
          (buttonClicked)="summary()"
        >
          <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.UPLOAD"/>
        </gg-button>
      </form>
    </ng-container>

    <ng-container *ngIf="state === documentUploadingState.UploadConfirmation">
      <div class="info__preview--loading-text">
        <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.UPLOADING_END"/>
      </div>
      <div class="info__preview--name-gray">
        <sp-msg [msg]="selectedDocumentType"/>
      </div>
      <img
        alt="document-image"
        class="info__preview--img info__preview--img--no-margin"
        [src]="document.image"
      />
      <div class="info__preview--buttons">
        <gg-button
          additionalClasses="info__preview--button-small info__button info__button--gray"
          [settings]="editButton" class="info__button--gray"
          (buttonClicked)="cancel()"
        >
          <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.CANCEL"/>
        </gg-button>
        <gg-button
          additionalClasses="info__button info__preview--button-small"
          [settings]="saveButton"
          (buttonClicked)="confirm()"
        >
          <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.OK"/>
        </gg-button>
      </div>
    </ng-container>
  </ng-container>
  <gg-verification-progress-bar *ngIf="state === documentUploadingState.Progress"></gg-verification-progress-bar>
  <gg-verification-document-error *ngIf="state === documentUploadingState.Error" (cancel)="cancel()"></gg-verification-document-error>
</div>
