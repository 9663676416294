import { Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';

export const USER_DATA_WINPAY_VISA_WITHDRAWAL = {
  first_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  last_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  country: [
    '', [ Validators.required ]
  ],
  phone: [
    '',
    [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(15)
    ]
  ],
  city: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  document_number: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(12)
    ]
  ],
  document_serial: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(10)
    ]
  ],
  birth_date: [
    '',
    [
      Validators.required,
      CustomValidators.birthday
    ]
  ],
  document_type: [
    '',
    [
      Validators.required
    ]
  ]
};
