import { SpConnector } from '@libs/cdk';
import { WINDOW } from '@ng-web-apis/common';
import { ConnectedEvents } from '@portal/shared/types';
import { map } from 'rxjs';
import { ElementAnalyticHandler } from './abstract.handler';

const enum CustomPixelPaths {
  Visit = 'https://s2517.com/set-cookie.gif?visit-26-kz=1',
  Registration = 'https://s2517.com/set-cookie.gif?registration-26-kz=1',
  Deposit = 'https://s2517.com/set-cookie.gif?deposit-26-kz=1'
}

export class CustomHandler extends ElementAnalyticHandler {
  private readonly window = this.injector.get<Window>(WINDOW);
  private readonly connector = this.injector.get<SpConnector<ConnectedEvents>>(SpConnector);

  override create(): void {
    this.connector.listen('analytics:create-custom-pixel').pipe(
      map(({ payload }) => payload.type === 'cashier' ? CustomPixelPaths.Deposit : CustomPixelPaths.Registration)
    ).subscribe((event) => {
      this.createElement('img', { height: '0', width: '0', src: event }, this.window.document.body);
    });
  }

  override onNavigationEnds(): void {
    this.createElement('img', { height: '0', width: '0', src: CustomPixelPaths.Visit }, this.window.document.body);
  }
}
