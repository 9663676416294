import { Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';

@Component({
  standalone: true,
  selector: 'gg-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: [ './inline-loader.component.scss' ],
  imports: [ SpCDKModule ]
})
export class InlineLoaderComponent {
  @Input() visible: boolean = false;
}
