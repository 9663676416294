import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { inject } from '@angular/core';
import { SpPlatform } from '@libs/cdk';

export abstract class PWADialogAbstract {
  private readonly dialog = inject(DialogRef);
  private readonly platform = inject(SpPlatform);
  protected readonly data = inject(DIALOG_DATA);
  protected readonly device = this.platform.currentDeviceType;
  protected readonly browser = this.platform.currentBrowser;

  close(): void {
    this.dialog.close();
  }
}
