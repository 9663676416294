import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { createPromoCodeSubmitForm } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { ErrorManager } from '@portal/shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-payment-promo-code',
  templateUrl: './cashier-promo-code.component.html',
  styleUrls: [ './cashier-promo-code.component.scss' ],
  imports: [ SpCDKModule, ControlsModule, ThemeModule ],
  providers: [ ErrorManager ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashierPromoCodeComponent implements OnDestroy {
  private readonly bonusData = inject(BonusesData);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly promoCodeActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly promoCodeActivated$: Observable<boolean> = this.promoCodeActivated.asObservable();
  readonly form: FormGroup = createPromoCodeSubmitForm();
  readonly errors = inject(ErrorManager).setUp({ form: this.form });

  @Input() methodMaxValue: Nullable<string> = null;
  @Input() noLabelPlaceholder: Nullable<boolean> = true;
  @Output() checkChanges: EventEmitter<void> = new EventEmitter<void>();

  ngOnDestroy(): void { this.form.reset(); }

  checkPromoCode(): void {
    const promoCodeControl = this.form.controls[ 'promoCode' ];

    this.bonusData.activatePromoCode(promoCodeControl.value).pipe(
      first(), takeUntilDestroyed(this.destroyRef$),
      map(() => this.bonusesQuery.getAvailableDepositBonuses(this.methodMaxValue))
    ).subscribe({
      next: (bonuses: Array<Bonus>) => {
        this.bonusData.selectBonus(bonuses[ 0 ]);
        this.promoCodeActivated.next(true);
        this.checkChanges.emit();
      },
      error: (res) => {
        this.errors.set({ promoCode: [ res.issues[ 0 ] ] });
        promoCodeControl.setErrors({});
      }
    });
  }

  getCurrentStatus(activated: boolean): void {
    if (!activated) { return }
    this.promoCodeActivated.next(false);
  }
}
