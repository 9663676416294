import { HttpClient } from '@angular/common/http';
import { DestroyRef, Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpStorage } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { BehaviorSubject, filter, map, Observable, of, Subscription, switchMap, tap, timer } from 'rxjs';
import { SpAuthorizationCommands } from './abstract.commands';
import { SP_AUTHORIZATION_CONFIG, SpAuthorizationConfig } from './config';

export type TokensPair = Partial<{ accessToken: string; refreshToken: string }> | null;

@Injectable()
export class SpAuthorizationService implements OnDestroy {
  private readonly tokensStorage: BehaviorSubject<Nullable<TokensPair>> = new BehaviorSubject<Nullable<TokensPair>>(null);
  private readonly userStorage: BehaviorSubject<Nullable<unknown>> = new BehaviorSubject<Nullable<unknown>>(null);

  private readonly tokenExpirationTimer: Subscription = new Subscription();
  private readonly userDiscovery: Subscription = new Subscription();

  readonly tokens$: Observable<Nullable<TokensPair>> = this.tokensStorage.asObservable();
  readonly user$: Observable<Nullable<unknown>> = this.userStorage.asObservable();

  readonly authorized$: Observable<boolean> = this.tokens$.pipe(
    filter((tokens) => tokens === null || (!!tokens?.accessToken && !!tokens?.refreshToken)),
    map((tokens) => Boolean(tokens?.accessToken))
  );

  get tokens(): Nullable<TokensPair> { return this.tokensStorage.getValue(); }

  get authorized(): boolean { return Boolean(this.tokens?.accessToken); }

  constructor(
    private readonly http: HttpClient,
    private readonly storage: SpStorage,
    private readonly destroy: DestroyRef,
    @Optional() private readonly commands: SpAuthorizationCommands | null,
    @Optional() @Inject(SP_AUTHORIZATION_CONFIG) private readonly config: SpAuthorizationConfig | null
  ) {
    if (!this.config) { return; }

    const refreshToken = this.storage.getItem(this.config.refreshKey);
    refreshToken && this.tokensStorage.next({ refreshToken });

    this.userDiscovery = this.authorized$.pipe(switchMap(() => this.discover())).subscribe();
  }

  ngOnDestroy(): void {
    this.tokenExpirationTimer.unsubscribe();
    this.userDiscovery.unsubscribe();
  }

  set(tokens: unknown): void { this.setToken(tokens); }

  clear(): Observable<unknown> {
    return !this.config
      ? of(null).pipe(tap(() => this.set(null)))
      : this.http.get(this.config.routes.signOut).pipe(tap(() => this.set(null)));
  }

  discover(): Observable<unknown> {
    const discover = Boolean(this.tokensStorage.getValue()?.accessToken) && this.config && this.commands
      ? this.http.get(this.config?.routes.discovery).pipe(map((user) => this.commands?.mapDiscoveredUser(user)))
      : of(null);

    return discover.pipe(tap((user) => this.userStorage.next(user)));
  }

  refresh(): Observable<TokensPair> {
    if (!this.tokensStorage.getValue()?.refreshToken || !this.config) { return of(null); }
    return this.http.post(this.config.routes.refresh, {});
  }

  private setToken(response: unknown): void {
    if (!this.config || !this.commands) { return; }

    const tokens = this.commands.parseTokens(response);
    const expiresIn = this.commands.parseExpiresIn(response);

    tokens?.refreshToken
      ? this.storage.setItem(this.config.refreshKey, tokens.refreshToken)
      : this.storage.removeItem(this.config.refreshKey);

    this.tokensStorage.next(tokens);

    if (!tokens?.accessToken || !expiresIn) { return; }

    timer(expiresIn - (1000 * 60)).pipe(
      takeUntilDestroyed(this.destroy),
      switchMap(() => this.refresh())
    ).subscribe((res) => this.setToken(res as TokensPair));
  }
}
