import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { ContactDetailsService } from '@portal/user/features/settings/contact-details/contact-details.service';

@Component({
  selector: 'gg-user-settings-contact-details-phone-not-verified',
  templateUrl: './phone-not-verified.component.html',
  styleUrls: [
    './phone-not-verified.component.scss',
    './purple.phone-not-verified.component.scss',
    './silver.phone-not-verified.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNotVerifiedComponent {
  private readonly contactDetailsService = inject(ContactDetailsService);
  mobilePhoneForm: FormGroup;
  mobilePhoneErrors: ErrorManager;

  @Input() isFlashCallOption: boolean = false;
  @Input() saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };

  constructor() {
    this.mobilePhoneForm = this.contactDetailsService.mobilePhoneForm;
    this.mobilePhoneErrors = this.contactDetailsService.mobilePhoneErrors;
  }

  verifyPhone(): void {
    this.contactDetailsService.verifyPhone();
  }
}
