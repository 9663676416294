import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { RecaptchaInputDialogComponent, RecaptchaQuery } from '@portal/recaptcha';
import { ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserData } from '@portal/user/data';
import { EmailConfirmationService } from '@portal/user/features/email-confirmation/email-confirmation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gg-user-email-confirmation-not-confirmed',
  templateUrl: './not-confirmed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotConfirmedComponent implements OnDestroy {
  private readonly window = inject(WINDOW);
  private readonly recaptchaQuery = inject(RecaptchaQuery);
  private readonly notifications = inject(SpNotification);
  private readonly confirmationService = inject(EmailConfirmationService);
  private readonly userCommands = inject(UserData);
  private readonly dialog = inject(Dialog);
  private eventSubscription: Subscription = new Subscription();

  readonly actionButtonConfig: IButton = { theme: ButtonThemes.White, fullWidth: true };
  readonly email$ = this.confirmationService.email$;

  redirectOn(email: string): void {
    this.window.open(`//${email.split('@')[1]}`);
  }

  showEmailForm(): void {
    this.confirmationService.changeViewState('changeEmail');
  }

  resendEmailVerification(recaptchaToken?: Nullable<string>): void {
    if (!recaptchaToken && this.recaptchaQuery.isRecaptchaEnabledFor('profileEmail')) {
      this.eventSubscription = this.dialog.open(RecaptchaInputDialogComponent, { panelClass: '' }).closed.subscribe((newRecaptchaToken): void => {
        newRecaptchaToken && this.resendEmailVerification(newRecaptchaToken as Nullable<string>);
      });
      return;
    }

    this.userCommands.resendVerificationEmail(recaptchaToken).subscribe({
      next: () => this.confirmationService.changeViewState('emailResent'),
      error: () => this.notifications.error('NOTIFICATIONS.MESSAGES.EMAIL_RESEND_ERROR')
    });
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }
}
