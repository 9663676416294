import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Language, SpLocalization, SpStorage } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { USER_AGENT } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { DialogPanel, StorageKey } from '@portal/shared/types';
import { BOT_AGENTS_LIST, CONTINUE_WITH_LOCALE_MESSAGE } from '@portal/user/shared';
import { filter, map, timer } from 'rxjs';

@Component({
  selector: 'gg-user-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: [ './language-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageDialogComponent implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly agent = inject(USER_AGENT);
  private readonly storage = inject(SpStorage);
  private readonly config = inject(ConfigQuery);
  private readonly localization = inject(SpLocalization);

  readonly proceedButton: IButton = { size: ButtonSizes.Large, theme: ButtonThemes.Green };
  readonly browserLanguage: Nullable<Language> = this.localization.languageFromBrowser;
  readonly pathLanguage: Nullable<Language> = this.localization.languageFromPath;
  readonly defaultLanguage: Nullable<Language> = this.localization.defaultLanguage;
  readonly continueMessage: string = CONTINUE_WITH_LOCALE_MESSAGE[ this.defaultLanguage as keyof typeof CONTINUE_WITH_LOCALE_MESSAGE ];

  @ViewChild('selectLanguage', { static: true, read: TemplateRef }) template: Nullable<TemplateRef<unknown>> = null;

  get isLanguageSpecificationNeeded(): boolean {
    return this.config.modules.localization.showLanguageSelectDialog
           && !this.storage.getItem(StorageKey.LocaleChanged)
           && this.browserLanguage !== this.defaultLanguage
           && this.localization.isValidLanguage(this.browserLanguage);
  }

  ngOnInit(): void {
    const isBotAgent = BOT_AGENTS_LIST.some((agent) => this.agent.includes(agent));

    if (isBotAgent && !this.pathLanguage) { this.localization.setLanguage(this.localization.defaultLanguage); }
    if (!this.isLanguageSpecificationNeeded || isBotAgent) { return; }

    this.localization.setLanguage(this.localization.languageFromBrowser);
    this.openSpecificationDialog();
  }

  close(language?: Nullable<Language>): void {
    if (language) { this.localization.setLanguage(language); }

    this.storage.setItem(StorageKey.LocaleChanged, 'changed');
    this.dialog.closeAll();
  }

  private openSpecificationDialog(): void {
    timer(5000).pipe(
      map(() => this.template), filter((template): template is TemplateRef<unknown> => !!template)
    ).subscribe((template) => this.dialog.open(template, { panelClass: DialogPanel.BottomCenter }));
  }

}
