export class BankCard {
  constructor(
    readonly id: string,
    readonly account: string,
    readonly expMonth?: string,
    readonly expYear?: string,
    readonly type: string = 'unknown',
    readonly status?: string,
  ) {}
}
