<section class="field">
  <div class="{{ additionalClass }} input placeholder__wrap">
    <form id="date-form" [formGroup]="dateForm">
      <gg-input-select
        class="date-select date-day"
        [options]="days"
        formControlName="day"
        [defaultOption]="defaultDay"
        additionalClass="day-picker"
      />
      <gg-input-select
        class="date-select date-month"
        [options]="months"
        formControlName="month"
        [defaultOption]="defaultMonth"
        additionalClass="month-picker"
      />
      <gg-input-select
        class="date-select date-year"
        [options]="years"
        formControlName="year"
        [defaultOption]="defaultYear"
        additionalClass="year-picker"
      />
    </form>
  </div>
  <gg-input-error [errors]="errors"></gg-input-error>
</section>
