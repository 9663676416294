import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-logo',
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, RouterLink ]
})
export class LogoComponent {
  private readonly configQuery = inject(ConfigQuery);
  private readonly breakpoints = inject(BreakpointObserver);

  protected readonly logo = this.configQuery.project.logo;
  protected readonly isTablet$ = this.getBreakpointChecker();

  getBreakpointChecker(): Observable<boolean> {
    return this.breakpoints.observe('(min-width: 1024px)').pipe(map(({ matches }) => matches));
  }
}
