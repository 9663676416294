import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Language, SpConnector, SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { InputSelectComponent } from '@portal/shared/components/controls/inputs/input-select/input-select.component';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { ConnectedEvents } from '@portal/shared/types';
import { UserQuery } from '@portal/user';

import { InputAbstract } from 'apps/portal/src/app/shared/components/controls/input.abstract';

import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LANGUAGE_NAMES } from './language-names.map';

@Component({
  selector: 'gg-dropdown-language-switcher',
  templateUrl: './dropdown-language-switcher.component.html',
  styleUrls: [ './dropdown-language-switcher.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DropdownLanguageSwitcherComponent, multi: true }
  ]
})
export class DropdownLanguageSwitcherComponent extends InputAbstract implements OnInit, OnDestroy {
  private readonly localization = inject(SpLocalization);
  private readonly valueChangesPipe: Subscription = new Subscription();
  private readonly connector = inject(SpConnector<ConnectedEvents>);
  private readonly userId = inject(UserQuery).id;

  readonly control: FormControl = new FormControl();

  readonly languages: Array<IInputSelectData> = [];
  currentLanguage: Nullable<IInputSelectData>;

  @Input() position: InputSelectComponent['position'] = 'below';
  @Input() default: Nullable<Language> = null;
  @Output() changed: EventEmitter<void> = new EventEmitter();

  constructor() {
    super();

    this.languages = this.localization.list.map((language) =>
      ({ value: language, description: LANGUAGE_NAMES[language] })
    );

    this.valueChangesPipe.add(this.createValueChangesPipeline());
  }

  override ngOnInit(): void {
    super.ngOnInit();
    const current = this.default || this.localization.currentLanguage;
    this.currentLanguage = this.languages.find(({ value }) => value === current);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.valueChangesPipe.unsubscribe();
  }

  createValueChangesPipeline(): Subscription {
    return this.control.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe({
      next: (language) => {
        if (this.userId) {
          this.connector.sendEvent('log:user-language-change',
            {
              userId: this.userId,
              before: this.currentLanguage?.value || this.localization.currentLanguage,
              after: language
            });
        }
        this.changed.emit();
        this.localization.setLanguage(language);
      }
    });
  }
}
