import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { AbstractBonus } from '@portal/bonuses/data/abstracts';
import { CASHIER_BONUS_SWIPER_CONFIG } from '@portal/bonuses/shared';
import { UserQuery } from '@portal/user';
import { BehaviorSubject, combineLatest, map, Observable, of, withLatestFrom } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'gg-bonuses-cashier-slider',
  templateUrl: './slider-bonuses.component.html',
  styleUrls: [
    './slider-bonuses.component.scss',
    './silver.bonuses.component.scss',
    './purple.bonuses.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SliderBonusesComponent implements OnInit, OnChanges {
  private readonly bonusesData = inject(BonusesData);
  private readonly bonusesQuery = inject(BonusesQuery);

  readonly sliderConfig: SwiperOptions = CASHIER_BONUS_SWIPER_CONFIG;
  readonly areBonusesBlocked = !inject(UserQuery).isBonusesEnabled;

  activeSlide: BehaviorSubject<number> = new BehaviorSubject(0);
  bonusesList: Nullable<Observable<Array<Bonus>>> = null;

  @Input() depositAmount: Nullable<string>;
  @Input() maxMethodPrice: Nullable<string>;
  @Input() isCryptoMethod: Nullable<boolean> = false;

  @Output() setAmountField: EventEmitter<string> = new EventEmitter<string>();

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  ngOnInit(): void {
    this.bonusesList = combineLatest([
      this.bonusesQuery.selectAvailableDepositBonuses(this.maxMethodPrice),
      (this.isCryptoMethod ? this.bonusesData.loadCryptoActiveBonus() : of(null)) ]
    ).pipe(
      filter(([ bonuses ]) => !!bonuses.length),
      withLatestFrom(this.bonusesQuery.depBonusActivated$),
      tap(([ [ bonuses ], depBonusActivatedValue ]) => {
        if (this.activeBonus || depBonusActivatedValue) { return; }
        const doubleBonus = bonuses.find((bonus) => bonus.type === 'casino_linked');
        this.bonusesData.selectBonus(doubleBonus || bonuses[ 0 ]);
      }),
      tap(([ [ bonuses, activeCryptoBonus ] ]) => {
        if (this.isCryptoMethod) {
          activeCryptoBonus ? this.bonusesData.selectBonus({ id: activeCryptoBonus }) : this.unselectBonus();
        }
        this.activeSlide.next(bonuses.findIndex((bonus) => this.activeBonus?.id === bonus.id));
      }),
      map(([ bonuses ]) => bonuses[ 0 ])
    );
  }

  ngOnChanges({ depositAmount }: SimpleChanges): void {
    if (!depositAmount) { return; }
    if (Number(this.depositAmount) < Number(this.activeBonus?.activation.price)) { this.unselectBonus(); }
  }

  selectBonus(bonus: Bonus, event: MouseEvent): void {
    event.stopPropagation();
    this.bonusSelected(bonus);
  }

  isEqual(bonus: string | AbstractBonus): boolean {
    if (bonus instanceof AbstractBonus) { return this.activeBonus?.id === bonus.id; }

    return this.activeBonus?.id === bonus;
  }

  private bonusSelected(bonus: Bonus): void {
    if (this.activeBonus === bonus) {
      this.unselectBonus();
      return;
    }

    this.bonusesData.selectBonus(bonus);

    if (this.isCryptoMethod) {
      this.bonusesData.setCryptoActiveBonus(bonus);
    }

    if (Number(this.depositAmount) < Number(this.activeBonus?.activation.price)) {
      this.setAmountField.emit(this.activeBonus?.activation.price);
    }
  }

  private unselectBonus(): void {
    this.activeSlide.next(0);
    if (this.isCryptoMethod) {
      this.bonusesData.cancelCryptoActiveBonus();
    }
    this.bonusesData.unselectBonus();
  }
}
