import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { ConfigStore, createConfig } from '@portal/config/data';
import { GetConfigResponse } from '@portal/config/shared';
import { catchError, map, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfigData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(ConfigStore);
  private readonly localization = inject(SpLocalization);

  synchronize(): void {
    this.http.get<GetConfigResponse>('/api/2/get_front_config').pipe(
      map((config) => createConfig(config._item)),
      tap(({ modules }) => modules?.localization && this.localization.setupLanguages(modules.localization)),
      catchError(() => of({}))
    ).subscribe((config) => this.store.update({ ...config, sync: true }));
  }
}
