import { inject } from '@angular/core';
import { VerificationData, VerificationQuery } from '@portal/verification/data';
import { Observable } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { synchronize$ } from '@libs/store';
import { first, map, switchMap } from 'rxjs/operators';
import { VerificationSectionType } from '@portal/verification/shared/enums';

export function defaultVerificationGuard(): Observable<boolean | UrlTree> {
  const verificationQuery = inject(VerificationQuery);
  const verificationCommands = inject(VerificationData);
  const router = inject(Router);

  return verificationQuery.synchronized$.pipe(
    synchronize$(verificationCommands, verificationQuery),
    first(),
    switchMap(() => verificationQuery.isKycEnable$),
    map((isKycEnable: boolean) => {
      const verificationPage = isKycEnable ? VerificationSectionType.KYC : VerificationSectionType.Basic;
      return router.createUrlTree([ 'user', 'verification', `${verificationPage}` ]);
    })
  )
}
