<div class="bonus-info" ggCreateThemeClass="bonus-info">
  <div class="bonus-title">{{bonus | bonusTitle}}</div>
  <div class="bonus-amount">
    <div class="bonus-amount-title">
      <ng-container *ngIf="isFreeSpinBonus else otherType">
          <sp-msg msg="BONUSES.BONUS_AMOUNT"/>
      </ng-container>
      <ng-template #otherType>
          <sp-msg msg="BONUSES.BONUS_AMOUNT_BONUS"/>:
      </ng-template>
    </div>
    <div class="bonus-amount-value">
      <span *ngIf="isWageringStatus else bonusGained">{{bonus.wager.account.bonus}}</span>
      <ng-template #bonusGained>{{bonus.wager.account.bonusGained}}</ng-template>
      {{currency$ | async | currencySymbol}}
    </div>
  </div>
  <gg-bonus-card-max-win [bonus]="bonus" isPreWrap="true"/>
</div>

<div class="wager-progress" ggCreateThemeClass="wager-progress">
  <div class="wager-progress-bg">

    <gg-bonus-multi-circle class="wager-progress-circle" [bonus]="bonus"/>

    <ng-container *ngIf="bonus?.status.is.success else statistic">
      <img class="wager-completed-check" [src]="'{project}/images/bonuses/{palette}/wager-completed.svg' | path"/>
    </ng-container>

    <ng-template #statistic>
      <div class="wager-info">
        <div class="wager-info-label"><sp-msg msg="BONUSES.WAGER"></sp-msg></div>
        <div class="wager-info-factor">x{{bonus?.wager.factor}}</div>
      </div>
    </ng-template>

  </div>
  <div class="wager-progress-footer" *ngIf="bonus?.wager.account as account">
    <span class="wager-progress-current">{{account.scaleCurrent}}</span>
    <span class="wager-progress-target">
      | {{account.scaleExpected}} {{currency$ | async | currencySymbol}}
    </span>
  </div>
</div>
