import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { BonusesQuery } from '@portal/bonuses/data';
import { BONUS_LIST_CONFIG, BonusListConfig, BonusListFilter } from '@portal/bonuses/shared';
import { NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gg-bonuses-list',
  templateUrl: './bonuses-list.component.html',
  styleUrls: [ 'bonuses-list.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusesListComponent implements OnInit {
  private readonly bonusesQuery = inject(BonusesQuery);

  config: Observable<BonusListConfig> = NEVER;

  @Input() filter: BonusListFilter = BonusListFilter.Available;

  ngOnInit(): void {
    const section = this.filter === BonusListFilter.Available
      ? this.bonusesQuery.availableBonuses$
      : this.bonusesQuery.activatedBonuses$;

    this.config = section.pipe(map((bonuses) => ({ bonuses, ...BONUS_LIST_CONFIG[this.filter] })));
  }
}
