<div class="payment-header">
  <div class="payment-header__button">
    <gg-button-back (click)="backPage()"></gg-button-back>
  </div>
  <div class="payment-header__content">
    <ng-content></ng-content>
  </div>
  <div class="payment-header__logo">
    <img *ngIf="secondaryLogo" class="payment-header__logo-icon" [src]="secondaryLogo" alt="payment-logo"/>
  </div>
</div>
