export enum RoyalpayPaymentMethods {
  BankCardDeposit = 40,
  BankCardDepositFs = 41,
  BankCardWithdrawal = 42,
  YandexMoneyDeposit = 50,
  YandexMoneyWithdrawal = 51,
  DepositH2H = 243,
  DepositH2HKZT = 244,
  DepositSBP = 256,
  BankCardWithdrawalNew = 260,
  QiwiCardDeposit = 266,
  QiwiDeposit = 267,
  QiwiWithdrawal = 268,
  WithdrawalSBP = 274
}
