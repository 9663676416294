<div class="menu-block" *ngFor="let section of sections">
  @for (item of section.items; track $index) {
    <a class="menu-item" routerLinkActive="active" [routerLink]="item.url" [routerLinkActiveOptions]="item.activeOptions |
     fallback: { exact: false }" (click)="close()">
      <img [src]="getImagePath(item)" class="menu-item-icon" alt=""/>
      <span class="menu-item-label" [spMsg]="item.label"></span>
      @if (item.amount) {
        <span class="menu-item-amount">{{ item.amount }}</span>
      }
      @if (item.badge) {
        <span class="menu-item-badge menu-item-badge--{{item.badge}}">{{ item.badge }}</span>
      }
    </a>
  }
  <hr *ngIf="!!section.items.length && section.hasSeparator" class="menu-block-separator"/>
</div>

