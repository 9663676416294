import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-max-win',
  templateUrl: './max-win.component.html',
  styleUrls: [ './max-win.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxWinComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus>;
  @Input() isPreWrap?: boolean;
  @Input() isLinkedBonus?: boolean;
}
