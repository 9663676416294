import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { PathPipe, ThemeModule } from '@portal/config';
import { SidebarNavigationItem, SidebarNavigationSection } from '@portal/config/shared';

@Component({
  standalone: true,
  selector: 'gg-layout-sidebar-sections',
  templateUrl: './sections.component.html',
  styleUrls: [ './sections.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, RouterLink, RouterLinkActive, ThemeModule ]
})
export class SectionsComponent {
  private readonly dialog = inject(DialogRef);

  private readonly path = inject(PathPipe);

  @Input({ required: true }) sections: Array<SidebarNavigationSection> = [];

  close(): void { this.dialog.close(); }

  getImagePath(item: SidebarNavigationItem): string {
    return item.image || this.path.transform('{project}/images/main-menu/{palette}/' + item.icon);
  }
}
