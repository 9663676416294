import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: [ './tooltip.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TooltipComponent {
  tooltip = '';
  left = 0;
  top = 0;

  get styles(): string {
    return `left:${this.left}px; top: ${this.top}px`;
  }
}
