export enum AppexMethods {
  Deposit = 202,
  Withdrawal = 203,
  DepositKZT = 204,
  WithdrawalKZT = 205,
  DepositUntrusted = 207,
  DepositH2HKtz = 394,
  DepositH2HRub = 395,
  DepositP2P = 404,
  DepositSpbQr = 405
}
