<section class="crypto-flow" *ngIf="!!cryptoFlowInfo else loader">
  <pd-payment-header
    class="cashier__header"
    [secondaryLogo]="paymentInfo.logo_secondary">
    <div class="cashier__title">{{cryptoFlowInfo.cryptoName}}</div>
    <p class="cashier__header-info">
      {{cryptoFlowInfo.cryptoCurrency}}
    </p>
  </pd-payment-header>

  <gg-bonuses-cashier-slider
    [depositAmount]="formInfo.get('amount').value"
    [maxMethodPrice]="paymentInfo.max_value"
    [isCryptoMethod]="true"
  ></gg-bonuses-cashier-slider>

  <gg-bonuses-promo-code></gg-bonuses-promo-code>

  <div class="cashier__title cashier__title--deposit">
    <sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"></sp-msg>
  </div>
  <div class="crypto-flow__info-wrapper">
    <div class="crypto-flow__info-currency">
      <img class="crypto-flow__qrcode" [src]="'data:image/png;base64,' + cryptoFlowInfo.qrcode" alt=""/>
      <div class="crypto-flow__pay-block">
        <div class="crypto-flow__currency-block">
          <p class="crypto-flow__top-text">
            1 {{cryptoFlowInfo.cryptoCurrency}}
            = {{cryptoFlowInfo.exchangeRate}}{{cryptoFlowInfo.currency | currencySymbol}}
          </p>
          <p class="crypto-flow__bottom-text">
            <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"></sp-msg>
            {{'CASHIER.METHODS.DEPOSIT' | translate | lowercase}}
            : {{cryptoFlowInfo.minDep}} {{cryptoFlowInfo.cryptoCurrency}}
          </p>
        </div>
        <div class="crypto-flow__currency-block">
          <p class="crypto-flow__top-text">
            <span>{{'CASHIER.CARD.ADDRESS' |  translate | uppercase}}</span>
          </p>
          <div class="crypto-flow__copy-block">
            <p class="crypto-flow__bottom-text crypto-flow__bottom-text--account">{{cryptoFlowInfo.account}}</p>
            <gg-clipboard-copy [copyValue]="cryptoFlowInfo.account"></gg-clipboard-copy>
          </div>
        </div>
      </div>
    </div>
    <div class="crypto-flow__info-text">
      <div class="crypto-flow__info-banner">
        <img class="crypto-flow__info-img" [src]="'{project}/images/icons/info-icon.svg' | path" alt="info-icon"/>
        <p><sp-msg [spMsg]="'CASHIER.CARD.CRYPTO_INFO1'" [params]="{currency:
        cryptoFlowInfo.cryptoCurrency}"></sp-msg></p>
      </div>
      <div
        *ngIf="isTimer else cryptoBannerInfo"
        class="crypto-flow__info-banner crypto-flow__info-banner--timer">
        <div class="crypto-flow__info-banner-timer-content">
          <span spMsg="CASHIER.CARD.TIME_LEFT"></span>
          <div class="crypto-flow__timer">{{timer ? timer : '-'}}</div>
        </div>
        <span spMsg="CASHIER.CARD.WALLET_LIFE_INFO2"></span>
      </div>
      <ng-template #cryptoBannerInfo>
        <div class="crypto-flow__info-banner crypto-flow__info-banner--bottom-right">
          <img class="crypto-flow__info-img" [src]="'{project}/images/icons/info-icon.svg' | path" alt="info-icon"/>
          <p><sp-msg spMsg="CASHIER.CARD.CRYPTO_INFO2"></sp-msg></p>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<ng-template #loader></ng-template>
