import {
  Component,
  ElementRef,
  EventEmitter,
  inject, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { synchronize } from '@libs/store';
import { Nullable } from '@libs/utils';
import { CountriesData, CountriesQuery, Country, SearchCountryBy } from '@portal/countries';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

enum OptionsState {
  Opened = 'opened',
  Closed = 'closed'
}

@Component({
  selector: 'gg-country-code-selector',
  templateUrl: 'country-code-selector.component.html'
})
export class CountryCodeSelectorComponent implements OnInit, OnDestroy {
  private readonly query = inject(CountriesQuery);
  private readonly commands = inject(CountriesData);

  private readonly optionsShown$: BehaviorSubject<OptionsState> = new BehaviorSubject<OptionsState>(OptionsState.Closed);

  private searchControlSub!: Subscription;

  readonly searchControl = new FormControl();

  originalList: Array<Country> = [];
  filteredList: Array<Country> = [];
  selectedCountry: Nullable<Country>;

  @Input() initialValue: Nullable<string>;
  @Output() selected$: EventEmitter<Country> = new EventEmitter();

  @ViewChild('search') searchEl: Nullable<ElementRef> = null;

  get isOpened(): boolean {
    return OptionsState.Opened === this.optionsShown$.getValue();
  }

  ngOnInit(): void {
    this.searchControlSub = this.searchControl.valueChanges.subscribe(this.search.bind(this));

    synchronize(this.commands, this.query);

    combineLatest([ this.query.country$, this.query.countries$ ]).subscribe(([ country, countries ]) => {
      const searchPredicate = this.initialValue?.startsWith('+')
        ? (c: Country): boolean | undefined => this.initialValue?.startsWith(c.phonePrefix)
        : (c: Country): boolean => c.isoCode === country;

      const selected = countries.find(searchPredicate);

      this.filteredList = countries;
      this.originalList = countries;
      this.selectedCountry = selected;

      if (selected) this.selected$.emit(selected);
    });
  }

  ngOnDestroy(): void {
    !!this.searchControlSub && this.searchControlSub.unsubscribe();
  }

  toggleOptions(): void {
    this.optionsShown$.next(this.isOpened ? OptionsState.Closed : OptionsState.Opened);
  }

  selectCountry(country: Country): void {
    this.optionsShown$.next(OptionsState.Closed);
    this.selectedCountry = country;
    this.selected$.emit(country);
  }

  private find(search: Nullable<string>, by: SearchCountryBy): Nullable<Country> | Array<Country> {
    if (!search) { return this.originalList[0]; }

    search = search.toLowerCase();

    switch (by) {
      case SearchCountryBy.Code:
        return this.originalList.find(country => country.phonePrefix.includes(search as string));
      case SearchCountryBy.ShortCode:
        return this.originalList.find(country => country.isoCode.toLowerCase() === search);
      case SearchCountryBy.Name:
        return this.originalList.filter(country => country.name.toLowerCase().startsWith(search as string));
    }
  }

  private search(countrySearch: string): void {
    const countries = countrySearch.length > 0
      ? this.find(countrySearch, SearchCountryBy.Name) as Array<Country>
      : this.originalList;

    this.filteredList = countries;
  }
}
