import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import moment, { Moment } from 'moment';
import 'moment-duration-format';
import { Observable, timer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

type Timer = Record<'days' | 'hours' | 'minutes' | 'seconds', string>;
const EXPIRED_TIMER: Timer = { days: '00', hours: '00', minutes: '00', seconds: '00' };

@Component({
  standalone: true,
  imports: [ SpCDKModule, ThemeModule ],
  selector: 'gg-timer',
  templateUrl: './timer.component.html',
  styleUrls: [ './timer.component.scss', './silver.timer.component.scss', './purple.timer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent {
  readonly timer$: Observable<Timer>;

  @Input() header: string = '';
  @Input() expiredDate: Nullable<Moment> = null;

  @Output() expired: EventEmitter<null> = new EventEmitter();

  constructor() {
    this.timer$ = timer(1000, 1000).pipe(startWith(0), this.createTimerValues());
  }

  private createTimerValues(): (source$: Observable<number>) => Observable<Timer> {
    return (source$) => source$.pipe(
      map(() => {
        if (!this.expiredDate) { return EXPIRED_TIMER; }

        const duration = moment.duration(this.expiredDate.diff(moment()), 'milliseconds');
        const diff = (duration as any).format('DD:HH:mm:ss', { trim: false });

        if (this.expiredDate.diff(moment.now()) <= 150) { this.expired.emit(); }

        const [ days, hours, minutes, seconds ] = diff.split(':');

        return { days, hours, minutes, seconds };
      })
    );
  }

}
