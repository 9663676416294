import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';
import { AccordionComponent } from '@portal/verification/components/card/info-accordion/accordion.component';

@Component({
  standalone: true,
  imports: [SpCDKModule, ControlsModule, AccordionComponent],
  selector: 'gg-verification-upload-card-info',
  templateUrl: './upload-card-info.component.html',
  styleUrls: ['./upload-card-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadCardInfoComponent {}
