import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { synchronize$ } from '@libs/store';
import { Nullable, OrArray } from '@libs/utils';
import { CountriesData, CountriesQuery, Country } from '@portal/countries';
import { ControlsModule } from '@portal/shared/components';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { map, Observable, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-payment-country-selector',
  templateUrl: './payment-country-selector.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PaymentCountrySelectorComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCountrySelectorComponent extends InputAbstract implements OnInit {
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly query = inject(CountriesQuery);
  private readonly commands = inject(CountriesData);
  private readonly selectedCountry = this.query.country$;

  readonly countriesList$ = this.selectedCountry.pipe(this.selectCountries$());
  readonly country = new FormControl();

  @Input() icons = false;
  @Input() paymentMethodId: Nullable<string>;

  override ngOnInit(): void {
    this.country.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((v) => this.writeValue(v));
  }

  private selectCountries$(): (source$: Observable<Nullable<string>>) => Observable<Array<IInputSelectData>> {
    return (source$) => source$.pipe(
      synchronize$(this.commands, this.query),
      switchMap(() => this.query.countries$.pipe(
        switchMap((countries) => this.checkCountryListForPayments(countries)),
        map((countries) => this.mapCountryToSelectOption(countries)),
      ))
    );
  }

  private checkCountryListForPayments(countries: Array<Country>): Observable<Array<Country>> {
    return this.commands.loadCountriesListForPayments(this.paymentMethodId).pipe(
      map((countriesList) => {
        if (!Array.isArray(countriesList) || countriesList.length === 0) { return countries; }
        return countries.filter(country => countriesList.includes(country.isoCode));
      })
    );
  }

  private mapCountryToSelectOption(country: Array<Country>): Array<IInputSelectData>;
  private mapCountryToSelectOption(country: Country): IInputSelectData;
  private mapCountryToSelectOption(country: OrArray<Country>): OrArray<IInputSelectData> {
    if (Array.isArray(country)) { return country.map((c) => this.mapCountryToSelectOption(c)); }
    return { value: country.isoCode, description: country.name, icon: this.icons ? country.src : null };
  }
}
