import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CashierDataService, PaymentMethodsQuery } from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PaymentMethodsGuard implements CanActivate {
  constructor(
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly cashierCommands: CashierDataService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.paymentMethodsQuery.synchronized$.pipe(
      tap(() => this.cashierCommands.synchronize()),
      map(() => true)
    );
  }
}
