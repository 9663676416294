import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { OrArray } from '@libs/utils';
import { PaymentState, PaymentStore } from '@portal/payment/data';
import { HistoryParams, PaymentParams, WidgetParams } from '@portal/payment/shared';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentParamsQuery extends SteroidQuery<PaymentState> {
  readonly params$ = this.select('params');
  readonly section$ = this.params$.pipe(map(({ section }) => section));

  readonly historyParams$ = this.params$.pipe(this.getParamsBySection<HistoryParams>('history'));
  readonly paymentParams$ = this.params$.pipe(this.getParamsBySection<PaymentParams>([ 'deposit', 'withdrawal' ]));

  readonly isDeposit$ = this.paymentParams$.pipe(map(({ section }) => section === 'deposit'));

  get section(): WidgetParams['section'] { return this.get('params').section; }
  get isWithdrawal(): boolean { return this.get('params').section === 'withdrawal'; }

  constructor(protected store: PaymentStore) { super(store); }

  private getParamsBySection<T extends WidgetParams>(section: OrArray<WidgetParams['section']>): (params$: Observable<WidgetParams>) => Observable<T> {
    return params$ => params$.pipe(
      filter((params): params is T => Array.isArray(section) ? section.includes(params.section) : params.section === section)
    );
  }
}
