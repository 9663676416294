import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { createPaymentContainer, PaymentParamsData, PaymentStore } from '@portal/payment/data';
import { PaymentGroupsResponse, PaymentOperation } from '@portal/payment/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentGroupsData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(PaymentStore);
  private readonly paramsData = inject(PaymentParamsData);

  loadPreferredMethods(section?: PaymentOperation): Observable<unknown> {
    return section
      ? this.loadPreferredMethod(section)
      : forkJoin([ this.loadPreferredMethod('deposit'), this.loadPreferredMethod('withdrawal') ]);
  }

  private loadPreferredMethod(section: PaymentOperation): Observable<unknown> {
    const sectionType = section === 'withdrawal' ? 'withdraw' : 'deposit';
    return this.http.get<PaymentGroupsResponse>(`/user_payments/user_preferred_payment_methods?operation_type=${sectionType}`).pipe(
      tap(({ _items, _meta: { currencySteps, defaultAmount } }) => {
        this.store.update({
          container: { ...this.store.get('container'), [ section ]: _items.map(createPaymentContainer) },
          amountSteps: currencySteps,
          defaultAmount: Number(defaultAmount),
        });
      }),
      tap(() => this.paramsData.setPayment()),
      catchError((error) => throwError(() => ResponseFactory.error(error))));
  }
}
