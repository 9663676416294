import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ActivatedBonus } from '@portal/bonuses/data';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, BonusPipesModule, ThemeModule ],
  selector: 'gg-bonus-activated-dialog',
  templateUrl: './bonus-activated-dialog.component.html',
  styleUrls: [ './bonus-activated-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusActivatedDialogComponent {
  private readonly dialog = inject(DialogRef);
  private readonly activatedBonus = inject(DIALOG_DATA);

  readonly backButton: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Medium };
  readonly bonus: Nullable<ActivatedBonus> = this.activatedBonus;

  close(withRedirect = false): void {
    this.dialog.close(withRedirect);
  }
}
