import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { ErrorComponent } from '@portal/verification/components/documents-upload/error/error.component';
import { ProgressComponent } from '@portal/verification/components/documents-upload/progress/progress.component';
import { UploadedDocsComponent } from '@portal/verification/components/documents-upload/uploaded-docs/uploaded-docs.component';
import { SpCDKModule } from '@libs/cdk';
import { DocumentUpload } from '@portal/verification/shared';

@NgModule({
  imports: [ SpCDKModule, MomentModule ],
  declarations: [ UploadedDocsComponent, ProgressComponent, ErrorComponent ],
  exports: [ ProgressComponent, ErrorComponent, UploadedDocsComponent ],
  providers: [ DocumentUpload ],
})
export class DocumentsUploadModule {}
