import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params, UrlSegment } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { SpLocalization, SpStorage } from '@libs/cdk';
import { errorIf, excludeNullish, Nullable } from '@libs/utils';
import { USER_AGENT } from '@ng-web-apis/common';
import {
  createRegistrationConfig,
  PhoneNumberDto,
  ReferralData,
  ReferralQuery,
  RegistrationConfig,
  RegistrationStepChangedDto,
  SetNewPasswordDto,
  VerifyCredentialsDto,
  VerifyOtpDto,
  VerifyPhoneDto
} from '@portal/authorization/data';
import {
  AutologinResponse,
  CodeSentResponse,
  createEmailRecoveryForm,
  createPhoneRecoveryForm,
  createPhoneVerificationForm,
  EmailCheckResponse,
  getReturnPathSegment,
  ReferralObject,
  RegistrationByStepResponse,
  RegistrationConfigResponse,
  VerifyCredentialsResponse,
  VerifyOtpResponse,
  VerifyPhoneResponse
} from '@portal/authorization/shared';
import { ConfigQuery } from '@portal/config';
import { ResponseFactory } from '@portal/shared/helpers';
import { StorageKey } from '@portal/shared/types';
import { UserQuery } from '@portal/user';
import { catchError, Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthorizationData {
  private readonly http = inject(HttpClient);
  private readonly storage = inject(SpStorage);
  private readonly languages = inject(SpLocalization).list;
  private readonly paymentWidgetEnable = inject(UserQuery).blocking?.paymentsWidget;
  private readonly refData = inject(ReferralData);
  private readonly refQuery = inject(ReferralQuery);
  private readonly authorization = inject(SpAuthorizationService);
  private readonly location = inject(Location);
  private readonly ua = inject(USER_AGENT);
  private readonly is2faMethod = inject(ConfigQuery).modules.settings.enableYa2fa;

  requestPhoneVerification(credentials: PhoneNumberDto): Observable<unknown> {
    return this.http.post<CodeSentResponse>('/api/2/phone/send_code/', credentials).pipe(
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  checkEmailExists(token: string): Observable<unknown> {
    return this.http.post<EmailCheckResponse>('/api/2/auth/email/check/', { socialAuthToken: token }).pipe(
      errorIf(({ _item }) => _item.available)
    );
  }

  loadRegistrationFlowConfig(country?: Nullable<string>): Observable<RegistrationConfig> {
    country = country ? `?country=${country.toUpperCase()}` : '';

    return this.http.get<RegistrationConfigResponse>(`/api/2/auth/registration/${country}`).pipe(
      map((response) => createRegistrationConfig(response._items[ 0 ]))
    );
  }

  verifyCredentials(credentials: Omit<VerifyCredentialsDto, keyof ReferralObject>): Observable<UrlSegment> {
    const body = excludeNullish({ ...credentials, ...this.refQuery.info });

    return this.http.post<VerifyCredentialsResponse>('/api/2/auth/login/', body).pipe(
      map((response) => ('accessToken' in response._item)
        ? this.authorize(response)
        : new UrlSegment('/auth/login/otp', response._item)
      ),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyOtp(credentials: Omit<VerifyOtpDto, keyof ReferralObject>): Observable<UrlSegment> {
    const body = excludeNullish({ ...credentials, ...this.refQuery.info, ua: this.ua });

    return this.http.post<VerifyOtpResponse>('/api/2/auth/2fa/code/check/', body).pipe(
      map((response) => this.authorize(response)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyPhone(credentials: Omit<VerifyPhoneDto, keyof ReferralObject>): Observable<UrlSegment> {
    const body = excludeNullish({ ...credentials, ...this.refQuery.info });

    return this.http.post<VerifyPhoneResponse>('/api/2/auth/register/', body).pipe(
      map((response) => this.authorize(response)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyRegistrationStep(credentials: Record<string, unknown>): Observable<UrlSegment | RegistrationStepChangedDto> {
    const { type, stage, ...rest } = credentials;
    const data = excludeNullish({ ...rest, ...this.refQuery.info });

    return this.http.post<RegistrationByStepResponse>('/api/2/auth/registration', { type, stage, data }).pipe(
      map((response) => {
        return 'accessToken' in response._item ? this.authorize(response) : response._item;
      }),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifySocialToken({ status, authToken: token }: Params): Observable<UrlSegment> {
    if (status !== 'success') {
      return throwError(() => ({ error: { code: 'NOT_SUCCESS_STATUS' } }));
    }

    const body = excludeNullish({ token, ...this.refQuery.info });

    return this.http.post<AutologinResponse>('/api/2/auth/social/', body).pipe(
      map((response) => this.authorize(response)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyAutologinToken(token: string): Observable<UrlSegment> {
    return this.http.post<AutologinResponse>('/api/2/auth/autologin/', { token, ua: this.ua }).pipe(
      map((response) => this.authorize(response)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  recoverEmail(credentials: ReturnType<typeof createEmailRecoveryForm>['value']): Observable<unknown> {
    return this.http.post('/api/2/auth/password/recover/send/', excludeNullish(credentials)).pipe(
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  sendRemindPhoneCode(credentials: ReturnType<typeof createPhoneRecoveryForm>['value']): Observable<unknown> {
    return this.http.post('/api/2/auth/password/recover/send/', excludeNullish(credentials)).pipe(
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  recoverPhone(credentials: ReturnType<typeof createPhoneVerificationForm | typeof createPhoneRecoveryForm>['value']): Observable<UrlSegment> {
    return this.http.post<AutologinResponse>('/api/2/auth/password/recover/url/', excludeNullish(credentials)).pipe(
      map((response) => {
        if ('accessToken' in response._item) {
          return this.authorize(response);
        } else if (this.is2faMethod) {
          return new UrlSegment('/auth/login/otp', response._item);
        } else {
          return new UrlSegment('/auth/login', response._item);
        }
      }),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  saveRecoveredPassword(credentials: SetNewPasswordDto): Observable<UrlSegment> {
    return this.http.post<AutologinResponse>('/api/2/auth/password/recover/validate/', excludeNullish(credentials)).pipe(
      map((response) => {
        if ('accessToken' in response._item) {
          return this.authorize(response);
        } else if (this.is2faMethod) {
          return new UrlSegment('/auth/login/otp', response._item);
        } else {
          return new UrlSegment('/auth/login', response._item);
        }
      }),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  unauthorize(): Observable<UrlSegment> {
    return this.http.post('/api/2/auth/logout/', {}).pipe(
      tap(() => this.storage.removeItem(StorageKey.Refresher)),
      map(() => new UrlSegment('', {})),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  private authorize(response: VerifyCredentialsResponse | VerifyOtpResponse | AutologinResponse | RegistrationByStepResponse | VerifyPhoneResponse): UrlSegment {
    const authorized = response._item;

    if ('bonus' in authorized) {
      authorized.bonus?.gameName && this.storage.setItem(StorageKey.Game, authorized.bonus.gameName);
      authorized.bonus?.gameSlug && this.storage.setItem(StorageKey.ReturnPath, `/slots/${authorized.bonus.gameSlug}`);
    }

    const path = this.storage.getItem(StorageKey.ReturnPath);
    this.storage.removeItem(StorageKey.ReturnPath);
    this.refData.clear();
    this.authorization.set(response);
    return getReturnPathSegment(path, this.location.path(), this.languages, this.paymentWidgetEnable);
  }
}
