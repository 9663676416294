import { inject, Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { CurrencyNormalizerPipe } from '@portal/currencies/application';
import { CurrencySymbol } from '@portal/currencies/shared';
import { UserQuery } from '@portal/user';

@Pipe({ name: 'priceNormalizer' })
export class PriceNormalizerPipe implements PipeTransform {
  private readonly currency = inject(UserQuery).currency;
  private readonly normalizer = inject(CurrencyNormalizerPipe);

  transform(value: Nullable<number | string>, currency?: Nullable<CurrencySymbol>): string {
    currency = currency || this.currency;

    if (typeof value === 'string' && isNaN(Number(value))) { return value; }

    const price = typeof value === 'string' ? value : (Number(value) / 100).toFixed(4);
    const fractionalPrice = price.replace('.', ',') || '';
    const priceWithCurrency: `${string} ${Nullable<CurrencySymbol>}` = `${fractionalPrice} ${currency}`;

    return this.normalizer.transform(priceWithCurrency);
  }
}
