import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, watch } from '@libs/utils';
import { LoaderComponent } from '@portal/payment/components';
import { PaymentStatus, PostActionAbstract } from '@portal/payment/shared';
import { filter, Observable, of, switchMap, throwError, timer } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, LoaderComponent ],
  template: `
    <gg-payment-loader/>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDsPollingActionComponent extends PostActionAbstract implements OnInit, OnDestroy {
  private readonly watcher = watch();

  ngOnInit(): void {
    const { initiatorId } = this.result?.data || {};
    if (this.processErrors(!initiatorId)) { return; }

    this.watcher.add(this.check3ds(initiatorId).subscribe({
      next: (status) => this.setStatus({ status }),
      error: () => this.setError(),
    }));
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  private check3ds(initiatorID: Nullable<string>): Observable<PaymentStatus> {
    const update = this.paymentData.update({ initiatorID });

    return timer(0, 2000).pipe(
      switchMap(() => this.paymentData.check3ds(this.result.data.initiatorId, this.result.data.actual_payment_id?.toString())),
      filter(({ url, status, value }) => !this.processUrl(url) && (status === 'error' || !!value)),
      switchMap(({ status }) => status !== 'error' ? update : throwError(() => of(status))),
    );
  }
}
