import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  inject,
  Optional,
  Output,
  QueryList,
  Self
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { ControlAdapter, Nullable } from '@libs/utils';
import { RadioButtonComponent } from 'apps/portal/src/app/shared/components/controls-reimplement/radio/radio-button.component';
import { merge } from 'rxjs';

@Component({
  selector: 'gg-radio-group',
  templateUrl: './radio-group.component.html',
  // styleUrls: [ './radio-group.components.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent<T = unknown> implements AfterContentInit {
  private readonly adapter: ControlAdapter<T> = new ControlAdapter();
  private readonly destroyRef = inject(DestroyRef);

  @Output() readonly clicked: EventEmitter<T> = new EventEmitter();

  @ContentChildren(RadioButtonComponent) buttons: Nullable<QueryList<RadioButtonComponent>> = null;

  constructor(
    private readonly cd: ChangeDetectorRef,
    @Optional() @Self() private readonly ngControl: NgControl
  ) {
    if (!this.ngControl) { return; }
    this.ngControl.valueAccessor = this.adapter.accessor;
    this.adapter.registerOnModelChange((value) => { this.setActiveButton(value); });
  }

  ngAfterContentInit(): void {
    this.ngControl && this.setActiveButton(this.ngControl.value);

    const requests$ = this.buttons?.map((button) => button.clicked) || [];
    merge(...requests$).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (value) => this.setActiveButton(value as T, true)
    );
  }

  private setActiveButton(activeValue: T, emitEvent = false): void {
    if (this.buttons?.find(({ value }) => value === activeValue)?.active) { return; }
    this.buttons?.forEach((button) => {
      button.active = button.value === activeValue;
      button.update();
    });
    emitEvent && this.clicked.emit(activeValue);
    this.adapter.touch();
    this.adapter.change(activeValue);
  }
}
