import { NgModule } from '@angular/core';
import { SettingsModule } from '@portal/user/pages/settings/settings.module';
import { UserRoutingModule } from './user.routing';

@NgModule({
  imports: [
    SettingsModule,
    UserRoutingModule
  ]
})
export class UserModule {}
