import { Game } from '@portal/games/data';
import { GameSourceInfo } from '@portal/games/shared';

export function findGamesBySource(games: Array<Game>, source: GameSourceInfo): Array<Game> {
  const filteredGames = games.filter((game) => source.key in game.source)
    .sort((game, comparer) => {
      if (game.source[source.key] < comparer.source[source.key]) { return -1; }
      if (game.source[source.key] > comparer.source[source.key]) { return 1; }
      return 0;
    });
  return [ ...new Set(filteredGames) ];
}
