import { UserBankCardDto } from '@portal/cashier/data';

export class BankCard {
  constructor(
    readonly id: string,
    readonly mask: string
  ) {}

  static create(bankCard: UserBankCardDto): BankCard {
    return new BankCard(bankCard.id, bankCard.mask);
  }
}
