import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { Game, GameProvider, GameSection } from '@portal/games/data';
import { GameSourceInfo } from '@portal/games/shared';

export type GamesState = {
  sync: boolean;
  total: Record<GameSourceInfo['key'], number>;
  limit: Record<GameSourceInfo['key'], number>;
  providers: Array<GameProvider>;
  sections: Array<GameSection>;
  recent: Array<Game>;
  currentGame: Nullable<Game>;
  entities: { games: SteroidEntity<Game> };
};

const initialState: SteroidConfig<GamesState> = {
  options: { name: 'games' },
  initial: {
    sync: false,
    total: {},
    limit: {},
    providers: [],
    sections: [],
    recent: [],
    currentGame: null
  },
  entities: { games: { idKey: 'id' } }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'games', resettable: true })
export class GamesStore extends SteroidStore<GamesState> {
  constructor() { super(initialState); }
}
