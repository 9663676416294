import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpCommonModule } from 'libs/cdk/src/lib/common';
import { Observable, timer } from 'rxjs';
import { map, scan, startWith, takeWhile, tap } from 'rxjs/operators';
import { CountdownType } from './types';

@Component({
  standalone: true,
  imports: [ SpCommonModule ],
  selector: 'gg-countdown',
  templateUrl: 'countdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit {
  timer$: Observable<CountdownType> = new Observable<CountdownType>();

  @Input() countdownTime: number = 0; /* millisecond */
  @Output() timeExit = new EventEmitter();

  ngOnInit(): void {
    this.timer$ = timer(0, 1000).pipe(
      map(() => -1000),
      scan((acc, cur) => acc + cur, this.countdownTime),
      tap((val) => val <= 0 && this.timeExit.emit()),
      takeWhile((value) => value >= 0),
      map((time) => this.calcTimeDiff(time)),
      startWith(this.calcTimeDiff(this.countdownTime))
    );
  }

  private calcTimeDiff(millisecondTime: number): CountdownType {
    let seconds = Math.floor(millisecondTime / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return {
      seconds: this.padTo2Digits(seconds),
      minutes: minutes ? this.padTo2Digits(minutes) : '',
      hours: hours ? this.padTo2Digits(hours) : ''
    };
  }

  private padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
