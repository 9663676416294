import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const accessBonusGuard = (): Observable<boolean | UrlTree> => {
  const config = inject(ConfigQuery).modules.access.blockedSectionRedirectionPaths.bonus;
  const blockedRoute = inject(Router).createUrlTree([ config ]);

  return inject(UserQuery).blocking$.pipe(
    map((blocking) => !blocking?.bonuses || blockedRoute)
  );
};
