import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { PaymentGroupsData, PaymentGroupsQuery, PaymentParamsData } from '@portal/payment/data';
import { Payment, PaymentBuilder } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, RouterModule ],
  templateUrl: './success.component.html',
  styleUrls: [ './success.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessStatusComponent {
  private readonly destroy = inject(DestroyRef);
  private readonly configQuery = inject(ConfigQuery);
  private readonly builder = inject(PaymentBuilder);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly groupsData = inject(PaymentGroupsData);
  private readonly groupsQuery = inject(PaymentGroupsQuery);

  private readonly payment$ = this.builder.payment$;

  readonly operation$ = this.payment$.pipe(map(({ operation }) => operation));
  readonly isPartialWithdraw$ = this.payment$.pipe(this.checkIsWithdrawParted());
  readonly isCashWithdraw$ = this.payment$.pipe(this.checkIsWithdrawByCash());

  readonly okButton: IButton = { size: ButtonSizes.Medium };
  readonly withdrawalButton: IButton = { size: ButtonSizes.Large };
  readonly sections = this.configQuery.modules.cashier.buttonsOnStatusPage;

  goToWithdrawal(): void { this.paramsData.setHistory({ show: 'withdrawal' }); }

  close(): void { this.paramsData.clear(); }

  private checkIsWithdrawParted(): (source$: Observable<Payment>) => Observable<boolean> {
    return (source$) => source$.pipe(map(({ method, amount }) => {
      return !!method?.amounts?.maxWithdrawal && !!amount && method.amounts.maxWithdrawal < amount;
    }));
  }

  private checkIsWithdrawByCash(): (source$: Observable<Payment>) => Observable<boolean> {
    return (source$) => source$.pipe(map(() => {
      return false; // TODO: NEED TO CHECK METHOD FOR CACTUS CASH
    }));
  }

}
