import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';

@Component({
  selector: 'gg-bonus-card-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() bonus: Nullable<Bonus> = null;
  @Input() isUseNewIcon = false;

  get isContainer(): boolean {
    return !!this.bonus && 'bonuses' in this.bonus;
  }
}
