import { EventEmitter, inject, Injectable } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Observable, Subject } from 'rxjs';
import { INNER_OPTIONS } from './simple-notifications.module';
import { DEFAULT_ICONS, Icons, Notification, NotificationEvent, NotificationType } from './utils';

@Injectable()
export class NotificationsService {
  private readonly emitter: Subject<NotificationEvent> = new Subject();

  readonly emitter$: Observable<NotificationEvent> = this.emitter.asObservable();
  readonly globalOptions = inject(INNER_OPTIONS);

  icons: Nullable<Icons> = DEFAULT_ICONS;

  set(notification: Notification, to: boolean): Notification {
    notification.id = notification?.override?.id
      ? notification.override.id
      : Math.random().toString(36).substring(3);
    notification.click = new EventEmitter<object>();
    notification.clickIcon = new EventEmitter<object>();
    notification.timeoutEnd = new EventEmitter<object>();

    this.emitter.next({ command: 'set', notification, add: to });
    return notification;
  }

  success(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Success,
      icon: this.icons?.success || '',
      override,
      context
    }, true);
  }

  error(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Error,
      icon: this.icons?.error || '',
      override,
      context
    }, true);
  }

  alert(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Alert,
      icon: this.icons?.alert || '',
      override,
      context
    }, true);
  }

  info(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Info,
      icon: this.icons?.info || '',
      override,
      context
    }, true);
  }

  warn(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Warn,
      icon: this.icons?.warn || '',
      override,
      context
    }, true);
  }

  bare(title: string = '', content: string = '', override?: string, context?: string): Notification {
    return this.set({
      title,
      content: content || '',
      type: NotificationType.Bare,
      icon: 'bare',
      override,
      context
    }, true);
  }

  // With type method
  create(title: string = '', content: string = '', type = NotificationType.Success, override?: string, context?: string): Notification {
    return this.set({ title, content, type, icon: (this.icons as any)[type], override, context }, true);
  }

  // HTML Notification method
  html(html: string, type = NotificationType.Success, override?: string, icon = 'bare', context?: string): Notification {
    return this.set({ html, type, icon: (this.icons as any)[icon], override, context }, true);
  }

  // Remove all notifications method
  remove(id?: string): void {
    const cmd = id ? { command: 'clean', id } : { command: 'cleanAll' };
    this.emitter.next(cmd);
  }
}
