import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ContentModule, ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, ContentModule ],
  selector: 'gg-verification-info-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
  readonly accordionItems: Array<Record<string, string>> = [
    {
      header: 'PROFILE.CARD_VERIFICATION.ACCORDION_HEADER_FIRST',
      body: 'PROFILE.CARD_VERIFICATION.ACCORDION_BODY_FIRST'
    },
    {
      header: 'PROFILE.CARD_VERIFICATION.ACCORDION_HEADER_SECOND',
      body: 'PROFILE.CARD_VERIFICATION.ACCORDION_BODY_SECOND'
    }
  ];
}
