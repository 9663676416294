import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { RecaptchaConfig } from '@portal/recaptcha/data';

export type RecaptchaState = {
  config: Nullable<RecaptchaConfig>;
  sync: boolean;
};

const initial: SteroidConfig<RecaptchaState> = {
  options: { name: 'recaptcha' },
  initial: { config: null, sync: false }
};

@Injectable({ providedIn: 'root' })
export class RecaptchaStore extends SteroidStore<RecaptchaState> {
  constructor() { super(initial); }
}
