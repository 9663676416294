import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { SpPlatform } from '@libs/cdk';
import { ThemeContainer } from '@portal/config';
import { LayoutData } from '@portal/layout/data';
import { HeaderContext } from '@portal/layout/shared';
import { map, shareReplay } from 'rxjs/operators';
import { HeaderFlatComponent, HeaderRoundedComponent } from './theme';

@Component({
  selector: 'gg-layout-header',
  template: '<gg-themed-component [config]="config" [context]="context$"/>',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends ThemeContainer<HeaderContext> implements OnInit {
  private readonly platform = inject(SpPlatform);
  private readonly layoutData = inject(LayoutData);
  private readonly authorization = inject(SpAuthorizationService);
  private readonly breakpoints = inject(BreakpointObserver);

  protected override readonly config = { rounded: HeaderRoundedComponent, flat: HeaderFlatComponent };
  protected readonly menu$ = this.breakpoints.observe('(min-width: 768px)').pipe(
    map(({ matches }) => matches),
    shareReplay({ refCount: true })
  );

  constructor() {
    super({ authorization: false, balance: false, rewards: false });
    this.updateContext({ showSidebar: (): void => this.layoutData.toggleSidebar(true) });
  }

  ngOnInit(): void {
    const authorization = !this.authorization.authorized && this.platform.browser;
    const rewards = this.platform.browser;
    const balance = this.authorization.authorized;
    const menu = this.menu$;

    this.updateContext({ ...this.currentContext, authorization, rewards, balance, menu });
  }
}
