import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { UserInfo } from '@portal/user/data';

export type UserState = Partial<UserInfo>;

const initial: SteroidConfig<UserState> = {
  options: { name: 'user' },
  initial: {}
};

@Injectable({ providedIn: 'root' })
export class UserStore extends SteroidStore<UserState> {
  constructor() {super(initial);}
}
