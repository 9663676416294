import { Validators } from '@angular/forms';
import { USER_DATA_INFO } from './user-data-form-declaration.constant';

export const USER_DATA_FORM_MUCHBETTER_PAYPER = {
  ...USER_DATA_INFO,
  state: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ]
};
