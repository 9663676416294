<div
  class="item"
  ggCreateThemeClass="item-theme"
  [id]="paymentMethod.id"
  [ngClass]="paymentMethod.name | translate"
  [class.item_recommended]="isRecommended"
  [class.item_popular]="isPopular"
  [class.item_international]="isInternational"
>
  <div class="item__special-border item__special-border_recommended" *ngIf="isRecommended">
    <img src="/assets/images/cashier/star.svg" alt=""/>
    <sp-msg msg="CASHIER.RECOMMENDED"></sp-msg>
  </div>

  <div class="item__special-border item__special-border_popular" *ngIf="isPopular">
    <img src="/assets/images/cashier/like.svg" alt=""/>
    <sp-msg msg="CASHIER.POPULAR"></sp-msg>
  </div>

  <div class="item__special-border item__special-border_international" *ngIf="isInternational">
    <img src="/assets/images/cashier/world.svg" alt=""/>
    <sp-msg msg="CASHIER.INTERNATIONAL_CARDS"></sp-msg>
  </div>

  <div class="item__title" *ngIf="isShowTitle()"
       [spMsg]="paymentMethod.name | translate"></div>
  <img class="item__icon {{paymentMethod.name | translate}}" alt="payment-logo" [src]="paymentMethod.logo"
       [ngClass]="addIconClass(paymentMethod.logo)"/>
  <p class="item__cashier-info" *ngIf="paymentMethod.category !== 'cryptocurrency' || section !== 'deposit'">
    <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"></sp-msg>
    {{ paymentMethod.min_value | number:'1.0-0' }} {{ currency$ | async | currencySymbol }}
    |
    <sp-msg spMsg="CASHIER.CARD.MAX" spaces="wrap"></sp-msg>
    {{ paymentMethod.max_value | number:'1.0-0' }} {{ currency$ | async | currencySymbol }}
  </p>
</div>
