<div class="bonuses-list-wrapper" *ngIf="filter && config | async as config">
  <div class="bonuses-list available-bonuses" *ngIf="config.bonuses.length as bonusesCount">

    <div *ngIf="config.title as title" class="bonuses-list-title">
      <sp-msg [msg]="title" spaces="end"></sp-msg>
      <span class="bonuses-count">{{bonusesCount}}</span>
    </div>
    <div class="swiper-container">
      <swiper-container ggSwiper [config]="config.slider" init="false" class="swiper-wrapper">
        <swiper-slide *ngFor="let bonus of config.bonuses" class="swiper-slide">
          <gg-bonus-card [bonus]="bonus"/>
        </swiper-slide>
      </swiper-container>
      <div class="{{filter}}-prev-btn slider-arrow slider-prev-button">
        <img [src]="'{project}/images/slider/prev.svg' | path" alt=""/>
      </div>
      <div class="{{filter}}-next-btn slider-arrow slider-next-button">
        <img [src]="'{project}/images/slider/next.svg' | path" alt=""/>
      </div>
    </div>

  </div>
</div>
