import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, watch } from '@libs/utils';
import { PostActionAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { switchMap } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './code-dialog.component.html',
  styleUrl: './code-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule ]
})
export class CodeDialogActionComponent extends PostActionAbstract implements OnInit, OnDestroy {
  private readonly dialog = inject(Dialog);
  private readonly watcher = watch();

  private ref: Nullable<DialogRef<Nullable<string>>> = null;

  code: Nullable<string> = null;

  @ViewChild('tpl', { static: true, read: TemplateRef }) tpl: Nullable<TemplateRef<unknown>> = null;

  ngOnInit(): void {
    const { initiatorId, transaction_id, url } = this.result.data || {};
    if (this.processErrors(!url, !initiatorId && !transaction_id) || this.processUrl(url)) { return; }
    this.createDialog(initiatorId, transaction_id);
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  close(code?: string): void { this.ref?.close(code || null); }

  private createDialog(initiatorID: Nullable<string>, transaction_id: Nullable<string>): void {
    this.ref = this.tpl ? this.dialog.open(this.tpl, { panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ] }) : null;

    this.watcher.add(this.ref?.closed.pipe(
      switchMap((code: Nullable<string>) => this.paymentData.update({ initiatorID, code, transaction_id }))
    ).subscribe((status) => this.setStatus({ status })));
  }
}
