import { Game, GameDto } from '@portal/games/data';

export const createGame = (dto: GameDto): Game => {
  const isNotRecent = !!dto.sections.length && !dto.sections.some((section) => Object.keys(section).map((game) => game === 'recent'));

  const { operator, sections } = dto;
  const source = sections.reduce((acc, section) => {
    return { ...acc, ...section };
  }, { [ operator ]: 0 });
  const { gid, extID: extId, _id: id, slug } = dto;
  const { name, gameImageUrl: image, gameBgImageUrl: background, gameImageAlt: imageAlt } = dto;
  const info = { name, image, background };
  const { isAvailable: available, fullWidth: fullscreen, screenRatio: ratio } = dto;
  const demo = isNotRecent || dto.demo;
  const settings = { available, demo, fullscreen, ratio };

  return new Game(id, gid, extId, slug, settings, source, info, imageAlt);
};
