<section class="pending-status">
  <img class="pending-status__icon" alt="status-icon" [src]="'{project}/images/cashier/progress-new.svg' | path"/>
  <div class="pending-status__info">
    <h2 class="pending-status__title" spMsg="CASHIER.STATUS.PAYMENT_PENDING"></h2>
    <p class="pending-status__text" spMsg="CASHIER.STATUS.ACCOUNT_RECHARGED"></p>
  </div>
  <gg-button
    [settings]="button"
    class="play__button info__button--progress"
    (buttonClicked)="updateStatus()"
    [loading]="loading | async"
  >
    <sp-msg spMsg="CASHIER.STATUS.UPDATE"/>
  </gg-button>
  <div class="pending-status__support">
    <sp-msg spMsg="CASHIER.STATUS.ASK_SUPPORT" spaces="end"/>
    <a class="pending-status__support-link" href="mailto:{{ supportEmail }}" spMsg="CASHIER.STATUS.SUPPORT_LINK"></a>.
  </div>
</section>
