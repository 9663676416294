<ng-container *spLet="availableEntities$ | spAsync as data">
  @if (data.tiles) {
    <gg-payment-tiles
      [tiles]="data.tiles"
      [active]="data.type === 'method' ? (activeMethod$ | spAsync) : (activeGroup$ | spAsync)"
      [title]="titleByFlow | spAsync"
      (selected)="setSelected($event, data.type)"
    />
  }
</ng-container>
