import { inject, Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { SpLocalization } from './localization.service';

@Pipe({ name: 'byLanguage' })
export class SpByLanguagePipe implements PipeTransform {
  private readonly lang = inject(SpLocalization).currentLanguage;

  // TODO: Reimplement incoming types for this pipe, remove eslint disable
  // eslint-disable-next-line
  transform<T extends Record<string, any>>(byLanguages: Nullable<T>, property?: keyof T): string {
    if (!byLanguages) { return ''; }
    return property && byLanguages[property] ? byLanguages[property][this.lang] : byLanguages[this.lang];
  }
}
