import { BonusDto } from '@portal/bonuses/data';
import { BonusMaxWinDto } from '@portal/bonuses/shared/types/bonus-max-win';

export const mapMaxWinType = (dto: BonusDto): BonusMaxWinDto => {
  if (dto.maxWin) {
    const { type } = dto.maxWin;
    return {
      number: type === 'number',
      bonus: type === 'bonus',
      deposit: type === 'deposit',
      isActive: dto.maxWin.isActive,
      multiplier: dto.maxWin.multiplier,
      amount: dto.maxWin.amount,
      maxWinAmount: dto.maxWin.maxWinAmount
    };
  } else { return { isActive: false } }
};
