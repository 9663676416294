import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Language, SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { createPage, Page, PageResponse, PageStore } from '@portal/static/data';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const SLUGS_REPLACEMENT_MAP: Record<string, string> = {
  'anti-fraud': 'antifraud',
  'privacy-policy': 'privacypolicy'
};

@Injectable({ providedIn: 'root' })
export class PageData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(PageStore);
  private readonly language = inject(SpLocalization).currentLanguage;

  private readonly pages = this.store.entity('pages');

  loadPage(slug: Page['slug'], lang: Language): Observable<Nullable<Page>> {
    const params = { params: { lang, slug: SLUGS_REPLACEMENT_MAP[slug] || slug } };

    return this.http.get<PageResponse>('/api/static_pages', params).pipe(tap((page) => this.pages.add(createPage(page))));
  }

  getFile(page: Page): Observable<Blob> {
    return this.http.get(`/api/2/cms/download/static/${page.slug}?lang=${this.language}`, { responseType: 'blob' });
  }
}
