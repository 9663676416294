<div class="bonus-cancel-confirm" ggCreateThemeClass="bonus-cancel-confirm">
  <div class="confirm-text-attention">
    <span class="title" spMsg="COMMON.ATTENTION"></span>

    <span class="text" *ngIf="bonus?.type === 'poker_reload' else casinoType">
      <sp-msg msg="BONUSES.POKER_RELOAD_BONUS_CANCEL"></sp-msg>
    </span>
    <ng-template #casinoType>
        <sp-msg msg="BONUSES.BONUS_ACCOUNT_CANCEL"></sp-msg>
    </ng-template>

    <span class="sum">
      {{bonus?.wager.account.bonus}} {{currency$ | async | currencySymbol}}
    </span>
  </div>

  <div class="confirm-text" spMsg="BONUSES.CANCEL_CONFIRMATION"></div>

  <div class="confirm-buttons">
    <gg-button class="cancel-confirm" [settings]="confirmButton" (click)="cancel()"><sp-msg msg="COMMON.YES"/></gg-button>
    <gg-button class="cancel-reject" [settings]="rejectButton" (click)="reject()"><sp-msg msg="COMMON.NO"/></gg-button>
  </div>

  <a class="terms-link" [routerLink]="['/page', 'terms']" spMsg="BONUSES.TERMS_LINK"></a>
</div>
