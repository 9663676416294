import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { ErrorResponse } from '@portal/shared/helpers/responses';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ErrorObjects,
  Errors,
  FormProcessor,
  ServerProcessor,
  VALIDATION_FIELDS_REPLACE_MAP,
  ValidityActions
} from './utils';

@Injectable()
export class ErrorManager {
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly fieldReplaceMap = inject(VALIDATION_FIELDS_REPLACE_MAP, { optional: true });

  private readonly _errors: BehaviorSubject<Errors> = new BehaviorSubject({});
  private readonly formValidator = new FormProcessor();
  private readonly serverValidator = new ServerProcessor();

  private actions: ValidityActions = {};

  readonly errors: Observable<Errors> = this._errors.asObservable();

  setUp(setup: { form: FormGroup; actions?: ValidityActions }): ErrorManager {
    this.actions = setup.actions || {};

    setup.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef$),
      map(() => this.replaceErrors(this.formValidator.run(setup.form))),
      tap((errors) => this._errors.next(errors))
    ).subscribe((errors) => this.actions.form?.(!Object.keys(errors).length, errors));

    return this;
  }

  get(field: string | number): Observable<ErrorObjects> {
    return this.errors.pipe(map((errors) => errors[field]), filter(Boolean));
  }

  set(errors: Errors): void { this._errors.next(errors); }

  count(): Observable<number> {
    return this.errors.pipe(map(e => Object.keys(e).length));
  }

  isValid(): Observable<boolean> {
    return this.count().pipe(map(Boolean));
  }

  isInvalid(): Observable<boolean> {
    return this.isValid().pipe(map(v => !v));
  }

  setServerErrors(error: ErrorResponse): void {
    const validation = this.replaceErrors(this.serverValidator.run(error));
    this.actions.server?.(!Object.keys(validation).length, validation);
    this._errors.next(validation);
  }

  private replaceErrors(errors: Errors): Errors {
    if (!this.fieldReplaceMap) { return errors; }

    return Object.entries(errors).reduce((acc, [ fieldName, error ]) => {
      const replacedFieldName = this.fieldReplaceMap?.[fieldName] || fieldName;
      return { ...acc, [replacedFieldName]: error };
    }, {});
  }
}
