<section class="select">
  <ul (click)="toggleOptions()" class="select__default">
    <li class="select__option" *ngIf="selectedCountry">
      <img alt="" [src]="selectedCountry.src" class="select__img"/>
      <img src="assets/images/icons/arrow-small.svg" class="select__arrow" [ngClass]="{'select__arrow--up': isOpened }" alt="" />
    </li>
  </ul>
  <div class="select__wrapper" [ngClass]="{'select__wrapper--open': isOpened}">
    <ul class="select__options">
      <li class="select__search">
        <input [placeholder]="'COMMON.SEARCH' | translate" [formControl]="searchControl" #search/>
      </li>
      <li *ngFor="let countryCode of filteredList" (click)="selectCountry(countryCode)" class="select__option">
        <img alt="" [src]="countryCode.src" class="select__img select__img--option"/>
        <span>{{countryCode.name}}</span>
      </li>
    </ul>
  </div>
</section>
