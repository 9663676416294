import { Validators } from '@angular/forms';
import { USER_DATA_FORM_MUCHBETTER_PAYPER } from './user-data-form-state.constant';

export const USER_DATA_FORM_PAYPER_POST_CODE = {
  ...USER_DATA_FORM_MUCHBETTER_PAYPER,
  zip_code: [
    '',
    [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(7)
    ]
  ]
};
