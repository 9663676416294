import { Validators } from '@angular/forms';

export const USER_DATA_FORM_PHONE = {
  phone: [
    '',
    [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(15)
    ]
  ]
};
