import { BonusDto } from '@portal/bonuses/data';
import { BonusExpiration, BonusStatus } from '@portal/bonuses/shared';

export const mapExpiration = ({ timing }: BonusDto): BonusExpiration => {
  const beforeStart = 'start' in timing ? timing.activation : timing.dateActivationStart;
  const beforeActivation = 'start' in timing ? timing.activation : timing.dateActivationEnd;
  const beforePlayEnd = 'start' in timing ? timing.activation : timing.datePlayEnd;

  return {
    timers: {
      [BonusStatus.Freeze]: beforeStart,
      [BonusStatus.New]: beforeActivation,
      [BonusStatus.Waiting]: beforeActivation,
      [BonusStatus.Blocked]: beforeActivation,
      [BonusStatus.Active]: beforePlayEnd,
      [BonusStatus.Wager]: beforePlayEnd
    }
  };
};
