import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-radio-button',
  templateUrl: './radio-button.component.html',
  // styleUrls: [ './radio-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent {
  private readonly cd = inject(ChangeDetectorRef);
  @Output() readonly clicked = new EventEmitter<unknown>();

  @Input() value: Nullable<unknown> = null;
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() labelPosition: 'before' | 'after' = 'after';

  click(): void { !this.disabled && this.clicked.emit(this.value); }

  update(): void { this.cd.detectChanges(); }
}
