<div *ngIf="banner?.content as content" class="square-banner {{banner?.id}}" [ngClass]="structure">
  <img class="square-banner__image" [src]="content | byLanguage : 'image' | fallback: ''"
       [alt]="content.imageAlt | byLanguage"/>

  <div class="square-banner__title" [spMsg]="content | byLanguage : 'title' | uppercase"></div>
  <div class="square-banner__description" [spMsg]="content | byLanguage : 'description'"></div>

  <gg-button *ngIf="content.callToAction" (click)="goToUrl()" [settings]="button | spAsync">
    <sp-msg [msg]="content | byLanguage : 'callToAction' | uppercase"></sp-msg>
  </gg-button>
</div>
