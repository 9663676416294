import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export type Tab = { label: string; value: string };

@Component({
  selector: 'gg-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: [ './button-group.component.scss', './button-group.purple.component.scss', './button-group.silver.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ButtonGroupComponent {
  @Input() tabs: Array<Tab> = [];
  @Input() active = '';
  @Output() tabChanged: EventEmitter<string> = new EventEmitter();

  changeTab(tab: string): void { this.tabChanged.emit(tab); }

  isActiveTab(tab: string): boolean { return this.active === tab; }
}
