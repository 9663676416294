import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ConfigQuery } from '@portal/config';

export function accessPokerGuard(): boolean | UrlTree {
  const redirectUrl: UrlTree = inject(Router).createUrlTree(['']);
  const isPokerEnabled = inject(ConfigQuery).modules.sections.poker;

  return isPokerEnabled ? true : redirectUrl;
}
