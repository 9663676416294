<div class="payment-operations">
  <div class="payment-operations__header">
    <div class="payment-operations__logo">
      @if (isTablet$ | spAsync) {
        <a href="#" routerLink="/">
          <img loading="lazy" [src]="logo.mobile" alt="logo"/>
        </a>
      } @else {
        <a href="#" routerLink="/">
          <img loading="lazy" [src]="logo.desktop" alt="logo"/>
        </a>
      }
    </div>
  </div>
  <ul class="payment-operations__list" *spLet="active$ | spAsync as active">
    @for (section of sections; track $index) {
      <li class="payment-operations__element">
        <a class="payment-operations__item" [class.active]="section.section === active" (click)="select(section.section)">
          <sp-msg [spMsg]="section.title"/>
        </a>
      </li>
    }
  </ul>
  <img
    [src]="'assets/images/icons/{palette}/light-cross.svg' | palette"
    class="payment-operations__close-icon"
    alt="close-icon" (click)="close()"
  />
</div>
