// @ts-nocheck

import { AccountDto } from '@portal/cashier/data';

export class Account {
  constructor(
    readonly account: string,
    readonly createdAt: string,
    readonly uuid: string
  ) {}

  static create(account: AccountDto): Account {
    return new Account(account.account, account.created_at, account.uuid);
  }
}
