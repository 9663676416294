import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: [ './input-email.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputEmailComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputEmailComponent extends InputAbstract {}
