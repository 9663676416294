// @ts-nocheck

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CashierDataService, PaymentMethodsQuery } from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class PaymentMethodGuard implements CanActivate {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly cashierCommands: CashierDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const methodId = route.params.method;

    return this.paymentMethodsQuery.paymentMethods$.pipe(
      filter(m => !!m.length),
      tap((methods) => {
        if (!this.paymentMethodsQuery.hasActive() && methodId) {
          const currentMethod = methods.find((method) => method.id === Number(methodId));
          currentMethod && this.cashierCommands.setPaymentMethod(currentMethod);
        }
      }),
      switchMap(() => this.paymentMethodsQuery.selectedMethod$),
      map((entity) => !!entity || this.router.createUrlTree([ '/cashier', 'deposit' ]))
    );
  }
}
