import { Injectable } from '@angular/core';
import { Promotion } from '@portal/promotions/data/entities';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';

export type PromotionState = {
  sync: boolean;
  filters: {
    casino: boolean;
    betting: boolean;
    poker: boolean;
  };
  entities: {
    promotions: SteroidEntity<Promotion, string>;
  };
}

const initialState: SteroidConfig<PromotionState> = {
  options: { name: 'promotions' },
  entities: { promotions: { idKey: 'slug' } },
  initial: {
    filters: {
      casino: true,
      betting: true,
      poker: true,
    },
    sync: false
  }
};

@Injectable({ providedIn: 'root' })
export class PromotionStore extends SteroidStore<PromotionState> {
  constructor() { super(initialState); }
}
