import { EntityStore, QueryEntity, StoreConfigOptions } from '@datorama/akita';
import { generateCollectionName } from './generate-names';
import { Steroid, SteroidConfig, SteroidEntities, SteroidEntityConfig, SteroidOptions, SteroidState } from './types';

export const createEntities = <S extends SteroidState>(
  options: SteroidOptions, entities?: SteroidConfig<S>['entities']
): Steroid<S>['entities'] => {
  if (!entities) { return {} as Steroid<S>['entities']; }

  const names = Object.keys(entities) as Array<keyof SteroidConfig<S>['entities']>;

  return names.reduce((acc, name) => {
    type Entity = SteroidEntities<S>[typeof name];

    const { idKey, initial } = entities[name] as SteroidEntityConfig<SteroidEntities<S>[typeof name]>;
    const collectionName = generateCollectionName(name, options.name);
    const config = { ...options, idKey, name: collectionName } as StoreConfigOptions;

    const store = new EntityStore<Entity>({}, config);
    initial && store.set(initial);
    const query = new QueryEntity<Entity>(store);

    return { ...acc, [name]: { store, query } };
  }, {} as Steroid<S>['entities']);
};
