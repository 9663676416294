<section class="field">
  <div class="{{ additionalClass }} placeholder__wrap email">
    <input
      #input
      type="email"
      class="email__input field__input"
      [disabled]="disabled"
      [(ngModel)]="value"
      [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value, 'email__input--no-label':
      noLabelPlaceholder}"
    />
    <span *ngIf="!noLabelPlaceholder" class="placeholder__text">{{ placeholder }}</span>
  </div>
  <gg-input-error [errors]="errors"/>
</section>
