import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, Pair, Pairs } from '@libs/utils';
import { Bonus, BonusPipesModule, BonusTitlePipe } from '@portal/bonuses';
import { BonusesQuery } from '@portal/bonuses/data';
import { ThemeModule } from '@portal/config';
import { CurrencyNormalizerPipe } from '@portal/currencies/application';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentGroupsQuery, PaymentMethod, PaymentParamsQuery } from '@portal/payment/data';
import { Payment, PaymentBuilder } from '@portal/payment/shared';
import { DottedLineComponent } from '@portal/shared/components/content/dotted-line';
import { map, Observable, withLatestFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, CurrenciesModule, BonusPipesModule, DottedLineComponent, ThemeModule ],
  selector: 'gg-payment-summary',
  templateUrl: './summary.component.html',
  styleUrls: [ './summary.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
  private readonly builder = inject(PaymentBuilder);
  private readonly bonusTitlePipe = inject(BonusTitlePipe);
  private readonly currencyNormalizer = inject(CurrencyNormalizerPipe);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly isWithdrawal = inject(PaymentParamsQuery).isWithdrawal;

  protected readonly method$ = inject(PaymentGroupsQuery).activeMethod$;

  readonly summary$ = this.builder.payment$.pipe(this.collectSummary());

  @Input() initAmount: Nullable<string>;
  @Output() showCommissionInfo: EventEmitter<void> = new EventEmitter();

  showCommission(): void {
    this.showCommissionInfo.emit()
  }

  isFinancial(key: Nullable<string>): boolean {
    return key === 'CASHIER.HISTORY.MENU.DEPOSIT' || key === 'CASHIER.HISTORY.MENU.WITHDRAWAL';
  }

  private collectSummary(): (source$: Observable<Payment>) => Observable<Pairs> {
    return source$ => source$.pipe(
      withLatestFrom(this.method$),
      map(([ payment, method ]) => {
        return [ this.collectAmount(payment), this.collectCommission(payment, method), this.collectBonus(payment) ];
      }),
    );
  }

  private collectAmount({ operation, amount }: Payment): Pair {
    if (!operation || !amount) { return null; }
    const amountValue = this.initAmount ? this.initAmount : amount.toFixed(2);
    const normalized = this.currencyNormalizer.transform(amountValue);

    return { key: `CASHIER.HISTORY.MENU.${operation.toUpperCase()}`, value: normalized };
  }

  private collectBonus({ bonus }: Payment): Pair {
    if (!bonus || this.isWithdrawal) { return null; }
    const instance = this.bonusesQuery.getBonus(bonus as Bonus);
    return { key: 'BONUSES.BONUS', value: instance ? this.bonusTitlePipe.transform(instance) : 'BONUSES.BONUS' };
  }

  private collectCommission({ amount }: Payment, method: Nullable<PaymentMethod>): Pair {
    const min = amount || method?.amounts.limits.min || 0;
    const commission = min * Number(method?.amounts?.fee?.now?.rate || 0);
    const normalized = this.currencyNormalizer.transform(commission.toFixed(2));
    const prefix = commission > 0 ? '≈ ' : '';

    return { key: 'CASHIER.COMMISSION', value: `${prefix} ${normalized}`, visible: !!commission };
  }
}
