import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export const MAIN_BANNERS_SLIDER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 5000
  },
  allowTouchMove: true,
  breakpoints: {
    768: {
      spaceBetween: 16,
      centeredSlides: true
    }
  },
  injectStyles: [ '.swiper { overflow: visible; }' ],
  navigation: false
};
