import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import {
  Article,
  ArticlesState,
  ArticlesStore,
  getMoreArticlesOfSameType,
  getOnlyNews,
  getOnlyPromos,
  getPokerPromos,
  NewsArticle,
  PromoArticle
} from '@portal/articles/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ArticlesQuery extends SteroidQuery<ArticlesState> {

  private readonly articles = this.entity('articles');
  readonly synchronized$: Observable<boolean> = this.select('sync');

  readonly news$: Observable<Array<NewsArticle>> = this.articles.selectAll().pipe(map(getOnlyNews));
  readonly promo$: Observable<Array<PromoArticle>> = this.articles.selectAll().pipe(map(getOnlyPromos));
  readonly pokerPromo$: Observable<Array<PromoArticle>> = this.articles.selectAll().pipe(map(getPokerPromos));

  constructor(store: ArticlesStore) { super(store); }

  getMoreArticles(article: Nullable<Article>, count: number): Array<Article> {
    return getMoreArticlesOfSameType(article, this.articles.getAll()).splice(0, count);
  }

  getSingleArticle(slug: Article['slug']): Nullable<Article> {
    return this.articles.getEntity(slug);
  }
}
