<section class="history">
  <div class="wrapper">
    <pd-history-menu [active]="section$ | spAsync"></pd-history-menu>
    <ng-container *ngIf="operations$ | spAsync as operations">
      <div *ngIf="operations.items.length else noTransactions">
        <pd-history-item
          *ngFor="let operation of operations.items"
          [operation]="operation"
          (cancelling)="cancel($event)"
        ></pd-history-item>
      </div>
      <ng-template #noTransactions>
        <div class="history__no-items">
          <span class="history__no-items__text" spMsg="CASHIER.HISTORY.TRANSACTION.NO_TRANSACTION"></span>
          <gg-button [routerLink]="['/cashier', 'deposit']" class="history__no-items__button">
            <sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"></sp-msg>
          </gg-button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</section>
