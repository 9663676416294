<div class="crypto-verification">
  <img src="assets/images/cashier/stub/Illustration.svg" class="crypto-verification__img" alt="Illustration"/>
  <div class="crypto-verification__content">
    <div class="crypto-verification__information">
      <h3 class="crypto-verification__information-title" spMsg="CONFIRM_EMAIL_PAGE.TITLE"></h3>
      <div class="crypto-verification__information-description" spMsg="CONFIRM_EMAIL_PAGE.DESCRIPTION"></div>
    </div>
    <gg-button [routerLink]="['/user', 'settings', 'contact-details']" (click)="dialog.closeAll()" class="crypto-verification__link">
      <sp-msg msg="CONFIRM_EMAIL_PAGE.SETTINGS_BUTTON"/>
    </gg-button>
  </div>
</div>
