<div class="card-options__bank-card-options-wrapper">
  <div class="card-options__bank-card-options-button" (click)="toggleCardOptions()">
    <span class="card-options__bank-card-options-button-dot"></span>
  </div>
  <div class="card-options__bank-card-options" *ngIf="isCardOptionsShown | spAsync">
    <div class="card-options__bank-card-option card-options__bank-card-option_delete" (click)="openDeleteBlock(card.id)">
      <img src="assets/images/cashier/delete.svg" alt="delete" class="card-options__delete-icon"/>
      <sp-msg spMsg="CASHIER.CARD.DELETE"/>
    </div>
  </div>
</div>
