import { Nullable } from '@libs/utils';

export class UserInfo {
  constructor(
    readonly id: string,
    readonly status: string,
    readonly registration: {
      registeredByPhone: boolean;
      passwordExists: boolean;
      isPokerUser: boolean;
      emailFaked: Nullable<boolean>;
    },
    readonly settings: {
      lang: Nullable<string>;
      currency: Nullable<string>;
    },
    readonly personal: {
      nick: Nullable<string>;
      firstName: Nullable<string>;
      lastName: Nullable<string>;
      birthday: Nullable<string>;
      avatar: Nullable<string>;
      phone: Nullable<string>;
      email: Nullable<string>;
      isSpentMoney: Nullable<boolean>;
      mustChangeNick: Nullable<boolean>;
    },
    readonly verification: {
      phone: {
        phone: Nullable<string>;
        sent: Nullable<number>;
        code: Nullable<string>;
        fails: Nullable<number>;
        confirmed: Nullable<boolean>;
      };
      email: {
        confirmed: Nullable<boolean>;
      };
      otp: {
        createdAt: Nullable<string>;
        activatedAt: Nullable<string>;
        secret: Nullable<string>;
        isActive: Nullable<boolean>;
        qrCodeUrl: Nullable<string>;
      };
      kycaid: {
        status: Nullable<'success' | 'unverified' | 'failure' | 'pending'>;
      };
      documents: {
        verified: Nullable<boolean>;
        list: Array<{
          id: Nullable<string>;
          uid: Nullable<string>;
          md5: Nullable<string>;
          uploadDate: Nullable<string>;
          uploadUser: Nullable<string>;
          filename: Nullable<string>;
          contentType: Nullable<string>;
          length: Nullable<number>;
          format: Nullable<string>;
          size: {
            width?: Nullable<number>;
            height?: Nullable<number>;
          };
          verification: Nullable<number>;
          name: Nullable<string>;
          url: Nullable<string>;
        }>;
      };
    },
    readonly restrictedPages: {
      depositsEnabled: boolean;
      gamesEnabled: boolean;
      withdrawalsEnabled: boolean;
      bonusesEnabled: boolean;
      gamesDisabledDueToNonContact: boolean;
    },
    readonly paymentData: {
      first_name: string;
      last_name: string;
      middle_name: string;
      phone: Nullable<string>;
      country: string;
      city: string;
      state: string;
      address: string;
      address_number: number;
      zip_code: string;
      birth_date: string;
      document_number: string;
      document_serial: string;
      document_type: string;
      nationality: string;
      email: string;
    },
    readonly balance: {
      balance: Nullable<string>;
      bonusBalance: Nullable<string>;
      bonusBalanceBlocked: Nullable<string>;
      bonusCount: Nullable<number>;
      hasBonusBalanceBlocked: Nullable<boolean>;
      totalBalance: Nullable<string>;
      truePlayTokens?: Nullable<string>;
      truePlayBalance?: Nullable<string>;
    },
    readonly blocking: {
      paymentsWidget: boolean;
      betting: boolean;
      slots: boolean;
      lobby: boolean;
      bonuses: boolean;
      deposit: boolean;
      withdrawal: boolean;
      selfBlocked: boolean;
      selfBlockedUntil: Nullable<string>;
    },
    readonly level: Nullable<{
      current: number;
      progress: number;
    }>
  ) {}
}
