import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import {
  FOOTER_PAYMENTS_SWIPER_CONFIG,
  FOOTER_PROVIDERS_SWIPER_CONFIG,
  PLACEHOLDER_SLIDE,
  SLIDER_ITEMS_MIN
} from '@portal/layout/shared';
import { SwiperModule } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

@Component({
  standalone: true,
  selector: 'gg-layout-footer-slider',
  templateUrl: './slider.component.html',
  styleUrls: [ './slider.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, SwiperModule, RouterLink ]
})
export class SliderComponent implements OnInit {
  private readonly window = inject(WINDOW);

  title: Nullable<string> = null;
  config: Nullable<SwiperOptions> = null;
  path: Nullable<string> = null;
  folder: Nullable<string> = null;

  @Input() items: Array<string> = [];
  @Input() section: 'providers' | 'payments' = 'providers';
  @Input() authorized: boolean = false;

  ngOnInit(): void {
    if (this.items.length < SLIDER_ITEMS_MIN && this.window.innerWidth > 991) {
      const filler = new Array(SLIDER_ITEMS_MIN - this.items.length).fill(PLACEHOLDER_SLIDE);
      this.items = [ ...this.items, ...filler ];
    }
    this.title = this.section === 'payments' ? 'FOOTER.PAYMENT_METHODS' : 'FOOTER.PROVIDERS';
    this.config = this.section === 'payments' ? FOOTER_PAYMENTS_SWIPER_CONFIG : FOOTER_PROVIDERS_SWIPER_CONFIG;
    this.path = this.section === 'payments' ? 'cashier' : 'all';
    this.folder = this.section === 'payments' ? 'payment-systems' : 'providers';
  }

  createPath(item: string): Array<string> {
    if (this.section === 'payments') {
      return this.authorized ? [ String(this.path) ] : [ 'auth/login' ];
    }

    return item === PLACEHOLDER_SLIDE ? [ String(this.path) ] : [ item ];
  }

  createLogoPath(item: string): string { return item.replace('_lic', ''); }

  isRealItem(item: string): boolean { return item !== PLACEHOLDER_SLIDE; }
}
