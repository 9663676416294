import { Nullable } from '@libs/utils';
import { BonusDto, BonusPokerReloadDto } from '@portal/bonuses/data';
import { BonusWager, BonusWagerAccount } from '@portal/bonuses/shared';

const calcProgress = (scaleCurrent: string, scaleExpected: string): number => {
  return parseFloat(scaleExpected) ? parseFloat(scaleCurrent) / (parseFloat(scaleExpected) / 100) : 0;
};

const calcBonusValues = (dto: BonusPokerReloadDto): Omit<BonusWagerAccount, 'bonus' | 'maxAmount'> => {
  return dto.bonusAccounts.scale.reduce((acc, scale) => {
    const bonusGained = scale.bonusGained !== undefined ? (parseFloat(scale.bonusGained)).toFixed(2) : '0.00';

    return {
      scaleExpected: (parseFloat(acc.scaleExpected) + parseFloat(scale.expected)).toFixed(2),
      scaleCurrent: (parseFloat(acc.scaleCurrent) + parseFloat(scale.current)).toFixed(2),
      bonusGained,
      progressList: [...acc.progressList || [], calcProgress(scale.current, scale.expected)],
    };
  }, {
    scaleExpected: '0.00',
    scaleCurrent: '0.00',
    bonusGained: '0.00',
    progressList: []
  } as Omit<BonusWagerAccount, 'bonus' | 'maxAmount'>);
};

export const mapWager = (dto: BonusDto): Nullable<BonusWager> => {
  const progress: number = 'bonusAccounts' in dto && 'scaleCurrent' in dto.bonusAccounts
    ? parseFloat(dto.bonusAccounts.scaleCurrent) / (parseFloat(dto.bonusAccounts.scaleExpected) / 100)
    : 100;

  if ('wager' in dto) {
    const { wager: factor, bonusAccounts: account } = dto;

    const bonusGained = account && 'bonusGained' in account ? account.bonusGained : '0.00';

    const modifiedAccount = account ? { ...account, bonusGained } : null;
    return { progress, factor, account: modifiedAccount };
  }

  if ('recoupmentCond' in dto && 'bonusAccounts' in dto) {
    const { factor } = dto.recoupmentCond;

    const account = {
      bonus: dto.bonusAccounts.bonus,
      maxAmount: dto.bonusAccounts.maxAmount,
      gain: dto.bonusAccounts.gain,
      ...calcBonusValues(dto)
    };

    return { progress, factor, account };
  }

  return null;
};
