import { Component, forwardRef, inject, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigQuery } from '@portal/config';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { ErrorObjects } from '@portal/shared/helpers/error-manager/utils';

@Component({
  selector: 'gg-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: [ './input-password.component.scss', './input-password.purple.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPasswordComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputPasswordComponent extends InputAbstract {
  readonly isComplicatedPassword = inject(ConfigQuery).modules.forms.isComplicatedPassword;

  isPasswordVisible = false;
  eyeIcon = 'eye';
  inputType = 'password';

  @Input() showInfoText = true;

  togglePassword(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.inputType = this.isPasswordVisible ? 'text' : 'password';
    this.eyeIcon = this.isPasswordVisible ? 'eye-close' : 'eye';
  }

  filterStrong(errors: ErrorObjects): ErrorObjects {
    return errors?.filter((err) => err.code !== 'strong') || null;
  }
}
