import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { DynamicComponentDirective } from '@portal/shared/components/content/dynamic/dynamic.directive';

import { PalettePipe, PathPipe, ProjectPipe, StructurePipe } from './path.pipe';
import { CreateThemeClassDirective, ThemeDirective } from './theme.directive';
import { ThemedComponent } from './themed.component';

const components = [ ThemedComponent ];
const directives = [ ThemeDirective, CreateThemeClassDirective ];
const pipes = [ PathPipe, ProjectPipe, PalettePipe, StructurePipe ];

@NgModule({
  imports: [ SpCDKModule, DynamicComponentDirective ],
  declarations: [ ...components, ...directives, ...pipes ],
  exports: [ ...components, ...directives, ...pipes ],
  providers: [ pipes ]
})
export class ThemeModule {}
