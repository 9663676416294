<section class="bonuses-wrapper">
  <gg-bonuses-account-panel/>
  <gg-banner-socket page="bonuses" section="notification" class="banner-notification"/>
  <div class="bonuses-wrapper__promo-code-button-wrapper">
    <gg-promo-code-button class="bonuses-wrapper__promo-code-button" [routerLink]="['promo']">
      <img [src]="'{project}/images/bonuses/{palette}/tag.svg' | path" class="bonuses-wrapper__promo-code-button-icon" alt=""/>
      <span class="bonuses-wrapper__promo-code-button-text" spMsg="BONUSES.USE" spaces="end"></span>
      <span class="bonuses-wrapper__promo-code-button-text bonuses-wrapper__promo-code-button-text_theme" spMsg="BONUSES.PROMO_CODE"></span>
    </gg-promo-code-button>
  </div>
  <gg-bonuses-welcome-container/>
  <div class="bonuses-list-wrapper">
    <gg-bonuses-list filter="available"/>
    <gg-bonuses-list filter="activated"/>
  </div>
</section>
