import { inject, Injectable } from '@angular/core';
import { SpLocalization } from '../localization';
import { Notification, NotificationsService, NotificationType } from './source';

@Injectable()
export class SpNotification {
  private readonly notifier = inject(NotificationsService);
  private readonly localization = inject(SpLocalization);

  success(content: string = '', title: string = 'NOTIFICATIONS.TITLES.SUCCESS'): Notification {
    return this.createNotification(content, title, NotificationType.Success);
  }

  error(content: string = '', title: string = 'NOTIFICATIONS.TITLES.ERROR'): Notification {
    return this.createNotification(content, title, NotificationType.Error);
  }

  info(content: string = '', title: string = 'NOTIFICATIONS.TITLES.INFO'): Notification {
    return this.createNotification(content, title, NotificationType.Info);
  }

  warn(content: string = '', title: string = 'NOTIFICATIONS.TITLES.WARNING'): Notification {
    return this.createNotification(content, title, NotificationType.Warn);
  }

  private createNotification(content: string, title: string, type: NotificationType, override?: string): Notification {
    const stringTitle = this.localization.translate(title);
    const stringContent = this.localization.translate(content);

    return this.notifier.create(stringTitle, stringContent, type, override);
  }
}
