import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { SectionNavigationComponent } from '@portal/payment';
import { PaymentDialogWidgetComponent } from './payment-dialog-widget.component';

@NgModule({
  imports: [ SpCDKModule, SectionNavigationComponent ],
  declarations: [ PaymentDialogWidgetComponent ],
  exports: [ PaymentDialogWidgetComponent ]
})
export class PaymentDialogWidgetModule {}
