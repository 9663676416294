import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { AccordionComponent } from './accordion.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';

const components = [ AccordionComponent, ExpansionPanelComponent ];

@NgModule({
  imports: [
    SpCDKModule,
    CdkAccordionModule,
    ThemeModule,
    RouterLink
  ],
  declarations: components,
  exports: components
})
export class AccordionModule {}
