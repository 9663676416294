import { SpArrayJoinPipe } from './array-join.pipe';
import { SpAsyncPipe } from './async.pipe';
import { SpEntriesPipe } from './entries.pipe';
import { SpFallbackPipe } from './fallback.pipe';
import { SpRepeatPipe } from './repeat.pipe';
import { SpSafeUrlPipe } from './safe-url.pipe';
import { SpTruncatePipe } from './truncate.pipe';

export * from './array-join.pipe';
export * from './async.pipe';
export * from './entries.pipe';
export * from './fallback.pipe';
export * from './repeat.pipe';
export * from './safe-url.pipe';
export * from './truncate.pipe';

export const commonPipes = [ SpArrayJoinPipe, SpAsyncPipe, SpEntriesPipe, SpFallbackPipe, SpRepeatPipe, SpSafeUrlPipe, SpTruncatePipe ];
