import {
  MiniRectangleBannerComponent,
  OneOnOneBannerComponent,
  PosterBannerComponent,
  RectangleBannerComponent,
  SectionBannerComponent,
  SlideBannerComponent,
  SquareBannerComponent,
  TextBannerComponent
} from '@portal/banners/components';
import { DownloadAppHeaderBannerComponent } from '@portal/banners/components/download-app-header-banner/download-app-header-banner.component';
import { DownloadAppMenuBannerComponent } from '@portal/banners/components/download-app-menu-banner/download-app-menu-banner.component';

export const bannersByTypes = {
  rectangle_mini: MiniRectangleBannerComponent,
  one_on_one: OneOnOneBannerComponent,
  section: SectionBannerComponent,
  poster: PosterBannerComponent,
  rectangle: RectangleBannerComponent,
  slide: SlideBannerComponent,
  square: SquareBannerComponent,
  text: TextBannerComponent,
  download_app_header: DownloadAppHeaderBannerComponent,
  download_app_menu: DownloadAppMenuBannerComponent
};
