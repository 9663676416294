import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ConfigQuery, Themed } from '@portal/config';
import { HeaderContext } from '@portal/layout/shared';

@Component({
  templateUrl: './rounded.component.html',
  styleUrl: './rounded.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderRoundedComponent extends Themed<HeaderContext> {
  protected readonly isMobileNav = inject(ConfigQuery).modules.header.mobileNavigation.filter(({ url }) => Boolean(url));
}
