// @ts-nocheck

import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pd-enter-code-dialog',
  templateUrl: './enter-code-dialog.component.html',
  styleUrls: ['./enter-code-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnterCodeDialogComponent {
  code: string;

  constructor(private readonly dialogService: DialogRef) {}

  close(): void {
    this.dialogService.close();
  }

  sendCode(): void {
    this.dialogService.close(this.code);
  }
}
