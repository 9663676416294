<div class="container-list">
  <h3 class="container-list__title" [spMsg]="title"></h3>
  <div class="container-list__inner">
    <ng-scrollbar visibility="hover">
      <div class="container-list__wrapper">
        @for (container of containers; track container.id) {
          <div class="container-list__item">
            <div class="container-list__element" [class.container-list__element_active]="container.id === active" (click)="onSelect(container.id)">
              <a class="container-list__link" [class.container-list__link_active]="container.id === active">
                <img class="container-list__img" [src]="container.icon" [alt]="container.name"/>
                <span class="container-list__name" [class.container-list__name_active]="container.id === active" [spMsg]="container.name"></span>
              </a>
            </div>
          </div>
        }
      </div>
    </ng-scrollbar>
  </div>
</div>
