import { PostActionAbstract } from '@portal/payment/shared';
import { PaymentDataComponent } from '@portal/payment/widgets/payment-dialog/sections/payment-financial/post-actions/payment-data';
import { ClassConstructor } from 'libs/utils/src';
import { ThreeDsPollingActionComponent } from './3ds-polling';
import { ApproveDialogActionComponent } from './approve-dialog';
import { CodeDialogActionComponent } from './code-dialog';
import { NoActionComponent } from './no-action';
import { P2pComponent } from './p2p';
import { QrDialogActionComponent } from './qr-dialog';
import { StatusPollingActionComponent } from './status-polling';
import { VerificationDialogComponent } from './verification-action-dialog';

export const paymentActions: Record<string, ClassConstructor<PostActionAbstract>> = {
  'showDialog:code': CodeDialogActionComponent,
  'showDialog:qr': QrDialogActionComponent,
  'showDialog:approve': ApproveDialogActionComponent,
  'showDialog:verification': VerificationDialogComponent,
  'polling:3ds': ThreeDsPollingActionComponent,
  'polling:status': StatusPollingActionComponent,
  'p2p:p2p': P2pComponent,
  'noAction': NoActionComponent,
  'paymentData:paymentData': PaymentDataComponent,
};
