import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { SpCommonModule } from '../../common';
import { NotificationComponent } from './components/notification/notification.component';
import { SimpleNotificationsComponent } from './components/simple-notifications/simple-notifications.component';
import { NotificationsService } from './notifications.service';
import { DEFAULT_OPTIONS, NotificationOptions } from './utils';

export const OPTIONS: InjectionToken<NotificationOptions> = new InjectionToken('options');
export const INNER_OPTIONS: InjectionToken<NotificationOptions> = new InjectionToken('inner options');
export const optionsFactory = (options: NotificationOptions): NotificationOptions => ({ ...DEFAULT_OPTIONS, ...options });

@NgModule({
  imports: [ SpCommonModule ],
  declarations: [ SimpleNotificationsComponent, NotificationComponent ],
  exports: [ SimpleNotificationsComponent ]
})
export class SimpleNotificationsModule {
  static forRoot(options: NotificationOptions = {}): ModuleWithProviders<SimpleNotificationsModule> {
    return {
      ngModule: SimpleNotificationsModule,
      providers: [
        NotificationsService,
        { provide: OPTIONS, useValue: options },
        { provide: INNER_OPTIONS, useFactory: optionsFactory, deps: [ OPTIONS ] }
      ]
    };
  }
}
