<section class="octopay">
  <div class="octopay__wrapper">
    <div class="octopay__header">
      <div class="octopay__header__left">
        <gg-button-back (click)="backPage()"></gg-button-back>
      </div>
      <p class="octopay__header__middle" spMsg="CASHIER.CARD.MAKE_TRANSFER"></p>
      <span class="octopay__header__right"></span>
    </div>
    <div class="octopay__info" spMsg="CASHIER.CARD.OPEN_BANK_APP"></div>
    <div class="octopay__amount">{{octopayInfo?.amount | priceNormalizer: (currency$ | async)}}</div>
    <div class="octopay__card">
      <div class="octopay__card__block">
        <span class="octopay__card__title" spMsg="CASHIER.CARD.NUMBER"></span>
        <div class="octopay__card__num">
          <span>{{octopayInfo?.card}}</span>
          <gg-clipboard-copy [copyValue]="octopayInfo?.card"></gg-clipboard-copy>
        </div>
        <div class="octopay__card__time-block">
          <span spMsg="CASHIER.CARD.TIME_LEFT"></span>
          <div class="octopay__card__timer">{{timer}}</div>
        </div>
      </div>
      <p class="octopay__card__info" spMsg="CASHIER.CARD.UNIQUE_NUM"></p>
    </div>
    <div class="octopay__extra-info">
      <img src="assets/images/cashier/info.svg" alt=""/>
      <span spMsg="CASHIER.CARD.NO_COMMENTS"></span>
    </div>
    <gg-button class="octopay__button" (click)="checkTransfer()">
      <span spMsg="CASHIER.CARD.CONFIRM_TRANSFER"></span>
    </gg-button>
  </div>
</section>


