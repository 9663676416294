import {Validators} from '@angular/forms';
import { validationRules } from "@portal/shared/constants";

export const USER_DATA_ROYALPAY_WITHDRAWAL = {
  amount: [0, [Validators.required]],
  account: [
    '',
    { nonNullable: true, validators: validationRules.phone }
  ],
  bank_id: [
    '', [Validators.required]
  ]
};
