import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { ConfigModules, Project, Theme } from '@portal/config/data';

export type ConfigState = {
  sync: boolean;
  project: Nullable<Project>;
  theme: Nullable<Theme>;
  modules: Nullable<ConfigModules>;
};

const config: SteroidConfig<ConfigState> = {
  options: { name: 'config' },
  initial: { sync: false, project: null, theme: null, modules: null }
};

@Injectable({ providedIn: 'root' })
export class ConfigStore extends SteroidStore<ConfigState> {
  constructor() { super(config); }
}
