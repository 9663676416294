import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData } from '@portal/bonuses/data';
import { BonusAction } from '@portal/bonuses/shared';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: [ './additional-info.component.scss', './purple.additional-info.component.scss', './silver.additional-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalInfoBonusComponent {
  private readonly bonusData = inject(BonusesData);
  private readonly navigation = inject(SpNavigation);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly navigationService = inject(SpNavigation);

  @Input() bonus: Nullable<Bonus> = null;
  @Input() bonusesEnabled: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  get isMessageShown(): boolean {
    const status = this.bonus?.status.current;
    return status === 'gained' ||
           status === 'blocked' ||
           status === 'used' ||
           status === 'canceled' ||
           status === 'expired';
  }

  processBonusAction(action: BonusAction): void {
    if (!this.bonus) { return; }

    if (action !== 'select' && !this.userInfoQuery.isBonusesEnabled) {
      this.navigationService.navigate([ 'cashier', 'deposit-verification' ]);
      return;
    }

    if (action === 'select') {
      this.bonusData.selectBonus(this.bonus, true);
    }

    if (action === 'activate' && this.bonus.activation.manual) {
      this.bonusData.activateBonus(this.bonus).subscribe();
    }
    if (action === 'play' && 'game' in this.bonus && this.bonus.game) {
      this.bonus.type === 'sportsbook' ? this.navigation.navigate('/sports') : this.navigation.navigate(this.bonus.game.link);
    }
    if (action === 'cancel') {
      this.cancel.emit();
    }
    if (action === 'expire') {
      this.bonusData.synchronize();
    }
  }
}
