import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CardSelectorComponent, SummaryComponent } from '@portal/payment';
import { CommissionModule, GeneratedFormComponent, LoaderComponent } from '@portal/payment/components';
import { PaymentFlowAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { StepperModule } from '@portal/shared/components/content/stepper';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, StepperModule, CardSelectorComponent, GeneratedFormComponent, SummaryComponent, LoaderComponent, LoaderComponent, CommissionModule ],
  templateUrl: './generic-flow.component.html',
  styleUrls: [ './generic-flow.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFlowComponent extends PaymentFlowAbstract {
  constructor() { super('additional', 'payment'); }
}
