import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

type ViewportOffsets = Record<'top' | 'right' | 'bottom' | 'left', number>;

@Injectable({ providedIn: 'root' })
export class SpViewport {

  private readonly offsets: ViewportOffsets = { top: 0, right: 0, bottom: 0, left: 0 };

  constructor(
    private readonly scroller: ViewportScroller,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  isInViewport(elm: HTMLElement, offsets: Partial<ViewportOffsets> = {}): boolean {
    const computed = this.computeOffsets(offsets);
    return this.checkElementInViewportByY(elm, computed) && this.checkElementInViewportByX(elm, computed);
  }

  setInViewport(anchor: string): void;
  setInViewport(elm: HTMLElement, offsets: Pick<Partial<ViewportOffsets>, 'top' | 'left'>): void;
  setInViewport(elmOrAnch: HTMLElement | string, offsets: Pick<Partial<ViewportOffsets>, 'top' | 'left'> = {}): void {
    if (typeof elmOrAnch === 'string') {
      this.scroller.scrollToAnchor(elmOrAnch);
    } else {
      const computed = this.computeOffsets(offsets);
      this.scroller.scrollToPosition([ 0, elmOrAnch.offsetTop + computed.top ]);
    }
  }

  scroll(position: [ number, number ] = [ 0, 0 ]): void { this.scroller.scrollToPosition(position); }

  private computeOffsets(offsets: Partial<ViewportOffsets>): ViewportOffsets {
    return { ...this.offsets, ...offsets };
  }

  private checkElementInViewportByY(element: HTMLElement, computed: ViewportOffsets): boolean {
    const elementTop = element.offsetTop + computed.top;
    const elementBottom = elementTop + element.offsetHeight + computed.bottom;
    const viewportTop = this.document.documentElement.scrollTop;
    const viewportBottom = viewportTop + this.document.documentElement.clientHeight;

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  private checkElementInViewportByX(element: HTMLElement, computed: ViewportOffsets): boolean {
    // const elementLeft = elm.offsetLeft + computed.left;
    // const elementRight = elementLeft + elm.offsetWidth + computed.right;
    // const viewportLeft = this.document.documentElement.scrollLeft;
    // const viewportRight = viewportLeft + this.document.documentElement.clientWidth;
    return element && computed && true;
  }
}

