import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { PaymentService } from '@portal/payment/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const OpenNewCashierGuard = (state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  const router = inject(Router)
  const paymentService = inject(PaymentService);

  const section = state.url.toString() === CashierSections.Withdrawal ? 'withdrawal' : 'deposit'

  return paymentService.isWidgetAvailable$.pipe(
    map((isAvailable) => isAvailable ? router.createUrlTree([], { queryParams: { section }, queryParamsHandling: 'merge' }) : true)
  )
};
