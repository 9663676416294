import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { CardPartsModule } from '@portal/bonuses/components';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { AdditionalInfoBonusComponent } from './additional-info/additional-info.component';
import { CancelComponent } from './cancel/cancel.component';
import { CardComponent } from './card.component';
import { ActiveLabelComponent } from './info/active-label/active-label.component';
import { InfoComponent } from './info/info.component';
import { BonusMultiCircleComponent } from './progress/multi-circle/multi-circle.component';
import { ProgressComponent } from './progress/progress.component';

const parts = [ AdditionalInfoBonusComponent, CancelComponent, ActiveLabelComponent, InfoComponent, BonusMultiCircleComponent, ProgressComponent ];
const shared = [ BonusPipesModule, CardPartsModule ];

@NgModule({
  imports: [ SpCDKModule, RouterModule, ContentModule, shared, ThemeModule, ControlsModule, CurrenciesModule ],
  declarations: [ CardComponent, parts ],
  exports: [ CardComponent ],
})
export class BonusCardModule {}
