<gg-accordion class="accordion">
  <gg-accordion-expansion-panel
    *ngFor="let item of accordionItems"
    class="accordion__item"
    [title]="item.header"
    [expandedIconPath]="'assets/images/icons/info.svg'"
    [collapsedIconPath]="'assets/images/icons/info.svg'"
  >
    <div class="accordion__body">
      <sp-msg [msg]="item.body"></sp-msg>
    </div>
  </gg-accordion-expansion-panel>
</gg-accordion>



