export enum LeoPaymentMethods {
  Deposit = 85,
  DepositCard = 86,
  WithdrawalCard = 96,
  MonobankDeposit = 98,
  Privat24Deposit = 99,
  BankCardVisaDepositTrusted = 100,
  MonobankDepositTrusted = 101,
  Privat24DepositTrusted = 102
}
