<ng-container *spLet="method$ | spAsync as method">
  <div class="commission-info">
    <div class="commission-info__title" spMsg="CASHIER.CARD.DYNAMIC_COMMISSION"></div>
    <p class="commission-info__text" *ngIf="method.amounts.fee?.now?.last_deposit_date_in_hours">
      <sp-msg msg="CASHIER.CARD.FROM_LAST_DEPOSIT" spaces="end"/>
      <span class="commission-info__text commission-info__text--bold">
        <sp-msg msg="CASHIER.CARD.LESS_HOURS" [params]="{from: method.amounts.fee?.now?.last_deposit_date_in_hours}"/>
      </span>,
      <sp-msg msg="CASHIER.CARD.COMMISSION_FEE_IS" spaces="wrap"/>
      {{ method.view.commission.current }}%
    </p>

    @if(method.view.commission.rules.length) {
       <div class="commission-info__rules-block">
         <p class="commission-info__text commission-info__rules-title">
            <sp-msg msg="CASHIER.CARD.ASK_WITHDRAW_LATER"/>
         </p>
         @for (rule of method.view.commission.rules; track rule) {
          <span class="commission-info__text commission-info__text--bold commission-info__text--margin">
            <sp-msg msg="CASHIER.CARD.FEE_RULE" [params]="{from: rule.left, rate: rule.rate}"/>
          </span>
         }
       </div>
    }

    <gg-button class="commission-info__btn" [fullWidth]="true" [settings]="closeButton"
      (click)="closePopup()"><sp-msg msg="COMMON.CLOSE"/></gg-button>
  </div>
</ng-container>
