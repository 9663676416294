<ng-container *ngIf="isShowDeposit">
  <ng-container *ngIf="bonus.activation.deposit">
    <span spMsg="BONUSES.FOR_DEPOSIT"></span>
    <span class="bonus-deposit-amount bonus-card-info-modal">
      <sp-msg msg="COMMON.FROM"></sp-msg>
      <span class="activation-amount-value"> {{ wholeValue }} {{ currency$ | async | currencySymbol }} </span>
    </span>
  </ng-container>

  <span *ngIf="bonus.activation.manual" spMsg="BONUSES.NON_DEPOSIT"></span>
</ng-container>
