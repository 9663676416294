import { CryptoFLowDto } from '@portal/cashier/data/dto/cryptoflow-data.dto';
import { CurrencySymbol } from '@portal/currencies/shared';

export class CryptoflowData {
  constructor(
    readonly account: string,
    readonly cryptoCurrency: string,
    readonly qrcode: string,
    readonly currency: CurrencySymbol,
    readonly cryptoName: string,
    readonly minDep: string,
    readonly exchangeRate: string,
    readonly validTill: number,
  ) {}

  static create(cryptoflowData: CryptoFLowDto): CryptoflowData {
    return new CryptoflowData(
      cryptoflowData.account,
      cryptoflowData.crypto_currency,
      cryptoflowData.qrcode,
      cryptoflowData.currency,
      cryptoflowData.crypto_name,
      cryptoflowData.min_dep,
      cryptoflowData.exchange_rate,
      cryptoflowData.valid_till,
    );
  }
}
