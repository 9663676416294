import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { UserQuery } from '@portal/user';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function setPokerCredentialsGuard(): Observable<boolean | UrlTree> {
  const pokerUrl: UrlTree = inject(Router).createUrlTree(['/poker']);
  const isAuth$ = inject(SpAuthorizationService).authorized$;
  const userQuery$ = inject(UserQuery);

  return combineLatest([ isAuth$, userQuery$.hasNickname$, userQuery$.passwordExists$, userQuery$.mustChangeNick$ ]).pipe(
    map(([ auth, hasNick, hasPass, mustChangeNick ]) => !auth || (auth && hasNick && (hasPass && !mustChangeNick))),
    map((needRedirect) => needRedirect ? pokerUrl : true)
  );
}
