import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { RegistrationConfig } from '@portal/authorization/data';
import {
  RegistrationSchemaDefinition,
  RegistrationSchemaDefinitions,
  RegistrationSchemaPropertyConfig,
  RegistrationStageSchema,
  StageConfig
} from '@portal/authorization/shared';
import { ConfigQuery } from '@portal/config';
import { validationRules } from '@portal/shared/constants';
import { CustomValidators } from '@portal/shared/helpers';

type FormField = { title: string; control: FormControl };

export class StageConfigBuilder {
  readonly ontario = this.source.modules.forms.checkboxes.ontario;
  readonly forms = this.source.modules.forms;

  constructor(private readonly source: ConfigQuery) {}

  createStage(stageConfig: RegistrationConfig, controlOrders: Array<Record<string, number>>, stage = 0): StageConfig {
    const { name: stageType, stages } = stageConfig;
    // eslint-disable-next-line
    const { name: stageName, schema } = stages.find(({ order }) => order === stage)!;

    const definitions = this.removeCpaDefinitionsFrom(schema);

    const controls = Object.values(definitions).reduce((acc, definition) => {
      return { ...acc, ...this.createControlsFrom(definition, controlOrders[stage]) };
    }, {});

    return { stage, stageType, stageName, total: stages.length, form: new FormGroup(controls) };
  }

  private removeCpaDefinitionsFrom([ { definitions } ]: Array<RegistrationStageSchema>): RegistrationSchemaDefinitions {
    // eslint-disable-next-line
    delete definitions['CPAInfoSchema'];
    return definitions;
  }

  private createControlsFrom(definition: RegistrationSchemaDefinition, orders: Record<string, number>): Record<string, FormControl> {
    const sortedProperties: Array<{ title: string; control: FormControl }> = [];
    const { properties, required } = definition;

    Object.entries(properties).forEach(([ title, propertyConfig ]) => {
      if (isNaN(orders[title]) || !propertyConfig) { return; }

      propertyConfig = { ...propertyConfig, required: required?.includes(title) };

      const control = new FormControl(
        this.makeControlValue(propertyConfig),
        this.addControlValidators(propertyConfig)
      );

      const additionalProperty = this.temporaryAddNecessaryFieldsToForms(title);
      if (additionalProperty) { sortedProperties[orders[additionalProperty.title]] = additionalProperty; }

      sortedProperties[orders[title]] = { title, control };
    });

    this.makeCheckBoxFormFields()?.map((checkbox) => {
      sortedProperties[orders[checkbox.title]] = checkbox;
    });

    return sortedProperties
      .filter((property) => !!property)
      .map((property) => {
        if (property.title !== 'promo_code') { return property; }
        return { ...property, title: 'promoCode' };
      })
      .reduce((controls, { title, control }) => ({ ...controls, [title]: control }), {});
  }

  private makeCheckBoxFormFields(): Nullable<Array<FormField>> {
    return Object.values(this.forms.checkboxes)
      .filter((props) => props.show)
      .map((props) => {
        const control = new FormControl(props.checked);

        if (props.required) {
          control.setValidators(validationRules.requiredCheckbox);
          control.markAsTouched();
          control.markAsDirty();
        }

        return { title: props.name, control };
      });
  }

  private temporaryAddNecessaryFieldsToForms(previousFieldTitle: string): Nullable<FormField> {
    return previousFieldTitle === 'recaptcha_token' && this.ontario.show
      ? { title: 'ontario', control: new FormControl(this.ontario.checked) }
      : null;
  }

  private makeControlValue(property: RegistrationSchemaPropertyConfig): string | boolean {
    if (property.type === 'boolean' && property.default) { return property.default; }
    return '';
  }

  private addControlValidators(property: RegistrationSchemaPropertyConfig): Array<ValidatorFn> {
    const validators = [];

    if (property.required) { validators.push(Validators.required); }
    if (property.minLength) { validators.push(Validators.minLength(property.minLength)); }
    if (property.maxLength) { validators.push(Validators.maxLength(property.maxLength)); }
    if (property.pattern) { validators.push(Validators.pattern(property.pattern)); }

    if (property.title === 'birthday') { validators.push(CustomValidators.birthday); }
    if (property.title === 'zip_code') {
      if (this.forms.zipCodeInfo.validators.required) {
        validators.push(Validators.required);
      }
      if (this.forms.zipCodeInfo.validators.min) {
        validators.push(Validators.minLength(this.forms.zipCodeInfo.validators.min));
      }
      if (this.forms.zipCodeInfo.validators.max) {
        validators.push(Validators.maxLength(this.forms.zipCodeInfo.validators.max));
      }
    }
    return validators;
  }
}
