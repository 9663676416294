<div *ngIf="bonus?.wager.factor else withoutWager">
  <span><sp-msg msg="BONUSES.WAGER"></sp-msg>:</span>
  <span class="bonus-wager-factor bonus-card-info-modal"> x{{ bonus.wager.factor }}</span>
</div>

<ng-template #withoutWager><div spMsg="BONUSES.NO_WAGER"></div></ng-template>

<div *ngIf="bonus?.type === 'casino_cashback' || bonus?.type === 'casino_cashback_external'" class="bonus-cashback-info">
  <span class="bonus-cashback-info--title"><sp-msg msg="BONUSES.CASHBACK_SUM"></sp-msg>:</span>
  <span class="bonus-cashback-value">
    {{ bonus.wager.account.bonus }} {{ currency$ | async | currencySymbol }}
  </span>
</div>
