import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankData, BankDetails, PaymentsBanksResponse } from '@portal/shared/components/controls/interfaces';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Nullable } from '@libs/utils';

@Injectable({ providedIn: 'root' })
export class BanksService {
  constructor(private readonly http: HttpClient) {}

  getBankList(paymentMethodId: Nullable<string>): Observable<Array<BankDetails>> {
    return this.http.get<PaymentsBanksResponse>(`/api/payments/banks/list/${paymentMethodId}`).pipe(
      take(1),
      map((response: PaymentsBanksResponse) => {
        const prefix = '/assets/images/shared/banks';
        return response.banks.map((bank: BankData) => ({
          ...bank, src: `${prefix}/${bank.bank_id}.svg`
        }));
      })
    );
  }
}
