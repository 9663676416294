import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogCactusDataInterface } from '@portal/cashier/types/interfaces/dialog-cactus-data.interface';

@Component({
  templateUrl: './cactus-dialog.component.html',
  styleUrls: [ './cactus-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CactusDialogComponent {
  code = '';
  saved = false;
  address = '';
  commission = '';
  amountForWidget = '';

  constructor(
    private readonly dialog: DialogRef,
    @Inject(DIALOG_DATA) private readonly data: DialogCactusDataInterface
  ) {
    this.code = this.data.code;
    this.address = this.data.form.city + ' ' + this.data.form.address;
    this.commission = this.data.commission;
    this.amountForWidget = this.data.amountForWidget;
  }

  close(): void {
    this.dialog.close();
  }

  confirm(): void {
    this.dialog.close('confirm');
  }
}
