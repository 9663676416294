import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { VerificationData } from '@portal/verification/data';
import { Nullable } from '@libs/utils';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [SpCDKModule, ControlsModule],
  selector: 'gg-verification-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: [ './qr-code.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeComponent {
  private readonly verificationCommands = inject(VerificationData);
  private readonly deviceDetector = inject(SpPlatform);

  isDesktop: boolean = this.deviceDetector.type.desktop;

  @Input() qrCode: Nullable<string>;

  resetQrCode(): void {
    this.verificationCommands.resetQrCode();
  }
}
