<section class="field">
  <div class="{{ additionalClass }} placeholder__wrap login">
    <input type="login" [disabled]="disabled" class="login__input field__input" [(ngModel)]="value"
           [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value}" #input/>
    <span class="placeholder__text">{{placeholder}}</span>

    <img src="assets/images/icons/user.svg" class="icon" *ngIf="icon" alt=""/>
  </div>
  <gg-input-error [errors]="errors"/>
</section>
