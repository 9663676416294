import { Component, inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager, ErrorResponse } from '@portal/shared/helpers';
import { UserData } from '@portal/user/data';
import { AuthMethodDetails, AuthMethods, AuthQrCode, createTwoFactorAuthForm } from '@portal/user/shared';

@Component({
  selector: 'gg-auth-method',
  templateUrl: './auth-method.component.html',
  styleUrls: [ './auth-method.component.scss' ]
})
export class AuthMethodComponent {
  private readonly errorManager = inject(ErrorManager);
  private readonly notifications = inject(SpNotification);
  private readonly userSettingsCommands = inject(UserData);
  private readonly source = inject(ConfigQuery);

  readonly form: FormGroup = createTwoFactorAuthForm();
  readonly errors: ErrorManager;
  readonly greenButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Medium };
  readonly projectAddress = this.source.modules.settings.projectAddress;
  readonly projectName = this.source.project.name || '';
  readonly authMethods = AuthMethods;

  @Input() methodDetails: Nullable<AuthMethodDetails> = null;
  @Input() twoFactorSettings: Nullable<AuthQrCode> = null;

  constructor() {
    this.errors = this.errorManager.setUp({ form: this.form });
  }

  enableAuth(): void {
    if (this.form.invalid) { return; }
    const { code } = this.form.value;

    this.userSettingsCommands.setTwoFactorAuth(code, 'true').subscribe({
      next: () => this.notifications.success('NOTIFICATIONS.MESSAGES.2FACTOR_ENABLED'),
      error: (err: ErrorResponse) => {
        this.errors.setServerErrors(err);
        this.notifications.error('NOTIFICATIONS.MESSAGES.2FACTOR_ENABLE_FAIL');
      }
    });
  }
}
