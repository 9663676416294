<ng-container *spLet="history$ | spAsync as history">
  <section class="history" *spLet="show$ | spAsync as show">
    <h1 class="history__title" spMsg="CASHIER.METHODS.HISTORY"></h1>
    <gg-payment-history-navigation [active]="show" (changed)="changeSection($event)"/>
    <ng-container *ngFor="let operation of history">
      <gg-payment-history-operation [operation]="operation" [extended]="isExtended" (cancel)="cancel(operation, show)"/>
    </ng-container>
    <div class="history__no-items" *ngIf="history?.length === 0">
      <span class="history__no-items__text" spMsg="CASHIER.HISTORY.TRANSACTION.NO_TRANSACTION"></span>
      <gg-button (buttonClicked)="makeDeposit()" class="history__no-items__button"><sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"/></gg-button>
    </div>
  </section>
</ng-container>
