// @ts-nocheck

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CashierDataService, CryptoCurrency } from '@portal/cashier/data';
import { CRYPTO_METHODS } from '@portal/cashier/shared';
import { Observable, of } from 'rxjs';

@Injectable()
export class CryptocurrenciesResolver implements Resolve<Array<CryptoCurrency>> {
  constructor(
    private readonly cashierCommands: CashierDataService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Array<CryptoCurrency>> {
    const method = Number(route.params.method);

    if (!CRYPTO_METHODS.includes(method)) { return of(null); }

    return this.cashierCommands.loadCryptoCurrencies(method);
  }
}
