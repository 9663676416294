import { CurrencySymbol } from '@portal/currencies/shared/currency-symbols';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';

const countriesPath = '/assets/images/shared/countries';
const currenciesPath = '/assets/images/shared/currencies';

export const CURRENCIES: Record<CurrencySymbol, IInputSelectData> = {
  USD: { icon: `${countriesPath}/US.png`, description: 'US Dollar', suffix: '($)', value: 'USD' },
  UAH: { icon: `${countriesPath}/UA.png`, description: 'Українська гривня', suffix: '(₴)', value: 'UAH' },
  KZT: { icon: `${countriesPath}/KZ.png`, description: 'Теңге', suffix: '(₸)', value: 'KZT' },
  EUR: { icon: `${countriesPath}/EU.png`, description: 'Euro', suffix: '(€)', value: 'EUR' },
  RUB: { icon: `${countriesPath}/RU.png`, description: 'Российский рубль', suffix: '(₽)', value: 'RUB' },
  UZS: { icon: `${countriesPath}/UZ.png`, description: 'Сўм', suffix: '(UZS)', value: 'UZS' },
  AZN: { icon: `${countriesPath}/AZ.png`, description: '₼', suffix: '(AZN)', value: 'AZN' },
  CAD: { icon: `${countriesPath}/CA.png`, description: 'Canadian dollar', suffix: '(CAD)', value: 'CAD' },
  INR: { icon: `${countriesPath}/IN.png`, description: 'Indian Rupee', suffix: '(₹)', value: 'INR' },
  USDT: { icon: `${currenciesPath}/usdt.svg`, description: 'Tether', suffix: '(USDT)', value: 'USDT' },
  ETH: { icon: `${currenciesPath}/eth.svg`, description: 'Ethereum', suffix: '(ETH)', value: 'ETH' },
  BTC: { icon: `${currenciesPath}/btc.svg`, description: 'Bitcoin', suffix: '(BTC)', value: 'BTC' },
  LTC: { icon: `${currenciesPath}/ltc.svg`, description: 'Litecoin', suffix: '(LTC)', value: 'LTC' },
};
