import { Article } from '@portal/articles/data';

export function sortArticles<T extends Article = Article>(articles: Array<T>): Array<T> {
  return articles.sort((articleA, articleB): 0 | 1 | -1 => {
    const { system: { consecutiveNumber: consecutiveA, createdAt: createdA } } = articleA;
    const { system: { consecutiveNumber: consecutiveB, createdAt: createdB } } = articleB;
    const HAS_NO_SEQUENTIAL_NUMBER = 0;

    if (consecutiveA === consecutiveB) { return 0; }
    if (consecutiveA === HAS_NO_SEQUENTIAL_NUMBER) { return 1; }
    if (consecutiveB === HAS_NO_SEQUENTIAL_NUMBER) { return -1; }

    if (consecutiveA > consecutiveB) { return 1; }
    if (consecutiveA < consecutiveB) { return -1; }

    if (new Date(createdA) < new Date(createdB)) { return -1; }
    if (new Date(createdA) > new Date(createdB)) { return 1; }

    return 0;
  });
}
