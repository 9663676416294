<section class="lifetime-warning" *ngIf="timer$ | async as timer">
  <img src="assets/images/icons/cross.svg" alt="" class="lifetime-warning__close" (click)="close()"/>
  <div class="lifetime-warning__wrapper">
    <img src="assets/images/cashier/warning.svg" class="lifetime-warning__img" alt=""/>
    <div class="lifetime-warning__information">
      <sp-msg msg="CASHIER.CARD.P2P.AMOUNT_MESSAGE"/>
      <div>{{ summary }}</div>
      <sp-msg msg="CASHIER.CARD.P2P.TIME_MESSAGE"/>
      <div>{{ timer.time | amDateFormat: 'mm:ss' }}</div>
    </div>
    <gg-button class="active-bonus-verification__button" [settings]="okButton" (click)="close()">
      OK
    </gg-button>
  </div>
</section>
