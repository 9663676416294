<div class="bonus-card {{bonus?.type}}" [ngClass]="{'selected': selected}" ggCreateThemeClass="bonus-card">
    <gg-bonus-card-icon class="bonus-card__icon" [ngClass]="{'bonus-card__icon--double': isLinkedBonus}" [bonus]="bonus"
                        [isUseNewIcon]="isLinkedBonus"/>
    <h2 class="bonus-card__title">{{ bonus | bonusTitle }}</h2>
    <div class="bonus-card__popup" spPrevent="propagation" (click)="showPopup()">
      <img [src]="'/images/icons/{palette}/info.svg' | path" class="bonus-card__icon-info" alt=""/>
    </div>
    <span class="select">
      <img [src]="'assets/images/check/{palette}/checked.svg' | palette" alt=""/>
      <sp-msg msg="CASHIER.BONUSES.SELECTED"></sp-msg>
    </span>
</div>
