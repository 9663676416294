<ng-container *ngIf="hasDescription">
  <div class="card-description">
    <ng-container *ngIf="isMultiDescription else singleDescription">
      <div class="card-description__content" *ngFor="let description of bonus.information.description | byLanguage" [innerHTML]="description |
      cleanAndReplace"></div>
    </ng-container>
    <ng-template #singleDescription>
      <div class="card-description__content" *ngIf="bonus.information.description as description"
            [innerHTML]="description | byLanguage | cleanAndReplace"></div>
    </ng-template>
      <gg-button
        *ngIf="isWaitingStatus && bonus.activation.deposit"
        class="card-description__button"
        [settings]="actionButton"
        [disabled]="this.bonus.status.is.freeze"
        ggOpenPayments
      ><sp-msg msg="BONUSES.DEPOSIT"/></gg-button>
      <gg-button
        *ngIf="isWaitingStatus && bonus.activation.manual"
        class="card-description__button"
        [settings]="actionButton"
        [disabled]="this.bonus.status.is.freeze"
        (click)="processBonusActivate()"
      ><sp-msg msg="BONUSES.ACTIVATE"/></gg-button>
  </div>
</ng-container>
