import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-flip',
  templateUrl: './flip.component.html',
  styleUrls: [ './flip.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlipComponent implements OnInit, OnChanges {
  private readonly renderer = inject(Renderer2);
  @Input() flip: boolean = false;

  @ViewChild('flipContainer', { static: true }) flipContainer: Nullable<ElementRef> = null;

  ngOnInit(): void { this.rotate(); }

  ngOnChanges(): void {
    if (this.flipContainer) { this.rotate(); }
  }

  rotate(): void {
    if (this.flip) {
      this.renderer.addClass(this.flipContainer?.nativeElement, 'rotate');
    } else {
      this.renderer.removeClass(this.flipContainer?.nativeElement, 'rotate');
    }

  }

}
