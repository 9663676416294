import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpAuthorizationCommands } from './abstract.commands';
import { SpAuthorizationService } from './authorization.service';
import { authorizeInterceptor } from './authorize.interceptor';
import { SP_AUTHORIZATION_CONFIG, SpAuthorizationConfig } from './config';
import { renewInitializer } from './renew.initializer';
import { SpShowForDirective } from './show-only.directive';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ SpShowForDirective ],
  providers: [ SpAuthorizationService ],
  exports: [ SpShowForDirective ]
})
export class SpAuthorizationModule {
  static init(config: SpAuthorizationConfig): ModuleWithProviders<SpAuthorizationModule> {
    config.checkBy = config.checkBy || 'authorization';

    return {
      ngModule: SpAuthorizationModule,
      providers: [
        { provide: SP_AUTHORIZATION_CONFIG, useValue: config },
        { provide: SpAuthorizationCommands, ...config.commands },
        config.checkOnInitialize ? renewInitializer : [],
        authorizeInterceptor
      ]
    };
  }
}
