import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { SpLocalization } from '@libs/cdk';
import { ArticlesStore, createArticles, NewsArticle, PromoArticle } from '@portal/articles/data';
import { ArticlesListResponse } from '@portal/articles/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ArticlesData {
  private readonly http = inject(HttpClient);
  private readonly articlesStore = inject(ArticlesStore);
  readonly language = inject(SpLocalization).currentLanguage;

  @transaction()
  synchronize(): void {
    this.http.get<ArticlesListResponse>('/api/news?categories=promo,news&limit=200').pipe(
      catchError((error) => throwError(ResponseFactory.error(error))),
      tap((articles) => this.articlesStore.entity('articles').set(createArticles(articles.list)))
    ).subscribe(() => this.articlesStore.update({ sync: true }));
  }

  getFile(article: NewsArticle | PromoArticle): Observable<Blob> {
    const section = article instanceof NewsArticle ? 'news' : 'promo';
    return this.http.get(`/api/2/cms/download/${section}/${article.id}?lang=${this.language}`, { responseType: 'blob' });
  }
}
