import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { SocketComponent } from '@portal/banners';
import { ThemeModule } from '@portal/config';
import { AccordionModule } from '@portal/shared/components/content/accordion';
import { SettingsFeaturesModule } from '@portal/user/features/settings/settings-features.module';
import { UserRoutingModule } from '@portal/user/pages/user.routing';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    SpCDKModule,
    SettingsFeaturesModule,
    AccordionModule,
    UserRoutingModule,
    SocketComponent,
    ThemeModule
  ]
})
export class SettingsModule {
}
