import { Dialog } from '@angular/cdk/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { PAYMENT_SECTIONS, WidgetParams } from '@portal/payment/shared';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ThemeModule, RouterLink ],
  selector: 'gg-payment-section-navigation',
  templateUrl: './section-navigation.component.html',
  styleUrls: [ './section-navigation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionNavigationComponent implements OnInit {
  private readonly source = inject(ConfigQuery);
  private readonly userQuery = inject(UserQuery);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly dialog = inject(Dialog);
  private readonly breakpoints = inject(BreakpointObserver);

  protected readonly isTablet$ = this.getBreakpointChecker();

  readonly logo = this.source.project.logo;
  readonly projectSource = this.source.project.source || '';
  readonly active$ = inject(PaymentParamsQuery).section$;

  sections: Nullable<Array<WidgetParams & { title: string }>> = null;

  ngOnInit(): void {
    const isPokerAvailable = this.userQuery.isPokerUser;
    const isDepositEnabled = this.userQuery.isDepositEnabled;
    const isWithdrawalEnabled = this.userQuery.isWithdrawalEnabled;
    const { enableHistoryTab, availableSections } = this.source.modules.cashier;

    this.sections = PAYMENT_SECTIONS
      .filter((category) => availableSections.includes(category.section))
      .filter((category) => !isPokerAvailable || isWithdrawalEnabled ? category.section !== 'transfers' : category)
      .filter((category) => !enableHistoryTab ? category.section !== 'history' : category)
      .filter((category) => isDepositEnabled ? category.section !== 'deposit' : category)
      .filter((category) => isWithdrawalEnabled ? category.section !== 'withdrawal' : category);
  }

  getBreakpointChecker(): Observable<boolean> {
    return this.breakpoints.observe('(min-width: 768px)').pipe(map(({ matches }) => matches));
  }

  select(section: WidgetParams['section']): void {
    this.paramsData.update({ section, container: undefined, group: null, method: undefined });
  }

  close(): void { this.dialog.closeAll(); }
}
