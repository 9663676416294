import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: [ './input-radio.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputRadioComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputRadioComponent extends InputAbstract implements OnInit, OnChanges {
  @Input() options: Array<string> = [];
  @Input() name: Nullable<string> = null;
  @Input() selected = '';
  @Input() showDeleteButton = null;
  @Output() deleteElem = new EventEmitter();

  override ngOnInit(): void { this.writeValue(this.selected); }

  ngOnChanges(): void { this.writeValue(this.selected); }

  isSelected(option: string): boolean { return option === this.selected; }

  deleteThisElem(option: string): void { this.deleteElem.emit(option); }

  changeSelected(option: string): void {
    this.writeValue(option);
    this.selected = this.value;
  }
}
