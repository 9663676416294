<div class="slider-bonuses">
  <gg-bonuses-blocked-overlay *ngIf="areBonusesBlocked && isCryptoMethod"></gg-bonuses-blocked-overlay>
  <section *ngIf="bonusesList | spAsync as bonuses" class="slider-bonuses" ggCreateThemeClass="bonus__wrapper">
    <div class="slider-bonuses__wrapper">
      <div class="slider-bonuses__title" spMsg="CASHIER.BONUSES.CHOOSE_BONUS"></div>
      <swiper-container ggSwiper init="false" [config]="sliderConfig" [index]="activeSlide | async"
                        class="slider-bonuses__swiper">
        <swiper-slide *ngFor="let bonus of bonuses" class="slider-bonuses__bonuses">
          <ng-container *spLet="isEqual(bonus) as active">
            <div class="slider-bonuses__bonus" [class.selected]="active" (click)="selectBonus(bonus, $event)">
              <gg-bonus-cropped-card [bonus]="bonus"/>
              <img *ngIf="active" class="slider-bonuses__hide" src="./assets/images/cashier/close.svg" alt="close-icon"/>
            </div>
          </ng-container>
        </swiper-slide>
      </swiper-container>
      <div class="slider-bonuses__btn-prev"></div>
      <div class="slider-bonuses__btn-next"></div>
    </div>
  </section>
</div>
