import { InjectionToken } from '@angular/core';
import { UrlMatcherOptions } from '@libs/utils';

export const SP_HTTP_CONFIG: InjectionToken<SpHttpConfig> = new InjectionToken('config for http module');
export const SP_HTTP_SERVICES: InjectionToken<Record<string, string>> = new InjectionToken('list of backend services');
export const SP_HTTP_SERVER_HOST: InjectionToken<string> = new InjectionToken('ssr host');

export type SpHttpConfig = {
  withServer?: true;
  services?: Record<string, string>;
  host?: { url: string; ignore?: UrlMatcherOptions };
  credentials?: { enabled: boolean; toggled?: UrlMatcherOptions };
};


