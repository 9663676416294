import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ConfigQuery } from '@portal/config';
import { SpCDKModule } from '@libs/cdk';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [SpCDKModule, RouterLink],
  selector: 'gg-payment-method-verification',
  templateUrl: './payment-method-verification.component.html',
  styleUrls: [ './payment-method-verification.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodVerificationComponent {
  private readonly config = inject(ConfigQuery)
  readonly isPaymentVerificationEnabled: boolean = this.config.modules.verification.enablePaymentConfirmation;
}
