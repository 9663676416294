<div class="access-links">
  <a routerLink="/bonuses" class="access-links__link">
    <img [src]="'{project}/images/header/gifts.svg' | path" class="gift-icon" alt="gift"/>
    <img [src]="'{project}/images/header/gifts-hover.svg' | path" class="gift-icon-hover" alt="gift"/>
    @if (count$ | spAsync) {
      <gg-notification-bullet/>
    }
  </a>
  <a routerLink="/user/settings" class="access-links__link">
    <img [src]="'{project}/images/header/profile.svg' | path" class="profile-icon" alt="profile"/>
    <img [src]="'{project}/images/header/profile-hover.svg' | path" class="profile-icon-hover" alt="profile"/>
  </a>
</div>
