<section *ngIf="banner" ggCreateThemeClass="slider-wrapper">
  <div *ngIf="banner?.content as content" class="slide-banner">
    <div class="slide-banner__img">
      <ng-container *spLet="image$ | async as image">
        <gg-banners-slide-image [image]="content | byLanguage : image | fallback: ''" [imageAlt]="content.imageAlt |
        byLanguage | removePlaceholders"/>
      </ng-container>
    </div>
    <div class="slide-banner__info-body">
      <div class="slide-banner__info" [ngClass]="{'slide-banner__with-title': content | byLanguage : 'title'}">
        <div class="slide-banner__title" [spMsg]="content | byLanguage : 'title'"></div>
        <div class="slide-banner__button-group">
          <button
            *ngIf="content | byLanguage : 'callToAction' as callToAction"
            type="button"
            class="button slide-banner__button"
            (click)="goToUrl(banner.settings.url || '')"
          >{{callToAction}}</button>
          <button
            *ngIf="content | byLanguage : 'additionalAction' as additionalAction"
            type="button"
            [class.button]="palette === 'purple'"
            class="slide-banner__additional-button"
            (click)="goToUrl(banner.settings.additionalUrl || '')"
          >{{additionalAction}}</button>
        </div>
      </div>
    </div>
  </div>
</section>
