import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { SpCDKModule } from '@libs/cdk';
import { createVerificationStatus } from 'apps/portal/src/app/verification/data/mappers';
import { VerificationStatus } from 'apps/portal/src/app/verification/data/core';

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-kyc-verification-status',
  templateUrl: './kyc-status.component.html',
  styleUrls: [ './kyc-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycStatusComponent {
  @Input() set currentKycaidStatus(status: Nullable<string>) {
    this.currentKycVerificationStatus = createVerificationStatus(status);
  }

  currentKycVerificationStatus: Nullable<VerificationStatus>;
}
