import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { HistoryParams } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';

@Component({
  selector: 'gg-payment-history-navigation',
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
  readonly navigation: Array<{ value: HistoryParams['show']; label: string }> = [
    { value: 'all', label: 'CASHIER.HISTORY.MENU.ALL' },
    { value: 'deposit', label: 'CASHIER.HISTORY.MENU.DEPOSIT' },
    { value: 'withdrawal', label: 'CASHIER.HISTORY.MENU.WITHDRAWAL' }
  ];

  @Input() active: Nullable<HistoryParams['show']>;
  @Output() changed: EventEmitter<HistoryParams['show']> = new EventEmitter();

  change(show: string): void { this.changed.emit(show as HistoryParams['show']); }
}
