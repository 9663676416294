<div class="payment-tiles">
  <h3 class="payment-tiles__title" [spMsg]="title"></h3>
  <div class="payment-tiles__container">
    <ng-scrollbar visibility="hover">
      <div class="payment-tiles__wrapper">
        @for (tile of tiles; track tile.id) {
          <div
            class="payment-tiles__element"
            [class.payment-tiles__element_active]="tile.id === active"
            (click)="onSelect(tile.id)"
          >
          <a class="payment-tiles__link" [class.payment-tiles__link_active]="tile.id === active">
            <img [src]="tile.icon" [alt]="tile.name" class="payment-tiles__img"/>
            @if (tile.name && tile.name.length > 1) {
              <sp-msg
                class="payment-tiles__methods-name"
                [class.payment-tiles__methods-name_active]="tile.id === active"
                [spMsg]="tile.name"
              />
            }
            @if (tile?.limits) {
              <div class="payment-swiper__limits">
                {{ tile?.limits.min }} - {{ tile?.limits.max | currencyNormalizer }}
              </div>
            }
          </a>
        </div>
<!--            TODO: implement time for methods-->
<!--           <div class="payment-tiles__time">-->
<!--           <img src="/assets/images/cashier/clock_gray.svg"/>-->
<!--           </div>-->
        }
      </div>
    </ng-scrollbar>
  </div>
</div>
