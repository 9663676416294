import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { UserBankCardQuery } from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class BankCardsPaymentMethodsGuard implements CanActivate {
  constructor(
    private readonly userBankCardQuery: UserBankCardQuery,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userBankCardQuery.selectedCard$.pipe(
      first(),
      map((selectedCard) => !!selectedCard || this.router.createUrlTree([ 'cashier', 'deposit', 'card-validation' ]))
    );
  }
}

