import { DocumentsTypes } from '@portal/verification/shared/enums';

export const DOCUMENTS_TYPES = {
  [DocumentsTypes.passport_internal_1]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.PASSPORT_INTERNAL_1',
  [DocumentsTypes.passport_internal_2]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.PASSPORT_INTERNAL_2',
  [DocumentsTypes.id_card_1]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.ID_CARD_1',
  [DocumentsTypes.id_card_2]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.ID_CARD_2',
  [DocumentsTypes.ProofOfAddress]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.PROOF_OF_ADDRESS',
  [DocumentsTypes.ipn]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.IPN',
  [DocumentsTypes.PaymentMethod]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.PAYMENT_METHOD_DOC',
  [DocumentsTypes.DriverLicense]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.DRIVER_LICENSE_DOC',
  [DocumentsTypes.Selfie]: 'PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.SELFIE'
};
