import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Banner, BannersState, BannersStore, findByVisibility } from '@portal/banners/data';
import { BannerVisibilityFilter } from '@portal/banners/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BannersQuery extends SteroidQuery<BannersState> {
  private readonly banners = this.entity('banners');
  readonly synchronized$ = this.select('sync');
  readonly appBannersAvailable$ = this.select('areAppBannersAvailable');

  constructor(store: BannersStore) { super(store); }

  selectVisible(options: BannerVisibilityFilter): Observable<Array<Banner>> {
    return this.banners.selectAll().pipe(map((banners) => findByVisibility(banners, options)));
  }
}
