<form [formGroup]="form" class="pd-widget">
  <label class="pd-widget__label">
    <sp-msg spMsg="CASHIER.CARD.INPUT_CARD_NUMBER"></sp-msg>
    <gg-mastercard-info-block [method]="method"></gg-mastercard-info-block>
    <gg-input-bank-card
      [errors]="errors.get('account') | async"
      [mask]="mask"
      formControlName="account"
      class="pd-widget__input pd-widget__input--account"
    ></gg-input-bank-card>
  </label>
  <div class="pd-widget__flex">
    <div class="pd-widget__label">
      <sp-msg spMsg="CASHIER.CARD.CARD_EXP"></sp-msg>
      <div class="pd-widget__label--flex">
        <gg-input-card-month class="pd-widget__input pd-widget__input--exp"
                             formControlName="expM"
                             [errors]="errors.get('expM') | async"></gg-input-card-month>
        <gg-input-card-year class="pd-widget__input pd-widget__input--exp"
                            formControlName="expY"
                            [errors]="errors.get('expY') | async"></gg-input-card-year>
      </div>
    </div>
    <div *ngIf="needCvv">
    <label class="pd-widget__label">CVV
      <gg-input-card-cvv class="pd-widget__input pd-widget__input--cvv" formControlName="cvv"
                         [errors]="errors.get('cvv') | async"></gg-input-card-cvv>
    </label>
    </div>
  </div>
  <gg-button [loading]="showLoading" [fullWidth]="true" (click)="sendPDWidgetRequest()"
             [disabled]="!form.valid" [settings]="pdWidgetButton">
    <sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"></sp-msg>
  </gg-button>
  <a
    *ngIf="this.selectedUserBankCard"
    class="pd-widget__green-back-button"
    spMsg="CASHIER.CARD.SELECT_ANOTHER_CARD"
    [routerLink]="['/cashier', 'deposit', 'card-validation']"
  ></a>
</form>
