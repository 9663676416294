import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-authorization',
  templateUrl: './authorization.component.html',
  styleUrl: 'authorization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule, RouterLink, ThemeModule ]
})
export class AuthorizationComponent {
  private readonly breakpoints = inject(BreakpointObserver);
  private readonly configQuery = inject(ConfigQuery);

  protected readonly hasRegistration = this.configQuery.modules.authentication.registrationMethods.length;

  buttonSettings(buttonType?: string): Observable<IButton> {
    return this.breakpoints.observe('(min-width: 768px)').pipe(map(({ matches }) =>
      buttonType === ButtonThemes.Gray
        ? ({ size: matches ? ButtonSizes.Medium : ButtonSizes.Small, theme: ButtonThemes.Gray })
        : ({ size: matches ? ButtonSizes.Medium : ButtonSizes.Small })
    ));
  }
}
