import { PaymentDataFormStatus, PaymentMethodForm, PaymentMethodType } from '@portal/cashier/data';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { FeeInterface } from '@portal/cashier/types/interfaces/fee.interface';

export class PaymentMethod {
  // TODO: reimplement properties with camelCase style for the inner usages
  constructor(
    readonly category: string,
    readonly id: number,
    readonly max_value: string,
    readonly min_value: string,
    readonly operation_type: CashierSections,
    readonly order: number,
    readonly list: object,
    readonly fee: FeeInterface,
    readonly info_banner_key: string,
    readonly parent_id: number,
    readonly is_copy: boolean,
    readonly logo_primary: string,
    readonly logo_secondary: string,
    readonly fee_rate: number | string,
    readonly commission_rate: number,
    readonly form: Array<PaymentMethodForm>,
    readonly custom_name: string,
    readonly type_of_method: string,
    readonly allow_blacklisted_cards: boolean,
    readonly paymentDataForm: PaymentDataFormStatus,
    readonly default_amount: string,
    readonly is_min_amount_enabled: boolean,
    readonly max_withdrawal_amount?: number,
    readonly number?: number,
    readonly key?: string,
    readonly enabled_for_non_confirmed_phone?: boolean
  ) {}

  static create(paymentMethod: PaymentMethodType): PaymentMethod {
    return new PaymentMethod(
      paymentMethod.category,
      paymentMethod.id,
      paymentMethod.max_value,
      paymentMethod.min_value,
      paymentMethod.operation_type,
      paymentMethod.order,
      paymentMethod.list,
      paymentMethod.fee,
      paymentMethod.info_banner_key,
      paymentMethod.parent_id,
      paymentMethod.is_copy,
      paymentMethod.logo_primary,
      paymentMethod.logo_secondary,
      paymentMethod.fee_rate,
      paymentMethod.commission_rate,
      paymentMethod.form,
      paymentMethod.custom_name,
      paymentMethod.type_of_method,
      paymentMethod.allow_blacklisted_cards,
      paymentMethod.payment_data_form,
      paymentMethod.default_amount,
      paymentMethod.is_min_amount_enabled,
      paymentMethod.max_withdrawal_amount,
      paymentMethod.number,
      paymentMethod.key,
      paymentMethod.enabled_for_non_confirmed_phone
    );
  }
}
