import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export const FLAT_BANNERS_SLIDER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 5000
  },
  centeredSlides: true,
  allowTouchMove: true,
  navigation: false
};
