import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  ParamMap,
  Params,
  Router,
  RouterEvent,
  UrlSegment
} from '@angular/router';
import { arrayalize, ClassConstructor, GlobalWindow, isUrl, Nullable, OrArray, UrlOptions } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SpNavigation {
  private readonly history: Array<string> = [];

  get url(): string { return this.router.url; }

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    @Inject(WINDOW) private readonly window: GlobalWindow
  ) {
    this.event(NavigationEnd).pipe(
      takeUntilDestroyed()
    ).subscribe((event) => this.history.push(event.urlAfterRedirects));
  }

  event<E extends RouterEvent>(event: ClassConstructor<E>): Observable<E> {
    return this.router.events.pipe(
      map((e) => e as RouterEvent),
      filter((e): e is E => e instanceof event)
    );
  }

  matchRouterUrl(urls: OrArray<string>, strategy: 'in' | 'start' | 'end' = 'in'): boolean {
    return arrayalize(urls).some((url) => {
      if (strategy === 'start') { return this.url.startsWith(url); }
      if (strategy === 'end') { return this.url.endsWith(url); }
      return this.url.includes(url);
    });
  }

  params(): Observable<Params>;
  params(type: 'snapshot'): Params;
  params(type: 'map'): Observable<ParamMap>;
  params(type?: 'snapshot' | 'map'): Params | Observable<Params | ParamMap> {
    if (type === 'snapshot') { return this.route.snapshot.params; }
    if (type === 'map') { return this.route.paramMap; }
    return this.route.params;
  }

  query(): Observable<Params>;
  query(type: 'snapshot'): Params;
  query(type: 'map'): Observable<ParamMap>;
  query(type?: 'snapshot' | 'map'): Params | Observable<Params | ParamMap> {
    if (type === 'snapshot') { return this.route.snapshot.queryParams; }
    if (type === 'map') { return this.route.queryParamMap; }
    return this.route.queryParams;
  }

  back(fallbackPath: OrArray<string>, queryParams?: Params, extras?: NavigationExtras): void {
    this.history.pop();
    this.history.length
      ? this.location.back()
      : this.router.navigate(arrayalize(fallbackPath), { queryParams, ...extras });
  }

  navigate(url: OrArray<string>, queryParams?: Nullable<Params>, extras?: Nullable<NavigationExtras>): Promise<boolean> {
    const route = arrayalize(url);
    if (!this.isExternal(route)) { return this.router.navigate(route, { queryParams, ...extras }); }

    this.window.location.href = route.join('/');
    return Promise.resolve(true);
  }

  open(url: OrArray<string>, queryParams?: Params, extras?: NavigationExtras): void {
    const route = this.isExternal(url) ? url as string : this.router.createUrlTree(arrayalize(url), { queryParams, ...extras }).toString();
    this.window.open(route);
  }

  redirect(url: UrlSegment): void;
  redirect(url: OrArray<string>, queryParams?: Params, extras?: NavigationExtras): void;
  redirect(url: OrArray<string> | UrlSegment, queryParams?: Params, extras?: NavigationExtras): void {
    this.window.location.href = url instanceof UrlSegment
      ? decodeURIComponent(`${url.path}${Object.keys(url.parameters).length ? `?${new URLSearchParams(url.parameters).toString()}` : ''}`)
      : this.router.createUrlTree(arrayalize(url), { queryParams, ...extras }).toString();
  }

  process(url: UrlOptions): Promise<boolean> {
    if (!url.external) { return this.navigate(url.path, url.queryParams, url.extras); }

    url.target === 'blank' ?
      this.open(url.path, url.queryParams, url.extras) :
      this.redirect(url.path, url.queryParams, url.extras);

    return Promise.resolve(true);
  }

  private isExternal(url: OrArray<string>): boolean {
    const urlString: string = Array.isArray(url) ? url.join('') : url;
    return isUrl(urlString);
  }
}
