import { AfterViewInit, Component, DestroyRef, forwardRef, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpPlatform } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Country } from '@portal/countries';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-country-phone-input',
  templateUrl: './input-phone-with-country.component.html',
  styleUrls: [ './input-phone-with-country.component.scss', './input-phone.purple.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPhoneWithCountryComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputPhoneWithCountryComponent extends InputAbstract implements OnInit, AfterViewInit {
  private readonly isServer: boolean = inject(SpPlatform).server;
  private readonly destroyRef$ = inject(DestroyRef);

  private readonly lengthNumber = 15;
  override maxlength = this.lengthNumber;

  readonly control = new FormControl();

  defaultItem: Nullable<Country>;
  prefix: string = '';

  @Input() showSelector = true;

  override ngOnInit(): void {
    !this.isServer && this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((v) => {
      v === null && !this.control.dirty && this.initialValue
        ? this.control.setValue(this.initialValue.slice(this.prefix.length))
        : this.writeValue(`${this.prefix}${v}`);
    });
  }

  selectCountry(country: Country): void {
    this.maxlength = this.lengthNumber - country.phonePrefix.length;
    this.prefix = country.phonePrefix;
  }
}
