export enum CypixPaymentMethods {
  BankCardDeposit = 119,
  BankCardWithdrawalRu = 120,
  BankCardWithdrawalWorld = 125,
  BankCardDepositKZT = 136,
  BankCardWithdrawalWorldKZT = 137,
  MobileWithdrawal = 143,
  BankCardCISWithdrawal = 163,
  BankCardWithdrawalRuFixed = 166,
  DepositH2HUntrusted = 212,
  DepositH2H = 213,
  DepositP2PUntrusted = 294,
  MobileDepositKZT = 299,
  BankCardDepositKztH2H = 371,
  BankCardDepositH2H = 372,
  BankCardSBPDeposit = 397
}
