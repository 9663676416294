import { GamesWithTotal } from '@portal/games/data';
import { createGame } from '@portal/games/data/mappers/create-game';
import { SectionGamesResponse } from '@portal/games/shared';

export function createRecentGames(games: SectionGamesResponse): GamesWithTotal {
  return {
    games: games._items.map((game) => createGame(game)),
    total: { recent: games._meta.total }
  };
}
