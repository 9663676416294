import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy
} from '@angular/core';
import { Nullable } from '@libs/utils';
import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[ggTooltip]' })
export class TooltipDirective implements OnDestroy {

  private componentRef: Nullable<ComponentRef<TooltipComponent>> = null;

  @Input('ggTooltip') tooltip: Nullable<string> = '';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly appRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnDestroy(): void { this.destroy(); }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.componentRef || !this.tooltip) { return; }

    this.document.body.appendChild(this.createTooltipElement());
    this.setTooltipComponentProperties();
  }

  @HostListener('mouseleave') onMouseLeave(): void { this.destroy(); }

  private destroy(): void {
    if (!this.componentRef) { return; }

    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.componentRef = null;
  }

  private createTooltipElement(): HTMLElement {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
    this.componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
    return (this.componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
  }

  private setTooltipComponentProperties(): void {
    if (!this.componentRef || !this.tooltip) { return; }

    this.componentRef.instance.tooltip = this.tooltip;
    const { left, bottom } = this.elementRef.nativeElement.getBoundingClientRect();
    this.componentRef.instance.left = left;
    this.componentRef.instance.top = bottom;
  }
}
