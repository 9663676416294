import { APP_INITIALIZER, inject } from '@angular/core';
import { UserQuery } from '@portal/user';
import { setUser } from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {
  inject(UserQuery).id$.pipe(first(Boolean)).subscribe((id) => id && setUser({ id }));
  return () => of(true);
};

export const sentryTraceInitializer = { provide: APP_INITIALIZER, useFactory: init, multi: true };
