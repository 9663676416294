<div *ngIf="banner?.content as content" class="game">
  <img
    class="game__img"
    [src]="content | byLanguage : 'image' | fallback: ''"
    [alt]="content.imageAlt | byLanguage"
  />

  <div class="game__overlay" (click)="goToUrl()">
    <span class="game__title" [innerHTML]="content.title | byLanguage"></span>
    <gg-link [settings]="goToGameButton" class="game__button">
      <sp-msg [msg]="content | byLanguage : 'callToAction'"></sp-msg>
    </gg-link>
  </div>
</div>
