import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CryptoCurrenciesMapper, CryptoCurrency, CryptoCurrencyDto, CurrenciesRepository } from '@portal/currencies/data';
import { PaymentMethod } from '@portal/payment/data';
import { filter, map, Observable, tap } from 'rxjs';

type CryptoCurrencies = { cryptocurrencies: Array<CryptoCurrencyDto> };

export class SelectCryptoCurrencies {
  private readonly http = inject(HttpClient);
  private readonly repo = inject(CurrenciesRepository);

  receive(method: PaymentMethod['id']): Observable<Array<CryptoCurrency>> {
    return this.repo.steroid.query.select('crypto').pipe(
      tap((crypto) => !crypto[ method ] && this.loadCryptoCurrencies(method)),
      filter((crypto): crypto is Record<typeof method, Array<CryptoCurrency>> => Boolean(crypto[ method ])),
      map((crypto) => crypto[ method ]),
    );
  }

  private loadCryptoCurrencies(method: PaymentMethod['id']): void {
    this.http.get<CryptoCurrencies>(`/api/payments/cryptocurrencies/list/${method}`).pipe(
      map(({ cryptocurrencies }) => CryptoCurrenciesMapper.toDomain(cryptocurrencies)),
      map((currencies) => ({ ...this.repo.get('crypto'), [ method ]: currencies })),
    ).subscribe((crypto) => this.repo.update({ crypto }));
  }
}
