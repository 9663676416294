import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { synchronize } from '@libs/store';
import { BannersData, BannersQuery } from '@portal/banners/data';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {
  const isAuthorized$ = inject(SpAuthorizationService).authorized$;
  const data = inject(BannersData), query = inject(BannersQuery);

  return () => isAuthorized$.pipe(tap(() => synchronize(data, query)), first());
};

export const bannersInitializer: Provider = { provide: APP_INITIALIZER, useFactory: init, multi: true };
