import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import range from 'lodash.range';
import moment from 'moment';

@Component({
  selector: 'gg-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: [ './input-date.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputDateComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDateComponent extends InputAbstract implements OnInit, AfterContentInit {
  readonly dateForm = new FormGroup({
    day: new FormControl(),
    month: new FormControl(),
    year: new FormControl()
  });
  readonly days: Array<IInputSelectData> = range(1, 32, 1).map(this.daysToSelectOption);
  readonly months: Array<IInputSelectData> = range(0, 12, 1).map(this.monthsToSelectOption);
  readonly years: Array<IInputSelectData> = range(moment().year() - 118, moment().year() - 17, 1).map(this.yearToSelectOption).reverse();

  defaultDay!: IInputSelectData;
  defaultMonth!: IInputSelectData;
  defaultYear!: IInputSelectData;

  override ngOnInit(): void {
    if (this.disabled) { this.dateForm.disable(); }

    this.dateForm.valueChanges.subscribe((value) => {
      this.writeValue(`${value.year}-${value.month}-${value.day}`);
    });
  }

  ngAfterContentInit(): void {
    const date: Date = new Date(this.value);
    const [ year, month, day ] = [ date.getFullYear(), date.getMonth() + 1, date.getDate() ];

    this.defaultDay = day ? this.daysToSelectOption(day) : this.days[0];
    this.defaultMonth = month ? this.monthsToSelectOption(parseInt(String(month), 10) - 1) : this.months[0];
    this.defaultYear = year ? this.yearToSelectOption(parseInt(String(year), 10)) : this.years[0];

    this.dateForm.setValue({
      day: day || this.days[0].value,
      month: month || this.months[0].value,
      year: year || this.years[0].value
    });
  }

  private daysToSelectOption(value: number | string): IInputSelectData {
    value = `0${value}`.substr(-2);
    return { value, description: value };
  }

  private monthsToSelectOption(value: number | string): IInputSelectData {
    const month = moment.monthsShort(Number(value));
    return { value: `0${Number(value) + 1}`.substr(-2), description: `COMMON.${month.toUpperCase()}` };
  }

  private yearToSelectOption(value: number | string): IInputSelectData {
    return { value: String(value), description: String(value) };
  }
}
