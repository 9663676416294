import { APP_INITIALIZER, inject } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { interval, Observable, of, switchMap } from 'rxjs';
import { first } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {
  const config = inject(ConfigQuery);
  const translation = inject(SpLocalization);

  return () => config.synchronized$.pipe(
    first(Boolean), switchMap(() => {
      if (!config.project) {return of(true); }
      return interval(100).pipe(first(() => Boolean(Object.keys(translation.currentTranslation || {}).length)));
    })
  );
};

export const translationInitializer = { provide: APP_INITIALIZER, useFactory: init, multi: true };
