import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Dialog } from '@angular/cdk/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, RouterLink } from '@angular/router';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { OpenPaymentsDirective } from '@portal/payment';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { BalanceComponent, QuickAccessLinksComponent } from '@portal/user';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-user-balance',
  imports: [ SpCDKModule, BalanceComponent, ControlsModule, ContentModule, RouterLink, QuickAccessLinksComponent, OpenPaymentsDirective, ThemeModule ],
  templateUrl: './user-balance.component.html',
  styleUrls: [ './user-balance.component.scss', 'user-balance-purple.component.scss', 'user-balance-silver.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBalanceComponent implements OnInit {
  private readonly breakpoints = inject(BreakpointObserver);
  private readonly configQuery = inject(ConfigQuery);
  private readonly navigation = inject(SpNavigation);
  private navigationEnd$: Nullable<Observable<NavigationEnd>>;
  private readonly dialogsService = inject(Dialog);

  protected readonly isTablet$ = this.getBreakpointChecker();
  protected readonly isLight = this.configQuery.modules.sidebar.isLightBalance;
  protected isBalanceVisible$: Nullable<Observable<boolean>>;

  @Input() withDepositButton: boolean | 'true' | 'false' = false;
  @Input() section: 'header' | 'sidebar' = 'header';

  get showDepositButton(): boolean { return coerceBooleanProperty(this.withDepositButton); }

  ngOnInit(): void {
    this.navigationEnd$ = this.navigation.event(NavigationEnd).pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.isBalanceVisible$ = this.navigationEnd$?.pipe(map(event => !event.url.startsWith('/slots/')));
  }

  getBreakpointChecker(): Observable<IButton> {
    return this.breakpoints.observe('(min-width: 768px)').pipe(map(({ matches }) =>
      ({ size: matches ? ButtonSizes.Medium : ButtonSizes.Small })
    ));
  }

  close(): void {
    this.dialogsService.closeAll();
  }
}
