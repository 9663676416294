<section class="counter-block" ggCreateThemeClass="counter-block">
  <button type="button" class="counter minus" (click)="decrease()">
    <img class="counter__svg" alt="" src="assets/images/icons/minus.svg"/>
  </button>
  <div class="error-wrapper">
    <div class="sum-wrapper">
      <input
        #input
        type="text"
        inputmode="numeric"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [ngModel]="value"
        [ngClass]="{ sum: true, error: errors && !!errors.length }"
        (keyup.enter)="makeTransaction()"
      />
      <span class="currency">{{settings?.currencySymbol}}</span>
    </div>
    <gg-input-error class="error-text" [errors]="errors"></gg-input-error>
  </div>
  <button type="button" class="counter plus" (click)="increase()">
    <img src="assets/images/icons/plus.svg" class="counter__icon" alt=""/>
  </button>
</section>
