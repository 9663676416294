import { RegistrationConfig } from '@portal/authorization/data';

export const PHONE_REGISTRATION_CONFIG: RegistrationConfig = {
  country: '',
  name: 'extended',
  stages: [
    {
      order: 0,
      name: 'stage_first_extended',
      schema: [
        {
          $schema: 'http://json-schema.org/draft-07/schema#',
          definitions: {
            CPAInfoSchema: {
              type: 'object',
              properties: {
                city: {
                  title: 'city',
                  type: 'string'
                },
                click_id: {
                  title: 'click_id',
                  type: 'string'
                },
                country: {
                  title: 'country',
                  type: 'string'
                },
                host: {
                  title: 'host',
                  type: 'string'
                },
                offer_id: {
                  title: 'offer_id',
                  type: 'string'
                },
                referer: {
                  title: 'referer',
                  type: 'string'
                },
                source: {
                  title: 'source',
                  type: 'string'
                },
                subid1: {
                  title: 'subid1',
                  type: 'string'
                },
                subid2: {
                  title: 'subid2',
                  type: 'string'
                },
                timestamp: {
                  title: 'timestamp',
                  type: 'number',
                  format: 'integer'
                },
                url: {
                  title: 'url',
                  type: 'string'
                },
                user_agent: {
                  title: 'user_agent',
                  type: 'string'
                },
                webmaster: {
                  title: 'webmaster',
                  type: 'string'
                },
                x_referer: {
                  title: 'x_referer',
                  type: 'string'
                }
              },
              additionalProperties: false
            },
            ExtendedStageFirstSchema: {
              type: 'object',
              properties: {
                phone: {
                  title: 'phone',
                  type: 'string',
                  pattern: '^$|^[+]{1}[0-9]{6,14}$'
                },
                birthday: {
                  title: 'birthday',
                  type: 'string',
                  format: 'date'
                },
                recaptcha_token: {
                  title: 'recaptcha_token',
                  type: 'string'
                }
              },
              required: [
                'phone'
              ],
              additionalProperties: false
            }
          },
          $ref: '#/definitions/ExtendedStageFirstSchema'
        }
      ]
    },
    {
      order: 1,
      name: 'stage_second_extended',
      schema: [
        {
          $schema: 'http://json-schema.org/draft-07/schema#',
          definitions: {
            ExtendedStageSecondSchema: {
              type: 'object',
              properties: {
                currency: {
                  title: 'currency',
                  type: 'string',
                  enum: [],
                  enumNames: []
                },
                recaptcha_token: {
                  title: 'recaptcha_token',
                  type: 'string'
                },
                country_code: {
                  title: 'country_code',
                  type: 'string',
                  pattern: '^[A-Z]{2}$'
                },
                has_privacy_policy: {
                  title: 'has_privacy_policy',
                  type: 'boolean'
                },
                is_adult: {
                  title: 'is_adult',
                  type: 'boolean'
                },
                is_subscribed: {
                  title: 'is_subscribed',
                  type: 'boolean'
                },
                password: {
                  title: 'password',
                  type: 'string',
                  minLength: 8,
                  maxLength: 255
                },
                promo_code: {
                  title: 'promo_code',
                  type: 'string',
                  pattern: '^[a-zA-Zа-яА-Я0-9]{3,16}$'
                },
                referer: {
                  title: 'referer',
                  type: 'string'
                },
                domain: {
                  title: 'domain',
                  type: 'string'
                },
                source: {
                  title: 'source',
                  type: 'string'
                },
                lang: {
                  title: 'lang',
                  type: 'string',
                  enum: [
                    'en',
                    'fr',
                    'de',
                    'ru',
                    'kz'
                  ],
                  enumNames: []
                },
                ua: {
                  title: 'ua',
                  type: 'string'
                },
                code: {
                  title: 'code',
                  type: 'string',
                  pattern: '^[0-9]{4}$'
                },
              },
              required: [
                'password',
                'currency',
                'lang',
                'ua',
                'code'
              ],
              additionalProperties: false
            }
          },
          $ref: '#/definitions/ExtendedStageSecondSchema'
        }
      ]
    }
  ]
};
