import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
  ViewRef
} from '@angular/core';
import { Nullable } from '@libs/utils';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../notifications.service';
import {
  Notification,
  NotificationAnimationType,
  NotificationEvent,
  NotificationOptions,
  Position
} from '../../utils';

@Component({
  selector: 'sp-simple-notifications',
  templateUrl: './simple-notifications.component.html',
  styleUrls: [ './simple-notifications.component.css' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SimpleNotificationsComponent implements OnDestroy {
  private readonly service = inject(NotificationsService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly listener: Nullable<Subscription>;
  private readonly lastOnBottom = true;
  private readonly maxStack = 8;
  private readonly preventLastDuplicates: any = false;
  private readonly preventDuplicates = false;

  private usingComponentOptions = false;
  private lastNotificationCreated: Nullable<Notification>;

  notifications: Array<Notification> = [];
  position: Position = [ 'bottom', 'right' ];

  // Sent values
  timeOut = 0;
  maxLength = 0;
  clickToClose = true;
  clickIconToClose = false;
  showProgressBar = true;
  pauseOnHover = true;
  theClass = '';
  rtl = false;
  animate: NotificationAnimationType = NotificationAnimationType.FromRight;

  @Input() set options(opt: NotificationOptions) {
    this.usingComponentOptions = true;
    this.attachChanges(opt);
  }

  @Output() create: EventEmitter<unknown> = new EventEmitter();
  @Output() destroy: EventEmitter<unknown> = new EventEmitter();

  constructor() {
    if (!this.usingComponentOptions) { this.attachChanges(this.service.globalOptions); }

    this.listener = this.service.emitter$.subscribe((item) => {
      if (item.command === 'cleanAll') {
        this.notifications = [];
      } else if (item.command === 'clean' && item.id) {
        this.cleanSingle(item.id);
      } else if (item.command === 'set' && item.add && item.notification) {
        this.add(item.notification);
      } else if (item.command === 'set' && !item.add) {
        this.defaultBehavior(item);
      } else {
        this.defaultBehavior(item);
      }

      if (!(this.cd as ViewRef).destroyed) { this.cd.detectChanges(); }
    });
  }

  ngOnDestroy(): void {
    this.listener?.unsubscribe();
    this.cd.detach();
  }

  // Default behavior on event
  defaultBehavior(value: NotificationEvent): void {
    if (!value.notification) { return; }

    this.notifications.splice(this.notifications.indexOf(value.notification), 1);
    this.destroy.emit(this.buildEmit(value.notification, false));
  }

  // Add the new notification to the notification array
  add(item: Notification): void {
    item.createdOn = new Date();

    const toBlock: boolean = this.preventLastDuplicates || this.preventDuplicates ? this.block(item) : false;

    // Save this as the last created notification
    this.lastNotificationCreated = item;
    // Override icon if set
    if (item.override && item.override.icons && item.override.icons[item.type]) {
      item.icon = item.override.icons[item.type];
    }

    if (!toBlock) {
      // Check if the notification should be added at the start or the end of the array
      if (this.lastOnBottom) {
        if (this.notifications.length >= this.maxStack) {
          this.notifications.splice(0, 1);
        }

        this.notifications.push(item);
      } else {
        if (this.notifications.length >= this.maxStack) {
          this.notifications.splice(this.notifications.length - 1, 1);
        }

        this.notifications.splice(0, 0, item);
      }

      this.create.emit(this.buildEmit(item, true));
    }
  }

  // Check if notifications should be prevented
  block(item: Notification): boolean {

    const toCheck = item.html ? this.checkHtml : this.checkStandard;

    if (this.preventDuplicates && this.notifications.length > 0) {
      for (const notification of this.notifications) {
        if (toCheck(notification, item)) {
          return true;
        }
      }
    }

    if (this.preventLastDuplicates) {

      let comp: Notification;

      if (this.preventLastDuplicates === 'visible' && this.notifications.length > 0) {
        if (this.lastOnBottom) {
          comp = this.notifications[this.notifications.length - 1];
        } else {
          comp = this.notifications[0];
        }
      } else if (this.preventLastDuplicates === 'all' && this.lastNotificationCreated) {
        comp = this.lastNotificationCreated;
      } else {
        return false;
      }
      return toCheck(comp, item);
    }

    return false;
  }

  checkStandard(checker: Notification, item: Notification): boolean {
    return checker.type === item.type && checker.title === item.title && checker.content === item.content;
  }

  checkHtml(checker: Notification, item: Notification): boolean {
    return checker.html ?
      checker.type === item.type && checker.title === item.title && checker.content === item.content && checker.html === item.html :
      false;
  }

  // Attach all the changes received in the options object
  attachChanges(options: NotificationOptions): void {
    for (const key in options) {
      if (Reflect.has(this, key)) {
        // @ts-expect-error do not understand what's going on
        (this as any)[key] = options[key];
      } else if (key === 'icons') {
        this.service.icons = options[key];
      }
    }
  }

  buildEmit(notification: Notification, to: boolean): Notification {
    const toEmit: Notification = {
      createdOn: notification.createdOn,
      type: notification.type,
      icon: notification.icon,
      id: notification.id
    };

    if (notification.html) {
      toEmit.html = notification.html;
    } else {
      toEmit.title = notification.title;
      toEmit.content = notification.content;
    }

    if (!to) {
      toEmit.destroyedOn = new Date();
    }

    return toEmit;
  }

  cleanSingle(id: string): void {
    let indexOfDelete = 0;
    let doDelete = false;
    let noti: Notification;

    this.notifications.forEach((notification, idx) => {
      if (notification.id !== id) { return; }
      indexOfDelete = idx;
      noti = notification;
      doDelete = true;
    });

    if (doDelete) {
      this.notifications.splice(indexOfDelete, 1);
      // @ts-expect-error bullshit
      this.destroy.emit(this.buildEmit(noti, false));
    }
  }
}
