import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';

@Component({
  selector: 'gg-header-mobile-nav',
  standalone: true,
  imports: [ SpCDKModule, RouterLink, RouterLinkActive ],
  templateUrl: './mobile-nav.component.html',
  styleUrls: [ './mobile-nav.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavComponent {
  protected readonly sections = inject(ConfigQuery).modules.header.mobileNavigation;
}
