import { Injector, RendererFactory2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { NoOp } from '@libs/utils';

import { ScriptService } from 'ngx-script-loader';
import { first } from 'rxjs/operators';

export abstract class AnalyticsHandler {
  protected constructor(protected readonly injector: Injector) {}

  abstract create(): void;

  abstract onNavigationEnds(event: NavigationEnd): void;
}

export abstract class LoaderAnalyticsHandler extends AnalyticsHandler {
  private readonly loader = this.injector.get(ScriptService);

  constructor(injector: Injector) { super(injector); }

  protected loadScript(url: string, callback: NoOp): void {
    this.loader.loadScript(url, { async: 'true' }).pipe(first()).subscribe(() => callback());
  }
}

export abstract class ElementAnalyticHandler extends AnalyticsHandler {
  private readonly renderer = this.injector.get(RendererFactory2).createRenderer(null, null);

  constructor(injector: Injector) { super(injector); }

  protected createElement(tag: string, attrs: Record<string, string>, parent: HTMLElement): void {
    const el = this.renderer.createElement(tag);
    Object.entries(attrs).forEach(([ attr, value ]) => this.renderer.setAttribute(el, attr, value));
    this.renderer.appendChild(parent, el);
  }
}
