import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { PaymentCardsData } from '@portal/payment/data';
import { CardInfo, CardSelectorState } from '@portal/payment/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gg-payment-cards-card-options',
  templateUrl: './card-options.component.html',
  styleUrls: [ './card-options.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardOptionsComponent {
  private readonly cardsData = inject(PaymentCardsData);
  readonly isCardOptionsShown: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input({ required: true }) card: Nullable<Partial<CardInfo>>;
  @Output() stateChanged: EventEmitter<CardSelectorState> = new EventEmitter();

  toggleCardOptions(): void {
    this.isCardOptionsShown.next(!this.isCardOptionsShown.value);
  }

  openDeleteBlock(id: string): void {
    this.cardsData.updateCardState(id, 'delete');
  }
}
