import { Nullable } from '@libs/utils';
import { VerificationMethod } from '@portal/verification/data/core';
import { BankIdVerificationMethod, DiiaVerificationMethod, DocumentsVerificationMethod, MonoVerificationMethod, PrivatVerificationMethod, ShuftiProVerificationMethod } from '@portal/verification/data/entities';

export abstract class VerificationMethodFactory {
  static create(method: string): Nullable<VerificationMethod> {
    if (method === 'by_bank_id_kycaid') { return new BankIdVerificationMethod(); }
    if (method === 'by_diia_kycaid') { return new DiiaVerificationMethod(); }
    if (method === 'by_doc_kycaid') { return new DocumentsVerificationMethod(); }
    if (method === 'by_monobank_id_kycaid') { return new MonoVerificationMethod(); }
    if (method === 'by_privatbank_id_kycaid') { return new PrivatVerificationMethod(); }
    if (method === 'by_doc_shufti_pro') { return new ShuftiProVerificationMethod(); }
    return;
  }
}
