import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { synchronize$ } from '@libs/store';
import { ConfigData, ConfigQuery } from '@portal/config';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {

  const isAuthorized$ = inject(SpAuthorizationService).authorized$;
  const sync = synchronize$(inject(ConfigData), inject(ConfigQuery));
  return () => isAuthorized$.pipe(sync, first());
};

export const configInitializer: Provider = { provide: APP_INITIALIZER, useFactory: init, multi: true };
