import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { HostedPipe, SpHttpModule } from '@libs/http';
import { BannersQuery } from '@portal/banners/data';
import { BannerComponent, PWA_APPLE_OS, PWADialogService, PWAService } from '@portal/banners/shared';
import { ConfigQuery } from '@portal/config';

@Component({
  standalone: true,
  imports: [ SpCDKModule, SpHttpModule ],
  selector: 'gg-banners-download-sidebar',
  templateUrl: './download-app-menu-banner.component.html',
  styleUrls: [ './download-app-menu-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ PWADialogService ]
})
export class DownloadAppMenuBannerComponent extends BannerComponent {
  private readonly hosted = inject(HostedPipe);
  private readonly platform = inject(SpPlatform);
  private readonly PWADialogService = inject(PWADialogService);
  private readonly downloadLink = inject(ConfigQuery).modules.apk.appDownloadLink;
  private readonly enabledApk = inject(ConfigQuery).modules.apk.enabled;
  private readonly enabledPwa = inject(ConfigQuery).modules.pwa.enabled;
  readonly authorized = inject(SpAuthorizationService).authorized;

  readonly os = this.platform.currentOS;
  readonly available$ = inject(BannersQuery).appBannersAvailable$;
  readonly isPWAAvailable$ = inject(PWAService).isAvailable({ storage: false, bets: false });

  get classWithOS(): string {
    const os = PWA_APPLE_OS.includes(this.os.toLowerCase()) ? 'apple' : this.os.toLowerCase();
    return `download-app--${os}`;
  }

  get isConfigAvailable(): boolean {
    return this.platform.device.android ? this.enabledApk : this.enabledPwa
  }

  processClick(): void {
    this.platform.device.android ? this.download() : this.openDialog();
  }

  download(): void { this.goToUrl(this.hosted.transform(this.downloadLink)); }

  openDialog(): void {
    this.PWADialogService.open();
  }
}
