import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { BannerComponent } from '@portal/banners/shared';
import { ControlsModule, ILink, LinkSizes, LinkThemes } from '@portal/shared/components/controls';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-banners-one-on-one',
  templateUrl: './one-on-one-banner.component.html',
  styleUrls: [ './one-on-one-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneOnOneBannerComponent extends BannerComponent {
  readonly goToGameButton: ILink = { size: LinkSizes.Medium, theme: LinkThemes.Green };
}
