import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pd-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentButtonComponent {
}
