import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import {
  AuthorizationComponent,
  LogoComponent,
  SidebarIconComponent,
  UserBalanceComponent
} from '@portal/layout/components';
import { MobileNavComponent } from '@portal/layout/components/header/mobile-nav';
import { BreadcrumbsComponent } from '@portal/layout/features/breadcrumbs/breadcrumbs.component';
import { QuickAccessLinksComponent, RewardsIconComponent } from '@portal/user';
import { HeaderComponent } from './header.component';
import { HeaderFlatComponent, HeaderRoundedComponent } from './theme';

@NgModule({
  imports: [SpCDKModule, ThemeModule, QuickAccessLinksComponent, RewardsIconComponent, AuthorizationComponent, LogoComponent, SidebarIconComponent, UserBalanceComponent, BreadcrumbsComponent, MobileNavComponent],
  declarations: [ HeaderComponent, HeaderFlatComponent, HeaderRoundedComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule {}
