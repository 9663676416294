<div class="bonus-card-container" ggCreateThemeClass="bonus-card-container" *ngIf="bonus">
  <gg-flip class="flip-wrapper" [flip]="(cardState$ | async) === 'flipped'">
    <div class="bonus-card-wrapper bonus-card-theme-{{bonus.type}}" front>
      <ng-container *ngIf="hasBonusDescription">
        <div class="bonus-icon" (click)="toggleCardDescription()">
          <img [src]="'/images/icons/{palette}/info.svg' | path" class="bonus-icon-info" alt=""/>
          <img [src]="'/images/icons/{palette}/info-hover.svg' | path" class="bonus-icon-info-hover" alt=""/>
        </div>
      </ng-container>
      <ng-container *ngIf="isWagerActive else info"><gg-bonus-card-progress [bonus]="bonus"/></ng-container>
      <ng-template #info><gg-bonus-card-info [bonus]="bonus"/></ng-template>
      <gg-bonus-card-additional-info [bonus]="bonus" [bonusesEnabled]="bonusesEnabled$ | async" (cancel)="toggleCardCancellation()"/>
    </div>
    <div class="bonus-card-wrapper bonus-card-theme-{{bonus.type}} bonus-card-back-side" back>
      <div class="bonus-icon" (click)="toggleCardDescription()">
        <img [src]="'/images/bonuses/{palette}/back.svg' | path" class="rotate-to-front-side" alt=""/>
        <img [src]="'/images/bonuses/{palette}/back-hover.svg' | path" class="rotate-to-front-side-hover" alt=""/>
      </div>
      <gg-bonus-card-description [bonus]="bonus" [bonusesEnabled]="bonusesEnabled$ | async"/>
    </div>
  </gg-flip>
  <div class="bonus-card-wrapper bonus-card-cancel" *ngIf="(cardState$ | async) === 'cancelling'">
    <gg-bonus-card-cancel [bonus]="bonus" (discard)="toggleCardCancellation()"></gg-bonus-card-cancel>
  </div>
</div>

