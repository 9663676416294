import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { BannerComponent } from '@portal/banners/shared';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ButtonSizes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ThemeModule, ControlsModule ],
  selector: 'gg-banners-square',
  templateUrl: './square-banner.component.html',
  styleUrls: [ './square-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SquareBannerComponent extends BannerComponent {
  private readonly breakpoints = inject(BreakpointObserver);

  readonly structure = inject(ConfigQuery).theme.structure;
  readonly button = this.getButtonSettings();

  getButtonSettings(): Observable<IButton> {
    return this.breakpoints.observe('(min-width: 769px)').pipe(map(({ matches }) =>
      ({ size: matches ? ButtonSizes.Medium : ButtonSizes.Small })
    ));
  }
}
