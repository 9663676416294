import { inject, Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { PaymentGroup, PaymentParamsQuery, PaymentState, PaymentStore } from '@portal/payment/data';
import { PaymentContainer } from '@portal/payment/data/entities/payment-container';
import { MethodsByParams, PaymentParams } from '@portal/payment/shared';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentGroupsQuery extends SteroidQuery<PaymentState> {
  readonly params$ = inject(PaymentParamsQuery).paymentParams$;

  readonly containers$ = this.params$.pipe(switchMap((params) => this.selectContainers(params)));
  readonly container$ = this.params$.pipe(switchMap((params) => this.selectContainer(params)));
  readonly groups$ = this.params$.pipe(switchMap((params) => this.selectGroups(params)));
  readonly group$ = this.params$.pipe(switchMap((params) => this.selectGroup(params)));
  readonly methods$ = this.params$.pipe(switchMap((params) => this.selectMethods(params)));
  readonly activeMethod$ = this.methods$.pipe(map(({ active }) => active));
  readonly hasGroupsOrMethods$ = this.params$.pipe(switchMap((params) => this.checkGroupsAndMethods(params)));

  readonly isCrypto$ = this.group$.pipe(map((group) => group?.type === 'crypto'));

  constructor(store: PaymentStore) { super(store); }

  private checkGroupsAndMethods(params: PaymentParams): Observable<boolean> {
    return combineLatest([ this.selectContainer(params), this.selectGroup(params) ]).pipe(
      map(([ container, group ]) => Boolean(container?.groups.length > 1 || group?.methods.length && group?.methods.length > 1)),
    );
  }

  private selectContainers(params: PaymentParams): Observable<Array<PaymentContainer>> {
    return this.select('container').pipe(map((groups) => groups[ params.section ]));
  }

  private selectContainer(params: PaymentParams): Observable<PaymentContainer> {
    return this.selectContainers(params).pipe(map((containers) => containers.find(({ id }) => id === params.container) || containers[ 0 ]));
  }

  private selectGroups(params: PaymentParams): Observable<Nullable<Array<PaymentGroup>>> {
    return this.selectContainer(params).pipe(map((container) => container?.groups));
  }

  private selectGroup(params: PaymentParams): Observable<Nullable<PaymentGroup>> {
    return this.selectGroups(params).pipe(
      map((groups) => groups?.find(({ id }) => id === params.group)),
    );
  }

  private selectMethods(params: PaymentParams): Observable<MethodsByParams> {
    return this.selectGroup(params).pipe(map((group) =>
      ({ active: group?.methods.find(({ id }) => params.method === id) || null, methods: group?.methods || [] }),
    ));
  }
}
