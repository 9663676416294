import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { UserQuery } from '@portal/user';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function hasPokerCredentialsGuard(): Observable<boolean | UrlTree> {
  const redirectUrl: UrlTree = inject(Router).createUrlTree([ '/poker', 'set-nickname' ]);
  const isAuth$ = inject(SpAuthorizationService).authorized$;
  const userQuery$ = inject(UserQuery);

  return combineLatest([ isAuth$, userQuery$.hasNickname$, userQuery$.passwordExists$, userQuery$.mustChangeNick$ ]).pipe(
    map(([ auth, hasNick, hasPass, mustChangeNick]) => {

      return (!auth || (auth && hasPass && (hasNick && !mustChangeNick))) || redirectUrl;
    })
  );
}
