import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { UserQuery } from '@portal/user';
import { VerificationQuery } from '@portal/verification/data';
import { VerificationState } from '@portal/verification/shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, RouterLink ],
  templateUrl: './verification-warning.component.html',
  styleUrls: [ './verification-warning.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationWarningComponent {
  private readonly user = inject(UserQuery);
  private readonly verification = inject(VerificationQuery);
  private readonly source = inject(ConfigQuery);

  readonly dialog = inject(Dialog);
  readonly isMobile = inject(SpPlatform).type.mobile;
  readonly verificationState$: Observable<Nullable<VerificationState>>;

  constructor() {
    const verificationParams: Array<string> = [ ...this.source.modules.cashier.verificationParams ];

    this.verificationState$ = combineLatest([ this.user.confirmation$, this.verification.isKycEnable$, this.user.kycStatus$ ]).pipe(
      map(([ confirmation, isKycEnable, kycStatus ]) => {
        if (isKycEnable && !verificationParams.includes('kyc')) { verificationParams.push('kyc'); }

        const count = Object.entries(confirmation).filter(([ key, value ]) => verificationParams.includes(key) && !!value).length;

        return {
          stat: { of: verificationParams.length, count },
          email: { enabled: true, confirmed: confirmation.email },
          phone: { enabled: this.source.modules.forms.enableMobilePhone, confirmed: confirmation.phone },
          kyc: { enabled: isKycEnable, status: kycStatus, confirmed: confirmation.kyc }
        };
      })
    );
  }
}
