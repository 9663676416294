import { ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';

const phonePattern = '^(\\s*)?(\\+)?([- _():=+]?\\d[- _():=+]?){5,12}(\\s*)?$';
const loginPattern = '^[a-zA-Z0-9@+\\-+_.]*$';

export const validationRules = {
  email: [ Validators.required, Validators.email ],
  login: [ Validators.required, Validators.minLength(3), Validators.maxLength(128),  Validators.pattern(loginPattern)],
  nick: [ Validators.required, Validators.minLength(3), Validators.maxLength(32), CustomValidators.lowerCase, CustomValidators.nickName ],
  password: [ Validators.required ],
  newPassword: [ Validators.required, Validators.minLength(8) ],
  phone: [ Validators.required, Validators.minLength(5), Validators.pattern(phonePattern) ],
  phoneVerificationCode: [ Validators.required ],
  complicatedPassword: [ Validators.required, CustomValidators.complicatedPassword ],
  promoCode: [],
  faqSearch: [ Validators.minLength(3) ],
  requiredCheckbox: [ CustomValidators.requiredCheckbox ],
  confirmationCode: [ Validators.required, Validators.minLength(4), Validators.maxLength(6) ],
  twoFactorAuthCode: [ Validators.required, Validators.minLength(6), Validators.maxLength(6) ],
  firstName: [ Validators.required, Validators.minLength(2), Validators.maxLength(64) ],
  lastName: [ Validators.required, Validators.minLength(2), Validators.maxLength(64) ],
  middleName: [ Validators.required, Validators.minLength(2), Validators.maxLength(64) ],
  account: [ Validators.required, Validators.maxLength(100) ],
  amount: [ Validators.required, Validators.min(1), CustomValidators.amount('int') ],
  matchFields: (matcher: string, matching: string): Array<ValidatorFn> =>
    [ CustomValidators.matchFields(matcher, matching) ],
  cvv: [ Validators.required, Validators.minLength(3), Validators.maxLength(3), CustomValidators.amount('int') ],
  expirationMonth: [ Validators.required, Validators.minLength(2), Validators.maxLength(2), CustomValidators.amount('int'), CustomValidators.expM ],
  expirationYear: [ Validators.required, Validators.minLength(2), Validators.maxLength(2), CustomValidators.amount('int'), CustomValidators.expY ],
  accountBankCard: [Validators.required, Validators.minLength(12), Validators.maxLength(19), CustomValidators.amount('int')],
  city: [ Validators.required, Validators.minLength(2), Validators.maxLength(512)  ],
  address: [ Validators.required, Validators.minLength(2), Validators.maxLength(512)  ],
  documentSerial: [ Validators.required, Validators.minLength(2), Validators.maxLength(10)  ],
  documentNumber: [ Validators.required, Validators.minLength(2), Validators.maxLength(12)  ],
  birthDate: [       Validators.required, CustomValidators.birthday ],
  username: [ Validators.required, Validators.minLength(2), Validators.maxLength(64), CustomValidators.username ]
};
