export enum PaycosMethods {
  Deposit = 214,
  Withdrawal = 221,
  WithdrawalMC = 295,
  WithdrawalVisa = 296,
  DFPayWithdrawalUA = 314,
  BankCardH2HDeposit = 379,
  BankCardH2HWithdrawal = 380,
  BankCardWithdrawalRub = 416
}
