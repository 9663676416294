import { ConnectorDescription } from '@libs/cdk';

export const connectorInfo: ConnectorDescription = {
  'aggregated data': {
    id: { description: 'user id', type: 'string | undefined' },
    language: { description: 'web page language', type: 'string' },
    translations: { description: 'list of translations for page language', type: 'Record<string, string>' },
    source: { description: 'project source', type: 'string' },
    fToken: { description: 'saved fToken', type: 'string | undefined' },
    refToken: { description: 'saved refresh token', type: 'string | undefined' },
    uriParams: { description: 'query params that available on application start', type: 'Params' },
  },
  'available events': {
    'page:redirect': {
      description: 'redirect to the specific url',
      payload: '{url: string, returnPath?: string; query?: Record<string, string>}',
      message: 'set_url',
    },
    'page:scroll': {
      description: 'scroll page to by X, Y axios',
      payload: '[ xAsios: number, yAxios: number ]',
      message: 'scroll',
    },
    'page:open-cashier': {
      description: 'open cashier',
      payload: null,
      message: 'openCashier',
    },
    'frame:redirect': {
      description: 'specific redirect with multiple options in payload',
      payload: '{ to: string; id: string; device: string; demo: boolean; returnPath: string }',
      message: 'redirect',
    },
    'frame:resize': {
      description: 'resize frame container according to received values',
      payload: '{width: number, height: number}',
      message: 'resize',
    },
    'frame:ready': {
      description: 'frame ready event',
      payload: null,
      message: 'gameFrameReady',
    },
    'log:user-autologin': {
      description: 'log user autologin',
      payload: '{ before: string; after: string }',
      message: 'userAutologin',
    },
    'log:user-language-change': {
      description: 'log user language change',
      payload: '{ userId: string, before: string; after: string }',
      message: 'userLanguageChange',
    },
    'log:user-select-games-section': {
      description: 'log user select games section',
      payload: '{ userId: string; section: string }',
      message: 'userSelectGamesSection',
    },
  },
};
