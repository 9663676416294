import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Breadcrumb, LayoutState, LayoutStore } from '@portal/layout/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsQuery extends SteroidQuery<LayoutState> {
  readonly breadcrumbs$: Observable<Array<Breadcrumb>> = this.select('breadcrumbs');

  constructor(store: LayoutStore) { super(store); }

  get breadcrumbs(): Array<Breadcrumb> { return this.get('breadcrumbs'); }
}
