import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Injector, OnDestroy, OnInit, Provider, ViewChild, ViewContainerRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { SpCDKModule, SpConnector, SpPlatform } from '@libs/cdk';
import { ClassConstructor, excludeNonNumericCharacters, GlobalWindow, Nullable, watch, withStartValue } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { Bonus } from '@portal/bonuses';
import { BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { BonusesSelectorModule } from '@portal/bonuses/features/bonuses-selector/bonuses-selector.module';
import { ConfigQuery } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { AmountSelectorComponent, ContainersNavigationComponent, SectionNavigationComponent } from '@portal/payment';
import { LoaderComponent, UserBalanceComponent } from '@portal/payment/components';
import { PaymentData, PaymentGroupsQuery, PaymentParamsQuery, PaymentResult } from '@portal/payment/data';
import { MethodsSelectorComponent } from '@portal/payment/features/methods-selector';
import { createPaymentForm, Limits, Payment, PaymentBuilder, PaymentService } from '@portal/payment/shared';
import { ContentModule, ControlsModule, DynamicComponent } from '@portal/shared/components';
import { IButton } from '@portal/shared/components/controls';
import { ConnectedEvents } from '@portal/shared/types';
import { UserQuery } from '@portal/user';
import { VerificationQuery } from '@portal/verification/data';
import { BehaviorSubject, combineLatest, delay, Observable, of, switchMap, tap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { paymentFlows } from './flows';
import { paymentMessages } from './messages';
import { paymentActions } from './post-actions';
import { paymentStatuses } from './statuses';

@Component({
  standalone: true,
  templateUrl: './payment-financial.component.html',
  styleUrls: [ './payment-financial.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ContainersNavigationComponent, UserBalanceComponent, CurrenciesModule, AmountSelectorComponent, ControlsModule, ContentModule, MatSidenavContainer, MatSidenav, BonusesSelectorModule, MethodsSelectorComponent, SectionNavigationComponent, LoaderComponent ],
})
export class PaymentFinancialComponent implements OnInit, OnDestroy {
  private readonly window = inject(WINDOW) as GlobalWindow;
  private readonly userQuery = inject(UserQuery);
  private readonly configQuery = inject(ConfigQuery);
  private readonly paymentData = inject(PaymentData);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly groupsQuery = inject(PaymentGroupsQuery);
  private readonly paramsQuery = inject(PaymentParamsQuery);
  private readonly verificationQuery = inject(VerificationQuery);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly platform = inject(SpPlatform);
  private readonly paymentService = inject(PaymentService);
  private readonly connector = inject(SpConnector<ConnectedEvents>);
  private readonly breakpoints = inject(BreakpointObserver);
  private readonly bonusesData = inject(BonusesData);

  private readonly paymentParams$ = this.paramsQuery.paymentParams$;
  private readonly watcher = watch();
  private readonly builder = new PaymentBuilder();
  private readonly modules = this.configQuery.modules;
  private readonly bonusSidenavOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly paymentInitiated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly depositsDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isMobile$ = this.breakpoints.observe('(max-width: 767px)').pipe(map(({ matches }) => matches));
  private readonly completedFlow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isMobile = toSignal(this.isMobile$);
  readonly isButtonDisabled = toSignal(this.disableButton());
  readonly isDeposit$ = this.paramsQuery.isDeposit$;
  readonly isCrypto$ = this.groupsQuery.isCrypto$;
  readonly hasGroupsOrMethods$ = this.groupsQuery.hasGroupsOrMethods$;
  readonly limits$ = this.extractLimits();
  readonly flow$ = this.createFlowWatcher();
  readonly message$ = this.createMessageWatcher();
  readonly action$ = this.createActionWatcher();
  readonly status$ = this.createStatusWatcher();
  readonly submitButton: IButton = { loading: false };
  readonly form: ReturnType<typeof createPaymentForm> = createPaymentForm(this.activeBonus);
  readonly bonusSidenavOpened$ = this.bonusSidenavOpened.asObservable();
  readonly depositsDisabled$ = this.depositsDisabled.asObservable();
  readonly activeMethod$ = this.groupsQuery.activeMethod$;
  readonly isLoaderVisible$ = this.builder.completed$;

  statusClass: Nullable<string> = '';

  selectedBonus: Nullable<Bonus> = null;

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  @ViewChild('page', { read: ViewContainerRef }) page: Nullable<ViewContainerRef>;
  @ViewChild('paymentSidenav', { static: true }) sidenav: Nullable<MatSidenav>;

  ngOnInit(): void {
    const propsChange = combineLatest([
      this.paymentParams$,
      withStartValue(this.form),
      this.groupsQuery.container$,
      this.groupsQuery.group$,
      this.groupsQuery.activeMethod$,
    ]).pipe(
      map((updater) => {
        const [ params, form, container, group, method ] = updater;
        const amount = excludeNonNumericCharacters(form.amount || params.amount);

        if (amount) { this.sidenav?.close(); }
        if (!method && !group) { this.builder.reopen(); }

        return { operation: params.section, amount, bonus: form.bonus, container, group, method };
      }),
    ).subscribe((params) => this.builder.updatePayment(params));

    this.paymentService.selfBlockedSection({ section: 'withdrawal', container: undefined, group: null, method: undefined });

    const amountWatcher = this.form.controls.amount.valueChanges.subscribe((amount) => this.setBonus(amount));
    const bonusWatcher = this.form.controls.bonus.valueChanges.subscribe((bonus) => this.setAmount(bonus));
    const statusWatcher = this.status$.pipe(delay(0)).subscribe(() => this.cd.detectChanges());
    const fingerprintWatcher = fromPromise(this.platform.fingerprint).pipe(
      first(),
    ).subscribe(fingerprint => this.builder.updatePayment({ data: { additional: { fingerprint } } }));

    const builderWatcher = combineLatest([ this.builder.completed$, this.paymentInitiated ])
      .subscribe(([ status, click ]) => { click && this.completedFlow.next(status); });

    const statusSidenavWatcher = this.sidenav?.openedChange.subscribe((open) => {
      if (!open) {
        this.builder.reopen();
        this.paymentInitiated.next(false);
      }
    });

    this.watcher.add([ propsChange, amountWatcher, bonusWatcher, statusWatcher, fingerprintWatcher, statusSidenavWatcher, builderWatcher ]);
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  selectBonus(event: Nullable<Bonus>): void {
    this.selectedBonus = event;
    this.closeSidenav();
  }

  openSidenav(section: 'flow' | 'bonus'): void {
    if (section === 'flow') { this.paymentInitiated.next(true); }

    this.bonusSidenavOpened.next(section === 'bonus');
    this.sidenav?.open();
  }

  closeSidenav(): void {
    this.bonusSidenavOpened.next(false);
    this.paymentInitiated.next(false);
    this.sidenav?.close();
  }

  private disableButton(): Observable<boolean> {
    return combineLatest([ this.groupsQuery.activeMethod$, this.groupsQuery.group$, this.builder.payment$ ]).pipe(
      distinctUntilChanged(),
      map(([ method, groups, { amount } ]) => {

        if (groups?.type === 'crypto' && !this.paramsQuery.isWithdrawal) { return false; }
        if (!method || (this.paramsQuery.isWithdrawal && this.userQuery.balance?.balance === '0.00')) { return true; }
        if (this.paramsQuery.isWithdrawal && Number(amount) > Number(this.userQuery.balance?.balance)) { return true; }
        if (method?.amounts.limits?.min && method?.amounts.limits?.max) {
          return this.checkLimits(method.amounts.limits?.min, method.amounts.limits?.max, amount);
        }

        return this.checkLimits(groups?.limits?.min, groups?.limits?.max, amount);
      }),
    );
  }

  private checkLimits(minLimit: Nullable<number>, maxLimit: Nullable<number>, amount: Nullable<number>): boolean {
    const min = minLimit ?? Number.POSITIVE_INFINITY;
    const max = maxLimit ?? Number.POSITIVE_INFINITY;

    return !(Number(amount) >= min && Number(amount) <= max);
  }

  private setBonus(amount: number): void {
    const bonus = this.form.controls.bonus;
    if (isNaN(Number(amount)) || amount >= Number(bonus.value?.activation.price)) { return; }
    bonus.setValue(null);
    this.bonusesData.unselectBonus();
    this.selectBonus(null);
  }

  private setAmount(bonus: Nullable<Bonus>): void {
    if (!bonus) { return; }

    const amount = this.form.controls.amount;
    if (Number(bonus?.activation.price) < amount.value) { return; }
    amount.setValue(Number(bonus?.activation.price), { emitEvent: false });
  }

  private extractLimits(): Observable<Limits> {
    return combineLatest([ this.groupsQuery.container$, this.groupsQuery.group$, this.groupsQuery.methods$ ]).pipe(
      map(([ container, group, method ]) => {
        if (method.active?.amounts.limits.min && method.active?.amounts.limits.max) {
          return { ...method.active.amounts.limits, rounding: method.active.amounts.rounding };
        }
        if (group) { return { ...group.limits }; }

        return { ...container.limits };
      }),
    );
  }

  private createFlowWatcher(): Observable<Nullable<DynamicComponent>> {
    return combineLatest([ this.paymentInitiated, this.groupsQuery.group$ ]).pipe(
      map(([ initiated, group ]) => initiated && group?.type ? this.createComponent(paymentFlows[ group.type ]) : null),
    );
  }

  private createMessageWatcher(): Observable<Nullable<DynamicComponent>> {
    const { getActiveStatusBonuses$ } = this.bonusesQuery;
    const { confirmation$, restrictedPages$, hasBonusBalance$ } = this.userQuery;
    const { isKycEnable$ } = this.verificationQuery;

    return combineLatest([ this.isDeposit$, this.isCrypto$, hasBonusBalance$, getActiveStatusBonuses$, confirmation$, restrictedPages$, isKycEnable$ ]).pipe(
      map(([ isDeposit, isCrypto, hasBonusBalance, activeStatusBonuses, confirmation, restrictedPages, isKycEnabled ]) => {
        const { email, phone, kyc } = confirmation;
        const { depositsEnabled } = restrictedPages || {};
        const { authentication: { showEmailConfirmation }, cashier: { enableStubForCryptocurrency } } = this.modules;
        const credentials = Object.values({
          withEmail: showEmailConfirmation && !isKycEnabled && email && phone,
          withoutEmail: !showEmailConfirmation && !isKycEnabled && phone,
          withoutEmailKyc: !showEmailConfirmation && isKycEnabled && phone && kyc,
          withEmailKyc: showEmailConfirmation && isKycEnabled && email && phone && kyc,
        }).some(Boolean);

        if (!isDeposit && (hasBonusBalance || activeStatusBonuses.length)) { return 'active-bonus'; }
        if (!isDeposit && !credentials) { return 'withdraw-verification'; }
        if (isDeposit && enableStubForCryptocurrency && !email && isCrypto) { return 'crypto-verification'; }
        if (isDeposit && !depositsEnabled) {
          this.depositsDisabled.next(true);
          return 'deposit-verification';
        } else { this.depositsDisabled.next(false); }

        return null;
      }),
      map((message) => message ? this.createComponent(paymentMessages[ message ]) : null),
    );
  }

  private createActionWatcher(): Observable<Nullable<DynamicComponent>> {
    return this.completedFlow.pipe(
      switchMap((completed) => {
        if (!completed) { return of(null); }

        const payment = this.builder.snapshot;
        const query = payment.method?.id === '386'
          ? this.paymentData.getPaymentCode(payment.method.id)
          : this.createPayment(payment);

        return query.pipe(
          map((result) => {
            const { name, payload } = result.action || {};
            const action = paymentActions[ `${name}:${payload}` ] || paymentActions[ 'noAction' ];

            if (!result.action) {
              this.closeSidenav();
              this.paymentInitiated.next(false);
            }

            return this.createComponent(action, [ { provide: PaymentResult, useValue: result } ]);
          }));
      }),
    );
  }

  private createPayment(payment: Payment): Observable<PaymentResult> {
    if (payment.operation === 'deposit') {
      this.connector.sendEvent({ event: 'analytics:create-custom-pixel', payload: { type: 'cashier' } });
    }
    return this.paymentData.create(payment, this.window);
  }

  private createStatusWatcher(): Observable<Nullable<DynamicComponent>> {
    return this.paymentParams$.pipe(
      distinctUntilChanged((prev, curr) => curr.status === prev.status),
      tap(({ status }) => {
        this.statusClass = status ? 'status' : '';
      }),
      map(({ status }) => status && paymentStatuses[ status ] ? this.createComponent(paymentStatuses[ status ]) : null),
      tap((component) => {
        if (component) {
          this.paymentInitiated.next(false);
          this.builder.reopen();
        }
      }),
    );
  }

  private createComponent(component?: ClassConstructor, providers: Array<Provider> = []): Nullable<DynamicComponent> {
    const injector = Injector.create({ providers: [ { provide: PaymentBuilder, useValue: this.builder }, ...providers ] });
    return component ? new DynamicComponent(component, injector) : null;
  }

}
