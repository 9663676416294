export enum PaymentCenterPaymentMethods {
  BankCardDeposit = 59,
  BankCardDepositRebill = 61,
  BankCardWithdrawal = 60,
  BankCardDepositNew = 250,
  BankCardDepositH2h = 382,
  SBPWithdrawal = 396,
  P2PDeposit = 417,
  P2PWithdrawal = 418
}
