<section class="withdrawal-verification">
  <div class="withdrawal-verification__wrapper">
    <img src="assets/images/verification/not-verified.svg" class="withdrawal-verification__img" alt=""/>
    <h4 class="withdrawal-verification__text" spMsg="CASHIER.VERIFICATION.END_VERIFICATION"></h4>
    @if (verificationState$ | async; as verification) {
      <div class="withdrawal-verification__checklist">
        <div class="withdrawal-verification__number">
          <span class="withdrawal-verification__green">{{ verification.stat.count }}</span> / {{ verification.stat.of }}
          <span class="withdrawal-verification__ended" spMsg="CASHIER.VERIFICATION.ENDED"></span>
        </div>
        <div class="withdrawal-verification__options">
          <div class="withdrawal-verification__option">
            <div class="withdrawal-verification__option-title"
                 [class.withdrawal-verification__option-title--complete]="verification.email.confirmed">
              <sp-msg msg="PROFILE.CONTACT_DETAILS.EMAIL"/>
            </div>
          </div>
          @if (verification.phone.enabled) {
            <div class="withdrawal-verification__option">
              <div class="withdrawal-verification__option-title"
                   [class.withdrawal-verification__option-title--complete]="verification.phone.confirmed">
                <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"/>
              </div>
            </div>
          }
          @if (verification.kyc.enabled) {
            <div class="withdrawal-verification__option" [ngClass]="{'withdrawal-verification__option--complete':
            verification.kyc.confirmed}">
              <div class="withdrawal-verification__option-status">
                <div
                  class="withdrawal-verification__option-title"
                  [class.withdrawal-verification__option-title--pending]="verification.kyc.status === 'pending'"
                  [class.withdrawal-verification__option-title--complete]="verification.kyc.confirmed"
                >
                  <sp-msg msg="PROFILE.CONTACT_DETAILS.USER_VERIFICATION"/>
                </div>
                @if (verification.kyc.status === 'pending') {
                  <sp-msg class="withdrawal-verification__option-sub-title" msg="PROFILE.KYCAID.USER_VERIFICATION_IN_PROGRESS"/>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <gg-button
        class="withdrawal-verification__button"
        [routerLink]="verification.kyc.confirmed && confirmationKyc ? [ '/user', 'settings', 'contact-details' ] : [ '/user', 'verification' ]"
        (click)="dialog.closeAll()">
        <sp-msg msg="CASHIER.VERIFICATION.GO_TO_VERIFICATION"/>
      </gg-button>
    }
  </div>
</section>
