import { inject, Pipe, PipeTransform } from '@angular/core';
import { ConfigQuery } from '@portal/config/data';
import { PartialPath } from '@portal/config/shared';

@Pipe({ name: 'project' })
export class ProjectPipe extends PartialPath implements PipeTransform {
  protected readonly replace = '{project}';
  protected readonly with = inject(ConfigQuery).project.name;
}

@Pipe({ name: 'palette' })
export class PalettePipe extends PartialPath implements PipeTransform {
  protected readonly replace = '{palette}';
  protected readonly with = inject(ConfigQuery).theme.palette;
}

@Pipe({ name: 'structure' })
export class StructurePipe extends PartialPath implements PipeTransform {
  protected readonly replace = '{structure}';
  protected readonly with = inject(ConfigQuery).theme.structure;
}

@Pipe({ name: 'path' })
export class PathPipe extends PartialPath implements PipeTransform {
  private readonly palette = inject(PalettePipe);
  private readonly structure = inject(StructurePipe);

  protected readonly replace = `{project}`;
  protected readonly with = `project/${inject(ConfigQuery).project.name}`;

  override transform(value: string): string {
    return `/assets/${super.transform(this.palette.transform(this.structure.transform(value)))}`;
  }
}
