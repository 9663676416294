import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Nullable } from '@libs/utils';
import { AbstractMessage } from './abstract.msg';

@Component({
  selector: 'sp-msg',
  template: `<span #placeholder><ng-content></ng-content></span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpMessageComponent extends AbstractMessage {
  @ViewChild('placeholder', { static: true, read: ElementRef }) element: ElementRef | null = null;
  @Input() msg: Nullable<string> = null;
}
