import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { RadioButtonComponent } from './radio-button.component';
import { RadioGroupComponent } from './radio-group.component';

@NgModule({
  imports: [ SpCDKModule ],
  declarations: [ RadioGroupComponent, RadioButtonComponent ],
  exports: [ RadioGroupComponent, RadioButtonComponent ]
})
export class RadioModule {}
