import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NewBankCardResponse } from '@portal/cashier/data';
import { BankCard, createBankCard, PaymentStore } from '@portal/payment/data';
import { BankCardDetailsResponse, BankCardsListResponse, CardInfo } from '@portal/payment/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentCardsData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(PaymentStore);
  private readonly cards = this.store.entity('cards');

  synchronize(): void {
    this.http.get<BankCardsListResponse>('/api/payments/deposit/card_type/get_user_cards').pipe(
      map(({ _items }) => _items.map(createBankCard)),
      catchError((error) => throwError(() => ResponseFactory.error(error))),
    ).subscribe((cards) => this.cards.set(cards));
  }

  update(card: CardInfo): Observable<CardInfo> {
    return card.id ? this.details(card.id) : this.add(card).pipe(map((id) => ({ id })));
  }

  updateCardState(id: CardInfo['id'], status: string): void {
    this.cards.update((state) => {
      const cardInfo = state.entities ? { ...state?.entities[ id as string ] } : {};
      return { ...state, entities: { ...state.entities, [ id as string ]: { ...cardInfo, status } } };
    });
  }

  delete(id: string): Observable<unknown> {
    return this.http.post(`/api/payments/deposit/card_type/delete_card`, { id: id }).pipe(
      tap(() => this.cards.remove(id)),
      catchError(error => throwError(() => ResponseFactory.error(error))),
    );
  }

  private add(cardData: CardInfo): Observable<string> {
    const card = { ...cardData, exp_month: cardData.expMonth, exp_year: cardData.expYear };
    return this.http.post<NewBankCardResponse>(`/api/payments/deposit/card_type/set_new_card_data`, card).pipe(
      tap((newCard) => this.cards.update({ newCardId: newCard._item.id })),
      map((newCard) => newCard._item.id),
      catchError(error => throwError(() => ResponseFactory.error(error))),
    );
  }

  private details(id: string): Observable<BankCard> {
    return this.http.get<BankCardDetailsResponse>(`/api/payments/deposit/card_type/get_user_card_data/${id}`).pipe(
      map(({ _items }: BankCardDetailsResponse) => createBankCard(_items)),
      tap((card) => this.cards.update({ selectedCard: card })),
      catchError(error => throwError(() => ResponseFactory.error(error))),
    );
  }

}
