<div class="card-change" *ngIf="cards">
  <ng-container *ngFor="let card of cards">
    <gg-payment-cards-card-details
      class="card-change__card"
      [class.card-change__card_active]="card.id === activeCard"
      [card]="card" [withCVVControl]="false"
      (click)="selectCard(card)"
    />
  </ng-container>
  <gg-button class="card-change__save-button" [settings]="submitButton" (buttonClicked)="save.emit()">
    <sp-msg msg="COMMON.SAVE"></sp-msg>
  </gg-button>
</div>
