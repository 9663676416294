import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { ticker } from '@libs/utils';
import { EmailConfirmationService } from '@portal/user/features/email-confirmation/email-confirmation.service';

@Component({
  selector: 'gg-user-email-confirmation-resent',
  templateUrl: './email-resent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailResentComponent implements OnDestroy {
  private readonly confirmationService = inject(EmailConfirmationService);
  private readonly cd = inject(ChangeDetectorRef);
  readonly timer$;
  timer: number = 0;

  constructor() {
    this.timer$ = ticker(30).subscribe({
      next: (n) => {
        this.timer = n;
        this.cd.detectChanges();
      },
      complete: () => this.confirmationService.changeViewState('notConfirmed')
    });
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }
}
