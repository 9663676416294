export function checkWebApiAvailability(storage: Storage | null): boolean {
  if (!storage) { return false; }
  try {
    const mock = '_storage_test_';
    storage.setItem(mock, '');
    storage.removeItem(mock);
    return true;
  } catch (error) {
    if (error instanceof DOMException) { return false; }
    const { code, name } = error as DOMException;
    const hasErrorInCodes = code === 22 || code === 1014;
    const hasErrorInNames = name === 'QuotaExceededError' || name === 'NS_ERROR_DOM_QUOTA_REACHED';

    return (hasErrorInCodes || hasErrorInNames) && !!storage.length;
  }
}
