import { ClassConstructor } from '@libs/utils';
import { DesktopPWAInstallDialogComponent } from './desktop-pwa-install/desktop-pwa-install-dialog.component';
import { DesktopPWAInstructionDialogComponent } from './desktop-pwa-instruction/desktop-pwa-instruction-dialog.component';
import { IOSPWAInstructionDialogComponent } from './ios-pwa-instruction/ios-pwa-instruction-dialog.component';

export * from './desktop-pwa-install/desktop-pwa-install-dialog.component';
export * from './desktop-pwa-instruction/desktop-pwa-instruction-dialog.component';
export * from './ios-pwa-instruction/ios-pwa-instruction-dialog.component';

export const PWADialogs: Record<string, Record<string, ClassConstructor>> = {
  desktop: {
    'instruction': DesktopPWAInstructionDialogComponent,
    'install': DesktopPWAInstallDialogComponent
  },
  mobile: {
    'instruction': IOSPWAInstructionDialogComponent
  }
};
