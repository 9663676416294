<div class="amount__wrapper">
  <h3 class="amount__title" spMsg="CASHIER.CARD.INSERT_AMOUNT"></h3>
  <section class="amount__counter-block" ggCreateThemeClass="counter-block">
    <button type="button" class="counter minus" (click)="decrease()">
      <img class="counter__svg" alt="" src="assets/images/icons/minus.svg"/>
    </button>
    <div class="error-wrapper">
      <div class="sum-wrapper">
        <input #field type="text" inputmode="numeric" class="sum" placeholder="{{'CASHIER.CARD.INSERT_AMOUNT' | translate}}" [formControl]="control"/>
        <span class="currency">{{ currency | currencySymbol }}</span>
      </div>
    </div>
    <button type="button" class="counter plus" (click)="increase()">
      <img src="assets/images/icons/plus.svg" class="counter__icon" alt=""/>
    </button>
  </section>
  <p class="amount__limits">
    <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"/>
    {{ limits?.min | currencyNormalizer }}
    | <sp-msg spMsg="CASHIER.CARD.MAX" spaces="wrap"/>
    {{ limits?.max | currencyNormalizer }}
  </p>
</div>
