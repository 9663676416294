import { Nullable } from '@libs/utils';
import { CryptoWallet, FeeOption, Fees, FormDeclaration } from '@portal/payment/shared';

export class PaymentMethod {
  constructor(
    readonly id: string,
    readonly amounts: {
      fee: Nullable<Fees>;
      rounding: Nullable<number>;
      maxWithdrawal: Nullable<number>;
      limits: { min: number; max: number; inputMax?: number };
    },
    readonly data: {
      cvv: boolean;
      payment: Nullable<FormDeclaration>;
      additional: Nullable<FormDeclaration>;
      wallet: Nullable<CryptoWallet>;
    },
    readonly view: {
      limits: Nullable<{ min: string; max: string }>;
      commission: { rules: Nullable<Array<FeeOption>>; current: number };
      name: Nullable<string>;
      banner: Nullable<string>;
      logo: { primary: string; secondary: string };
    }
  ) {}
}
