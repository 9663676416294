// @ts-nocheck

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { USER_AGENT, WINDOW } from '@ng-web-apis/common';
import { CactusData } from '@portal/cashier/data';
import { CreateDeposit } from '@portal/cashier/data/types/create-deposit.type';
import { CreateWithdrawal } from '@portal/cashier/data/types/create-withdrawal.type';
import { ICashierStep } from '@portal/shared/components/controls/inputs/input-cash/input-cash.component';
import { normalizeFloatAmount, Nullable } from 'libs/utils/src';
import { map, Observable, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { CASHIER_STEPS } from './constants/stepper.constant';
import { LeoPaymentMethods } from './types/enums/payment-methods/leo-payments-methods.enum';
import { PaymegaPaymentMethods } from './types/enums/payment-methods/paymega-methods';
import { PayperPaymentMethods } from './types/enums/payment-methods/payper-methods.enum';
import { XpatePaymentMethods } from './types/enums/payment-methods/xpate-payment-methods.enum';
import { BrowserInfo } from './types/interfaces/browser-info.interface';
import { FeeInterface } from './types/interfaces/fee.interface';

@Injectable()
export class CashierService {
  private readonly http = inject(HttpClient);
  private readonly window = inject(WINDOW);
  private readonly userAgent = inject(USER_AGENT);
  private readonly platform = inject(SpPlatform);

  findStepsByCurrency(currency: Nullable<string>): Array<ICashierStep> {
    switch (currency) {
      case 'USD':
      case 'EUR':
        return CASHIER_STEPS.usd;
      case 'KZT':
        return CASHIER_STEPS.kzt;
      case 'UAH':
        return CASHIER_STEPS.uah;
      case 'UZS':
        return CASHIER_STEPS.uzs;
      case 'AZN':
        return CASHIER_STEPS.azn;
      case 'CAD':
        return CASHIER_STEPS.cad;
      case 'INR':
        return CASHIER_STEPS.inr;
      case 'USDT':
        return CASHIER_STEPS.usdt;
      case 'BTC':
        return CASHIER_STEPS.btc;
      case 'ETH':
        return CASHIER_STEPS.eth;
      case 'LTC':
        return CASHIER_STEPS.ltc;
      default:
        return CASHIER_STEPS.rub;
    }
  }

  isLeoPaymentWidget(method): boolean {
    return method === LeoPaymentMethods.Deposit;
  }

  isPaymegaWidget(method): boolean {
    return [
      PaymegaPaymentMethods.Deposit,
      PaymegaPaymentMethods.DepositFirst,
      PaymegaPaymentMethods.DepositJoker
    ].includes(method);
  }

  NewXpatePaymentMethods(method): boolean {
    return [
      XpatePaymentMethods.NewBankCardDepositKzt,
      XpatePaymentMethods.NewBankCardDeposit,
      XpatePaymentMethods.NewBankCardDepositEU,
      XpatePaymentMethods.NewBankCardDepositUntrustedEU,
      XpatePaymentMethods.BankCardDepositInterac,
      XpatePaymentMethods.NewBankCardDepositUntrusted
    ].includes(method);
  }

  createWithdrawRequest(withdrawal: CreateWithdrawal, cactusData?: CactusData): Observable<any> {
    let body = {
      amount: withdrawal.amount,
      payment_method: withdrawal.method,
      cardholder_name: withdrawal.cardholder_name,
      expiry_month: withdrawal.expiry_month,
      expiry_year: withdrawal.expiry_year,
      browser_info: this.getBrowserInfo(),
      pending_url: null,
      page_url: window.location.origin,
      account: withdrawal.account,
      card_data: withdrawal.card_data,
      payment_data: withdrawal.payment_data ? { ...withdrawal.payment_data } : null,
      bank_id: withdrawal.bank_id,
      operator_id: withdrawal.operator_id,
      verification_code: withdrawal.verification_code ? withdrawal.verification_code : null
    };

    const pendingMethods = [
      PayperPaymentMethods.WithdrawalTransfer,
      PayperPaymentMethods.WithdrawalBanking
    ];

    if (pendingMethods.includes(withdrawal.method)) {
      body.pending_url = withdrawal.url;
    }

    if (cactusData) {
      body = {
        ...body,
        address: cactusData.address ? cactusData.address : null,
        city: cactusData.city ? cactusData.city : null,
        messenger: cactusData.messenger ? cactusData.messenger : null,
        username: cactusData.username ? cactusData.username : null
      }
    }

    return this.http.post('/api/payments/withdrawal/create/', body);
  }

  createDepositRequest(deposit: CreateDeposit, cactusData?: CactusData): Observable<any> {
    return fromPromise(this.platform.fingerprint).pipe(
      map((fingerprint) => {
        let body = {
          amount: deposit.amount,
          payment_method: deposit.method || null,
          error_url: `${deposit.url}/error`,
          pending_url: `${deposit.url}/progress`,
          success_url: deposit.method !== LeoPaymentMethods.Deposit ? `${deposit.url}/success` : `${deposit.url}/going`,
          cancel_url: deposit.url.replace('/transaction', ''),
          present_id: deposit.bonus ? deposit.bonus.id : '',
          present_type: deposit.bonus ? deposit.bonus.type : '',
          page_url: window.location.origin,
          token: null,
          account: deposit.account ? deposit.account : null,
          card_data: deposit.cvv
            ? { cvv: deposit.cvv, exp_month: deposit.exp_month, exp_year: deposit.exp_year }
            : deposit.exp_month && deposit.exp_year ? {
                exp_month: deposit.exp_month,
                exp_year: deposit.exp_year
              }
              : null,
          browser_info: this.getBrowserInfo(fingerprint),
          payment_data: deposit.payment_data ? { ...deposit.payment_data } : null,
          user_card_id: deposit.user_card_id,
          operator: deposit.operator,
          code: deposit.code ? deposit.code : null,
          transaction_id: deposit.transaction_id ? deposit.transaction_id : null,
          initiatorID: deposit.initiator_id ? deposit.initiator_id : null,
          bank_id: deposit.bank_id ? deposit.bank_id : null,
        };

        if (cactusData) {
          body = {
            ...body,
            address: cactusData.address ? cactusData.address : null,
            city: cactusData.city ? cactusData.city : null,
            messenger: cactusData.messenger ? cactusData.messenger : null,
            username: cactusData.username ? cactusData.username : null
          }
        }

        return body;
        }),
      switchMap((body) => this.http.post(deposit.postUrl, body))
    )
  }

  isShowDynamicCommission(fee: FeeInterface): boolean {
    return !!fee.rules.length;
  }

  makePercentCommission(rate: string | number): number {
    return Number(rate) * 100;
  }

  makeDynamicCommissionRule(rules): object {
    return rules.filter((rule) => {
      return !!rule.left && rule.left > 0;
    }).map((rule) => {
      return { ...rule, rate: Number(rule.rate) * 100 };
    });
  }

  makeCommissionAmount(amount, rate): string {
    return (normalizeFloatAmount(amount) * Number(rate)).toFixed(2);
  }

  makeAmountAfterCommission(amount, rate): string {
    const amountRate = 1 - rate;
    return (normalizeFloatAmount(amount) * Number(amountRate)).toFixed(2);
  }

  private getBrowserInfo(fingerprint?: Nullable<number>): BrowserInfo {
    return {
      browser_tz: new Date().getTimezoneOffset(),
      browser_screen_width: this.window.screen.availWidth,
      browser_screen_height: this.window.screen.availHeight,
      browser_color_depth: this.window.screen.colorDepth,
      browser_language: this.window.navigator.language,
      browser_java_enabled: false,
      browser_user_agent: this.userAgent,
      window_width: this.window.screen.width,
      window_height: this.window.screen.height,
      browser_javascript_enabled: true,
      browser_tz_name: this.window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      browser_iframe_width: this.window.innerWidth,
      browser_iframe_height: this.window.innerHeight,
      browser_fingerprint: fingerprint
    };
  }
}
