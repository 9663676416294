import { StepInterface } from '../types/interfaces/step.interface';

export const CASHIER_STEPS: { [language: string]: Array<StepInterface> } = {
  rub: [
    { from: 0, to: 1000, step: 200 },
    { from: 1000, to: 3000, step: 500 },
    { from: 3000, to: 10000, step: 1000 },
    { from: 10000, step: 5000 }
  ],
  uah: [
    { from: 0, to: 400, step: 100 },
    { from: 400, to: 1000, step: 200 },
    { from: 1000, to: 3000, step: 500 },
    { from: 3000, to: 10000, step: 1000 },
    { from: 10000, step: 5000 }
  ],
  usd: [
    { from: 0, to: 10, step: 2 },
    { from: 10, to: 30, step: 5 },
    { from: 30, to: 100, step: 10 },
    { from: 100, step: 50 }
  ],
  kzt: [
    { from: 0, to: 4000, step: 500 },
    { from: 4000, to: 10000, step: 2000 },
    { from: 10000, to: 50000, step: 5000 },
    { from: 50000, step: 10000 }
  ],
  uzs: [
    { from: 0, to: 50000, step: 10000 },
    { from: 50000, to: 200000, step: 50000 },
    { from: 200000, step: 100000 }
  ],
  azn: [
    { from: 0, to: 20, step: 5 },
    { from: 20, to: 50, step: 10 },
    { from: 50, to: 100, step: 50 },
    { from: 100, to: 500, step: 100 },
    { from: 100, to: 1000, step: 250 },
    { from: 1000, step: 500 }
  ],
  cad: [
    { from: 0, to: 100, step: 10 },
    { from: 100, to: 500, step: 50 },
    { from: 500, step: 100 }
  ],
  inr: [
    { from: 0, to: 1000, step: 100 },
    { from: 1000, to: 5000, step: 500 },
    { from: 5000, step: 1000 }
  ],
  usdt: [
    { from: 0, to: 10, step: 2 },
    { from: 10, to: 30, step: 5 },
    { from: 30, to: 100, step: 10 },
    { from: 100, step: 50 }
  ],
  btc: [
    { from: 0, to: 10, step: 0.00001 },
    { from: 10, to: 100, step: 0.001 }
  ],
  eth: [
    { from: 0, to: 10, step: 0.0001 },
    { from: 10, to: 100, step: 0.01 }
  ],
  ltc: [
    { from: 0, to: 10, step: 0.0001 },
    { from: 10, to: 100, step: 0.01 }
  ]
};
