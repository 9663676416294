import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
  ViewRef
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Nullable } from '@libs/utils';
import { NotificationsService } from '../../notifications.service';
import { Notification, NotificationAnimationType } from '../../utils';

@Component({
  selector: 'sp-notification',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('enterLeave', [

      // Fade
      state('fade', style({ opacity: 1 })),
      transition('* => fade', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out')
      ]),
      state('fadeOut', style({ opacity: 0 })),
      transition('fade => fadeOut', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out')
      ]),

      // Enter from top
      state('fromTop', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('* => fromTop', [
        style({ opacity: 0, transform: 'translateY(-5%)' }),
        animate('400ms ease-in-out')
      ]),
      state('fromTopOut', style({ opacity: 0, transform: 'translateY(5%)' })),
      transition('fromTop => fromTopOut', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('300ms ease-in-out')
      ]),

      // Enter from right
      state('fromRight', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => fromRight', [
        style({ opacity: 0, transform: 'translateX(5%)' }),
        animate('400ms ease-in-out')
      ]),
      state('fromRightOut', style({ opacity: 0, transform: 'translateX(-5%)' })),
      transition('fromRight => fromRightOut', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('300ms ease-in-out')
      ]),

      // Enter from bottom
      state('fromBottom', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('* => fromBottom', [
        style({ opacity: 0, transform: 'translateY(5%)' }),
        animate('400ms ease-in-out')
      ]),
      state('fromBottomOut', style({ opacity: 0, transform: 'translateY(-5%)' })),
      transition('fromBottom => fromBottomOut', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('300ms ease-in-out')
      ]),

      // Enter from left
      state('fromLeft', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => fromLeft', [
        style({ opacity: 0, transform: 'translateX(-5%)' }),
        animate('400ms ease-in-out')
      ]),
      state('fromLeftOut', style({ opacity: 0, transform: 'translateX(5%)' })),
      transition('fromLeft => fromLeftOut', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('300ms ease-in-out')
      ]),

      // Rotate
      state('scale', style({ opacity: 1, transform: 'scale(1)' })),
      transition('* => scale', [
        style({ opacity: 0, transform: 'scale(0)' }),
        animate('400ms ease-in-out')
      ]),
      state('scaleOut', style({ opacity: 0, transform: 'scale(0)' })),
      transition('scale => scaleOut', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('400ms ease-in-out')
      ]),

      // Scale
      state('rotate', style({ opacity: 1, transform: 'rotate(0deg)' })),
      transition('* => rotate', [
        style({ opacity: 0, transform: 'rotate(5deg)' }),
        animate('400ms ease-in-out')
      ]),
      state('rotateOut', style({ opacity: 0, transform: 'rotate(-5deg)' })),
      transition('rotate => rotateOut', [
        style({ opacity: 1, transform: 'rotate(0deg)' }),
        animate('400ms ease-in-out')
      ])
    ])
  ],
  templateUrl: './notification.component.html',
  styleUrls: [ './notification.component.css' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationComponent implements OnInit, OnDestroy {
  private readonly framesPerSecond = 40;
  private readonly icon: string = '';
  private stopTime = false;
  private timer: any = 0;
  private sleepTime: number = 0;
  private startTime: number = 0;
  private endTime: number = 0;
  private pauseStart: number = 0;

  // Progress bar variables
  title: Nullable<string>;
  content: Nullable<string>;

  titleIsTemplate = false;
  contentIsTemplate = false;
  htmlIsTemplate = false;

  progressWidth = 0;
  safeSvg: Nullable<SafeHtml>;
  safeInputHtml: Nullable<SafeHtml>;

  @Input() timeOut: number = 0;
  @Input() showProgressBar: boolean = false;
  @Input() pauseOnHover: boolean = true;
  @Input() clickToClose: boolean = true;
  @Input() clickIconToClose: boolean = true;
  @Input() maxLength: number = 0;
  @Input() theClass: string = '';
  @Input() rtl: boolean = false;
  @Input() animate: NotificationAnimationType = NotificationAnimationType.Fade;
  @Input() position: number = 0;
  @Input() item: Nullable<Notification>;

  constructor(
    private readonly notificationService: NotificationsService,
    private readonly domSanitizer: DomSanitizer,
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone
  ) {}

  ngOnInit(): void {
    if (this.item?.override) { this.attachOverrides(); }

    if (this.item && this.animate) { this.item.state = this.animate; }

    if (this.timeOut !== 0) { this.startTimeOut(); }

    if (this.item) {
      this.contentType(this.item.title, 'title');
      this.contentType(this.item.content, 'content');
      this.contentType(this.item.html, 'html');
    }

    this.safeSvg = this.domSanitizer.bypassSecurityTrustHtml(this.icon || this.item?.icon || '');
    this.safeInputHtml = this.domSanitizer.bypassSecurityTrustHtml(this.item?.html);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
    this.cd.detach();
  }

  startTimeOut(): void {
    this.sleepTime = 1000 / this.framesPerSecond /* ms */;
    this.startTime = new Date().getTime();
    this.endTime = this.startTime + this.timeOut;
    this.zone.runOutsideAngular(() => this.timer = setTimeout(this.instance, this.sleepTime));
  }

  onEnter(): void {
    if (this.pauseOnHover) {
      this.stopTime = true;
      this.pauseStart = new Date().getTime();
    }
  }

  onLeave(): void {
    if (this.pauseOnHover) { return; }
    this.stopTime = false;
    this.startTime += (new Date().getTime() - this.pauseStart);
    this.endTime += (new Date().getTime() - this.pauseStart);
    this.zone.runOutsideAngular(() => setTimeout(this.instance, this.sleepTime));
  }

  onClick(event: MouseEvent): void {
    this.item?.click?.emit(event);
    if (this.clickToClose) {this.remove();}
  }

  onClickIcon(event: MouseEvent): void {
    this.item?.clickIcon?.emit(event);
    if (this.clickIconToClose) { this.remove(); }
  }

  // Attach all the overrides
  attachOverrides(): void {
    Object.keys(this.item?.override).forEach(a => {
      //eslint-disable-next-line
      if (Reflect.has(this, a)) { (this as any)[a] = this.item?.override[a]; }
    });
  }

  private readonly instance = (): void => {
    const now = new Date().getTime();

    if (this.endTime < now) {
      this.remove();
      this.item?.timeoutEnd?.emit();
    } else if (!this.stopTime) {
      if (this.showProgressBar) {
        // We add this.sleepTime just to have 100% before close
        this.progressWidth = Math.min((now - this.startTime + this.sleepTime) * 100 / this.timeOut, 100);
      }

      this.timer = setTimeout(this.instance, this.sleepTime);
    }
    this.zone.run(() => !(this.cd as ViewRef).destroyed && this.cd.detectChanges());
  };

  private remove(): void {
    if (this.animate && this.item) {
      this.item.state = this.animate + 'Out';
      setTimeout(() => this.item && this.notificationService.set(this.item, false), 310);
    } else {
      this.item && this.notificationService.set(this.item, false);
    }
  }

  private contentType(item: string | TemplateRef<unknown>, key: string): void {
    (this as any)[key] = item instanceof TemplateRef ? item : this.domSanitizer.bypassSecurityTrustHtml(item);
    (this as any)[key + 'IsTemplate'] = item instanceof TemplateRef;
  }
}
