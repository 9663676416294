import { DestroyRef, inject } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Nullable, watch, withStartValue } from '@libs/utils';
import { PaymentGroupsQuery, PaymentMethod, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { FormDeclaration, PaymentBuilder, PaymentParams } from '@portal/payment/shared';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { BehaviorSubject, filter, NEVER, Observable, of, switchMap, tap } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

export type Forms = Array<AbstractControl | 'additional' | 'payment'>;

export abstract class PaymentFlowAbstract {
  private readonly destroy = inject(DestroyRef);
  private readonly groupsQuery = inject(PaymentGroupsQuery);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly paramsQuery = inject(PaymentParamsQuery);
  private readonly userQuery = inject(UserQuery);

  private readonly watcher = watch();
  private readonly payment: FormControl<object | null> = new FormControl(null);
  private readonly additional: FormControl<object | null> = new FormControl(null);
  private readonly commissionWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly commissionInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected readonly builder = inject(PaymentBuilder);

  readonly isDeposit$ = this.paramsQuery.isDeposit$;
  readonly activeMethod$ = this.groupsQuery.activeMethod$;
  readonly submitButton: IButton = { loading: false };
  readonly form: FormArray<AbstractControl> = new FormArray<AbstractControl>([]);
  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly commissionWarning$: Observable<boolean> = this.commissionWarning.asObservable();
  readonly commissionInfo$: Observable<boolean> = this.commissionInfo.asObservable();

  step: number = 0;

  get invalid(): boolean {
    const isEmptyWithdrawalBalance = this.paramsQuery.isWithdrawal && this.userQuery.balance?.balance === '0.00';

    return Boolean(this.form.controls[ this.step ]?.invalid) || isEmptyWithdrawalBalance;
  }

  get stepsCount(): number { return this.form.length; }

  get isLastStep(): boolean { return !this.stepsCount || this.stepsCount - 1 === this.step; }

  protected constructor(...controls: Forms) {
    this.destroy.onDestroy(() => this.watcher.unsubscribe());

    this.watcher.add(this.builder.completed$.subscribe((status) => { this.submitButton.loading = status; }));

    this.watcher.add(this.paramsQuery.paymentParams$.pipe(
      distinctUntilChanged((prev, curr) => prev.group !== curr.group),
      filter((group): group is PaymentParams & { method: NonNullable<PaymentMethod> } => !!group.method),
      switchMap(() => this.activeMethod$.pipe(
        first((method) => !!method),
        tap(() => this.clearForm()),
        tap((method) => this.createForm(controls, method))),
      ),
      switchMap(() => !this.stepsCount ? this.submitStep() : NEVER),
    ));
  }

  isGenerated(control: AbstractControl): Observable<{ control: FormControl; form: Nullable<FormDeclaration> } | null> {
    return this.activeMethod$.pipe(map((method) => {
      const { additional, payment } = method?.data || {};

      if (control === this.additional && additional?.length) {return { control: this.additional, form: additional } || null;}
      if (control === this.payment && payment?.length) { return { control: this.payment, form: payment } || null; }

      return null;
    }));
  }

  resetMethod(): void { this.paramsData.setPayment({ method: undefined }); }

  submitStep(): Observable<unknown> {
    if (!this.isLastStep || this.form.controls[ this.step ]?.invalid) { return of(null); }

    this.submitButton.loading = true;

    return this.activeMethod$.pipe(
      first((method) => !!method),
      switchMap((method) => this.checkCommissions(method)),
    );
  }

  showCommissionInfo(): void {
    this.commissionInfo.next(true);
  }

  closeCommissionInfo(): void {
    this.commissionInfo.next(false);
  }

  checkCommissions(method: Nullable<PaymentMethod>): Observable<unknown> {
    if (!method?.view.commission.current) {
      this.builder.complete();
      return of(null);
    }
    this.submitButton.loading = false;
    this.commissionWarning.next(true);
    return of(method);
  }

  closeCommissionWarning(event: boolean): void {
    if (event) { this.builder.complete(); }
    this.commissionWarning.next(false);
  }

  private clearForm(): void {
    this.form.clear();
    this.step = 0;
  }

  private createForm(controls: Forms, method?: Nullable<PaymentMethod>): void {
    const createForm = (control: Exclude<Forms[number], AbstractControl>): void => {
      const dataForm = method?.data[ control ] || [];
      if (!dataForm?.length) { return; }
      if (Object.values(dataForm).some((f) => !f.value)) {
        this.form.push(this[ control ]);
        this.watcher.add(withStartValue(this[ control ]).subscribe((v) => this.builder.updatePayment({ data: { [ control ]: v } })));
      } else {
        const form = dataForm.reduce((acc, { name, value }) => ({ ...acc, [ name ]: value }), {});
        this.builder.updatePayment({ data: { [ control ]: form } });
      }
    };

    controls.forEach((control) =>
      control instanceof AbstractControl ? this.form.push(control) : createForm(control),
    );
  }
}
