export enum TranzzoPaymentMethods {
  Deposit = 66,
  DepositVisa = 69,
  DepositVisaUntrusted = 70,
  DepositMasterCard = 71,
  DepositVisaWidget = 73,
  DepositVisaUntrustedWidget = 74,
  DepositMasterCardWidget = 75,
  WithdrawalADVCash = 133
}
