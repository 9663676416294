import { AfterContentInit, ChangeDetectionStrategy, Component, forwardRef, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { InputAbstract } from 'apps/portal/src/app/shared/components/controls/input.abstract';
import { DEFAULT_DOCUMENTS } from './documents.constant';

@Component({
  selector: 'gg-dropdown-document-selector',
  templateUrl: './dropdown-document-selector.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownDocumentSelectorComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownDocumentSelectorComponent extends InputAbstract implements AfterContentInit {
  private readonly localization = inject(SpLocalization);
  readonly document = new FormControl();

  documentList: Array<IInputSelectData> = [];
  defaultItem: Nullable<IInputSelectData>;

  constructor() {
    super();
    this.document.valueChanges.subscribe((value) => this.writeValue(value));
  }

  ngAfterContentInit(): void {
    this.documentList = DEFAULT_DOCUMENTS.map((documents) => {
      return {
        description: this.localization.translate(documents.description),
        value: documents.value
      };
    });
    if (this.initialValue) {
      this.defaultItem = DEFAULT_DOCUMENTS
        .filter((value) => value.value === this.initialValue)
        .map((initialItem) => {
          return {
            description: this.localization.translate(initialItem.description),
            value: initialItem.value
          };
        }).find(Boolean);
    } else {
      this.defaultItem = {
        value: '',
        description: 'DOCUMENTS.PASSPORT_TYPE'
      };
    }
  }

}
