import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: [
    './input-checkbox.component.scss',
    './input-checkbox.purple.component.scss',
    './input-checkbox.silver.component.scss'
  ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputCheckboxComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputCheckboxComponent extends InputAbstract {
  @Input() for: Nullable<string> = null;
  @Input() switch = false;

  get isChecked(): boolean { return !!this.value; }

  get isSwitch(): boolean { return this.switch; }

}
