import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DEFAULT_MESSENGER,
  MESSENGER_LIST
} from './messenger-list.constant';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { InputAbstract } from 'apps/portal/src/app/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-dropdown-messenger-selector',
  templateUrl: './dropdown-messenger-selector.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownMessengerSelectorComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMessengerSelectorComponent extends InputAbstract implements AfterContentInit {
  private readonly destroyRef$ = inject(DestroyRef);

  readonly messenger = new FormControl();
  readonly messengers = MESSENGER_LIST;
  readonly defaultMessenger: IInputSelectData = DEFAULT_MESSENGER;

  override ngOnInit(): void {
    this.messenger.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((v) => this.writeValue(v));
  }

  ngAfterContentInit(): void {
    this.defaultMessenger && this.writeValue(this.defaultMessenger.value);
  }
}
