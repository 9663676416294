import { Banner } from '@portal/banners';

export const oldBankCardBannerConfig: Banner = {
  id: '',
  settings: {
    url: '',
    type: 'text',
    expirationDate: '',
    additionalUrl: ''
  },
  visibility: {
    order: 0,
    page: 'deposit',
    section: 'top',
    showTo: 'both'
  },
  content: {
    title: {
      kk: '<b>Назар аударыңыз!</b> Картаңыздан төлем жасауда қиындықтар болуы мүмкін. Басқа картаны қолданып көруді ұсынамыз.',
      fr: '<b>Faites attention!</b> Il peut y avoir des difficultés à effectuer un paiement avec votre carte. Nous vous recommandons d\'essayer une autre carte.',
      de: '<b>Achtung!</b> Bei der Zahlung von Ihrer Karte können Schwierigkeiten auftreten. Wir empfehlen, eine andere Karte auszuprobieren.',
      en: '<b>Pay attention!</b> There may be difficulties with making a payment from your card. We recommend trying another card.',
      uk: '<b>Зверніть увагу!</b> Можливі складнощі з проведенням платежу з картки. Рекомендуємо спробувати іншу картку.',
      ru: '<b>Обратите внимание!</b> Возможны сложности с проведением платежа с вашей карты. Рекомендуем попробовать другую карту.',
      uz: '<b>Diqqat!</b> Kartangizdan toʻlovni amalga oshirishda qiyinchiliklar boʻlishi mumkin. Boshqa kartani sinab ko\'rishni tavsiya qilamiz.'
    },
    label: null,
    image: null,
    imageAlt: null,
    callToAction: null,
    description: null,
    mobileImage: null,
    additionalAction: null
  }
};
