import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusCasinoCash } from '@portal/bonuses/data';

@Component({
  selector: 'gg-bonus-card-game',
  templateUrl: './game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameComponent {
  @Input() bonus: Nullable<Bonus> = null;
  @Input() ignoreLink = false;

  get hasGame(): boolean { return !!this.bonus && 'game' in this.bonus; }

  get hasSubTitle(): boolean { return this.bonus instanceof BonusCasinoCash; }
}
