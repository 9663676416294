import { Article, ArticleDto, NewsArticle, PromoArticle } from '@portal/articles/data';
import { createSystemInfo } from '@portal/articles/data/mappers/create-system-info';
import { createTimingInfo } from '@portal/articles/data/mappers/create-timing-info';

function createPromoArticle(article: ArticleDto): PromoArticle {
  const { _id, i18n, url, label } = article;
  const system = createSystemInfo(article);
  const timing = createTimingInfo(article);

  return new PromoArticle(_id, url, i18n, label, system, timing);
}

function createNewsArticle(article: ArticleDto): NewsArticle {
  const { _id, url, i18n } = article;
  const system = createSystemInfo(article);

  return new NewsArticle(_id, url, i18n, system);
}

export function createArticles(articles: Array<ArticleDto>): Array<Article> {
  return articles.map((article: ArticleDto) => {
    if ('label' in article || 'start_date' in article) { return createPromoArticle(article); }
    return createNewsArticle(article);
  });
}
