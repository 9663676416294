import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SpLocalization } from '@libs/cdk';
import { isNullish, Nullable } from '@libs/utils';
import { Bonus, BonusesData } from '@portal/bonuses/data';
import { ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  selector: 'gg-bonus-card-description',
  styleUrls: [ 'description.component.scss' ],
  templateUrl: './description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionComponent {
  private readonly router = inject(Router);
  private readonly language = inject(SpLocalization).currentLanguage;
  private readonly bonusesData = inject(BonusesData);

  readonly actionButton: IButton = { theme: ButtonThemes.White, fullWidth: true };

  @Input() bonus: Nullable<Bonus> = null;
  @Input() bonusesEnabled: boolean = false;

  get hasDescription(): boolean { return !isNullish(this.bonus?.information.description); }

  get isMultiDescription(): boolean {
    return this.hasDescription && Array.isArray(this.bonus?.information.description?.[ this.language ]);
  }

  get isWaitingStatus(): boolean {
    return this.bonus?.status.current === 'waiting' && this.bonus.type !== 'casino_linked';
  }

  processBonusActivate(): void {
    this.bonus && this.bonusesData.activateBonus(this.bonus).subscribe();
  }
}
