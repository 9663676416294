import { UpdateStateCallback } from '@datorama/akita/src/lib/types';
import {
  createEntities,
  createStore,
  Steroid,
  SteroidConfig,
  SteroidEntities,
  SteroidProps,
  SteroidState
} from './utils';

export abstract class SteroidStore<S extends SteroidState> {
  readonly steroid: Steroid<S>;

  protected constructor(storeConfig: SteroidConfig<S>) {
    const { options, entities, initial } = storeConfig;
    this.steroid = { ...createStore<S>(options, initial), entities: createEntities<S>(options, entities) };
  }

  get(): SteroidProps<S>;
  get<P extends keyof SteroidProps<S>>(p: P): SteroidProps<S>[P];
  get<P extends keyof SteroidProps<S>>(p?: P): SteroidProps<S> | SteroidProps<S>[P] {
    const value = this.steroid.store.getValue();
    return p ? value[p] : value;
  }

  update(callbackOrState: UpdateStateCallback<SteroidProps<S>> | Partial<SteroidProps<S>>): void {
    typeof callbackOrState === 'object' // DO NOT ASK ANYTHING (problem in type overload)
      ? this.steroid.store.update(callbackOrState)
      : this.steroid.store.update(callbackOrState);
  }

  reset(): void {
    this.steroid.store.config.resettable && this.steroid.store.reset();
  }

  setLoading(isLoading?: boolean): void {
    this.steroid.store.setLoading(isLoading);
  }

  setError<T>(error: T): void {
    this.steroid.store.setError(error);
  }

  entity<C extends keyof SteroidEntities<S>>(entity: C): Steroid<S>['entities'][C]['store'] {
    const selected = this.steroid.entities[entity].store;
    if (!selected) { throw new Error(`Entity ${entity as string} not found`); }

    return selected;
  }
}
