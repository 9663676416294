import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { BonusCardModalComponent } from '@portal/bonuses/components';
import { CardPartsModule } from '@portal/bonuses/components/card-parts';
import { Bonus } from '@portal/bonuses/data';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';

@Component({
  standalone: true,
  imports: [ SpCDKModule, BonusPipesModule, CardPartsModule, ThemeModule ],
  selector: 'gg-cashier-bonus-card',
  templateUrl: './cashier-bonus-card.component.html',
  styleUrls: [
    './cashier-bonus-card.component.scss',
    './purple.bonus.component.scss',
    './silver.bonus.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashierBonusCardComponent {
  private readonly dialog = inject(Dialog);

  @Input() bonus: Nullable<Bonus> = null;
  @Input() selected: boolean = false;

  get isLinkedBonus(): boolean {
    return this.bonus?.type === 'casino_linked';
  }

  showPopup(): void {
    const data = {
      bonus: this.bonus,
      isWelcomeBonus: this.isLinkedBonus
    };
    this.dialog.open(BonusCardModalComponent, { panelClass: 'bottom-center', data });
  }
}
