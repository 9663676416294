import { OrArray } from '@libs/utils';
import { ExchangeRatesDto, FiatCurrencyDto, SelectedCurrencies, SelectFiatSettings } from '@portal/currencies/data/dto';
import { FiatCurrency } from '@portal/currencies/data/entities';

export class CurrenciesMapper {
  static toDomain(dto: FiatCurrencyDto, rates: ExchangeRatesDto): FiatCurrency;
  static toDomain(dto: Array<FiatCurrencyDto>, rates: ExchangeRatesDto): Array<FiatCurrency>;
  static toDomain(dto: OrArray<FiatCurrencyDto>, rates: ExchangeRatesDto): OrArray<FiatCurrency> {
    if (Array.isArray(dto)) { return dto.map((currency) => CurrenciesMapper.toDomain(currency, rates)); }
    return new FiatCurrency({ ...dto, rate: rates[ dto.name ] || null });
  }

  static filterCurrencies<S extends SelectFiatSettings>(currencies: Array<FiatCurrency>, settings: S): SelectedCurrencies<S> {
    if (!settings.registration && !settings.extractDefault) { return currencies as SelectedCurrencies<S>; }

    const filtered = currencies.filter((currency): currency is FiatCurrency => {
      const checkRegistration = !settings.registration || (settings.registration && currency.is.registration);
      const checkDefault = !settings.extractDefault || (settings.extractDefault && !currency.is.default);
      return checkRegistration && checkDefault;
    });

    return settings.extractDefault
      ? { default: currencies.find((currency) => currency.is.default), currencies: filtered } as SelectedCurrencies<S>
      : filtered as SelectedCurrencies<S>;
  }
}
