<div class="promo-code" [formGroup]="form">
  <div class="promo-code__input" *spLet="promoCodeActivated$ | async as codeActivated">
    <gg-input (input)="getCurrentStatus(codeActivated)" class="promo-code__input-value" formControlName="promoCode"
      [ngClass]="{'promo-code__success-promo': codeActivated}"
      [placeholder]="codeActivated ? ('BONUSES.PROMO_CODE_PLACEHOLDER' | translate) :
      'AUTH.REGISTRATION.ENTER_PROMO_CODE' | translate"
      [noLabelPlaceholder]="noLabelPlaceholder" [errors]="errors.get('promoCode') | async" [isTrim]="true"/>
    <span *ngIf="!codeActivated" spMsg="CASHIER.CARD.APPLY" class="promo-code__button"
          (click)="checkPromoCode()"></span>
    <img *ngIf="codeActivated" [src]="'{project}/images/bonuses/{palette}/success.svg' | path" class="promo-code__success-icon" alt=""/>
    <span *ngIf="codeActivated" class="promo-code__success-message">
      <sp-msg msg="BONUSES.PROMO_CODE_ACTIVATED"></sp-msg>
    </span>
  </div>
</div>
