import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { GlobalWindow, Nullable } from '@libs/utils';
import { Action3DSData, Action3DSDataDto, ActionPollingData, ActionPollingDataDto, createAction3dsData, createActionPollingData, createDepositRequest, createDialogResult, createErrorResult, createPaymentResult, createWithdrawRequest, getUpdatedStatus, PaymentResult, PaymentUpdateDto } from '@portal/payment/data';
import { Payment, PaymentCreatedResponse, PaymentParams, PaymentStatus, PaymentUpdatedResponse, PaymentUpdateStatusResponse, VerificationCodeResponse } from '@portal/payment/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentData {
  private readonly http = inject(HttpClient);

  create(payment: Payment, window: GlobalWindow): Observable<PaymentResult> {
    return payment.operation === 'deposit' ? this.createDeposit(payment, window) : this.createWithdrawal(payment, window);
  }

  update(body: PaymentUpdateDto): Observable<PaymentStatus> {
    return this.http.post<PaymentUpdatedResponse>('/api/payments/deposit/update/', body).pipe(
      map((result) => result.status as PaymentStatus), catchError((error) => throwError(() => ResponseFactory.error(error))),
    );
  }

  checkStatus(initiatorID: Nullable<string>): Observable<ActionPollingData> {
    return this.http.get<ActionPollingDataDto>(`/api/payments/deposit/pending/${initiatorID}`).pipe(
      map((response) => createActionPollingData(response)), catchError((error) => throwError(() => ResponseFactory.error(error))),
    );
  }

  check3ds(initiatorID: Nullable<string>, paymentMethod: Nullable<string>): Observable<Action3DSData> {
    return this.http.post<Action3DSDataDto>('/api/payments/3ds/check/', { initiatorID, paymentMethod }).pipe(
      map((response) => createAction3dsData(response)), catchError((error) => throwError(() => ResponseFactory.error(error))),
    );
  }

  updateStatus(invoice: PaymentParams['invoice']): Observable<PaymentStatus> {
    return this.http.get<PaymentUpdateStatusResponse>(`/api/payments/deposit/status/${invoice}`).pipe(
      map((response) => getUpdatedStatus(response)), catchError((error) => throwError(() => ResponseFactory.error(error))),
    );
  }

  getPaymentCode(id: string): Observable<PaymentResult> {
    return this.http.post<VerificationCodeResponse>('/api/payments/payment-code/generate', { payment_method: id }).pipe(
      map(({ code }) => createDialogResult(code)), catchError(() => of(createErrorResult())),
    );
  }

  private createDeposit(payment: Payment, window: GlobalWindow): Observable<PaymentResult> {
    return this.http.post<PaymentCreatedResponse>('/user_payments/deposit/create', createDepositRequest(payment, window)).pipe(
      map((result) =>
        result.status === 'error' ? createErrorResult() : createPaymentResult(result)),
      catchError(() => of(createErrorResult())),
    );
  }

  private createWithdrawal(payment: Payment, window: GlobalWindow): Observable<PaymentResult> {
    return this.http.post<PaymentCreatedResponse>('/api/payments/withdrawal/create/', createWithdrawRequest(payment, window)).pipe(
      map((result) => createPaymentResult(result)), catchError(() => of(createErrorResult())),
    );
  }
}
