import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpNavigation } from '@libs/cdk';
import { PaymentParamsData } from '@portal/payment/data';
import { WidgetParams } from '@portal/payment/shared';
import { UserQuery } from '@portal/user';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private readonly destroy = inject(DestroyRef);
  private readonly navigation = inject(SpNavigation);
  private readonly blocking$ = inject(UserQuery).blocking$;
  private readonly paramsData = inject(PaymentParamsData);

  readonly isWidgetAvailable$ = this.blocking$.pipe(first(), map((blocking) => blocking?.paymentsWidget));

  openPaymentsDialog(): void {
    this.isWidgetAvailable$.pipe(
      takeUntilDestroyed(this.destroy),
    ).subscribe((isAvailable) => isAvailable
      ? this.navigation.navigate([], { section: 'deposit' }, { queryParamsHandling: 'merge' })
      : this.navigation.navigate([ '/cashier', 'deposit' ]),
    );
  }

  selfBlockedSection(params?: WidgetParams): void {
    this.blocking$.pipe(
      map((blocking) => {
        if (blocking?.deposit && !blocking.withdrawal) {
          this.paramsData.update(params);
        } else if (!blocking?.deposit && blocking?.withdrawal) {
          this.paramsData.update({ section: 'deposit', container: undefined, group: null, method: undefined });
        } else if (blocking?.deposit && blocking.withdrawal) {
          this.paramsData.update({ section: 'history' });
        }
      }),
      takeUntilDestroyed(this.destroy),
    ).subscribe();
  }
}
