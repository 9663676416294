import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';

export function getAvailableDepositBonuses(bonuses: Array<Bonus>, price?: Nullable<string>): Array<Bonus> {
  return bonuses.filter(({ status, activation }) => {
    return price
      ? status.is.waiting && activation.deposit && Number(activation.price) <= Number(price)
      : status.is.waiting && activation.deposit;
  }).sort((b1, b2) => Date.parse(b2.information.createdDate) - Date.parse(b1.information.createdDate));

}
