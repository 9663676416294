<section class="updated-docs">
  <sp-msg class="updated-docs__title" msg="PROFILE.DOCUMENTS_VERIFICATION.UPLOADED_DOCUMENTS"/>
  <ul *ngIf="documents as documents">
    <li *ngFor="let doc of documents" class="updated-docs__uploaded">
      <div class="updated-docs__doc-preview">
        <div class="updated-docs__doc-preview-text">
          <span class="updated-docs__name">
            {{ doc.name | translate}}
          </span>
          <span class="updated-docs__uploaded-date">{{doc.uploadDate | amDateFormat:'DD.MM.YYYY'}}</span>
        </div>
      </div>
    </li>
  </ul>
</section>
