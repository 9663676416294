<section class="mobile-nav">
  @for (item of sections; track $index) {
    <div
      class="mobile-nav__item"
      routerLinkActive="mobile-nav__item_active"
      [spMsg]="item.text"
      [routerLink]="item.url"
      [routerLinkActiveOptions]="item.activeOptions | fallback: { exact: false }"
    ></div>
  }
</section>
