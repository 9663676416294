// @ts-nocheck

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccessCardGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly userInfoQuery: UserQuery,
    private readonly source: ConfigQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const blockUrl = [ this.source.modules.access.blockedSectionRedirectionPaths.deposit ];

    return this.userInfoQuery.blocking$.pipe(
      map((blocking) => blocking.deposit && state.url.includes(CashierSections.Deposit)),
      map((isBlocked) => !isBlocked || this.router.createUrlTree(blockUrl))
    );
  }
}
