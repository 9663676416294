<ng-container class="nickname-form">
  <div class="nickname-form__title">
    <sp-msg msg="POKER.FOR_PLAY"></sp-msg>
  </div>
  <div class="nickname-form__subtitle">
    <sp-msg msg="POKER.LOGIN_WARNING"></sp-msg>
  </div>

  <form [formGroup]="form" (ngSubmit)="saveUserInfo()">
    <gg-input
      *ngIf="(nickExists$ | async) === false"
      class="nickname-form__nick"
      placeholder="{{'PROFILE.PERSONAL_INFO.NICK' | translate}}"
      formControlName="nick"
      [errors]="errors.get('nick') | async"
    />
    <gg-input-password
      *ngIf="(passwordExists$ | async) === false"
      class="input-password"
      formControlName="password"
      placeholder="{{'COMMON.PASSWORD' | translate}}"
      [errors]="errors.get('password') | async"
    />
    <gg-button
      class="nickname-form__button"
      [settings]="saveButton"
      [fullWidth]="true"
      [disabled]="form.invalid"
      (buttonClicked)="saveUserInfo()"
    ><sp-msg msg="PROFILE.PERSONAL_INFO.SAVE"/></gg-button>
  </form>
</ng-container>
