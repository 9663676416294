import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'cashier', loadChildren: () => import('@portal/cashier').then(m => m.CashierModule) },
  { path: 'auth', loadChildren: () => import('@portal/authorization').then(m => m.AuthorizationModule) },
  { path: 'bonuses', loadChildren: () => import('@portal/bonuses').then(m => m.BonusesModule) },
  { path: 'poker', loadChildren: () => import('@portal/poker').then(m => m.PokerModule) },
  { path: 'faq', loadChildren: () => import('@portal/faq').then(m => m.FaqModule) },
  { path: 'sports', loadChildren: () => import('@portal/betting').then(m => m.BettingModule) },
  { path: 'news', loadChildren: () => import('@portal/articles').then(m => m.ArticlesModule), data: { page: 'news' } },
  { path: 'promo', loadChildren: () => import('@portal/articles').then(m => m.ArticlesModule), data: { page: 'promo' } },
  { path: 'promotions', loadChildren: () => import('@portal/promotions').then(m => m.PromotionModule) },
  { path: 'user/settings', loadChildren: () => import('@portal/user').then(m => m.UserModule) },
  { path: 'user/verification', loadChildren: () => import('@portal/verification').then(m => m.VerificationModule) },
  { path: 'error', loadChildren: () => import('@portal/static').then(m => m.ErrorsModule) },
  { path: 'page', loadChildren: () => import('@portal/static').then(m => m.PageModule) },
  { path: '404', redirectTo: 'error/404' },
  { path: 'slots-blocked', redirectTo: 'page/slots-blocked' },
  { path: 'away', redirectTo: 'page/away' },
  { path: '', loadChildren: () => import('@portal/games').then(m => m.GamesModule) },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRouting {}
