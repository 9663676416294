<section class="verified" ggCreateThemeClass="verified">
    <div class="verified__title">
        <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"></sp-msg>
    </div>
    <div class="verified__value">{{ userPhone }}</div>
    <div class="verified__wrapper">
        <img [src]="'images/icons/{palette}/check-email.svg' | path" class="verified__icon info__icon--verified" alt=""/>
        <span class="verified__check">
          <sp-msg msg="PROFILE.CONTACT_DETAILS.APPROVED"></sp-msg>
        </span>
    </div>
</section>
