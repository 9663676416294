<div>
  <div class="timer-border" ggCreateThemeClass="timer-border">
    <div class="timer-title" [spMsg]="header"></div>
    <div class="timer-body" *ngIf="timer$ | async as timer">
      <div class="timer-value">
        <span class="timer-value-count">{{ timer.days }}</span>
        <div class="timer-value-label" spMsg="COMMON.DAYS"></div>
      </div>
      <div class="time-separator">:</div>
      <div class="timer-value">
        <span class="timer-value-count">{{ timer.hours }}</span>
        <div class="timer-value-label" spMsg="COMMON.HOURS"></div>
      </div>
      <div class="time-separator">:</div>
      <div class="timer-value">
        <span class="timer-value-count">{{ timer.minutes }}</span>
        <div class="timer-value-label" spMsg="COMMON.MINUTES"></div>
      </div>
      <div class="time-separator">:</div>
      <div class="timer-value">
        <span class="timer-value-count">{{ timer.seconds }}</span>
        <div class="timer-value-label" spMsg="COMMON.SECONDS"></div>
      </div>
    </div>
  </div>
</div>
