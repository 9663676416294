import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';

@Component({
  selector: 'gg-bonus-card-info-active-label',
  templateUrl: './active-label.component.html',
  styleUrls: [ './active-label.component.scss', './silver.active-label.scss', './purple.active-label.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveLabelComponent {

  @Input() bonus: Nullable<Bonus>;

  get isActiveLabel(): boolean {
    return !!this.bonus?.status.is.active && (this.bonus.type === 'casino_freespin' || this.bonus.type === 'sportsbook');
  }

}
