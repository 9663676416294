import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [ SpCDKModule, RouterModule ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule {}
