import { SpLocalizationConfig } from './config';
import { SpLocalization } from './localization.service';

export function createBaseHref(config: SpLocalizationConfig): (service: SpLocalization) => string | undefined {
  const { useBaseHref } = config;

  return ({ currentLanguage, defaultLanguage }) => {
    const languageChanged = !!currentLanguage && currentLanguage !== defaultLanguage;
    return useBaseHref === 'always' || (useBaseHref === 'not-default' && languageChanged)
      ? `/${currentLanguage}`
      : undefined;
  };
}
