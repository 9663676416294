import { Directive, HostBinding, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config/data';
import { ThemeComparer } from '@portal/config/shared';

@Directive({ selector: '[ggTheme]' })
export class ThemeDirective<T> implements OnInit {
  private readonly theme = inject(ConfigQuery).theme;
  private readonly tplRef = inject(TemplateRef<T>);
  private readonly vcRef = inject(ViewContainerRef);

  @Input('ggTheme') comparer: Nullable<ThemeComparer> = null;

  ngOnInit(): void {
    this.comparer && this.theme.isMatch(this.comparer) && this.vcRef.createEmbeddedView(this.tplRef);
  }
}

@Directive({ selector: '[ggCreateThemeClass]' })
export class CreateThemeClassDirective {
  private readonly theme = inject(ConfigQuery).theme;

  @Input('ggCreateThemeClass') classname: Nullable<string> = '';

  @HostBinding('class') get classnames(): string {
    if (!this.theme && !this.classname) { return ''; }
    if (!this.theme && this.classname) { return this.classname; }
    if (this.theme && !this.classname) { return `${this.theme.palette} ${this.theme.structure}`; }

    return `${this.theme.palette}-${this.classname} ${this.theme.structure}-${this.classname}`;
  }
}
