import { CdkStepper } from '@angular/cdk/stepper';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  inject,
  Input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NoOp, noOp, Nullable, switchTap } from '@libs/utils';
import { Observable, of, tap } from 'rxjs';
import { NextStepDirective, PrevStepDirective } from './stepper.directive';

type ChangeAction = (step: number) => Observable<unknown> | void;

@Component({
  selector: 'gg-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss' ],
  providers: [ { provide: CdkStepper, useExisting: StepperComponent } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent extends CdkStepper implements AfterContentInit {
  private readonly destroy$ = inject(DestroyRef);

  @Input() beforeChange: ChangeAction = noOp;
  @Input() onChange: ChangeAction = noOp;
  @Input() executeOnBack: boolean = false;

  @ContentChild(NextStepDirective) nextStep: Nullable<NextStepDirective>;
  @ContentChild(PrevStepDirective) prevStep: Nullable<PrevStepDirective>;

  override ngAfterContentInit(): void {
    super.ngAfterContentInit();

    this.nextStep?.action.pipe(this.executeActions(this.next), takeUntilDestroyed(this.destroy$)).subscribe();

    this.executeOnBack
      ? this.prevStep?.action.pipe(this.executeActions(this.previous), takeUntilDestroyed(this.destroy$)).subscribe()
      : this.prevStep?.action.pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => this.previous());
  }

  private executeActions(action: NoOp): (source$: Observable<void>) => Observable<void> {
    return (source$) => source$.pipe(
      switchTap(() => {
        const result = this.beforeChange(this.selectedIndex);
        return result instanceof Observable ? result : of(null);
      }),
      tap(() => action.bind(this)()),
      switchTap(() => {
        const result = this.onChange(this.selectedIndex);
        return result instanceof Observable ? result : of(null);
      })
    );
  }
}
