import { Nullable } from '@libs/utils';
import { PaymentMethod, PaymentMethodsDto } from '@portal/cashier/data';

export function getPaymentMethods(paymentMethods: PaymentMethodsDto): Nullable<Array<PaymentMethod>> {
  if (!paymentMethods) { return null; }

  return paymentMethods.list
    .map((paymentMethod) => PaymentMethod.create(paymentMethod))
    .sort((a, b) => a.order - b.order);
}
