<ng-template #tpl>
  <div class="verification-code-dialog">
    <img (click)="closeDialog()" alt="" class="verification-code-dialog__close" src="assets/images/icons/cross.svg"/>
    <div class="verification-code-dialog__wrapper">
      <div class="verification-code-dialog__code-banner">
        <sp-msg spMsg="CASHIER.CARD.VERIFICATION_CODE"/>
        <div *ngIf="code" class="verification-code-dialog__code-banner-box">
          <h3 class="verification-code-dialog__code-banner-code">{{code}}</h3>
          <gg-clipboard-copy [copyValue]="code" class="copy-past"/>
        </div>
      </div>
      <div class="verification-code-dialog__info-banner-content">
        <sp-msg spMsg="CASHIER.CARD.CACTUS_WARNING"/>
        <gg-input-checkbox [(ngModel)]="saved" class="checkbox__item" for="saved">
          <sp-msg spMsg="CASHIER.CARD.SAVED_CODE"/>
        </gg-input-checkbox>
      </div>
      <div class="verification-code-dialog__info-address">
        <sp-msg spMsg="CASHIER.CARD.DELIVERY_ADDRESS"/>
        <div class="verification-code-dialog__info-address-content">{{ address }}</div>
        <a (click)="closeDialog()" class="verification-code-dialog__info-address-link">{{ "CASHIER.CARD.CHANGE" | translate }}</a>
      </div>
      <gg-payment-summary/>
      <gg-button (click)="close(code)" [disabled]="!saved" class="verification-code-dialog__btn">
        <sp-msg spMsg="CASHIER.CARD.CONFIRM"/>
      </gg-button>
    </div>
  </div>
</ng-template>
