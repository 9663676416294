import {
  BonusCasinoWelcomeDto,
  mapActivation,
  mapExpiration,
  mapGame,
  mapInformation,
  mapInStatus,
  mapWager
} from '@portal/bonuses/data';
import { AbstractBonus } from '@portal/bonuses/data/abstracts';
import { mapMaxWinType } from '@portal/bonuses/data/mappers/partials/max-win';
import {
  BonusActivation,
  BonusExpiration,
  BonusGame,
  BonusInformation,
  BonusInStatus,
  BonusStatus,
  BonusWager
} from '@portal/bonuses/shared';
import { BonusMaxWinDto } from '@portal/bonuses/shared/types/bonus-max-win';

export class BonusCasinoWelcome extends AbstractBonus<BonusCasinoWelcomeDto> {
  readonly game: BonusGame = mapGame(this.source);

  readonly status: BonusInStatus = mapInStatus(this.source.status);
  readonly activation: BonusActivation = mapActivation(this.source);
  readonly wager: BonusWager = mapWager(this.source);
  readonly expiration: BonusExpiration = mapExpiration(this.source);
  readonly information: BonusInformation = mapInformation(this.source);
  readonly maxWin: BonusMaxWinDto = mapMaxWinType(this.source);

  constructor(bonus: BonusCasinoWelcomeDto) {
    super(bonus);
    this.status.is.inProgress = bonus.status === BonusStatus.Wager;
  }
}
