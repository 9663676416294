const FIRST_STEP_ORDERS = {
  email: 0,
  password: 1,
  country_code: 2,
  currency: 3,
  birthday: 4,
  promo_code: 4,
  hasPrivacyPolicy: 5,
  isAdult: 6,
  subscription: 7,
  lang: 9,
  auth_token: 10,
  recaptcha_token: 11,
};

const SECOND_STEP_ORDERS = {
  first_name: 0,
  last_name: 1,
  birthday: 2,
  state: 3,
  request_id: 4,
};

const THIRD_STEP_ORDERS = {
  phone: 0,
  city: 1,
  address: 2,
  zip_code: 3,
  request_id: 4
};

export const SIMPLE_ORDERS = {
  email: 0,
  password: 1,
  country_code: 2,
  currency: 3,
  birthday: 4,
  promo_code: 5,
  hasPrivacyPolicy: 6,
  isAdult: 7,
  subscription: 8,
  ontario: 9,
  lang: 10,
  auth_token: 11,
  recaptcha_token: 12,
  cpa_info: 13,
  referer: 14,
  utm: 15
};

export const EXTENDED_EMAIL_REGISTRATION_ORDERING = [ FIRST_STEP_ORDERS, SECOND_STEP_ORDERS, THIRD_STEP_ORDERS ];
export const SIMPLE_EMAIL_REGISTRATION_ORDERING = [ SIMPLE_ORDERS ];


