import { FormControl, FormGroup } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';

export const createPhoneRecoveryForm = (): FormGroup<{
  phone: FormControl<string>;
  recaptcha_token: FormControl<string>;
}> => new FormGroup({
  phone: new FormControl<string>('', { nonNullable: true, validators: validationRules.phone }),
  recaptcha_token: new FormControl<string>('', { nonNullable: true })
});

export const createPhoneVerificationForm = (): FormGroup<{
  code: FormControl<string>;
  password: FormControl<string>;
  passwordRepeat: FormControl<string>;
}> => new FormGroup({
  code: new FormControl('', { nonNullable: true, validators: validationRules.phoneVerificationCode }),
  password: new FormControl('', { nonNullable: true, validators: validationRules.newPassword }),
  passwordRepeat: new FormControl('', { nonNullable: true, validators: validationRules.newPassword })
}, { validators: validationRules.matchFields('password', 'passwordRepeat') });
