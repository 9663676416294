import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusPokerReload } from '@portal/bonuses/data';
import { ConfigQuery } from '@portal/config';

@Component({
  selector: 'gg-bonus-multi-circle',
  templateUrl: './multi-circle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusMultiCircleComponent implements AfterViewInit {
  strokeColor: string = inject(ConfigQuery).modules.bonuses.strokeColor;
  ctx: Nullable<CanvasRenderingContext2D> = null;
  width: number = 0;
  height: number = 0;
  progress: any = [];

  @ViewChild('multiCircle') multiCircle: Nullable<ElementRef<HTMLCanvasElement>> = null;

  @Input() set bonus(bonus: Nullable<Bonus>) {
    if (!bonus) { return; }
    const { wager, status } = bonus;
    if (wager) {
      this.progress = bonus instanceof BonusPokerReload ? wager?.account?.progressList : [ wager.progress ];
    }

    if (!status.is.inProgress && !status.is.success) { this.strokeColor = '#999'; }
  }

  ngAfterViewInit(): void {
    if (!this.multiCircle) { return; }
    this.ctx = this.multiCircle.nativeElement.getContext('2d');
    this.width = this.multiCircle.nativeElement.width;
    this.height = this.multiCircle.nativeElement.height;

    this.drawCircleSection(this.progress);
  }

  drawCircleSection(progress: Array<number>): void {
    const radius = 40; // as radian
    const indent = progress.length > 1 ? 10 : 0; // px
    const radianIndent = indent / radius; // radian
    let startCor = ((Math.PI / 180) * 270) + radianIndent / 2; // radian
    const circleLength = 2 * Math.PI * radius; // px
    const sectionLength = circleLength / progress.length; // px
    const radianSecLength = (sectionLength - indent) / radius; // radian
    const radialPercent = radianSecLength / 100; // radial

    progress.forEach((percent) => {
      if (!this.ctx) { return; }
      this.ctx.beginPath();
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
      this.ctx.arc(
        this.width / 2,
        this.height / 2,
        radius,
        startCor,
        startCor + radianSecLength
      );
      this.ctx.lineWidth = 5;
      this.ctx.stroke();

      // progress

      if (percent) {
        this.ctx.beginPath();
        this.ctx.lineCap = 'round';
        this.ctx.strokeStyle = this.strokeColor;
        this.ctx.arc(
          this.width / 2,
          this.height / 2,
          radius,
          startCor,
          startCor + radialPercent * percent
        );
        this.ctx.lineWidth = 5;
        this.ctx.stroke();
      }

      startCor += radianSecLength + radianIndent;
    });
  }
}
