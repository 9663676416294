import { Nullable } from '@libs/utils';
import { VerificationStatus } from '@portal/verification/data/core';
import { FailureVerificationStatus, NotVerifiedVerificationStatus, PendingVerificationStatus, VerifiedVerificationStatus } from '@portal/verification/data/entities';

export abstract class VerificationStatusFactory {
  static create(status: Nullable<string>): Nullable<VerificationStatus> {
    if (status === 'failure') { return new FailureVerificationStatus(); }
    if (status === 'unverified') { return new NotVerifiedVerificationStatus(); }
    if (status === 'pending') { return new PendingVerificationStatus(); }
    if (status === 'success') { return new VerifiedVerificationStatus(); }
    return null;
  }
}
