import { PaymentGroup } from '@portal/payment/data';

export class PaymentContainer {
  constructor(
    readonly id: string,
    readonly icon: string,
    readonly name: string,
    readonly limits: { min: number; max: number },
    readonly groups: Array<PaymentGroup>
  ) {}
}
