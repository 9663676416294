import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { SocketComponent } from '@portal/banners';
import { SliderBonusesModule } from '@portal/bonuses';
import { CroppedCardComponent, PromoCodeComponent } from '@portal/bonuses/components';
import { ThemeModule } from '@portal/config';
import { CountrySelectorComponent, InputPhoneWithCountryModule, PaymentCountrySelectorComponent, StatesSelectorComponent } from '@portal/countries';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';
import { ErrorManager } from '@portal/shared/helpers';
import { VerificationWarningComponent } from '@portal/verification/features';
import { CashierComponent } from './cashier.component';
import { CashierRoutingModule } from './cashier.routing.module';
import { CashierService } from './cashier.service';
import { ActiveBonusWarningComponent } from './components/active-bonus-warning/active-bonus-warning.component';
import { BankCardsComponent } from './components/bank-cards/bank-cards.component';
import { CardValidationComponent } from './components/card-validation/card-validation.component';
import { DeleteCardDialogComponent } from './components/card-validation/components/delete-card-dialog/delete-card-dialog.component';
import { UserBankCardComponent } from './components/card-validation/components/user-bank-card/user-bank-card.component';
import { CardComponent } from './components/card/card.component';
import { CactusDialogComponent } from './components/card/components/cactus-pop-up/cactus-dialog.component';
import { CommissionInfoDialogComponent } from './components/card/components/commission-info-dialog/commission-info-dialog.component';
import { CommissionWarningDialogComponent } from './components/card/components/commission-warning-dialog/commission-warning-dialog.component';
import { CryptoErrorComponent } from './components/card/components/crypto-error/crypto-error.component';
import { CryptoFlowComponent } from './components/card/components/crypto-flow/crypto-flow.component';
import { DotSeparatedComponent } from './components/card/components/dot-separated/dot-separated.component';
import { EnterCodeDialogComponent } from './components/card/components/enter-code-dialog/enter-code-dialog.component';
import { MastercardInfoBlockComponent } from './components/card/components/mastercard-info-block/mastercard-info-block.component';
import { OctopayDepositBlockComponent } from './components/card/components/octopay-deposit-block/octopay-deposit-block.component';
import { PaysoftWidgetComponent } from './components/card/components/paysoft-widget/paysoft-widget.component';
import { PdWidgetCactusComponent } from './components/card/components/pd-widget-cactus/pd-widget-cactus.component';
import { PdWidgetPayComponent } from './components/card/components/pd-widget-pay/pd-widget-pay.component';
import { PdWidgetUserDataComponent } from './components/card/components/pd-widget-user-data/pd-widget-user-data.component';
import { DepositVerificationKycComponent } from './components/deposit-verification-kyc/deposit-verification-kyc.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { PhoneRegDialogComponent } from './components/deposit/phone-reg-dialog/phone-reg-dialog.component';
import { HistoryItemComponent } from './components/history/components/item/history-item.component';
import { HistoryMenuComponent } from './components/history/components/menu/history-menu.component';
import { HistoryComponent } from './components/history/history.component';
import { InformationMessageComponent } from './components/information-message/information-message.component';
import { PaymentHeaderComponent } from './components/payment-header/payment-header.component';
import { StatusComponent } from './components/status/status.component';
import { StubComponent } from './components/stub/stub.component';
import { TransfersFormComponent } from './components/transfers/components/form/transfers-form.component';
import { SuccessTransferComponent } from './components/transfers/components/success/success-transfer.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { CashierGridModule } from './containers/grid/grid.module';
import { HeaderModule } from './containers/header/header.module';

@NgModule({
  declarations: [
    CashierComponent,
    DepositComponent,
    WithdrawalComponent,
    TransfersFormComponent,
    SuccessTransferComponent,
    HistoryComponent,
    HistoryMenuComponent,
    HistoryItemComponent,
    ActiveBonusWarningComponent,
    StatusComponent,
    CardComponent,
    PdWidgetPayComponent,
    PhoneRegDialogComponent,
    MastercardInfoBlockComponent,
    PaysoftWidgetComponent,
    PdWidgetUserDataComponent,
    CommissionInfoDialogComponent,
    CommissionWarningDialogComponent,
    InformationMessageComponent,
    DepositVerificationKycComponent,
    BankCardsComponent,
    PaymentHeaderComponent,
    StubComponent,
    OctopayDepositBlockComponent,
    CardValidationComponent,
    DeleteCardDialogComponent,
    UserBankCardComponent,
    CryptoFlowComponent,
    EnterCodeDialogComponent,
    CactusDialogComponent,
    PdWidgetCactusComponent,
    CryptoErrorComponent,
    DotSeparatedComponent,
  ],
  imports: [
    SpCDKModule,
    CashierRoutingModule,
    CurrenciesModule,
    CashierGridModule,
    HeaderModule,
    ReactiveFormsModule,
    ControlsModule,
    ThemeModule,
    SliderBonusesModule,
    PromoCodeComponent,
    CroppedCardComponent,
    ContentModule,
    ClipboardCopyComponent,
    FormsModule,
    InputPhoneWithCountryModule,
    CountrySelectorComponent,
    StatesSelectorComponent,
    SocketComponent,
    VerificationWarningComponent,
    PaymentCountrySelectorComponent,
    CurrenciesModule,
  ],
  providers: [
    ErrorManager,
    CashierService,
  ],
})
export class CashierModule {}
