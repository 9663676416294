import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Observable, Subscribable } from 'rxjs';

@Pipe({ name: 'spAsync', pure: false })
export class SpAsyncPipe implements PipeTransform, OnDestroy {
  asyncPipe = new AsyncPipe(inject(ChangeDetectorRef));

  transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T>): T;
  transform(obj: null | undefined): null;
  transform<T>(obj: Nullable<Observable<T> | Subscribable<T> | Promise<T>>): T;
  transform<T>(obj: Nullable<Observable<T> | Subscribable<T> | Promise<T>>): T {
    return this.asyncPipe.transform(obj) as T;
  }

  ngOnDestroy(): void { this.asyncPipe.ngOnDestroy(); }
}
