<form action="" method="post" class="paysoft-form" id="paysoft-form" ngNoForm
      [ggCreateThemeClass]="'paysoft-form-theme'">
  <div class="row row--card">
    <div id="field-card-number" class="paysoft-form__input"></div>
    <div id="error-field-card-number" class="error"></div>
  </div>

  <div class="paysoft-form__flex">
    <div class="row row--date">
      <div class="row--wrap">
        <div id="field-card-expire-month" class="paysoft-form__input paysoft-form__input--date"></div>
        <div id="error-field-card-expire-month" class="error"></div>
      </div>
      <div class="row--wrap">
        <div id="field-card-expire-year" class="paysoft-form__input paysoft-form__input--date"></div>
        <div id="error-field-card-expire-year" class="error"></div>
      </div>
    </div>

    <div class="row row--cvv">
      <div id="field-card-cvc" class="paysoft-form__input paysoft-form__input--cvv"></div>
      <div id="error-field-card-cvc" class="error"></div>
    </div>
  </div>

  <div class="row row--button">
    <div id="error-pay" class="error error--global"></div>
    <gg-button id="pay-button" [disabled]="isDisabledDepositButton" [fullWidth]="true"
              [settings]="depositButton" (click)="createPay()">
      <sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"></sp-msg>
    </gg-button>
  </div>
</form>
