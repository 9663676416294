import { BonusDto } from '@portal/bonuses/data';
import { BonusInformation } from '@portal/bonuses/shared';
import cloneDeep from 'lodash.clonedeep';

export const mapInformation = (dto: BonusDto): BonusInformation => {
  const title = 'title' in dto ? cloneDeep(dto.title) : null;
  const description = 'description' in dto ? cloneDeep(dto.description) : null;
  const promo = 'promo' in dto ? cloneDeep(dto.promo) : null;
  const createdDate = dto.timing.dateCreation;

  return { title, description, promo, createdDate };
};
