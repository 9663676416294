<section *ngIf="banners$ | spAsync as banners" ggCreateThemeClass="main-banner">
  <ng-container *ngIf="isBrowser else image">
    <swiper-container ggSwiper init="false" class="banners-slider" [config]="bannersSliderConfig">
      <swiper-slide *ngFor="let banner of banners"><gg-banners-slide [banner]="banner"/></swiper-slide>
    </swiper-container>
    <div class="swiper-pagination"></div>
  </ng-container>
  <ng-template #image>
    <gg-banners-slide class="banners-slider" [banner]="banners[0]"/>
  </ng-template>
</section>
