import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HostedPipe } from './hosted.pipe';
import { SpHttpCredentialsInterceptor } from './interceptors/credentials.interceptor';
import { SpHttpHostInterceptor } from './interceptors/host.interceptor';
import { SP_HTTP_CONFIG, SP_HTTP_SERVICES, SpHttpConfig } from './utils';

@NgModule({
  imports: [ HttpClientModule ],
  declarations: [ HostedPipe ],
  exports: [ HttpClientModule, HostedPipe ],
  providers: [ HostedPipe ]
})
export class SpHttpModule {
  static init(config?: SpHttpConfig): ModuleWithProviders<SpHttpModule> {
    return {
      ngModule: SpHttpModule,
      providers: [
        { provide: SP_HTTP_CONFIG, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: SpHttpHostInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpHttpCredentialsInterceptor, multi: true },
        config?.withServer ? provideHttpClient(withFetch()) : [],
        config?.services ? { provide: SP_HTTP_SERVICES, useValue: config.services } : []
      ]
    };
  }
}
