import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UserDocument } from '@portal/verification/shared';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-verification-uploaded-docs',
  templateUrl: './uploaded-docs.component.html',
  styleUrls: ['./uploaded-docs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadedDocsComponent {
  @Input() documents: Nullable<Array<UserDocument>>;
}
