// @ts-nocheck

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccessDepositsGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userInfoQuery: UserQuery,
    private readonly source: ConfigQuery
  ) {}

  canActivate(): Observable<boolean | UrlTree> {

    return this.userInfoQuery.blocking$.pipe(
      map((blocking) => {
        const redirectPath = blocking.withdrawal
          ? [ this.source.modules.access.blockedSectionRedirectionPaths.deposit ]
          : [ '/cashier', 'withdrawal' ];

        return !blocking.deposit || this.router.createUrlTree(redirectPath);
      })
    );
  }
}
