import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { SwiperModule } from '@portal/shared/components';
import { BonusCardModule } from '../card/card.module';
import { BonusesListComponent } from './bonuses-list.component';
import {ThemeModule} from '@portal/config';

@NgModule({
  imports: [SpCDKModule, BonusCardModule, SwiperModule, ThemeModule ],
  declarations: [ BonusesListComponent ],
  exports: [ BonusesListComponent ]
})
export class BonusesListModule {}
