import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SP_LANGUAGES_CONFIG, SP_LOCALIZATION_TRANSFORM, SpLocalizationConfig } from './config';
import { createBaseHref } from './create-base.util';
import { SpLocalization } from './localization.service';

@NgModule({
  imports: [ CommonModule, TranslateModule.forRoot() ],
  providers: [ SpLocalization ]
})
export class SpLocalizationCoreModule {
  static init(config: SpLocalizationConfig): ModuleWithProviders<SpLocalizationCoreModule> {
    const languages = config.languages ? { provide: SP_LANGUAGES_CONFIG, useValue: config.languages } : [];
    const transform = config.transform ? { provide: SP_LOCALIZATION_TRANSFORM, ...config.transform } : [];
    const baseHref = { provide: APP_BASE_HREF, useFactory: createBaseHref(config), deps: [ SpLocalization ] };
    const loader = config.loader ? { provide: TranslateLoader, ...config.loader } : [];

    return { ngModule: SpLocalizationCoreModule, providers: [ loader, transform, languages, baseHref ] };
  }
}
