import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ControlAdapter, excludeNonNumericCharacters, normalizeFloatAmount, Nullable, watch } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentQuery } from '@portal/payment/data';
import { Limits } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { UserQuery } from '@portal/user';
import { fromEvent, merge } from 'rxjs';

@Component({
  standalone: true,
  selector: 'gg-payment-amount-selector',
  templateUrl: './amount-selector.component.html',
  styleUrls: [ './amount-selector.component.scss', './amount-selector-purple.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule, ThemeModule, CurrenciesModule ],
})
export class AmountSelectorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  private readonly watcher = watch();
  private readonly userQuery = inject(UserQuery);
  private readonly adapter = new ControlAdapter<number>(inject(NgControl));
  private readonly paymentsQuery = inject(PaymentQuery);

  readonly currency = this.userQuery.currency;

  @Input() limits: Nullable<Limits>;
  @Input({ required: true }) isDeposit: boolean = false;

  @ViewChild('field', { static: true }) input: Nullable<ElementRef<HTMLInputElement>> = null;

  get control(): FormControl { return this.adapter.control as FormControl; }

  get currentStep(): number {
    const value = this.adapter.control?.value;
    const stepSetting = this.paymentsQuery.amountSteps?.find((step) => value >= step.from && (!step.to || step.to > value));
    return this.limits?.rounding || stepSetting?.step || 1;
  }

  constructor() {
    this.adapter.registerOnModelChange((value) => this.applyValue(value));
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  ngOnInit(): void {
    this.selectAmount();
  }

  ngAfterViewInit(): void {
    const changeControl = this.input?.nativeElement && merge(
      fromEvent<FocusEvent>(this.input.nativeElement, 'focus'),
      fromEvent<FocusEvent>(this.input.nativeElement, 'blur'),
      fromEvent<InputEvent>(this.input.nativeElement, 'input'),
    ).subscribe((e: InputEvent | FocusEvent) => {
      if (this.input?.nativeElement && e instanceof InputEvent && (e.inputType === 'deleteContentBackward' || e.inputType === 'insertFromPaste' || isNaN(Number(e.data)))) {
        this.input.nativeElement.value = this.input.nativeElement.value ? String(excludeNonNumericCharacters(this.adapter.control?.value)) : '';
        return;
      }
      if (this.input?.nativeElement && e instanceof FocusEvent) {
        const value = Number((e.target as HTMLInputElement).value);
        this.applyValue(value);
      }
    });

    this.watcher.add(changeControl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { limits: { currentValue, previousValue, firstChange } } = changes;
    if (firstChange || currentValue.min === previousValue.min && currentValue.max === previousValue.max) return;
    this.selectAmount();
  }

  increase(): void {
    this.applyValue(this.adapter.control?.value + this.currentStep);
  }

  decrease(): void {
    if (this.adapter.control?.value > this.currentStep) {
      this.applyValue(this.adapter.control?.value - this.currentStep);
    }
  }

  private selectAmount(): void {
    if (!this.limits) { return; }
    const { min, max } = this.limits;
    const defaultAmount = this.paymentsQuery.defaultAmount;
    const amount = defaultAmount && defaultAmount > min ? defaultAmount : min;
    const currentValue = this.adapter.control?.value;

    if (currentValue < min || currentValue > max) {
      this.applyValue(amount);
    }
  }

  private applyValue(value: number): void {
    if (!this.input?.nativeElement) { return; }

    value = this.convertInput(value);
    this.adapter.change(excludeNonNumericCharacters(value));
    this.input.nativeElement.value = value.toString();
  }

  private convertInput(input: number): number {
    const rounding = this.limits?.rounding ? this.limits.rounding : 1;

    const value = Math.ceil(normalizeFloatAmount(input));
    const valueMax = Math.floor(this.limits?.inputMax || 0);
    const rounded = Math.floor(value / rounding) * rounding;
    const roundedMax = Math.floor(valueMax / rounding) * rounding;

    if (this.limits?.rounding) { return rounded; }
    if (value < Number(this.limits?.min) && Number(this.limits?.min) <= valueMax) { return Number(this.limits?.min); }
    if (value > valueMax) { return this.limits?.rounding ? roundedMax : valueMax; }

    return value;
  }

}
