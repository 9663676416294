import { AfterContentInit, ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-rounded-search',
  templateUrl: './input-search.component.html',
  styleUrls: [ './input-search.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputRoundedSearchComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputRoundedSearchComponent extends InputAbstract implements AfterContentInit {
  collapsed = false;

  @Input() isCollapsible = false;

  ngAfterContentInit(): void {
    if (!this.isCollapsible || this.value) { return; }
    this.collapsed = true;
  }

  expand(): void {
    if (!this.isCollapsible || !this.collapsed) { return; }
    this.collapsed = false;
    this.input?.nativeElement.focus();
  }

  collapse(): void {
    if (this.value) { return; }
    this.collapsed = true;
  }
}
