import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true } ],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent extends InputAbstract {}
