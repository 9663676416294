import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export const SLIDER_ITEMS_MIN = 7;
export const PLACEHOLDER_SLIDE = 'fake';

export const FOOTER_PAYMENTS_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  spaceBetween: 1,
  keyboard: false,
  navigation: {
    nextEl: '.swiper__btn--next-payments',
    prevEl: '.swiper__btn--prev-payments',
    disabledClass: 'swiper__btn--disabled'
  },
  breakpoints: {
    768: { spaceBetween: 2 },
    1199: { allowTouchMove: false, spaceBetween: 2 }
  }
};

export const FOOTER_PROVIDERS_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  spaceBetween: 2,
  navigation: {
    nextEl: '.swiper__btn--next-providers',
    prevEl: '.swiper__btn--prev-providers',
    disabledClass: 'swiper__btn--disabled'
  },
  breakpoints: {
    1199: { allowTouchMove: false }
  }
};
