<ng-container *spLet="isDeposit$ | spAsync as deposit">
  <ng-container *spLet="activeMethod$ | spAsync as method">
    <ng-container *spLet="commissionWarning$ | spAsync as commissionWarning">
      <ng-container *spLet="commissionInfo$ | spAsync as commissionInfo">
        <gg-payment-commission-warning *ngIf="commissionWarning" [amount]="builder.snapshot.amount" (closeCommission)="closeCommissionWarning($event)"/>
        <gg-payment-commission-info *ngIf="commissionInfo" (closeCommission)="closeCommissionInfo()"/>
        <ng-container *ngIf="method && !commissionWarning && !commissionInfo">
          <gg-stepper [(selectedIndex)]="step" class="bank-card-flow__stepper" [beforeChange]="submitStep.bind(this)">
            <cdk-step *ngFor="let control of form.controls;">
              <ng-container *ngIf="isGenerated(control) | spAsync as gen else selector">
                <sp-msg class="bank-card-flow__title" *ngIf="method.data?.payment === gen.form" [spMsg]="deposit ? 'CASHIER.CARD.USER_DATA_FORM_TITLE_DEPOSIT' : 'CASHIER.CARD.USER_PAYMENT_WITHDRAWAL_DATA_TITLE'"/>
                <gg-payment-generated-form class="bank-card-flow__form" [declaration]="gen.form" [formControl]="gen.control"/>
              </ng-container>
              <ng-template #selector>
                <gg-payment-bank-card-selector [formControl]="card"/>
              </ng-template>
              <gg-payment-summary (showCommissionInfo)="showCommissionInfo()"/>
            </cdk-step>
            <gg-button [settings]="submitButton" [disabled]="invalid" class="bank-card-flow__button" ggStepperNext>
              <sp-msg [msg]="'CASHIER.CARD.MAKE_' + (deposit ? 'DEPOSIT' : 'WITHDRAW')"/>
            </gg-button>
          </gg-stepper>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
