<div class="phone-reg-popup">
  <div class="phone-reg-popup__title">
    <sp-msg spMsg="COMMON.CONGRATULATIONS"></sp-msg>
  </div>
  <p class="phone-reg-popup__text">
    <sp-msg spMsg="CASHIER.BONUSES.YOU_RECEIVED_NO_DEP_BONUS" spaces="end"></sp-msg>
    <span class="phone-reg-popup__text--game">{{gameName}}</span>
    <sp-msg spMsg="CASHIER.BONUSES.FOR_THE_AMOUNT" spaces="start"></sp-msg>
  </p>
  <gg-button
      *ngIf="gameUrl as gameUrl"
      class="phone-reg-popup__button"
      [routerLink]="gameUrl"
      [settings]="inGameButton"
      (click)="clearLocalStorageItems()"
  >
    <sp-msg spMsg="GAMES.PLAY"></sp-msg>
  </gg-button>
  <a href="{{promoLink}}" target="_blank" class="phone-reg-popup__extra-text">
    <sp-msg style="text-transform: lowercase" spMsg="COMMON.MORE_DETAILS_IN_NEWS"></sp-msg>
  </a>
  <img src="assets/images/icons/cross.svg" class="phone-reg-popup__close" (click)="close()" alt=""/>
</div>
