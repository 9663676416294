import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthorizationQuery } from '@portal/authorization/data';

export const getDefaultRegistrationMethodGuard = (): UrlTree | true => {
  const methods = inject(AuthorizationQuery);
  const router = inject(Router);

  const method = methods.registrationMethods.find(item => item.isDefault)?.type;
  return router.createUrlTree(method ? [ 'auth', 'registration', `by-${method}` ] : [ '/' ]);

};
