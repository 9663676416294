import { HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { SpAuthorizationCommands, TokensPair } from '@libs/authorization';
import { Nullable } from '@libs/utils';
import { USER_AGENT } from '@ng-web-apis/common';
import { UserInfoDto } from '@portal/user/data';
import { UserInfoResponse } from '@portal/user/shared';

type AuthorizationResponse = { _item: { accessToken: string; refreshToken: string } };

export class AuthorizationCommands<T extends AuthorizationResponse> extends SpAuthorizationCommands {
  agent = inject(USER_AGENT);

  mapDiscoveredUser({ _item }: UserInfoResponse): UserInfoDto { return _item; }

  parseExpiresIn(): Nullable<number> { return 60 * 1000 * 5; }

  parseTokens(response: T): TokensPair { return response?._item || null; }

  override patchSignInRequest(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    if (request.url.includes('/api/2/auth/registration') && request.method === 'POST') {
      // @ts-expect-error body is of unknown type
      const body = { ...(request.body || {}), data: { ...(request.body).data, ua: this.agent } };
      return request.clone({ body });
    }
    if (request.url.includes('/api/2/auth/password/create')) {
      return this.patch(request, tokens);
    }

    const body = { ...(request.body || {}), ua: this.agent };
    return request.clone({ body });
  }

  override patchRefreshRequest(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    return request.clone({ body: { token: tokens?.refreshToken, ua: this.agent } });
  }

  override patchAuthorizedRequest(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    return this.patch(request, tokens);
  }

  override patchDiscoveryRequest(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    return this.patch(request, tokens);
  }

  override patchSignOutRequest(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    return request.clone({ body: { refreshToken: tokens?.refreshToken } });
  }

  private patch(request: HttpRequest<unknown>, tokens?: TokensPair): HttpRequest<unknown> {
    const headers = request.headers.set('X-Authorization', `Bearer ${tokens?.accessToken}`);
    return request.clone({ headers });
  }
}
