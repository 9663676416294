import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './list.component.html',
  styleUrls: [ './list.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly navigation = inject(SpNavigation);
  private readonly bonusQuery = inject(BonusesQuery);
  private readonly bonusData = inject(BonusesData);

  readonly hasEntities: Subscription;

  constructor() {
    this.hasEntities = this.bonusQuery.hasBonuses$.pipe(first()).subscribe(
      (entities) => !entities && this.moveToEmptyPage()
    );
  }

  ngOnDestroy(): void {
    this.bonusData.clearStore();
  }

  private moveToEmptyPage(): void {
    this.navigation.navigate([ 'empty' ], {}, { skipLocationChange: true, relativeTo: this.route });
  }
}
