import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, Observable } from 'rxjs';
import { withLatestFrom, tap, switchMap } from 'rxjs/operators';
import { synchronize } from '@libs/store';
import { ControlAdapter, Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses';
import { BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-payment-bonus-button',
  templateUrl: './bonus-button.component.html',
  styleUrls: [ './bonus-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusButtonComponent implements OnInit {
  private readonly ngControl = inject(NgControl, { self: true });
  private readonly adapter: ControlAdapter<Nullable<Bonus>> = new ControlAdapter();
  private readonly bonusesData = inject(BonusesData);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly isCryptoMethodSubject = new BehaviorSubject<Nullable<boolean>>(false);
  private readonly activeSelectedBonus: BehaviorSubject<Nullable<Bonus>> = new BehaviorSubject<Nullable<Bonus>>(null);

  readonly activeSelectedBonus$: Observable<Nullable<Bonus>> = this.activeSelectedBonus.asObservable();
  readonly isBonusesEnabled = inject(UserQuery).isBonusesEnabled;

  showPromocodeInput: boolean = false;
  bonusesList: Nullable<Observable<Array<Bonus>>> = null;
  maxPrice: Nullable<number>;

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  get isLinkedBonus(): boolean {
    return this.activeBonus?.type === 'casino_linked';
  }

  @Input()
  set maxMethodPrice(value: Nullable<number>) {
    this.bonusesList = this.bonusesQuery.selectAvailableDepositBonuses(String(value));
    this.maxPrice = value;
  }

  @Input()
  set selectedBonus(value: Nullable<Bonus>) {
    this.selectBonus(value);
  }

  @Input()
  set isCryptoMethod(value: Nullable<boolean>) {
    this.isCryptoMethodSubject.next(value || false);
  }

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  get isCryptoMethod(): Nullable<boolean> {
    return this.isCryptoMethodSubject.value;
  }

  constructor() {
    synchronize(this.bonusesData, this.bonusesQuery);
    this.ngControl.valueAccessor = this.adapter.accessor;
    this.adapter.registerOnModelChange((value) => {
      if (value === null) {
        this.activeSelectedBonus.next(null);
        this.bonusesData.unselectBonus(false);
      } else {
        this.bonusesData.selectBonus(value as Bonus, this.isCryptoMethod);
        this.activeSelectedBonus.next(value);
      }
    });
  }

  ngOnInit(): void {
    this.activeBonus && this.adapter.change(this.activeBonus);
    this.setActiveBonus();
  }

  promocodeActivated(): void {
    this.bonusesData.selectBonus(this.activeBonus as Bonus, this.isCryptoMethod);
    this.activeSelectedBonus.next(this.activeBonus);
    this.adapter.change(this.activeBonus);
  }

  setActiveBonus(): void {
    this.isCryptoMethodSubject.pipe(
      takeUntilDestroyed(this.destroyRef$),
      switchMap((isCryptoMethod) => {
        return combineLatest([
          this.bonusesQuery.selectAvailableDepositBonuses(String(this.maxPrice)),
          this.bonusesQuery.selectAvailableCryptoDepositBonuses(isCryptoMethod),
        ]);
      }),
      filter(([ bonuses ]) => !!bonuses?.length),
      withLatestFrom(this.bonusesQuery.depBonusActivated$),
      tap(([ [ bonuses ], depBonusActivatedValue ]) => {
        if (depBonusActivatedValue && !this.isCryptoMethod && !this.activeBonus) {
          this.bonusesData.unselectBonus();
        }
        if (depBonusActivatedValue || this.activeBonus) {
          return;
        }
        const doubleBonus = bonuses?.find((bonus) => bonus?.type === 'casino_linked');
        const bonusForActivation = doubleBonus ? doubleBonus : bonuses[ 0 ];
        this.isBonusesEnabled ? this.bonusesData.selectBonus(bonusForActivation) : this.bonusesData.unselectBonus();
      }),
      tap(([ [ , activeCryptoBonus ] ]) => {
        if (this.isCryptoMethod) {
          activeCryptoBonus ? this.bonusesData.selectBonus({ id: activeCryptoBonus }) : this.bonusesData.unselectBonus();
        }
      }),
      tap(() => { this.activeSelectedBonus.next(this.activeBonus) }),
      tap(() => this.activeBonus && this.adapter.change(this.activeBonus)),
    ).subscribe();
  }

  private selectBonus(bonus: Nullable<Bonus>): void {
    this.activeSelectedBonus.next(bonus);
    if (bonus) {
      this.bonusesData.selectBonus(bonus, this.isCryptoMethod);
      this.adapter.change(bonus);
    } else {
      this.bonusesData.unselectBonus(this.isCryptoMethod);
      this.adapter.change(null);
    }
  }
}
