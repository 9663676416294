import { PaymentMethod } from '@portal/payment/data';

export class PaymentGroup {
  constructor(
    readonly type: string,
    readonly id: string,
    readonly icon: string,
    readonly name: string,
    readonly limits: { min: number; max: number },
    readonly methods: Array<PaymentMethod>
  ) {}
}
