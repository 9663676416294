import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-info',
  templateUrl: './info.component.html',
  styleUrls: [ './info.component.scss', './silver.info.scss', './purple.info.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus> = null;
}
