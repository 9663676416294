import { OrArray } from '@libs/utils';
import { Breadcrumb } from '@portal/layout/data';

export function createBreadcrumb(label: string, link?: Array<string>): Breadcrumb {
  return new Breadcrumb(label, link);
}

export function createBreadcrumbsFrom(segments: OrArray<string>): Array<Breadcrumb> {
  const breadcrumbs: Array<Breadcrumb> = [];

  if (!Array.isArray(segments)) { segments = segments.split('/'); }

  segments.filter(Boolean).reduce((path: Array<string>, segment: string) => {
    const hiddenPages = [ 'user', 'error', 'auth', 'cashier', 'empty', 'page' ];

    [ segment ] = segment.split('?');

    path = [ ...path, segment ];

    const breadcrumb = hiddenPages.some((page) => path.includes(page))
      ? createBreadcrumb(segment)
      : createBreadcrumb(segment, path);

    if (breadcrumb.label) { breadcrumbs.push(breadcrumb); }

    return path;
  }, []);

  return breadcrumbs;
}
