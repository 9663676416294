import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { LoaderAnalyticsHandler } from './abstract.handler';

type FBWindow = { fbq: any; _fbq: any }

export class FbHandler extends LoaderAnalyticsHandler {
  private readonly window = this.injector.get<GlobalWindow<FBWindow>>(WINDOW);
  private readonly id = this.injector.get(ConfigQuery).modules.analytics.trackers.fbPixel;

  override create(): void {
    this.loadScript('https://connect.facebook.net/en_US/fbevents.js', () => {
      const n: FBWindow['fbq'] = this.window.fbq = function (...args: any): void {n.callMethod ? n.callMethod(...args) : n.queue.push(args);};
      if (!this.window._fbq) this.window._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      this.window.fbq('init', `${this.id}`);
      this.window.fbq('track', 'PageView');
    });
  }

  override onNavigationEnds(): void {
    !this.window.fbq ? this.create() : this.window.fbq('track', 'PageView');
  }
}
