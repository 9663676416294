<section class="transfers" *ngIf="isTransferSent$ | spAsync else transferForm">
  <div class="transfers__wrapper transfers__wrapper-success">
    <div class="transfers__header">
        <img class="transfers__header-img" src="assets/images/icons/ok.svg" alt=""/>
        <h3 class="transfers__header-title" spMsg="CASHIER.STATUS.REQUEST_ACCEPTED"></h3>
    </div>
    <p class="transfers__text">
      <sp-msg spMsg="CASHIER.STATUS.REQUEST_ACCEPTED_AND_PROCESSED" spaces="end"></sp-msg>
      <span class="transfers__text-bold"><sp-msg spMsg="CASHIER.STATUS.REQUEST_24"></sp-msg></span>
    </p>
    <gg-button (click)="back()">OK</gg-button>
  </div>
</section>
<ng-template #transferForm>
  <section class="transfers">
    <div class="transfers__wrapper">
      <form [formGroup]="form" (ngSubmit)="makeTransfer()">
        <div class="transfers__container">
          <span class="transfers__container-title" spMsg="CASHIER.TRANSFERS.SUM"></span>
          <div class="transfers__container-wrapper">
            <gg-input formControlName="amount" [errors]="errors.get('amount') | async"/>
            <span class="transfers__container-currency">{{ currency$ | async | currencySymbol }}</span>
          </div>
        </div>
        <div class="transfers__container">
          <span class="transfers__container-title" spMsg="CASHIER.TRANSFERS.RECIPIENT"></span>
          <gg-input formControlName="nick" [errors]="errors.get('nick') | async"/>
        </div>
        <gg-button class="transfers__button" [settings]="button" (click)="makeTransfer()" [disabled]="form.invalid">
          <sp-msg spMsg="CASHIER.TRANSFERS.MAKE_TRANSFER"/>
        </gg-button>
      </form>
    </div>
  </section>
</ng-template>
