import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export const BANK_CARD_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  spaceBetween: 10,
  slidesPerView: 'auto',
  allowTouchMove: true,
  keyboard: false,
  passiveListeners: true,
  touchReleaseOnEdges: false,
  touchMoveStopPropagation: true,
  slideToClickedSlide: true,
  injectStyles: [ '.card-view__cards: {width: auto;}', '.swiper {overflow: visible;}' ],
  navigation: {
    nextEl: '.card-view__btn-next',
    prevEl: '.card-view__btn-prev',
    disabledClass: 'card-view__btn-disabled',
  },
  pagination: {
    el: '.cards-pagination',
    clickable: true,
  },
};
