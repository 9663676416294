export enum ImpayerPaymentMethods {
  Deposit = 264,
  Withdrawal = 265,
  DepositTrusted = 272,
  BankCardDepositEUR = 338,
  BankCardDepositUSD = 339,
  BankCardWithdrawalEUR = 340,
  BankCardDepositRUBTrust = 343,
  BankCardDepositRUBWithdrawal = 344,
  BankCardDepositRUB = 361,
  BankCardDepositSpb = 370,
  SkrillDeposit = 401,
  SkrillWithdrawal = 402,
  NetellerDeposit = 406,
  DepositP2P = 429
}
