<div class="swiper__title" [spMsg]="title"></div>
<section class="swiper">
  <swiper-container ggSwiper init="false" class="swiper__items" [config]="config">
    @for (item of items; track $index) {
      <swiper-slide class="swiper__item swiper__item--{{item}}" *spLet="isRealItem(item) as isReal">
      <a [routerLink]="createPath(item)" title="{{ isReal ? item  : '' }}" class="swiper__link">
        @if (isReal) {
          <img [ngClass]="item" class="swiper__icon icon" src="assets/images/footer/{{folder}}/{{createLogoPath(item)}}.svg" loading="lazy" alt="logo"/>
        }
      </a>
    </swiper-slide>
    }
  </swiper-container>
  <div class="swiper__btn--prev-{{section}} swiper__btn"><i class="swiper__btn-icon"></i></div>
  <div class="swiper__btn--next-{{section}} swiper__btn"><i class="swiper__btn-icon"></i></div>
</section>
