import { UrlMatcherOptions } from '../types';
import { arrayalize } from './arrayalize';

export function isUrlMatched(matcher: UrlMatcherOptions): (source: string) => boolean {
  if (!matcher.match) {matcher.match = 'include'; }

  return (source) => {
    if (!matcher.urls.length) { return false; }

    return arrayalize(matcher.urls).some((segment) => {
      return matcher.match === 'exact' ? segment === source : source.includes(segment);
    });
  };
}
