import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { UserQuery } from '@portal/user';
import { filter, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-payment-user-balance',
  templateUrl: './user-balance.component.html',
  styleUrls: [ './user-balance.component.scss' ],
  imports: [ CurrenciesModule, SpCDKModule ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBalanceComponent {
  private readonly userQuery = inject(UserQuery);
  readonly balance$ = this.userQuery.balance$?.pipe(
    filter((balance) => !!balance), map((balance) => balance?.balance),
  );
}
