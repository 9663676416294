import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { RecaptchaInputDialogComponent, RecaptchaQuery } from '@portal/recaptcha';
import { ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { UserData } from '@portal/user/data';
import { EmailConfirmationService } from '@portal/user/features/email-confirmation/email-confirmation.service';
import { createEmailForm } from '@portal/user/shared';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'gg-user-email-confirmation-change-email',
  templateUrl: './change-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ErrorManager ]
})
export class ChangeEmailComponent implements OnDestroy {
  private readonly errorManager = inject(ErrorManager);
  private readonly recaptchaQuery = inject(RecaptchaQuery);
  private readonly dialog = inject(Dialog);
  private readonly notifications = inject(SpNotification);
  private readonly userSettingsCommands = inject(UserData);
  private readonly confirmationService = inject(EmailConfirmationService);
  private currentEmail: Nullable<string> = '';
  private eventSubscription: Subscription = new Subscription();

  readonly actionButtonConfig: IButton = { theme: ButtonThemes.White, fullWidth: true };
  readonly changeEmailForm: FormGroup = createEmailForm();
  readonly changeEmailErrors: ErrorManager = this.errorManager.setUp({ form: this.changeEmailForm });

  constructor() {
    this.confirmationService.email$
      .pipe(first())
      .subscribe((email) => this.currentEmail = email);

    this.changeEmailForm.setValue({ email: this.currentEmail, recaptchaToken: null });
  }

  changeEmail(recaptchaToken?: Nullable<string>): void {
    if (this.changeEmailForm.invalid) { return; }

    const { email } = this.changeEmailForm.value;

    if (this.currentEmail === email) {
      this.confirmationService.changeViewState('notConfirmed');
    } else if (!recaptchaToken && this.recaptchaQuery.isRecaptchaEnabledFor('profileEmail')) {
      this.eventSubscription = this.dialog.open(RecaptchaInputDialogComponent, { panelClass: '' }).closed.subscribe(
        (newRecaptchaToken): void => { newRecaptchaToken && this.changeEmail(newRecaptchaToken as Nullable<string>); }
      );
    } else {
      this.userSettingsCommands.changeEmail(email, recaptchaToken).subscribe({
        next: () => this.notifications.success('NOTIFICATIONS.MESSAGES.EMAIL_CONFIRMATION_EMAIL_CHANGED'),
        error: () => this.notifications.error('NOTIFICATIONS.MESSAGES.EMAIL_CONFIRMATION_EMAIL_CHANGE_ERROR'),
        complete: () => this.confirmationService.changeViewState('notConfirmed')
      });
    }
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }
}
