import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';

@Component({
  selector: 'gg-documents-upload-selector',
  templateUrl: './documents-upload-selector.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DocumentsUploadSelectorComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsUploadSelectorComponent extends InputAbstract implements OnInit {
  readonly document = new FormControl();

  defaultItem: Nullable<IInputSelectData>;
  truncateSelectedTo = 22;
  @Input() documentList: Array<IInputSelectData> = [];

  override ngOnInit(): void {
    this.defaultItem = { value: '', description: 'DOCUMENTS.DOCUMENT_TYPE' };
    this.document.valueChanges.subscribe((value) => this.writeValue(value));
  }

}
