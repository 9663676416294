import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PromotionState, PromotionStore } from '@portal/promotions/data/store/promotion.store';
import { PromotionTypes } from '@portal/promotions/shared/types/promotion-types';
import { Promotion } from '@portal/promotions/data/entities';
import { Nullable } from '@libs/utils';
import { filterPromotions, getMorePromotions } from 'apps/portal/src/app/promotions/data/mappers';
import { SteroidQuery } from '@libs/store';

@Injectable({ providedIn: 'root' })
export class PromotionQuery extends SteroidQuery<PromotionState> {
  private readonly promotions = this.entity('promotions');

  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly filters$: Observable<PromotionTypes> = this.select('filters');

  get filters(): PromotionTypes { return this.get('filters'); }

  constructor(store: PromotionStore) { super(store); }

  getPromotion(slug: Promotion['slug']): Nullable<Promotion> {
    return this.promotions.getEntity(slug);
  }

  findReadAlso(forPromotion: Promotion): Array<Promotion> {
    return getMorePromotions(this.promotions.getAll(), forPromotion);
  }

  filterPromotion(): Observable<Array<Promotion>> {
    return this.filters$.pipe(switchMap((filters) => filterPromotions(filters, this.promotions.selectAll())));
  }
}
