import { ModuleWithProviders, NgModule } from '@angular/core';
import { Language, SpCDKModule, SpLocalization } from '@libs/cdk';
import { RecaptchaQuery } from '@portal/recaptcha/data';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module,
} from 'ng-recaptcha';

@NgModule({
  imports: [SpCDKModule, RecaptchaModule, RecaptchaV3Module, RecaptchaFormsModule],
})
export class RecaptchaConfig {
  static init(): ModuleWithProviders<RecaptchaConfig> {
    const createLanguage = ({ currentLanguage }: { currentLanguage: Language }): Language => currentLanguage;
    const createSettings = ({ recaptchaKey }: RecaptchaQuery): RecaptchaSettings => ({
      siteKey: recaptchaKey as NonNullable<string>,
    });

    return {
      ngModule: RecaptchaConfig,
      providers: [
        { provide: RECAPTCHA_LANGUAGE, useFactory: createLanguage, deps: [SpLocalization] },
        { provide: RECAPTCHA_SETTINGS, useFactory: createSettings, deps: [RecaptchaQuery] },
      ],
    };
  }
}
