// @ts-nocheck

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { UserQuery } from '@portal/user';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DepositsEnabledGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userInfoQuery: UserQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (route.params.type !== CashierSections.Deposit) {
      return of(true);
    }

    return this.userInfoQuery.isDocumentsConfirmed$.pipe(
      map((isDepositsEnabled) => {
        return isDepositsEnabled || this.router.createUrlTree([ 'cashier', 'deposit-verification-docs' ]);
      })
    );
  }
}
