import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { InputPhoneWithCountryModule } from '@portal/countries';
import { RecaptchaEnabledModule, RecaptchaInputDialogFeatureModule } from '@portal/recaptcha';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';
import { ErrorManager } from '@portal/shared/helpers';
import { ChangePasswordComponent } from '@portal/user/features/settings/change-password/change-password.component';
import { EmailConfirmationComponent } from '@portal/user/features/settings/contact-details/components/email-confirmation/email-confirmation.component';
import { PhoneInProgressComponent } from '@portal/user/features/settings/contact-details/components/phone-in-progress/phone-in-progress.component';
import { PhoneNotVerifiedComponent } from '@portal/user/features/settings/contact-details/components/phone-not-verified/phone-not-verified.component';
import { PhoneVerifiedComponent } from '@portal/user/features/settings/contact-details/components/phone-verified/phone-verified.component';
import { ContactDetailsComponent } from '@portal/user/features/settings/contact-details/contact-details.component';
import { PersonalInfoComponent } from '@portal/user/features/settings/personal-info/personal-info.component';
import { ResponsibleGamingComponent } from '@portal/user/features/settings/responsible-gaming/responsible-gaming.component';
import { AuthMethodComponent } from '@portal/user/features/settings/two-factor-authentication/components/auth-method/auth-method.component';
import { MethodStatusComponent } from '@portal/user/features/settings/two-factor-authentication/components/method-status/method-status.component';
import { TwoFactorAuthenticationComponent } from '@portal/user/features/settings/two-factor-authentication/two-factor-authentication.component';
import { MomentModule } from 'ngx-moment';

const features = [
  ChangePasswordComponent,
  PersonalInfoComponent,
  ContactDetailsComponent,
  TwoFactorAuthenticationComponent,
  ResponsibleGamingComponent
];

const notExportFeatures = [
  EmailConfirmationComponent,
  PhoneInProgressComponent,
  PhoneNotVerifiedComponent,
  PhoneVerifiedComponent,
  AuthMethodComponent,
  MethodStatusComponent
];

@NgModule({
  imports: [
    SpCDKModule,
    ClipboardModule,
    MomentModule,
    ControlsModule,
    RecaptchaEnabledModule,
    ThemeModule,
    ClipboardCopyComponent,
    ReactiveFormsModule,
    RecaptchaInputDialogFeatureModule,
    ContentModule,
    InputPhoneWithCountryModule
  ],
  declarations: [
    features,
    notExportFeatures
  ],
  exports: [
    features
  ],
  providers: [ ErrorManager ]
})
export class SettingsFeaturesModule {
}
