@if(isConfigAvailable && ((available$ | async) || ((isPWAAvailable$ | async) && authorized))) {
  <a [ngClass]="classWithOS"
     class="download-app"
     spPrevent="all"
     (click)="processClick()">
    <sp-msg msg="COMMON.PROJECT_NAME" spaces="end"/>
    <sp-msg msg="COMMON.FOR"/>
    {{ os }}
  </a>
}
