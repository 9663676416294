import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { AmountSteps, BankCard } from '@portal/payment/data';
import { PaymentContainer } from '@portal/payment/data/entities/payment-container';
import { PaymentOperation, WidgetParams } from '@portal/payment/shared';

export type PaymentState = {
  params: WidgetParams;
  amountSteps: AmountSteps;
  defaultAmount: Nullable<number>;
  container: Record<PaymentOperation, Array<PaymentContainer>>;
  entities: { cards: SteroidEntity<BankCard> };
  sync: boolean;
};

const initial: SteroidConfig<PaymentState> = {
  options: { name: 'payments' },
  initial: { params: {}, container: { deposit: [], withdrawal: [] }, amountSteps: [], defaultAmount: null, sync: false },
  entities: { cards: { idKey: 'id' } },
};

@Injectable({ providedIn: 'root' })
export class PaymentStore extends SteroidStore<PaymentState> {
  constructor() {super(initial);}
}
