import { inject, Injectable } from '@angular/core';
import { SpStorage } from '@libs/cdk';
import { parseParam, ReferralObject } from '@portal/authorization/shared';
import { StorageKey } from '@portal/shared/types';

@Injectable({ providedIn: 'root' })
export class ReferralQuery {
  private readonly storage: SpStorage = inject(SpStorage);

  get fToken(): string | null { return this.storage.getItem(StorageKey.AppFToken); }

  get info(): ReferralObject {
    return {
      cpaInfo: parseParam(this.storage.getItem(StorageKey.Cpa)),
      referer: this.storage.getItem(StorageKey.Referer),
      uriParams: parseParam(this.storage.getItem(StorageKey.UriParams)),
      source: parseParam(this.storage.getItem(StorageKey.UriParams))
    };
  }
}
