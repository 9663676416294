import { Validators } from '@angular/forms';

export const USER_DATA_BTGATE_BANK_CARD_DEPOSIT = {
  first_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  last_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  phone: [
    '',
    [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(15)
    ]
  ],
  city: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ]
};
