import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import {ErrorManager, ErrorResponse} from '@portal/shared/helpers';
import { createEmailForm, UserDataInfo } from '@portal/user/shared';
import { Subscription, tap, timer } from 'rxjs';
import { ContactDetailsService } from '@portal/user/features/settings/contact-details/contact-details.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UserData } from "@portal/user/data";


@Component({
  selector: 'gg-user-settings-contact-details-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: [
    './email-confirmation.component.scss',
    './purple.email-confirmation.component.scss',
    './silver.email-confirmation.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailConfirmationComponent implements OnDestroy, OnInit {
  private readonly actionsInfo: Subscription = new Subscription();
  private readonly userSettingsCommands = inject(UserData);
  private readonly window = inject(WINDOW);
  private readonly errorManager = inject(ErrorManager);
  private readonly contactDetailsService = inject(ContactDetailsService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly destroyRef$ = inject(DestroyRef);

  readonly emailForm: FormGroup = createEmailForm();
  readonly emailErrors: ErrorManager = this.errorManager.setUp({ form: this.emailForm });
  isChangeEmailMode = false;
  hiddenChangeEmail = false;

  @Input() user: UserDataInfo = { emailConfirmed: false, email: '', phone: '' };
  @Input() saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };
  @Input() isOnlyEmailConfirmation: boolean = false;

  constructor() {
    this.emailForm = this.contactDetailsService.changeEmailForm;
    this.emailErrors = this.contactDetailsService.changeEmailErrors;
  }

  ngOnInit(): void {
    this.contactDetailsService.isEmailChanged$
        .pipe(takeUntilDestroyed(this.destroyRef$))
        .subscribe(emailChanged => {
          if (emailChanged) {
            this.changeEmailTimer();
          }
        })
  }

  ngOnDestroy(): void {
    this.actionsInfo?.unsubscribe();
  }

  redirectOn(email: string): void {
    this.window.open(`//${email.split('@')[1]}`);
  }

  saveEmail(): void {
    const { email, recaptchaToken } = this.emailForm.value;

    const userSettingsCommands: Subscription = this.userSettingsCommands.changeEmail(email, recaptchaToken).subscribe({
      error: (err: ErrorResponse) => this.emailErrors.setServerErrors(err)
    });
    this.actionsInfo.add(userSettingsCommands);
  }

  showEmailForm(): void {
    this.emailForm.get('email')?.setValue(this.user.email);
    this.isChangeEmailMode = true;
  }

  changeEmail(): void {
    this.isChangeEmailMode = false;
    this.contactDetailsService.changeEmail();

  }

  changeEmailTimer(): void {
    this.hiddenChangeEmail = true;
    timer(60000).pipe(
        takeUntilDestroyed(this.destroyRef$),
        tap(() => {
          this.hiddenChangeEmail = false;
          this.cd.detectChanges();
        })
    ).subscribe();
  }
}
