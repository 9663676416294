import { excludeNullish } from '@libs/utils';
import { Payment } from '@portal/payment/shared';
import { BehaviorSubject } from 'rxjs';

export class PaymentBuilder {
  private readonly payment: BehaviorSubject<Payment> = new BehaviorSubject<Payment>({});
  private readonly completed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly payment$ = this.payment.asObservable();
  readonly completed$ = this.completed.asObservable();

  get snapshot(): Payment { return this.payment.getValue(); }

  complete(): void { this.completed.next(true); }

  reopen(): void { this.completed.next(false); }

  updatePayment({ data, ...rest }: Payment): void {
    const snapshot = this.snapshot;
    const additional = data?.additional ? { ...snapshot.data?.additional, ...data?.additional } : snapshot.data?.additional;
    const payment = data?.payment ? { ...snapshot.data?.payment, ...data?.payment } : snapshot.data?.payment;
    const card = data?.card ? { ...snapshot.data?.card, ...data?.card } : snapshot.data?.card;

    data = additional || payment || card ? { additional, payment, card } : snapshot.data;

    this.payment.next(excludeNullish({ ...snapshot, data, ...rest }));
  }
}
