import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { FooterNavigationItem } from '@portal/config/shared';
import { BehaviorSubject, fromEvent, startWith } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-footer-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule ]
})
export class NavigationComponent implements OnInit {
  private readonly destroy = inject(DestroyRef);
  private readonly window = inject(WINDOW);
  private readonly navigation = inject(SpNavigation);
  private readonly expanded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isWindowTabletSize = this.window.innerWidth > 991;

  protected readonly expanded$ = this.expanded.asObservable();

  @Input() items: Array<FooterNavigationItem> = [];
  @Input() headline: Nullable<string> = null;
  @Input() view: 'list' | 'contacts' = 'list';

  ngOnInit(): void {
    fromEvent(this.window, 'resize').pipe(
      startWith(null),
      map(() => this.isWindowTabletSize),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroy)
    ).subscribe(() => this.expanded.next(this.isWindowTabletSize));
  }

  expand(): void {
    if (this.isWindowTabletSize) { return; }
    this.expanded.next(!this.expanded.getValue());
  }

  navigate(item: FooterNavigationItem): void { this.navigation.process(item); }
}
