import { Validators } from '@angular/forms';
import { USER_DATA_INFO } from '@portal/cashier/components/card/constants/user-data-form-declaration.constant';

export const USER_DATA_PREMIER_PAY = {
  ...USER_DATA_INFO,
  zip_code: [
    '',
    [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(7)
    ]
  ],
  state: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ]
};
