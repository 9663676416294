import { DeepRequired, mergeOnlyObjects } from '@libs/utils';
import { ConfigDto, ConfigModules, ConfigState, Project, Theme } from '@portal/config/data';
import { placeholder } from '@portal/config/shared';
import mergeWith from 'lodash.mergewith';

type MConfig = { theme: Omit<Theme, 'isMatch'>; modules: ConfigModules; project: string;
  source: string; logo: { mobile: string; desktop: string }; };


const modifyConfig = ({ config, modules }: DeepRequired<ConfigDto>): MConfig => {
  const { localization, theme, ...project } = config;

  // change theme old/new light/dark to rounded/flat silver/purple
  const palette = (theme.palette === 'light' ? 'silver' : 'purple');
  const structure = (theme.structure === 'old' ? 'rounded' : 'flat');

  // move config.localization to modules.localization and change showLocalePopup to showLanguageSelectDialog
  const { showLocalePopup: showLanguageSelectDialog, ...rest } = localization;
  const configModules: ConfigModules = { ...modules, localization: { showLanguageSelectDialog, ...rest } };
  const logo = { mobile: config.mobile_logo_url, desktop: config.desktop_logo_url }

  // rebuild flatten config source (get rid of .config prop)
  return { theme: { palette, structure }, ...project, modules: configModules, logo };
};

export function createConfig(dto: ConfigDto): Omit<ConfigState, 'sync'> {
  const config = modifyConfig(mergeWith<DeepRequired<ConfigDto>, ConfigDto>(placeholder, dto, mergeOnlyObjects));
  const { theme, project, source, modules, logo } = config;

  return { theme: new Theme(theme.palette, theme.structure), project: new Project(project, source, logo), modules };
}
