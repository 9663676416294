import { Nullable } from '@libs/utils';
import { AuthorizationSection } from '@portal/authorization/shared';

export const getAuthSectionFromRoute = (route: string): Nullable<AuthorizationSection> => {
  if (route.includes('registration/by-phone')) { return AuthorizationSection.RegistrationByPhone; }
  if (route.includes('registration/by-email')) { return AuthorizationSection.Registration; }
  if (route.includes('login/otp')) { return AuthorizationSection.Otp; }
  if (route.includes('login')) { return AuthorizationSection.Login; }
  if (route.includes('authToken') || route.includes('social')) { return AuthorizationSection.SocialAuth; }
  if (route.includes('password-reminder')) { return AuthorizationSection.PasswordReminder; }

  return null;
};
