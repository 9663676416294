import { ObserversModule } from '@angular/cdk/observers';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SpCommonModule } from '../common';
import { SpByLanguagePipe } from './by-language.pipe';
import { SpMessageComponent } from './msg.component';
import { SpMsgDirective } from './msg.directive';

@NgModule({
  imports: [ SpCommonModule, TranslateModule, ObserversModule ],
  declarations: [ SpMessageComponent, SpMsgDirective, SpByLanguagePipe ],
  exports: [ SpMessageComponent, SpMsgDirective, TranslateModule, SpByLanguagePipe ],
  providers: [ SpByLanguagePipe, TranslatePipe ]
})
export class SpLocalizationModule {}
