import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { UserData } from '@portal/user/data';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'gg-user-settings-responsible-gaming',
  templateUrl: './responsible-gaming.component.html',
  styleUrls: [ './responsible-gaming.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsibleGamingComponent {
  private readonly fb = inject(FormBuilder);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly userSettingsCommands = inject(UserData);
  private readonly notifications = inject(SpNotification);
  private readonly source = inject(ConfigQuery);

  readonly form: FormGroup = this.fb.group({ period: [ '', Validators.required ] });
  readonly supportEmail = `mailto:${this.source.modules.contacts.support}`;
  readonly responsibleGamingLink = `/page/responsiblegaming`;
  readonly greenButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };
  readonly blockingPeriods = this.source.modules.selflockingPeriods;
  readonly blockingPeriodsDefault = this.source.modules.selflockingPeriods[0];
  readonly isSelfBlocked$: Observable<Nullable<boolean>> = this.userInfoQuery.selfBlocking$;
  readonly selfBlockedTimer: Observable<Moment> = this.userInfoQuery.selfBlockingUntil$.pipe(
    map(date => moment.utc(date).local()),
    tap(data => this.showNotification = moment(data).diff(moment.now()) < 0)
  );

  showNotification = false;

  constructor() {
    this.form.controls['period'].setValue(this.blockingPeriodsDefault.value);
  }

  selfBlocking(): void {
    const formPeriod = this.form.get('period')?.value ?? [];
    const endDate = moment(moment.now()).add(...formPeriod).local().toISOString() ?? '';

    this.userSettingsCommands.selfBlocking(endDate).subscribe({
      next: () => this.notifications.success('NOTIFICATIONS.MESSAGES.SECTION_BLOCKED'),
      error: () => this.notifications.error('INTERNAL ERROR')
    });
  }

  updateBlockingStatus(): void {
    this.showNotification = true;
  }
}
