import { PaymentHistory, PaymentHistoryDto } from '@portal/payment/data';

export const createPaymentHistory = (dto: PaymentHistoryDto): PaymentHistory => {
  const { currency, createDate: created, operationType: operation, reason, type } = dto;
  const amount = dto.amount.toString();
  const { paymentAccount: account, paymentMethodName: method } = dto;

  return {
    id: dto._id,
    info: { created, currency, amount, operation, type, reason },
    status: dto.status,
    payment: { account, method }
  };
};
