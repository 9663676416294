import { Nullable } from '../types';
import { isNullish } from './is-nullish';

type NullishRecord = Record<string, Nullable<unknown>>;
type NullishArray = Array<Nullable<unknown>>;
type Nullish = NullishRecord | NullishArray;

export function excludeNullish<T extends Nullish>(value: T): NonNullable<T> {
  if (Array.isArray(value)) {
    return value.map((v) => excludeNullish(v as Nullish)).filter((v) => !isNullish(v)) as NonNullable<T>;
  }

  if (typeof value === 'object') {
    return Object.entries(value).reduce((acc, [ key, v ]) => {
      const isDefined = !isNullish(v);
      if (isDefined && typeof v === 'object') { return { ...acc, [key]: excludeNullish(v as Nullish) };}
      return !isDefined ? { ...acc } : { ...acc, [key]: v };
    }, {} as NonNullable<T>);
  }

  return value;
}
