import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Nullable } from '@libs/utils';
import { USER_AGENT } from '@ng-web-apis/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class SpPlatform {
  readonly browser: boolean = isPlatformBrowser(this.platformId) && this.cdkPlatform.isBrowser;
  readonly server: boolean = isPlatformServer(this.platformId) && !this.cdkPlatform.isBrowser;
  readonly currentBrowser = this.deviceDetector.browser.toLowerCase();
  readonly currentDeviceType = this.deviceDetector.deviceType.toLowerCase();
  readonly currentOS = this.deviceDetector.os;
  readonly client = this.browser ? import('clientjs').then(client => client) : Promise.resolve(null);

  get fingerprint(): Promise<Nullable<number>> {
    return this.client.then((client: any) => {
      const clientJS = new client.ClientJS();
      return clientJS?.getFingerprint();
    });
  }

  get userPlatformInfo(): string {
    return `${this.currentOS.toLowerCase()}+${this.currentBrowser.toLowerCase()}`;
  }

  readonly type = {
    mobile: this.deviceDetector.isMobile(),
    tablet: this.deviceDetector.isTablet(),
    desktop: this.deviceDetector.isDesktop()
  };

  readonly device = {
    ios: this.deviceDetector.os === 'iOS',
    android: this.deviceDetector.os === 'Android',
    windows: this.deviceDetector.os === 'Windows',
    mac: this.deviceDetector.os === 'Mac'
  };

  readonly environment = {
    isEdge: this.cdkPlatform.EDGE,
    isTrident: this.cdkPlatform.TRIDENT,
    isBlink: this.cdkPlatform.BLINK,
    isWebkit: this.cdkPlatform.WEBKIT,
    isFirefox: this.cdkPlatform.FIREFOX,
    isSafari: this.cdkPlatform.SAFARI
  };

  constructor(
    private readonly cdkPlatform: Platform,
    private readonly deviceDetector: DeviceDetectorService,
    @Inject(USER_AGENT) private readonly agent: string,
    @Inject(PLATFORM_ID) private readonly platformId: string
  ) { this.deviceDetector.setDeviceInfo(this.agent); }
}
