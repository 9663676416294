import { Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';
import { DEPOSIT_FORM } from './default-form-declaration.constant';

export const WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION = {
  ...DEPOSIT_FORM,
  account: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(19), CustomValidators.amount('int')]],
  expM: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2),
    CustomValidators.amount('int'),
    CustomValidators.expM
  ]],
  expY: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2),
    CustomValidators.amount('int'),
    CustomValidators.expY
  ]]
};
