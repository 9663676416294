import { inject, Injectable } from '@angular/core';
import { ConfigQuery } from '@portal/config';
import { createRecaptchaConfig, RecaptchaStore } from '@portal/recaptcha/data';

@Injectable({ providedIn: 'root' })
export class RecaptchaData {
  private readonly config = inject(ConfigQuery);
  private readonly store = inject(RecaptchaStore);

  synchronize(): void {
    const config = createRecaptchaConfig(this.config.modules.recaptcha);
    this.store.update({ config, sync: true });
  }
}
