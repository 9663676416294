import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { SpConnectorConfig } from './config';
import { SpConnector } from './connector';
import { SpCollectorsToken, SpConnectorConfigToken, SpExecutorsToken, SpTransformersToken } from './utils';

@NgModule({ providers: [ SpConnector ] })
export class SpConnectorModule {
  static init(config: SpConnectorConfig): ModuleWithProviders<SpConnectorModule> {
    const collectors: Array<Provider> = config.collectors?.map((collector) => {
      return { provide: SpCollectorsToken, ...collector, multi: true };
    }) || [];

    const executors: Array<Provider> = config.executors?.map((executor) => {
      return { provide: SpExecutorsToken, ...executor, multi: true };
    }) || [];

    const transformers: Array<Provider> = config.transformers?.map((transformer) => {
      return { provide: SpTransformersToken, ...transformer, multi: true };
    }) || [];

    return {
      ngModule: SpConnectorModule,
      providers: [ collectors, executors, transformers, { provide: SpConnectorConfigToken, useValue: config } ]
    };
  }
}
