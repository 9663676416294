import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Bonus } from '@portal/bonuses/data';

export type BonusesState = {
  sync: boolean;
  depBonusActivated: boolean;
  entities: { bonuses: SteroidEntity<Bonus> };
}

const initial: SteroidConfig<BonusesState> = {
  options: { name: 'bonuses' },
  initial: { sync: false, depBonusActivated: false },
  entities: { bonuses: { idKey: 'id' } }
};

@Injectable({ providedIn: 'root' })
export class BonusesStore extends SteroidStore<BonusesState> {
  constructor() {super(initial);}
}

