import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { normalizeFloatAmount, Nullable } from '@libs/utils';
import { PaymentGroupsQuery, PaymentMethod } from '@portal/payment/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gg-payment-commission-warning',
  templateUrl: './commission-warning.component.html',
  styleUrls: [ './commission-warning.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionWarningComponent {
  private readonly groupsQuery = inject(PaymentGroupsQuery);

  readonly method$ = this.groupsQuery.activeMethod$;
  readonly amountWithCommission$ = this.method$.pipe(this.calculateAmount());
  readonly getNowButton: IButton = { size: ButtonSizes.Medium };
  readonly closeButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };

  @Output() closeCommission: EventEmitter<boolean> = new EventEmitter();
  @Input() amount: number = 0;

  closePopup(): void { this.closeCommission.emit(false); }

  paymentConfirmed(): void { this.closeCommission.emit(true);}

  private calculateAmount(): (source$: Observable<Nullable<PaymentMethod>>) => Observable<string> {
    return (source$) => source$.pipe(map((method) => {
      const amountRate = 1 - Number(method?.amounts?.fee?.now?.rate);

      return (normalizeFloatAmount(this.amount) * Number(amountRate)).toFixed(2);
    }));
  }
}
