import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { AmountSteps, PaymentState, PaymentStore } from '@portal/payment/data';

@Injectable({ providedIn: 'root' })
export class PaymentQuery extends SteroidQuery<PaymentState> {
  get amountSteps(): AmountSteps { return this.get('amountSteps'); }

  get defaultAmount(): Nullable<number> { return this.get('defaultAmount'); }

  readonly cards = this.entity('cards');

  constructor(store: PaymentStore) { super(store); }
}
