import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VerificationState } from "@portal/verification/shared";

@Component({
  standalone: true,
  imports: [ SpCDKModule, RouterModule, ControlsModule ],
  templateUrl: './no-contact-warning.component.html',
  styleUrls: [ './no-contact-warning.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoContactWarningComponent {
  private readonly user = inject(UserQuery);
  private readonly source = inject(ConfigQuery);

  readonly verificationInfo$: Observable<Nullable<VerificationState>>;
  readonly theme = this.source.project.name || '';

  constructor() {
    const verificationParams: Array<string> = [ ...this.source.modules.cashier.verificationParams ];

    this.verificationInfo$ = this.user.confirmation$.pipe(
        map(( confirmation) => {
          const count = Object.entries(confirmation).filter(([ key, value ]) => verificationParams.includes(key) && !!value).length;

          return {
            stat: { of: verificationParams.length, count },
            email: { enabled: true, confirmed: confirmation.email },
            phone: { enabled: this.source.modules.forms.enableMobilePhone, confirmed: confirmation.phone },
          };
        }),
        takeUntilDestroyed()
    );
  }
}
