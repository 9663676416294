import {
  BonusCasinoFreebetDto,
  BonusFreebetWithoutBonusAccountsDto,
  SportsbookWagerDto
} from '@portal/bonuses/data';
import { BonusStatus } from '@portal/bonuses/shared';

export function mergeBonusAccountsFromSportsbook(bonusEntries: Array<BonusFreebetWithoutBonusAccountsDto>, sportsbookData: Array<SportsbookWagerDto>): Array<BonusCasinoFreebetDto> {
  const updatedBonusEntries: Array<BonusCasinoFreebetDto> = [];

  bonusEntries.forEach(bonusEntry => {
    const matchingSportsbook = sportsbookData.find(sportsbook => sportsbook.present_id === bonusEntry._id);
    if (matchingSportsbook && matchingSportsbook.status === BonusStatus.Wagging) {
      const bonusCasinoFreebetDto: BonusCasinoFreebetDto = {
        ...bonusEntry,
        bonusAccounts: {
          bonus: matchingSportsbook.bonus,
          scaleExpected: matchingSportsbook.scale_expected,
          scaleCurrent: matchingSportsbook.scale_current
        },
        status: matchingSportsbook.status
      };
      updatedBonusEntries.push(bonusCasinoFreebetDto);
    } else {
      const bonusCasinoFreebetDto: BonusCasinoFreebetDto = {
        ...bonusEntry,
        bonusAccounts: {
          bonus: '0.00',
          scaleExpected: '0.00',
          scaleCurrent: '0.00'
        },
      };
      updatedBonusEntries.push(bonusCasinoFreebetDto);
    }
  });

  return updatedBonusEntries;
}



