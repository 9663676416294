// @ts-nocheck

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LeoPaymentMethods } from '../../../../types/enums/payment-methods/leo-payments-methods.enum';
import { PaymegaPaymentMethods } from '../../../../types/enums/payment-methods/paymega-methods';
import { PaysoftPaymentMethods } from '../../../../types/enums/payment-methods/paysoft-methods.enum';
import { PaymentMethods } from '../../../../types/interfaces/payment-methods.type';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-mastercard-info-block',
  templateUrl: './mastercard-info-block.component.html',
  styleUrls: [ './mastercard-info-block.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MastercardInfoBlockComponent implements OnInit {
  readonly mastercardBlockMethods: Array<PaymentMethods> = [
    PaymegaPaymentMethods.DepositFirst,
    LeoPaymentMethods.Deposit,
    LeoPaymentMethods.MonobankDeposit,
    LeoPaymentMethods.Privat24Deposit,
    PaysoftPaymentMethods.BankCardDeposit,
    PaysoftPaymentMethods.BankCardDepositTrusted
  ];
  showMasterCardBlock: Nullable<boolean>;

  @Input() method: Nullable<number>;

  ngOnInit(): void {
    this.showMasterCardBlock = this.mastercardBlockMethods.includes(this.method);
  }
}
