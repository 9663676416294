import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { EmailConfirmationService } from '@portal/user/features/email-confirmation/email-confirmation.service';

@Component({
  selector: 'gg-user-email-confirmation-confirmed',
  templateUrl: './confirmed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmedComponent {
  private readonly confirmationService = inject(EmailConfirmationService);

  close(): void {
    this.confirmationService.changeViewState('disabled');
  }
}
