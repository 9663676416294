import { Validators } from '@angular/forms';
import { validationRules } from "@portal/shared/constants";

export const WITHDRAWAL_FORM_WITH_OPERATOR_ID = {
  amount: [0, [Validators.required]],
  account: [
    '',
    { nonNullable: true, validators: validationRules.phone }
  ],
  operator_id: [
    '', [Validators.required]
  ]
};
