<ng-template #selectLanguage>
  <section class="specify-user-locale">
    <div class="specify-user-locale__wrapper">
      <img src="assets/images/icons/localisation.svg" class="specify-user-locale__icon-locale" alt=""/>
      <div class="specify-user-locale__title" spMsg="POP_UP.IDENTIFIED_LANGUAGE"></div>
      <gg-dropdown-language-switcher position="below" class="specify-user-locale__language" [default]="browserLanguage" (changed)="close()"/>
      <gg-button class="specify-user-locale__button" [fullWidth]="true" [settings]="proceedButton" (buttonClicked)="close()">
        <sp-msg msg="COMMON.PROCEED"></sp-msg>
      </gg-button>
      <span class="specify-user-locale__button-close" (click)="close(defaultLanguage)">{{continueMessage}}</span>
      <img src="assets/images/icons/cross2.svg" class="specify-user-locale__close" (click)="close()" alt=""/>
    </div>
  </section>
</ng-template>
