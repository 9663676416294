<section class="field field__year">
  <div class="{{ additionalClass }} input placeholder__wrap">
    <input
      type="text"
      class="field__input"
      autocomplete="cc-exp-year"
      [disabled]="disabled"
      [(ngModel)]="value"
      [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value, 'field__input--no-label': noLabelPlaceholder}"
      [placeholder]="defaultPlaceholder"
      [mask]="mask"
      [maxlength]="maxlength"
      #input
    />
    <span class="placeholder__text">{{placeholder}}</span>
  </div>
  <gg-input-error [errors]="errors"></gg-input-error>
</section>
