import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SpStorage } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { StorageKey } from '@portal/shared/types';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-phone-reg',
  templateUrl: './phone-reg-dialog.component.html',
  styleUrls: [ './phone-reg-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneRegDialogComponent {
  private readonly dialogService = inject(DialogRef);
  private readonly storage = inject(SpStorage);
  private readonly source = inject(ConfigQuery);

  readonly inGameButton: IButton = { size: ButtonSizes.Large, theme: ButtonThemes.Green };
  readonly gameUrl: Nullable<string>;
  readonly gameName: Nullable<string>;
  readonly promoLink: string = this.source.modules.authentication.ads.regByPhonePromoUrl;

  @Input() private readonly name: Nullable<string>;

  constructor() {
    if (this.storage.getItem(StorageKey.ReturnPath)) {
      this.gameUrl = this.storage.getItem(StorageKey.ReturnPath);

      // TODO: Change gameName key
      this.gameName = this.storage.getItem(StorageKey.Game);
    }
  }

  clearLocalStorageItems(): void {
    this.storage.removeItem(StorageKey.ReturnPath);
    this.storage.removeItem(StorageKey.Game);
  }

  close(): void {
    this.clearLocalStorageItems();
    this.dialogService.close();
  }
}
