import { NavigationExtras } from '@angular/router';
import { Nullable } from '@libs/utils';

export type ParsedUrl = [ Array<string>, Nullable<NavigationExtras> ];

export const parseUrl = (url: Nullable<string>, fallback?: string): ParsedUrl => {
  if (!url) { return [ [ '' ], {} ]; }

  const path = url.split('/').filter(item => !!item);
  const extra: NavigationExtras = {};

  if (!path.length && !!fallback) {
    path.push(fallback.toLowerCase());
    extra.skipLocationChange = true;
  }

  if (!path.length) { return [ path, null ]; }

  const position = path.length - 1 >= 0 ? path.length - 1 : 0;
  const [ segment, query ] = path[position].split('?');

  if (query) {
    path[path.length - 1] = segment;
    extra.queryParams = query.split('&').reduce((acc, param) => {
      const [ key, value ] = param.split('=');
      return { ...acc, [key]: value };
    }, {});
  }

  return [ path, extra ];
};
