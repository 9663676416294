import { Banner } from '@portal/banners/data';
import { BannerVisibilityFilter } from '@portal/banners/shared';

export const findByVisibility = (banners: Array<Banner>, filter: BannerVisibilityFilter): Array<Banner> => {

  return banners.filter(({ visibility }) => {
    const isNonAuthorizedMatch = !filter.authorized && visibility.showTo !== 'authorized';
    const isAuthorizedMatch = filter.authorized && visibility.showTo !== 'anonymous';
    const isVisible = visibility.showTo !== 'hidden';

    const isSectionMatch = !filter.section || filter.section === visibility.section;
    const isPageMatch = !filter.page || filter.page === visibility.page;

    const isOrderMatch = !filter.orders || !isNaN(Number(visibility.order)) || visibility.order === 'last';

    return isPageMatch && isSectionMatch && isOrderMatch && isVisible && ( isNonAuthorizedMatch || isAuthorizedMatch);
  });
};
