<div class="container">
  <img src="assets/images/cashier/stub/Illustration.svg" class="image" alt="Illustration"/>
  <div class="content">
    <div class="information">
      <div class="title" spMsg="CONFIRM_EMAIL_PAGE.TITLE"></div>
      <div class="description" spMsg="CONFIRM_EMAIL_PAGE.DESCRIPTION"></div>
    </div>
    <gg-button [routerLink]="['/user', 'settings', 'contact-details']" class="confirmation-link">
      <sp-msg style="text-transform: uppercase" spMsg="CONFIRM_EMAIL_PAGE.SETTINGS_BUTTON"></sp-msg>
    </gg-button>
  </div>
</div>
