@if (isSegmentVisible) {
  <div class="breadcrumb">
    @if (withLink) {
      <img class="breadcrumb__arrow" [src]="'{project}/images/breadcrumbs/arrow.svg' | path" alt="breadcrumbs-arrow-icon"/>
      <a class="breadcrumb__segment-link" [routerLink]="link" [spMsg]="title"></a>
    } @else {
      <img class="breadcrumb__arrow" [src]="'{project}/images/breadcrumbs/arrow.svg' | path" alt="breadcrumbs-arrow-icon"/>
      <span class="breadcrumb__segment" [spMsg]="title"></span>
    }
  </div>
}
