import { inject, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { shareReplay } from 'rxjs/operators';
import { AnalyticsHandlerFactory } from './handler.factory';

@Injectable()
export class AnalyticsProcessor {
  private readonly navigation = inject(SpNavigation);
  private readonly factory = inject(AnalyticsHandlerFactory);

  createAnalyticHandlers(): void {
    const endEvent = this.navigation.event(NavigationEnd).pipe(shareReplay({ refCount: true }));

    this.factory.createAnalyticHandlers().forEach((strategy) => {
      strategy.create();
      endEvent.subscribe((event) => strategy.onNavigationEnds(event));
    });
  }
}
