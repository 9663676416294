import { Observable } from 'rxjs';
import { SteroidStore } from './store';
import { Steroid, SteroidEntities, SteroidProps, SteroidState } from './utils';

export class SteroidQuery<S extends SteroidState> {
  private readonly query: Steroid<S>['query'];
  private readonly entities: Steroid<S>['entities'];

  constructor(store: SteroidStore<S>) {
    const { query, entities } = store.steroid;
    this.query = query;
    this.entities = entities;
  }

  select(): Observable<SteroidProps<S>>;
  select<P extends keyof SteroidProps<S>>(p: P): Observable<SteroidProps<S>[P]>;
  select<P extends keyof SteroidProps<S>>(p?: P): Observable<SteroidProps<S> | SteroidProps<S>[P]> {
    return p ? this.query.select(p) : this.query.select();
  }

  get(): SteroidProps<S>;
  get<P extends keyof SteroidProps<S>>(p: P): SteroidProps<S>[P];
  get<P extends keyof SteroidProps<S>>(p?: P): SteroidProps<S> | SteroidProps<S>[P] {
    const value = this.query.getValue();
    return p ? value[p] : value;
  }

  selectLoading(): Observable<boolean> {
    return this.query.selectLoading();
  }

  selectError<T>(): Observable<T> {
    return this.query.selectError<T>();
  }

  protected entity<E extends keyof SteroidEntities<S>>(entity: E): Steroid<S>['entities'][E]['query'] {
    const selected = this.entities[entity].query;
    if (!selected) { throw new Error(`Entity ${entity as string} not found`); }

    return selected;
  }
}
