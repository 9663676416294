import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Article } from '@portal/articles/data';

export type ArticlesState = {
  entities: {
    articles: SteroidEntity<Article>;
  };
  sync: boolean;
}

const initial: SteroidConfig<ArticlesState> = {
  options: { name: 'articles' },
  initial: { sync: false },
  entities: { articles: { idKey: 'slug' } }
}

@Injectable({ providedIn: 'root' })
export class ArticlesStore extends SteroidStore<ArticlesState> {
  constructor() { super(initial); }
}
