// @ts-nocheck

import { Dialog } from '@angular/cdk/dialog';
import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, inject, Inject, OnDestroy, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { SpNavigation, SpNotification, SpPlatform } from '@libs/cdk';
import { GlobalWindow, normalizeFloatAmount, Nullable, tapOnce } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { BonusTitlePipe } from '@portal/bonuses/shared/pipes/bonus-title.pipe';
import { CactusDialogComponent } from '@portal/cashier/components/card/components/cactus-pop-up/cactus-dialog.component';
import { CommissionInfoDialogComponent } from '@portal/cashier/components/card/components/commission-info-dialog/commission-info-dialog.component';
import { CommissionWarningDialogComponent } from '@portal/cashier/components/card/components/commission-warning-dialog/commission-warning-dialog.component';
import { EnterCodeDialogComponent } from '@portal/cashier/components/card/components/enter-code-dialog/enter-code-dialog.component';
import { USER_DATA_BTGATE_BANK_CARD_DEPOSIT } from '@portal/cashier/components/card/constants/user-data-form-btgate-bank-card-deposit.constant';
import { USER_DATA_INFO_ECOMMPAY_SEPA } from '@portal/cashier/components/card/constants/user-data-form-ecommpay-sepa.constant';
import { USER_DATA_INFO_MESSENGER } from '@portal/cashier/components/card/constants/user-data-form-messenger.constant';
import { USER_DATA_INFO_MINFIN_DEPOSIT } from '@portal/cashier/components/card/constants/user-data-form-minfinity-deposit.constant';
import { USER_DATA_INFO__FULL_INFO_BIRTH } from '@portal/cashier/components/card/constants/user-data-form-part-full-info-birth.constant';
import { USER_DATA_INFO_NAME_PART_BIRTH } from '@portal/cashier/components/card/constants/user-data-form-part-name-birth.constant';
import { USER_DATA_PHONE_WITHDRAWAL } from '@portal/cashier/components/card/constants/user-data-form-phone-withdrawal.constant';
import { USER_DATA_FORM_PHONE } from '@portal/cashier/components/card/constants/user-data-form-phone.constant';
import { USER_DATA_PREMIER_PAY } from '@portal/cashier/components/card/constants/user-data-form-premier-pay.constant';
import { CYPIX_DEPOSIT_MOBILE_KZT } from '@portal/cashier/constants/cypix-deposit-mobile-kzt.constant';
import { DEFAULT_PAYMENT_METHOD_VIEW } from '@portal/cashier/constants/default-payment-method-view.constant';
import { WITHDRAWAL_FORM_WITH_BANK_ID } from '@portal/cashier/constants/withdrawal-form-with-bank-id.constant';
import { WITHDRAWAL_NOT_CARD_FORM_WITH_EMAIL } from '@portal/cashier/constants/withdrawal-notcard-with-email-form.constant';
import { WITHDRAWAL_USBX_ACCOUNT_FORM } from '@portal/cashier/constants/withdrawal-usbx-account-form-declaration.constant';
import { Account, BankCardDetails, CashierDataService, CashierQuery, CryptoConversionRate, CryptoCurrency, CryptoWithdrawalPayload, PaymentDataFormStatus, PaymentMethod, PaymentMethodsQuery, UserBankCardQuery, UserPaymentDataForm } from '@portal/cashier/data';
import { CryptoflowData } from '@portal/cashier/data/entities/cryptoflow-data';
import { CreateDeposit } from '@portal/cashier/data/types/create-deposit.type';
import { CreateWithdrawal } from '@portal/cashier/data/types/create-withdrawal.type';
import { AntrpayMethods } from '@portal/cashier/types/enums/payment-methods/antrpay-methods.enum';
import { BigideaPaymentMethods } from '@portal/cashier/types/enums/payment-methods/bigidea-methods.enum';
import { BilllineMethods } from '@portal/cashier/types/enums/payment-methods/billline-methods.enum';
import { BnpayMethods } from '@portal/cashier/types/enums/payment-methods/bnpay-methods.enum';
import { BtGateMethods } from '@portal/cashier/types/enums/payment-methods/bt-gate-methods.enum';
import { CactusPaymentMethods } from '@portal/cashier/types/enums/payment-methods/cactus-methods.enum';
import { CirxusPaymentMethods } from '@portal/cashier/types/enums/payment-methods/cirxus-methods.enum';
import { CrosspayPaymentMethods } from '@portal/cashier/types/enums/payment-methods/crosspay-methods.enum';
import { CryptoFlowPaymentMethods } from '@portal/cashier/types/enums/payment-methods/crypto-flow.enum';
import { CubixpayPaymentMethods } from '@portal/cashier/types/enums/payment-methods/cubixpay-payment-methods';
import { EuphoriaMethods } from '@portal/cashier/types/enums/payment-methods/euphoria-methods.enum';
import { ExpayMethods } from '@portal/cashier/types/enums/payment-methods/expay-methods.enum';
import { EzPaymentMethods } from '@portal/cashier/types/enums/payment-methods/ez-pay.methods';
import { IdmMethods } from '@portal/cashier/types/enums/payment-methods/idm-methods.enum';
import { MajorpayPaymentMethods } from '@portal/cashier/types/enums/payment-methods/majorpay-methods.enum';
import { MaxPayMethods } from '@portal/cashier/types/enums/payment-methods/maxpay-methods.enum';
import { MifinityPaymentMethods } from '@portal/cashier/types/enums/payment-methods/mifinity-methods.enum';
import { OctopayPaymentMethods } from '@portal/cashier/types/enums/payment-methods/octopay-methods.enum';
import { OneVisionMethods } from '@portal/cashier/types/enums/payment-methods/one-vision-methods.enum';
import { OneTnMethods } from '@portal/cashier/types/enums/payment-methods/onetn-methods.enum';
import { OverpayMethods } from '@portal/cashier/types/enums/payment-methods/overpay-methods.enum';
import { PagoMethods } from '@portal/cashier/types/enums/payment-methods/pago-methods.enum';
import { PaylerPaymentMethods } from '@portal/cashier/types/enums/payment-methods/payler-methods';
import { PayportalMethods } from '@portal/cashier/types/enums/payment-methods/payportal-methods.enum';
import { PremierPayMethods } from '@portal/cashier/types/enums/payment-methods/premierpay-methods.enum';
import { SmartGatesMethods } from '@portal/cashier/types/enums/payment-methods/smart-gates-methods.enum';
import { SPayzPaymentMethods } from '@portal/cashier/types/enums/payment-methods/spayz-methods.enum';
import { TurcodePaymentMethods } from '@portal/cashier/types/enums/payment-methods/turcode-methods.enum';
import { TwoSendMethods } from '@portal/cashier/types/enums/payment-methods/two-send-methods.enum';
import { UsbxPaymentMethods } from '@portal/cashier/types/enums/payment-methods/usbx-payment-methods.enum';
import { OctopayInfo } from '@portal/cashier/types/interfaces/octopay-info.interface';
import { ConfigQuery } from '@portal/config';
import { CurrencySymbolPipe } from '@portal/currencies/application';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ICashInputSettings } from '@portal/shared/components/controls/inputs/input-cash/input-cash.component';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { CustomValidators, ErrorManager } from '@portal/shared/helpers';
import { UserInfo, UserQuery } from '@portal/user/data';
import { combineLatest, interval, Observable, of, pairwise, Subscription, throwError, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { CashierService } from '../../cashier.service';
import { ACCOUNT_FIELD_NAME } from '../../constants/account-field-name.constant';
import { AMOUNT_FIELD_NAME } from '../../constants/amount-field-name.constant';
import { DEPOSIT_FORM } from '../../constants/default-form-declaration.constant';
import { WIDGET_PHONE_CODE_FORM } from '../../constants/widget-phone-code-form-declaration.constant';
import { WIDGET_PHONE_FORM } from '../../constants/widget-phone-form-declaration.constant';
import { WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION } from '../../constants/withdrawal-card-account-form-declaration.constant';
import { WITHDRAWAL_FORM } from '../../constants/withdrawal-form-declaration.constant';
import { WITHDRAWAL_FORM_WITH_OPERATOR_ID } from '../../constants/withdrawal-form-with-operator-id.constant';
import { WITHDRAWAL_FORM_FULL_ACCOUNT } from '../../constants/withdrawal-full-account-form-declaration.constant';
import { WITHDRAWAL_NOT_CARD_FORM } from '../../constants/withdrawal-not-card-form-declaration.constant';
import { WITHDRAWAL_NOT_CARD_LENGTH_FORM } from '../../constants/withdrawal-not-card-length-form-declaration.constant';
import { WITHDRAWAL_ONLY_PHONE_FORM } from '../../constants/withdrawal-only-phone-form.constant';
import { WITHDRAWAL_SHORT_PHONE } from '../../constants/withdrawal-short-phone.constant';
import { CashierRequestStatus } from '../../types/enums/cashier-request-status.enum';
import { AccentpayMethods } from '../../types/enums/payment-methods/accentpay-methods.enum';
import { AppexMethods } from '../../types/enums/payment-methods/appex-methods.enum';
import { AstropayPaymentMethods } from '../../types/enums/payment-methods/astropay-methods.enum';
import { BitandpaySofortMethods } from '../../types/enums/payment-methods/bitandpay-sofort.enum';
import { BlackRabbitPaymentMethods } from '../../types/enums/payment-methods/black-rabbit-methods.enum';
import { CryptoProcessingPaymentMethods } from '../../types/enums/payment-methods/crypto-processing.enum';
import { CypixPaymentMethods } from '../../types/enums/payment-methods/cypix-methods.enum';
import { EcommpayPaymentMethods } from '../../types/enums/payment-methods/ecommpay-methods.enum';
import { ExactlyPaymentMethods } from '../../types/enums/payment-methods/exactly-methods.enum';
import { FinchpayMethods } from '../../types/enums/payment-methods/finchpay-methods.enum';
import { ImpayerPaymentMethods } from '../../types/enums/payment-methods/impayer-methods.enum';
import { InterkassaPaymentMethods } from '../../types/enums/payment-methods/interkassa-methods.enum';
import { LeoPaymentMethods } from '../../types/enums/payment-methods/leo-payments-methods.enum';
import { MpcVipMethods } from '../../types/enums/payment-methods/mpc-vip-methods.enum';
import { PaycosMethods } from '../../types/enums/payment-methods/paycos-methods.enum';
import { PayhubPaymentMethods } from '../../types/enums/payment-methods/payhub-methods.enum';
import { PaymegaPaymentMethods } from '../../types/enums/payment-methods/paymega-methods';
import { PaymentCenterPaymentMethods } from '../../types/enums/payment-methods/payment-center-methods.enum';
import { PayopPaymentMethods } from '../../types/enums/payment-methods/payop-methods.enum';
import { PayperPaymentMethods } from '../../types/enums/payment-methods/payper-methods.enum';
import { PaysoftPaymentMethods } from '../../types/enums/payment-methods/paysoft-methods.enum';
import { PiastrixPaymentMethods } from '../../types/enums/payment-methods/piastrix-methods.enum';
import { PraxisMethods } from '../../types/enums/payment-methods/praxis-methods.enum';
import { ProcessingKZPaymentMethods } from '../../types/enums/payment-methods/processing-kz-methods';
import { RoyalpayPaymentMethods } from '../../types/enums/payment-methods/royal-payment-methods.enum';
import { TranzzoPaymentMethods } from '../../types/enums/payment-methods/tranzzo-methods';
import { UkrGasBankPaymentMethods } from '../../types/enums/payment-methods/ukrgasbank-methods.enum';
import { WinpayPaymentMethods } from '../../types/enums/payment-methods/winpay-methods.enum';
import { XpatePaymentMethods } from '../../types/enums/payment-methods/xpate-payment-methods.enum';
import { CashierSections } from '../../types/enums/sections.enum';
import { CRYPTO_USER_INFO } from './constants/crypto-form-declaration.constant';
import { FIXED_ROUND_VALUE_BY_FIVE_HUNDRED, FIXED_ROUND_VALUE_BY_ONE_HUNDRED, FIXED_ROUND_VALUE_BY_ONE_THOUSAND, FIXED_ROUND_VALUE_BY_TEN } from './constants/rounding-steps.constant';
import { SAVE_CARDS_METHODS } from './constants/save-cards-methods.constant';
import { USER_DATA_INFO } from './constants/user-data-form-declaration.constant';
import { USER_DATA_FN_ADDR_CITY_DOC } from './constants/user-data-form-fn-addr-city-doc.constant';
import { USER_DATA_FULL_NAME_PHONE } from './constants/user-data-form-full-name-phone.constant';
import { USER_DATA_INFO_NAME_FULL } from './constants/user-data-form-full-name.constant';
import { USER_DATA_FORM_FULL } from './constants/user-data-form-full.constant';
import { USER_DATA_NAME_PART_ZIPCODE_ADDRESS } from './constants/user-data-form-name-part-zipCode-address.constant';
import { USER_DATA_NAME_PHONE_COUNTRY } from './constants/user-data-form-name-phone-country.constant';
import { USER_DATA_INFO_NAME_PART_COUNTRY } from './constants/user-data-form-part-name-country.constant';
import { USER_DATA_INFO_NAME_PART_PHONE } from './constants/user-data-form-part-name-phone.constant';
import { USER_DATA_INFO_NAME_PART } from './constants/user-data-form-part-name.constant';
import { USER_DATA_FORM_PAYPER_POST_CODE } from './constants/user-data-form-payper-post-code.constant';
import { USER_DATA_ROYALPAY_WITHDRAWAL } from './constants/user-data-form-royalpay-withdrawal.constant';
import { USER_DATA_FORM_MUCHBETTER_PAYPER } from './constants/user-data-form-state.constant';
import { USER_DATA_WINPAY_VISA_WITHDRAWAL } from './constants/user-data-form-winpay-visa-withdrawal.constant';
import { USER_DATA_WINPAY_WITHDRAWAL } from './constants/user-data-form-winpay-withdrawal.constant';

@Component({
  selector: 'pd-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnDestroy {
  @ViewChild('widgetFakeForm', { read: ElementRef, static: false }) widgetFakeForm: Nullable<ElementRef>;

  private readonly authorization = inject(SpAuthorizationService);

  private readonly section: CashierSections;
  private readonly watchers: Subscription = new Subscription();
  private readonly isMobile = inject(SpPlatform).type.mobile;

  readonly originalCopiedMethodId: Nullable<number>;
  readonly errors: ErrorManager;
  readonly errorsCrypto: ErrorManager;
  readonly phoneWidgetForm: FormGroup = this.fb.group(WIDGET_PHONE_FORM);
  readonly phoneCodeWidgetForm: FormGroup = this.fb.group(WIDGET_PHONE_CODE_FORM);
  readonly addCardGray: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Gray };
  readonly depositPhoneWidgetButton: IButton = { size: ButtonSizes.Large };
  readonly depositButton: IButton = {};
  readonly headerLogo: Nullable<string>;
  readonly selectedUserBankCard$: Observable<BankCardDetails> = this.userCardsQuery.selectedCard$;
  private userPaymentDataForm: Nullable<UserPaymentDataForm>;
  private userBankCardId: Nullable<string>;

  activeBonus: Nullable<Bonus>;
  depositBonusInfo: Nullable<string>;
  presetCards: Array<Account>;
  form: FormGroup = this.fb.group({});
  formCrypto: FormGroup = this.fb.group(CRYPTO_USER_INFO);
  commission: Nullable<string>;
  commissionSignApprox: Nullable<string>;
  isShowDynamicCommission: boolean;
  isBonusList: boolean = this.bonusQuery.hasDepositBonuses;
  operators: Array<IInputSelectData>;
  cryptoActive$: Nullable<Observable<CryptoCurrency>>;
  convertCrypto$: Nullable<Observable<string>>;
  cryptoCurrency$: Observable<Array<CryptoCurrency>>;
  cryptoActive: Nullable<CryptoCurrency>;
  userCurrency: Nullable<string>;
  userBalance: Nullable<UserInfo['balance']>;
  userId: Nullable<string>;
  minAmount: Nullable<string>;
  maxAmount: Nullable<string>;
  emailConfirmed: Nullable<boolean>;
  showWidget = false;
  showPDWidget = false;
  widgetPayInfo: Nullable<string>;
  widgetBonusInfo: Nullable<string>;
  depositPayInfo: Nullable<string>;
  depositActiveCard: Nullable<string>;
  showDeleteCardPopup: Nullable<boolean>;
  cardDeleteAccount: Nullable<string>;
  isDisabledDepositButton: Nullable<boolean>;
  pdWidgetParams = null;
  pdWidgetMethod = null;
  pdWidgetAction = null;
  showPDWidgetLoading = false;
  showPhoneWidget = false;
  initiatorID = null;
  showPhoneWidgetCode = false;
  showPaysoftWidget = false;
  paysoftWidgetInfo = null;
  showCrypto = false;
  showCryptoOptions = false;
  showResendMessage = true;
  userForm: Partial<Nullable<UserPaymentDataForm>>;
  paymentUserData$: Observable<Nullable<UserPaymentDataForm>>;
  userCountryCode$: Observable<Nullable<string>>;
  userPhone$: Observable<string>;
  userPaymentCountryCode$: Observable<Nullable<string>>;
  userPaymentNationality$: Observable<Nullable<string>>;
  isUserPaymentDataFormLast = false;
  isPaymentData = false;
  roundingStep: Nullable<number>;
  isAccentPayH2HCardData = false;
  isShowQrcode = false;
  qrCodeLink = null;
  phoneMask: Nullable<string>;
  showLoadingPendingText = false;
  zipCodeMask = '';
  showOctopayDepositBlock = false;
  octopayInfo: Nullable<OctopayInfo>;
  cactusData: Nullable<{ address: string, city: string, messenger: string, username: string }> = null;
  isCardOnBlackList: Nullable<boolean>;
  showCryptoFlow = false;
  showCryptoError = false;
  isWaitingLoading = false;
  cryptoFlowInfo: Nullable<CryptoflowData>;
  firstCheck = true;
  paymentMethod: PaymentMethod;
  showCactusWidget = false;
  amountForWidget = '';
  monetixDepositMethods = [
    EcommpayPaymentMethods.MonetixDeposit,
    EcommpayPaymentMethods.MonetixDepositCardUntrusted,
  ];
  paysoftDepositMethods = [
    PaysoftPaymentMethods.BankCardDepositTrusted,
    PaysoftPaymentMethods.BankCardDeposit,
  ];
  winpayPhoneDepositMethods = [
    WinpayPaymentMethods.SamsungPayDeposit,
    WinpayPaymentMethods.ApplePayDeposit,
    WinpayPaymentMethods.GooglePayDeposit,
  ];
  cypixMobileMethods = [ CypixPaymentMethods.MobileWithdrawal ];
  cypixWithdrawalMethods = [
    CypixPaymentMethods.BankCardWithdrawalRu,
    CypixPaymentMethods.BankCardCISWithdrawal,
  ];
  xpateWithdrawalNewMethods = [
    XpatePaymentMethods.NewBankCardWithdrawal,
    XpatePaymentMethods.NewBankCardWithdrawalKzt,
  ];
  ukrGazBankHostToHostMethods = [
    UkrGasBankPaymentMethods.CardHostToHostDeposit,
    UkrGasBankPaymentMethods.CardHostToHostDepositUntrusted,
  ];
  notBankCardInputs = [
    PiastrixPaymentMethods.WalletWithdrawal,
    EcommpayPaymentMethods.MonetixWithdrawal,
    EcommpayPaymentMethods.SkrillWithdrawal,
    EcommpayPaymentMethods.SkrillDeposit,
    EcommpayPaymentMethods.NetellerWithdrawal,
    EcommpayPaymentMethods.WebmoneyWithdrawal,
    XpatePaymentMethods.BankCardWithdrawalInterac,
    MpcVipMethods.WithdrawalQiwi,
    PraxisMethods.WithdrawalMuchBetter,
    WinpayPaymentMethods.SepaWithdrawal,
    RoyalpayPaymentMethods.QiwiWithdrawal,
    MifinityPaymentMethods.Withdrawal,
    EcommpayPaymentMethods.MonetixNetellerWithdrawal,
    EcommpayPaymentMethods.MonetixSkrillWithdrawal,
    EcommpayPaymentMethods.SepaWithdrawal,
    ImpayerPaymentMethods.SkrillWithdrawal,
  ];
  payhubWithdrawalWithoutTokenMethods = [
    PayhubPaymentMethods.BankCardWithdrawalWithoutToken,
    PayhubPaymentMethods.BankCardWithdrawalWithoutToken2,
  ];
  paymegaDepositMethods = [
    PaymegaPaymentMethods.Deposit,
    PaymegaPaymentMethods.DepositFirst,
    PaymegaPaymentMethods.DepositJoker,
    PaymegaPaymentMethods.DepositApplePay,
    PaymegaPaymentMethods.DepositGPay,
    PaymegaPaymentMethods.DepositGPayJoker,
    PaymegaPaymentMethods.DepositApplePayJoker,
  ];
  royalPayFixedStepMethods = [
    RoyalpayPaymentMethods.BankCardDepositFs,
  ];
  payHubHostToHostMethods = [
    PayhubPaymentMethods.BankCardDepositHostToHost,
    PayhubPaymentMethods.BankCardDepositHostToHostNew,
  ];
  appexWithdrawalMethods = [
    AppexMethods.Withdrawal,
    AppexMethods.WithdrawalKZT,
  ];
  xpateTransferWithdrawalMethods = [
    XpatePaymentMethods.BankCardWithdrawalTransfer,
    XpatePaymentMethods.BankCardWithdrawalTransferEU,
    XpatePaymentMethods.BankCardWithdrawalTransferKzt,
  ];
  cryptoProcessingWithdrawalMethods = [
    CryptoProcessingPaymentMethods.Withdrawal,
    CryptoProcessingPaymentMethods.WithdrawalUsdtt,
    CryptoProcessingPaymentMethods.WithdrawalUsdte,
  ];
  payopDepositMethods = [
    PayopPaymentMethods.Deposit,
    PayopPaymentMethods.DepositPaydo,
  ];
  payopDepositMethodsIndia = [
    PayopPaymentMethods.DepositUPI,
    PayopPaymentMethods.DepositBankingIndia,
  ];
  payperDepositMethods = [
    PayperPaymentMethods.DepositInterac,
    PayperPaymentMethods.DepositBanking,
    PayperPaymentMethods.DepositTransfer,
  ];
  payperWithdrawalMethods = [
    PayperPaymentMethods.WithdrawalTransfer,
    PayperPaymentMethods.WithdrawalBanking,
  ];
  royalPayDepositH2HMethods = [
    RoyalpayPaymentMethods.DepositH2H,
    RoyalpayPaymentMethods.DepositH2HKZT,
  ];
  fixedWithdrawals = [
    RoyalpayPaymentMethods.BankCardWithdrawalNew,
    XpatePaymentMethods.BankCardWithdrawalFixed,
  ];
  blackRabbitDeposits = [
    BlackRabbitPaymentMethods.Deposit,
    BlackRabbitPaymentMethods.DepositWallet,
    BlackRabbitPaymentMethods.DepositP2P,
    BlackRabbitPaymentMethods.DepositH2H,
  ];
  impayerDepositMethods = [
    ImpayerPaymentMethods.Deposit,
    ImpayerPaymentMethods.DepositTrusted,
  ];
  btGatesMethods = [
    BtGateMethods.Deposit,
    BtGateMethods.Withdrawal,
  ];
  btGatesDepositMethods = [
    BtGateMethods.DepositBankCard,
    BtGateMethods.DepositP2P,
  ];
  paycosBankCardWithdrawals = [
    PaycosMethods.WithdrawalMC,
    PaycosMethods.WithdrawalVisa,
  ];
  cryptoFlowDepositMethods = [
    CryptoFlowPaymentMethods.DepositETH,
    CryptoFlowPaymentMethods.DepositBTC,
    CryptoFlowPaymentMethods.DepositLTC,
    CryptoFlowPaymentMethods.DepositUSDTE,
    CryptoFlowPaymentMethods.DepositUSDTT,
  ];
  newEcommpayDepositMethods = [
    EcommpayPaymentMethods.GiropayDeposit,
    EcommpayPaymentMethods.SofortDeposit,
    EcommpayPaymentMethods.RapidTransferDeposit,
    EcommpayPaymentMethods.PaySafeCashDeposit,
    EcommpayPaymentMethods.PaySafeCardDeposit,
  ];
  ecommpayFullNameMethods = [
    EcommpayPaymentMethods.MonetixNetellerDeposit,
    EcommpayPaymentMethods.MonetixNetellerWithdrawal,
    EcommpayPaymentMethods.MonetixSkrillDeposit,
    EcommpayPaymentMethods.MonetixSkrillWithdrawal,
    EcommpayPaymentMethods.BinancePayDeposit,
  ];
  ezPaymentMethods = [
    EzPaymentMethods.Deposit,
    EzPaymentMethods.Withdrawal,
  ];
  mifinityMethods = [
    MifinityPaymentMethods.Deposit,
    MifinityPaymentMethods.DepositDirect,
  ];
  spayzMethods = [
    SPayzPaymentMethods.Deposit,
    SPayzPaymentMethods.Withdrawal,
  ];
  ecommpayBankCardTrustedDeposits = [
    EcommpayPaymentMethods.DepositTrustedVipH2h,
    EcommpayPaymentMethods.DepositTrustedKztH2h,
    EcommpayPaymentMethods.DepositTrustedH2h,
  ];
  impayerRubDeposits = [
    ImpayerPaymentMethods.BankCardDepositRUBTrust,
    ImpayerPaymentMethods.BankCardDepositRUB,
  ];
  impayerBankCardWithdrawal = [
    ImpayerPaymentMethods.BankCardDepositRUBWithdrawal,
    ImpayerPaymentMethods.BankCardWithdrawalEUR,
  ];
  xpateH2hDepositMethods = [
    XpatePaymentMethods.BankCardDepositH2hKzt,
    XpatePaymentMethods.BankCardDepositH2h,
  ];
  cryptoOneTnMethodsDeposit = [
    OneTnMethods.DepositBTC,
    OneTnMethods.DepositETH,
    OneTnMethods.DepositUSDTE,
    OneTnMethods.DepositUSDTT,
    OneTnMethods.DepositTest,
    OneTnMethods.DepositLTC,
  ];
  cryptoOneTnMethodsWithdrawal = [
    OneTnMethods.WithdrawalTest,
    OneTnMethods.WithdrawalUSDTE,
    OneTnMethods.WithdrawalUSDTT,
  ];
  cypixDepositMethods = [
    CypixPaymentMethods.BankCardDepositKztH2H,
    CypixPaymentMethods.BankCardDepositH2H,
  ];

  bigideaInteracMethods = [
    BigideaPaymentMethods.InteracDeposit,
  ];

  ecommpayInteracMethods = [
    EcommpayPaymentMethods.InteracWithdrawal,
    EcommpayPaymentMethods.InteracDeposit,
  ];

  apexH2HDepositMethods = [
    AppexMethods.DepositH2HKtz,
    AppexMethods.DepositH2HRub,
  ];

  idmWithdrawalMethods = [
    IdmMethods.SbpWithdraw,
    IdmMethods.BankCardWithdraw,
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly cashierService: CashierService,
    private readonly bonusCommand: BonusesData,
    private readonly bonusQuery: BonusesQuery,
    private readonly bonusTitlePipe: BonusTitlePipe,
    private readonly notify: SpNotification,
    private readonly errorManager: ErrorManager,
    private readonly cd: ChangeDetectorRef,
    private readonly viewportScroller: ViewportScroller,
    private readonly userCardsQuery: UserBankCardQuery,
    private readonly userInfoQuery: UserQuery,
    private readonly navigationService: SpNavigation,
    private readonly dialogService: Dialog,
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly cashierQuery: CashierQuery,
    private readonly source: ConfigQuery,
    @Inject(WINDOW) private readonly window: GlobalWindow<{ Tranzzo: any; dataLayer: Array<unknown>; }>,
    @Inject(PLATFORM_ID) private readonly platform: string,
  ) {
    this.window.dataLayer = [];
    this.paymentMethod = this.paymentMethodsQuery.selectedMethod;

    if (this.paymentMethod && this.paymentMethod.is_copy) {
      this.originalCopiedMethodId = this.paymentMethod.id;

      this.paymentMethod = {
        ...this.paymentMethod,
        id: this.paymentMethod.parent_id,
      };
    }

    const selectedUserBankCard: Subscription = this.selectedUserBankCard$.subscribe((card) => this.userBankCardId = card?.id);
    this.watchers.add(selectedUserBankCard);

    this.headerLogo = this.paymentMethod.logo_secondary || DEFAULT_PAYMENT_METHOD_VIEW.secondaryLogo;

    this.section = this.activatedRoute.snapshot.params.type;

    this.presetCards = this.activatedRoute.snapshot.data.cards;

    this.isShowDynamicCommission = this.cashierService.isShowDynamicCommission(this.paymentMethod.fee);

    this.form = this.getFormConfig();

    this.userCountryCode$ = this.userInfoQuery.country$;
    this.userPaymentCountryCode$ = this.userInfoQuery.country$;
    this.userPaymentNationality$ = this.userInfoQuery.paymentDataNationality$;
    this.paymentUserData$ = this.userInfoQuery.paymentData$;
    this.userPhone$ = this.userInfoQuery.phone$;

    if (this.isCactusPaymentDepositMethod || this.isCactusPaymentWithdrawalMethod) {
      this.showCactusWidget = true;
      this.form = this.fb.group(USER_DATA_INFO_MESSENGER);
    }

    if (this.isCypixMobileDepositKZT) {
      this.form = this.fb.group(CYPIX_DEPOSIT_MOBILE_KZT);

      const mobileOperatorsSub = this.cashierCommands.loadMobileOperators(this.paymentMethod.id).subscribe(
        (mobileOperators) => {
          !!mobileOperators && this.form.controls[ 'operator' ].setValue(mobileOperators[ 0 ].code);

          this.operators = mobileOperators.map((operator) => {
            const iconName = operator.code.split('_')[ 1 ];
            return {
              icon: `./assets/images/cashier/operators/${iconName}.svg`,
              description: operator.name,
              value: operator.code,
            };
          });

          this.detectChanges();
        });

      this.watchers.add(mobileOperatorsSub);
    }

    if (
      this.isPayopDeposit || this.isPayopWithdrawal ||
      this.isPaycosWithdrawal || this.isFinchPayWithdrawal ||
      this.isFinchpayBillineDepositMethod || this.isEcommpayFullNameMethods ||
      this.isEcommpayDeposit || this.isEzPaymentMethods ||
      this.isPaycosDFpayWithdrawal || this.isSbpQrDeposit ||
      this.isBilllineMethods || this.isEcommpayOpenBankingEUDeposit ||
      this.isExactlyDepositH2H || this.isEcommpayBankCardTrustedDeposits ||
      this.isXpateH2hDepositMethods ||
      this.isOctopayH2HDeposit || this.isEcommpayOpenBankingEUDeposit ||
      this.isImpayerBancCardEURDeposit || this.isImpayerBancCardUSDDeposit ||
      this.isImpayerBancCardEURWithdrawal || this.isImpayerBancCardRubDeposit ||
      this.isCypixDepositMethods || this.isPaycosH2HDepositMethod ||
      this.isEzPaymentH2HDepositMethod || this.isPaylerMethodWithdrawal ||
      this.isPaymentCenterH2HDepositMethod || this.isCirxusBankCardH2H ||
      this.isOneVisionDepositMethod || this.isOneVisionWithdrawalMethod ||
      this.isCrosspayDepositH2H || this.isEcommpayMobileDeposit || this.isCypixSbpWithdrawal ||
      this.isTwoSendH2HWithdrawal || this.isTwoSendSbpWithdrawal || this.isPaycosWithdrawalRub ||
      this.isPaymentCenterP2PWithdrawal || this.isAntrpayDepositH2H || this.isCactusSbpWithdrawalMethod ||
      this.isEcommpayMobileWithdrawal || this.isOverpayWithdrawal || this.isWinpayBankCardDeposit ||
      this.isExpayWithdrawalP2P || this.isWinpayDepositH2H || this.isWinpayDepositH2H ||
      this.isPaylerMethodDepositH2H || this.isIdmWithdrawal || this.isMaxPayH2HWithdrawal ||
      this.isMaxPayH2HDeposit || this.isBankCardWithdrawalWorldKZT || this.isTurcodeSBPWithdrawal || this.isBTGateDepositKzt ||
      this.isExpaySbpWithdrawal || this.isBNPAYWithdrawal || this.isBNPAYWithdrawalSBP
    ) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
    }

    if (this.isCryptoFlowDepositMethods || this.isCryptoOneTnMethodsDeposit) {
      this.isWaitingLoading = true;
      this.cashierCommands
        .loadCryptoMethodInfo(this.paymentMethod.id)
        .subscribe((response: CryptoflowData) => {
          this.isWaitingLoading = false;

          if (!response.account) {
            this.showCryptoError = true;
            this.cd.detectChanges();
            return;
          }

          this.showCryptoFlow = true;
          this.cryptoFlowInfo = response;

          if (this.isCryptoOneTnMethodsDeposit) {
            this.cashierCommands
              .loadCryptoMethodInfo(this.paymentMethod.id, response.cryptoCurrency)
              .subscribe();
          }

          this.cd.detectChanges();
        });
    }

    if (this.isInterKassaDepositNova || this.isEcommpayInteracMethods || this.isBigideaInteracMethods || this.isApexH2HDeposit) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
    }

    if (this.isEcommpayNewDepositMethods || this.isXpateH2hDepositMethods) {
      this.openUserDataForm(USER_DATA_INFO);
    }

    if (this.isImpayerBankCardDepositRub) {
      this.roundingStep = FIXED_ROUND_VALUE_BY_TEN;
    }

    if (this.isEcommpaySepaWithdrawal) {
      this.openUserDataForm(USER_DATA_INFO_ECOMMPAY_SEPA);
      this.form = this.fb.group(WITHDRAWAL_NOT_CARD_LENGTH_FORM);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isBtGateBankCardDepositMethod) {
      this.openUserDataForm(USER_DATA_BTGATE_BANK_CARD_DEPOSIT);
    }

    if (this.isPaycosBankCardWithdrawals) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_BIRTH);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
    }

    if (this.isOctopayDeposit || this.isUsbxDepositUntrusted) {
      this.form = this.fb.group(WITHDRAWAL_FORM);
    }

    if (this.isMifinityMethods) {
      this.openUserDataForm(USER_DATA_INFO_MINFIN_DEPOSIT);
      this.form = this.fb.group(WITHDRAWAL_NOT_CARD_FORM_WITH_EMAIL);
    }

    if (this.isPremierPayDeposit || this.isPremierPayWithdrawal) {
      this.openUserDataForm(USER_DATA_PREMIER_PAY);
      this.zipCodeMask = 'S0S 0S0';
    }

    if (this.isPremierPayWithdrawal) {
      const premierPayUserDataForm = { ...USER_DATA_PREMIER_PAY };
      delete premierPayUserDataForm.phone;
      this.openUserDataForm(premierPayUserDataForm);
    }

    if (this.isFinchpayBillineDepositMethod) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_COUNTRY);
    }

    if (this.isImpayerDeposit || this.isImpayerWithdrawal) {
      this.roundingStep = FIXED_ROUND_VALUE_BY_TEN;
      this.openUserDataForm(USER_DATA_INFO);
    }

    if (this.isPayopDepositIndia) {
      this.openUserDataForm(USER_DATA_FN_ADDR_CITY_DOC);
    }

    if (this.isRoyalPayDepositH2H || this.isSpayzMethods || this.isPayhubH2HDepositMethods || this.isBlackRabbitDepositH2H) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
    }

    if (this.isPayhubWithdrawalWithoutTokenMethod || this.isRoyalPayWithdrawal) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
    }

    if (this.isPraxisDepositMuchBetter || this.isPayperWithdrawal || this.isBilllineBankCardDepositMethods) {
      this.openUserDataForm(USER_DATA_FORM_MUCHBETTER_PAYPER);
    }

    if (this.isPayperWithdrawal || this.isPremierPayWithdrawal || this.isBilllineMobileWithdrawal) {
      this.form = this.fb.group(WITHDRAWAL_ONLY_PHONE_FORM);
    }

    if (this.isPayperDeposit) {
      this.openUserDataForm(USER_DATA_FORM_PAYPER_POST_CODE);
      this.phoneMask = '(000) 000-0000';
      this.zipCodeMask = 'S0S 0S0';
    }

    if (this.isCubixpayDeposit) {
      this.openUserDataForm(USER_DATA_INFO__FULL_INFO_BIRTH);
    }

    if (this.isLeoBankCardDeposit) {
      this.form.addControl(ACCOUNT_FIELD_NAME, new FormControl('', [ Validators.required ]));
    } else if (this.isWinPayPhoneDepositMethod || this.isPayhubDepositHostToHost) {
      this.form.addControl(ACCOUNT_FIELD_NAME, new FormControl('', [ Validators.required, Validators.minLength(5), CustomValidators.phone ]));
    }

    if (this.isPresetCardsListActive && !this.isPresetCardsListFilled) {
      this.form.get(AMOUNT_FIELD_NAME)?.setErrors({ required: true });
      this.commission = this.countCommission();
    }

    if (!!this.presetCards && !!this.presetCards.length) {
      this.setActivePresetCard(this.presetCards[ 0 ]);
    }

    this.form.get(AMOUNT_FIELD_NAME)?.valueChanges.pipe(
      takeUntilDestroyed(),
    ).subscribe((value) => {
      if (this.activeBonus && Number(this.activeBonus.activation.price) > normalizeFloatAmount(value)) {
        this.bonusCommand.unselectBonus();
      }

      this.depositPayInfo = `${(Number(value))} ${this.userCurrency}`;
      this.commission = this.countCommission();
    });

    this.commission = this.countCommission();

    if (this.isCryptoProcessingWithdrawal || this.isCryptoOneTnMethodsWithdrawalWithDropScroll || this.isCryptoOneTnMethodsWithdrawal) {
      this.showCrypto = true;

      this.formCrypto.get(AMOUNT_FIELD_NAME)?.setValue(this.getDefaultAmount());

      this.cryptoActive$ = this.cashierQuery.activeCryptoCurrency$;
      this.cryptoActive = this.cashierQuery.activeCryptoCurrency;
      this.cryptoCurrency$ = this.cashierQuery.cryptoCurrency$;
      this.convertCrypto$ = this.cashierQuery.conversionRate$;
    }

    if (this.isCypixWithdrawals || this.isCypixWithdrawalRuFixed || this.isEcommpaySbpWithdrawal || this.isCypixSbpWithdrawal) {
      this.openUserDataForm(USER_DATA_INFO_NAME_FULL);

      if (this.isCypixWithdrawalRuFixed) {
        this.roundingStep = FIXED_ROUND_VALUE_BY_FIVE_HUNDRED;
      }
    }

    if (this.isCirxusWithdrawal) {
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
    }

    if (this.exactlyWithdrawalUpdatedMethod) {
      this.openUserDataForm(USER_DATA_INFO);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
    }

    if (this.isEcommpayWithdrawalFixedAmount || this.isAppexDepositUntrustedMethod || this.isWithdrawalsFixed) {
      this.roundingStep = FIXED_ROUND_VALUE_BY_ONE_HUNDRED;
    }

    if (this.isImpayerDepositFixedAmount) {
      this.roundingStep = FIXED_ROUND_VALUE_BY_ONE_THOUSAND;
    }

    if (this.isBitandpaySofortDeposit || this.isBtGateMethods) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_COUNTRY);
    }

    if (this.isBtGateWithdrawalMethod) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
    }

    if (this.isWithdrawalsFixed) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isRoyalPayFixedStepMethod || this.isRoyalpayFixedMethod) {
      this.roundingStep = FIXED_ROUND_VALUE_BY_FIVE_HUNDRED;
    }

    if (this.isWinPayWithdrawalMethod || this.isXpateWithdrawalNewMethod) {
      this.openUserDataForm(USER_DATA_WINPAY_WITHDRAWAL);
    }

    if (this.isWinPayVisaWithdrawalMethod) {
      this.openUserDataForm(USER_DATA_WINPAY_VISA_WITHDRAWAL);
    }

    if (this.isAppexWithdrawalMethod) {
      this.openUserDataForm(USER_DATA_FORM_FULL);
    }

    if (this.isAstropayWithdrawal || this.isAstropayDeposit) {
      this.openUserDataForm(USER_DATA_NAME_PHONE_COUNTRY);
    }

    if (this.isXpateTransferWithdrawal) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
      this.form = this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isXpateWithdrawalInterac) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
      this.form = this.fb.group(WITHDRAWAL_SHORT_PHONE);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isWinpaySepaWithdrawal) {
      this.openUserDataForm(USER_DATA_FULL_NAME_PHONE);
      this.form = this.fb.group(WITHDRAWAL_NOT_CARD_LENGTH_FORM);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isEcommpayWithoutTokenWithdrawal) {
      this.openUserDataForm(USER_DATA_NAME_PART_ZIPCODE_ADDRESS);
    }

    if (this.isRoyalPayWithdrawalSBP || this.isEcommpaySbpWithdrawal || this.isPaycosSBPWithdrawalMethod || this.isPaymentCenterSbpWithdrawal || this.isCypixSbpWithdrawal || this.isTwoSendSbpWithdrawal || this.isAntrpayWithdrawalSBP || this.isCactusSbpWithdrawalMethod || this.isEuphoriaSBPWithdraw
        || this.isMaxPayWithdrawalH2h || this.isBlackRabbitWithdrawalSBP || this.isPagoWithdrawalSbp || this.isMajorpaySbpWithdrawal || this.isExpaySbpWithdrawal || this.isTurcodeSBPWithdrawal || this.isBNPAYWithdrawalSBP) {
      this.form = this.fb.group(USER_DATA_ROYALPAY_WITHDRAWAL);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isBigideaInteracWithdrawal) {
      this.form = this.fb.group(USER_DATA_PHONE_WITHDRAWAL);
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isMaxPayDepositH2h) {
      this.form = this.fb.group(WITHDRAWAL_FORM_WITH_BANK_ID);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isEcommpayMobileWithdrawal) {
      this.form = this.fb.group(WITHDRAWAL_FORM_WITH_OPERATOR_ID);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isOverpayDepositH2H) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
    }

    if (this.isDepositPage) {
      this.bonusQuery.active$.pipe(
        tapOnce(() => this.getInitialData()),
        startWith(null),
        pairwise(),
      ).subscribe(([ activeBonusPrev, activeBonusCurr ]) => {
        this.activeBonus = activeBonusCurr;

        if (!activeBonusCurr) {
          this.depositBonusInfo = 'CASHIER.BONUSES.NO_SELECTED';
        } else {
          this.depositBonusInfo = this.bonusTitlePipe.transform(this.activeBonus);
        }

        this.setAmountAndCommission();
      });

    } else { this.getInitialData(); }

    if (this.isUsbxWithdrawal) {
      this.openUserDataForm(USER_DATA_FORM_PHONE);
      this.form = this.fb.group(WITHDRAWAL_USBX_ACCOUNT_FORM);
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isWithdrawPage) {
      this.form.get('amount')?.setValue(this.paymentMethod.min_value);
    }

    if (this.isApexSbpQr || this.isCardayportalBankCardDepositCis) {
      this.openUserDataForm(USER_DATA_FORM_PHONE);
    }

    // keep definition of the errors variable on the bottom of the constructor after all form manipulations
    this.errors = this.errorManager.setUp({ form: this.form });
    this.errorsCrypto = this.errorManager.setUp({ form: this.formCrypto });
  }

  get isOverpayDepositH2H(): boolean { return this.paymentMethod.id === OverpayMethods.BankCardDepositH2H; }

  get isOverpayWithdrawal(): boolean { return this.paymentMethod.id === OverpayMethods.Withdrawal; }

  get isPayPortalBankCardDepositH2H(): boolean { return this.paymentMethod.id === PayportalMethods.BankCardDepositH2H; }

  get isCardayportalBankCardDepositCis(): boolean { return this.paymentMethod.id === PayportalMethods.CardpayportalBankCardDepositCis; }

  get isXpateH2hDepositMethods(): boolean { return this.xpateH2hDepositMethods.includes(this.paymentMethod.id); }

  get isInterKassaDepositNova(): boolean { return this.paymentMethod.id === InterkassaPaymentMethods.DepositNova; }

  get isImpayerBancCardEURDeposit(): boolean { return this.paymentMethod.id === ImpayerPaymentMethods.BankCardDepositEUR; }

  get isImpayerBancCardUSDDeposit(): boolean { return this.paymentMethod.id === ImpayerPaymentMethods.BankCardDepositUSD; }

  get isImpayerBankCardDepositRub(): boolean { return this.paymentMethod.id === ImpayerPaymentMethods.BankCardDepositRUB; }

  get isImpayerBancCardRubDeposit(): boolean { return this.impayerRubDeposits.includes(this.paymentMethod.id); }

  get isImpayerDepositFixedAmount(): boolean { return this.paymentMethod.id === ImpayerPaymentMethods.BankCardDepositRUBTrust; }

  get isImpayerBancCardEURWithdrawal(): boolean { return this.impayerBankCardWithdrawal.includes(this.paymentMethod.id); }

  get isEcommpayOpenBankingEUDeposit(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.OpenBankingEUDeposit; }

  get isSpayzMethods(): boolean { return this.spayzMethods.includes(this.paymentMethod.id); }

  get isSbpQrDeposit(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.SbpQrDeposit; }

  get isEcommpayFullNameMethods(): boolean { return this.ecommpayFullNameMethods.includes(this.paymentMethod.id); }

  get isEcommpayNewDepositMethods(): boolean { return this.newEcommpayDepositMethods.includes(this.paymentMethod.id); }

  get isEcommpaySepaWithdrawal(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.SepaWithdrawal; }

  get isBtGateBankCardDepositMethod(): boolean { return this.btGatesDepositMethods.includes(this.paymentMethod.id); }

  get isBtGateP2PDepositMethod(): boolean { return this.paymentMethod.id === BtGateMethods.DepositP2P; }

  get isBtGateMethods(): boolean { return this.btGatesMethods.includes(this.paymentMethod.id); }

  get isBtGateWithdrawalMethod(): boolean { return this.paymentMethod.id === BtGateMethods.Withdrawal; }

  get isPaycosDFpayWithdrawal(): boolean { return this.paymentMethod.id === PaycosMethods.DFPayWithdrawalUA; }

  get isEzPaymentMethods(): boolean { return this.ezPaymentMethods.includes(this.paymentMethod.id); }

  get isCypixMobileDepositKZT(): boolean { return this.paymentMethod.id === CypixPaymentMethods.MobileDepositKZT; }

  get isMifinityMethods(): boolean {return this.mifinityMethods.includes(this.paymentMethod.id); }

  get isPremierPayDeposit(): boolean { return this.paymentMethod.id === PremierPayMethods.Deposit; }

  get isCryptoFlowDepositMethods(): boolean { return this.cryptoFlowDepositMethods.includes(this.paymentMethod.id); }

  get isPremierPayWithdrawal(): boolean { return this.paymentMethod.id === PremierPayMethods.Withdrawal; }

  get isOctopayDeposit(): boolean { return this.paymentMethod.id === OctopayPaymentMethods.Deposit; }

  get isImpayerDeposit(): boolean { return this.impayerDepositMethods.includes(this.paymentMethod.id); }

  get isImpayerWithdrawal(): boolean { return this.paymentMethod.id === ImpayerPaymentMethods.Withdrawal; }

  get isWinpaySepaWithdrawal(): boolean { return this.paymentMethod.id === WinpayPaymentMethods.SepaWithdrawal; }

  get isFinchPayWithdrawal(): boolean { return this.paymentMethod.id === FinchpayMethods.Withdrawal; }

  get isRoyalPayWithdrawal(): boolean { return this.paymentMethod.id === RoyalpayPaymentMethods.BankCardWithdrawal; }

  get isRoyalPayDepositH2H(): boolean { return this.royalPayDepositH2HMethods.includes(this.paymentMethod.id); }

  get isPayperDeposit(): boolean { return this.payperDepositMethods.includes(this.paymentMethod.id); }

  get isPayperWithdrawal(): boolean { return this.payperWithdrawalMethods.includes(this.paymentMethod.id); }

  get isAstropayDeposit(): boolean { return this.paymentMethod.id === AstropayPaymentMethods.BankCardDeposit; }

  get isAstropayWithdrawal(): boolean { return this.paymentMethod.id === AstropayPaymentMethods.BankCardWithdrawal; }

  get isXpateTransferWithdrawal(): boolean { return this.xpateTransferWithdrawalMethods.includes(this.paymentMethod.id); }

  get isXpateWithdrawalInterac(): boolean { return this.paymentMethod.id === XpatePaymentMethods.BankCardWithdrawalInterac; }

  get isExactlyMastercardDeposit(): boolean { return this.paymentMethod.id === ExactlyPaymentMethods.DepositMasterCard; }

  get isPraxisDepositMuchBetter(): boolean { return this.paymentMethod.id === PraxisMethods.DepositMuchBetter; }

  get isPraxisWithdrawalMuchBetter(): boolean { return this.paymentMethod.id === PraxisMethods.WithdrawalMuchBetter; }

  get isNotBankCardMethod(): boolean { return this.notBankCardInputs.includes(this.paymentMethod.id); }

  get isSaveCardMethod(): boolean { return SAVE_CARDS_METHODS.includes(this.paymentMethod.id); }

  get isXpateWithdrawalNewMethod(): boolean { return this.xpateWithdrawalNewMethods.includes(this.paymentMethod.id); }

  get isCryptoProcessingWithdrawal(): boolean { return this.cryptoProcessingWithdrawalMethods.includes(this.paymentMethod.id); }

  get isDepositPage(): boolean { return this.section === CashierSections.Deposit; }

  get isWithdrawPage(): boolean { return this.section === (CashierSections.Withdrawal || CashierSections.Withdraw); }

  get isPresetCardsListActive(): boolean { return this.isWithdrawPage && !!this.presetCards; }

  get isPresetCardsListFilled(): boolean { return this.isPresetCardsListActive && !!this.presetCards.length; }

  get isProcessingKZWithdrawal(): boolean { return this.paymentMethod.id === ProcessingKZPaymentMethods.Withdrawal; }

  get isLeoBankCardDeposit(): boolean { return this.paymentMethod.id === LeoPaymentMethods.DepositCard; }

  get isPayopDeposit(): boolean { return this.payopDepositMethods.includes(this.paymentMethod.id); }

  get isPayopDepositIndia(): boolean { return this.payopDepositMethodsIndia.includes(this.paymentMethod.id); }

  get isPayopWithdrawal(): boolean { return this.paymentMethod.id === PayopPaymentMethods.Withdrawal; }

  get isPaycosWithdrawal(): boolean { return this.paymentMethod.id === PaycosMethods.Withdrawal; }

  get isWinPayPhoneDepositMethod(): boolean { return this.winpayPhoneDepositMethods.includes(this.paymentMethod.id); }

  get isWinPayWithdrawalMethod(): boolean { return this.paymentMethod.id === WinpayPaymentMethods.BankCardWithdrawal; }

  get isWinPayVisaWithdrawalMethod(): boolean { return this.paymentMethod.id === WinpayPaymentMethods.BankCardVisaWithdrawal; }

  get isPayhubWithdrawalMethod(): boolean { return this.paymentMethod.id === PayhubPaymentMethods.BankCardWithdrawal; }

  get isPayhubWithdrawalWithoutTokenMethod(): boolean { return this.payhubWithdrawalWithoutTokenMethods.includes(this.paymentMethod.id); }

  get isShowMonetixRegistrationInfoBlock(): boolean { return this.monetixDepositMethods.includes(this.paymentMethod.id); }

  get isCypixMobileMethod(): boolean { return this.cypixMobileMethods.includes(this.paymentMethod.id); }

  get depositPresetCardList(): Array<string> { return this.presetCards.map(card => card.account); }

  get isCryptoOneTnMethodsWithdrawalWithDropScroll(): boolean { return this.paymentMethod.id === OneTnMethods.Withdrawal; }

  get isCryptoOneTnMethodsWithdrawal(): boolean { return this.cryptoOneTnMethodsWithdrawal.includes(this.paymentMethod.id); }

  get isPaycosH2HDepositMethod(): boolean { return this.paymentMethod.id === PaycosMethods.BankCardH2HDeposit; }

  get isPaycosSBPWithdrawalMethod(): boolean { return this.paymentMethod.id === PaycosMethods.BankCardH2HWithdrawal; }

  get isAdditionalInfoInputActive(): boolean {
    return this.isWithdrawPage && !this.isPresetCardsListActive && !this.isProcessingKZWithdrawal;
  }

  get isCypixDepositMethods(): boolean { return this.cypixDepositMethods.includes(this.paymentMethod.id); }

  get isEcommpayInteracMethods(): boolean { return this.ecommpayInteracMethods.includes(this.paymentMethod.id); }

  get isBigideaInteracMethods(): boolean { return this.bigideaInteracMethods.includes(this.paymentMethod.id); }

  get isPaymentCenterH2HDepositMethod(): boolean { return this.paymentMethod.id === PaymentCenterPaymentMethods.BankCardDepositH2h; }

  get isPayhubH2HDepositMethods(): boolean { return this.paymentMethod.id === PayhubPaymentMethods.BankCardDepositH2H; }

  get isApexH2HDeposit(): boolean { return this.apexH2HDepositMethods.includes(this.paymentMethod.id); }

  get isCactusPaymentDepositMethod(): boolean { return this.paymentMethod.id === CactusPaymentMethods.cashDepositH2H; }

  get isCactusPaymentWithdrawalMethod(): boolean { return this.paymentMethod.id === CactusPaymentMethods.cashWithdrawalH2H; }

  get isCactusSbpWithdrawalMethod(): boolean { return this.paymentMethod.id === CactusPaymentMethods.sbpWithdrawal; }

  get isPaycosWithdrawalRub(): boolean { return this.paymentMethod.id === PaycosMethods.BankCardWithdrawalRub; }

  get isBilllineMobileWithdrawal(): boolean { return this.paymentMethod.id === BilllineMethods.MobileWithdrawal; }

  get isEcommpayMobileWithdrawal(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.MobileWithdrawal; }

  get isEuphoriaP2PDeposit(): boolean { return this.paymentMethod.id === EuphoriaMethods.P2PDeposit; }

  get isEuphoriaBankCardDeposit(): boolean { return this.paymentMethod.id === EuphoriaMethods.BankCardDeposit; }

  get isEuphoriaSBPWithdraw(): boolean { return this.paymentMethod.id === EuphoriaMethods.SBPWithdraw; }

  get isEuphoriaBankCardWithdraw(): boolean { return this.paymentMethod.id === EuphoriaMethods.BankCardWithdraw; }

  get isWinpayBankCardDeposit(): boolean { return this.paymentMethod.id === WinpayPaymentMethods.BankCardDeposit; }

  get isBigideaInteracWithdrawal(): boolean { return this.paymentMethod.id === BigideaPaymentMethods.InteracWithdrawal; }

  get isIdmWithdrawal(): boolean { return this.idmWithdrawalMethods.includes(this.paymentMethod.id); }

  get isMaxPayH2HDeposit(): boolean { return this.paymentMethod.id === MaxPayMethods.DepositBankCardH2H; }

  get isMaxPayH2HWithdrawal(): boolean { return this.paymentMethod.id === MaxPayMethods.WithdrawalBankCardH2H; }

  get isTurcodeBankCardWithdrawal(): boolean { return this.paymentMethod.id === TurcodePaymentMethods.TurcodeBankCardWithdrawal; }

  get cashInputSettings(): ICashInputSettings {
    return {
      min: this.paymentMethod.min_value,
      max: this.maxPayAmount,
      steps: this.cashierService.findStepsByCurrency(this.userCurrency),
      currencySymbol: new CurrencySymbolPipe().transform(this.userCurrency),
      roundingStep: this.roundingStep,
      isPlusCommission: this.isWithdrawalsFixed,
      rate: Number(this.paymentMethod.fee.now.rate),
      isWithdraw: this.isWithdrawPage,
    };
  }

  get maxPayAmount(): string | number {
    if (!this.isWithdrawPage || Number(this.paymentMethodsQuery.defaultAmount) > Number(this.paymentMethod.max_value)) {
      return this.paymentMethod.max_value;
    }

    if (!this.isWithdrawPage || Number(this.paymentMethod.min_value) > Number(this.userBalance?.balance)) {
      return this.paymentMethod.max_value;
    }

    return Number(this.userBalance?.balance) > Number(this.paymentMethod.max_value)
      ? this.paymentMethod.max_value
      : Number(this.userBalance?.balance);
  }

  get callToAction(): string { return this.isDepositPage ? 'CASHIER.CARD.MAKE_DEPOSIT' : 'CASHIER.CARD.MAKE_WITHDRAW'; }

  get showSubmit(): boolean { return !this.isPresetCardsListActive || this.isPresetCardsListActive && this.isPresetCardsListFilled; }

  get isCypixWithdrawals(): boolean { return this.cypixWithdrawalMethods.includes(this.paymentMethod.id); }

  get isCypixWithdrawalRuFixed(): boolean { return this.paymentMethod.id === CypixPaymentMethods.BankCardWithdrawalRuFixed; }

  get isEcommpayWithdrawalFixedAmount(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.BankCardWithdrawalFixedAmount; }

  get isCryptoOneTnMethodsDeposit(): boolean { return this.cryptoOneTnMethodsDeposit.includes(this.paymentMethod.id); }

  get isEzPaymentH2HDepositMethod(): boolean { return this.paymentMethod.id === EzPaymentMethods.BankCardDepositH2H; }

  get isRoyalPayFixedStepMethod(): boolean {
    return this.royalPayFixedStepMethods.includes(this.paymentMethod.id);
  }

  get isPayhubDepositHostToHost(): boolean {
    return this.payHubHostToHostMethods.includes(this.paymentMethod.id);
  }

  get isBlackRabbitDeposit(): boolean {
    return this.blackRabbitDeposits.includes(this.paymentMethod.id);
  }

  get UserPaymentDataButtonName(): string {
    return this.isUserPaymentDataFormLast ? 'CASHIER.CARD.MAKE_DEPOSIT' : 'CASHIER.CARD.USER_PAYMENT_DATA_SUBMIT';
  }

  get UserPaymentDataTitle(): string {
    return this.isDepositPage ? 'CASHIER.CARD.USER_PAYMENT_DATA_TITLE' : 'CASHIER.CARD.USER_PAYMENT_WITHDRAWAL_DATA_TITLE';
  }

  get isUkrGazBankHostToHostMethods(): boolean {
    return this.ukrGazBankHostToHostMethods.includes(this.paymentMethod.id);
  }

  get accentPayHostToHostMethod(): boolean {
    return this.paymentMethod.id === AccentpayMethods.BankCardDepositHostToHost;
  }

  get exactlyWithdrawalUpdatedMethod(): boolean {
    return this.paymentMethod.id === ExactlyPaymentMethods.WithdrawalUpdated;
  }

  get isAppexWithdrawalMethod(): boolean {
    return this.appexWithdrawalMethods.includes(this.paymentMethod.id);
  }

  get isAppexDepositUntrustedMethod(): boolean {
    return this.paymentMethod.id === AppexMethods.DepositUntrusted;
  }

  get isRoyalpayFixedMethod(): boolean {
    return this.paymentMethod.id === RoyalpayPaymentMethods.BankCardWithdrawalNew;
  }

  get isWithdrawalsFixed(): boolean {
    return this.fixedWithdrawals.includes(this.paymentMethod.id);
  }

  get isFinchpayBillineDepositMethod(): boolean {
    return this.paymentMethod.id === FinchpayMethods.BillineDeposit;
  }

  get isBitandpaySofortDeposit(): boolean {
    return this.paymentMethod.id === BitandpaySofortMethods.BitandpaySofortDeposit;
  }

  get isEcommpayWithoutTokenWithdrawal(): boolean {
    return this.paymentMethod.id === EcommpayPaymentMethods.WithoutTokenWithdrawal;
  }

  get isRoyalPayWithdrawalSBP(): boolean {
    return this.paymentMethod.id === RoyalpayPaymentMethods.WithdrawalSBP;
  }

  get isTurcodeSBPWithdrawal(): boolean {
    return this.paymentMethod.id === TurcodePaymentMethods.TurcodeSBPWithdrawal;
  }

  get isUsbxDepositTrusted(): boolean {
    return this.paymentMethod.id === UsbxPaymentMethods.BankCardDepositTrusted;
  }

  get isUsbxDepositUntrusted(): boolean {
    return this.paymentMethod.id === UsbxPaymentMethods.BankCardDepositUntrusted;
  }

  get isUsbxWithdrawal(): boolean {
    return this.paymentMethod.id === UsbxPaymentMethods.BankCardWithdrawal;
  }

  get isSmartGatesWithdrawal(): boolean {
    return this.paymentMethod.id === SmartGatesMethods.Withdrawal;
  }

  get isEcommpayDeposit(): boolean {
    return this.paymentMethod.id === EcommpayPaymentMethods.MobileDeposit;
  }

  get isUserPaymentDataFormAvailable(): boolean {
    return this.paymentMethod.paymentDataForm === PaymentDataFormStatus.always;
  }

  get isBilllineMethods(): boolean { return this.paymentMethod.id === BilllineMethods.Withdrawal; }

  get isEcommpayBankCardTrustedDeposits(): boolean {
    return this.ecommpayBankCardTrustedDeposits.includes(this.paymentMethod.id);
  }

  get isPaycosBankCardWithdrawals(): boolean {
    return this.paycosBankCardWithdrawals.includes(this.paymentMethod.id);
  }

  get isExactlyDepositH2H(): boolean { return this.paymentMethod.id === ExactlyPaymentMethods.DepositTrustedH2H; }

  get isEcommpaySbpWithdrawal(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.SbpWithdrawal; }

  get isOctopayH2HDeposit(): boolean { return this.paymentMethod.id === OctopayPaymentMethods.BankCardDepositH2H; }

  get isPaylerMethodWithdrawal(): boolean { return this.paymentMethod.id === PaylerPaymentMethods.BankCardWithdrawal; }

  get isPaylerMethodDepositH2H(): boolean { return this.paymentMethod.id === PaylerPaymentMethods.BankCardDepositH2H; }

  get isPaymentCenterSbpWithdrawal(): boolean { return this.paymentMethod.id === PaymentCenterPaymentMethods.SBPWithdrawal; }

  get isMajorpaySbpWithdrawal(): boolean { return this.paymentMethod.id === MajorpayPaymentMethods.WithdrawSBP; }

  get isCirxusBankCardH2H(): boolean { return this.paymentMethod.id === CirxusPaymentMethods.BankCardDepositH2H; }

  get isCirxusWithdrawal(): boolean { return this.paymentMethod.id === CirxusPaymentMethods.BankCardWithdrawal; }

  get isOneVisionDepositMethod(): boolean { return this.paymentMethod.id === OneVisionMethods.Deposit; }

  get isOneVisionWithdrawalMethod(): boolean { return this.paymentMethod.id === OneVisionMethods.Withdrawal; }

  get isApexSbpQr(): boolean { return this.paymentMethod.id === AppexMethods.DepositSpbQr; }

  get isCubixpayDeposit(): boolean { return this.paymentMethod.id === CubixpayPaymentMethods.BankCardDeposit; }

  get isBilllineBankCardDepositMethods(): boolean { return this.paymentMethod.id === BilllineMethods.bankCardDeposit; }

  get isCrosspayDepositH2H(): boolean { return this.paymentMethod.id === CrosspayPaymentMethods.DepositH2H; }

  get isEcommpayMobileDeposit(): boolean { return this.paymentMethod.id === EcommpayPaymentMethods.MobileDepositTrust; }

  get isTwoSendH2HDeposit(): boolean { return this.paymentMethod.id === TwoSendMethods.DepositH2H; }

  get isTwoSendH2HWithdrawal(): boolean { return this.paymentMethod.id === TwoSendMethods.WithdrawalH2H; }

  get isTwoSendP2PDeposit(): boolean { return this.paymentMethod.id === TwoSendMethods.DepositP2P; }

  get isTwoSendSbpDeposit(): boolean { return this.paymentMethod.id === TwoSendMethods.DepositSbp; }

  get isTwoSendSbpQrDeposit(): boolean { return this.paymentMethod.id === TwoSendMethods.DepositSbpQr; }

  get isTwoSendSbpWithdrawal(): boolean { return this.paymentMethod.id === TwoSendMethods.WithdrawalSbp; }

  get isCypixSbpWithdrawal(): boolean { return this.paymentMethod.id === CubixpayPaymentMethods.SbpWithdrawal; }

  get isPaymentCenterP2PWithdrawal(): boolean { return this.paymentMethod.id === PaymentCenterPaymentMethods.P2PWithdrawal; }

  get isAntrpayDepositH2H(): boolean { return this.paymentMethod.id === AntrpayMethods.DepositH2H; }

  get isAntrpayWithdrawalSBP(): boolean { return this.paymentMethod.id === AntrpayMethods.WithdrawalSBP; }

  get isBlackRabbitWithdrawalSBP(): boolean { return this.paymentMethod.id === BlackRabbitPaymentMethods.WithdrawalSBP; }

  get isBlackRabbitDepositH2H(): boolean { return this.paymentMethod.id === BlackRabbitPaymentMethods.DepositH2H; }

  get isMaxPayDepositH2h(): boolean { return this.paymentMethod.id === MaxPayMethods.depositH2h; }

  get isMaxPayWithdrawalH2h(): boolean { return this.paymentMethod.id === MaxPayMethods.withdrawH2h; }

  get isWinpayDepositH2H(): boolean { return this.paymentMethod.id === WinpayPaymentMethods.DepositH2H; }

  get isExpayWithdrawalP2P(): boolean { return this.paymentMethod.id === ExpayMethods.WithdrawP2P; }

  get isPagoWithdrawalSbp(): boolean { return this.paymentMethod.id === PagoMethods.SbpWithdrawal; }

  get isExpaySbpWithdrawal(): boolean { return this.paymentMethod.id === ExpayMethods.WithdrawSBP; }

  get isBankCardWithdrawalWorldKZT(): boolean { return this.paymentMethod.id === CypixPaymentMethods.BankCardWithdrawalWorldKZT; }

  get isBNPAYWithdrawal(): boolean { return this.paymentMethod.id === BnpayMethods.Withdraw; }

  get isBNPAYWithdrawalSBP(): boolean { return this.paymentMethod.id === BnpayMethods.WithdrawSBP; }

  get isBTGateDepositKzt(): boolean { return this.paymentMethod.id === BtGateMethods.DepositBankCardKzt; }

  get methodsWithTwoSteps(): boolean {
    return this.isPayopDeposit || this.isAstropayDeposit ||
           this.isPayopDepositIndia || this.isPraxisDepositMuchBetter ||
           this.isPayperDeposit || this.isRoyalPayDepositH2H ||
           this.isFinchpayBillineDepositMethod || this.isBitandpaySofortDeposit ||
           this.isImpayerDeposit || this.isUsbxDepositTrusted ||
           this.isPremierPayDeposit || this.isEcommpayDeposit ||
           this.isPaycosBankCardWithdrawals || this.isEcommpayNewDepositMethods ||
           this.isEcommpayFullNameMethods || this.isEzPaymentMethods ||
           this.isMifinityMethods || this.isPaycosDFpayWithdrawal ||
           this.isBtGateMethods || this.isBtGateBankCardDepositMethod ||
           this.isEcommpaySepaWithdrawal || this.isSbpQrDeposit ||
           this.isBilllineMethods || this.isEcommpayOpenBankingEUDeposit ||
           this.isSpayzMethods || this.isImpayerBancCardEURDeposit ||
           this.isImpayerBancCardUSDDeposit || this.isImpayerBancCardEURWithdrawal ||
           this.isEcommpayBankCardTrustedDeposits || this.isImpayerBancCardRubDeposit ||
           this.isInterKassaDepositNova || this.isEcommpaySbpWithdrawal ||
           this.isExactlyDepositH2H || this.isXpateH2hDepositMethods ||
           this.isOctopayH2HDeposit || this.isCypixDepositMethods ||
           this.isPaycosH2HDepositMethod || this.isEzPaymentH2HDepositMethod ||
           this.isPaylerMethodWithdrawal || this.isPayhubH2HDepositMethods ||
           this.isEcommpayInteracMethods || this.isPaymentCenterH2HDepositMethod ||
           this.isBigideaInteracMethods || this.isCirxusBankCardH2H || this.isApexH2HDeposit ||
           this.isApexSbpQr || this.isBilllineBankCardDepositMethods ||
           this.isBilllineBankCardDepositMethods || this.isCirxusBankCardH2H ||
           this.isOneVisionWithdrawalMethod || this.isOneVisionDepositMethod ||
           this.isCubixpayDeposit || this.isApexH2HDeposit ||
           this.isCrosspayDepositH2H || this.isEcommpayMobileDeposit || this.isTwoSendH2HWithdrawal ||
           this.isTwoSendSbpWithdrawal || this.isCypixSbpWithdrawal || this.isPaycosWithdrawalRub ||
           this.isPaymentCenterP2PWithdrawal || this.isAntrpayDepositH2H || this.isCactusSbpWithdrawalMethod ||
           this.isPayPortalBankCardDepositH2H || this.isEcommpayMobileWithdrawal || this.isOverpayWithdrawal ||
           this.isOverpayDepositH2H || this.isWinpayBankCardDeposit || this.isBlackRabbitDepositH2H ||
           this.isBigideaInteracWithdrawal || this.isCardayportalBankCardDepositCis || this.isExpayWithdrawalP2P ||
           this.isWinpayDepositH2H || this.isPaylerMethodDepositH2H || this.isIdmWithdrawal ||
           this.isBankCardWithdrawalWorldKZT || this.isBNPAYWithdrawal || this.isBNPAYWithdrawalSBP ||
           this.isMaxPayH2HWithdrawal || this.isMaxPayH2HDeposit || this.isBankCardWithdrawalWorldKZT || this.isTurcodeSBPWithdrawal || this.isExpaySbpWithdrawal;
  }

  ngOnDestroy(): void {
    !!this.watchers && this.watchers.unsubscribe();
  }

  togglePopup(cardAccount?: string): void {
    this.showDeleteCardPopup = !!cardAccount;
    this.cardDeleteAccount = cardAccount || null;
  }

  deleteCard(): void {
    const [ { uuid } ] = this.presetCards.filter(card => card.account === this.cardDeleteAccount);
    this.togglePopup();
    this.cashierCommands.deleteCard(uuid).subscribe(
      (answer) => {
        if (answer.status === 'error') {
          this.notify.error('NOTIFICATIONS.MESSAGES.CARD_DELETE_ERROR');
          return;
        }
        this.notify.success('NOTIFICATIONS.MESSAGES.CARD_DELETE_SUCCESS');
        this.presetCards = this.presetCards.filter(card => card.uuid !== uuid);
        [ this.depositActiveCard ] = this.depositPresetCardList;
        this.form.updateValueAndValidity();
      },
      () => this.notify.error('NOTIFICATIONS.MESSAGES.CARD_DELETE_ERROR'),
    );
  }

  navigateToDeposit(): void {
    this.navigationService.navigate([ 'cashier', CashierSections.Deposit ]);
  }

  setActivePresetCard(card: Account): void {
    this.form.get(ACCOUNT_FIELD_NAME)?.setValue(card.account);
  }

  isPresetCardActive(card: Account): boolean {
    return this.form.get(ACCOUNT_FIELD_NAME)?.value === card.account;
  }

  cryptoProcessingWithdrawal(requestInfo?: string): void {
    if (!this.formCrypto.valid) { return; }

    if (this.isShowDynamicCommission && requestInfo !== 'hide_dynamic' && requestInfo !== 'skip') {
      const { isWithdrawalsFixed, paymentMethod: { fee } } = this;
      const commission = this.cashierService.makePercentCommission(fee.now.rate);
      const commissionRules = fee.rules && this.cashierService.makeDynamicCommissionRule(fee.rules);
      const commissionAmount = this.cashierService.makeCommissionAmount(this.getAmount(), fee.now.rate);
      const amountAfterCommission = this.cashierService.makeAmountAfterCommission(this.getAmount(), fee.now.rate);
      const data = {
        isWithdrawalsFixed,
        fee,
        amount: this.getAmount(),
        commission,
        commissionRules,
        commissionAmount,
        amountAfterCommission,
      };
      const dialogRef = this.dialogService.open(CommissionWarningDialogComponent, {
        panelClass: 'bottom-center',
        data,
      }).closed.subscribe(
        (result) => {
          if (result) {
            this.sendRequest(result as string);
          }
        },
      );
      this.watchers.add(dialogRef);
      return;
    }

    const { address, amount } = this.formCrypto.value;

    const payload: CryptoWithdrawalPayload = {
      amount,
      payment_method: this.paymentMethod.id,
      cryptocurrency: this.cryptoActive?.name,
      account: address,
    };

    this.cashierCommands.createCryptoWithdrawRequest(payload).subscribe(
      response => {
        this.navigateToStatusPage(response);
      },
      () => {
        this.navigateToStatusPage({ status: CashierRequestStatus.Error });
      },
    );
  }

  sendRequest(requestInfo?: string): void {
    if (this.isShowDynamicCommission && this.isCryptoProcessingWithdrawal && requestInfo === 'hide_dynamic') {
      this.cryptoProcessingWithdrawal('hide_dynamic');
    }

    const { amount, account, bank_id, operator, operator_id } = this.form.value;
    const paymentMethodId = this.checkIsMethodCopied();

    if (!this.form.valid) { return; }

    if (this.isShowDynamicCommission && requestInfo !== 'hide_dynamic') {
      const { isWithdrawalsFixed, paymentMethod: { fee } } = this;
      const commission = this.cashierService.makePercentCommission(fee.now.rate);
      const commissionRules = fee.rules && this.cashierService.makeDynamicCommissionRule(fee.rules);
      const commissionAmount = this.cashierService.makeCommissionAmount(this.getAmount(), this.paymentMethod.fee.now.rate);
      const amountAfterCommission = this.cashierService.makeAmountAfterCommission(this.getAmount(), this.paymentMethod.fee.now.rate);
      const data = {
        isWithdrawalsFixed,
        fee,
        amount: this.getAmount(),
        commission,
        commissionRules,
        commissionAmount,
        amountAfterCommission,
      };

      const dialogRef = this.dialogService.open(CommissionWarningDialogComponent, {
        panelClass: 'bottom-center',
        data,
      }).closed.subscribe((result) => {
          if (result) {
            this.sendRequest(result as string);
          }
        },
      );
      this.watchers.add(dialogRef);
      return;
    }

    if (this.cashierService.isLeoPaymentWidget(this.paymentMethod.id)) {
      this.showPhoneWidget = true;
      return;
    }

    if (
      this.isRoyalPayDepositH2H || this.isImpayerDeposit ||
      this.isUsbxDepositTrusted || this.isImpayerBancCardEURDeposit ||
      this.isImpayerBancCardRubDeposit || this.isBtGateBankCardDepositMethod ||
      this.isImpayerBancCardUSDDeposit || this.isEcommpayBankCardTrustedDeposits ||
      this.isExactlyDepositH2H || this.isOctopayH2HDeposit ||
      this.isXpateH2hDepositMethods || this.isCypixDepositMethods ||
      this.isPaycosH2HDepositMethod || this.isEzPaymentH2HDepositMethod || this.isPayhubH2HDepositMethods ||
      this.isPaymentCenterH2HDepositMethod || this.isCirxusBankCardH2H || this.isPaymentCenterH2HDepositMethod ||
      this.isCirxusBankCardH2H || this.isOneVisionDepositMethod || this.isCubixpayDeposit || this.isApexH2HDeposit ||
      this.isCrosspayDepositH2H || this.isTwoSendH2HDeposit || this.isAntrpayDepositH2H || this.isOverpayDepositH2H ||
      this.isPayPortalBankCardDepositH2H || this.isBlackRabbitDepositH2H || this.isPaylerMethodDepositH2H || this.isWinpayDepositH2H ||
      this.isMaxPayH2HDeposit || this.isBTGateDepositKzt
    ) {
      this.showPDWidget = true;
      this.showWidget = true;
      this.showWidgetPayAndBonusInfo(amount);
      this.detectChanges();
      return;
    }

    if (this.cashierService.isPaymegaWidget(this.paymentMethod.id) ||
        this.isPayhubDepositHostToHost ||
        this.isUkrGazBankHostToHostMethods
    ) {

      if (this.depositActiveCard && requestInfo !== 'add_card') {
        this.sendDepositRequest(amount, paymentMethodId, null, this.depositActiveCard);
        return;
      }

      this.showWidgetPayAndBonusInfo(amount);

      this.showWidget = true;

      if (this.cashierService.isPaymegaWidget(this.paymentMethod.id) ||
          this.isPayhubDepositHostToHost ||
          this.isUkrGazBankHostToHostMethods
      ) {
        this.showPDWidget = true;
        this.viewportScroller.scrollToPosition([ 0, 0 ]);
      }

      return;
    }

    if (this.cashierService.NewXpatePaymentMethods(this.paymentMethod.id)) {
      this.openUserDataForm(USER_DATA_INFO);
      if (this.isUserPaymentDataFormAvailable) {
        this.isUserPaymentDataFormLast = true;
        return;
      }
    }

    if (this.isBlackRabbitDeposit) {
      this.openUserDataForm(USER_DATA_INFO_NAME_PART_PHONE);
      if (this.isUserPaymentDataFormAvailable) {
        this.isUserPaymentDataFormLast = true;
        return;
      }
    }

    if (this.accentPayHostToHostMethod) {
      this.showWidgetPayAndBonusInfo(amount);
      this.openUserDataForm(USER_DATA_INFO_NAME_PART);
      return;
    }

    if (this.isMifinityMethods) {
      this.userPaymentDataForm = { ...this.userPaymentDataForm, email: this.form.get('email')?.value };
    }

    if (this.isCactusPaymentDepositMethod || this.isCactusPaymentWithdrawalMethod) {
      const { address = null, city = null, messenger = null, username = null } = this.form.value;
      this.cactusData = {
        address,
        city,
        messenger,
        username,
      };
    }

    if (this.isApexSbpQr) {
      this.userPaymentDataForm = {
        phone: this.userPaymentDataForm.phone.replace('+', ''),
      };
    }

    if (this.isWithdrawPage) {
      const { expM = null, expY = null, name = null, surname = null } = this.form.value;
      const cardData = name || surname || expM
        ? {
          full_name: `${name} ${surname}`,
          first_name: name,
          last_name: surname,
          exp_month: expM,
          exp_year: expY,
        }
        : null;

      let paymentData = this.userPaymentDataForm ? this.userPaymentDataForm : null;

      if (this.isPayhubWithdrawalMethod) {
        paymentData = { ...paymentData, first_name: name, last_name: surname };
      }

      if (this.isCactusPaymentWithdrawalMethod) {
        this.cashierCommands.createPaymentCode(this.paymentMethod.id).subscribe((code: string) => {
          const data = {
            code,
            form: this.form.value,
            amountForWidget: this.amountForWidget,
            commission: this.commission,
          };
          this.dialogService.open(CactusDialogComponent, {
            panelClass: 'bottom-center',
            data,
          }).closed.subscribe((confirm) => {
            if (confirm) {
              this.sendWithdrawalRequest(amount, account, paymentMethodId, name, expM, expY, cardData, paymentData, bank_id, operator_id, code, this.cactusData);
            }
          });
        });
      } else {
        this.sendWithdrawalRequest(amount, account, paymentMethodId, name, expM, expY, cardData, paymentData, bank_id, operator_id);
      }
    } else {
      this.sendDepositRequest(amount, paymentMethodId, null, account, null, null, null, this.userPaymentDataForm, operator, bank_id, this.cactusData);
    }
  }

  showTermCheckbox(): boolean {
    return this.source.modules.cashier.operationAgreementCheckbox
           && (this.paymentMethod.id === InterkassaPaymentMethods.Withdrawal
               || this.paymentMethod.id === TranzzoPaymentMethods.WithdrawalADVCash
               || this.paymentMethod.id === TranzzoPaymentMethods.Deposit
               || this.paymentMethod.id === TranzzoPaymentMethods.DepositVisa
               || this.paymentMethod.id === TranzzoPaymentMethods.DepositVisaUntrusted
               || this.paymentMethod.id === TranzzoPaymentMethods.DepositMasterCard);
  }

  sendPDWidgetRequest(value): void {
    const { amount, account: phone = null } = this.form.value;
    const { account = null, expM = null, expY = null, cvv = null } = value;
    const paymentMethodId = this.checkIsMethodCopied();
    this.showPDWidgetLoading = true;

    if (this.isPayhubDepositHostToHost) {
      this.userPaymentDataForm = { ...this.userPaymentDataForm, phone };
    }

    this.sendDepositRequest(
      amount,
      paymentMethodId,
      null,
      account,
      cvv,
      expM,
      expY,
      this.userPaymentDataForm,
    );
  }

  hideUserData(): void {
    this.isPaymentData = !this.isPaymentData;
    if (this.isWithdrawPage) {
      this.showWidget = !this.showWidget;
    }

    if (this.accentPayHostToHostMethod) {
      this.isAccentPayH2HCardData = true;
      this.showPDWidget = true;
    }
  }

  saveFormData(formData: UserPaymentDataForm): void {
    this.userPaymentDataForm = formData;

    if (this.methodsWithTwoSteps) {
      this.showWidget = false;
    }

    if (this.isUserPaymentDataFormLast) {
      const { amount } = this.form.value;
      const paymentMethodId = this.checkIsMethodCopied();
      this.sendDepositRequest(amount, paymentMethodId, null, null, null, null, null, this.userPaymentDataForm);
    }
  }

  sendPhone(): void {
    const { amount } = this.form.value;
    const { phone } = this.phoneWidgetForm.value;
    const paymentMethodId = this.checkIsMethodCopied();

    if (!this.phoneWidgetForm.valid) { return; }

    this.sendDepositRequest(amount, paymentMethodId, null, phone);
  }

  sendCode(): void {
    const { code } = this.phoneCodeWidgetForm.value;
    this.cashierCommands.depositUpdate(this.initiatorID, code).subscribe(response => {
      if (response.status === CashierRequestStatus.Success) {
        this.navigateToStatusPage({ status: CashierRequestStatus.Going });
        return;
      }

      this.navigateToStatusPage(response);
    });
  }

  reSendCode(): void {
    this.sendPhone();
    this.showResendMessage = false;
    timer(30000).subscribe(() => this.showResendMessage = true);
  }

  isStubEnabled(): boolean {
    if (!this.source.modules.cashier.enableStubForCryptocurrency) { return true; }

    return this.emailConfirmed || !this.hasCategoryCryptocurrency();
  }

  setAmountField(bonusValue: string): void {
    this.form.get(AMOUNT_FIELD_NAME)?.setValue(bonusValue);
  }

  changeActiveCrypto(crypto: CryptoCurrency): void {
    this.cashierCommands.setActiveCryptoCurrency(crypto);
    this.cryptoActive = this.cashierQuery.activeCryptoCurrency;
    this.showCryptoOptions = !this.showCryptoOptions;
    this.convertCrypto();
  }

  convertCrypto(): void {
    const { amount } = this.formCrypto.value;
    const payload: CryptoConversionRate = {
      amount,
      payment_method: String(this.paymentMethod.id),
      currency: this.userCurrency,
      cryptocurrency: this.cryptoActive?.name,
    };

    this.cashierCommands.loadConvertCrypto(payload).subscribe();

    this.commission = this.countCommission();
    this.detectChanges();
  }

  changeAmount(): void {
    this.commission = this.countCommission();
    const amountField = this.form.get(AMOUNT_FIELD_NAME);

    if (Number(this.form.value.amount) > Number(this.paymentMethod.max_value)) {
      amountField?.setValue(this.paymentMethod.max_value);
    }

    if (isNaN(this.form.value.amount)) {
      this.isWithdrawPage
        ? amountField?.setValue(this.paymentMethod.min_value)
        : amountField?.setValue(this.paymentMethod.max_value);
    }

    this.detectChanges();
  }

  openDynamicCommissionInfoDialog(): void {
    const { fee } = this.paymentMethod;
    const data = {
      fee,
      commission: this.cashierService.makePercentCommission(fee.now.rate),
      commissionRules: fee.rules && this.cashierService.makeDynamicCommissionRule(fee.rules),
    };

    this.dialogService.open(CommissionInfoDialogComponent, { panelClass: 'bottom-center', data });
  }

  getAmount(): number {
    return this.isCryptoProcessingWithdrawal ? this.formCrypto.get(AMOUNT_FIELD_NAME)?.value : this.form.get(AMOUNT_FIELD_NAME)?.value;
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  private getInitialData(): void {
    const userInfo: Subscription = combineLatest([
      this.userInfoQuery.id$,
      this.userInfoQuery.balance$,
      this.userInfoQuery.currency$,
      this.userInfoQuery.personal$,
      this.userInfoQuery.verification$,
    ]).subscribe(([ userId, balance, currency, personal, verification ]) => {
      this.userBalance = balance;
      this.userId = userId;
      this.userCurrency = currency;
      this.commission = this.countCommission();
      this.minAmount = `${this.paymentMethod.min_value} ${this.userCurrency}`;
      this.maxAmount = `${this.paymentMethod.max_value} ${this.userCurrency}`;
      this.emailConfirmed = verification?.email.confirmed;

      if (this.isPayhubWithdrawalMethod) {
        const validators = [ Validators.required, Validators.minLength(2), Validators.maxLength(64) ];
        this.form.addControl('name', new FormControl(personal?.firstName, validators));
        this.form.addControl('surname', new FormControl(personal?.lastName, validators));
      }

      if (this.isCactusPaymentWithdrawalMethod) {
        this.amountForWidget = `${(parseFloat(this.form.value[ AMOUNT_FIELD_NAME ]))} ${this.userCurrency}`;
      }
    });

    this.watchers.add(userInfo);
  }

  private setAmountAndCommission(): void {
    if (this.isTwoSendH2HDeposit) {
      this.form.get(AMOUNT_FIELD_NAME)?.setValue(this.paymentMethod.max_value);
    } else {
      this.form.get(AMOUNT_FIELD_NAME)?.setValue(this.getDefaultAmount());
    }
    this.depositPayInfo = `${(parseFloat(this.form.value[ AMOUNT_FIELD_NAME ]))} ${this.userCurrency}`;
    this.commission = this.countCommission();
  }

  private getFormConfig(): FormGroup {
    switch (true) {
      case !this.isWithdrawPage || (this.isWithdrawPage && this.isProcessingKZWithdrawal):
        return this.fb.group(DEPOSIT_FORM);
      case this.isWithdrawPage && this.isXpateWithdrawalNewMethod || this.isSmartGatesWithdrawal:
        return this.fb.group(WITHDRAWAL_FORM_FULL_ACCOUNT);
      case this.isWithdrawPage && this.isWinPayVisaWithdrawalMethod || this.isWinPayWithdrawalMethod:
        return this.fb.group(WITHDRAWAL_CARD_ACCOUNT_FORM_DECLARATION);
      case (this.isWithdrawPage && this.isSaveCardMethod) || (this.isNotBankCardMethod && !this.isEcommpaySepaWithdrawal):
        return this.fb.group(WITHDRAWAL_NOT_CARD_FORM);
      default:
        return this.fb.group(WITHDRAWAL_FORM);
    }
  }

  private dispatchStatusHostToHost(id: string): void {
    this.cashierCommands.check3dsPayment(id, this.paymentMethod.id.toString()).subscribe();
  }

  private openUserDataForm(form): void {
    if (this.isUserPaymentDataFormAvailable) {
      this.showWidget = true;
      this.isPaymentData = true;
      this.userForm = form;
    }
  }

  private showWidgetPayAndBonusInfo(amount: string): void {
    this.widgetPayInfo = `${String(amount)} ${this.userCurrency}`;
    this.widgetBonusInfo = this.activeBonus
      ? `+ ${this.bonusTitlePipe.transform(this.activeBonus)}`
      : null;
  }

  private hasCategoryCryptocurrency(): boolean {
    return this.paymentMethod.category === 'cryptocurrency';
  }

  private checkIsMethodCopied(): Nullable<number> {
    const { id, is_copy } = this.paymentMethod;
    return is_copy ? this.originalCopiedMethodId : id;
  }

  private sendWithdrawalRequest(amount, account, method, name, expM, expY, cardData, paymentData, bankId, operatorId, verificationCode?, cactusData?): void {
    this.depositButton.loading = true;
    let withdrawalBody: CreateWithdrawal = {
      amount,
      method,
      account,
      url: window.location.origin,
      cardholder_name: name,
      expiry_month: expM,
      expiry_year: expY,
      card_data: cardData,
      payment_data: paymentData,
      bank_id: bankId,
      operator_id: operatorId,
      verification_code: verificationCode,
    };
    this.cashierService.createWithdrawRequest(withdrawalBody, cactusData).subscribe(
      (response) => {
        if (response.code === 'ACCOUNT_IN_BLACKLIST_ERROR') {
          this.isCardOnBlackList = true;
          this.isDisabledDepositButton = false;
          this.depositButton.loading = false;
          this.cd.detectChanges();
          return;
        }

        if (response.code === 'INSUFFICIENT_FUNDS') {
          this.notify.error('NOTIFICATIONS.MESSAGES.NEED_MORE_GOLD');
        }

        if (response.data && response.data.status === 'TRANSACTION_CREATED') {
          window.location.href = response.data.url;
          return;
        }

        if (this.isCactusPaymentWithdrawalMethod) {
          this.navigateToStatusPage(response, false, null, null, true);
        }

        if (this.paymentMethod.max_withdrawal_amount &&
            normalizeFloatAmount(this.form.get(AMOUNT_FIELD_NAME).value) > normalizeFloatAmount(this.paymentMethod.max_withdrawal_amount)) {
          this.navigateToStatusPage(response, true);
        } else {
          this.navigateToStatusPage(response);
          this.authorization.discover();
        }

        this.depositButton.loading = false;
      },
      () => {
        this.depositButton.loading = false;
        this.navigateToStatusPage({ status: CashierRequestStatus.Error });
      },
    );
  }

  private sendDepositRequest(amount, method, token?, account?, cvv?, expM?, expY?, paymentData?, operator?, bank_id?, cactusData?): void {
    this.isDisabledDepositButton = true;
    this.depositButton.loading = true;
    this.showPDWidgetLoading = true;

    if (this.isApexH2HDeposit && paymentData) {
      paymentData.phone = paymentData.phone.replace('+', '');
    }

    if (!this.userInfoQuery.isBonusesEnabled && this.activeBonus) {
      this.navigationService.navigate([ 'cashier', 'deposit-verification' ]);
      return;
    }

    const trimAccount = account ? account?.toString().replace(/\s/g, '') : null;

    let body: CreateDeposit = {
      amount,
      method,
      url: `${window.location.origin}/cashier/deposit/transaction`,
      postUrl: '/api/payments/deposit/create/',
      bonus: this.activeBonus,
      token,
      account: trimAccount,
      cvv,
      exp_month: expM,
      exp_year: expY,
      payment_data: paymentData,
      user_card_id: this.userBankCardId,
      operator,
      bank_id,
    };

    this.cashierService.createDepositRequest(body, cactusData).subscribe({
      next: (response) => {
        if (
          response.data && response.data.initiatorId && !this.isUkrGazBankHostToHostMethods &&
          !this.accentPayHostToHostMethod && !this.isImpayerDeposit && !this.isEcommpayBankCardTrustedDeposits
          && !this.isXpateH2hDepositMethods && !this.isCypixDepositMethods && !this.isPaymentCenterH2HDepositMethod &&
          !this.isApexH2HDeposit && !this.isCactusPaymentDepositMethod && !this.isTwoSendH2HWithdrawal && !this.isTwoSendH2HDeposit &&
          !this.isOneVisionDepositMethod && !this.isPayPortalBankCardDepositH2H && !this.isBlackRabbitDepositH2H && !this.isPaylerMethodDepositH2H
        ) {
          this.initiatorID = response.data.initiatorId;
          this.showPhoneWidgetCode = true;
          this.isDisabledDepositButton = false;
          this.depositButton.loading = false;
          return;
        }

        if (response.status === CashierRequestStatus.Error) {
          this.cashierCommands.resetErrorMessage();

          if (response.message === 'Invalid card number.: card_number') {
            this.notify.error('NOTIFICATIONS.MESSAGES.BAD_CARD_NUMBER');
            this.showPDWidgetLoading = false;
            return;
          } else if (response.message === 'Card has expired') {
            this.notify.error('NOTIFICATIONS.MESSAGES.CARD_EXPIRED');
            this.showPDWidgetLoading = false;
            return;
          } else if (this.paymentMethod.id === LeoPaymentMethods.Deposit) {
            // Inactive method
            this.cashierCommands.setErrorMessage(response.message);
            return;
          }

          this.navigateToStatusPage({ status: CashierRequestStatus.Error }, null, null, this.userBankCardId);
          return;
        }

        if (response.status === CashierRequestStatus.Success && this.window) {
          timer(60).subscribe(() => this.authorization.discover());

          this.bonusCommand.synchronize();

          if (!!response.data.target_card_number) {
            const data = response.data;

            this.octopayInfo = {
              amount: String(amount),
              card: data.target_card_number,
              expireTime: data.valid_till,
              orderID: data.order_id,
            };
            this.showOctopayDepositBlock = true;

            this.isDisabledDepositButton = false;
            this.depositButton.loading = false;
            this.detectChanges();

            return;
          }

          if (!!response.data.url) {
            this.window.location.href = response.data.url;
            return;
          }

          if (!!response.data.qrcode && !this.isMobile && !response.data.url) {
            this.showPDWidget = false;
            this.showWidget = false;
            this.isShowQrcode = true;
            this.qrCodeLink = response.data.qrcode;

            this.isDisabledDepositButton = false;
            this.depositButton.loading = false;

            this.detectChanges();
            return;
          }

          if (this.paysoftDepositMethods.includes(this.paymentMethod.id)) {
            this.paysoftWidgetInfo = response.data;
            this.showPaysoftWidget = true;
            setTimeout(() => this.viewportScroller.scrollToPosition([ 0, 0 ]), 0);
            this.detectChanges();
            return;
          }

          if (this.paymegaDepositMethods.includes(this.paymentMethod.id) &&
              !!response.message && response.message.lowerCase === CashierRequestStatus.Success) {
            this.navigateToStatusPage({ status: CashierRequestStatus.Going });
            return;
          }

          if (!!response.data.params) {
            this.pdWidgetParams = response.data.params;
            this.pdWidgetMethod = response.data.method;
            this.pdWidgetAction = response.data.url;
            this.detectChanges();
            timer(60).subscribe(() => this.widgetFakeForm.nativeElement.submit());
            return;
          }

          // TODO: According to agreements with payments managers and BE 'isUkrGazBankHostToHostMethods' won't be used anymore
          //  and 'accentPayHostToHostMethod' now is not active and will be reimplemented soon
          if (this.isUkrGazBankHostToHostMethods || this.accentPayHostToHostMethod || this.isEcommpayBankCardTrustedDeposits || this.isBlackRabbitDepositH2H) {
            const initiatorId = response.data.initiatorId;
            this.dispatchStatusHostToHost(initiatorId);

            if (!this.isEcommpayBankCardTrustedDeposits && !this.isBlackRabbitDepositH2H) {
              this.navigateToStatusPage({ status: CashierRequestStatus.Progress }, false, initiatorId);
              return;
            }

            this.cashierQuery.threeDSPaymentData$.pipe(
              switchMap(values => !values.exist ? interval(2000) : of(null)))
              .subscribe(() => {
                this.dispatchStatusHostToHost(initiatorId);
              });

            this.cashierQuery.threeDSPaymentData$.subscribe((data) => {
              if (data.redirectUrl) {
                this.window.location.href = data.redirectUrl;
                return;
              } else if (data.auth3dsValue) {
                const depositBody: CreateDeposit = {
                  ...body,
                  postUrl: '/api/payments/deposit/update/',
                };
                this.cashierService.createDepositRequest(depositBody);
                return;
              }
            });
            return;
          }

          if ((this.isXpateH2hDepositMethods || this.isCypixDepositMethods || this.isTwoSendH2HDeposit) && response.data.transaction_id && response.data.initiatorId) {
            this.dialogService.open(EnterCodeDialogComponent, 'bottom-center-500').closed.subscribe(
              (code: string) => {
                if (code) {
                  const depositBody: CreateDeposit = {
                    ...body,
                    postUrl: '/api/payments/deposit/update/',
                    code,
                    transaction_id: response.data.transaction_id,
                    initiator_id: response.data.initiatorId,
                  };
                  this.cashierService.createDepositRequest(depositBody).subscribe((res) => {
                    if (res.status === CashierRequestStatus.Error) {
                      this.cashierCommands.resetErrorMessage();

                      this.navigateToStatusPage({ status: CashierRequestStatus.Error }, null, null, this.userBankCardId);
                      return throwError(() => 'deposit error');
                    }
                    if (res.status === CashierRequestStatus.Success) {
                      this.cashierCommands.resetErrorMessage();

                      this.navigateToStatusPage({ status: CashierRequestStatus.Success });
                      return;
                    }
                  });
                }
              },
            );
          }

          this.navigateToStatusPage({ status: 'success' });

          return;
        }

        if (response.status === CashierRequestStatus.Pending && this.isImpayerDeposit || this.isPaymentCenterH2HDepositMethod ||
            this.isApexH2HDeposit || this.isOneVisionDepositMethod || this.isPayPortalBankCardDepositH2H || this.isPaylerMethodDepositH2H) {

          if (this.isApexH2HDeposit) {
            this.navigateToStatusPage({ status: CashierRequestStatus.Progress }, false, response.data.initiatorId);
            return;
          }

          const checkPaymentStatus = () => {
            this.showLoadingPendingText = true;
            this.detectChanges();

            const timerDuration = this.isImpayerDeposit ? 10000 : 2000;

            timer(timerDuration).pipe(
              switchMap(() => {
                return this.cashierCommands.checkPaymentStatus(response.data.initiatorId);
              }),
            ).subscribe(
              (statusRes) => {
                if (this.window && statusRes.data?.url) {
                  this.showLoadingPendingText = false;
                  this.window.location.href = statusRes.data.url;
                } else if (statusRes.status === CashierRequestStatus.Error && this.window) {
                  this.navigateToStatusPage({ status: CashierRequestStatus.Error });
                } else if (statusRes.status === CashierRequestStatus.Success && this.window && !statusRes.data.url) {
                  this.navigateToStatusPage({ status: CashierRequestStatus.Going });
                } else {
                  checkPaymentStatus();
                }
              },
            );
          };
          checkPaymentStatus();
          return;
        }

        if (!this.isUkrGazBankHostToHostMethods || !this.accentPayHostToHostMethod || !this.isEcommpayBankCardTrustedDeposits ||
            !this.isCactusPaymentDepositMethod || !this.isBlackRabbitDepositH2H) {
          this.navigateToStatusPage(response);
        }

        if (response.status === CashierRequestStatus.Pending && this.isCactusPaymentDepositMethod) {
          this.navigateToStatusPage({ status: CashierRequestStatus.Progress });
        }
      },
    });
  }

  private navigateToStatusPage(response, showWithdrawalExtraInfo?, invoice?, bankCardMethodId?, showWithdrawalCactusInfo?): void {
    this.bonusCommand.synchronize();
    const section = this.section === CashierSections.Withdraw ? CashierSections.Withdrawal : this.section;
    this.navigationService.navigate([ 'cashier', section, 'transaction', response.status ], {
      showWithdrawalExtraInfo,
      invoice,
      bankCardMethodId,
      showWithdrawalCactusInfo,
    });
  }

  private getDefaultAmount(): string {

    if (Number(this.paymentMethodsQuery.defaultAmount) > Number(this.paymentMethod.max_value)) {
      return this.paymentMethod.max_value;
    }

    if (this.isWithdrawPage) { return this.paymentMethod.min_value; }

    const methodDefaultAmount = this.paymentMethod.is_min_amount_enabled ? (
      !!parseFloat(this.paymentMethod.default_amount)
        ? parseFloat(this.paymentMethod.default_amount)
        : parseFloat(this.paymentMethod.min_value)
    ) : parseFloat(this.paymentMethodsQuery.defaultAmount);

    const defaultAmount: string = parseFloat(this.paymentMethod.min_value) > methodDefaultAmount ?
      this.paymentMethod.min_value : methodDefaultAmount;

    if (this.firstCheck) {
      this.form.get(AMOUNT_FIELD_NAME)?.setValue(defaultAmount);
      this.firstCheck = false;
    }
    const currentAmount = this.form.getRawValue().amount;

    if (!!this.activeBonus) {
      return Number(currentAmount) < Number(this.activeBonus?.activation.price) ?
        this.activeBonus.activation.price : currentAmount;
    } else {
      return Number(currentAmount) >= Number(this.paymentMethod.min_value) ? currentAmount : defaultAmount;
    }
  }

  private countCommission(): string {
    const amount = this.isCryptoProcessingWithdrawal ? this.formCrypto.get(AMOUNT_FIELD_NAME)?.value : this.form.get(AMOUNT_FIELD_NAME)?.value;
    const minAmount = amount ? amount : this.paymentMethod.min_value;
    const commission = this.cashierService.makeCommissionAmount(minAmount, this.paymentMethod.fee.now.rate);
    this.commissionSignApprox = Number(commission) > 0 ? '≈ ' : '';
    return `${commission} ${this.userCurrency}`;
  }
}
