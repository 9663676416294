import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';

@Component({
  standalone: true,
  selector: 'gg-layout-footer-section',
  templateUrl: './section.component.html',
  styleUrls: [ './section.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule ]
})
export class SectionComponent {
  @Input() title: Nullable<string>;
}
