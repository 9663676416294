// @ts-nocheck
import { Dialog } from '@angular/cdk/dialog';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, inject,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpStorage } from '@libs/cdk';
import { GlobalWindow, Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { Banner, BannersData, BannersQuery } from '@portal/banners';
import { BankCardsAge, CashierLoaderService } from '@portal/cashier';
import { oldBankCardBannerConfig } from '@portal/cashier/components/card-validation/constants/banner-config.constant';
import { PhoneRegDialogComponent } from '@portal/cashier/components/deposit/phone-reg-dialog/phone-reg-dialog.component';
import {
  CashierDataService,
  PaymentMethod,
  PaymentMethodAge,
  PaymentMethodsQuery,
  UserBankCardQuery
} from '@portal/cashier/data';
import { ConfigQuery } from '@portal/config';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { APPLE_PAY_METHODS } from '../../constants/methods-for-apple-pay.constant';
import { CashierSections } from '../../types/enums/sections.enum';

@Component({
  selector: 'gg-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: [ './deposit.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly bannersStore = inject(BannersData);
  private readonly cashierLoader = inject(CashierLoaderService);

  private urlExtras: Subscription = new Subscription();
  private depositCardPreValidationSub: Nullable<Subscription>;
  private recommendedMethod = null;

  readonly dialogHeight: string = this.window.innerWidth < 500 ? '315px' : 'auto';
  readonly section: CashierSections = CashierSections.Deposit;
  readonly bankCardBannerConfig: Banner = oldBankCardBannerConfig;
  readonly isBankCardPage: boolean = this.router.url.includes('bank-cards');
  readonly isCashierLoading$: Observable<boolean>;

  paymentMethods$: Observable<Array<PaymentMethod>>;
  isBankCardsButtonShown = false;

  get isOldCardSelected(): boolean {
    return this.isBankCardPage && this.userCardsQuery.selectedCardAge === BankCardsAge.Old;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogService: Dialog,
    private readonly storage: SpStorage,
    private readonly router: Router,
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly userCardsQuery: UserBankCardQuery,
    private readonly cd: ChangeDetectorRef,
    private readonly source: ConfigQuery,
    @Inject(WINDOW) private readonly window: GlobalWindow<{ ApplePaySession: boolean }>
  ) {
    this.isCashierLoading$ = this.cashierLoader.isCashierLoading$ || this.paymentMethodsQuery.loading$;
  }

  ngOnInit(): void {
    if (this.isOldCardSelected) {
      this.bannersStore.addBanner(this.bankCardBannerConfig);
    }

    if (this.userCardsQuery.selectedCard && !this.isBankCardPage) {
      this.cashierCommands.resetSelectedCard();
      this.cashierCommands.synchronize();
    }

    const isBankCardPreValidation = this.source.modules.cashier.depositCardPrevalidation;
    this.isBankCardsButtonShown = !this.isBankCardPage && isBankCardPreValidation;

    if (isBankCardPreValidation && this.isBankCardPage) {
      this.paymentMethods$ = this.paymentMethodsQuery.deposits$.pipe(
        map((allMethods) => this.filterMethodsByAgeType(allMethods)),
        tap((bankMethods) => this.setRecommendedMethods(bankMethods))
      );
      this.cd.markForCheck();
    } else {
      this.paymentMethods$ = this.paymentMethodsQuery.deposits$.pipe(
        tap((paymentMethods) => {
          if (!Array.isArray(paymentMethods)) {
            this.isBankCardsButtonShown = false;
            return;
          }

          this.isBankCardsButtonShown = isBankCardPreValidation
            && paymentMethods.some((methods) => methods.category === 'bank_card');

          this.cd.detectChanges();
        }),
        filter((paymentMethods) => {
          return Array.isArray(paymentMethods) && !!paymentMethods.length;
        }),
        map((paymentMethods) => !this.window.ApplePaySession
          ? paymentMethods.filter((paymentMethod) => !APPLE_PAY_METHODS.includes(paymentMethod.id))
          : paymentMethods
        ),
        map((paymentMethods) => {
          return !isBankCardPreValidation ? paymentMethods : paymentMethods.filter((paymentMethod) => paymentMethod.category !== 'bank_card');
        }),
        tap((paymentMethods) => {
          this.setRecommendedMethods(paymentMethods);
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.urlExtras = this.activatedRoute.queryParams.pipe(
      filter(({ popup }) => !!popup),
      filter(() => !!this.storage.getItem(StorageKeys.gameName))
    ).subscribe(() => this.dialogService.open(PhoneRegDialogComponent, 'bottom-center-500', undefined, this.dialogHeight));
  }

  ngOnDestroy(): void {
    !!this.urlExtras && this.urlExtras.unsubscribe();
    !!this.depositCardPreValidationSub && this.depositCardPreValidationSub.unsubscribe();
  }

  private setRecommendedMethods(paymentMethods: Array<PaymentMethod>): void {
    if (
      this.source.modules.cashier.recommendedPaymentMethod
      && paymentMethods.find(pm => Number(pm.id) === Number(this.source.modules.cashier.recommendedPaymentMethod.id))
    ) {
      this.recommendedMethod = this.source.modules.cashier.recommendedPaymentMethod;
    }
  }

  private filterMethodsByAgeType(methods: Array<PaymentMethod>): Array<PaymentMethod> {
    const depositMethods = methods.filter((paymentMethod) => paymentMethod.operation_type === 'deposit');
    if (this.userCardsQuery.selectedCardAge === BankCardsAge.Old) {
      return depositMethods.filter((paymentMethod) =>
        paymentMethod.type_of_method === PaymentMethodAge.Old ||
        paymentMethod.type_of_method === PaymentMethodAge.Both);
    } else {
      return depositMethods.filter((paymentMethod) =>
        paymentMethod.type_of_method === PaymentMethodAge.New ||
        paymentMethod.type_of_method === PaymentMethodAge.Both
      );
    }
  }
}
