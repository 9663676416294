// @ts-nocheck

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { SpLocalization, SpNavigation } from '@libs/cdk';
import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { IButton } from '@portal/shared/components/controls';
import { ScriptService } from 'ngx-script-loader';
import { PAYSOFT_WIDGET_LINK } from './constants/paysoft-widget-link.constant';
import { PaysoftWidgetInterface } from './types/interfaces/paysoft-widget.interface';
import { BonusesData } from '@portal/bonuses/data';

@Component({
  selector: 'pd-paysoft-widget',
  templateUrl: './paysoft-widget.component.html',
  styleUrls: [
    './paysoft-widget.component.scss',
    './flat.paysoft-widget.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaysoftWidgetComponent implements OnInit {
  @Input() widgetInfo: PaysoftWidgetInterface;

  depositButton: IButton = {};
  isDisabledDepositButton = false;
  PaySoft = null;
  globalErrorBlock = null;
  widgetInputColor = '#999';
  widgetInputFontSize = '16px';
  widgetInputErrorColor = '#f8333c';

  constructor(
    private readonly scriptsLoader: ScriptService,
    private readonly cd: ChangeDetectorRef,
    private readonly bonusCommand: BonusesData,
    private readonly navigationService: SpNavigation,
    private readonly localization: SpLocalization,
    @Inject(WINDOW) private readonly window: GlobalWindow<{ PMHostedFields: (args: unknown) => void; }>
  ) {}

  ngOnInit(): void {
    const attrs = { async: 'true' };

    this.scriptsLoader.loadScript(PAYSOFT_WIDGET_LINK, attrs).subscribe(() => {
      this.PaySoft = this.window.PMHostedFields({
        params: { ...this.widgetInfo },
        lang: this.localization.currentLanguage
      });

      const style = {
        base: {
          'font-size': this.widgetInputFontSize,
          color: this.widgetInputColor
        },
        error: {
          color: this.widgetInputErrorColor
        }
      };

      const cardNumber = this.PaySoft.create('cardnumber', '#field-card-number', { style });
      const cardExpireYear = this.PaySoft.create('cardexpireyear', '#field-card-expire-year', { style });
      const cardExpireMonth = this.PaySoft.create('cardexpiremonth', '#field-card-expire-month', { style });
      const cardCVV = this.PaySoft.create('cardcvv', '#field-card-cvc', { style });

      const fields = [ cardNumber, cardExpireMonth, cardExpireYear, cardCVV ];

      fields.forEach((element) => {

        element.addEventListener('change', (event) => {
          const displayError = document.getElementById('error-' + element.parentNode.id);

          if (event.detail.error !== null) {
            displayError.textContent = event.detail.error;
            displayError.style.display = 'block';
          } else {
            displayError.textContent = '';
            displayError.style.display = 'none';
          }
        });
      });
    }, () => {
      console.log('PAYSOFT SCRIPT NOT DOWNLOAD');
    });
  }

  createPay(): void {
    this.isDisabledDepositButton = true;
    this.depositButton.loading = true;
    this.globalErrorBlock = document.getElementById('error-pay');
    this.globalErrorBlock.style.display = 'none';

    this.PaySoft.Pay((result) => {
      this.isDisabledDepositButton = false;
      this.depositButton.loading = false;

      if (result.status !== 'success') {
        this.globalErrorBlock.textContent = result.message;
        this.globalErrorBlock.style.display = 'block';
      } else {
        this.bonusCommand.synchronize();
        this.navigationService.navigate([ 'cashier', 'deposit', 'transaction', 'success' ]);
      }

      this.cd.detectChanges();
    });
  }
}
