import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData, OperatorDetails } from '@portal/shared/components/controls/interfaces';
import { OperatorsService } from '@portal/shared/components/controls/services/operators.service';

@Component({
  selector: 'gg-dropdown-operator-selector',
  templateUrl: './dropdown-operator-selector.component.html',
  styleUrls: [ './dropdown-operator-selector.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownOperatorSelectorComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownOperatorSelectorComponent extends InputAbstract implements OnInit {
  readonly operator_id = new FormControl();

  operatorList: Array<IInputSelectData> = [];
  defaultItem: Nullable<IInputSelectData>;

  @Input() paymentMethodId!: Nullable<number>;
  @Output() operatorChanged = new EventEmitter();

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly operatorsService: OperatorsService,
    private readonly localization: SpLocalization
  ) { super(); }

  override ngOnInit(): void {
    super.ngOnInit();

    this.operatorsService.getOperatorList(this.paymentMethodId?.toString()).subscribe((operators: Array<OperatorDetails>) => {
      this.operatorList = this.mapOperatorToSelectOption(operators);
      this.defaultItem = this.getDefaultOperator(operators[0].operator_id);
      this.defaultItem ? this.writeValue(this.defaultItem.value) : this.writeValue('');
      this.operatorChanged.emit(operators);
      this.cd.detectChanges();
    });

    this.operator_id.valueChanges.subscribe((value) => {
      this.operatorChanged.emit(value);
      this.writeValue(value);
    });
  }

  getDefaultOperator(defaultOperatorId: string): Nullable<IInputSelectData> {
    return this.operatorList.find(operator => operator.value === defaultOperatorId);
  }

  private mapOperatorToSelectOption(operator: Array<OperatorDetails>): Array<IInputSelectData>;
  private mapOperatorToSelectOption(operator: OperatorDetails): IInputSelectData;
  private mapOperatorToSelectOption(operator: Array<OperatorDetails> | OperatorDetails): Array<IInputSelectData> | IInputSelectData {
    if (Array.isArray(operator)) {
      return operator.map((b) => this.mapOperatorToSelectOption(b));
    } else {
      return {
        value: operator.operator_id,
        description: this.localization.currentLanguage === 'en' ? operator.name : operator.name_rus,
        icon: operator.src
      };
    }
  }
}
