import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { validationRules } from '@portal/shared/constants';
import { ErrorManager, ErrorResponse } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import { UserData } from '@portal/user/data';
import { createChangePasswordForm } from '@portal/user/shared';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'gg-user-settings-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [ './change-password.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ErrorManager ]
})
export class ChangePasswordComponent implements OnDestroy {
  private requestCompletedSubs: Subscription = new Subscription();
  private requestNewPasswordSubs: Subscription = new Subscription();
  private readonly passwordExistSubs: Subscription;

  private readonly errorManager = inject(ErrorManager);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly userSettingsCommands = inject(UserData);
  private readonly isComplicatedPassword = inject(ConfigQuery).modules.forms.isComplicatedPassword;
  private readonly notifications = inject(SpNotification);

  readonly formShown$: BehaviorSubject<string> = new BehaviorSubject('visible');
  readonly saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };
  readonly okButton: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Small };
  readonly passwordExists$ = this.userInfoQuery.passwordExists$;
  readonly errors: ErrorManager;
  readonly form: FormGroup = createChangePasswordForm();

  constructor() {
    this.errors = this.errorManager.setUp({
      form: this.form,
      actions: { server: (status: boolean) => !status && this.formShown$.next('visible') }
    });

    if (this.isComplicatedPassword) {
      this.form.get('newPassword')?.setValidators(validationRules.complicatedPassword);
      this.form.get('confirmPassword')?.setValidators(validationRules.complicatedPassword);
    }

    this.passwordExistSubs = this.passwordExists$.subscribe((exist) => {
      !exist && this.form.removeControl('oldPassword');
    });
  }

  save(): void {
    if (!this.form.valid) return;

    this.requestCompletedSubs = this.userSettingsCommands.updatePassword(this.form.value).subscribe({
      next: () => {
        this.notifications.success('NOTIFICATIONS.MESSAGES.SETTINGS_PASSWORD_CHANGED');
        this.formShown$.next('hidden');
      },
      error: (err: ErrorResponse) => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.SETTINGS_PASSWORD_CHANGE_FAIL');
        this.errors.setServerErrors(err);
      }
    });
  }

  saveNewPassword(): void {
    if (this.form.invalid) return;

    this.requestNewPasswordSubs = this.userSettingsCommands.createPassword(this.form.value.newPassword).subscribe({
      next: () => {
        this.notifications.success('NOTIFICATIONS.MESSAGES.SETTINGS_PASSWORD_CHANGED');
        this.formShown$.next('hidden');
      },
      error: (err: ErrorResponse) => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.SETTINGS_PASSWORD_CHANGE_FAIL');
        this.errors.setServerErrors(err);
      }
    });
  }

  reset(): void {
    this.form.reset({ password: '', password_new: '', password_confirm: '' });
    this.formShown$.next('visible');
  }

  ngOnDestroy(): void {
    this.requestCompletedSubs?.unsubscribe();
    this.requestNewPasswordSubs?.unsubscribe();
    this.passwordExistSubs?.unsubscribe();
  }
}
