import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationQuery } from '@portal/verification/data';
import { Nullable } from '@libs/utils';
import { VerificationSectionType } from '@portal/verification/shared/enums';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-kycaid-verification',
  templateUrl: './kycaid-verification.component.html',
  styleUrls: ['./kycaid-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycaidVerificationComponent {
  private readonly verificationQuery = inject(VerificationQuery);
  private readonly userInfoQuery = inject(UserQuery);

  readonly currentTab: typeof VerificationSectionType = VerificationSectionType;

  currentKycaidStatus$ = this.userInfoQuery.kycStatus$;
  qrCode$: Observable<Nullable<string>> = this.verificationQuery.qrCode$;
}
