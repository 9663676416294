import { Nullable } from '@libs/utils';
import { ArticleSystemInfo, ArticleTimingInfo } from '../core/partials';
import { ArticleDto } from '../dto';

export class PromoArticle {

  readonly type = 'promo';

  constructor(
    readonly id: string,
    readonly slug: string,
    readonly content: ArticleDto['i18n'],
    readonly label: Nullable<string>,
    readonly system: ArticleSystemInfo,
    readonly timing: Nullable<ArticleTimingInfo>
  ) {}
}
