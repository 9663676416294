import { inject, Injectable } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { ConfigModules, ConfigQuery } from '@portal/config';

@Injectable({ providedIn: 'root' })
export class AuthorizationQuery {
  private readonly config = inject(ConfigQuery);
  private readonly authorization = inject(SpAuthorizationService);

  readonly authorized$ = this.authorization.authorized$;

  get phoneVerificationMethod(): ConfigModules['authentication']['phoneVerificationMethod'] {
    return this.config.modules.authentication.phoneVerificationMethod;
  }

  get registrationMethods(): ConfigModules['authentication']['registrationMethods'] {
    return this.config.modules.authentication.registrationMethods.sort((a) => Number(a.isDefault) && -1);
  }

  get authorized(): boolean {return this.authorization.authorized; }
}
