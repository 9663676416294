import { NgModule } from '@angular/core';
import { AccordionModule } from '@portal/shared/components/content/accordion';
import { CountdownComponent } from '@portal/shared/components/content/countdown';
import { DynamicComponentDirective } from '@portal/shared/components/content/dynamic';
import { FlipComponent } from '@portal/shared/components/content/flip';
import { InlineLoaderComponent } from '@portal/shared/components/content/inline-loader';
import { NotificationBulletComponent } from '@portal/shared/components/content/notification-bullet';
import { PaginatorModule } from '@portal/shared/components/content/paginator';
import { StepperModule } from '@portal/shared/components/content/stepper';
import { SwiperModule } from '@portal/shared/components/content/swiper';
import { TimerComponent } from '@portal/shared/components/content/timer';
import { TooltipModule } from '@portal/shared/components/content/tooltip';

const reexport = [
  InlineLoaderComponent, TooltipModule, AccordionModule, PaginatorModule, SwiperModule,
  TimerComponent, FlipComponent, CountdownComponent, StepperModule, DynamicComponentDirective, NotificationBulletComponent
];

@NgModule({ imports: reexport, exports: reexport })
export class ContentModule {}
