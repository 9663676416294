// @ts-nocheck

import { Component, Input } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { HISTORY_NAVIGATION } from '@portal/cashier/constants/history-navigation.constant';
import { HistorySections } from '@portal/cashier/data';

@Component({
  selector: 'pd-history-menu',
  templateUrl: './history-menu.component.html'
})
export class HistoryMenuComponent {
  @Input() active: HistorySections;

  constructor(private readonly navigationService: SpNavigation) {}

  readonly navigation = HISTORY_NAVIGATION;

  nav(section: string): void {
    this.navigationService.navigate([ '/cashier', 'history', section ]);
  }

}
