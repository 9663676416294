import { Directive, HostListener, inject } from '@angular/core';
import { PaymentService } from '@portal/payment/shared';

@Directive({ selector: '[ggOpenPayments]', standalone: true })
export class OpenPaymentsDirective {
  private readonly paymentService = inject(PaymentService);

  @HostListener('click') openPaymentsDialog(): void {
    this.paymentService.openPaymentsDialog();
  }
}
