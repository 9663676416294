<ng-container *spLet="appState$ | spAsync as state">
  @if (state === 'error') {
    <router-outlet/>
  } @else {
    <div [hidden]="state === 'hidden'">
      <gg-banner-socket page="all" section="top"/>
      <gg-user-email-confirmation-plug/>
      <gg-layout-header/>
      <div class="app-wrapper__body"><router-outlet/></div>
      <gg-seo-text/>
      <gg-layout-breadcrumbs/>
      <gg-layout-footer/>
    </div>
  }

  <gg-layout-sidebar/>
  <gg-layout-toolbar [hidden]="state === 'hidden'"/>
  <sp-simple-notifications/>
</ng-container>
