<section ggCreateThemeClass="in-progress">
  <section class="in-progress">
    <div class="in-progress__title-wrapper">
      <div class="in-progress__title" *ngIf="isFlashCallOption else isNotFlashCallOption">
        <sp-msg msg="PROFILE.CONTACT_DETAILS.ENTER_FLASHCALL_CODE"></sp-msg>
      </div>
      <ng-template #isNotFlashCallOption class="in-progress__title">
        <sp-msg class="in-progress__title" msg="PROFILE.CONTACT_DETAILS.ENTER_SMS_CODE"></sp-msg>
      </ng-template>

      <div class="in-progress__tools">
        <div class="in-progress__description">
          <div class="in-progress__info-text">
            <div class="in-progress__info-text_phone">
              {{ mobilePhoneForm.get('phone')?.value }}
              <img alt="" [src]="'images/icons/{palette}/edit.svg' | path" (click)="changeNumber()">
            </div>
          </div>

          <ng-container [ngSwitch]="currentState$| async">
            <div class="in-progress__info-text" *ngSwitchCase="PhoneVerificationLayoutState.CodeResent">
              <sp-msg msg="PROFILE.CONTACT_DETAILS.RECALL_MESSAGE"></sp-msg>
              <span class="in-progress__info-timer">
                <gg-countdown [countdownTime]="59000" (timeExit)="timeExit()"/>
                <sp-msg msg="COMMON.SECONDS"></sp-msg>
              </span>
            </div>

            <div class="in-progress__info-text"
                 *ngSwitchCase="PhoneVerificationLayoutState.CodeSent"
                 (click)="resendCode()">
              <span>
                <sp-msg msg="PROFILE.CONTACT_DETAILS.CODE_NOT_RECEIVED"></sp-msg>
              </span>
              <span class="in-progress__changer in-progress__changer_block">
                <sp-msg msg="PROFILE.CONTACT_DETAILS.RECALL"></sp-msg>
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <form [formGroup]="confirmationPhoneCodeForm">
        <gg-input
          class="in-progress__phone-input"
          formControlName="code"
          [placeholder]="'COMMON.CODE' | translate"
          [errors]="confirmationPhoneCodeErrors.get('code') | async"
        ></gg-input>

        <section class="in-progress__captcha-section" *hasRecaptcha="'profilePhone'">
          <re-captcha formControlName="recaptchaToken"></re-captcha>
          <gg-input-error [errors]="confirmationPhoneCodeErrors.get('recaptcha_token') | async"></gg-input-error>
        </section>
      </form>
    </div>

    <gg-button
      class="in-progress__button"
      [fullWidth]="true"
      [settings]="saveButton"
      [disabled]="!confirmationPhoneCodeForm.valid"
      (buttonClicked)="verifyPhoneCode()"
    >
      <sp-msg msg="PROFILE.CONTACT_DETAILS.APPROVE"></sp-msg>
    </gg-button>
  </section>
</section>
