import { TDocuments } from './documents.enum';
import {IDocuments} from './documents.interface';

export const DEFAULT_DOCUMENTS: Array<IDocuments> = [
  {
    description: 'DOCUMENTS.RUS_PASSPORT',
    value: TDocuments.PassportInternal
  },
  {
    description: 'DOCUMENTS.INTERNATIONAL_PASSPORT',
    value: TDocuments.PassportForeign
  },
  {
    description: 'DOCUMENTS.FOREIGN_PASSPORT',
    value: TDocuments.PassportForeignCitizenship
  }
];

