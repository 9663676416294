import { inject, Injectable } from '@angular/core';
import { SpStorage } from '@libs/cdk';

import { Nullable, OrArray } from '@libs/utils';
import { LOCATION } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { SP_LANGUAGES_CONFIG, SpLocalizationConfig } from './config';
import { Language, Languages, Translation } from './language';

@Injectable()
export class SpLocalization {
  private readonly storageKey = 'sp-current-locale';

  private readonly location = inject(LOCATION);
  private readonly storage = inject(SpStorage);
  private readonly ngxTranslate = inject(TranslateService);

  private config = inject(SP_LANGUAGES_CONFIG, { optional: true });

  get list(): Languages { return this.ngxTranslate.getLangs() || []; }

  get defaultLanguage(): Nullable<Language> { return this.config?.default; }

  get currentLanguage(): Language { return this.ngxTranslate.currentLang; }

  get currentTranslation(): Translation { return this.ngxTranslate.translations[ this.currentLanguage ]; }

  get isDefaultSelected(): boolean { return this.currentLanguage === this.defaultLanguage; }

  get languageFromBrowser(): Nullable<Language> {
    const language = this.ngxTranslate.getBrowserLang();
    return this.isValidLanguage(language) ? language : null;
  }

  get languageFromPath(): Nullable<Language> {
    const [ language ] = this.location.pathname.substring(1).split('/');
    return this.isValidLanguage(language) ? language : null;
  }

  get languageFromStorage(): Nullable<Language> {
    const language = this.storage.getItem(this.storageKey);
    return this.isValidLanguage(language) ? language : null;
  }

  constructor() {
    this.config && this.setupLanguages(this.config);
  }

  isValidLanguage(language: Nullable<string>): language is Language { return !!language && this.list.includes(language); }

  setupLanguages(config: SpLocalizationConfig['languages']): void {
    if (!config) { return; }

    this.config = { ...this.config, ...config };

    this.config?.list && this.ngxTranslate.addLangs(this.config.list);
    this.setLanguage(this.languageFromPath || this.languageFromStorage || this.languageFromBrowser || this.config?.default);
  }

  setLanguage(language: Nullable<Language>): void {
    if (!this.isValidLanguage(language)) { return; }
    this.currentLanguage && this.currentLanguage !== language ? this.redirectWithNewBaseHref(language) : this.useLanguage(language);
  }

  translate(key: OrArray<string>, interpolate?: object): string { return this.ngxTranslate.instant(key, interpolate); }

  private redirectWithNewBaseHref(language: Nullable<Language>): void {
    const [ lang, ...path ] = this.location.pathname.split('/').filter(Boolean);
    const segments = this.list.includes(lang) ? path : [ lang, ...path ];
    const url = language === this.defaultLanguage ? segments : [ language, ...segments ];
    const search = this.location.search || '';

    language && this.storage.setItem(this.storageKey, language);
    this.location.href = `${url.join('/')}${search}`;
  }

  private useLanguage(language: Nullable<Language>): void {
    language && this.ngxTranslate.use(language).pipe(first()).subscribe(() =>
      this.storage.setItem(this.storageKey, language)
    );
  }
}
