import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { BannerComponent } from '@portal/banners/shared';

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-banners-rectangle',
  templateUrl: './rectangle-banner.component.html',
  styleUrls: [ './rectangle-banner.component.scss' ],
  // encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RectangleBannerComponent extends BannerComponent {

  get isLink(): boolean { return Boolean(this.banner?.settings.url); }
}
