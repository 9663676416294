import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager, ErrorResponse } from '@portal/shared/helpers';
import { UserData } from '@portal/user/data';
import { ContactDetailsService } from '@portal/user/features/settings/contact-details/contact-details.service';
import { PhoneVerificationState } from '@portal/user/shared';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'gg-user-settings-contact-details-phone-verification-in-progress',
  templateUrl: './phone-in-progress.component.html',
  styleUrls: [
    './phone-in-progress.component.scss',
    './purple.phone-in-progress.component.scss',
    './silver.phone-in-progress.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInProgressComponent implements OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  private readonly contactDetailsService = inject(ContactDetailsService);
  private readonly userSettingsCommands = inject(UserData);
  private readonly notifications = inject(SpNotification);

  readonly PhoneVerificationLayoutState = PhoneVerificationState;
  confirmationPhoneCodeForm: FormGroup;
  confirmationPhoneCodeErrors: ErrorManager;
  mobilePhoneForm: FormGroup;
  currentState$: Observable<PhoneVerificationState>;

  @Input() isFlashCallOption: boolean = false;
  @Input() saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };

  constructor() {
    this.confirmationPhoneCodeForm = this.contactDetailsService.confirmationCodeForm;
    this.confirmationPhoneCodeErrors = this.contactDetailsService.confirmationCodeErrors;
    this.mobilePhoneForm = this.contactDetailsService.mobilePhoneForm;
    this.currentState$ = this.contactDetailsService.phoneVerificationState$;
  }

  ngOnDestroy(): void {
    !!this.subscriptions && this.subscriptions.unsubscribe();
  }

  changeNumber(): void {
    this.contactDetailsService.updateVerificationState(PhoneVerificationState.NotVerified);
  }

  resendCode(): void {
    this.contactDetailsService.verifyPhone();
  }

  verifyPhoneCode(): void {
    const userSettingsCommands: Subscription = this.userSettingsCommands.verifyCode(this.confirmationPhoneCodeForm.value).subscribe({
      next: () => {
        this.contactDetailsService.updateVerificationState(PhoneVerificationState.Verified);
        this.notifications.success('NOTIFICATIONS.MESSAGES.CONTACT_DETAILS_PHONE_VERIFIED');
      },
      error: (err: ErrorResponse) => {
        this.confirmationPhoneCodeErrors.setServerErrors(err);
        this.notifications.error('NOTIFICATIONS.MESSAGES.CONTACT_DETAILS_WRONG_CODE');
      }
    });
    this.subscriptions.add(userSettingsCommands);
  }

  timeExit(): void {
    this.contactDetailsService.updateVerificationState(PhoneVerificationState.CodeSent);
  }
}
