<section class="field" ggCreateThemeClass="field">
  <div class="{{ additionalClass }} placeholder__wrap password">
    <input
      [type]="inputType"
      [disabled]="disabled"
      class="password__input field__input"
      [(ngModel)]="value"
      [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value}"
      #input
    />
    <span class="placeholder__text">{{placeholder}}</span>

    <img class="icon" *ngIf="icon" src="assets/images/icons/password.svg" alt=""/>

    <span (click)="togglePassword()">
      <ng-container *ngIf="eyeIcon === 'eye' else hiddenIcon">
        <img [src]="'{project}/images/icons/password-eye.svg' | path" class="icon icon__eye" alt=""/>
      </ng-container>
      <ng-template #hiddenIcon>
        <img [src]="'{project}/images/icons/password-eye-close.svg' | path" class="icon icon__eye" alt=""/>
      </ng-template>
    </span>


  </div>
  <p *ngIf="isComplicatedPassword && showInfoText; else errorBlock" class="password__text" [ngClass]="{ 'error': errors && !!errors.length}">
    <sp-msg msg="AUTH.PASSWORD_TEXT"></sp-msg>
  </p>
  <ng-template #errorBlock>
    <gg-input-error [errors]="isComplicatedPassword ? filterStrong(errors) : errors"/>
  </ng-template>
</section>
