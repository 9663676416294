// @ts-nocheck

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CashierService } from '@portal/cashier/cashier.service';
import { SAVE_CARDS_METHODS } from '@portal/cashier/components/card/constants/save-cards-methods.constant';
import { Account, CashierDataService } from '@portal/cashier/data';
import { Observable, of } from 'rxjs';

@Injectable()
export class CardsResolver implements Resolve<Array<Account>> {
  constructor(
    private readonly cashierService: CashierService,
    private readonly cashierCommands: CashierDataService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Array<Account>> {
    const method = Number(route.params.method);
    if (!this.hasCardPresets(method)) { return of(null); }

    return this.cashierCommands.loadAccounts(method);
  }

  hasCardPresets(method: number): boolean {
    return SAVE_CARDS_METHODS.includes(Number(method));
  }
}
