import { NavigationEnd } from '@angular/router';
import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { LoaderAnalyticsHandler } from './abstract.handler';

type GAWindow = { dataLayer: Array<unknown> };

export class GaHandler extends LoaderAnalyticsHandler {
  private readonly userId = this.injector.get(UserQuery).id;
  private readonly id = this.injector.get(ConfigQuery).modules.analytics.trackers.ga4Id;
  private readonly window = this.injector.get<GlobalWindow<GAWindow>>(WINDOW);

  override create(): void {
    this.loadScript(`https://www.googletagmanager.com/gtag/js?id=${this.id}`, () => {
      this.window.dataLayer?.push([ 'js', new Date() ], [ 'config', this.id, this.userId ? { user_id: this.userId } : undefined ]);
    });
  }

  override onNavigationEnds({ urlAfterRedirects }: NavigationEnd): void {
    this.window.dataLayer?.push([ 'config', this.id, { page_path: urlAfterRedirects } ]);
  }
}
