import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CurrenciesRepository, ExchangeRatesDto } from '@portal/currencies/data';
import { filter, map, Observable, tap } from 'rxjs';

type ExchangeRates = { _item: ExchangeRatesDto };

export class SelectExchangeRates {
  private readonly http = inject(HttpClient);
  private readonly repo = inject(CurrenciesRepository);

  receive(): Observable<ExchangeRatesDto> {
    return this.repo.steroid.query.select('rates').pipe(
      tap((rates) => !rates && this.loadRates()), filter(Boolean),
    );
  }

  private loadRates(): void {
    this.http.get<ExchangeRates>('/api/2/currencies/rates/').pipe(
      map((rates) => rates._item),
    ).subscribe((rates) => this.repo.update({ rates }));
  }
}
