<ng-container *spLet="isExpanded$ | async as expanded">
  <div class="bonus-welcome" *ngIf="container$ | async as container" ggCreateThemeClass="bonus-welcome">
    <h4 class="bonus-welcome__title" spMsg="BONUSES.WELCOME_BONUS"></h4>
    <section class="bonus-welcome-pack">
      <div class="bonus-welcome-pack-info">
        <img src="/assets/images/bonuses/welcome-bonus/{{theme}}/mobile/decor.svg" class="bonus-welcome-pack-info__banner-mobile" alt="banner"/>
        <img src="/assets/images/bonuses/welcome-bonus/{{theme}}/decor.svg" class="bonus-welcome-pack-info__banner-desktop" alt="banner"/>
        <div class="bonus-welcome-pack-info__label">
          <div spMsg="BONUSES.WELCOME_PACK.ZAZA"></div>
        </div>
        <div class="bonus-welcome-pack-info__title"
             [innerHTML]="container.information?.title[language] | fallback : '' | cleanAndReplace">
        </div>
        <div class="bonus-welcome-pack-info__description" [ngClass]="{'limitTextHeight': expanded}">
          <gg-bonus-card-description [bonus]="container"></gg-bonus-card-description>
        </div>

        <button type="button" class="text-interaction" (click)="showText()">
          <sp-msg [msg]="expanded ? 'COMMON.READ_MORE' : 'COMMON.READ_LESS'"></sp-msg>
        </button>
      </div>

      <div class="bonus-welcome-pack-deposit-bonus">
        <div class="bonus-welcome-pack-deposit-bonus__icons">
          <gg-bonus-card-icon [bonus]="container"/>
        </div>
        <div class="bonus-welcome-pack-deposit-bonus__bonuses">
          <div *ngFor="let bonus of container.bonuses; let i = index">
            <h4 *ngIf="!i" class="label">
              1
              <sup spMsg="BONUSES.WELCOME_PACK.FIRST" spaces="end"></sup>
              <sp-msg msg="BONUSES.BONUS"/>
            </h4>
            <h4 class="title"><span *ngIf="i">+ </span>{{ bonus | bonusTitle }}</h4>
            <gg-bonus-card-game [bonus]="bonus"/>
            <hr/>
          </div>
          <gg-bonus-card-activation [bonus]="container"/>
          <gg-bonus-card-max-win isLinkedBonus="true"/>
          <gg-bonus-card-wager-info [bonus]="container"/>
        </div>

        <gg-bonus-card-expiration-timer class="bonus-welcome-pack-deposit-bonus__timer" [bonus]="container" (expired)="activate(container, 'expire')"/>
        <gg-bonus-card-cta [bonus]="container" (action)="activate(container, 'activate')" ggOpenPayments/>
      </div>
    </section>
  </div>
</ng-container>
