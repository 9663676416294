<div class="history-block">
  <div class="history-block__header">
    <div class="history-block__type" *spLet="operation?.info.operation as operationType" [ngClass]="operationType">
      <img class="history-block__img history-block__img--{{operationType}}" [src]="'{project}/images/cashier/arrow-' + operationType +'.svg' | path" [alt]="operationType"/>
      <sp-msg [spMsg]="'CASHIER.HISTORY.TRANSACTION.' + operationType.toUpperCase()"></sp-msg>
    </div>
    <div class="history-block__date">{{ date }}</div>
  </div>
    <ng-container *ngIf="isCancelling$ | spAsync else default">
      <div class="history-block__cancel-question" spMsg="CASHIER.HISTORY.TRANSACTION.APPROVE_CANCEL"></div>
      <div class="history-block__cancel-buttons">
        <gg-button class="history-block__cancel-buttons--yes" [settings]="yesButton" (click)="cancelTransaction()">
          <sp-msg spMsg="COMMON.YES"></sp-msg>
        </gg-button>
        <gg-button [settings]="noButton" (click)="setCancelState(false)">
          <sp-msg spMsg="COMMON.NO"></sp-msg>
        </gg-button>
      </div>
    </ng-container>
    <ng-template #default>
      <div class="history-block__content">
        <div class="history-block__amount">{{ amount }}</div>
        <ng-container *ngIf="extended">
          <div class="history-block__card-info" *spLet="operation?.payment as payment">
            <div class="history-block__method">{{ payment.method }}</div>
            <div class="history-block__card">{{ payment?.account }}</div>
          </div>
        </ng-container>
        <div class='history-block__card-action'>
          <div class="history-block__status history-block__status--{{operation?.status}}" *ngIf="operation?.status">
            <sp-msg [spMsg]="'CASHIER.HISTORY.TRANSACTION.STATUSES.' + operation?.status.toUpperCase()"/>
          </div>
          <div class="history-block__declined-info" *ngIf="operation?.status === 'declined' && operation?.info.reason">{{ operation?.info.reason }}</div>
          <p *ngIf="canBeCancelled" class="history-block__cancel-button" (click)="setCancelState(true)" spMsg="CASHIER.HISTORY.TRANSACTION.CANCEL"></p>
        </div>
      </div>
    </ng-template>
</div>
