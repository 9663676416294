<section ggCreateThemeClass="confirmation-email">
    <section class="confirmation-email" [class.confirmation-email_only-email]="isOnlyEmailConfirmation">
        <div class="confirmation-email__title">
            <sp-msg msg="PROFILE.CONTACT_DETAILS.EMAIL"></sp-msg>
        </div>
        <ng-container *ngIf="user.email else noMail">
            @if(isChangeEmailMode) {
            <form [formGroup]="emailForm">
                <gg-input [noLabelPlaceholder]="true" class="confirmation-email__input" formControlName="email"
                          [errors]="emailErrors.get('email') | async"></gg-input>
                <section class="confirmation-email__captcha-section" *ggHasRecaptcha="'profileEmail'">
                    <re-captcha formControlName="recaptchaToken"></re-captcha>
                    <gg-input-error [errors]="emailErrors.get('recaptchaToken') | async"></gg-input-error>
                </section>
            </form>
            <gg-button
                    class="confirmation-email__button"
                    [fullWidth]="true"
                    [disabled]="!emailForm.valid"
                    [settings]="saveButton"
                    (buttonClicked)="changeEmail()">
                <sp-msg msg="PROFILE.CONTACT_DETAILS.SAVE"></sp-msg>
            </gg-button>
            } @else {
                <div class="confirmation-email__value">{{ user.email }}</div>
                            <div class="confirmation-email__verified">
                <div
                  *ngIf="user.emailConfirmed else notConfirmed"
                  class="confirmation-email__verified-wrapper"
                >
                    <img [src]="'images/icons/{palette}/check-email.svg' | path" class="confirmation-email__icon"
                         alt=""/>
                    <span class="confirmation-email__check">
            <sp-msg msg="PROFILE.CONTACT_DETAILS.APPROVED"></sp-msg>
          </span>
                </div>
                <ng-template #notConfirmed>
                    <div class="confirmation-email__not-verified-wrapper">
                        <img [src]="'images/icons/{palette}/not.svg' | path" class="confirmation-email__icon" alt=""/>
                        <span class="confirmation-email__check confirmation-email__check_not">
                          <sp-msg msg="PROFILE.CONTACT_DETAILS.NOT_APPROVED"></sp-msg>
                        </span>
                    </div>

                    <p class="confirmation-email__change-email"
                       [class.hidden]="hiddenChangeEmail"
                       (click)="showEmailForm()">
                      <sp-msg msg="CONFIRM_EMAIL.CHANGE_EMAIL"></sp-msg>
                    </p>

                    <p class="confirmation-email__verified-confirm" (click)="redirectOn(user.email)">
                        <sp-msg msg="PROFILE.CONTACT_DETAILS.CONFIRM"></sp-msg>
                    </p>
                </ng-template>
            </div>
            }
        </ng-container>
        <ng-template #noMail>
            <form [formGroup]="emailForm">
                <gg-input [noLabelPlaceholder]="true" class="confirmation-email__input" formControlName="email"
                          [errors]="emailErrors.get('email') | async"></gg-input>
                <section class="confirmation-email__captcha-section" *ggHasRecaptcha="'profileEmail'">
                    <re-captcha formControlName="recaptchaToken"></re-captcha>
                    <gg-input-error [errors]="emailErrors.get('recaptchaToken') | async"></gg-input-error>
                </section>
                <gg-button
                  *ngIf="!user.email"
                  [disabled]="!emailForm.valid"
                  [settings]="saveButton"
                  [fullWidth]="true"
                  class="confirmation-email__button"
                  (buttonClicked)="saveEmail()"
                >
                    <sp-msg msg="PROFILE.CONTACT_DETAILS.SAVE"></sp-msg>
                </gg-button>
            </form>
        </ng-template>
    </section>
</section>
