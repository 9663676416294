import { Component, ChangeDetectionStrategy, OnDestroy, inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, Subscription } from 'rxjs';
import { VerificationData, VerificationQuery } from '@portal/verification/data';
import { Nullable } from '@libs/utils';
import { ErrorModalComponent } from '@portal/verification/components/kyc/error-modal/error-modal.component';
import { Dialog } from '@angular/cdk/dialog';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { VerificationMethod } from 'apps/portal/src/app/verification/data/core';
import { KycaidVerificationSendMethodType } from 'apps/portal/src/app/verification/data/dto';
import { KycaidVerification } from 'apps/portal/src/app/verification/data/entities';

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: [ './identity-verification.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdentityVerificationComponent implements OnDestroy {
  private readonly window = inject(WINDOW);
  private readonly dialog = inject(Dialog);
  private readonly platform = inject(SpPlatform);
  private readonly verificationQuery = inject(VerificationQuery);
  private readonly verificationCommands = inject(VerificationData);

  readonly kycVerificationMethods: Array<Nullable<VerificationMethod>> = this.verificationQuery.kycVerificationMethods;

  loading$: Observable<Nullable<boolean>> = this.verificationQuery.loading$;
  methodIndex: Nullable<number> = null;
  verificationCommandsSubscription: Subscription = new Subscription();
  verificationErrorSubscription: Subscription = new Subscription();

  selectMethod(methodType: string, index: number): void {
    if (this.methodIndex) {
      this.methodIndex = null;

      return;
    }

    const data: KycaidVerificationSendMethodType = {
      type: methodType,
      data: {
        redirectURL: `${this.window.location.origin}/user/verification/kyc`
      }
    };

    this.verificationCommandsSubscription = this.verificationCommands.sendKycaidVerification(data).subscribe({
      next: (response: KycaidVerification) => {
        if (this.platform.type.desktop && response.qrCode) {
          return;
        }

        this.window.location.href = response.redirectURL;
      },
      error: () => this.verificationErrorSubscription = this.verificationQuery.errorType$.subscribe(error => {
        this.dialog.open(ErrorModalComponent, { panelClass: 'bottom-center', data: error });
      })
    });

    this.methodIndex = index;
  }

  ngOnDestroy(): void {
    !!this.verificationCommandsSubscription && this.verificationCommandsSubscription.unsubscribe();
    !!this.verificationErrorSubscription && this.verificationErrorSubscription.unsubscribe();
  }
}
