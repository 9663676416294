import { ModuleWithProviders, NgModule } from '@angular/core';
import { LOCAL_STORAGE, SESSION_STORAGE } from '@ng-web-apis/common';
import { checkWebApiAvailability } from './availability.util';
import { SpMemoryStorage } from './memory-storage';
import { SpStorage } from './storage';
import { SpWebApiStorage } from './web-api-storage';

@NgModule()
export class SpStorageModule {
  static local(): ModuleWithProviders<SpStorageModule> {
    return {
      ngModule: SpStorageModule,
      providers: [ { provide: SpStorage, useClass: SpWebApiStorage, deps: [ LOCAL_STORAGE ] } ]
    };
  }

  static session(): ModuleWithProviders<SpStorageModule> {
    return {
      ngModule: SpStorageModule,
      providers: [ { provide: SpStorage, useClass: SpWebApiStorage, deps: [ SESSION_STORAGE ] } ]
    };
  }

  static memory(): ModuleWithProviders<SpStorageModule> {
    return {
      ngModule: SpStorageModule,
      providers: [ { provide: SpStorage, useClass: SpMemoryStorage } ]
    };
  }

  static available(): ModuleWithProviders<SpStorageModule> {
    if (typeof window !== 'undefined') {
      if (checkWebApiAvailability(window.localStorage)) { return SpStorageModule.local(); }
      if (checkWebApiAvailability(window.sessionStorage)) { return SpStorageModule.session(); }
    }

    return SpStorageModule.memory();
  }
}
