import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class SpTruncatePipe implements PipeTransform {
  transform(value: string | number, limit: number = 10, trail = '...'): string {
    if (typeof value === 'number' || !value || value.length <= limit) { return String(value); }

    return value.substring(0, limit - trail.length).replace(/\s+$/, '') + trail;
  }
}
