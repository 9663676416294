import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { LayoutState, LayoutStore } from '../layout.store';

@Injectable({ providedIn: 'root' })
export class LayoutQuery extends SteroidQuery<LayoutState> {
  readonly appState$ = this.select('app');
  readonly loading$ = this.select('loading');
  readonly sidebar$ = this.select('sidebar');

  constructor(store: LayoutStore) {super(store);}
}
