import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { PaginatorComponent } from './paginator.component';
import { PaginatorDirective } from './paginator.directive';

@NgModule({
  imports: [ SpCDKModule ],
  declarations: [ PaginatorComponent, PaginatorDirective ],
  exports: [ PaginatorComponent, PaginatorDirective ]
})
export class PaginatorModule {}
