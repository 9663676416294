<section *ngIf="bonus" class="success-popup">
  <img src="assets/images/icons/cross2.svg" class="success-popup__close" (click)="close()" alt=""/>
  <div class="success-popup__content">
    <img class="success-popup__img" [src]="bonus | bonusIcon | path" alt="free-spin-icon">

    <h4 class="success-popup__content-title" spMsg="BONUSES.SUCCESS_TITLE"></h4>

    <div class="success-popup__content-subtitle-wrapper">
      <ng-container [ngSwitch]="bonus.type">
        <p *ngSwitchCase="'casino_freespin'" class="success-popup__content-subtitle">
          <sp-msg msg="BONUSES.SUCCESS_SUBTITLE" spaces="end"/>
          {{bonus.rounds}}
          <sp-msg [msg]="bonus.typeTranslation" spaces="wrap"/>
          <sp-msg msg="BONUSES.IN_GAME" spaces="end"/>
          {{bonus.game}}
        </p>
        <p *ngSwitchCase="'casino_reload'" class="success-popup__content-subtitle">
          <sp-msg msg="BONUSES.SUCCESS_SUBTITLE_RATE" spaces="end"/>
          {{bonus.rate}}
          <sp-msg msg="BONUSES.RELOAD_TEXT" spaces="start"/>
        </p>
      </ng-container>
    </div>

    <gg-button [settings]="backButton" (buttonClicked)="close(true)">
      <sp-msg msg="BONUSES.GO_TO_BONUSES"/>
    </gg-button>
  </div>
</section>
