<ng-container *spLet="view$ | async as view">
  <div class="email-confirmation" *ngIf="isVisible$ | async" [class.confirmed]="isConfirmed$ | async">
    <ng-container [ngSwitch]="view">
      <gg-user-email-confirmation-not-confirmed *ngSwitchCase="'notConfirmed'"></gg-user-email-confirmation-not-confirmed>
      <gg-user-email-confirmation-change-email *ngSwitchCase="'changeEmail'"></gg-user-email-confirmation-change-email>
      <gg-user-email-confirmation-resent *ngSwitchCase="'emailResent'"></gg-user-email-confirmation-resent>
      <gg-user-email-confirmation-confirmed *ngSwitchCase="'confirmed'"></gg-user-email-confirmation-confirmed>
    </ng-container>
  </div>
</ng-container>
