import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';

@Component({
  selector: 'gg-bonus-card-promo',
  templateUrl: './promo.component.html',
  styleUrls: [ 'silver.promo.component.scss', 'purple.promo.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoComponent {
  readonly language = inject(SpLocalization).currentLanguage;
  @Input() bonus: Nullable<Bonus>;

  get hasPromo(): boolean {
    if (!this.bonus || !('information' in this.bonus)) { return false; }
    const { promo } = this.bonus.information;

    return !!promo?.name
           && Boolean((promo.name[this.language] && promo.description[this.language]))
           && Boolean(this.bonus.status.is.waiting);
  }
}
