import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { VerificationState, VerificationStore } from '@portal/verification/data';
import { VerificationMethod } from '@portal/verification/data/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VerificationQuery extends SteroidQuery<VerificationState> {
  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly isKycEnable$: Observable<boolean> = this.select('methods').pipe(map((value) => value.length > 0));
  readonly loading$: Observable<Nullable<boolean>> = this.select('loading');
  readonly qrCode$: Observable<Nullable<string>> = this.select('qrCode');
  readonly errorType$: Observable<Nullable<string>> = this.select('errorType').pipe(
    map((errorType) => errorType?.issues.map((issue) => issue.code).toString()),
  );

  constructor(store: VerificationStore) { super(store); }

  get kycVerificationMethods(): Array<Nullable<VerificationMethod>> { return this.get('methods'); }
}
