import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { OpenPaymentsDirective } from '@portal/payment/features/open-payments/open-payments.directive';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { CleanAndReplacePipe } from '@portal/shared/pipes';
import { MomentModule } from 'ngx-moment';
import { ActivationComponent } from './activation/activation.component';
import { ButtonComponent } from './button/button.component';
import { DescriptionComponent } from './description/description.component';
import { GameComponent } from './game/game.component';
import { IconComponent } from './icon/icon.component';
import { MaxBetComponent } from './max-bet/max-bet.component';
import { MaxSumComponent } from './max-sum/max-sum.component';
import { MaxWinComponent } from './max-win/max-win.component';
import { MessageComponent } from './message/message.component';
import { PromoComponent } from './promo/promo.component';
import { SectionLabelComponent } from './section-label/section-label.component';
import { TimerComponent } from './timer/timer.component';
import { WagerInfoComponent } from './wager-info/wager-info.component';

const reexport = [ ActivationComponent, ButtonComponent, DescriptionComponent, GameComponent, IconComponent,
  MaxBetComponent, MaxSumComponent, MaxWinComponent, MessageComponent, PromoComponent,
  SectionLabelComponent, TimerComponent, WagerInfoComponent ];

@NgModule({
  imports: [ SpCDKModule, ControlsModule, ThemeModule, RouterLink, ContentModule, MomentModule, CurrenciesModule, BonusPipesModule, CleanAndReplacePipe, OpenPaymentsDirective ],
  declarations: reexport,
  exports: reexport,
})
export class CardPartsModule {}
