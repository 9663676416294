import { NavigationEnd } from '@angular/router';
import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { LoaderAnalyticsHandler } from './abstract.handler';

type GTMWindow = { dataLayer: Array<unknown> };

export class GtmHandler extends LoaderAnalyticsHandler {
  private readonly id = this.injector.get(ConfigQuery).modules.analytics.trackers.gtmId;
  private readonly window = this.injector.get<GlobalWindow<GTMWindow>>(WINDOW);

  override create(): void {
    this.loadScript(`https://www.googletagmanager.com/gtm.js?id=${this.id}`, () => {
      this.window.dataLayer?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    });
  }

  override onNavigationEnds({ urlAfterRedirects }: NavigationEnd): void {
    this.window.dataLayer?.push([ 'config', this.id, { page_path: urlAfterRedirects } ]);
  }
}
