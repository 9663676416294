import { ByLanguage } from '@libs/cdk';
import { MetaTag, Seo, SeoDto } from '@portal/seo';

type Meta = Record<MetaTag, ByLanguage<string>>;

export function createSeo(dto: SeoDto): Seo {
  const id = `${dto.pageId}|${dto.pageType}`;

  const meta = dto.tags.reduce((acc, tag) => ({ ...acc, ...tag }), {}) as Meta;
  const { title, description, keywords } = meta;
  const seoMeta = { title, description, keywords };

  return new Seo(id, dto.text, dto.h1, seoMeta);
}
