import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommissionRulesInterface } from '@portal/cashier/types/interfaces/commission-rules.interface';
import { CommissionInfoDialogInterface } from '@portal/cashier/types/interfaces/dialog-data.interface';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { FeeInterface } from '../../../../types/interfaces/fee.interface';

@Component({
  selector: 'pd-dynamic-commission-info',
  templateUrl: './commission-info-dialog.component.html',
  styleUrls: [ './commission-info-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CommissionInfoDialogComponent {
  readonly closeButton: IButton = { size: ButtonSizes.Large, theme: ButtonThemes.Gray };

  fee: FeeInterface;
  commission: number;
  commissionRules: CommissionRulesInterface;

  constructor(
    private readonly dialogService: Dialog,
    @Inject(DIALOG_DATA) private readonly data: CommissionInfoDialogInterface
  ) {
    this.commission = this.data.commission;
    this.commissionRules = this.data.commissionRules;
    this.fee = this.data.fee;
  }

  close(): void {
    this.dialogService.closeAll();
  }
}
