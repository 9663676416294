import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-card-cvv',
  templateUrl: './input-card-cvv.component.html',
  styleUrls: [ './input-card-cvv.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputCardCvvComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputCardCvvComponent extends InputAbstract {
  @Input() override mask = '000';
  @Input() override defaultPlaceholder = 'CVV';
}
