import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Themed } from '@portal/config';
import { HeaderContext } from '@portal/layout/shared';

@Component({
  templateUrl: './flat.component.html',
  styleUrl: './flat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderFlatComponent extends Themed<HeaderContext> {}
