<div class="verification-warning">
  <img
    class="verification-warning__img"
    src="assets/project/{{theme}}/images/cashier/verification.svg"
    alt="verification-icon"
  >
  <div class="verification-warning__navigation">
    <h4 class="verification-warning__title" msg="PROFILE.KYCAID.VERIFICATION_PAGE"></h4>
    <gg-button [routerLink]="['/user', 'verification']" class="verification-warning__btn" [fullWidth]="true">
      <sp-msg spMsg="PROFILE.KYCAID.VERIFICATION_PAGE_BUTTON"></sp-msg>
    </gg-button>
  </div>
</div>
