import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ConfigList, FooterNavigationItem, LicenseNavigationItem, PartnerNavigationItem } from '@portal/config/shared';
import { NavigationComponent, SectionComponent, SliderComponent } from '@portal/layout/components/footer';
import { LICENSES } from '@portal/layout/shared';
import { CleanAndReplacePipe } from '@portal/shared/pipes';
import moment from 'moment/moment';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, CleanAndReplacePipe, ThemeModule, NavigationComponent, SliderComponent, SectionComponent ]
})
export class FooterComponent {
  private readonly platform = inject(SpPlatform);
  private readonly configQuery = inject(ConfigQuery);
  private readonly authorization = inject(SpAuthorizationService);

  private readonly footer = this.configQuery.modules.footer;

  protected readonly authorized = this.authorization.authorized;
  protected readonly warningIcon: string = this.footer.license.ageWarningShield.toString();
  protected readonly licenses: Array<LicenseNavigationItem> = this.mapLicenses();
  protected readonly generalTerms: Array<string> = this.footer.license?.generalTerms || [];
  protected readonly rtp: Nullable<string> = this.footer.license?.rtp;
  protected readonly partner: PartnerNavigationItem = this.footer.partner;

  protected readonly paymentMethods: Array<string> = this.footer.paymentSystems || [];
  protected readonly providers: Array<string> = this.footer.providers || [];

  protected readonly navigation: ConfigList<FooterNavigationItem> = this.footer.menu;
  protected readonly security: ConfigList<FooterNavigationItem> = this.footer.security;
  protected readonly contacts: Array<FooterNavigationItem> = this.footer.contacts;

  protected readonly socials = this.configQuery.modules.contacts.social?.map((social) => {
    return { ...social, icon: `images/footer/social/${social.type}.svg` };
  }) || [];

  readonly timer$ = this.platform.browser && this.footer.timer
    ? timer(1000, 1000).pipe(map(() => moment().format('HH:mm')))
    : null;

  private mapLicenses(): Array<LicenseNavigationItem> {
    const licenses = this.footer.license.licenses;

    return LICENSES.reduce<Array<LicenseNavigationItem>>((acc, license) => {
      const licenseType = license.type as keyof typeof licenses;

      if (typeof licenses[ licenseType ] !== 'object' || Array.isArray(licenses[ licenseType ])) { return [ ...acc ]; }

      const { pageLink, iconLink } = licenses[ licenseType ] as { pageLink: string; iconLink: string };
      return [ ...acc, { ...license, link: pageLink, icon: iconLink } ];
    }, []);
  }
}
