import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { ItemComponent } from './components/item/item.component';
import { PaymentButtonComponent } from './components/payment-button/payment-button.component';
import { GridComponent } from './grid.component';

@NgModule({
  imports: [ SpCDKModule, RouterModule, ThemeModule, CurrenciesModule ],
  declarations: [ GridComponent, ItemComponent, PaymentButtonComponent ],
  exports: [ GridComponent ],
})
export class CashierGridModule {}
