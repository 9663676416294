<div [formGroup]="form" class="pd-widget">
  <h4 class="pd-widget__title">
    <sp-msg spMsg="CASHIER.CARD.ENTER_DATA"></sp-msg>
  </h4>
    <gg-input
      [isTrim]="true"
      class="pd-widget__input"
      formControlName="city"
      placeholder="{{'CASHIER.CARD.CITY' | translate}}"
      [errors]="errors.get('city') | async"
    ></gg-input>
    <gg-input
      [isTrim]="true"
      class="pd-widget__input"
      formControlName="address"
      placeholder="{{'CASHIER.CARD.ADDRESS' | translate}}"
      [errors]="errors.get('address') | async"
    ></gg-input>
  <h4 class="pd-widget__title">
    <sp-msg spMsg="CASHIER.CARD.COOSE_MESSENGER"></sp-msg>
  </h4>
    <gg-input-select
      [defaultOption]="defaultMessenger"
      [options]="messengers"
      class="pd-widget__dropdown"
      formControlName="messenger"
      placeholder="Messenger"
    ></gg-input-select>
  <gg-input
    [isTrim]="true"
    class="pd-widget__input"
    formControlName="username"
    placeholder="{{'CASHIER.CARD.NICK' | translate}}"
    [errors]="errors.get('username') | async"
  ></gg-input>
</div>
