import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { PostActionAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  templateUrl: './qr-dialog.component.html',
  styleUrl: './qr-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrDialogActionComponent extends PostActionAbstract implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly destroy$ = inject(DestroyRef);
  private ref: Nullable<DialogRef<Nullable<string>>> = null;
  readonly closeButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };

  readonly qrcode = this.result.data.qrcode;

  @ViewChild('tpl', { static: true, read: TemplateRef }) tpl: Nullable<TemplateRef<unknown>>;

  ngOnInit(): void {
    const { url, qrcode } = this.result.data;

    if (this.processErrors(!url && !qrcode) || this.processUrl(url)) { return; }
    this.createDialog();
  }

  close(): void {this.ref?.close();}

  private createDialog(): void {
    this.ref = this.tpl && this.dialog.open(this.tpl, { panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ] });
    this.ref?.closed.pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => this.setStatus({ status: 'pending' }));
  }
}
