import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { LoaderAnalyticsHandler } from './abstract.handler';

type YAWindow = { ym: any };

export class YaHandler extends LoaderAnalyticsHandler {
  private readonly id = this.injector.get(ConfigQuery).modules.analytics.trackers.yndxMetrikId;
  private readonly window = this.injector.get<GlobalWindow<YAWindow>>(WINDOW);

  override create(): void {
    this.loadScript('https://mc.yandex.ru/metrika/tag.js', () => {
      // @ts-expect-error third-party pixel lib lives its own life;
      this.window.ym = this.window.ym || function (...args: any): void {(this.window.ym.a = this.window.ym.a || []).push(args);};
      this.window.ym.l = new Date();
      this.window.ym(`${this.id}`, 'init', { clickmap: true, trackLinks: true, accurateTrackBounce: true });
    });
  }

  override onNavigationEnds(): void { !this.window.ym && this.create(); }
}
