<div class="email-confirmation__wrapper edit-email">

  <div class="email-confirmation__change-email-title">
    <img alt="edit" class="email-confirmation__edit-image" src="assets/images/email-confirmation/edit.svg"/>
    <sp-msg msg="CONFIRM_EMAIL.EDIT_EMAIL"></sp-msg>
  </div>

  <form class="email-confirmation__email-form" [formGroup]="changeEmailForm">
    <gg-input-email class="email-confirmation__email" formControlName="email" [errors]="changeEmailErrors.get('email') |
    async"></gg-input-email>
  </form>

  <gg-button class="email-confirmation__change-email-button" [settings]="actionButtonConfig" (buttonClicked)="changeEmail()">
    <sp-msg msg="COMMON.SAVE"></sp-msg>
  </gg-button>

</div>

