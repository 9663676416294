export const BREADCRUMBS_MAP: Record<string, string> = {
  all: 'All',
  sports: 'COMMON.SECTIONS.SPORTS',
  poker: 'COMMON.SECTIONS.POKER',
  games: 'COMMON.SECTIONS.CASINO',
  nt: 'NetEnt',
  redtiger: 'Red Tiger',
  yg: 'Yggdrasil',
  qs: 'Quickspin',
  mg: 'Microgaming',
  eg: 'Evolution Gaming',
  spinomenal: 'Spinomenal',
  ps: 'Playson',
  bg: 'Booongo',
  endorphina: 'Endorphina',
  amatic: 'Amatic',
  playngo: 'Play’n GO',
  leap: 'Leap',
  thunderkick: 'Thunderkick',
  spribe: 'Spribe',
  wazdan: 'Wazdan',
  push_gaming: 'Push Gaming',
  btg: 'BTG',
  relax_gaming: 'Relax Gaming',
  top: 'MAIN_MENU.TOP',
  new: 'MAIN_MENU.NEW',
  live_dealers: 'MAIN_MENU.LIVE_DEALERS',
  table: 'MAIN_MENU.TABLE',
  slots: 'MAIN_MENU.SLOTS',
  news: 'ARTICLES.NEWS',
  promo: 'ARTICLES.PROMO',
  bonuses: 'MAIN_MENU.BONUSES',
  'personal-info': 'PROFILE.PERSONAL_INFO.TITLE',
  'contact-details': 'PROFILE.CONTACT_DETAILS.TITLE',
  'two-factor-auth': 'PROFILE.TWO_FACTOR_AUTH.TITLE',
  'change-password': 'PROFILE.CHANGE_PASSWORD.TITLE',
  'responsible-gaming': 'PROFILE.RESPONSIBLE_GAMING.TITLE',
  faq: 'MAIN_MENU.HELP',
  login: 'AUTH.LOGIN.SIGN_IN',
  'password-reminder': 'AUTH.PASSWORD_REMINDER.TITLE',
  registration: 'AUTH.REGISTRATION.SIGN_UP',
  history: 'CASHIER.METHODS.HISTORY',
  deposit: 'CASHIER.METHODS.DEPOSIT',
  withdrawal: 'CASHIER.METHODS.WITHDRAW',
  transfers: 'CASHIER.METHODS.TRANSFER',
  search: 'COMMON.SEARCH',
  terms: 'FOOTER.TERMS_AND_CONDITIONS',
  'privacy-policy': 'FOOTER.PRIVACY_POLICY',
  'anti-fraud': 'FOOTER.ANTIFRAUD',
  tournaments: 'MAIN_MENU.TOURNAMENTS',
  missions: 'MAIN_MENU.MISSIONS',
  kyc: 'MAIN_MENU.KYC_VERIFICATION',
  basic: 'MAIN_MENU.BASIC_VERIFICATION',
  card: 'MAIN_MENU.PAYMENT_VERIFICATION',
  crash: 'MAIN_MENU.CRASH',
  scratch: 'MAIN_MENU.SCRATCH',
  verification: 'MAIN_MENU.VERIFICATION',
  promocode: "BONUSES.PROMO_CODE_PLACEHOLDER",
  settings: "MAIN_MENU.SETTINGS",
  404: "NOT_FOUND.PAGE_NOT_FOUND",
};
