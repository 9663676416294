import { inject } from '@angular/core';
import { synchronize$ } from '@libs/store';
import { BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { Observable } from 'rxjs';

export const loadBonusesGuard = (): Observable<boolean> => {
  const query = inject(BonusesQuery);

  return query.synchronized$.pipe(synchronize$(inject(BonusesData), query));
};
