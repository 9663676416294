import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-wager-info',
  templateUrl: './wager-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WagerInfoComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus> = null;
}
