// @ts-nocheck
import { HistoriesDto, HistorySections, HistoryType, TransactionsHistory } from '@portal/cashier/data';

export function getHistory(history: HistoriesDto, section: HistorySections): HistoryType {
  return {
    [section]: {
      total: history.length,
      items: history.map(TransactionsHistory.create)
    }
  };
}
