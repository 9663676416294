// @ts-nocheck

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setLoading, transaction } from '@datorama/akita';
import { SpNavigation, SpNotification } from '@libs/cdk';
import { HISTORY_LOAD_LIMIT } from '@portal/cashier/constants/history-load-limit.constant';
import {
  Account,
  AccountsResponse,
  BankCard,
  BankCardDetails,
  BankCardDetailsResponse,
  BankCardsResponse,
  CactusVerificationCode,
  CancelHistoryResponse,
  CashierStore,
  CryptoConversionRate,
  CryptoConvertedRateResponse,
  CryptoCurrency,
  CryptoCurrencyResponse,
  CryptoWithdrawalPayload,
  DeleteBankCard,
  get3dsPaymentData,
  getAccounts,
  getBankCardDetails,
  getBankCards,
  getCryptoCurrency,
  getMobileOperators,
  getPaymentMethods,
  HistoryBySection,
  HistoryResponse,
  HistorySections,
  MobileOperator,
  MobileOperatorsResponse,
  NewBankCardResponse,
  PaymentMethod,
  PaymentMethodsDto,
  ThreeDSecurePaymentData,
  ThreeDSPaymentDataDto,
  TransactionStatusDto,
  TransferResponse,
  UserBankCardDetails,
} from '@portal/cashier/data';
import { getCryptoflowData } from '@portal/cashier/data/commands/get-cryptoflow-data';
import { getHistory } from '@portal/cashier/data/commands/get-history';
import { CryptoFLowDto } from '@portal/cashier/data/dto/cryptoflow-data.dto';
import { CryptoflowData } from '@portal/cashier/data/entities/cryptoflow-data';
import { TransactionsHistory } from '@portal/cashier/data/entities/history';
import { ConfigQuery } from '@portal/config';
import { ResponseFactory } from '@portal/shared/helpers';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CashierDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly repository: CashierStore,
    private readonly notifications: SpNotification,
    private readonly navigationService: SpNavigation,
    private readonly source: ConfigQuery
  ) {}

  @transaction()
  synchronize(): void {
    const requests = this.source.modules.cashier.depositCardPrevalidation
      ? [ this.getPaymentsMethods(), this.loadUserBankCards() ]
      : [ this.getPaymentsMethods() ];

    forkJoin(requests).subscribe(() => this.repository.update({ sync: true }));
  }

  setPaymentMethod({ id }: PaymentMethod): void {
    this.repository.setActive(id);
  }

  @transaction()
  getPaymentsMethods(account?: string): Observable<Array<PaymentMethod>> {
    const isUrlParams = account ? `?account=${account}` : '';

    return this.http.get<PaymentMethodsDto>(`/api/payments/user_payment_methods/${isUrlParams}`).pipe(
      setLoading(this.repository),
      tap((response) => {
        this.repository.update({
          defaultAmount: response.default_amount
        });
      }),
      map((response: PaymentMethodsDto) => getPaymentMethods(response)),
      tap((methods) => this.repository.set(methods)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  addUserBankCards(cardData: UserBankCardDetails): Observable<Array<BankCard>> {
    return this.http.post<NewBankCardResponse>(`/api/payments/deposit/card_type/set_new_card_data`, cardData).pipe(
      tap((card) => this.repository.update({ newCardId: card._item.id })),
      switchMap(() => this.loadUserBankCards()),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  deleteUserBankCard(bankCardId: unknown): Observable<Array<BankCard>> {
    return this.http.post<DeleteBankCard>(`/api/payments/deposit/card_type/delete_card`, { id: bankCardId }).pipe(
      setLoading(this.repository),
      switchMap(() => this.loadUserBankCards()),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  getUserCardInfo(bankCardId: string): Observable<BankCardDetails> {
    return this.http.get<BankCardDetailsResponse>(`/api/payments/deposit/card_type/get_user_card_data/${bankCardId}`).pipe(
      map(({ _items }: BankCardDetailsResponse) => getBankCardDetails(_items)),
      tap((card) => this.repository.update({ selectedCard: card })),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  loadAccounts(paymentMethod: number): Observable<Array<Account>> {
    return this.http.get<AccountsResponse>(`/api/payments/accounts/${paymentMethod}`).pipe(
      map((response) => getAccounts(response.accounts)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  loadMobileOperators(paymentMethodId: number): Observable<Array<MobileOperator>> {
    return this.http.get<MobileOperatorsResponse>(`/api/payments/operators/list/${paymentMethodId}`).pipe(
      map((response) => getMobileOperators(response.operators)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  check3dsPayment(initiatorID: string, paymentMethod: string): Observable<ThreeDSecurePaymentData> {
    return this.http.post<ThreeDSPaymentDataDto>(
      '/api/payments/3ds/check/',
      {
        initiatorID,
        paymentMethod
      }
    ).pipe(
      map((response) => get3dsPaymentData(response)),
      tap((paymentData) => this.repository.update((state) => ({
        ...state,
        threeDSPaymentData: paymentData
      }))),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  makeTransfer(nick: string, amount: string): Observable<void | TransferResponse> {
    return this.http.post<TransferResponse>('/api/payments/transfers/create/', { nick, amount }).pipe(
      setLoading(this.repository),
      tap(() => this.navigationService.navigate([ '/cashier', 'transfers', 'success' ], {
        nick,
        amount
      })),
      catchError((error) => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.CASHIER_TRANSFER_FAIL');

        return throwError(() => ResponseFactory.error(error));
      })
    );
  }

  getTransactionStatus(section: string, invoice: string): Observable<string> {
    return this.http.get<TransactionStatusDto>(`/api/payments/deposit/status/${invoice}`).pipe(
      map((response) => response.payment_status),
      tap((paymentStatus) => {
        if (![ 'success', 'failed' ].includes(paymentStatus)) { return; }

        const page = paymentStatus === 'failed' ? 'error' : paymentStatus;
        this.navigationService.navigate([ 'cashier', section, 'transaction', page ]);
      }),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  loadHistory(section: HistorySections, transactionsFilterDate: string): Observable<HistoryBySection> {
    const type = !!section && section !== HistorySections.All ? `&type=${section}` : '';
    const skip = 0;

    return this.http.get<HistoryResponse>(`/api/payments/payments_history?skip=${skip}&limit=${HISTORY_LOAD_LIMIT}${type}`).pipe(
      map((res) => getHistory(res._items, section)),
      tap((history) => this.repository.update((state) => {
        if (!!transactionsFilterDate) {
          history[section].items = history[section].items?.filter((i) => (Number(i.createDate) * 1000) >= Number(transactionsFilterDate));
        }

        return {
          ...state,
          history: { ...state.history, ...history[section] }
        };
      })),
      map((history) => history[section]),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  cancelHistoryOperation(operation: TransactionsHistory, section: HistorySections, transactionsFilterDate: string): Observable<void | CancelHistoryResponse> {
    return this.http.post<CancelHistoryResponse>(`/api/payments/payments_history/${operation.id}/cancel`, null).pipe(
      tap((response) => response._status === 'OK' && this.loadHistory(section, transactionsFilterDate).subscribe()),
      catchError((error) => {

        this.notifications.error('NOTIFICATIONS.MESSAGES.CANCEL_TRANSACTION_ERROR');
        return throwError(() => ResponseFactory.error(error));
      })
    );
  }

  // TODO: resolve any type to the real one
  checkPaymentStatus(initiatorId: string): Observable<any> {
    return this.http.get(`/api/payments/deposit/pending/${initiatorId}`);
  }

  // TODO: resolve any type to the real one
  depositUpdate(initiatorID, code, paRes?): Observable<any> {
    return this.http.post('/api/payments/deposit/update/', { initiatorID, code, pa_res: paRes });
  }

  // TODO: resolve any type to the real one
  deleteCard(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.delete(`/api/payments/account/${id}`, httpOptions);
  }

  @transaction()
  loadCryptoCurrencies(paymentMethod: number): Observable<Array<CryptoCurrency>> {
    return this.http.get<CryptoCurrencyResponse>(`/api/payments/cryptocurrencies/list/${paymentMethod}`).pipe(
      map((res) => getCryptoCurrency(res.cryptocurrencies)),
      tap((crypto) => this.repository.update((state) => ({
        ...state,
        cryptoCurrencies: crypto,
        selectedCryptoCurrencies: crypto[0]
      }))),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  @transaction()
  loadConvertCrypto(currencyInfo: CryptoConversionRate): Observable<void | CryptoConvertedRateResponse> {
    return this.http.post<CryptoConvertedRateResponse>('/api/payments/cryptocurrencies/rate/', currencyInfo).pipe(
      tap((rate) => this.repository.update({ conversionRate: rate.converted_amount })),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  createCryptoWithdrawRequest(currencyInfo: CryptoWithdrawalPayload): Observable<unknown> {
    return this.http.post('/api/payments/withdrawal/create/', currencyInfo);
  }

  loadCryptoMethodInfo(paymentMethod: number, currency?: string): Observable<CryptoflowData> {
    return this.http.post('/api/payments/cryptocurrencies/account', {
      payment_method: paymentMethod,
      currency
    }).pipe(
      map((response: CryptoFLowDto) => getCryptoflowData(response)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  setActiveCryptoCurrency(crypto: CryptoCurrency): void {
    this.repository.update((state) => ({
      ...state,
      selectedCryptoCurrencies: crypto
    }));
  }

  setErrorMessage(error): void {
    this.repository.update((state) => ({
      ...state,
      errorMessage: error
    }));
  }

  resetErrorMessage(): void {
    this.repository.update((state) => ({
      ...state,
      errorMessage: null
    }));
  }

  resetSelectedCard(): void {
    this.repository.update((state) => ({
      ...state,
      selectedCard: null
    }));
  }

  createPaymentCode(paymentMethod: number): Observable<string> {
    return this.http.post('/api/payments/payment-code/generate', {
      payment_method: paymentMethod,
    }).pipe(
      map((res: CactusVerificationCode) => res.code),
      catchError(error => throwError(ResponseFactory.error(error)))
    );
  }

  resetPaymentMethods(): void {
    this.repository.reset();
  }

  @transaction()
  private loadUserBankCards(): Observable<Array<BankCard>> {
    return this.http.get<BankCardsResponse>(`/api/payments/deposit/card_type/get_user_cards`).pipe(
      setLoading(this.repository),
      map(({ _items }: BankCardsResponse) => getBankCards(_items)),
      tap((cards) => {
        this.repository.update({ cards });
      }),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }
}
