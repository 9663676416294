import { NgModule } from '@angular/core';
import { SP_DEFAULT_NOTIFICATION_CONFIG } from './notifications.config';
import { SpNotification } from './notifications.service';
import { SimpleNotificationsModule } from './source';

@NgModule({
  imports: [ SimpleNotificationsModule.forRoot(SP_DEFAULT_NOTIFICATION_CONFIG) ],
  providers: [ SpNotification ]
})
export class SpNotificationsCoreModule {}

@NgModule({
  imports: [ SimpleNotificationsModule ],
  exports: [ SimpleNotificationsModule ],
  providers: [ SpNotification ]
})
export class SpNotificationsModule {}
