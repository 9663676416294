import { FormControl, FormGroup } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';

export const createPersonalInfoForm = (): FormGroup => {
  return new FormGroup({
    first_name: new FormControl('', { nonNullable: true, validators: validationRules.firstName }),
    last_name: new FormControl('', { nonNullable: true, validators: validationRules.lastName }),
    nick: new FormControl('', { nonNullable: true, validators: validationRules.nick })
  });
};
