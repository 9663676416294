import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { SpCommonModule } from './common';
import { SpLocalizationModule } from './localization';
import { SpMetaModule } from './meta';
import { SpNavigationModule } from './navigation';
import { SpNotificationsModule } from './notification';
import { SpPlatformModule } from './platform';
import { SpStorageModule } from './storage';
import { SpViewportModule } from './viewport';

const modules = [ SpCommonModule, SpLocalizationModule, SpNotificationsModule ];
const providers = [ SpPlatformModule, SpStorageModule, SpViewportModule, SpNavigationModule, SpMetaModule ];
const matCDK = [ DialogModule, PortalModule, ClipboardModule, DragDropModule ];

const reexport = [ modules, providers, matCDK ];

@NgModule({ imports: reexport, exports: reexport })
export class SpCDKModule {}
