<section ggCreateThemeClass="not-verified">
  <section class="not-verified">
  <div class="not-verified__title">
    <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"></sp-msg>
  </div>
  <div class="not-verified__text">
    <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE_EXAMPLE"></sp-msg>
  </div>
  <div class="not-verified__tools">
    <form [formGroup]="mobilePhoneForm">

      <gg-country-phone-input
        class="not-verified__input"
        [placeholder]="'AUTH.REGISTRATION.PHONE' | translate"
        formControlName="phone"
        [errors]="mobilePhoneErrors.get('phone') | async"
      ></gg-country-phone-input>

      <section class="not-verified__captcha-section" *ggHasRecaptcha="'profilePhone'">
        <re-captcha formControlName="recaptchaToken"></re-captcha>
        <gg-input-error [errors]="mobilePhoneErrors.get('recaptchaToken') | async"></gg-input-error>
      </section>
    </form>
    <div class="not-verified__flash-call-block" *ngIf="isFlashCallOption">
      <p class="not-verified__flash-call-text-bold">
        <sp-msg msg="PROFILE.CONTACT_DETAILS.YOU_RECEIVE_CALL"></sp-msg>
      </p>
    </div>
    <gg-button
      class="not-verified__button"
      [fullWidth]="true"
      [disabled]="mobilePhoneForm.invalid"
      [settings]="saveButton"
      (buttonClicked)="verifyPhone()"
    >
      <sp-msg msg="PROFILE.CONTACT_DETAILS.SAVE"></sp-msg>
    </gg-button>
  </div>
</section>
</section>

