import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject, combineLatest, filter, fromEvent, map, Observable, Subject } from 'rxjs';
import { connectorApiInfo } from './help';
import { ConnectorEvent } from './types';
import { SpCollectorsToken, SpConnectorConfigToken, SpExecutorsToken, SpTransformersToken } from './utils';

@Injectable()
export class SpConnector<E extends ConnectorEvent = ConnectorEvent, D extends object = object> {
  private readonly data: BehaviorSubject<Partial<D>> = new BehaviorSubject<Partial<D>>({} as D);
  private readonly events: Subject<E> = new Subject<E>();

  private readonly destroy = inject(DestroyRef);
  private readonly config = inject(SpConnectorConfigToken);

  private readonly transformers = inject(SpTransformersToken, { optional: true });
  private readonly collectors = inject(SpCollectorsToken, { optional: true });
  private readonly executors = inject(SpExecutorsToken, { optional: true });

  constructor() {
    this.setupExecutors();
    this.setupCollectors();
    this.config.parseMessages && this.parsePostMessages();
  }

  help(): void {
    Object.entries({ ...connectorApiInfo, ...(this.config.info || {}) }).forEach(([ section, data ]) => {
      console.log('');
      console.log(`%c ${section}`, 'background: #222; color: #bada55');
      console.table(data);
    });
  }

  get(): Partial<D>;
  get<T extends keyof D>(prop: T): Partial<D>[T];
  get<T extends keyof D>(prop?: T): Partial<D> | Partial<D>[T] {
    const value = this.data.getValue();
    return prop ? value[ prop ] : value;
  }

  request(): Observable<Partial<D>>;
  request<T extends keyof D>(prop: T): Observable<Partial<D>[T]>;
  request<T extends keyof D>(prop?: T): Observable<Partial<D> | Partial<D>[T]> {
    if (!prop) { return this.data.asObservable(); }
    return this.data.pipe(map((value) => value[ prop ]));
  }

  listen(): Observable<E>;
  listen<T extends E['event']>(event: T): Observable<Extract<E, { event: T }>>;
  listen<T extends E['event']>(event?: T): Observable<Extract<E, { event: T }> | E> {
    return event
      ? this.events.pipe(filter((e): e is Extract<E, { event: T }> => e.event === event))
      : this.events.asObservable();
  }

  sendEvent(event: E): void;
  sendEvent<T extends E['event']>(event: T, payload: Extract<E, { event: T }>['payload']): void;
  sendEvent<T extends E['event']>(event: E | T, payload?: Extract<E, { event: T }>['payload']): void {
    typeof event === 'string' ? this.events.next({ event, payload } as unknown as E) : this.events.next(event);
  }

  private parsePostMessages(): void {
    fromEvent(inject(WINDOW), 'message').pipe(
      map((message) => this.transformMessageEvent(message)),
      filter((event): event is E => !!event),
      takeUntilDestroyed(this.destroy)
    ).subscribe((event) => this.sendEvent(event));
  }

  private setupCollectors(): void {
    const streams: Array<Observable<Partial<D>>> = [];

    this.collectors?.forEach((collector) => {
      const collected = collector.collect();
      collected instanceof Observable ? streams.push(collected) : this.data.next({ ...this.data.getValue(), ...collected });
    });

    streams.length && combineLatest(streams).pipe(takeUntilDestroyed(this.destroy)).subscribe((result) => {
      const collectedResult = result.reduce((collected, data) => ({ ...collected, ...data }), this.data.getValue());
      this.data.next(collectedResult);
    });
  }

  private setupExecutors(): void {
    this.events.pipe(takeUntilDestroyed(this.destroy)).subscribe((event) => {
      this.executors?.forEach((executor) => executor.execute(event));
    });
  }

  private transformMessageEvent(message: Event): Nullable<ConnectorEvent> {
    return this.transformers?.reduce<Nullable<ConnectorEvent>>((event, transformer) => {
      return transformer.transform(message as MessageEvent) || event;
    }, null);
  }
}
