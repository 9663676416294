import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'gg-accordion-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: [ './expansion-panel.component.scss', './main.expansion-panel.component.scss', './alternative.expansion-panel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelComponent {
  @ViewChild(CdkAccordionItem, { read: CdkAccordionItem }) item?: CdkAccordionItem;

  @Input() titleInfo: string = '';
  @Input() titleIcon: string = '';
  @Input() isExpanded = false;
  @Input() linkToRoute: string = '';
  @Input() collapsedIconPath = 'assets/images/icons/{palette}/plus-icon.svg';
  @Input() expandedIconPath = 'assets/images/icons/{palette}/x-icon.svg';
  @Output() expandChanged = new EventEmitter();

  toggleAccordion(): void {
    this.isExpanded = !this.isExpanded;
    this.isExpanded && this.expandChanged.emit();
  }

  getPath(): string | null {
    return this.linkToRoute ? `/user/settings/${this.linkToRoute}` : null;
  }
}
