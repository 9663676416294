import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces/input-select-data.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gg-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: [
    './input-select.component.scss',
    './input-select.purple.component.scss',
    './input-select.silver.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputSelectComponent),
    multi: true
  } ]
})

export class InputSelectComponent extends InputAbstract implements OnInit, OnChanges {
  private selectedValue: Nullable<string>;

  readonly searchControl = new FormControl();

  showOptions = false;
  chosenOption: Nullable<IInputSelectData>;
  optionsList$!: BehaviorSubject<Nullable<Array<IInputSelectData>>>;

  @Input() options: Nullable<Array<IInputSelectData>> = [];
  @Input() defaultOption: Nullable<IInputSelectData>;
  @Input() isSearch = false;
  @Input() truncateTo = 27;
  @Input() truncateSelectedTo = 27;
  @Input() position: 'below' | 'above' = 'below';

  @ViewChild('search') searchEl: Nullable<ElementRef> = null;
  @ViewChild('select', { static: true }) selectWrapper?: ElementRef<HTMLElement>;

  get optionClass(): Record<string, boolean> {
    return { 'field__select-wrapper_open': this.showOptions, [this.position]: true };
  }

  get inputClass(): Record<string, boolean> {
    return {
      error: !!this.errors && !!this.errors.length,
      'field__select--dirty': !!this.value,
      field__select_opened: this.showOptions,
      'field__select--empty': this.defaultOption?.value === '' && !this.value,
      [this.position]: true
    };
  }

  override ngOnInit(): void {
    this.searchControl.valueChanges.subscribe(this.search.bind(this));
    if (!this.defaultOption) { return; }

    this.chosenOption = this.buildChosenOption(this.defaultOption);
    this.selectedValue = this.defaultOption.value;
    this.writeValue(this.selectedValue);
    this.optionsList$ = new BehaviorSubject(this.options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.optionsList$ = new BehaviorSubject(changes['options'].currentValue);
      if (!changes['options'].currentValue?.length && !!this.defaultOption) {
        this.chosenOption = this.buildChosenOption(this.defaultOption);
      }
    }

    if (changes['defaultOption']?.currentValue) {
      this.chosenOption = this.buildChosenOption(changes['defaultOption'].currentValue);
    }
  }

  newOption(option: IInputSelectData): void {
    this.chosenOption = this.buildChosenOption(option);
    this.showOptions = false;
    this.selectedValue = option.value;
    this.onChange?.(this.selectedValue);
    this.writeValue(this.selectedValue);
  }

  search(optionSearch: string): void {
    const countries = optionSearch.length > 0
      ? this.getSearchOption(optionSearch) as Array<IInputSelectData>
      : this.options;

    this.optionsList$.next(countries);
  }

  @HostListener('document:click', [ '$event' ])
  private listenClicks(event: MouseEvent): void {
    if (!this.selectWrapper || event.composedPath().includes(this.selectWrapper.nativeElement)) { return; }
    this.showOptions = false;
  }

  private buildChosenOption(option: IInputSelectData): IInputSelectData {
    return { ...option, description: option.description };
  }

  private getSearchOption(search: string): IInputSelectData | Array<IInputSelectData> {
    return this.options?.filter(option => option.description.toLowerCase().startsWith(search.toLowerCase())) || [];
  }
}
