import { inject } from '@angular/core';
import { Params } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { Collector, Language, SpLocalization, Translation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ReferralQuery } from '@portal/authorization/data';
import { ConfigQuery, Project } from '@portal/config';
import { UserQuery } from '@portal/user';
import { map, Observable } from 'rxjs';

export class UserCollector implements Collector<{ id: Nullable<string> }> {
  private readonly userQuery = inject(UserQuery);

  collect(): Observable<{ id: Nullable<string> }> {
    return this.userQuery.id$.pipe(map((id) => ({ id })));
  }
}

export class LocalizationCollector implements Collector<{ language: Language; translations: Translation }> {
  private readonly localization = inject(SpLocalization);

  collect(): { language: Language; translations: Translation } {
    return { language: this.localization.currentLanguage, translations: this.localization.currentTranslation };
  }
}

export class ConfigCollector implements Collector<{ source: Project['source'] }> {
  private readonly config = inject(ConfigQuery);

  collect(): { source: Project['source'] } {
    return { source: this.config.project.source };
  }
}

export class ParamsCollector implements Collector<{ uriParams: Nullable<Params> }> {
  private readonly referral = inject(ReferralQuery);

  collect(): { uriParams: Nullable<Params> } {
    return { uriParams: this.referral.info.uriParams };
  }
}

export class TokensCollector implements Collector<{ fToken: Nullable<string>; refToken: Nullable<string> }> {
  private readonly authorization = inject(SpAuthorizationService);
  private readonly referral = inject(ReferralQuery);

  collect(): { fToken: Nullable<string>; refToken: Nullable<string> } {
    return {
      fToken: this.referral.fToken,
      refToken: this.authorization.tokens?.refreshToken,
    };
  }
}
