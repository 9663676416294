<div class="radio" [ngClass]="additionalClass" *ngFor="let option of options" (click)="changeSelected(option)">
  <label class="radio__label" for="{{ option }}-fake" [ngClass]="{ radio__checked: isSelected(option) }">
    <input type="radio" id="{{ option }}-fake" [name]="name" [value]="option" class="radio__input"/>
    <span class="radio__box"></span>
    <span class="radio__message">{{ option }}</span>
    <span *ngIf="showDeleteButton" class="radio__delete" (click)="deleteThisElem(option)">
      <img src="assets/images/icons/cross2.svg" alt=""/>
    </span>
    <gg-input-error additionalClass="radio__message--error" [errors]="errors"/>
  </label>
</div>
