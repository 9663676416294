import { Directive, EventEmitter, HostListener } from '@angular/core';

@Directive()
abstract class StepDirective {
  readonly action: EventEmitter<void> = new EventEmitter();

  @HostListener('click') click(): void { this.action.emit(); }
}

@Directive({ selector: '[ggStepperNext]' })
export class NextStepDirective extends StepDirective {}

@Directive({ selector: '[ggStepperPrev]' })
export class PrevStepDirective extends StepDirective {}

@Directive({ selector: '[ggStepperProvide]' })
export class ProvideDirective {}
