<section class="payment-data-action">
    <sp-msg class="payment-data-action__title" spMsg="CASHIER.CARD.USER_DATA_FORM_TITLE_DEPOSIT"/>
    <gg-payment-generated-form class="bank-card-flow__form" [declaration]="form" [formControl]="control"/>
    <gg-button
      class="payment-data-action__button"
      [fullWidth]="true"
      [settings]="submitButton"
      [disabled]="control.invalid"
      (buttonClicked)="submit()"
    >
      <sp-msg msg="CASHIER.CARD.MAKE_DEPOSIT"/>
    </gg-button>
</section>
