import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { BankCard, PaymentState, PaymentStore } from '@portal/payment/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentCardsQuery extends SteroidQuery<PaymentState> {
  private readonly cards = this.entity('cards');

  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly cards$: Observable<Array<BankCard>> = this.cards.selectAll();

  constructor(store: PaymentStore) { super(store); }

  getCard(index: number): BankCard {
    return this.cards.getAll()[ index ];
  }

  cardsLength(): number {
    return this.cards.getAll().length;
  }
}
