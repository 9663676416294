<div class="pwa-ios pwa-instruction">
  <img src="assets/images/icons/cross.svg" class="pwa-ios__close" (click)="close()" alt=""/>
  <img src="assets/images/pwa/logo-mob.svg" class="pwa-ios__logo" alt=""/>
  <span class="pwa-ios__title" spMsg="COMMON.INSTALL_MOBILE_APP"></span>
  @for (line of lines; track line; let i = $index) {
    <div class="pwa-ios__line">
      <span class="pwa-ios__line__num">{{ i + 1 }}.</span>
      <span class="pwa-ios__line__text" [spMsg]="line.text"></span>
      @if (line.icon) {
        <img class="pwa-ios__line__img" [src]="'assets/images/pwa/' + line.icon" alt=""/>
      }
    </div>
  }
</div>
