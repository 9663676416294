@if (isMobileDevice$ | spAsync) {
  <ng-container *spLet="isDeposit$ | spAsync as deposit">
  <gg-payment-mobile-container-nav
    [containers]="availableContainers$ | spAsync"
    [active]="active$ | spAsync"
    [title]="deposit ? 'CASHIER.SWIPER.PAYMENT_METHOD' : 'CASHIER.SWIPER.WITHDRAWAL_METHOD'"
    (selected)="select($event)"
  />
</ng-container>
} @else {
  <ng-scrollbar visibility="hover">
    <ol class="payment-methods-list" *spLet="active$ | spAsync as active">
      @for (container of containers$ | spAsync; track container.id; let i = $index) {
        @if (container.groups.length) {
          <li class="payment-methods-list__element" *spLet="container.id === active as isActive"
(click)="select(container.id)">
            <a class="payment-methods-list__link" [class.payment-methods-list__link_active]="isActive">
              <img [src]="container.icon" [alt]="container.name" class="payment-methods-list__img"/>
              @if (container.name) {
                <sp-msg class="payment-methods-list__name" [class.payment-methods-list__name_active]="isActive"
                       [spMsg]="container.name"/>
              }
            </a>
          </li>
        }
      }
    </ol>
  </ng-scrollbar>
}
