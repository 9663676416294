import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { CurrenciesModule } from '@portal/currencies';
import { PaymentContainerType } from '@portal/payment/shared';
import { SpLocalizationModule } from 'libs/cdk/src/lib/localization';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'gg-payment-tiles',
  standalone: true,
  templateUrl: './payment-tiles.component.html',
  styleUrl: './payment-tiles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, SpLocalizationModule, CurrenciesModule, NgScrollbar ],
})
export class PaymentTilesComponent {
  @Input() tiles: Nullable<Array<PaymentContainerType>> = null;
  @Input() active: Nullable<string> = null;
  @Input() title: string = 'CASHIER.SWIPER.SELECT_METHOD';

  @Output() selected = new EventEmitter<string>();

  onSelect(id: Nullable<string>): void {
    id && this.selected.emit(id);
  }
}
