<cdk-accordion-item
  class="expansion-panel"
  spCreateThemeClass="expansion-panel"
  #accordionItem="cdkAccordionItem"
  [expanded]="isExpanded"
  [ngClass]="{'expansion-panel_expanded': isExpanded}">
  <div [routerLink]="getPath()" class="expansion-panel__header"
       [class.expansion-panel__header_active]="isExpanded"
       (click)="toggleAccordion()">
    <div class="expansion-panel__header-container" *ngIf="titleIcon else onlyTitle">
      <img [src]="titleIcon" alt="" class="expansion-panel__icon">
      <sp-msg class="expansion-panel__header-title" [class.expansion-panel__header-title_active]="isExpanded" [msg]="titleInfo"></sp-msg>
    </div>
    <ng-template #onlyTitle>
      <sp-msg class="expansion-panel__header-title" [class.expansion-panel__header-title_active]="isExpanded" [msg]="titleInfo"></sp-msg>
    </ng-template>
    <img
      *ngIf="!accordionItem.expanded && collapsedIconPath"
      class="expansion-panel__header-icon expansion-panel__header-icon_collapsed"
      alt="collapsed-icon"
      [src]="collapsedIconPath | palette"
    />
    <img
      *ngIf="accordionItem.expanded && expandedIconPath"
      class="expansion-panel__header-icon expansion-panel__header-icon_expanded"
      alt="expanded-icon"
      [src]="expandedIconPath | palette"
    />
  </div>
  <div class="expansion-panel__body" [style.display]="accordionItem.expanded ? '' : 'none'">
    <ng-content></ng-content>
  </div>
</cdk-accordion-item>
