import { inject } from '@angular/core';
import { SpNavigation, SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { PaymentData, PaymentParamsData, PaymentResult } from '@portal/payment/data';
import { PaymentBuilder, PaymentParams } from '@portal/payment/shared';

export abstract class PostActionAbstract {
  protected readonly builder = inject(PaymentBuilder);
  protected readonly result = inject(PaymentResult);
  protected readonly paymentData = inject(PaymentData);
  protected readonly paramsData = inject(PaymentParamsData);
  protected readonly notify = inject(SpNotification);
  protected readonly navigation = inject(SpNavigation);

  protected processErrors(...statements: Array<boolean>): boolean {
    if (this.result.status !== 'error' && statements.every((s) => !s)) { return false; }

    this.collectErrorNotifications().forEach((error) => this.notify.error(error));
    this.setError();

    return true;
  }

  protected processUrl(url: Nullable<string>): boolean {
    if (url) this.navigation.navigate(url);
    return !!url;
  }

  protected setError(params: Partial<PaymentParams> = {}): void {
    this.setStatus({ status: 'error', ...params });
  }

  protected setStatus(params: Partial<PaymentParams> = {}): void {
    const { status } = this.result;
    const { method, amount } = this.builder.snapshot || {};
    this.paramsData.setPayment({ status, method: method?.id || undefined, amount: amount?.toString(), ...params });
  }

  protected collectErrorNotifications(): Array<string> {
    const errors: Array<string> = [];
    this.result.message === 'Invalid card number.: card_number' && errors.push('NOTIFICATIONS.MESSAGES.BAD_CARD_NUMBER');
    this.result.message === 'Card has expired' && errors.push('NOTIFICATIONS.MESSAGES.CARD_EXPIRED');
    return errors;
  }
}
