import { Nullable } from '@libs/utils';
import { FiatCurrencyDto } from '@portal/currencies/data/dto';

type FiatCurrencyProps = FiatCurrencyDto & Partial<{ rate: Nullable<number> }>;

export class FiatCurrency {
  readonly name = this.source.name;
  readonly is = { default: this.source.isDefault, registration: this.source.inRegistration };
  readonly rate: Nullable<number> = this.source.rate;

  constructor(
    private readonly source: FiatCurrencyProps,
  ) {}

  isEqual(currency: FiatCurrency | FiatCurrency['name']): boolean {
    return currency instanceof FiatCurrency ? this.source.name === currency.name : this.source.name === currency;
  }

  toString(): string {
    return this.source.name;
  }
}
