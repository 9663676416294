import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { BannerComponent } from '@portal/banners/shared';

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-banners-poster',
  templateUrl: './poster-banner.component.html',
  styleUrls: [ './poster-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosterBannerComponent extends BannerComponent {}
