import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'removePlaceholders', standalone: true })
export class RemovePlaceholdersPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) { return ''; }

    const curlyBracesRegex = /{.*?}/g;
    const currencyRegex = /(?:<\/?[^>]+>)*{(.*?)currency(.*?)currency(?:<\/?[^>]+>)*}/g;
    return curlyBracesRegex.test(value) ? value.replace(currencyRegex, '').replace(curlyBracesRegex, '') : value;
  }
}
