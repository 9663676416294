import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { passOnly } from '@libs/authorization';
import { SettingsComponent } from '@portal/user/pages/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: SettingsComponent
      },
      {
        path: ':section',
        component: SettingsComponent
      }
    ],
    canActivate: [ passOnly('authorized', [ '/auth', 'login' ], '/user/settings') ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class UserRoutingModule {}
