<ng-container *spLet="context | spAsync as context">
  <div class="page-header" *spLet="context.menu | spAsync as isTablet">
    <div class="page-header__logo-container">
      @if (isTablet) {
        <gg-layout-sidebar-icon class="page-header__sidebar-icon" (click)="context.showSidebar()"/>
      }
      <gg-layout-logo class="page-header__logo"/>
    </div>
    @if (!isTablet) {
      @if (context.rewards && isTablet) {
        <gg-user-rewards-icon class="page-header__rewards-icon"/>
      }
      @if (context.authorization) {
        <gg-layout-authorization class="page-header__authorization"/>
      }
      @if (context.balance) {
        @if (isTablet) {
           <gg-user-quick-access-links class="page-header__quick-access-links"/>
        }
        <gg-layout-user-balance withDepositButton="true" class="page-header__user-balance"/>
      }
      @if (!isTablet && context.rewards) {
        <gg-user-rewards-icon/>
      }
    } @else {
      <div class="page-header__navigation">
        @if (context.rewards && isTablet) {
          <gg-user-rewards-icon class="page-header__rewards-icon"/>
        }
        @if (context.authorization) {
          <gg-layout-authorization class="page-header__authorization"/>
        }
        @if (context.balance) {
          @if (isTablet) {
            <gg-user-quick-access-links class="page-header__quick-access-links"/>
          }
          <gg-layout-user-balance withDepositButton="true" class="page-header__user-balance"/>
        }
        @if (!isTablet && context.rewards) {
          <gg-user-rewards-icon/>
        }
      </div>
    }
  </div>
</ng-container>

