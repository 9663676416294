import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Nullable } from '@libs/utils';
import { LinkSizes, LinkThemes } from './link.enums';
import { ILink } from './link.interfaces';

@Component({
  selector: 'gg-link',
  templateUrl: './link.component.html',
  styleUrls: [ './link.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LinkComponent {
  @Input() settings: ILink = {};
  @Input() additionalClasses: Nullable<string> = null;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Output() buttonClicked: EventEmitter<MouseEvent> = new EventEmitter();

  get linkClasses(): Array<string> {
    const classes = [ 'button', `button--${this.settings.size || LinkSizes.Medium}`, `button--${this.settings.theme || LinkThemes.Green}` ];

    if (this.settings.loading) { classes.push('button--loading'); }
    if (this.settings.disabled) { classes.push('button--disabled'); }
    if (this.settings.icon) { classes.push('button--icon'); }
    if (this.fullWidth) { classes.push('button--full-width'); }
    if (this.additionalClasses) { classes.push(...this.additionalClasses.split(' ')); }

    return classes;
  }
}
