import { Language } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import {
  BannerContents,
  BannerOrder,
  BannerPage,
  BannerSection,
  BannerType,
  BannerVisibility
} from '@portal/banners/shared';

type Settings = { type: BannerType } & Record<'url' | 'additionalUrl' | 'expirationDate', Nullable<string>>;
type Visibility = { page: BannerPage; section: BannerSection; order: BannerOrder; showTo: BannerVisibility };
type Content = Record<BannerContents, Nullable<Record<Language, string>>>;

export class Banner {
  constructor(
    readonly id: string,
    readonly settings: Settings,
    readonly visibility: Visibility,
    readonly content: Content
  ) {}
}
