import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NoOp, Nullable } from '@libs/utils';
import { ErrorObjects } from '@portal/shared/helpers/error-manager/utils';
import { fromEvent, merge, Subscription, timer } from 'rxjs';

@Directive()
export class InputAbstract implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
  private touchEvents: Nullable<Subscription> = null;

  showClearButton: boolean = false;
  val: string = '';
  disabled = false;
  onChange: Nullable<NoOp>;
  onTouch: Nullable<NoOp>;

  @Input() additionalClass: string = '';
  @Input() placeholder: string = '';
  @Input() clearable: boolean = true;
  @Input() errors: ErrorObjects = [];
  @Input() active = false;
  @Input() defaultPlaceholder = '';
  @Input() type: string = 'text';
  @Input() autocomplete: string = '';
  @Input() maxlength: string | number = '';
  @Input() icon: boolean = false;
  @Input() noLabelPlaceholder: boolean = false;
  @Input() initialValue: string = '';
  @Input() mask: Nullable<string> = '';
  @Input() isTrim: boolean = false;
  @Input() tooltip: Nullable<string>;

  @ViewChild('input', { static: false }) input: Nullable<ElementRef> = null;

  get value(): string { return this.val; }

  set value(val: string) {
    if (val !== undefined && val !== null && val !== this.val) {
      this.val = this.isTrim ? val.trim() : val;
      this.showClearButton = !!this.val.length;
      this.onChange?.(this.val);
    }
  }

  ngOnInit(): void {
    if (!this.initialValue) { return; }
    this.val = this.initialValue;
  }

  ngAfterViewInit(): void {
    if (!this.input?.nativeElement) { return; }
    if (this.active) {
      timer(0).subscribe(() => this.input?.nativeElement.focus());
    }

    this.touchEvents = merge(
      fromEvent(this.input.nativeElement, 'focus'),
      fromEvent(this.input.nativeElement, 'blur')
    ).subscribe(() => this.onTouch?.());
  }

  ngOnDestroy(): void {
    this.touchEvents?.unsubscribe();
  }

  writeValue(value: string): void { this.value = value; }

  registerOnChange(fn: (val: string) => void): void { this.onChange = fn; }

  registerOnTouched(fn: NoOp): void { this.onTouch = fn; }

  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled; }

  clear(): void {
    this.value = '';
    this.input?.nativeElement.focus();
  }
}
