import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  inject
} from '@angular/core';
import { NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { ToolbarNavigationItem } from '@portal/config/shared';
import { ToolbarItemComponent } from '@portal/layout/components/toolbar';
import { LayoutData } from '@portal/layout/data';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PWAService } from '@portal/banners/shared';

@Component({
  standalone: true,
  selector: 'gg-layout-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: [ './toolbar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, RouterLinkActive, RouterLink, ToolbarItemComponent ]
})
export class ToolbarComponent {
  private readonly layoutData = inject(LayoutData);
  private readonly configQuery = inject(ConfigQuery);
  private readonly authorization = inject(SpAuthorizationService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly navigation: SpNavigation = inject(SpNavigation);
  private readonly authorized = this.authorization.authorized;

  protected readonly visible$ = combineLatest(this.breakpointObserver.observe('(max-width: 767px)'), this.navigation.event(NavigationEnd)).pipe(
   map(([{matches}, event]) => matches && !event.url.startsWith('/slots/'))
 );
  protected readonly menuItem: ToolbarNavigationItem = { icon: 'menu', label: 'TOOLBAR.MENU' };
  protected readonly navigationItems = this.buildNavigation();
  protected readonly inPWA = inject(PWAService).inPWA;

  open(): void { this.layoutData.toggleSidebar(true); }

  private buildNavigation(): Array<ToolbarNavigationItem> {
    return this.configQuery.modules.toolbar.menu.filter((item) => (this.authorized && item.forAuthorized) || item.forUnauthorized);
  }
}
