import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SpPlatform } from '@libs/cdk';
import { ChatAdapter } from '@portal/chats/shared';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'gg-chat',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatsComponent {
  private readonly userInfoQuery = inject(UserQuery);
  private readonly chatAdapter = inject(ChatAdapter);
  private readonly router = inject(Router);
  private readonly isMobile = inject(SpPlatform).type.mobile;
  private readonly isSidebarChat = inject(ConfigQuery).modules.contacts.supportChatInSidebar;

  constructor() {
    if (inject(SpPlatform).server || this.isSidebarChat) { return; }
    this.changeWidgetView();
    this.chatAdapter.init(this.userInfoQuery.id, { manual: false });
  }

  private changeWidgetView(): Subscription {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      if ((event.url.startsWith('/slots/') || event.url.includes('/sports')) && this.isMobile) {
        this.chatAdapter.hide();
      } else {
        this.chatAdapter.show();
      }
    });
  }
}
