import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { CardPartsModule } from '@portal/bonuses/components/card-parts';
import { Bonus } from '@portal/bonuses/data';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';

@Component({
  standalone: true,
  imports: [ SpCDKModule, BonusPipesModule, CardPartsModule, ThemeModule ],
  selector: 'gg-bonus-cropped-card',
  templateUrl: './cropped-card.component.html',
  styleUrls: [ './cropped-card.component.scss', './purple.cropped-card.component.scss', 'silver.cropped-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CroppedCardComponent {
  @Input() bonus: Nullable<Bonus> = null;

  get isWelcomeBonus(): boolean {
    return this.bonus?.type === 'casino_linked';
  }
}
