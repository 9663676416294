import { CryptoProcessingPaymentMethods } from '@portal/cashier/types/enums/payment-methods/crypto-processing.enum';
import { OneTnMethods } from '@portal/cashier/types/enums/payment-methods/onetn-methods.enum';

export const CRYPTO_METHODS = [
  CryptoProcessingPaymentMethods.Withdrawal,
  CryptoProcessingPaymentMethods.WithdrawalUsdtt,
  CryptoProcessingPaymentMethods.WithdrawalUsdte,
  OneTnMethods.Withdrawal,
  OneTnMethods.WithdrawalUSDTT,
  OneTnMethods.WithdrawalUSDTE,
  OneTnMethods.WithdrawalTest
];
