import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery, Project, ThemeModule } from '@portal/config';
import { NotificationBulletComponent } from '@portal/shared/components/content/notification-bullet';
import { UserInfo, UserQuery } from '@portal/user/data';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-user-rewards-icon',
  templateUrl: './rewards-icon.component.html',
  styleUrls: [ './rewards-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ThemeModule, RouterLink, NotificationBulletComponent ]
})
export class RewardsIconComponent {
  private readonly userQuery = inject(UserQuery);
  private readonly configQuery = inject(ConfigQuery);

  protected readonly icon$ = this.userQuery.level$.pipe(
    map((level) => this.getLevelIcon(level))
  );

  private getLevelIcon(level: UserInfo['level']): `${string}/${Project['name']}/${string}.svg` {
    return `assets/images/header/rewards/${this.configQuery.project.name}/${level?.current || 1}.svg`;
  }
}
