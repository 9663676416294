import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SpStorage } from '@libs/cdk';
import { StorageKey } from '@portal/shared/types';

export const saveReturnPathGuard = (): boolean => {
  const storage = inject(SpStorage);
  const router = inject(Router);
  const savedRoute = storage.getItem(StorageKey.ReturnPath);
  const prevRoute = router.url;
  const isSlotRoute = savedRoute ? savedRoute.includes('slots') : null;
  const restrictedSaveRoute = [ 'registration', 'login', 'away' ].find((page) => prevRoute.includes(page));
  const isCashierRoute = savedRoute ? savedRoute.includes('cashier') : null;
  const isBonusesRoute = savedRoute ? savedRoute.includes('bonuses') : null;
  const isUserRoute = savedRoute ? savedRoute.includes('user') : null;

  if (!isSlotRoute && !restrictedSaveRoute && !isCashierRoute && !isBonusesRoute && !isUserRoute) { storage.setItem(StorageKey.ReturnPath, prevRoute); }

  return true;
};
