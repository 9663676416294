import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { NextStepDirective, PrevStepDirective, ProvideDirective } from './stepper.directive';
import { StepperComponent } from './stepper.component';

@NgModule({
  imports: [ SpCDKModule, CdkStepperModule ],
  declarations: [ StepperComponent, PrevStepDirective, NextStepDirective, ProvideDirective ],
  exports: [ StepperComponent, CdkStepperModule, PrevStepDirective, NextStepDirective, ProvideDirective ]
})
export class StepperModule {}
