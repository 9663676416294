<div class="footer-wrapper">
  <footer class="footer">
    <section class="footer__menu-block">
      <nav class="menu-wrapper" [ngClass]="{'menu-wrapper--no-social': !socials.length}">
        <gg-layout-footer-navigation headline="FOOTER.PRODUCT" [items]="navigation.list"/>
        <gg-layout-footer-navigation [headline]="security.title" [items]="security.list"/>
        <gg-layout-footer-navigation headline="FOOTER.CONTACTS" [items]="contacts" view="contacts"/>
        <section>
          <div class="info" *ngIf="!!socials.length">
            <div class="info__title" spMsg="FOOTER.FOLLOW_US"></div>
            <div class="info__social">
              @for (link of socials; track $index) {
                <a target="_blank" href="{{link.url}}" class="info__item">
                  <img [src]="'{project}/' + link.icon | path" alt="" class="info__img" aria-hidden="true" loading="lazy"/>
                </a>
              }
            </div>
          </div>
          @if (partner) {
            <div class="partner">
              <div class="partner__title" [spMsg]="partner.title"></div>
              <div class="partner__img">
                <a href="{{partner.link}}">
                  <img [src]="partner.icon" [alt]="partner.alt" [title]="partner.iconTitle" aria-hidden="true" loading="lazy"/>
                </a>
              </div>
            </div>
          }
        </section>
      </nav>
    </section>

    @if (paymentMethods.length) {
      <gg-layout-footer-slider [items]="paymentMethods" section="payments" [authorized]="authorized"/>
    }
    @if (providers.length) {
      <gg-layout-footer-slider [items]="providers" section="providers"/>
    }

    <section class="extra-info-wrapper section">
      <gg-layout-footer-section class="extra-info">
        <div class="extra-info__items">
          <div class="extra-info__item">
            <img [src]="'{project}/images/footer/additional-info/' + warningIcon + '.svg' | path" class="extra-info__icon" alt="Adult" loading="lazy"/>
          </div>
          @for (license of licenses; track license.link) {
            <div *ngFor="let licence of licenses">
              <a *ngIf="licence.link" [href]="licence.link | cleanAndReplace" target="_blank" class="extra-info__item">
                <img [src]="licence.icon | cleanAndReplace" alt="" [width]="licence.width"/>
              </a>
            </div>
          }
        </div>
        @if (generalTerms.length) {
          <div class="extra-info_description">
            @for (terms of generalTerms; track $index) {
              <span class="extra-info__text" [innerHTML]="terms | translate | cleanAndReplace"></span>
<!--              <span class="extra-info__text" [spMsg]="terms"></span>-->

            }
          </div>
        }
        <span class="extra-info__text rtp" *ngIf="rtp" spMsg="FOOTER.RTP_INFO"></span>
      </gg-layout-footer-section>
    </section>

    <div class="timer" *ngIf="timer$">{{ timer$ | async }}</div>
  </footer>
</div>
