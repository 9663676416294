import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';
import { Nullable } from '@libs/utils';

export const createBankCardForm = (): FormGroup<{
  account: FormControl<Nullable<string>>;
  id: FormControl<Nullable<string>>;
  holder: FormControl<Nullable<string>>;
  expMonth: FormControl<Nullable<string>>;
  cvv: FormControl<Nullable<string>>;
  type: FormControl<Nullable<string>>;
  expYear: FormControl<Nullable<string>>;
}> => new FormGroup({
  id: new FormControl<Nullable<string>>(''),
  holder: new FormControl<Nullable<string>>(''),
  account: new FormControl<Nullable<string>>('', { validators: Validators.required }),
  expMonth: new FormControl<Nullable<string>>(''),
  expYear: new FormControl<Nullable<string>>(''),
  cvv: new FormControl<Nullable<string>>('', { validators: validationRules.cvv }),
  type: new FormControl<Nullable<string>>('')
});
