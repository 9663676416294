import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-bank-card',
  templateUrl: './input-bank-card.component.html',
  styleUrls: [ './input-bank-card.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputBankCardComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputBankCardComponent extends InputAbstract {
  @Input() override mask: Nullable<string> = '0000 0000 0000 0000 0000';
  @Input() override autocomplete = 'cc-number';
}
