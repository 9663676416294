import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, DestroyRef, inject, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule } from '@libs/cdk';
import { arrayalize, Nullable } from '@libs/utils';
import { Banner, BannersQuery } from '@portal/banners/data';
import { bannersByTypes } from '@portal/banners/features/socket/banners-by-types';
import { BannerComponent, BannerPage, BannerSection } from '@portal/banners/shared';
import { Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ContentModule } from '@portal/shared/components';

@Component({
  selector: 'gg-banner-socket',
  standalone: true,
  imports: [ SpCDKModule, ContentModule ],
  templateUrl: './socket.component.html',
  styleUrls: [ './socket.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocketComponent implements AfterViewInit {
  private readonly query = inject(BannersQuery);
  private readonly authorized$ = inject(SpAuthorizationService).authorized$;
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly cd = inject(ChangeDetectorRef);

  @Input() section: Nullable<BannerSection> = null;
  @Input() page: Nullable<BannerPage> = null;
  @Input() banners: Nullable<Banner> = null;

  @ViewChild('container', { read: ViewContainerRef }) readonly container: Nullable<ViewContainerRef> = null;

  ngAfterViewInit(): void {
    if (!this.container) { return; }

    const source$ = this.banners ? of(arrayalize(this.banners)) : this.selectBannersFromStore();
    source$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((banners) => {
      this.container?.clear();
      banners.forEach(banner => {
        const componentType = bannersByTypes[banner.settings.type];
        const ref: Nullable<ComponentRef<BannerComponent>> = this.container?.createComponent(componentType);
        if (ref) { ref.instance.banner = banner; }
      });
      this.cd.detectChanges();
    });
  }

  private selectBannersFromStore(): Observable<Array<Banner>> {
    return this.authorized$.pipe(
      map((authorized) => ({ page: this.page, section: this.section, authorized })),
      switchMap((filter) => this.query.selectVisible(filter)),
      distinctUntilChanged((curr, prev) => curr.length === prev.length)
    );
  }
}
