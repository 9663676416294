import { AfterViewInit, Directive, ElementRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { isNullish, Nullable } from '@libs/utils';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({ selector: '[ggSwiper]' })
export class SwiperDirective implements AfterViewInit, OnChanges {
  private readonly element: ElementRef<SwiperContainer> = inject(ElementRef<SwiperContainer>);
  private readonly platform = inject(SpPlatform);

  @Input() config?: Nullable<SwiperOptions>;
  @Input() index: Nullable<SwiperContainer['swiper']['activeIndex']>;
  @Input() slideChangedListener: Nullable<boolean> = false;
  @Output() slideChanged: EventEmitter<number> = new EventEmitter<number>();

  ngAfterViewInit(): void {
    if (isNullish(this.element) || this.platform.server) { return; }

    const config = !isNullish(this.index) ? { ...this.config, initialSlide: this.index ?? 0 } : this.config;
    Object.assign(this.element.nativeElement, config);
    this.element.nativeElement.initialize();

    if (this.slideChangedListener) {
      this.element?.nativeElement.addEventListener('slidechange', (event$) => {
        this.slideChanged.emit(event$.detail[ 0 ].activeIndex);
      });
    }
  }

  ngOnChanges({ index }: SimpleChanges): void {
    if (index?.currentValue !== index?.previousValue) {
      this.element.nativeElement.swiper?.slideTo(index?.currentValue);
    }
  }
}
