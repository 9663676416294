import {
  Bonus,
  BonusContainerWelcomeDto,
  createBonuses,
  mapActivation,
  mapExpiration,
  mapInformation,
  mapInStatus,
  mapWager
} from '@portal/bonuses/data';
import { AbstractBonus } from '@portal/bonuses/data/abstracts';
import { mapMaxWinType } from '@portal/bonuses/data/mappers/partials/max-win';
import {
  BonusActivation,
  BonusExpiration,
  BonusInformation,
  BonusInStatus,
  BonusWager
} from '@portal/bonuses/shared';
import { BonusMaxWinDto } from '@portal/bonuses/shared/types/bonus-max-win';

export class BonusContainerWelcome extends AbstractBonus<BonusContainerWelcomeDto> {
  readonly bonuses: Array<Bonus> = createBonuses(this.source.linkedBonuses);

  readonly status: BonusInStatus = mapInStatus(this.source.status);
  readonly activation: BonusActivation = mapActivation(this.source);
  readonly expiration: BonusExpiration = mapExpiration(this.source);
  readonly wager: BonusWager = mapWager(this.source);
  readonly information: BonusInformation = mapInformation(this.source);
  readonly maxWin: BonusMaxWinDto = mapMaxWinType(this.source);
}

