import { SpAuthorizationConfig } from '@libs/authorization';
import { USER_AGENT } from '@ng-web-apis/common';
import { StorageKey } from '@portal/shared/types';
import { AuthorizationCommands } from './authorization.commands';

export const authorizationConfig: SpAuthorizationConfig = {
  routes: {
    signIn: [
      '/api/2/auth/login/',
      '/api/2/auth/registration',
      '/api/2/auth/password/create/',
      '/api/2/phone/send_code/',
      '/api/2/auth/register/',
      '/api/2/auth/password/recover/send/',
      '/api/2/auth/password/recover/url/',
      '/api/2/auth/social/'
    ],
    signOut: '/api/2/auth/logout/',
    refresh: '/api/2/auth/tokens/',
    discovery: '/api/2/user/'
  },
  commands: { useClass: AuthorizationCommands, deps: [ USER_AGENT ] },
  returnKey: StorageKey.ReturnPath,
  refreshKey: 'refresher',
  checkOnInitialize: true,
  checkBy: 'discovery'
};
