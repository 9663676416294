import { ThreeDSPaymentDataDto } from '@portal/cashier/data';

export class ThreeDSecurePaymentData {
  constructor(
    readonly exist: boolean,
    readonly auth3dsValue?: string,
    readonly redirectUrl?: string,
  ) {}

  static create(paymentData: ThreeDSPaymentDataDto): ThreeDSecurePaymentData {
    return new ThreeDSecurePaymentData(paymentData.exist, paymentData.auth_3ds_value, paymentData.redirect_url);
  }
}
