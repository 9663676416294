<ng-template #sidebar>
  <nav class="main-menu-wrapper">
    <img src="assets/images/icons/cross.svg" class="close-menu-button" alt="" (click)="close()"/>
    @if (authorized) {
      <div class="main-menu__user">
        <div class="main-menu__user-block" (click)="close(['/user', 'settings'])">
          <img [src]="'{project}/images/main-menu/{palette}/user.svg' | path" class="main-menu__user-icon" [alt]=""/>
          @if (nickname$ | spAsync; as nick) {
            <span class="main-menu__user-nick">{{ nick }}</span>
          }
        </div>
        <gg-layout-user-balance withDepositButton="true" class="main-menu__user-balance" [section]="'sidebar'"/>
      </div>
    }
    <div class="menu-container" [ngClass]="{'menu-container__auth': authorized}">
      <gg-layout-sidebar-sections [sections]="sections$ | spAsync"/>
    </div>
    @if (isShowChat) {
      <gg-chat-sidebar/>
    }
    <div class="main-menu-footer">
      <gg-dropdown-language-switcher position="above"/>
      @if (authorized) {
        <div class="menu_item logout" (click)="close(['/auth', 'logout'])">
          <img class="menu-item-icon" [src]="'{project}/images/main-menu/{palette}/logout.svg' | path" alt=""/>
          <span class="menu-item-label" spMsg="HEADER.LOGOUT"></span>
        </div>
      }
    </div>
    <gg-banner-socket class="main-menu-wrapper__banner" page="menu" section="bottom"/>
  </nav>
</ng-template>
