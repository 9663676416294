import { ArticleSystemInfo } from '../core/partials';
import { ArticleDto } from '../dto/article';

export class NewsArticle {

  readonly type = 'news';

  constructor(
    readonly id: string,
    readonly slug: string,
    readonly content: ArticleDto['i18n'],
    readonly system: ArticleSystemInfo
  ) {}
}
