import { Validators } from '@angular/forms';
import { DEPOSIT_FORM } from '@portal/cashier/constants/default-form-declaration.constant';
import { CustomValidators } from '@portal/shared/helpers';

export const USER_DATA_INFO_MESSENGER = {
  ...DEPOSIT_FORM,
  city: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  address: [
    '', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  username: [
    '', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64),
      CustomValidators.username
    ]
  ],
  messenger: [
    '', [
      Validators.required
    ]
  ]
};






