import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeKk from '@angular/common/locales/kk';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import localeUz from '@angular/common/locales/uz';

import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SpCDKModule } from '@libs/cdk';
import { ChatsComponent, ChatsModule } from '@portal/chats';
import { CoreModule } from '@portal/core';
import { PaymentDialogWidgetComponent, PaymentDialogWidgetModule } from '@portal/payment';
import { LanguageDialogComponent, LanguageDialogModule } from '@portal/user';
import { WrapperComponent } from 'apps/portal/src/app/layout';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeKk, 'kk');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeUz, 'uz');

const general = [ BrowserModule, BrowserAnimationsModule, AppRouting, CoreModule, SpCDKModule ];
const bootstrap = [ ChatsModule, LanguageDialogModule, PaymentDialogWidgetModule ];
const components = [ WrapperComponent ];

@NgModule({
  imports: [ general, bootstrap, components, ServiceWorkerModule.register('worker.js', {
    enabled: !isDevMode(),
    registrationStrategy: 'registerImmediately',
  }) ],
  declarations: [ AppComponent ],
  providers: [ provideClientHydration(withNoHttpTransferCache()) ],
  bootstrap: [ AppComponent, ChatsComponent, LanguageDialogComponent, PaymentDialogWidgetComponent ],
})
export class AppModule {}
