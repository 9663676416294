import { ClassConstructor } from '@libs/utils';
import { WidgetParams } from '@portal/payment/shared';
import { PaymentFinancialComponent } from './payment-financial';
import { PaymentHistoryComponent } from './payment-history';
import { PaymentTransferComponent } from './payment-transfer';

export * from './payment-history';
export * from './payment-financial';
export * from './payment-transfer';

export const paymentSections: Record<WidgetParams['section'], ClassConstructor> = {
  deposit: PaymentFinancialComponent,
  withdrawal: PaymentFinancialComponent,
  transfers: PaymentTransferComponent,
  history: PaymentHistoryComponent
};
