import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Country } from '@portal/countries';
import { SelectExchangeRates } from '@portal/currencies/commands/select-exchange-rates';
import { CurrenciesMapper, CurrenciesRepository, FiatCurrency, FiatCurrencyDto, SelectedCurrencies, SelectFiatSettings } from '@portal/currencies/data';
import { ResponseFactory } from '@portal/shared/helpers';
import { catchError, filter, forkJoin, map, Observable, tap, throwError } from 'rxjs';
import { first } from 'rxjs/operators';

type CurrenciesList = { _items: Array<FiatCurrencyDto> };

export class SelectFiatCurrencies {
  private readonly http = inject(HttpClient);
  private readonly repo = inject(CurrenciesRepository);
  private readonly selectExchangeRates = inject(SelectExchangeRates);

  receive<S extends SelectFiatSettings>(settings: S = {} as S): Observable<SelectedCurrencies<S>> {
    const list = settings.list || 'all';

    return this.repo.steroid.query.select('fiat').pipe(
      tap((currencies) => !currencies[ list ] && this.loadCurrencies(list)),
      filter((currencies): currencies is Record<typeof list, Array<FiatCurrency>> => Boolean(currencies[ list ])),
      map((currencies) => CurrenciesMapper.filterCurrencies(currencies[ list ], settings)),
    );
  }

  private loadCurrencies(list: Country['isoCode'] | 'all'): void {
    const params = list !== 'all' ? { params: { country: list } } : undefined;

    forkJoin([
      this.selectExchangeRates.receive().pipe(first()),
      this.http.get<CurrenciesList>('/api/2/currencies/', params),
    ]).pipe(
      map(([ rates, { _items: currencies } ]) => CurrenciesMapper.toDomain(currencies, rates)),
      catchError((error) => throwError(() => ResponseFactory.error(error))),
    ).subscribe((currencies) => this.repo.update({ fiat: { [ list ]: currencies } }));
  }
}
