import { Component, ChangeDetectionStrategy, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENTS_TYPES, DOCUMENTS_UPLOAD_FORM, DocumentUpload, UserDocument } from '@portal/verification/shared';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { FileViewer } from '@portal/shared/helpers';
import { SpCDKModule } from '@libs/cdk';
import { UploadCardInfoComponent } from '@portal/verification/components/card/upload-card-info/upload-card-info.component';
import { Nullable } from '@libs/utils';
import { DocumentsTypes, DocumentVerificationUploadingState } from '@portal/verification/shared/enums';
import { DocumentsUploadModule } from '@portal/verification/components/documents-upload';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, UploadCardInfoComponent, DocumentsUploadModule ],
  selector: 'gg-verification-card-upload-form',
  templateUrl: './card-upload-form.component.html',
  styleUrls: [ './card-upload-form.component.scss' ],
  providers: [ DocumentUpload ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardUploadFormComponent implements OnDestroy {
  private readonly fb = inject(FormBuilder);
  private readonly fileViewer = inject(FileViewer);
  private readonly documentUpload = inject(DocumentUpload);
  private readonly userInfoQuery = inject(UserQuery);

  private readonly eventsSubscription: Subscription = new Subscription();

  readonly documentUploadingState$: BehaviorSubject<DocumentVerificationUploadingState> = new BehaviorSubject(
    DocumentVerificationUploadingState.Upload as DocumentVerificationUploadingState
  );
  readonly selectedDocument$ = this.fileViewer.file$;
  readonly documents$: Observable<Nullable<Array<UserDocument>>>;
  readonly cardUploadForm: FormGroup = this.fb.group(DOCUMENTS_UPLOAD_FORM);
  readonly documentUploadingState: typeof DocumentVerificationUploadingState = DocumentVerificationUploadingState;
  readonly docTypes: typeof DocumentsTypes = DocumentsTypes;
  readonly documentsTypes = DOCUMENTS_TYPES;

  readonly editButton: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Medium };
  readonly saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Medium };

  constructor() {
    this.documents$ = this.userInfoQuery.documents$.pipe(
      map((documents) => documents?.reduce((paymentDocuments: Array<UserDocument>, document: UserDocument) => {
        return document.name === this.docTypes.PaymentMethod
          ? [ ...paymentDocuments, { ...document, name: this.documentsTypes[document.name as keyof typeof DOCUMENTS_TYPES] } ]
          : paymentDocuments;
      }, []))
    );

    this.eventsSubscription.add(this.documentUpload.uploadDocumentSuccess$.subscribe((md5) => {
      this.documentUpload.saveDocument(md5, this.docTypes.PaymentMethod);
    }));

    this.eventsSubscription.add(this.documentUpload.failureNotification$.subscribe(() =>
      this.documentUploadingState$.next(DocumentVerificationUploadingState.Error)
    ));

    this.eventsSubscription.add(this.documentUpload.successNotification$.subscribe(() =>
      this.documentUploadingState$.next(DocumentVerificationUploadingState.Upload)
    ));
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  fileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target?.files;

    if (files && files?.length > 0) {
      const file = files[0];
      this.fileViewer.readFileData(file);
      this.documentUploadingState$.next(DocumentVerificationUploadingState.TypeSelection);
      this.cardUploadForm?.get('document')?.setValue(file);
    }
  }

  cancel(): void {
    this.cardUploadForm.get('document')?.setValue(null);
    this.documentUploadingState$.next(DocumentVerificationUploadingState.Upload);
  }

  confirm(): void {
    this.documentUploadingState$.next(DocumentVerificationUploadingState.Progress);
    this.documentUpload.uploadDocument(this.cardUploadForm.get('document')?.value);
  }
}
