<section class="menu" *spLet="expanded$ | spAsync as expanded">
  <div class="menu__header" (click)="expand()">
    <div class="menu__title" [spMsg]="headline"></div>
    <img src="assets/images/icons/arrow-small.svg" class="menu__arrow" [class.menu__arrow--open]="expanded" alt="arrow-icon"/>
  </div>
  @if (expanded) {
    <ng-container [ngTemplateOutlet]="view === 'list' ? listView : contactsView"/>
  }
</section>

<ng-template #listView>
  <ul class="menu__items" *ngIf="view === 'list'">
    @for (item of items; track $index) {
      <li class="menu__item">
        <a [href]="item.path" (click)="navigate(item)" class="menu__link" [spMsg]="item.text"></a>
      </li>
    }
  </ul>
</ng-template>

<ng-template #contactsView>
  <ul class="contacts">
    <li class="contacts__item" *ngFor="let item of items">
      <a class="contacts__link" [href]="item.path">
        <span class="contacts__icon-box">
          <img class="contacts__icon" alt="" [src]="item.icon"/>
        </span>
        <div class="contacts__text-box">
          <div class="contacts__title" [spMsg]="item.text"></div>
          <div class="contacts__info-text">{{ item.info }}</div>
        </div>
      </a>
    </li>
  </ul>
</ng-template>
