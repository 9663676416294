import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-progress',
  templateUrl: './progress.component.html',
  styleUrls: [ './progress.component.scss', './purple.progress.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus> = null;

  get isFreeSpinBonus(): boolean { return this.bonus?.type === 'casino_freespin'; }

  get isWageringStatus(): boolean { return !!this.bonus && [ 'wager', 'active' ].includes(this.bonus.status.current); }
}
