import { OrArray } from '@libs/utils';
import { CryptoCurrencyDto } from '@portal/currencies/data/dto';
import { CryptoCurrency } from '@portal/currencies/data/entities';

export class CryptoCurrenciesMapper {
  static toDomain(dto: CryptoCurrencyDto): CryptoCurrency;
  static toDomain(dto: Array<CryptoCurrencyDto>): Array<CryptoCurrency>;
  static toDomain(dto: OrArray<CryptoCurrencyDto>): OrArray<CryptoCurrency> {
    if (Array.isArray(dto)) { return dto.map((crypto) => CryptoCurrenciesMapper.toDomain(crypto)); }
    return new CryptoCurrency(dto);
  }
}
