import { VerificationSectionType } from '@portal/verification/shared/enums';

export const VERIFICATION_TABS: Record<string, Record<string, string>> = {
  kyc: {
    label: 'VERIFICATION.HEADER.TAB_MAIN',
    value: VerificationSectionType.KYC
  },
  basic: {
    label: 'VERIFICATION.HEADER.TAB_ADDITIONAL',
    value: VerificationSectionType.Basic
  }
};
