<div ggCreateThemeClass="profile-info">
  <form
    class="profile-info"
    [formGroup]="form"
    (ngSubmit)="save()"
    (keyup.enter)="save()"
  >
    <div class="profile-info__user-id">
      <p class="profile-info__user-id-title" spMsg="PROFILE.USER_ID"></p>
      <div class="profile-info__user-id-copy">
        <span class="profile-info__user-id-copy-value">{{userId}}</span>
        <gg-clipboard-copy [copyValue]="userId"></gg-clipboard-copy>
      </div>
    </div>

    <div class="profile-info__initial-data" *ngIf="isInitialForm() else noInitialForm">
      <gg-input
        class="profile-info__input"
        formControlName="first_name"
        [placeholder]="'PROFILE.PERSONAL_INFO.FIRST_NAME' | translate"
        [errors]="errors.get('first_name') | async">
      </gg-input>
      <gg-input
        class="profile-info__input"
        formControlName="last_name"
        [placeholder]="'PROFILE.PERSONAL_INFO.LAST_NAME' | translate"
        [errors]="errors.get('last_name') | async">
      </gg-input>
      <gg-input
        class="profile-info__input"
        [tooltip]="'PROFILE.PERSONAL_INFO.CAN_NOT_BE_CHANGED' | translate"
        formControlName="nick"
        [placeholder]="'PROFILE.PERSONAL_INFO.NICK' | translate"
        [errors]="errors.get('nick') | async">
      </gg-input>
      <gg-button
        [settings]="saveButton"
        [fullWidth]="true"
        class="profile-info__button"
        [disabled]="form.invalid"
        (buttonClicked)="save()">
        <sp-msg msg="PROFILE.PERSONAL_INFO.SAVE"></sp-msg>
      </gg-button>
    </div>

    <ng-template #noInitialForm>
      <div class="profile-info__data" *spLet="form.getRawValue() as value">

        <div class="profile-info__data-info">
          <ng-container *ngIf="!isEditSection.first_name">
            <div class="profile-info__data-info-title">
              <sp-msg msg="PROFILE.PERSONAL_INFO.FIRST_NAME"></sp-msg>
              <span class="profile-info__data-info-subtitle" (click)="showEditContainer('first_name')" spMsg="PROFILE.CHANGE"></span>
            </div>
            <div class="profile-info__data-info-value">{{ value.first_name }}</div>
          </ng-container>

          <ng-container *ngIf="isEditSection.first_name">
            <gg-input
              class="profile-info__input"
              formControlName="first_name"
              [placeholder]="'PROFILE.PERSONAL_INFO.FIRST_NAME' | translate"
              [errors]="errors.get('first_name') | async">
            </gg-input>
            <gg-button
              [settings]="saveButton"
              [fullWidth]="true"
              class="profile-info__button"
              [disabled]="form.controls.first_name.invalid"
              (buttonClicked)="save('first_name')">
              <sp-msg msg="PROFILE.PERSONAL_INFO.SAVE"></sp-msg>
            </gg-button>
          </ng-container>
        </div>

        <div class="profile-info__data-info">
          <ng-container *ngIf="!isEditSection.last_name">
            <div class="profile-info__data-info-title">
              <sp-msg msg="PROFILE.PERSONAL_INFO.LAST_NAME"></sp-msg>
              <span class="profile-info__data-info-subtitle" (click)="showEditContainer('last_name')" spMsg="PROFILE.CHANGE"></span>
            </div>
            <div class="profile-info__data-info-value">{{ value.last_name }}</div>
          </ng-container>

          <ng-container *ngIf="isEditSection.last_name">
            <gg-input
              class="profile-info__input"
              formControlName="last_name"
              [placeholder]="'PROFILE.PERSONAL_INFO.LAST_NAME' | translate"
              [errors]="errors.get('last_name') | async">
            </gg-input>
            <gg-button
              [settings]="saveButton"
              [fullWidth]="true"
              class="profile-info__button"
              [disabled]="form.controls.last_name.invalid"
              (buttonClicked)="save('last_name')">
              <sp-msg msg="PROFILE.PERSONAL_INFO.SAVE"></sp-msg>
            </gg-button>
          </ng-container>
        </div>

        <div class="profile-info__data-info">
          <div class="profile-info__data-info-title">
            <sp-msg msg="PROFILE.PERSONAL_INFO.NICK"></sp-msg>
          </div>
          <div class="profile-info__data-info-value">{{ value.nick }}</div>
        </div>
      </div>
    </ng-template>
  </form>
</div>



