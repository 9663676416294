import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { PostActionAbstract } from '@portal/payment/shared';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule ],
})
export class NoActionComponent extends PostActionAbstract implements OnInit {
  ngOnInit(): void {
    if (this.processErrors()) { return; }

    const { data } = this.result;
    const operation = this.builder.snapshot.operation;

    if (data.url && operation === 'deposit') {
      timer(300).pipe(first()).subscribe(() => this.navigation.navigate(data.url!));
      this.builder.reopen();
      this.paramsData.setPayment({ method: undefined });
    } else {
      this.setStatus({ invoice: data?.initiatorId || undefined });
    }
  }
}
