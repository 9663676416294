import { inject } from '@angular/core';
import { GlobalWindow, Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ChatAdapter, InitConfig, LiveChatWidget, loadLiveChat, VisibilityChangedData } from '@portal/chats/shared';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

export class LiveChatAdapter extends ChatAdapter {
  private readonly window = inject(WINDOW) as GlobalWindow<LiveChatWidget>;

  constructor(readonly liveChatId: string) {
    super();
  }

  init(userId: Nullable<string>, { manual = false }: InitConfig): void {
    loadLiveChat(this.liveChatId, userId);

    if (manual) {
      this.visibilityChange();
      this.defaultButtonHide();
    }
  }

  open(): void {
    this.window.LiveChatWidget.call('maximize');
  }

  close(): void {
    //todo not implemented
  }

  show(): void {
    this.window.LiveChatWidget.call('minimize');
  }

  hide(): void {
    this.window.LiveChatWidget.call('hide');
  }

  chatName(): string {
    return 'LiveChat';
  }

  private defaultButtonHide(): void {
    this.window.LiveChatWidget.call('hide');
  }

  private visibilityChange(): void {
    this.window.LiveChatWidget.on('visibility_changed', (data: VisibilityChangedData) => {
      if (data.visibility === 'minimized') {
        timer(100).pipe(
          take(1),
        ).subscribe(() => {
          this.defaultButtonHide();
        });
      }
    });
  }
}
