import { GameSection } from '@portal/games/data';
import { GameSectionDto } from '@portal/games/data';

export const createGameSection = ({
  name,
  key,
  displayOnTopMenu,
  displayOnSidebar,
  _id,
  image
}: GameSectionDto): GameSection => {
  return new GameSection(key, name, _id, {
    sidebar: displayOnSidebar,
    topMenu: displayOnTopMenu
  }, image);
}
