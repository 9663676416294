<section class="card-validation" [formGroup]="newCardForm">
  <gg-user-bank-card
    [newCardForm]="newCardForm"
    [existCardForm]="existCardForm"
    [errors]="errors"
  ></gg-user-bank-card>
  <gg-button
    class="card-validation__submit-button"
    [fullWidth]="true"
    [settings]="continueButton"
    [disabled]="newCardForm.invalid && existCardForm.invalid"
    [loading]="loading$ | async"
    (click)="validateBankCard()"
  >
    <sp-msg spMsg="COMMON.PROCEED"></sp-msg>
  </gg-button>
</section>
