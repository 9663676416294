import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ControlAdapter, Nullable } from '@libs/utils';
import { CardInfo } from '@portal/payment/shared';
import { ErrorManager } from '@portal/shared/helpers';

@Component({
  selector: 'gg-payment-cards-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: [ './card-details.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetailsComponent {
  private readonly ngControl = inject(NgControl, { optional: true });
  private readonly adapter: ControlAdapter<string> = new ControlAdapter(this.ngControl);

  errors: ErrorManager = inject(ErrorManager);

  @Input({ required: true }) card: Nullable<Partial<CardInfo>>;
  @Input() withCVVControl: boolean = true;

  get control(): FormControl { return this.adapter.control as FormControl; }
}
