import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { SpAuthorizationModule } from '@libs/authorization';
import { SpConnectorModule, SpLocalizationCoreModule, SpNotificationsCoreModule, SpStorageModule } from '@libs/cdk';
import { SpHttpModule } from '@libs/http';
import { AnalyticsModule } from '@portal/analytics/analytics.module';
import { authorizationConfig, httpConfig, localizationConfig, spConnectorConfig } from '@portal/core/configs';
import { errorHandlers } from '@portal/core/error-handlers';
import { initializers } from '@portal/core/initializers';
import { interceptors } from '@portal/core/interceptors';
import { SsrService } from '@portal/core/services';
import { RecaptchaConfig } from '@portal/recaptcha';
import { ControlsModule } from '@portal/shared/components';
import { TraceService } from '@sentry/angular-ivy';

@NgModule({
  imports: [
    SpStorageModule.available(),
    SpConnectorModule.init(spConnectorConfig),
    SpHttpModule.init(httpConfig),
    SpAuthorizationModule.init(authorizationConfig),
    SpLocalizationCoreModule.init(localizationConfig),
    SpNotificationsCoreModule,
    RecaptchaConfig.init(),
    AnalyticsModule,
    ControlsModule.init(),
  ],
  providers: [
    initializers, interceptors, errorHandlers, SsrService,
    { provide: TraceService, deps: [ Router ] },
  ],
})
export class CoreModule {}
