import { FormControl, FormGroup } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';

export const createChangePasswordForm = (): FormGroup => {
  return new FormGroup({
    oldPassword: new FormControl('', { nonNullable: true, validators: validationRules.password }),
    newPassword: new FormControl('', { nonNullable: true, validators: validationRules.newPassword }),
    confirmPassword: new FormControl('', { nonNullable: true, validators: validationRules.newPassword })
  }, { validators: validationRules.matchFields('newPassword', 'confirmPassword') });
};
