import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from './error-response.class';
import { SuccessResponse } from './success-response.class';

export class ResponseFactory {

  static success<Data, Meta = unknown>(response: object): SuccessResponse<Data, Meta> {
    if ('bonusBalanceBlocked' in response && 'hasBonusBalanceBlocked' in response) { return ResponseFactory.makeSuccessResponseForBalance<Data, Meta>(response); }
    if ('default_amount' in response && 'currency' in response) { return ResponseFactory.makeSuccessResponseForPaymentMethods<Data, Meta>(response); }
    if ('payment_status' in response) { return ResponseFactory.makeSuccessResponseForPaymentStatus(response); }
    if ('accounts' in response) {return ResponseFactory.makeSuccessResponseForCards(response); }
    if (!('_items' in response) && !('_item' in response)) { return ResponseFactory.makeSuccessResponse<Data, Meta>(response); }
    if (('_items' in response || '_item' in response) && '_meta' in response) { return new SuccessResponse<Data, Meta>(response as any); }

    return new SuccessResponse<Data, Meta>({});
  }

  static error(response: object): ErrorResponse {
    if ('error' in response) {
      if (typeof response.error === 'string') { return ResponseFactory.makeErrorFromString(response); }
      if (response.error && ('_error' in (response.error as object) || '_issues' in (response.error as object)) && '_status' in (response.error as object)) { return new ErrorResponse(response as HttpErrorResponse); }
      if (response.error && (response.error as Array<unknown>).length) { return ResponseFactory.makeErrorForSettings(response); }
    }
    if ('errors' in response) { return ResponseFactory.makerErrorFromErrorsObject(response); }

    return {} as ErrorResponse;
  }

  private static makeSuccessResponse<Data, Meta = unknown>(response: any): SuccessResponse<Data, Meta> {
    response = !response.result ? { _item: null, _items: [], _meta: {} as Meta } : response;
    return new SuccessResponse<Data, Meta>(response);
  }

  private static makeSuccessResponseForPaymentMethods<Data, Meta = unknown>(response: any): SuccessResponse<Data, Meta> {
    return new SuccessResponse<Data, Meta>({ _item: response, _meta: {} as Meta, _items: [] });
  }

  private static makeSuccessResponseForBalance<Data, Meta = unknown>(response: any): SuccessResponse<Data, Meta> {
    return new SuccessResponse<Data, Meta>({ _item: response, _meta: {} as Meta, _items: [] });
  }

  private static makeSuccessResponseForCards<Data, Meta = unknown>(response: any): SuccessResponse<Data, Meta> {
    return new SuccessResponse<Data, Meta>({ _item: null, _meta: {} as Meta, _items: response.accounts });
  }

  private static makeSuccessResponseForPaymentStatus<Data, Meta = unknown>(response: any): SuccessResponse<Data, Meta> {
    return new SuccessResponse<Data, Meta>({ _item: response, _meta: {} as Meta, _items: [] });
  }

  private static makeErrorFromString(response: any): ErrorResponse {
    return new ErrorResponse(
      new HttpErrorResponse({ error: { _error: response.error, _status: response.status } })
    );
  }

  private static makerErrorFromErrorsObject(response: any): ErrorResponse {
    const _issues = Object.entries(response.errors).reduce(
      (acc, [ key, err ]) => [
        ...acc, { code: Array.isArray(err) ? err : [ err ], field: key }
      ], [] as Array<any>
    );

    return new ErrorResponse(new HttpErrorResponse({ error: { _error: null, _issues, _status: 400 } }));
  }

  private static makeErrorForSettings(response: any): ErrorResponse {
    const _issues = (response.error as Array<any>).map(({ key, field }) => ({
      code: `${field}_${key}`,
      field
    }));

    return new ErrorResponse(new HttpErrorResponse({ error: { _error: null, _issues, _status: 400 } }));
  }
}
