import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { SpCDKModule } from '@libs/cdk';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ThemeModule ],
  selector: 'gg-verification-upload-doc-info',
  templateUrl: './upload-doc-info.component.html',
  styleUrls: [ './upload-doc-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDocInfoComponent {
  readonly projectName  = inject(ConfigQuery).project.name || '';
}
