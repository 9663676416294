import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaInputDialogComponent } from './recaptcha-input-dialog.component';

@NgModule({
  imports: [ SpCDKModule, RecaptchaModule, RecaptchaFormsModule, ReactiveFormsModule, ControlsModule ],
  declarations: [ RecaptchaInputDialogComponent ],
  exports: [ RecaptchaInputDialogComponent ]
})
export class RecaptchaInputDialogFeatureModule {}
