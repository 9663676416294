<div
  *spLet="isBalanceVisible$ | spAsync as isBalance"
  class="user-balance"
  ggCreateThemeClass="user-balance"
  [class.user-balance-silver]="isLight && section === 'sidebar'"
>
  <div
    class="user-balance__wrapper"
    [class.user-balance__wrapper_only-deposit]="!isBalance && section === 'header'"
  >
    @if (isBalance || section === 'sidebar') {
      <gg-user-balance [section]="section"/>
    }
    @if (showDepositButton) {
      <gg-button ggOpenPayments [settings]="isTablet$ | spAsync" (click)="close()"><sp-msg msg="HEADER.CASHIER"/></gg-button>
    }
  </div>
</div>
