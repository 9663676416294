import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { SpConnector, SpLocalization, SpNavigation, SpPlatform } from '@libs/cdk';
import { ClassConstructor, GlobalWindow, Nullable, switchTap } from '@libs/utils';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { AnalyticsProcessor } from '@portal/analytics/analytics.processor';
import { ReferralData } from '@portal/authorization/data';
import { PWADialogService } from '@portal/banners';
import { PWAService } from '@portal/banners/shared';
import { ConfigQuery, Project } from '@portal/config';
import { LayoutData, LayoutState } from '@portal/layout/data';
import { ConnectedEvents } from '@portal/shared/types';
import { UserData, UserQuery } from '@portal/user/data';
import { ScriptService } from 'ngx-script-loader';
import { combineLatest, forkJoin, map, Observable, of, startWith, tap } from 'rxjs';

declare let QuestComponent: ClassConstructor;

type ExtendedWindow = {
  connector: SpConnector<ConnectedEvents>;
  userId: Nullable<string>;
  projectSource: Project['source'];
  QuestComponent: ClassConstructor;
};

@Component({
  selector: 'gg-root',
  templateUrl: './app.component.html',
  providers: [ PWADialogService ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly userData = inject(UserData);
  private readonly platform = inject(SpPlatform);
  private readonly user = inject(UserQuery);
  private readonly config = inject(ConfigQuery);
  private readonly connector = inject<ExtendedWindow['connector']>(SpConnector);
  private readonly navigation = inject(SpNavigation);
  private readonly localization = inject(SpLocalization);
  private readonly PWADialogService = inject(PWADialogService);
  private readonly PWAService = inject(PWAService);
  private readonly analyticsProcessor = inject(AnalyticsProcessor);
  private readonly window = inject<GlobalWindow<ExtendedWindow>>(WINDOW);
  private readonly layoutData = inject(LayoutData);
  private readonly scriptLoader = inject(ScriptService);
  private readonly location = inject(LOCATION);

  readonly hasProjectSource = this.checkProjectSource();

  constructor() {
    if (!this.hasProjectSource) { return; }
    inject(ReferralData).collect();

    this.analyticsProcessor.createAnalyticHandlers();

    if (!this.PWAService.inPWA && this.platform.browser) {
      const mediaQueryList = this.window.matchMedia('(display-mode: standalone)');
      mediaQueryList.addEventListener('change', (e: MediaQueryListEvent) => e.matches && this.location.reload());
    }

    this.connector.listen('user:trueplay-tokens').subscribe(({ payload }) => {
      this.userData.updateUserTruePlayBalance(payload);
    });

    this.PWADialogService.init();

    this.setWindowProps();

    this.getAppStateStream().pipe(
      switchTap(() => this.loadAdditionalScripts()),
    ).subscribe((state) => this.layoutData.setAppState(state));

    this.userData.createUserHandler(this.localization.currentLanguage);
  }

  private checkProjectSource(): boolean {
    if (this.config.project.source) { return true; }
    this.navigation.navigate([ '/error', 'uncaught' ], null, { skipLocationChange: true });
    return false;
  }

  private getAppStateStream(): Observable<LayoutState['app']> {
    if (!this.config.project?.source) { return of('error'); }

    const start = this.navigation.event(NavigationStart);
    const query = this.navigation.query();
    const frameReady = this.connector.listen('frame:ready').pipe(startWith(true), map(() => false));

    return combineLatest([ start, frameReady, query ]).pipe(
      map(([ { url }, hideContent, { empty } ]) => {
        const matchAuthorizationSections = !!url.match('/auth/social|autologin|logout');
        const matchSlotsMobileSection = !!url.match('/slots/') && this.platform.type.mobile && hideContent;
        return empty === 'true' || matchAuthorizationSections || matchSlotsMobileSection ? 'hidden' : 'ready';
      }),
    );
  }

  private loadAdditionalScripts(): Observable<unknown> {
    return forkJoin([
      this.scriptLoader.loadScript('/play/fs/files/initPG.js'),
      this.scriptLoader.loadScript('/play/fs/files/js/quest.component.js').pipe(tap(() => this.window.QuestComponent = QuestComponent)),
    ]);
  }

  private setWindowProps(): void {
    this.window.connector = this.connector;
    this.window.projectSource = this.config.project.source;
    this.window.userId = this.user.id;
  }
}
