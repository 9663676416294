import { ClassConstructor } from '@libs/utils';
import { P2pFlowComponent } from '@portal/payment/widgets/payment-dialog/sections/payment-financial/flows/p2p-flow/p2p-flow.component';
import { BankCardFlowComponent } from './bank-card-flow';
import { CryptoFlowComponent } from './crypto-flow';
import { GenericFlowComponent } from './generic-flow';

export * from './bank-card-flow';
export * from './generic-flow';

export const paymentFlows: Record<string, ClassConstructor> = {
  'bank_card': BankCardFlowComponent,
  'generic': GenericFlowComponent,
  'crypto': CryptoFlowComponent,
  'p2p_card': P2pFlowComponent,
  'p2p_sbp': P2pFlowComponent
};
