import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { isNullish } from '@libs/utils';
import { BALANCE_PANEL } from '@portal/bonuses/shared';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, CurrenciesModule ],
  selector: 'gg-bonuses-account-panel',
  templateUrl: './account.component.html',
  styleUrls: [ './account.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
  private readonly userQuery = inject(UserQuery);
  private readonly panel = inject(BALANCE_PANEL, { optional: true });
  private readonly isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(Boolean(this.panel?.initial));

  readonly button: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Small, fullWidth: true };
  readonly currency$ = this.userQuery.currency$;
  readonly isExpanded$ = this.isExpanded.asObservable();
  readonly balance$ = this.userQuery.bonusBalance$;

  toggleExpand(expand?: boolean): void {
    expand = isNullish(expand) || this.panel?.mode === 'auto' ? !this.isExpanded.getValue() : expand;
    this.isExpanded.next(expand);
  }
}
