import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-verification-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: [ './info-popup.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPopupComponent {
  private readonly dialog = inject(Dialog);
  readonly popupButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };

  closePopup(): void {
    this.dialog.closeAll();
  }
}
