import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { AuthorizationQuery } from '@portal/authorization/data';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { ContactDetailsService } from '@portal/user/features/settings/contact-details/contact-details.service';
import { PhoneVerificationOption, PhoneVerificationState, UserDataInfo } from '@portal/user/shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gg-user-settings-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: [ './contact-details.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ContactDetailsService ]
})
export class ContactDetailsComponent implements OnInit {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly authorizationQuery = inject(AuthorizationQuery);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly contactDetailsService = inject(ContactDetailsService);
  private readonly source = inject(ConfigQuery);

  readonly verificationState: typeof PhoneVerificationState = PhoneVerificationState;
  readonly saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };
  readonly isMobilePhoneEnabled: boolean = this.source.modules.forms.enableMobilePhone;
  readonly isFlashCallOption: boolean = this.authorizationQuery.phoneVerificationMethod === PhoneVerificationOption.FlashCall;

  phoneVerificationState$: Observable<PhoneVerificationState> = new Observable<PhoneVerificationState>();
  userVM: Observable<UserDataInfo> = new Observable<UserDataInfo>();

  ngOnInit(): void {
    this.phoneVerificationState$ = this.contactDetailsService.phoneVerificationState$;

    this.userVM = combineLatest([
      this.userInfoQuery.isEmailFaked$,
      this.userInfoQuery.email$,
      this.userInfoQuery.phone$,
      this.userInfoQuery.verification$
    ]).pipe(
      map(([ isEmailFaked, email, phone, verification ]) => {
        if (verification?.phone.confirmed) {
          this.contactDetailsService.updateVerificationState(PhoneVerificationState.Verified);
          this.cd.detectChanges();
        }
        return {
          emailConfirmed: verification?.email.confirmed,
          email: !isEmailFaked ? email : null,
          phone
        };
      }));
  }
}
