<div class="verification-popup">
  <img src="assets/images/icons/cross2.svg" class="verification-popup__close-icon" alt="" (click)="closePopup()"/>
  <h4 class="verification-popup__title">
    <sp-msg msg="VERIFICATION.HEADER.TITLE"/>
  </h4>
  <p class="verification-popup__paragraph">
    <sp-msg msg="VERIFICATION.INFO_POPUP.MAIN_TEXT"/>
  </p>
  <p class="verification-popup__paragraph">
    <sp-msg msg="VERIFICATION.INFO_POPUP.ADDITIONAL_TEXT"/>
  </p>
  <gg-button class="verification-popup__button" [settings]="popupButton" (buttonClicked)="closePopup()">
    <sp-msg msg="VERIFICATION.INFO_POPUP.BUTTON"/>
  </gg-button>
</div>
