import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SpCDKModule, SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ArticlesData, NewsArticle, PromoArticle } from '@portal/articles/data';
import { CommonPromotion, WelcomePromotion } from '@portal/promotions/data/entities';
import { PromotionsData } from '@portal/promotions/data/store';
import { ControlsModule } from '@portal/shared/components';
import { WINDOW } from '@ng-web-apis/common';
import { Page, PageData } from '@portal/static/data';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'gg-printable',
  templateUrl: './printable.component.html',
  styleUrls: [ './printable.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule ]
})
export class PrintableComponent {
  private readonly window = inject(WINDOW);
  private readonly pageData = inject(PageData);
  private readonly articlesData = inject(ArticlesData);
  private readonly notification = inject(SpNotification);
  private readonly promotionsData = inject(PromotionsData);

  @Input() section: Nullable<'article' | 'promotion' | 'faq' | 'page'> = null;
  @Input() entity: Nullable<NewsArticle | PromoArticle | WelcomePromotion | CommonPromotion| Page> = null;

  print(): void { this.window.print(); }

  save(): void {
    const request = this.getApiRequest();
    !request && this.notification.error('COMMON.FILE_UNAVAILABLE');
    this.getApiRequest()?.subscribe({
      next: (file) => this.createDownloadFile(file, this.entity?.slug),
      error: () => this.notification.error('COMMON.FILE_UNAVAILABLE')
    });
  }

  private getApiRequest(): Nullable<Observable<Blob>> {
    if (this.section === 'article' && this.entity instanceof NewsArticle || this.entity instanceof PromoArticle) {
      return this.articlesData.getFile(this.entity);
    }
    if (this.section === 'promotion' && this.entity instanceof WelcomePromotion || this.entity instanceof CommonPromotion) {
      return this.promotionsData.getFile(this.entity);
    }
    if (this.section === 'page' && this.entity instanceof Page) {
      return this.pageData.getFile(this.entity);
    }

    return null;
  }
  private createDownloadFile(file: Blob, name: Nullable<string>): void {
    const blob = new Blob([ file ], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a');
    const body = this.window.document.body;
    a.href = url;
    a.download = `${name}.txt`;

    body.appendChild(a);
    a.click();
    a.addEventListener('click', () => {
      body.removeChild(a);
      URL.revokeObjectURL(url);
    }, { once: true });
  }
}
