import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Nullable } from '@libs/utils';
import { BankCard, UserBankCardQuery } from '@portal/cashier/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { Observable } from 'rxjs';

@Component({
  selector: 'gg-delete-card-dialog',
  templateUrl: './delete-card-dialog.component.html',
  styleUrls: [ './delete-card-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCardDialogComponent {
  private readonly userCardsQuery = inject(UserBankCardQuery);
  private readonly dialog = inject(DialogRef);
  private readonly cardData: Nullable<BankCard> = inject(DIALOG_DATA);

  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly submitButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };

  cardNumber: Nullable<BankCard>;
  loading$: Nullable<Observable<boolean>>;

  constructor() {
    this.cardNumber = this.cardData;
  }

  deleteBankCard(): void {
    this.loading$ = this.userCardsQuery.loading$;
    this.dialog.close(this.cardNumber?.id);
  }

  close(): void {
    this.dialog.close();
  }
}
