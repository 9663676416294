import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData } from '@portal/bonuses/data';
import { ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';

@Component({
  selector: 'gg-bonus-card-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: [ './cancel.component.scss', './purple.cancel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelComponent {
  private readonly bonusesData = inject(BonusesData);

  readonly confirmButton: IButton = { theme: ButtonThemes.Gray, fullWidth: true };
  readonly rejectButton: IButton = { fullWidth: true };
  readonly currency$: Observable<Nullable<string>> = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus> = null;
  @Output() discard: EventEmitter<void> = new EventEmitter();

  cancel(): void { this.bonus && this.bonusesData.cancelBonus(this.bonus).subscribe(); }

  reject(): void { this.discard.emit(); }

}
