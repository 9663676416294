import { inject, Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { CurrencySymbolPipe } from '@portal/currencies/application/utils/currency-symbol.pipe';
import { CurrencySymbol, INTERKASSA_COMMISSION_TEXT } from '@portal/currencies/shared';
import { UserQuery } from '@portal/user';

@Pipe({ name: 'currencyNormalizer' })
export class CurrencyNormalizerPipe implements PipeTransform {
  private readonly user = inject(UserQuery);
  private readonly symbol = inject(CurrencySymbolPipe);

  transform(value: string | number): string {
    if (!value) { return ''; }

    // temporary solution
    if (value === INTERKASSA_COMMISSION_TEXT) { return `${value}%`; }

    let [ amount, currencyKey ] = String(value).split(' ') as [ string, Nullable<CurrencySymbol> ];
    amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    currencyKey = currencyKey || this.user.currency;

    const symbol = this.symbol.transform(currencyKey);

    return currencyKey && (currencyKey === 'USD' || currencyKey === 'EUR') ? `${symbol}${amount}` : `${amount} ${symbol}`;
  }
}
