import { Directive, DoCheck, ElementRef, inject, Input, PipeTransform, Renderer2 } from '@angular/core';
import { Nullable } from '@libs/utils';
import { TranslatePipe } from '@ngx-translate/core';
import { SP_LOCALIZATION_TRANSFORM } from './config';
import { MessageSpace } from './language';

type InterpolationParams = Record<string, string | number>;

@Directive()
export abstract class AbstractMessage implements DoCheck {
  private readonly transform = inject(SP_LOCALIZATION_TRANSFORM, { optional: true }) as unknown as PipeTransform;
  private readonly translatePipe = inject(TranslatePipe);
  private readonly renderer = inject(Renderer2);

  protected abstract element: ElementRef | null;
  protected abstract msg: Nullable<string>;

  @Input() params: Nullable<InterpolationParams> = {};
  @Input() spaces: Nullable<MessageSpace> = null;

  ngDoCheck(): void {
    if (!this.msg || !this.element) { return; }
    let value = this.translatePipe.transform(this.msg, this.params);
    value = this.transform ? this.transform.transform(value) : value;

    let { changingThisBreaksApplicationSecurity: unsafeValue = value } = value;
    const { nativeElement: element } = this.element;

    if (!unsafeValue) { return; }

    if (this.spaces && this.spaces === 'start') { unsafeValue = `&nbsp;${unsafeValue}`; }
    if (this.spaces && this.spaces === 'end') { unsafeValue = `${unsafeValue}&nbsp;`; }
    if (this.spaces && this.spaces === 'wrap') { unsafeValue = `&nbsp;${unsafeValue}&nbsp;`; }

    unsafeValue && this.renderer.setProperty(element, 'innerHTML', unsafeValue);
  }
}
