import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { GameSection, GamesQuery } from '@portal/games/data';
import { SeoData, SeoType } from '@portal/seo';
import { Observable } from 'rxjs';

@Injectable()
export class GameSectionsService {
  private readonly router = inject(Router);
  private readonly gamesQuery = inject(GamesQuery);
  private readonly navigation = inject(SpNavigation);
  private readonly seoData = inject(SeoData);

  private activeSection: GameSection['key'] | null = null;

  readonly list$: Observable<Array<GameSection>> = this.gamesQuery.topMenuSections$;

  isActive({ key }: GameSection, source: string): boolean {
    this.activeSection = source;
    return !!source && this.activeSection === key;
  }

  select({ key, id }: GameSection, params: Params): void {
    this.activeSection = key;
    this.seoData.load(SeoType.CasinoSection, id);
    this.navigation.navigate(...this.createNavigationLink(key, params));
  }

  private createNavigationLink(key: GameSection['key'], params: Params): Parameters<SpNavigation['navigate']> {
    const { section, query, source } = params;

    return !this.router.url.includes('search') && !this.router.url.includes('all') && !section && !query && !source
      ? [ [], null, { fragment: key } ]
      : [ [ key ], {} ];
  }
}
