// @ts-nocheck

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNavigation } from '@libs/cdk';
import { Nullable, unixTimer$ } from '@libs/utils';
import { BonusesData } from '@portal/bonuses/data';
import { PaymentMethod } from '@portal/cashier/data';
import { CryptoflowData } from '@portal/cashier/data/entities/cryptoflow-data';

@Component({
  selector: 'pd-crypto-flow',
  templateUrl: './crypto-flow.component.html',
  styleUrls: [ './crypto-flow.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CryptoFlowComponent implements OnChanges, OnDestroy {
  @Input() formInfo: FormGroup;
  @Input() paymentInfo: PaymentMethod;
  @Input() cryptoFlowInfo: Nullable<CryptoflowData>;
  @Input() isTimer: boolean;

  timer: string;

  constructor(
    private readonly navigation: SpNavigation,
    private readonly cd: ChangeDetectorRef,
    readonly bonusCommand: BonusesData
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.cryptoFlowInfo?.currentValue?.validTill && this.cryptoFlowInfo.validTill) {
      unixTimer$(this.cryptoFlowInfo.validTill).subscribe(timer => {
        if (timer.expired) {
          this.navigation.navigate('/cashier/deposit');
        }
        this.timer = timer.time.format('mm:ss');
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.bonusCommand.unselectBonus();
    this.bonusCommand.clearStore();
  }
}
