import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { BonusesQuery } from '@portal/bonuses/data';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { UserQuery } from '@portal/user';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HasActivatedBonusesGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly bonuses: BonusesQuery,
    private readonly user: UserQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (route.params['type'] !== CashierSections.Withdrawal) { return of(true); }

    return combineLatest([this.user.hasBonusBalance$, this.bonuses.getActiveStatusBonuses$]).pipe(
      map(([hasBonusBalance, activeBonuses]) => {
        return hasBonusBalance || activeBonuses.length
          ? this.router.createUrlTree(['cashier', 'active-bonus'])
          : true;
      })
    );
  }
}
