import { RecaptchaConfigDto } from '@portal/recaptcha/data';
import { RecaptchaSection } from '@portal/recaptcha/shared';

export class RecaptchaConfig {
  readonly config: Record<RecaptchaSection, boolean> = {
    login: this.settings.login,
    profileEmail: this.settings.userProfileEmail,
    profilePhone: this.settings.userProfilePhone,
    registrationEmail: this.settings.regByEmail,
    registrationPhone: this.settings.regByPhone,
    recoveryEmail: this.settings.recoveryByEmail,
    recoveryPhone: this.settings.recoveryByPhone
  };

  constructor(
    readonly key: string,
    private readonly settings: Omit<RecaptchaConfigDto, 'key'>
  ) {}
}
