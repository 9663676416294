import { NotificationAnimationType, NotificationOptions } from './source';

export const SP_DEFAULT_NOTIFICATION_CONFIG: NotificationOptions = {
  position: [ 'top', 'left' ],
  timeOut: 4000,
  showProgressBar: false,
  pauseOnHover: true,
  lastOnBottom: false,
  clickToClose: true,
  animate: NotificationAnimationType.Scale,
  theClass: 'pd-notifications'
};
