import { ComponentRef, Directive, inject, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { Nullable } from '@libs/utils';
import { DynamicComponent } from './component';

@Directive({
  standalone: true,
  selector: '[ggDynamicComponent]'
})
export class DynamicComponentDirective implements OnChanges {
  private readonly view: ViewContainerRef = inject(ViewContainerRef);

  private currentComponent: Nullable<ComponentRef<unknown>> = null;

  @Input('ggDynamicComponent') component: Nullable<DynamicComponent> = null;

  ngOnChanges({ component: { currentValue: component } }: SimpleChanges): void {
    if (this.currentComponent) { this.destroyCurrentComponent(this.currentComponent);}
    if (component) { this.setNewComponent(component); }
  }

  private setNewComponent(component: DynamicComponent): void {
    const { classConstructor, injector } = component;
    this.currentComponent = this.view.createComponent(classConstructor, { injector });
  }

  private destroyCurrentComponent(component: ComponentRef<unknown>): void {
    component.destroy();
    this.view.clear();
  }

}
