import { inject } from '@angular/core';
import { Executor, ParsedUrl, parseUrl, SpNavigation, SpStorage, SpViewport } from '@libs/cdk';
import { LayoutData } from '@portal/layout/data';
import { PaymentService } from '@portal/payment/shared';
import { ConnectedEvent, ConnectedEvents, StorageKey } from '@portal/shared/types';

export class PageExecutor implements Executor<ConnectedEvents> {
  private readonly storage = inject(SpStorage);
  private readonly viewport = inject(SpViewport);
  private readonly navigation = inject(SpNavigation);
  private readonly paymentService = inject(PaymentService);

  execute(event: ConnectedEvents): void {
    if (event.event === 'page:redirect') {
      event.payload?.returnPath && this.storage.setItem(StorageKey.ReturnPath, event.payload.returnPath);
      this.navigation.navigate(event.payload.url, event.payload.query || {});
    }
    if (event.event === 'page:scroll') { this.viewport.scroll(event.payload?.position); }
    if (event.event === 'page:open-cashier') { this.paymentService.openPaymentsDialog(); }
  }
}

export class FrameExecutor implements Executor<ConnectedEvents> {
  private readonly storage = inject(SpStorage);
  private readonly navigation = inject(SpNavigation);
  private readonly layoutData = inject(LayoutData);

  execute(event: ConnectedEvents): void {
    if (event.event === 'frame:redirect') { this.navigation.navigate(...this.parseRedirectEvent(event)); }
    if (event.event === 'frame:ready') { this.layoutData.toggleLoading(false); }
  }

  private parseRedirectEvent(event: ConnectedEvent<'frame:redirect'>): ParsedUrl {
    const { returnPath, to, id, device, demo } = event.payload;
    if (returnPath) { this.storage.setItem(StorageKey.ReturnPath, returnPath); }

    if (id) { return parseUrl(`/game/${id}?device=${device}${demo ? '&is_demo=true' : ''}`); } else if (to === 'login' || to === 'auth') { return parseUrl('auth/login'); } else if (to === 'main') { return parseUrl(''); } else if (to) { return parseUrl(to); }
    return parseUrl('');
  }
}
