import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { SummaryComponent } from '@portal/payment';
import { CryptoWalletInfoComponent, GeneratedFormComponent } from '@portal/payment/components';
import { CommissionModule } from '@portal/payment/components/commission';
import { PaymentFlowAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { StepperModule } from '@portal/shared/components/content/stepper';
import { NEVER, Observable, switchMap } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, CryptoWalletInfoComponent, GeneratedFormComponent, ControlsModule, StepperModule, SummaryComponent, CommissionModule ],
  templateUrl: './crypto-flow.component.html',
  styleUrls: [ './crypto-flow.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CryptoFlowComponent extends PaymentFlowAbstract {
  constructor() { super('additional', 'payment'); }

  override submitStep(): Observable<unknown> {
    return this.isDeposit$.pipe(first(), switchMap(deposit => deposit ? NEVER : super.submitStep()));
  }
}
