import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, RouterEvent, RouterOutlet } from '@angular/router';
import { SpCDKModule, SpLocalization, SpMetaService, SpNavigation } from '@libs/cdk';
import { switchTap } from '@libs/utils';
import { SocketComponent } from '@portal/banners';
import { ConfigQuery } from '@portal/config';
import { LayoutQuery } from '@portal/layout/data';
import { BreadcrumbsComponent } from '@portal/layout/features';
import { FooterComponent, HeaderModule, SidebarComponent, ToolbarComponent } from '@portal/layout/widgets';
import { Seo, SeoQuery, SeoTextComponent } from '@portal/seo';
import { EmailConfirmationPlugFeatureModule } from '@portal/user';
import { filter, first, NEVER, tap } from 'rxjs';

@Component({
  standalone: true,
  selector: 'gg-layout-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: [ './wrapper.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, SocketComponent, EmailConfirmationPlugFeatureModule, HeaderModule, RouterOutlet, SeoTextComponent, FooterComponent, SidebarComponent, ToolbarComponent, BreadcrumbsComponent ],
})
export class WrapperComponent implements OnInit {
  private readonly meta = inject(SpMetaService);
  private readonly seoQuery = inject(SeoQuery);
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);
  private readonly destroy = inject(DestroyRef);
  private readonly layoutQuery = inject(LayoutQuery);
  private readonly configQuery = inject(ConfigQuery);
  private readonly navigation = inject(SpNavigation);
  private readonly localization = inject(SpLocalization);

  private readonly activeTags$ = this.seoQuery.active$.pipe(filter(Boolean));

  protected readonly appState$ = this.layoutQuery.appState$;

  ngOnInit(): void {
    this.setDocumentProps();
    this.createPWAToolbarColorMeta();

    this.appState$.pipe(
      first(),
      switchTap((state) => state === 'error' ? NEVER : this.activeTags$.pipe(tap((seo) => this.setSeoTags(seo)))),
      filter((state) => state === 'error'),
      takeUntilDestroyed(this.destroy),
    ).subscribe(() => this.navigation.navigate([ '/error', 'uncaught' ], null, { skipLocationChange: true }));

    this.navigation.event(NavigationEnd).pipe(
      takeUntilDestroyed(this.destroy),
    ).subscribe((e) => this.createDocumentLinks(e));
  }

  private setDocumentProps(): void {
    const { theme, project } = this.configQuery;
    this.document.documentElement.lang = this.localization.currentLanguage;
    this.document.body.className = `${this.document.body.className} ${theme.palette} ${theme.structure} ${project.name}`;
  }

  private setSeoTags(seo: Seo): void {
    const keywords = seo.tags.keywords?.[ this.localization.currentLanguage ] || '';
    const description = seo.tags.description?.[ this.localization.currentLanguage ] || '';
    const title = seo.tags.title?.[ this.localization.currentLanguage ] || '';

    this.meta.setMeta(title, [ { name: 'keywords', content: keywords }, { name: 'description', content: description } ]);
  }

  private createDocumentLinks({ url }: RouterEvent): void {
    const { head, location: { protocol, hostname } } = this.document;
    const startUrl = `${protocol}//${hostname}`;

    head.querySelectorAll('link[rel=canonical], link[rel=alternate]').forEach((e: Element) => e.remove());

    this.localization.list.forEach((language) => {
      const alternate = this.renderer.createElement('link');
      const alternateLangSegment = this.localization.defaultLanguage === language ? '' : `/${language}`;

      this.renderer.setAttribute(alternate, 'rel', 'alternate');
      this.renderer.setAttribute(alternate, 'hreflang', language);
      this.renderer.setAttribute(alternate, 'href', `${startUrl}${alternateLangSegment}${url}`);
      this.renderer.appendChild(this.document.head, alternate);
    });

    const langSegment = this.localization.isDefaultSelected ? '' : `/${this.localization.currentLanguage}`;
    const lastSegment = url.includes('slots/') && !url.includes('demo') ? '/demo' : '';

    const canonical = this.renderer.createElement('link');
    this.renderer.setAttribute(canonical, 'rel', 'canonical');
    this.renderer.setAttribute(canonical, 'href', `${startUrl}${langSegment}${url}${lastSegment}`);
    this.renderer.appendChild(this.document.head, canonical);
  }

  private createPWAToolbarColorMeta(): void {
    const canonical = this.renderer.createElement('meta');
    this.renderer.setAttribute(canonical, 'name', 'theme-color');
    this.renderer.setAttribute(canonical, 'content', this.configQuery.modules.pwa.toolbarColor);
    this.renderer.appendChild(this.document.head, canonical);
  }
}
