// @ts-nocheck

import { ChangeDetectionStrategy, Component, inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { parseUrl } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { filter, map, share, startWith } from 'rxjs/operators';
import { CASHIER_SECTIONS } from './constants/sections.constant';
import { CashierSections } from './types/enums/sections.enum';
import { CashierSectionInterface } from './types/interfaces/section.interface';
import { CashierDataService } from '@portal/cashier/data';
import { CashierLoaderService } from '@portal/cashier/shared';

@Component({
  templateUrl: './cashier.component.html',
  styleUrls: [ './cashier.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CashierComponent implements OnDestroy {
  private readonly config = inject(ConfigQuery);
  private readonly userQuery = inject(UserQuery);
  private readonly cashierData = inject(CashierDataService);

  private isPokerUser: boolean;

  readonly sections: Array<CashierSectionInterface>;
  readonly active$: Observable<string>;

  constructor(private readonly router: Router,) {

    this.isPokerUser = this.userQuery.isPokerUser;

    this.sections = CASHIER_SECTIONS
      // @ts-ignore
      .filter((section) => this.config.modules.cashier.availableSections.includes(section.value))
      .filter((section) => !this.isPokerUser ? section.value !== CashierSections.Transfers : section)
      .filter((section) => !this.config.modules.cashier.enableHistoryTab ? section.value !== CashierSections.History : section);

    this.active$ = this.router.events.pipe(
      startWith(this.router),
      filter(event => event instanceof NavigationEnd || event instanceof Router),
      map((event: NavigationEnd) => parseUrl(event.url)[0][1]),
      map((type) => type === 'withdraw' ? CashierSections.Withdrawal : type),
      share()
    );
  }

  ngOnDestroy() {
    this.cashierData.resetPaymentMethods();
  }
}
