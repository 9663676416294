import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gg-user-settings-contact-details-phone-verified',
  templateUrl: './phone-verified.component.html',
  styleUrls: [ './phone-verified.component.scss', './silver.phone-verified.component.scss', './purple.phone-verified.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneVerifiedComponent {
  @Input() userPhone: string = '';
}
