// @ts-nocheck

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './success-transfer.component.html',
  styleUrls: ['../../transfers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessTransferComponent {
  readonly userCurrency$ = this.userInfoQuery.currency$;
  readonly transferInfo$: Observable<{ nick: string; amount: string; }>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: SpNavigation,
    private readonly userInfoQuery: UserQuery
  ) {
    this.transferInfo$ = this.activatedRoute.queryParams.pipe(map((params) => ({nick: params.nick, amount: params.amount})));
  }

  backPage(): void {
    this.navigationService.navigate(['/cashier', 'transfers']);
  }

}
