<div class="error-modal">
  <img
    src="assets/images/icons/cross2.svg"
    class="error-modal__popup-close-icon"
    alt=""
    (click)="closePopup()"
  />
  <ng-container [ngSwitch]="errorType">
    <ng-container *ngSwitchCase="kycErrorType.verificationExist">
      <h4 class="error-modal__popup-title">
        <sp-msg msg="PROFILE.KYCAID.POPUP_TITLE"></sp-msg>
      </h4>
      <p class="error-modal__popup-subtitle">
        <sp-msg msg="PROFILE.KYCAID.POPUP_SUBTITLE"></sp-msg>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="kycErrorType.verificationProhibited">
      <h4 class="error-modal__popup-title">
        <sp-msg msg="PROFILE.KYCAID.POPUP_TITLE_PROHIBITED"></sp-msg>
      </h4>
      <p class="error-modal__popup-subtitle">
        <sp-msg msg="PROFILE.KYCAID.POPUP_SUBTITLE_PROHIBITED"></sp-msg>
      </p>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <h4 class="error-modal__popup-title">
        <sp-msg msg="PROFILE.KYCAID.POPUP_TITLE_DEFAULT"></sp-msg>
      </h4>
      <p class="error-modal__popup-subtitle">
        <sp-msg msg="PROFILE.KYCAID.POPUP_SUBTITLE_DEFAULT"></sp-msg>
      </p>
    </ng-container>
  </ng-container>
  <gg-button
    class="error-modal__popup-button"
    [settings]="popupButton"
    (buttonClicked)="closePopup()"
  >
    <sp-msg msg="PROFILE.KYCAID.POPUP_BUTTON"></sp-msg>
  </gg-button>
</div>
