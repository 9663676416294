import { HttpErrorResponse } from '@angular/common/http';
import cloneDeep from 'lodash.clonedeep';

export class ErrorResponse {
  error: { code: string; message: string };
  issues: Array<{ code: string; message: string; field: string }>;
  _meta: object;
  status: string;

  constructor(response: HttpErrorResponse) {
    this.error = response.error._error;
    this.issues = cloneDeep(response.error._issues);
    this.status = response.error._status;
    this._meta = cloneDeep(response.error._meta);
  }
}
