import { Bonus } from '@portal/bonuses/data';
import { BonusStatus } from '@portal/bonuses/shared';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';

export interface BonusMessage {
  title: string;
  description: string;
  accent: 'fail' | 'success' | 'neutral';
  button?: { settings: IButton; title: string };
}

export const BONUS_STATUS_MESSAGES: Partial<Record<Bonus['status']['current'], BonusMessage>> = {
  [BonusStatus.Expired]: {
    title: 'BONUSES.BONUS_EXPIRED_TITLE',
    description: 'BONUSES.BONUS_EXPIRED_DESCRIPTION',
    accent: 'fail'
  },
  [BonusStatus.Gained]: {
    title: 'BONUSES.BONUS_COMPLETED_TITLE',
    description: 'BONUSES.BONUS_COMPLETED_DESCRIPTION',
    accent: 'success'
  },
  [BonusStatus.Blocked]: {
    title: 'BONUSES.BLOCKED',
    description: 'BONUSES.BLOCKED_DESCRIPTION',
    accent: 'success',
    button: { settings: { size: ButtonSizes.Small }, title: 'BONUSES.DEPOSIT' }
  },
  [BonusStatus.Used]: {
    title: 'BONUSES.USED',
    description: 'BONUSES.USED_DESCRIPTION',
    accent: 'fail'
  },
  [BonusStatus.Completed]: {
    title: 'BONUSES.BONUS_COMPLETED_TITLE',
    description: 'BONUSES.BONUS_COMPLETED_DESCRIPTION',
    accent: 'success'
  },
  [BonusStatus.Canceled]: {
    title: 'BONUSES.BONUS_CANCELED_TITLE',
    description: 'BONUSES.BONUS_CANCELED_DESCRIPTION',
    accent: 'neutral'
  }
};
