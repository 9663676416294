import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ErrorObjects } from '@portal/shared/helpers/error-manager/utils';

@Component({
  selector: 'gg-input-error',
  templateUrl: './errors.component.html',
  styleUrls: [ './errors.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class InputErrorComponent {
  @Input() errors: ErrorObjects = [];
  @Input() additionalClass = '';
}
