// @ts-nocheck

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'pd-dot-separated',
  templateUrl: './dot-separated.component.html',
  styleUrls: [ './dot-separated.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotSeparatedComponent {
  readonly dotsCount = 55;

  @Input() value: Nullable<string> = '';
  @Input() label: Nullable<string> = '';
}
