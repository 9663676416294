import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { CashierLoaderService } from '@portal/cashier';
import { Observable, tap, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class PaymentCheckInterceptor implements HttpInterceptor {
  private readonly http = inject(HttpClient);
  private readonly cashierLoader = inject(CashierLoaderService);
  private readonly location = inject(LOCATION);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<void>> {
    const checkList = req.url.includes('/api/payments/');

    if (!checkList) { return next.handle(req); }

    this.cashierLoader.showLoader();

    return this.checkPaymentStability().pipe(
      tap((allowRequest) => {
        if (!allowRequest) { this.location.href = '/page/error'; }
        this.cashierLoader.hideLoader();
      }),
      switchMap((allowRequest) => {
        return allowRequest ? next.handle(req) : throwError(() => 'requests are not available');
      })
    );
  }

  private checkPaymentStability(): Observable<boolean> {
    return this.http.get<{ result: boolean }>(`/api/2/check/payments/`).pipe(
      map((response) => response.result)
    );
  }
}
