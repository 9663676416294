import { UrlSegment } from '@angular/router';
import { Languages, parseUrl } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { AuthorizationSection, MANAGABLE_AUTH_SECTIONS } from '@portal/authorization/shared';

import { getAuthSectionFromRoute } from './get-auth-section';

export const getReturnPathSegment = (path: Nullable<string>, routerUrl: string, languages: Languages, paymentWidgetEnable: Nullable<boolean>): UrlSegment => {
  const section = getAuthSectionFromRoute(routerUrl);

  if (!section || !MANAGABLE_AUTH_SECTIONS.includes(section)) {
    return new UrlSegment('', {});
  }

  if ([ AuthorizationSection.Registration, AuthorizationSection.RegistrationByPhone ].includes(section)) {
    return paymentWidgetEnable
      ? new UrlSegment('', { section: 'deposit' })
      : new UrlSegment('cashier/deposit', {});
  }

  const [ url, extras ] = parseUrl(path === '/' ? null : path);
  // ЕСЛИ В УРЛЕ ЯЗЫК - УДАЛЯЕМ
  if (languages.includes(url[ 0 ])) { url.shift(); }
  // ЕСЛИ УРЛ ПУСТОЙ, ДОБАВЛЯЕМ ПУСТОЙ СЕГМЕНТ
  if (!url.length) { url.push(''); }

  const lastSegment = url[ url.length - 1 ];

  if (lastSegment.includes('#')) {
    const linkToCategory = lastSegment.replace(/#/g, '');
    return new UrlSegment(linkToCategory, {});
  }

  return new UrlSegment(url.join('/'), { ...(extras?.queryParams || {}) });
};
