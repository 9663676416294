<section class="no-contact-verification">
  <div class="no-contact-verification__wrapper">
    <div class="no-contact-verification__text" spMsg="USER.NO_CONTACTS_TEXT"></div>
    <ng-container *ngIf="verificationInfo$ | async as verification">
      <div class="no-contact-verification__checklist">
        <div class="no-contact-verification__number">
          <span class="no-contact-verification__green">{{ verification.stat.count }}</span> / {{ verification.stat.of }}
          <span class="no-contact-verification__ended" spMsg="CASHIER.VERIFICATION.ENDED"></span>
        </div>
        <div class="no-contact-verification__options">
          <div class="no-contact-verification__option">
            <div class="no-contact-verification__option-title"
             [class.no-contact-verification__option-title--complete]="verification.email.confirmed">
              <sp-msg msg="PROFILE.CONTACT_DETAILS.EMAIL"/>
            </div>
          </div>
          <div *ngIf="verification.phone.enabled" class="no-contact-verification__option">
            <div class="no-contact-verification__option-title"
             [class.no-contact-verification__option-title--complete]="verification.phone.confirmed">
              <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"/>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <gg-button class="no-contact-verification__button" [routerLink]="['/user', 'settings', 'contact-details']">
      <sp-msg msg="COMMON.APPROVE"/>
    </gg-button>
  </div>
</section>
