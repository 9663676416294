import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { CardInfo, PaymentCardForm } from '@portal/payment/shared';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { validationRules } from '@portal/shared/constants';
import { ErrorManager } from '@portal/shared/helpers';
import cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'gg-payment-new-card',
  templateUrl: './new-card.component.html',
  styleUrls: [ './new-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCardComponent implements OnInit {
  private initialValue: CardInfo = {};
  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };

  readonly errors: ErrorManager = inject(ErrorManager);

  @Input({ required: true }) form: Nullable<PaymentCardForm>;
  @Input({ required: true }) isExistButtonShown: Nullable<boolean>;
  @Output() save: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.initialValue = this.form ? cloneDeep(this.form.value) : {};

    if (!this.form) { return; }

    this.form.reset();
    this.form.controls.expMonth?.setValidators(validationRules.expirationMonth);
    this.form.controls.expYear?.setValidators(validationRules.expirationYear);
    this.form.controls.holder?.setValidators([ Validators.required ]);
    this.form.controls.account?.setValidators([ Validators.minLength(12) ]);
  }

  reset(): void {
    if (!this.form) { return; }

    this.form.controls.expMonth?.removeValidators(validationRules.expirationMonth);
    this.form.controls.expYear?.removeValidators(validationRules.expirationYear);
    this.form.controls.holder?.removeValidators([ Validators.required ]);
    this.form.controls.account?.removeValidators([ Validators.minLength(12) ]);
    this.form.reset();
    this.form.patchValue(this.initialValue);
    this.save.emit();
  }
}
