<section class="field">
  <div class="{{ additionalClass }} input placeholder__wrap">
    <input
      #input
      class="field__input"
      [type]="type"
      [disabled]="disabled"
      [(ngModel)]="value"
      [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value, 'field__input--no-label': noLabelPlaceholder}"
      [placeholder]="defaultPlaceholder"
      [autocomplete]="autocomplete"
      [maxlength]="maxlength"
      [mask]="mask"
      [ggTooltip]="tooltip"
    />

    @if (!noLabelPlaceholder) {
      <span class="placeholder__text">{{ placeholder }}</span>
    }
  </div>
  <gg-input-error [errors]="errors"></gg-input-error>
</section>
