import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Banner } from '@portal/banners/data';

export type BannersState = {
  areAppBannersAvailable: boolean;
  sync: boolean;
  entities: { banners: SteroidEntity<Banner, string> };
};

const initial: SteroidConfig<BannersState> = {
  options: { name: 'banners' },
  initial: { areAppBannersAvailable: false, sync: false },
  entities: { banners: { idKey: 'id' } }
};

@Injectable({ providedIn: 'root' })
export class BannersStore extends SteroidStore<BannersState> {
  constructor() { super(initial); }
}
