<section class="card-view" *spLet="pageState$ | spAsync as state">
  <ng-container *spLet="cards$ | spAsync as cards">

    @if (state === 'create') {
      <h4 class="card-view__title" spMsg="CASHIER.METHODS_TITLE.ENTER_CARD_NUMBER"></h4>
    } @else {
      <h4 class="card-view__title card-view__title_select" spMsg="CASHIER.CARD.SELECT_PAY_METHOD"></h4>
    }

    @if (cards && state === 'default') {
      <div class="card-view__selector">
        <swiper-container #swiper ggSwiper slideChangedListener="true" init="false" [config]="sliderConfig"
                          class="card-view__selector-wrapper"
                          (slideChanged)="slideChanged($event)">
          @for (card of cards; track $index) {
            <swiper-slide class="card-view__card" [ngClass]="{'card-view__card--delete': card.status === 'delete'}">
              <div class="card-view__bank-card">
                @if (card.status === 'delete') {
                  <gg-payment-cards-card-delete class="card-view__delete-confirmation" [card]="card" [index]="$index"
                                                (slideDeleted)="slideDeleted()"/>
                } @else {
                  <gg-payment-cards-card-details [card]="card" [formControl]="cardForm.controls.cvv"/>
                  <gg-payment-cards-card-options [card]="card" class="card-view__options" (stateChanged)="switchState($event)"/>
                }
              </div>
            </swiper-slide>
          }
        </swiper-container>
        <div class="card-view__btn-prev"></div>
        <div class="card-view__btn-next"></div>
        <div class="cards-pagination"></div>
      </div>

      <gg-button class="card-view__new-card-button" [settings]="newCardButton" (buttonClicked)="switchState('create')">
        <sp-msg spMsg="CASHIER.CARD_VALIDATION.ADD_NEW_CARD"></sp-msg>
      </gg-button>
    }

    @if (state === 'create') {
      <gg-payment-new-card class="card-view__new-card" [form]="cardForm" [isExistButtonShown]="cards.length" (save)="switchState('default')"/>
    }
  </ng-container>
</section>
