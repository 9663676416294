<ng-container *ngIf="formShown$ | async as formShown">
  <div
    class="change-password"
    *ngIf="(passwordExists$ | async) else noPassword"
    [ngClass]="formShown === 'visible' ? 'form' : 'success'"
  >
    <form class="password-form" [formGroup]="form" *ngIf="formShown === 'visible' else noForm" (keyup.enter)="save()">
      <div class="form__row">
        <gg-input-password
          placeholder="{{'COMMON.OLD_PASSWORD' | translate}}"
          class="form__input"
          formControlName="oldPassword"
          [errors]="errors.get('oldPassword') | async"
          [showInfoText]="false"
        ></gg-input-password>
      </div>
      <div class="form__row">
        <gg-input-password
          placeholder="{{'COMMON.NEW_PASSWORD' | translate}}"
          class="form__input"
          formControlName="newPassword"
          [errors]="errors.get('newPassword') | async"
        ></gg-input-password>
      </div>
      <div class="form__row">
        <gg-input-password
          placeholder="{{'COMMON.CONFIRM_PASSWORD' | translate}}"
          class="form__input"
          formControlName="confirmPassword"
          [errors]="errors.get('confirmPassword') | async"
          [showInfoText]="false"
        ></gg-input-password>
      </div>
      <gg-button
        [settings]="saveButton"
        additionalClasses="form__button"
        [disabled]="!form.valid"
        (buttonClicked)="save()"
        [fullWidth]="true"
      >
        <sp-msg msg="PROFILE.CHANGE_PASSWORD.SAVE"></sp-msg>
      </gg-button>
    </form>
  </div>
  <ng-template #noPassword>

    <div class="change-password" [ngClass]="formShown === 'visible' ? 'form' : 'success'">
      <form class="passwordForm" [formGroup]="form" *ngIf="formShown === 'visible' else noForm" (keyup.enter)="saveNewPassword()">
        <div class="form__row">
         <gg-input-password
           placeholder="{{'COMMON.NEW_PASSWORD' | translate}}"
           class="form__input"
           formControlName="newPassword"
           [errors]="errors.get('newPassword') | async"
         ></gg-input-password>
        </div>
        <div class="form__row">
          <gg-input-password
            placeholder="{{'COMMON.CONFIRM_PASSWORD' | translate}}"
            class="form__input"
            formControlName="confirmPassword"
            [errors]="errors.get('confirmPassword') | async"
            [showInfoText]="false"
          ></gg-input-password>
        </div>
        <gg-button
          [settings]="saveButton"
          additionalClasses="form__button"
          [disabled]="form.invalid"
          (buttonClicked)="saveNewPassword()"
          [fullWidth]="true"
        >
          <sp-msg msg="PROFILE.CHANGE_PASSWORD.SAVE"></sp-msg>
        </gg-button>
      </form>
    </div>
  </ng-template>
  <ng-template #noForm>
    <div class="success__block">
      <img class="success__img" src="assets/images/icons/ok.svg" alt=""/>
      <div class="success__text">
        <sp-msg msg="PROFILE.CHANGE_PASSWORD.PASSWORD_SUCCESS_CHANGED"></sp-msg>
      </div>
      <gg-button [settings]="okButton" class="success__button" (buttonClicked)="reset()">
        <sp-msg msg="PROFILE.CHANGE_PASSWORD.OK"></sp-msg>
      </gg-button>
    </div>
    </ng-template>
</ng-container>

