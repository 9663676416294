<ng-container *ngIf="bonus">
  <gg-button
    *ngIf="(bonus.status.is.waiting || bonus.status.is.freeze) && bonus.activation.manual"
    [settings]="actionButton"
    [disabled]="this.bonus.status.is.freeze"
    (click)="action.emit('activate')"
  ><sp-msg msg="BONUSES.ACTIVATE"/></gg-button>
    <gg-button
      *ngIf="(bonus.status.is.waiting || bonus.status.is.freeze) && bonus.activation.deposit"
      [settings]="actionButton"
      [disabled]="this.bonus.status.is.freeze"
      (click)="action.emit('select')"
      ggOpenPayments
    ><sp-msg msg="BONUSES.DEPOSIT"/></gg-button>
  <gg-button
    *ngIf="bonus.status.is.active && (bonus.type === 'casino_freespin' || bonus.type === 'sportsbook')"
    [settings]="actionButton"
    (click)="action.emit('play')"
  ><sp-msg [msg]="bonus.type === 'sportsbook' ? 'BONUSES.PLACE_BET' : 'BONUSES.PLAY'"/></gg-button>
  <gg-button
    *ngIf="bonus.status.is.inProgress"
    [settings]="cancelButton"
    (click)="action.emit('cancel')"
  ><sp-msg msg="BONUSES.CANCEL"/></gg-button>
</ng-container>
