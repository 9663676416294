import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { CurrencySymbol } from '@portal/currencies/shared';

@Pipe({ name: 'currencyNumberRound' })
export class CurrencyRoundPipe implements PipeTransform {
  transform(value: `${string} ${Nullable<CurrencySymbol>}`): string {
    if (!value) { return ''; }

    const [ amount, currencyKey ] = value.split(' ') as [ string, CurrencySymbol ];

    return `${Math.ceil(parseFloat(amount))} ${currencyKey}`;
  }
}
