import { ClassConstructor } from '@libs/utils';
import { PaymentStatus } from '@portal/payment/shared';
import { PendingStatusComponent } from './pending';
import { SuccessStatusComponent } from './success';
import { ErrorStatusComponent } from './error';

export * from './error';

export const paymentStatuses: Record<PaymentStatus, ClassConstructor> = {
  error: ErrorStatusComponent,
  success: SuccessStatusComponent,
  pending: PendingStatusComponent
};
