import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';
import { LanguageDialogComponent } from './language-dialog.component';

@NgModule({
  imports: [ SpCDKModule, ControlsModule ],
  declarations: [ LanguageDialogComponent ],
  exports: [ LanguageDialogComponent ]
})
export class LanguageDialogModule {}
