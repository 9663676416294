<ng-container *spLet="balance$ | spAsync as balance">
  <div
    *spLet="expanded$ | spAsync as expanded"
    class="balance"
    ggCreateThemeClass="balance"
    [ngClass]="{ 'balance-sidebar-light': isLight && section === 'sidebar' }"
  >
    <div class="balance__button">
      <div class="balance__button-title">
        <span spMsg="HEADER.BALANCE"></span>
        <img
          class="balance__button-icon"
          alt="arrow-icon"
          [class.balance__button-icon_expanded]="expanded"
          [src]="(isLight && section === 'sidebar' ? '{project}/images/header/arrow-down-green.svg' : '{project}/images/header/arrow-down.svg') | path"
        />
      </div>
      <span class="balance__button-subtitle">{{ balance.totalBalance | priceNormalizer }}</span>
    </div>
    @if (expanded) {
      <div class="balance__options" *spLet="detailsOpened$ | spAsync as detailsOpened" #options>
        <div class="balance__options-row">
          <p class="balance__options-row-title" spMsg="HEADER.FIAT_MONEY"></p>
          <span class="balance__options-row-amount">{{ balance.balance | priceNormalizer }}</span>
        </div>
        <div class="balance__options-row">
          <p class="balance__options-row-title" spMsg="HEADER.BONUS_BALANCE"></p>
          <span class="balance__options-row-amount">{{ balance.bonusBalance | priceNormalizer }}</span>
          @if (detailsOpened) {
            <p class="balance__options-row-description" spMsg="HEADER.BONUS_DESCRIPTION"></p>
          }
        </div>
        @if (enableTruePlayBalance && !!balance.truePlayTokens) {
          <div class="balance__options-row">
            <p class="balance__options-row-title" spMsg="HEADER.TRUE_PLAY_BALANCE"></p>
            <span class="balance__options-row-amount">{{ balance.truePlayTokens }}</span>
            <span class="balance__options-row-separate-line">|</span>
            <span class="balance__options-row-amount">{{ balance.truePlayBalance | priceNormalizer }}</span>
            @if (detailsOpened) {
              <p class="balance__options-row-description" spMsg="HEADER.TRUE_PLAY_DESCRIPTION"></p>
            }
          </div>
        }
        <span class="balance__details-button" (click)="toggleDetails()">
          @if (detailsOpened) {
            <sp-msg msg="HEADER.CLOSE_DETAILS"></sp-msg>
          } @else {
            <sp-msg msg="HEADER.SHOW_DETAILS"></sp-msg>
          }
        </span>
      </div>
    }
  </div>
</ng-container>


