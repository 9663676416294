import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'gg-recaptcha-input-dialog',
  templateUrl: './recaptcha-input-dialog.component.html',
  styleUrls: [ './recaptcha-input-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecaptchaInputDialogComponent {
  private readonly dialog = inject(DialogRef);

  readonly recaptchaToken: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: Validators.required
  });

  confirm(): void {
    this.dialog.close(this.recaptchaToken.value);
    this.recaptchaToken.reset();
  }

  close(): void {
    this.dialog.close(false);
  }
}
