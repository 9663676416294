import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { PaymentParamsData } from '@portal/payment/data';
import { PaymentBuilder } from '@portal/payment/shared';
import { ButtonSizes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, RouterModule, ControlsModule ],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorStatusComponent {
  private readonly configQuery = inject(ConfigQuery);
  private readonly builder = inject(PaymentBuilder);
  private readonly paramsData = inject(PaymentParamsData);

  private readonly modules = this.configQuery.modules;

  readonly isBankCard$ = this.builder.payment$.pipe(map(({ group }) => group?.type === 'bank_card'));
  readonly cardPdf: string = this.modules.cashier.newBankCardPDF;
  readonly supportEmail: string = this.modules.contacts.support;
  readonly retryButtonSettings: IButton = { size: ButtonSizes.Large };

  retry(): void { this.paramsData.setPayment({ section: 'deposit', group: null }); }
}
