<div class="loader">
  <div class="loader__block">
    <img
      class="loader__preloader-img"
      src="assets/images/loader/preloader.png"
      alt="preloader"
    />
  </div>
  <h3 class="loader__info loader__info-bold" [spMsg]="title"></h3>
  <h3 class="loader__info" spMsg="CASHIER.LOADER.TAKE_TIME"></h3>
</div>
