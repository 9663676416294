import { inject, Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { CountriesState, CountriesStore, Country } from '@portal/countries/data';
import { UserQuery } from '@portal/user';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountriesQuery extends SteroidQuery<CountriesState> {
  readonly synchronized$: Observable<boolean> = this.select('sync');

  readonly countries$: Observable<Array<Country>> = this.select('countries').pipe(map((c) => c || []));

  readonly country$ = combineLatest(
    [ inject(UserQuery).country$, this.select('country') ]
  ).pipe(map(([ userCountry, country ]) => userCountry || country));

  get countries(): Nullable<Array<Country>> { return this.get('countries'); }

  constructor(store: CountriesStore) { super(store); }

}
