import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorResponse, ResponseFactory, SuccessResponse } from '@portal/shared/helpers';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FormsService {
  private readonly http = inject(HttpClient);

  validatePhoneNumber(phone: string): Observable<ErrorResponse | SuccessResponse<void>> {
    return this.http.post<any>(`/api/2/payments/validate_phone/`, { phone }).pipe(
      catchError((error) => of(ResponseFactory.error(error)))
    );
  }
}
