<nav class="breadcrumbs">
  <div class="breadcrumbs__container">
    <a class="breadcrumbs__home" [routerLink]="['/']">
      <img [src]="'{project}/images/breadcrumbs/home.svg' | path" alt="home-icon"/>
    </a>
    <ng-container *ngFor='let breadcrumb of breadcrumbs$ | async; let last = last;'>
      <gg-header-breadcrumbs-segment [breadcrumb]="breadcrumb" [withLink]="!last"/>
    </ng-container>
  </div>
</nav>
