// @ts-nocheck

import { AfterContentInit, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { UserBankCardDetailsDto } from '@portal/cashier/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { USER_CARD_INFO } from './constants/card-form-declaration.constant';
import { USER_CARD_INFO_WITHOUT_CVV } from './constants/card-form-declaration_without_cvv.constant';

@Component({
  selector: 'gg-widget-pay',
  templateUrl: './pd-widget-pay.component.html',
  styleUrls: [ './pd-widget-pay.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PdWidgetPayComponent implements AfterContentInit {
  @Input() method: number;
  @Input() showLoading = false;
  @Input() placeholder = 'XXXX XXXX XXXX XXXX';
  @Input() selectedUserBankCard: UserBankCardDetailsDto;
  @Input() needCvv = true;

  @Output() pdWidgetInfo = new EventEmitter();

  errors: ErrorManager;
  readonly pdWidgetButton: IButton = { size: ButtonSizes.Large, theme: ButtonThemes.Green };
  form: FormGroup;
  mask: Nullable<string> = '0000 0000 0000 0000 0000';

  constructor(
    private readonly fb: FormBuilder,
    private readonly errorManager: ErrorManager
  ) {}

  ngAfterContentInit(): void {
    this.form = this.needCvv ? this.fb.group(USER_CARD_INFO) : this.fb.group(USER_CARD_INFO_WITHOUT_CVV);

    this.errors = this.errorManager.setUp({ form: this.form });

    if (!this.selectedUserBankCard) { return; }

    this.mask = null;

    this.form.patchValue({
      account: this.selectedUserBankCard.mask.replace(/(.{4})/g, '$1 '),
      expM: this.selectedUserBankCard.exp_month,
      expY: this.selectedUserBankCard.exp_year
    }, { emitEvent: false });

    this.form.get('account').disable({ onlySelf: true });

    if (this.needCvv) {
      this.form.get('expM').disable({ onlySelf: true });
      this.form.get('expY').disable({ onlySelf: true });
    }
  }

  sendPDWidgetRequest(): void {
    if (!this.form.valid) { return; }

    this.pdWidgetInfo.emit(this.makeCardInfoValid(this.form.getRawValue()));
  }

  private makeCardInfoValid(formValue): object {
    return Object.keys(formValue).reduce((acc, elem) => {
      return {
        ...acc,
        [ elem ]: (formValue[ elem ].length === 1) ? `0${formValue[ elem ]}` : formValue[ elem ]
      };
    }, {});
  }

}
