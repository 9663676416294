<section *ngIf="methodDetails" class="auth-method">
  <div class="auth-method__title" [spMsg]="methodDetails.appBlockTitle"></div>
  <div class="auth-method__app">
    <div class="auth-method__app-wrapper">
      <div class="auth-method__app-title" [spMsg]="methodDetails.appTitle"></div>
      <div class="auth-method__app-subtitle" [spMsg]="methodDetails.appDescription"></div>
      <div class="auth-method__app-buttons">
        <a [href]="methodDetails.appIOsUrl" class="auth-method__app-link" target="_blank">
          <img class="auth-method__app-link-img google__app-link-img_apple" src="assets/images/icons/app-store.svg" alt=""/>
        </a>
        <a [href]="methodDetails.appAndroidUrl" class="auth-method__app-link" target="_blank">
          <img class="auth-method__app-link-img" src="assets/images/icons/google-play.svg" alt=""/>
        </a>
      </div>
    </div>
    <div class="auth-method__app-example">
      <img [src]="methodDetails.appExampleImg" class="auth-method__app-example-img" alt=""/>
      <span *ngIf="methodDetails.value === authMethods.Google" class="auth-method__app-example-text">{{projectAddress}}</span>
    </div>
  </div>
  <div class="auth-method__title" spMsg="PROFILE.TWO_FACTOR_AUTH.GENERATE_CODE"></div>
  <div class="auth-method__steps-wrapper">
    <div class="auth-method__step">
      <div class="auth-method__step-title" spMsg="PROFILE.TWO_FACTOR_AUTH.STEP_1"></div>
      <div class="auth-method__step-text" [spMsg]="methodDetails.qrDescription"></div>
      <ng-container *ngIf="twoFactorSettings">
        <div class="auth-method__step-qr" *ngIf="twoFactorSettings.qrCode">
          <img src="assets/images/profile/2fa/frame.svg" class="auth-method__step-qr-frame" alt=""/>
          <img class="auth-method__step-qr-img" [src]="'data:image/png;base64,' + twoFactorSettings.qrCode" alt=""/>
        </div>
        <div class="auth-method__step-copy-code-wrapper">
          <div class="auth-method__step-copy-code-text" spMsg="PROFILE.TWO_FACTOR_AUTH.OR_ENTER_SECURE_CODE"></div>
          <div class="auth-method__step-copy">
            <div class="auth-method__step-copy-code">{{twoFactorSettings.secretCode}}</div>
            <gg-clipboard-copy [copyValue]="twoFactorSettings.secretCode"></gg-clipboard-copy>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="auth-method__step">
      <div class="auth-method__step-title" spMsg="PROFILE.TWO_FACTOR_AUTH.STEP_2"></div>
      <div class="auth-method__step-text" spMsg="PROFILE.TWO_FACTOR_AUTH.STEP_2_DESCRIPTION"></div>
      <form [formGroup]="form">
        <gg-input
          placeholder="{{'PROFILE.TWO_FACTOR_AUTH.AUTH_2_CODE' | translate}}"
          class="auth-method__step-input"
          formControlName="code"
          [errors]="errors.get('code') | async"
        ></gg-input>
      </form>
      <gg-button
        class="auth-method__step-submit-button auth__button"
        [settings]="greenButton"
        [disabled]="form.invalid"
        (buttonClicked)="enableAuth()"
      >
        <sp-msg msg="PROFILE.TWO_FACTOR_AUTH.ENABLE"></sp-msg>
      </gg-button>
      <div class="auth-method__step-text-red" spMsg="PROFILE.TWO_FACTOR_AUTH.DONT_LOSE_PASSWORD"></div>
      <div class="auth-method__step-text-gray" spMsg="PROFILE.TWO_FACTOR_AUTH.IF_LOSE_PASSWORD"></div>
    </div>
  </div>
</section>
