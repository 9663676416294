<ng-container *spLet="method$ | spAsync as method">
  <div class="commission-warning">

    <div class="commission-warning__title" spMsg="CASHIER.CARD.SUM_WITHDRAW"></div>
    <p class="commission-warning__amount">{{ amountWithCommission$ | spAsync | currencyNormalizer }}</p>
    <p class="commission-warning__text">
      <sp-msg spMsg="CASHIER.COMMISSION" spaces="end"/>
      {{ method.view.commission.current }}%
    </p>

    @if(method.view.commission.rules.length) {
      <div class="commission-warning__rules-block">
        <p class="commission-warning__text commission-warning__rules-title">
          <sp-msg msg="CASHIER.CARD.ASK_WITHDRAW_LATER"/>
         </p>

        @for (rule of method.view.commission.rules; track rule) {
          <span class="commission-warning__text commission-warning__text--bold commission-warning__text--margin">
            <sp-msg spMsg="CASHIER.CARD.FEE_RULE" [params]="{from: rule.left, rate: rule.rate}"/>
          </span>
        }
      </div>
    }

    <gg-button class="commission-warning__btn" [settings]="getNowButton" [fullWidth]="true" (click)="paymentConfirmed()"><sp-msg
      spMsg="CASHIER.CARD.GET_NOW"/></gg-button>
    <gg-button class="commission-warning__btn-close" [fullWidth]="true" [settings]="closeButton" (click)="closePopup()">
      <sp-msg spMsg="CASHIER.CARD.GET_LATER"/>
    </gg-button>
  </div>
</ng-container>
