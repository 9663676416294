import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { isUrlMatched } from '@libs/utils';
import { Observable } from 'rxjs';
import { SP_HTTP_CONFIG, SpHttpConfig } from '../utils';

@Injectable()
export class SpHttpCredentialsInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(SP_HTTP_CONFIG) private readonly config: SpHttpConfig
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config.credentials || !('enabled' in this.config.credentials)) { return next.handle(req); }

    const { toggled, enabled } = this.config.credentials;

    return !toggled || !isUrlMatched(toggled)(req.url)
      ? next.handle(req.clone({ withCredentials: enabled }))
      : next.handle(req.clone({ withCredentials: !enabled }));
  }
}
