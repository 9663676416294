import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { LOGIN_FORM_DECLARATION } from '@portal/poker/shared';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { validationRules } from '@portal/shared/constants';
import { ErrorManager, ResponseFactory } from '@portal/shared/helpers';
import { UserData, UserQuery } from '@portal/user/data';
import { combineLatest, filter, iif, map, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, ReactiveFormsModule ],
  selector: 'gg-poker-nickname-form',
  templateUrl: './nickname-form.component.html',
  styleUrls: [ './nickname-form.component.scss' ],
  providers: [ ErrorManager ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NicknameFormComponent implements OnDestroy {
  private readonly fb = inject(FormBuilder);
  private readonly errorManager = inject(ErrorManager);

  private readonly userData: UserData = inject(UserData);
  private readonly userQuery: UserQuery = inject(UserQuery);
  private readonly navigation: SpNavigation = inject(SpNavigation);
  private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  private subscriptionSetNickname$: Subscription = new Subscription();

  readonly isComplicatedPassword: boolean = inject(ConfigQuery).modules.forms.isComplicatedPassword;
  readonly saveButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };
  readonly form: FormGroup = this.fb.group(LOGIN_FORM_DECLARATION);
  readonly errors: ErrorManager;
  readonly passwordExists$: Observable<Nullable<boolean>>;
  readonly nickExists$: Observable<boolean>;

  constructor() {
    this.nickExists$ = combineLatest([this.userQuery.hasNickname$, this.userQuery.mustChangeNick$]).pipe(
      map(([hasNickname, mustChangeNick]) => hasNickname && !mustChangeNick),
      tap(() => {
        this.form.get('nick')?.setValidators(validationRules.nick);
        this.cd.detectChanges();
      })
    );

    this.passwordExists$ = this.userQuery.passwordExists$.pipe(
      filter((exists) => !exists),
      tap(() => {
        this.form.get('password')?.setValidators(validationRules.password);
        if (this.isComplicatedPassword) this.form.get('password')?.addValidators(validationRules.complicatedPassword);
        this.cd.detectChanges();
      })
    );

    this.errors = this.errorManager.setUp({ form: this.form });
  }

  ngOnDestroy(): void {
    !!this.subscriptionSetNickname$ && this.subscriptionSetNickname$.unsubscribe();
  }

  saveUserInfo(): void {
    if (this.form.invalid) { return; }

    const { nick, password } = this.form.value;

    this.subscriptionSetNickname$ = this.setPokerDetails(nick, password).subscribe({
      error: (error) => {
        this.errors.setServerErrors(ResponseFactory.error(error));
      }
    });
  }

  private setPokerDetails(nick: string, password: string): Observable<unknown> {
    return iif(
      () => !nick,
      this.userData.createPassword(password),
      this.userData.updatePokerNickAndPass(nick, password)
    ).pipe(tap(() => this.navigation.navigate([ 'poker' ])));
  }
}
