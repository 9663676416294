import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { ContentModule } from '@portal/shared/components';
import { DropdownMessengerSelectorComponent } from './dropdowns/dropdown-messenger-selector/dropdown-messenger-selector.component';
import { ReplaceSubstringPipe } from '@portal/shared/components/controls/inputs/components/errors/replace-substring.pipe';
import { InputFlatSearchComponent } from '@portal/shared/components/controls/inputs/input-search/flat/input-search.component';
import { InputRoundedSearchComponent } from '@portal/shared/components/controls/inputs/input-search/rounded/input-search.component';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { ButtonBackComponent } from './buttons/button-back/button-back.component';
import { ButtonGroupComponent } from './buttons/button-group/button-group.component';
import { ButtonComponent } from './buttons/button/button.component';
import { LinkComponent } from './buttons/link/link.component';
import { PromoCodeButtonComponent } from './buttons/promo-code-button/promo-code-button.component';
import { DocumentsUploadSelectorComponent } from './dropdowns/documents-upload-selector/documents-upload-selector.component';
import { DropdownBankSelectorComponent } from './dropdowns/dropdown-bank-selector/dropdown-bank-selector.component';
import { DropdownDocumentSelectorComponent } from './dropdowns/dropdown-document-selector/dropdown-document-selector.component';
import { DropdownLanguageSwitcherComponent } from './dropdowns/dropdown-language-switcher/dropdown-language-switcher.component';
import { DropdownOperatorSelectorComponent } from "./dropdowns/dropdown-operator-selector/dropdown-operator-selector.component";
import { ClearButtonComponent } from './inputs/components/clear-button/clear-button.component';
import { InputErrorComponent } from './inputs/components/errors/errors.component';
import { InputBankCardComponent } from './inputs/input-bank-card/input-bank-card.component';
import { InputCardCvvComponent } from './inputs/input-card-cvv/input-card-cvv.component';
import { InputCardMonthComponent } from './inputs/input-card-month/input-card-month.component';
import { InputCardYearComponent } from './inputs/input-card-year/input-card-year.component';
import { InputCashComponent } from './inputs/input-cash/input-cash.component';
import { InputCheckboxComponent } from './inputs/input-checkbox/input-checkbox.component';
import { InputDateComponent } from './inputs/input-date/input-date.component';
import { InputEmailComponent } from './inputs/input-email/input-email.component';
import { InputFileComponent } from './inputs/input-file/input-file.component';
import { InputLoginComponent } from './inputs/input-login/input-login.component';
import { InputPasswordComponent } from './inputs/input-password/input-password.component';
import { InputRadioComponent } from './inputs/input-radio/input-radio.component';
import { InputSearchComponent } from './inputs/input-search/input-search.component';
import { InputSelectComponent } from './inputs/input-select/input-select.component';
import { InputZipCodeComponent } from './inputs/input-zip-code/input-zip-code.component';
import { InputComponent } from './inputs/input/input.component';

const buttons = [ ButtonComponent, ButtonBackComponent, ButtonGroupComponent, PromoCodeButtonComponent, LinkComponent ];
const dropdowns = [ DocumentsUploadSelectorComponent, DropdownBankSelectorComponent, DropdownDocumentSelectorComponent, DropdownLanguageSwitcherComponent, DropdownMessengerSelectorComponent, DropdownOperatorSelectorComponent ];
const inputs = [ ClearButtonComponent, InputErrorComponent, ReplaceSubstringPipe, InputComponent, InputBankCardComponent, InputCardCvvComponent, InputCardMonthComponent, InputCardYearComponent, InputCashComponent, InputCheckboxComponent, InputDateComponent, InputEmailComponent, InputFileComponent, InputLoginComponent, InputPasswordComponent, InputRadioComponent, InputSearchComponent, InputRoundedSearchComponent, InputFlatSearchComponent, InputSelectComponent, InputZipCodeComponent ];
const reexport = [ ...buttons, ...dropdowns, ...inputs ];

@NgModule({
  imports: [ SpCDKModule, NgxMaskDirective, ThemeModule, ContentModule, FormsModule, ReactiveFormsModule ],
  declarations: reexport,
  exports: [ ...reexport, FormsModule, ReactiveFormsModule ]
})
export class ControlsModule {
  static init(): ModuleWithProviders<ControlsModule> {
    return { ngModule: ControlsModule, providers: [ provideEnvironmentNgxMask() ] };
  }
}
