<div *ngIf="hasGame" class="bonus-game-wrapper">
  <span spMsg="BONUSES.IN_GAME" spaces="end"></span>
  <ng-container *ngIf="ignoreLink else link">
    <span class="game-url">{{ bonus.game.name }}</span>
  </ng-container>
  <ng-template #link>
    <a class="game-url game-url--link" [routerLink]="bonus.game.link">{{ bonus.game.name }}</a>
  </ng-template>
</div>

<div class="bonus-game-wrapper" *ngIf="hasSubTitle" spMsg="BONUSES.DEPOSIT_ON_CASINO"></div>
