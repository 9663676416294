import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';

@Component({
  standalone: true,
  selector: 'gg-dotted-line',
  templateUrl: './dotted-line.component.html',
  styleUrls: [ './dotted-line.component.scss' ],
  imports: [ SpCDKModule ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DottedLineComponent {
  readonly dotsCount = 55;

  @Input() value: Nullable<string> = '';
  @Input() label: Nullable<string> = '';
}
