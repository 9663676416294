import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { BankDetails, IInputSelectData } from '@portal/shared/components/controls/interfaces';

import { BanksService } from '@portal/shared/components/controls/services';

@Component({
  selector: 'gg-dropdown-bank-selector',
  templateUrl: './dropdown-bank-selector.component.html',
  styleUrls: [ './dropdown-bank-selector.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownBankSelectorComponent),
    multi: true
  } ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownBankSelectorComponent extends InputAbstract implements OnInit {
  readonly bank_id = new FormControl();

  bankList: Array<IInputSelectData> = [];
  defaultItem: Nullable<IInputSelectData>;

  @Input() paymentMethodId!: Nullable<number>;
  @Output() bankChanged = new EventEmitter();

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly banksService: BanksService,
    private readonly localization: SpLocalization
  ) { super(); }

  override ngOnInit(): void {
    super.ngOnInit();

    this.banksService.getBankList(this.paymentMethodId?.toString()).subscribe((banks: Array<BankDetails>) => {
      this.bankList = this.mapBankToSelectOption(banks);
      this.defaultItem = this.getDefaultBank(banks[0].bank_id);
      this.defaultItem ? this.writeValue(this.defaultItem.value) : this.writeValue('');
      this.bankChanged.emit(banks);
      this.cd.detectChanges();
    });

    this.bank_id.valueChanges.subscribe((value) => {
      this.bankChanged.emit(value);
      this.writeValue(value);
    });
  }

  getDefaultBank(defaultBankId: string): Nullable<IInputSelectData> {
    return this.bankList.find(bank => bank.value === defaultBankId);
  }

  private mapBankToSelectOption(bank: Array<BankDetails>): Array<IInputSelectData>;
  private mapBankToSelectOption(bank: BankDetails): IInputSelectData;
  private mapBankToSelectOption(bank: Array<BankDetails> | BankDetails): Array<IInputSelectData> | IInputSelectData {
    if (Array.isArray(bank)) {
      return bank.map((b) => this.mapBankToSelectOption(b));
    } else {
      return {
        value: bank.bank_id,
        description: this.localization.currentLanguage === 'en' ? bank.name : bank.name_rus,
        icon: bank.src
      };
    }
  }
}
