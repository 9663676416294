import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { synchronize$ } from '@libs/store';
import { Nullable, switchTap, watch } from '@libs/utils';
import { PaymentCardsData, PaymentCardsQuery, PaymentGroupsData, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { allPaymentSections, isFinancialOperation, PaymentOperation, PaymentParams, WidgetParams } from '@portal/payment/shared';
import { VerificationData, VerificationQuery } from '@portal/verification/data';
import { delay, filter, Observable, of, pairwise, skip, startWith, switchMap, tap } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { paymentSections } from './sections';

@Component({
  selector: 'gg-payment-dialog',
  templateUrl: './payment-dialog-widget.component.html',
  styleUrl: './payment-dialog-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogWidgetComponent implements OnInit, OnDestroy {
  private readonly dialog = inject(Dialog);
  private readonly navigation = inject(SpNavigation);
  private readonly groupsData = inject(PaymentGroupsData);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly verificationData = inject(VerificationData);
  private readonly paramsQuery = inject(PaymentParamsQuery);
  private readonly verificationQuery = inject(VerificationQuery);
  private readonly route = inject(ActivatedRoute);
  private readonly cardsData = inject(PaymentCardsData);
  private readonly cardsQuery = inject(PaymentCardsQuery);

  private readonly queryParams = this.route.queryParams;
  private readonly watcher = watch();

  private isSynchronized = false;
  private ref: Nullable<DialogRef> = null;

  readonly section$: Observable<Nullable<ComponentPortal<unknown>>> = this.paramsQuery.params$.pipe(this.getPaymentSection());

  @ViewChild('widget', { read: TemplateRef, static: true }) widget: Nullable<TemplateRef<unknown>> = null;

  ngOnInit(): void {
    this.watcher.add(this.queryParams.pipe(
      tap(({ section }) => !allPaymentSections.includes(section) && this.ref && this.ref.close()),
      filter(({ section }) => allPaymentSections.includes(section) && !this.ref),
      synchronize$(this.verificationData, this.verificationQuery),
      switchTap(() => this.groupsData.loadPreferredMethods()),
    ).subscribe((params) => this.openPaymentWidget(params)));

    this.watcher.add(this.paramsQuery.params$.pipe(
      skip(1),
      delay(300),
    ).subscribe((query) => {
      this.navigation.navigate([], query, { queryParamsHandling: 'merge' });
      this.isSynchronized = true;
    }));

    this.cardsQuery.synchronized$.pipe(synchronize$(this.cardsData, this.cardsQuery), first()).subscribe();
  }

  ngOnDestroy(): void {
    this.watcher.unsubscribe();
    this.isSynchronized = false;
  }

  private openPaymentWidget(params: Params): void {
    this.paramsData.update(params as WidgetParams);

    const styles = { panelClass: 'payment-dialog', backdropClass: 'payments-dialog-bg' };
    this.ref = this.widget ? this.dialog.open(this.widget, styles) : null;

    const closeDialogWatcher = this.ref?.closed.subscribe(() => this.closePaymentWidget());
    this.watcher.add(closeDialogWatcher);
  }

  private closePaymentWidget(): void {
    this.ref = null;
    this.paramsData.clear();
  }

  private getPaymentSection(): (params$: Observable<WidgetParams>) => Observable<Nullable<ComponentPortal<unknown>>> {
    return (params$) => params$.pipe(
      startWith({} as PaymentParams), pairwise(),
      switchMap(([ prev, current ]) => {
        if (prev.section === current.section || !isFinancialOperation(current.section) || !this.isSynchronized) { return of(current); }
        return this.groupsData.loadPreferredMethods(current.section as PaymentOperation).pipe(map(() => current));
      }),
      distinctUntilChanged((prev, curr) => {
        return (isFinancialOperation(prev.section) && isFinancialOperation(curr.section)) || (prev.section === curr.section);
      }),
      map(({ section }) => section ? new ComponentPortal(paymentSections[ section ]) : null),
    );
  }
}
