<div class="bonus-info" ggCreateThemeClass="bonus-info">
  <gg-bonus-card-info-active-label [bonus]="bonus"/>
  <div class="bonus-title">{{ bonus | bonusTitle }}</div>

  <div class="bonus-extra">
    <gg-bonus-card-game class="bonus-game" *ngIf="bonus?.type !== 'sportsbook'" [bonus]="bonus"/>
    <gg-bonus-card-activation class="bonus-deposit" [bonus]="bonus"/>
    <gg-bonus-card-wager-info class="bonus-wager" [bonus]="bonus"/>
    <gg-bonus-card-max-sum [bonus]="bonus"/>
    <gg-bonus-card-max-bet [bonus]="bonus"/>
    <gg-bonus-card-max-win class="bonus-max-win" [bonus]="bonus"/>
  </div>
</div>

<div class="bonus-icon" [ngClass]="bonus.type" *ngIf="bonus">
  <gg-bonus-card-icon [bonus]="bonus"></gg-bonus-card-icon>
</div>
