import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, watch } from '@libs/utils';
import { LoaderComponent } from '@portal/payment/components';
import { ActionPollingData } from '@portal/payment/data';
import { PostActionAbstract } from '@portal/payment/shared';
import { Observable, switchMap, timer } from 'rxjs';

@Component({
  standalone: true,
  template: `
    <gg-payment-loader/>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, LoaderComponent ],
})
export class StatusPollingActionComponent extends PostActionAbstract implements OnInit, OnDestroy {
  private readonly watcher = watch();

  ngOnInit(): void {
    const { initiatorId } = this.result?.data || {};
    if (this.processErrors(!initiatorId)) { return; }

    this.watcher.add(this.checkStatus(initiatorId).subscribe((data) => {
      !this.processUrl(data.url) && this.setStatus({ status: data.status });
    }));
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  private checkStatus(initiatorId: Nullable<string>): Observable<ActionPollingData> {
    return timer(0, 2000).pipe(
      switchMap(() => this.paymentData.checkStatus(initiatorId)),
    );
  }
}
