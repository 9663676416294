import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';
import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { SpCDKModule } from '@libs/cdk';
import { KycVerificationErrors } from '@portal/verification/shared/enums';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-verification-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: [ './error-modal.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorModalComponent {
  private readonly dialog = inject(Dialog);
  private readonly dialogData = inject(DIALOG_DATA);

  readonly errorType: string;
  readonly popupButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly kycErrorType: typeof KycVerificationErrors = KycVerificationErrors;

  constructor() {
    this.errorType = this.dialogData;
  }

  closePopup(): void {
    this.dialog.closeAll();
  }
}
