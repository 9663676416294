import { inject, Inject, Injectable, Optional } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Request, Response } from 'express';

@Injectable({ providedIn: 'root' })
export class SsrService {
  private readonly isServer = inject(SpPlatform).server;

  constructor(
    @Optional() @Inject('expressRequest') private readonly request: Nullable<Request>,
    @Optional() @Inject('expressResponse') private readonly response: Nullable<Response>
  ) {}

  setResponseStatus(status: number): void {
    this.isServer && this.response?.status(status);
  }
}
