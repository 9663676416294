export const connectorApiInfo = {
  api: {
    get: {
      description: 'getter for aggregated object or concrete property',
      props: 'param: undefined | keyof Aggregated',
      return: 'Aggregated | Aggregated[prop]'
    },
    request: {
      description: 'getter for observable of aggregated object or concrete property',
      props: 'param: undefined | keyof Aggregated',
      return: 'Observable<Aggregated | Aggregated[prop]>'
    },
    listen: {
      description: 'getter for observable of concrete event',
      props: 'event undefined | keyof Event',
      return: '{event: string; payload: unknown}'
    },
    sendEvent: {
      description: 'sending event to web site system',
      props: '{event: string, payload: unknown} | event: string, payload: unknown',
      return: 'undefined'
    }
  }
};
