<div class="verification-header">
  <h4
    class="verification-header__title"
    [class.verification-header__title_capitalize]="!isKycEnabled"
  >
    <sp-msg msg="VERIFICATION.HEADER.TITLE"/>
  </h4>
  <gg-button-group
    *ngIf="isKycEnabled"
    class="verification-header__tabs"
    [active]="activeTab"
    [tabs]="tabs"
    (tabChanged)="nav($event)"
  ></gg-button-group>
  <svg class="verification-header__info-icon" (click)="openPopup()">
    <use
      *ngIf="isKycEnabled"
      xlink:href="assets/images/icons/info.svg#infoIcon"
      class="tooltip__icon-info"
    ></use>
  </svg>
</div>
