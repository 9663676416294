import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNavigation, SpNotification } from '@libs/cdk';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { UserData } from '@portal/user/data';
import { createTwoFactorAuthForm } from '@portal/user/shared';

@Component({
  selector: 'gg-method-status',
  templateUrl: './method-status.component.html',
  styleUrls: [ './method-status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MethodStatusComponent {
  private readonly userSettingsCommands = inject(UserData);
  private readonly notifications = inject(SpNotification);
  private readonly navigation = inject(SpNavigation);

  readonly form: FormGroup = createTwoFactorAuthForm();
  readonly grayButton: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Medium };

  isConfirmationScreen = false;

  showConfirmationScreen(): void {
    this.isConfirmationScreen = true;
  }

  disableAuth(): void {
    if (this.form.invalid) { return; }
    const { code } = this.form.value;

    this.userSettingsCommands.setTwoFactorAuth(code, 'false').subscribe({
      next: () => {
        this.notifications.success('NOTIFICATIONS.MESSAGES.2FACTOR_DISABLED');
        setTimeout(() => {
          this.navigation.navigate('/auth/logout');
        }, 3000);
      },
      error: () => this.notifications.error('NOTIFICATIONS.MESSAGES.2FACTOR_DISABLE_FAIL')
    });
  }
}
