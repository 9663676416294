import { GamesWithTotal } from '@portal/games/data';
import { createGame } from '@portal/games/data/mappers/create-game';
import { MainGamesResponse } from '@portal/games/shared';

export function createInitialGameBatch(games: MainGamesResponse): GamesWithTotal {
  return Object.entries(games._item).reduce((acc, [ section, { _items, _meta } ]) => {
    return {
      games: [ ...acc.games, ..._items.map(createGame) ],
      total: { ...acc.total, [ section ]: _meta.total },
      limit: { ...acc.limit, [ section ]: _meta.limit }
    };
  }, { games: [], total: {}, limit: {} } as GamesWithTotal);
}
