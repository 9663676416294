<section class="transfers">
  <div class="wrapper">
    <form [formGroup]="form">
      <div class="container">
        <span class="container__title" spMsg="CASHIER.TRANSFERS.SUM"></span>
        <div class="container__wrapper">
          <gg-input formControlName="amount" [errors]="errors.get('amount') | async"></gg-input>
          <span class="container__currency">{{userCurrency$ | async | currencySymbol}}</span>
        </div>
      </div>
      <div class="container">
        <span class="container__title" spMsg="CASHIER.TRANSFERS.RECIPIENT"></span>
        <gg-input formControlName="nick" [errors]="errors.get('nick') | async"></gg-input>
      </div>
      <gg-button (click)="makeTransfer()" [disabled]="form.invalid" [loading]="loading$ | async">
        <sp-msg spMsg="CASHIER.TRANSFERS.MAKE_TRANSFER"></sp-msg>
      </gg-button>
    </form>
  </div>
</section>
