<div class="bonus-dropdown">
  @if (!isBonusesEnabled) {
    <img class="bonus-dropdown__verification-img" [src]="'{project}/images/bonuses/{palette}/verified.svg' | path" alt="verification-icon"/>
    <div class="bonus-dropdown__verification-text">
      <sp-msg msg="CASHIER.BONUSES.VERIFICATION_TEXT"></sp-msg>
    </div>
    <gg-button class="bonus-dropdown__verification-button" [routerLink]="['/user', 'verification']">
      <sp-msg msg="VERIFICATION.HEADER.TITLE"/>
    </gg-button>
  } @else {
    <h3 class="bonus-dropdown__title" spMsg="CASHIER.BONUSES.CHOOSE_BONUS"></h3>
    <section class="bonus-dropdown__container">
      @if ((bonusesList | async)?.length) {
        <div class="bonus-dropdown__button" *spLet="activeSelectedBonus$ | spAsync as active" (click)="buttonClicked.emit()">
          @if (active) {
            <gg-bonus-card-icon
              class="bonus-dropdown__icon"
              [ngClass]="{'bonus-card__icon--double': isLinkedBonus}"
              [bonus]="active"
              [isUseNewIcon]="isLinkedBonus"
            />
            <div class="bonus-dropdown__text">
              <ng-container>{{ active | bonusTitle }}</ng-container>
            </div>
            <img src="assets/images/icons/arrow-small.svg" class="bonus-dropdown__arrow" alt=""/>
          } @else {
            <img class="bonus-dropdown__icon" [src]="'{project}/images/bonuses/{palette}/no-bonus.svg' | path"/>
            <div class="bonus-dropdown__text"><sp-msg msg="BONUSES.NO_BONUS"/></div>
            <img src="assets/images/icons/arrow-small.svg" class="bonus-dropdown__arrow" alt=""/>
          }
        </div>
      } @else {
        <div class="bonus-dropdown__no-bonuses">
          <span class="bonus-dropdown__no-bonuses-text" spMsg="CASHIER.BONUSES.NO_BONUSES_TITLE"></span>
        </div>
      }
    </section>
    @if (showPromocodeInput) {
      <gg-payment-promo-code (checkChanges)="promocodeActivated()" class="promocode__input" [noLabelPlaceholder]="false"/>
    } @else {
      <span class="promocode__title" (click)="showPromocodeInput = true" spMsg="AUTH.REGISTRATION.I_HAVE_PROMO_CODE"></span>
    }
  }
</div>

