import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export const CASHIER_BONUS_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  spaceBetween: 16,
  centeredSlides: true,
  slideToClickedSlide: true,
  navigation: {
    nextEl: '.slider-bonuses__btn-next',
    prevEl: '.slider-bonuses__btn-prev',
    disabledClass: '.slider-bonuses__btn-disabled'
  }
};
