import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export function kycaidVerificationGuard(
  next: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> {
  const router = inject(Router);
  const restrictedPages$ = inject(UserQuery).restrictedPages$;
  const  enableWidget = inject(UserQuery).blocking?.paymentsWidget;

  const page = next.data['page'] || next.params['type'];
  const redirect = enableWidget ? router.createUrlTree([ 'user', 'verification', 'prompt' ]) : router.createUrlTree([ 'cashier', 'deposit-verification' ]);

  return restrictedPages$.pipe(
    skipWhile(restrictedPage => !restrictedPage),
    map((restrictedPage) => {
    const isGamesEnabled = restrictedPage?.gamesDisabledDueToNonContact ? true : restrictedPage?.gamesEnabled;

    const canAccessDeposit = page === 'deposit' && restrictedPage?.depositsEnabled;
    const canPlay = page === 'games' && isGamesEnabled;
    const canWithdraw = page === 'withdrawal' && restrictedPage?.withdrawalsEnabled;

    return canAccessDeposit || canWithdraw || canPlay || redirect;
  })
  );
}
