import { ChangeDetectionStrategy, Component, inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ErrorManager } from '@portal/shared/helpers';
import { EmailConfirmationService } from '@portal/user/features/email-confirmation/email-confirmation.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gg-user-email-confirmation-plug',
  templateUrl: './email-confirmation.component.html',
  styleUrls: [ './email-confirmation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ ErrorManager, EmailConfirmationService ]
})
export class EmailConfirmationComponent implements OnDestroy {
  private readonly confirmationService = inject(EmailConfirmationService);
  private readonly viewStateWatcherSubscription = this.confirmationService.createViewStateWatcher();

  readonly view$ = this.confirmationService.view$;
  readonly isVisible$ = this.view$.pipe(map((view) => view !== 'disabled'));
  readonly isConfirmed$ = this.view$.pipe(map((view) => view === 'confirmed'));

  constructor() {
    this.viewStateWatcherSubscription = this.confirmationService.createViewStateWatcher();
  }

  ngOnDestroy(): void {
    this.viewStateWatcherSubscription.unsubscribe();
  }
}
