import { GamesWithTotal } from '@portal/games/data';
import { createGame } from '@portal/games/data/mappers/create-game';
import { SectionGamesResponse } from '@portal/games/shared';

export function createGameSearch(games: SectionGamesResponse): Omit<GamesWithTotal, 'total'> & {
  total: number;
} {
  return {
    games: games._items.map(createGame),
    total: games._meta.total
  };
}
