import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HostedPipe, SP_HTTP_CONFIG, SP_HTTP_SERVER_HOST } from '@libs/http';
import { isUrlMatched } from '@libs/utils';
import { Observable } from 'rxjs';

@Injectable()
export class SpHttpHostInterceptor implements HttpInterceptor {
  private readonly config = inject(SP_HTTP_CONFIG, { optional: true });
  private readonly hostedPipe = inject(HostedPipe);

  constructor() {
    const serverHost = inject(SP_HTTP_SERVER_HOST, { optional: true });
    if (!serverHost || !this.config) { return; }
    this.config.host = { ignore: this.config.host?.ignore, url: serverHost };
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config?.host) { return next.handle(req); }
    const { ignore } = this.config.host;

    return !ignore || !isUrlMatched(ignore)(req.url)
      ? next.handle(req.clone({ url: this.hostedPipe.transform(req.url) }))
      : next.handle(req);
  }
}
