// @ts-nocheck

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionsHistory } from '@portal/cashier/data/entities/history';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'pd-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: [ './history-item.component.scss' ]
})
export class HistoryItemComponent {
  readonly state$: BehaviorSubject<'normal' | 'cancelling'> = new BehaviorSubject('normal');
  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly questionButtonYes: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly questionButtonNo: IButton = { size: ButtonSizes.Medium };
  readonly enableHistoryCardInfo = this.source.modules.cashier?.enableHistoryCardInfo;

  @Input() operation: TransactionsHistory;
  @Output() cancelling: EventEmitter<TransactionsHistory> = new EventEmitter();

  constructor(private readonly source: ConfigQuery) {}

  toggleCancel(state?): void {
    const newState = this.state$.getValue() === 'normal' ? 'cancelling' : 'normal';
    this.state$.next(state || newState);
  }

  cancel(): void {
    this.cancelling.emit(this.operation);
    this.toggleCancel(false);
  }
}
