import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

type LetContext<T> = { spLet: T | null };

@Directive({ selector: '[spLet]' })
export class SpLetDirective<T> {
  private readonly ctx: LetContext<T> = { spLet: null };

  @Input() set spLet(value: T) { this.ctx.spLet = value; }
  
  constructor(
    vcRef: ViewContainerRef,
    tplRef: TemplateRef<LetContext<T>>
  ) { vcRef.createEmbeddedView(tplRef, this.ctx); }
}
