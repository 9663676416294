import { Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';

export const USER_DATA_INFO_NAME_PART_BIRTH = {
  first_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  last_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  birth_date: [
    '',
    [
      Validators.required,
      CustomValidators.birthday
    ]
  ],
};
