import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from "@angular/router";
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from "@portal/shared/components/controls";
import { SpCDKModule } from "@libs/cdk";


@Component({
  standalone: true,
  selector: 'gg-bonuses-blocked-overlay',
  templateUrl: './block-bonuses.component.html',
  styleUrls: ['./block-bonuses.component.scss'],
  imports: [ SpCDKModule, ControlsModule, RouterLink ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockBonusesComponent {
  readonly button: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Medium };
}
