import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { BonusExpiration } from '@portal/bonuses/shared';

@Component({
  selector: 'gg-bonus-card-expiration-timer',
  templateUrl: './timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent {

  @Input() bonus: Nullable<Bonus>;
  @Output() expired: EventEmitter<void> = new EventEmitter();

  get timer(): string | null {
    if (!this.bonus || !(this.bonus.status.current in this.bonus.expiration.timers)) { return null; }

    return this.bonus.expiration.timers[this.bonus.status.current as keyof BonusExpiration['timers']];
  }

}
