import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { FiatCurrency } from '@portal/currencies/data';

@Pipe({ name: 'rateNormalizer' })
export class RateNormalizerPipe implements PipeTransform {
  private readonly lang = inject(SpLocalization).currentLanguage;

  transform(rate: number, currency: FiatCurrency['name']): string {
    return formatCurrency(rate, this.lang, getCurrencySymbol(currency, 'narrow'), undefined, '1.4-4');
  }
}
