import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { BannerComponent } from '@portal/banners/shared';
import { ButtonSizes, ButtonThemes, ControlsModule, IButton } from '@portal/shared/components/controls';

@Component({
  standalone: true,
  selector: 'gg-banners-section',
  templateUrl: './section-banner.component.html',
  styleUrls: [ './section-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule ]
})
export class SectionBannerComponent extends BannerComponent {
  readonly goToLinkButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Green };
}
