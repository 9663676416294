<section
  *ngIf="isStubEnabled(); else elseBlock"
  class="cashier"
>
  <div [ngClass]="{'wrapper--bonuses': isBonusList, 'wrapper--crypto': showCrypto}"
       class="wrapper">
    <div *ngIf="!showWidget &&
                !showPhoneWidget &&
                !showPaysoftWidget &&
                !showCrypto &&
                !isAccentPayH2HCardData &&
                !isShowQrcode &&
                !showOctopayDepositBlock &&
                !showCryptoFlow &&
                !showCryptoError &&
                !isWaitingLoading"
         class="no-widget">

      <pd-payment-header [secondaryLogo]="headerLogo" class="cashier__header">
        <div class="cashier__title" spMsg="CASHIER.CARD.INSERT_AMOUNT"></div>
        <p class="cashier__header-info">
          <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"></sp-msg>
          {{ minAmount | currencyNumberRound | currencyNormalizer }}
          |
          <sp-msg spMsg="CASHIER.CARD.MAX" spaces="wrap"></sp-msg>
          {{ maxAmount | currencyNumberRound | currencyNormalizer }}
        </p>
      </pd-payment-header>
      <form [formGroup]="form">
        <gg-input-cash
          (changeAmount)="changeAmount()"
          (makeRequest)="sendRequest()"
          [errors]="errors.get('amount') | async"
          [settings]="cashInputSettings"
          formControlName="amount"
        ></gg-input-cash>

        <div *ngIf="showCactusWidget" class="cashier__info-banner-wrap">
          <sp-msg class="cashier__info-banner-content" spMsg="CASHIER.CARD.CACTUS_INFO"></sp-msg>
        </div>

        <div
          *ngIf="isWinPayPhoneDepositMethod || isPayhubDepositHostToHost || isPayperWithdrawal || isRoyalPayWithdrawalSBP
          || isPremierPayWithdrawal || isEcommpaySbpWithdrawal || isPaycosSBPWithdrawalMethod || isPaymentCenterSbpWithdrawal
          || isTwoSendSbpWithdrawal || isCypixSbpWithdrawal || isAntrpayWithdrawalSBP || isCactusSbpWithdrawalMethod || isBilllineMobileWithdrawal
          || isEcommpayMobileWithdrawal || isEuphoriaSBPWithdraw || isMaxPayWithdrawalH2h || isBlackRabbitWithdrawalSBP
          || isBigideaInteracWithdrawal || isPagoWithdrawalSbp || isMajorpaySbpWithdrawal || isExpaySbpWithdrawal ||
          isTurcodeSBPWithdrawal || isBNPAYWithdrawalSBP"
          class="winpay-phone"
        >
          <p class="cashier__title winpay-phone__title">
            <sp-msg spMsg="AUTH.PASSWORD_REMINDER.TYPE_PHONE"></sp-msg>
          </p>
          <gg-country-phone-input
            [errors]="errors.get('account') | async"
            [initialValue]="userPhone$ | spAsync"
            formControlName="account"
            placeholder="{{'AUTH.REGISTRATION.PHONE' | translate}}"
          ></gg-country-phone-input>
        </div>

        <div *ngIf="isEcommpayMobileWithdrawal" class="dropdown-operator-selector-container">
          <p class="cashier__title winpay-phone__title">
            <sp-msg spMsg="CASHIER.CHOOSE_OPERATOR"></sp-msg>
          </p>
          <gg-dropdown-operator-selector
            *ngIf="isEcommpayMobileWithdrawal"
            [paymentMethodId]="paymentMethod.id"
            formControlName="operator_id"
          ></gg-dropdown-operator-selector>
        </div>

        <div
          *ngIf="isRoyalPayWithdrawalSBP || isEcommpaySbpWithdrawal || isPaycosSBPWithdrawalMethod || isPaymentCenterSbpWithdrawal || isTwoSendSbpWithdrawal
          || isCypixSbpWithdrawal || isAntrpayWithdrawalSBP || isCactusSbpWithdrawalMethod || isEuphoriaSBPWithdraw ||
          isMaxPayWithdrawalH2h || isMaxPayDepositH2h || isBlackRabbitWithdrawalSBP || isPagoWithdrawalSbp ||
          isMajorpaySbpWithdrawal || isExpaySbpWithdrawal || isTurcodeSBPWithdrawal || isBNPAYWithdrawalSBP"
          class="dropdown-bank-selector-container"
        >
          <p class="cashier__title winpay-phone__title">
            <sp-msg spMsg="CASHIER.CHOOSE_BANK"></sp-msg>
          </p>
          <gg-dropdown-bank-selector
            [paymentMethodId]="paymentMethod.id"
            formControlName="bank_id"
          ></gg-dropdown-bank-selector>
        </div>
        <gg-bonuses-promo-code
          (checkChanges)="detectChanges()"
          *ngIf="isDepositPage"
          [methodMaxValue]="paymentMethod.max_value"
        ></gg-bonuses-promo-code>

        <pd-widget-cactus *ngIf="showCactusWidget" [form]="form"></pd-widget-cactus>

        <div *ngIf="isDepositPage && isOctopayDeposit || isUsbxDepositUntrusted" class="octopay-account">
          <div class="octopay-account__title cashier__title">
            <sp-msg spMsg="CASHIER.METHODS_TITLE.ENTER_CARD_NUMBER"></sp-msg>
          </div>
          <gg-input-bank-card
            [errors]="errors.get('account') | async"
            [noLabelPlaceholder]="true"
            class="withdrawal-info__account"
            formControlName="account"
          ></gg-input-bank-card>
        </div>

        <div *ngIf="isDepositPage && isMifinityMethods" class="mifinity-account">
          <div class="mifinity-account__email">
            <h4 class="card-number__title cashier__title">
              <sp-msg [spMsg]="'CASHIER.METHODS_TITLE.WALLET_EMAIL_MIFINITY'"></sp-msg>
            </h4>
            <gg-input-email
              [errors]="errors.get('email') | async"
              class="email"
              formControlName="email"
              placeholder="E-mail"
            ></gg-input-email>
          </div>
        </div>

        <ng-container *ngIf="isDepositPage">
          <div class="cashier__bonuses">
            <gg-bonuses-cashier-slider
              (setAmountField)="setAmountField($event)"
              [depositAmount]="form.get('amount').value"
              [maxMethodPrice]="paymentMethod.max_value"
            ></gg-bonuses-cashier-slider>
          </div>
        </ng-container>

        <div *ngIf="isCypixMobileDepositKZT" class="cypix-deposit-mobile-kzt">
          <ng-container>
            <h4 class="cashier__title cypix-deposit-mobile-kzt__title">
              <sp-msg spMsg="CASHIER.CHOOSE_OPERATOR"></sp-msg>
            </h4>
            <gg-input-select
              [additionalClass]="'operators'"
              [defaultOption]="operators ? operators[0] : null"
              [errors]="errors.get('operator') | async"
              [noLabelPlaceholder]="true"
              [options]="operators"
              formControlName="operator"
            >
            </gg-input-select>
          </ng-container>
        </div>

        <div *ngIf="paymentMethod.info_banner_key && !isCactusPaymentDepositMethod && !isCactusPaymentWithdrawalMethod" class="cashier__info-banner-wrap">
          <sp-msg [spMsg]="paymentMethod.info_banner_key" class="cashier__info-banner-content"></sp-msg>
        </div>

        <!--Choose card component-->
        <ng-container *ngIf="isPresetCardsListActive">
          <h4 class="cashier__title choose-card__title">
            <sp-msg spMsg="CASHIER.CARD.SELECT_CARD"></sp-msg>
          </h4>
          <div *ngIf="isPresetCardsListFilled else noPresetCards" class="choose-card__some">
            <div
              (click)="setActivePresetCard(card)"
              *ngFor="let card of presetCards"
              [ngClass]="{'choose-card__card--active': isPresetCardActive(card)}"
              class="choose-card__card"
            >
              <span class="circle"></span>
              {{ card.account }}
            </div>
            <gg-button
              (click)="navigateToDeposit()"
              [settings]="addCardGray"
              class="choose-card__button-gray"
            >
              <sp-msg spMsg="CASHIER.CARD.ADD_CARD"></sp-msg>
            </gg-button>
          </div>

          <ng-container *ngFor="let accountFormData of paymentMethod.form">
            <div *ngIf="isPayhubWithdrawalMethod"
                 class="card-number">
              <gg-input
                [errors]="errors.get('name') | async"
                [isTrim]="true"
                [placeholder]="accountFormData.placeholder | translate"
                class="card-data__input card-number__input"
                formControlName="name"
              >
              </gg-input>
              <gg-input
                [errors]="errors.get('surname') | async"
                [isTrim]="true"
                [placeholder]="accountFormData.placeholder | translate"
                class="card-data__input card-number__input"
                formControlName="surname"
              >
              </gg-input>
            </div>
          </ng-container>

          <ng-template #noPresetCards>
            <div class="choose-card__none">
              <h4 class="choose-card__title-sm" spMsg="CASHIER.CARD.DONT_HAVE_CARDS"></h4>
              <p class="choose-card__text">
                <sp-msg spMsg="CASHIER.CARD.FOR_ADD_CARD_1" spaces="end"></sp-msg>
                <span>{{ minAmount | currencyNormalizer }}</span>
                <sp-msg spMsg="CASHIER.CARD.FOR_ADD_CARD_2" spaces="start"></sp-msg>.
              </p>
              <gg-button (click)="navigateToDeposit()" class="choose-card__button-green">
                <sp-msg spMsg="CASHIER.CARD.ADD_CARD"></sp-msg>
              </gg-button>
            </div>
          </ng-template>
        </ng-container>

        <div
          *ngIf="isAdditionalInfoInputActive && !isPayperWithdrawal && !isRoyalPayWithdrawalSBP && !isPremierPayWithdrawal
           && !isEcommpaySbpWithdrawal && !isPaycosSBPWithdrawalMethod && !isCactusPaymentWithdrawalMethod && !isPaymentCenterSbpWithdrawal
           && !isTwoSendSbpWithdrawal && !isCypixSbpWithdrawal && !isAntrpayWithdrawalSBP  && !isCactusSbpWithdrawalMethod && !isBilllineMobileWithdrawal
           && !isEcommpayMobileWithdrawal && !isEuphoriaSBPWithdraw && !isMaxPayWithdrawalH2h &&!isMaxPayDepositH2h &&
           !isBlackRabbitWithdrawalSBP && !isBigideaInteracWithdrawal && !isPagoWithdrawalSbp && !isMajorpaySbpWithdrawal
           && !isExpaySbpWithdrawal && !isTurcodeSBPWithdrawal && !isBNPAYWithdrawalSBP"
          class="card-number"
        >
          <ng-container *ngFor="let accountFormData of paymentMethod.form">
            <div *ngIf="isCypixWithdrawalRuFixed || isEcommpayWithdrawalFixedAmount" class="card-number__amount-hint">
              <sp-msg [params]="{amount: roundingStep}" spMsg="CASHIER.CARD.AMOUNT_HINT"></sp-msg>
            </div>

            <h4 class="card-number__title cashier__title">
              <sp-msg [spMsg]="accountFormData.title"></sp-msg>
            </h4>

              <section
                *ngIf="isWinPayVisaWithdrawalMethod ||
                       isWinPayWithdrawalMethod ||
                       isXpateWithdrawalNewMethod ||
                       isPayhubWithdrawalWithoutTokenMethod ||
                       exactlyWithdrawalUpdatedMethod ||
                       isXpateTransferWithdrawal ||
                       isRoyalPayWithdrawal ||
                       isUsbxWithdrawal ||
                       isPremierPayWithdrawal ||
                       isWithdrawalsFixed ||
                       isSmartGatesWithdrawal ||
                       isPaycosBankCardWithdrawals ||
                       isBtGateWithdrawalMethod ||
                       isCirxusWithdrawal; else simpleWithdrawal"
                class="withdrawal-info">
                <gg-input-bank-card
                  [errors]="errors.get('account') | async"
                  [placeholder]="accountFormData.placeholder | translate"
                  class="withdrawal-info__account"
                  formControlName="account"
                ></gg-input-bank-card>
                <gg-input
                  *ngIf="isXpateWithdrawalNewMethod || isUsbxWithdrawal || isSmartGatesWithdrawal"
                  [autocomplete]="'cc-name'"
                  [errors]="errors.get('name') | async"
                  [defaultPlaceholder]="accountFormData.name | translate"
                  class="withdrawal-info__name"
                  formControlName="name"
                ></gg-input>
                <div class="withdrawal-info__date">
                  <gg-input-card-month
                    [errors]="errors.get('expM') | async"
                    class="withdrawal-info__month"
                    formControlName="expM"
                  ></gg-input-card-month>
                  <gg-input-card-year
                    [errors]="errors.get('expY') | async"
                    class="withdrawal-info__year"
                    formControlName="expY"
                  ></gg-input-card-year>
                </div>
              </section>
            <ng-template #simpleWithdrawal>
              <gg-input-bank-card
                [autocomplete]="isNotBankCardMethod ? '' : 'cc-number'"
                [errors]="errors.get('account') | async"
                [mask]="isNotBankCardMethod ? '' : '0000 0000 0000 0000 0000'"
                [placeholder]="accountFormData.placeholder | translate"
                class="card-data__input card-number__input"
                formControlName="account"
              ></gg-input-bank-card>
              <span *ngIf="isXpateWithdrawalInterac || isPraxisWithdrawalMuchBetter" class="withdrawal-info__text">
                <sp-msg spMsg="CASHIER.CARD.PHONE_NUMBER_HINT"></sp-msg>
              </span>
            </ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="showTermCheckbox()">
          <div class="agreement-item">
            <gg-input-checkbox [errors]="errors.get('privacyPolicy') | async" class="checkbox__item"
                               for="agree" formControlName="privacyPolicy">
              <p spMsg="AUTH.REGISTRATION.I_AGREE_WITH"></p>
            </gg-input-checkbox>
          </div>
        </ng-container>
      </form>
      <gg-input-error [errors]="errors.get('form') | async"></gg-input-error>
      <div *ngIf="isDepositPage" class="deposit__info">
        <div *ngIf="isExactlyMastercardDeposit" class="exactly-info-block">
          <sp-msg spMsg="CASHIER.CARD.INTERNATIONAL_CARDS_INFO"></sp-msg>
        </div>
        <span class="deposit__info__pay">
          <sp-msg spMsg="HEADER.CASHIER" spaces="end"></sp-msg>
          {{ depositPayInfo | currencyNormalizer }}
        </span>
        <span
          *ngIf="!!this.activeBonus"
          class="deposit__info__bonus"> | <sp-msg [spMsg]="depositBonusInfo"></sp-msg>
        </span>
      </div>
      <gg-mastercard-info-block [method]="paymentMethod.id"></gg-mastercard-info-block>
      <div *ngIf="isShowMonetixRegistrationInfoBlock" class="monetix-info-block">
        <sp-msg spMsg="CASHIER.CARD.MONETIX_INFO" spaces="end"></sp-msg>
        <sp-msg class="monetix-info-block--bold" spMsg="CASHIER.CARD.NO_MORE_10_SEC"></sp-msg>
      </div>
      <div *ngIf="isCypixMobileMethod" class="cashier__commission--operator">
        <img alt="" src="./assets/images/shared/attention.svg"/>
        <sp-msg spMsg="CASHIER.CARD.OPERATOR_COMMISSION"></sp-msg>
      </div>
      <div *ngIf="isPraxisDepositMuchBetter || isPraxisWithdrawalMuchBetter" class="muchbetter-info-text">
        <sp-msg spMsg="CASHIER.CARD.NO_ACC_MUCHBETTER" spaces="end"></sp-msg>
        <a href="https://a.api.muchbetter.com/merchant/user?trackingCode=UmExOTM5NjI3" target="_blank">
          <sp-msg spMsg="CASHIER.CARD.CREATE_ACC"></sp-msg>
        </a>
      </div>

      <div *ngIf="isCactusPaymentWithdrawalMethod" class="commission-info-green">
        <pd-dot-separated [value]="form.get('amount').value" label="CASHIER.SUM_WITHDRAWAL"/>
        <pd-dot-separated [value]="commission" label="CASHIER.COMMISSION"/>
      </div>

      <gg-button
        (click)="sendRequest()"
        *ngIf="showSubmit"
        [disabled]="isDisabledDepositButton || !form.valid"
        [fullWidth]="true"
        [settings]="depositButton"
        class="cashier__button-green"
        type="submit"
      >
        <sp-msg [spMsg]="callToAction"></sp-msg>
      </gg-button>
      <sp-msg
        *ngIf="isCardOnBlackList"
        class="withdrawal-info__black-list-card"
        spMsg="CASHIER.CARD.BLACK_LIST_CARD_ERROR"
      ></sp-msg>
      <div *ngIf="!isCypixMobileMethod || !isCactusPaymentWithdrawalMethod" class="cashier__commission">
        <sp-msg spMsg="CASHIER.COMMISSION" spaces="end"></sp-msg>
        {{ commissionSignApprox }}{{ commission | currencyNormalizer }}
        <span (click)="openDynamicCommissionInfoDialog()" *ngIf="isShowDynamicCommission" class="cashier__commission__question">
          ?
        </span>
      </div>
    </div>

    <section *ngIf="showWidget" class="widget">
      <pd-payment-header
        [secondaryLogo]="headerLogo"
        class="cashier__header">
      </pd-payment-header>

      <div *ngIf="isDepositPage && !isPaymentData" class="widget__pay-info">
        <p class="widget__sum">{{ widgetPayInfo | currencyNormalizer }}</p>
        <p *ngIf="widgetBonusInfo" class="widget__bonus">{{ widgetBonusInfo }}</p>
      </div>

      <pd-widget-user-data
        (isUserFormSaved)="hideUserData()"
        (userFormData)="saveFormData($event)"
        *ngIf="isPaymentData && isUserPaymentDataFormAvailable"
        [buttonName]="UserPaymentDataButtonName"
        [isCAD]="isPayperDeposit"
        [isThisUserFormLast]="isUserPaymentDataFormLast"
        [method]="paymentMethod.id"
        [paymentUserData]="paymentUserData$ | async"
        [phoneMask]="phoneMask"
        [showLoading]="showPDWidgetLoading"
        [title]="UserPaymentDataTitle"
        [userCountryCode]="userPaymentCountryCode$ | spAsync"
        [userForm]="userForm"
        [userNationality]="userPaymentNationality$ | spAsync"
        [zipCodeMask]="zipCodeMask"
        [originalCopiedMethodId]="originalCopiedMethodId"
      ></pd-widget-user-data>

      <ng-container *ngIf="showPDWidget && !isPaymentData">
        <gg-widget-pay
          (pdWidgetInfo)="sendPDWidgetRequest($event)"
          *ngFor="let accountFormData of paymentMethod.form"
          [method]="paymentMethod.id"
          [placeholder]="accountFormData.placeholder | translate"
          [selectedUserBankCard]="selectedUserBankCard$ | async"
          [showLoading]="showPDWidgetLoading"
          [needCvv]="!isBtGateP2PDepositMethod"
        ></gg-widget-pay>
      </ng-container>

      <p *ngIf="showLoadingPendingText" class="pending-text">
        <sp-msg spMsg="CASHIER.CARD.PENDING"></sp-msg>
      </p>

    </section>

    <section *ngIf="showPaysoftWidget" class="paysoft-widget">

      <pd-payment-header
        [secondaryLogo]="headerLogo"
        class="cashier__header">
        <div class="cashier__title">
          <sp-msg spMsg="CASHIER.METHODS_TITLE.ENTER_CARD_NUMBER"></sp-msg>
        </div>
      </pd-payment-header>

      <pd-paysoft-widget [widgetInfo]="paysoftWidgetInfo"></pd-paysoft-widget>

      <div class="cashier__commission">
        <sp-msg spMsg="CASHIER.COMMISSION" spaces="end"></sp-msg>
        {{ commissionSignApprox }}{{ commission | currencyNormalizer }}
      </div>
      <span
        (click)="openDynamicCommissionInfoDialog()"
        *ngIf="isShowDynamicCommission"
        class="cashier__commission__question"
      >?</span>
    </section>

    <div id="widget-wrapper"></div>

    <form #widgetFakeForm [action]="pdWidgetAction" [method]="pdWidgetMethod" ngNoForm>
      <input *ngFor="let param of pdWidgetParams | keyvalue" [name]="param.key" [value]="param.value" type="hidden"/>
    </form>

    <section *ngIf="showCrypto" class="crypto-block">
      <pd-payment-header
        [secondaryLogo]="headerLogo"
        class="cashier__header">
        <div [spMsg]="'CASHIER.CARD.INSERT_AMOUNT'" class="cashier__title"></div>
        <p class="cashier__header-info">
          <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"></sp-msg>
          {{ minAmount | currencyNormalizer }}
          |
          <sp-msg spMsg="CASHIER.CARD.MAX" spaces="wrap"></sp-msg>
          {{ maxAmount | currencyNormalizer }}
        </p>
      </pd-payment-header>

      <form [formGroup]="formCrypto" class="crypto-form">
        <gg-input-cash (changeAmount)="convertCrypto()"
                       (makeRequest)="cryptoProcessingWithdrawal()"
                       [errors]="errors.get('amount') | async"
                       [settings]="cashInputSettings"
                       formControlName="amount"></gg-input-cash>

        <div *ngIf="!isCryptoOneTnMethodsWithdrawal" class="crypto-currency">
          <div [spMsg]="'CASHIER.CARD.WALLET_CURRENCY'" class="crypto-currency__title cashier__title"></div>
          <!-- TODO: reimplement this to our default dropdown select -->
          <div class="select">
            <div
              (click)="showCryptoOptions = !showCryptoOptions"
              *spLet="cryptoActive$ | spAsync as crypto"
              class="select__option--active select__option"
            >
              <ng-container *ngIf="crypto">
                <img alt="" class="select__img" src="/assets/images/cashier/logos/{{crypto?.name}}.svg"/>
                <span class="select__text">{{ crypto?.name }}</span>
                <img [ngClass]="{'select__arrow--up': showCryptoOptions}" alt="" class="select__arrow" src="assets/images/icons/arrow-small.svg"/>
              </ng-container>
            </div>
            <ng-container *spLet="cryptoCurrency$ | spAsync as cryptos">
              <ul *ngIf="showCryptoOptions" class="select__options">
                <li (click)="changeActiveCrypto(crypto)" *ngFor="let crypto of cryptos" class="select__option">
                  <img alt="" class="select__img select__img--option" src="/assets/images/cashier/logos/{{crypto.name}}.svg"/>
                  <span>{{ crypto.name }}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
        <div class="crypto-address">
          <div class="crypto-address__title cashier__title">
            <sp-msg spMsg="CASHIER.CARD.CRYPTO_ADDRESS"></sp-msg>
          </div>
          <gg-input
            [errors]="errorsCrypto.get('address') | async"
            [noLabelPlaceholder]="true"
            formControlName="address"
          ></gg-input>
        </div>
        <span class="crypto-form__extra-info">
          <sp-msg spMsg="CASHIER.CARD.CRYPTO_EXTRA_INFO"></sp-msg>
        </span>
      </form>

      <div *spLet="cryptoActive$ | async as crypto" class="crypto-info">
        <h4 class="crypto-info__title">
          <sp-msg spMsg="CASHIER.CARD.WITHDRAWAL_CRYPTO_INFO"></sp-msg>
        </h4>
        <div class="crypto-info__block">
          <p class="crypto-info__text">
            <sp-msg spMsg="COMMON.MINIMUM"></sp-msg>
          </p>
          <span class="crypto-info__text-fake"></span>
          <p *ngIf="crypto" class="crypto-info__text crypto-info__text--bold">
            {{ crypto.min }}{{ crypto.name }}
          </p>
        </div>
        <div *spLet="convertCrypto$ as convertCrypto" class="crypto-info__block">
          <p class="crypto-info__text">
            <sp-msg spMsg="COMMON.TOTAL"></sp-msg>
          </p>
          <span class="crypto-info__text-fake"></span>
          <p *ngIf="crypto" class="crypto-info__text crypto-info__text--bold">
            ≈{{ convertCrypto }}{{ crypto.name }}
          </p>
        </div>
      </div>

      <gg-button
        (click)="cryptoProcessingWithdrawal()"
        *ngIf="showSubmit"
        [disabled]="!formCrypto.valid"
        [fullWidth]="true"
        [settings]="depositButton"
        class="cashier__button-green cashier__button-green--crypto"
        type="submit"
      >
        <sp-msg [spMsg]="callToAction"></sp-msg>
      </gg-button>

      <div class="cashier__commission">
        <sp-msg spMsg="CASHIER.COMMISSION" spaces="end"></sp-msg>
        {{ commissionSignApprox }}{{ commission | currencyNormalizer }}
        <span
          (click)="openDynamicCommissionInfoDialog()"
          *ngIf="isShowDynamicCommission"
          class="cashier__commission__question"
        >?</span>
      </div>
    </section>

    <pd-crypto-flow
      *ngIf="showCryptoFlow"
      [cryptoFlowInfo]="cryptoFlowInfo"
      [formInfo]="form"
      [paymentInfo]="paymentMethod"
      class="crypto-flow-section"
    ></pd-crypto-flow>

    <gg-crypto-error *ngIf="showCryptoError"></gg-crypto-error>

    <div *ngIf="showPhoneWidget" class="phone-widget">
      <pd-payment-header
        [secondaryLogo]="headerLogo"
        class="cashier__header">
        <div *ngIf="!showPhoneWidgetCode" class="header__middle header__middle--phone-widget">
          <div class="cashier__title header__title">
            <sp-msg spMsg="CASHIER.CARD.PHONE_WIDGET_TITLE"></sp-msg>
          </div>
          <span class="phone-widget__text">
            <sp-msg spMsg="CASHIER.CARD.EXAMPLE"></sp-msg>
            : +38080000000
          </span>
        </div>
        <div *ngIf="showPhoneWidgetCode" class="header__middle header__middle--phone-widget">
          <div class="cashier__title header__title">
            <sp-msg spMsg="PROFILE.CONTACT_DETAILS.ENTER_SMS_CODE"></sp-msg>
          </div>
        </div>
      </pd-payment-header>

      <form
        (ngSubmit)="sendPhone()"
        *ngIf="!showPhoneWidgetCode"
        [formGroup]="phoneWidgetForm"
        class="phone-widget__form"
      >
        <gg-input formControlName="phone"
                  placeholder="{{'PROFILE.CONTACT_DETAILS.PHONE' | translate}}"></gg-input>
        <gg-button
          (click)="sendPhone()"
          [disabled]="!phoneWidgetForm.valid"
          [fullWidth]="true"
          [settings]="depositPhoneWidgetButton"
          class="phone-widget__button"
        >
          <sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"></sp-msg>
        </gg-button>
      </form>
      <form (ngSubmit)="sendCode()" *ngIf="showPhoneWidgetCode" [formGroup]="phoneCodeWidgetForm"
            class="phone-widget__form">
        <gg-input formControlName="code" placeholder="{{'CASHIER.CARD.CONFIRM_CODE' | translate}}"></gg-input>
        <span class="phone-widget__text">
          <sp-msg spMsg="PROFILE.CONTACT_DETAILS.SMS_SENT_TO_NUMBER"></sp-msg>
          {{ phoneWidgetForm?.value?.phone }}
          <span (click)="showPhoneWidgetCode = !showPhoneWidgetCode" class="phone-widget__text phone-widget__text--change">
            <sp-msg spMsg="PROFILE.CONTACT_DETAILS.CHANGE_NUMBER"></sp-msg>
          </span>
        </span>
        <gg-button
          (click)="sendCode()"
          [disabled]="!phoneCodeWidgetForm.valid"
          [fullWidth]="true"
          [settings]="depositPhoneWidgetButton"
          class="phone-widget__button"
        >
          <sp-msg spMsg="PROFILE.CONTACT_DETAILS.APPROVE"></sp-msg>
        </gg-button>

        <span *ngIf="!showResendMessage" class="phone-widget__text phone-widget__text">
          <span class="phone-widget__text phone-widget__text--send">
            <sp-msg spMsg="PROFILE.CONTACT_DETAILS.SMS_SENT"></sp-msg>
          </span>
          <sp-msg spMsg="PROFILE.CONTACT_DETAILS.RESEND_MESSAGE" spaces="start"></sp-msg>
        </span>

        <span *ngIf="showResendMessage" class="phone-widget__text">
          <sp-msg spMsg="PROFILE.CONTACT_DETAILS.SMS_NOT_RECEIVED" spaces="end"></sp-msg>
          <span (click)="reSendCode()" class="phone-widget__text phone-widget__text--change">
            <sp-msg spMsg="PROFILE.CONTACT_DETAILS.RESEND_CODE"></sp-msg>
          </span>
        </span>
      </form>
    </div>

    <section *ngIf="isShowQrcode" class="qrcode">
      <pd-payment-header
        [secondaryLogo]="headerLogo"
        class="cashier__header">
      </pd-payment-header>
      <div class="qrcode__block">
        <img [src]="'data:image/png;base64,' + qrCodeLink" alt="qr-code" class="qrcode__img"/>
        <div class="qrcode__title">
          <sp-msg spMsg="COMMON.SCAN_QR_CODE"></sp-msg>
        </div>
      </div>
    </section>

    <pd-octopay-deposit-block *ngIf="showOctopayDepositBlock" [octopayInfo]="octopayInfo"></pd-octopay-deposit-block>
  </div>
</section>

<ng-template #elseBlock>
  <pd-stub id="stubBlock"></pd-stub>
</ng-template>
