import { AfterViewInit, ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ControlAdapter, tapOnce } from '@libs/utils';
import { CardComponentsModule } from '@portal/payment/components';
import { BankCard, PaymentCardsQuery } from '@portal/payment/data';
import { BANK_CARD_SWIPER_CONFIG, CardInfo, CardSelectorState, createBankCardForm, PaymentCardForm } from '@portal/payment/shared';
import { ControlsModule, SwiperModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SwiperContainer } from 'swiper/element';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, CardComponentsModule, SwiperModule ],
  selector: 'gg-payment-bank-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: [ './card-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [ ErrorManager ],
})
export class CardSelectorComponent implements OnInit, AfterViewInit {
  private readonly destroy$ = inject(DestroyRef);
  private readonly ngControl = inject(NgControl, { optional: true });
  private readonly adapter: ControlAdapter<typeof this.cardForm.value> = new ControlAdapter(this.ngControl);
  private readonly pageState: BehaviorSubject<CardSelectorState> = new BehaviorSubject<CardSelectorState>('init');

  readonly paymentQuery = inject(PaymentCardsQuery);
  readonly cards$: Observable<Array<BankCard>> = this.paymentQuery.cards$;
  readonly newCardButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Gray };
  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly pageState$: Observable<CardSelectorState> = this.pageState.asObservable();
  readonly cardForm: PaymentCardForm = createBankCardForm();
  readonly sliderConfig = BANK_CARD_SWIPER_CONFIG;

  errors: ErrorManager = inject(ErrorManager).setUp({ form: this.cardForm });

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  constructor() {
    this.adapter.registerOnModelChange((value) => this.cardForm.patchValue(value, { emitEvent: false }));
    this.cardForm.statusChanges.pipe(
      takeUntilDestroyed(this.destroy$),
    ).subscribe((status) => {
      const error = status === 'INVALID' ? { required: true } : null;
      this.adapter.control?.setErrors(error);
    });
  }

  ngOnInit(): void {
    this.cardForm.valueChanges.pipe(
      startWith(this.cardForm.value),
      takeUntilDestroyed(this.destroy$),
    ).subscribe((value) => this.adapter.change(value));

    this.cards$.pipe(
      tapOnce((cards) => {
        this.cardForm.patchValue(cards[ 0 ]);
      }),
      takeUntilDestroyed(this.destroy$),
    ).subscribe((cards) => {
      !cards.length && this.pageState.next('create');
    });
  }

  ngAfterViewInit(): void {
    this.pageState.next('init');

    timer(0).pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => {
      this.pageState.next(this.paymentQuery.cardsLength() ? 'default' : 'create');
    });
  }

  selectCard(card: CardInfo, cvv?: string): void {
    if (!this.cardForm) { return; }

    const cardValue = cvv ? { ...card, cvv } : card;
    this.cardForm.reset();
    this.cardForm.patchValue(cardValue);
  }

  switchState(state: CardSelectorState): void {
    this.pageState.next(state);
    this.cardForm.reset();
  }

  slideChanged(index: number = 0): void {
    const card = this.paymentQuery.getCard(index);
    const input = this.swiper.nativeElement.swiper.slides[ index ].getElementsByClassName('field__input')[ 0 ] as HTMLInputElement;
    const cvv = input?.value;
    this.selectCard(card, cvv);
  }

  slideDeleted(): void {
    this.swiper.nativeElement.swiper.slideTo(0);
    this.slideChanged();
  }
}
