import { SwiperOptions } from 'swiper/types';

export const DEFAULT_SWIPER_CONFIG: SwiperOptions = {
  speed: 700,
  initialSlide: 0,
  slidesPerView: 'auto',
  keyboard: true,
  mousewheel: false,
  scrollbar: false,
  navigation: true,
  pagination: false,
  allowTouchMove: true,
  breakpoints: {
    993: {
      allowTouchMove: false
    }
  }
};
