import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { CurrencySymbol } from '@portal/currencies/shared';
import { UserInfo, UserState, UserStore } from '@portal/user/data';
import { combineLatest, map } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends SteroidQuery<UserState> {
  readonly id$ = this.select('id');
  readonly playerStatus$ = this.select('status');
  readonly registration$ = this.select('registration');
  readonly settings$ = this.select('settings');
  readonly personal$ = this.select('personal');
  readonly verification$ = this.select('verification');
  readonly restrictedPages$ = this.select('restrictedPages');
  readonly paymentData$ = this.select('paymentData');
  readonly balance$ = this.select('balance');
  readonly blocking$ = this.select('blocking');
  readonly level$ = this.select('level');

  readonly isEmailFaked$ = this.registration$.pipe(map((r) => r?.emailFaked));
  readonly passwordExists$ = this.registration$.pipe(map((r) => r?.passwordExists));

  readonly currency$ = this.settings$.pipe(map((s) => s?.currency));

  readonly nickname$ = this.personal$.pipe(map((p) => p?.nick || p?.email?.split('@')[ 0 ]));
  readonly hasNickname$ = this.personal$.pipe(map((p) => !!p?.nick));
  readonly email$ = this.personal$.pipe(map((p) => p?.email));
  readonly isSpentMoney$ = this.personal$.pipe(map((p) => p?.isSpentMoney));
  readonly mustChangeNick$ = this.personal$.pipe(map((p) => p?.mustChangeNick));

  readonly documents$ = this.verification$.pipe(map((v) => v?.documents.list));
  readonly otpVerification$ = this.verification$.pipe(map((v) => v?.otp));
  readonly isDocumentsConfirmed$ = this.verification$.pipe(map((v) => v?.documents.verified));
  readonly kycStatus$ = this.verification$.pipe(map((v) => v?.kycaid.status));

  readonly country$ = this.paymentData$.pipe(map((p) => p?.country));
  readonly paymentDataNationality$ = this.paymentData$.pipe(map((p) => p?.nationality));

  readonly bonusBalance$ = this.balance$.pipe(map(b => b?.bonusBalance));
  readonly bonusCount$ = this.balance$.pipe(map((b) => b?.bonusCount));
  readonly hasBonusBalance$ = this.balance$.pipe(map(b => b?.bonusBalance !== '0.00'), distinctUntilChanged());

  readonly selfBlocking$ = this.blocking$.pipe(map((b) => !!b?.selfBlocked));
  readonly selfBlockingUntil$ = this.blocking$.pipe(map((b) => b?.selfBlockedUntil));

  readonly phone$ = combineLatest([ this.personal$, this.paymentData$ ]).pipe(map(([ p, pd ]) => p?.phone || pd?.phone));

  readonly confirmation$ = combineLatest(
    [ this.verification$, this.restrictedPages$, this.registration$ ],
  ).pipe(map(([ verification, restricted, registration ]) => ({
    email: verification?.email.confirmed,
    phone: verification?.phone.confirmed,
    kyc: restricted?.withdrawalsEnabled,
    emailFaked: registration?.emailFaked,
  })));

  get id(): Nullable<string> { return this.get('id'); }

  get currency(): Nullable<CurrencySymbol> { return this.get('settings')?.currency; }

  get balance(): Nullable<UserInfo['balance']> { return this.get('balance'); }

  get blocking(): Nullable<UserInfo['blocking']> { return this.get('blocking'); }

  get level(): Nullable<UserInfo['level']> { return this.get('level'); }

  get confirmation(): Record<'email' | 'phone' | 'kyc' | 'emailFaked', Nullable<boolean>> {
    const { verification, restrictedPages, registration } = this.get();
    return {
      email: verification?.email.confirmed,
      phone: verification?.phone.confirmed,
      kyc: restrictedPages?.withdrawalsEnabled,
      emailFaked: registration?.emailFaked,
    };
  }

  get isPokerUser(): boolean { return Boolean(this.get('registration')?.isPokerUser); }

  get selfBlocking(): boolean { return Boolean(this.get('blocking')?.selfBlocked); }

  get isBonusesEnabled(): boolean { return Boolean(this.get('restrictedPages')?.bonusesEnabled); }

  get isDepositEnabled(): boolean { return Boolean(this.get('blocking')?.deposit); }

  get isWithdrawalEnabled(): boolean { return Boolean(this.get('blocking')?.withdrawal); }

  constructor(store: UserStore) { super(store); }
}
