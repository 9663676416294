import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { PaymentContainerType } from '@portal/payment/shared';
import { ContentModule } from '@portal/shared/components';
import { NgScrollbar } from "ngx-scrollbar";

@Component({
  standalone: true,
  selector: 'gg-payment-mobile-container-nav',
  templateUrl: './mobile-container-nav.component.html',
  styleUrls: [ './mobile-container-nav.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SpCDKModule, ContentModule, RouterModule, NgScrollbar],
})
export class MobileContainerNavComponent {
  @Input({ required: true }) containers: Nullable<Array<PaymentContainerType>> = null;
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) active: Nullable<string> = null;

  @Output() selected = new EventEmitter<string>();

  onSelect(id: Nullable<string>): void {
    id && this.selected.emit(id);
  }
}
