import { inject } from '@angular/core';
import { Nullable } from '@libs/utils';
import { CurrenciesRepository, FiatCurrency, SelectFiatSettings } from '@portal/currencies/data';

export class GetDefaultCurrency {
  private readonly repo = inject(CurrenciesRepository);

  receive({ list = 'all' }: Pick<SelectFiatSettings, 'list'> = {}): Nullable<FiatCurrency> {
    return this.repo.get('fiat')[ list ]?.find((currency) => currency.is.default);
  }
}
