import { InjectionToken, PipeTransform } from '@angular/core';
import { BaseProvider } from '@libs/utils';
import { Language, SpTranslationLoader } from './language';

export const SP_LANGUAGES_CONFIG = new InjectionToken<SpLocalizationConfig['languages']>('configs of languages');
export const SP_LOCALIZATION_TRANSFORM = new InjectionToken<SpLocalizationConfig['transform']>('additional pipes');

export type SpLocalizationConfig = {
  useBaseHref: 'always' | 'not-default' | 'never';
  languages?: { list?: Array<Language>; default: Language };
  loader: BaseProvider<SpTranslationLoader>;
  transform?: BaseProvider<PipeTransform>;
};
