import { FormControl, FormGroup } from '@angular/forms';

export const createOtpForm = (): FormGroup<{
  code: FormControl<string>;
  userId: FormControl<string>;
  loginMeta: FormControl<string>;
}> => new FormGroup({
  code: new FormControl<string>('', { nonNullable: true }),
  userId: new FormControl<string>('', { nonNullable: true }),
  loginMeta: new FormControl<string>('', { nonNullable: true })
});
