import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { synchronize$ } from '@libs/store';
import { Nullable, OrArray } from '@libs/utils';
import { CountriesData, CountriesQuery, Country } from '@portal/countries';
import { ControlsModule } from '@portal/shared/components';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { map, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-country-selector',
  templateUrl: './country-selector.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CountrySelectorComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent extends InputAbstract implements OnInit {
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly query = inject(CountriesQuery);
  private readonly commands = inject(CountriesData);
  private readonly selectedCountry = this.query.country$;

  readonly countriesList$ = this.selectedCountry.pipe(this.selectCountries$());
  readonly country = new FormControl();

  defaultItem: Nullable<IInputSelectData>;

  @Input() icons = false;

  override ngOnInit(): void {
    this.country.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((v) => this.writeValue(v));
  }

  private selectCountries$(): (source$: Observable<Nullable<string>>) => Observable<Array<IInputSelectData>> {
    return (source$) => source$.pipe(
      synchronize$(this.commands, this.query),
      switchMap((country) => this.query.countries$.pipe(
        map((countries) => this.mapCountryToSelectOption(countries)),
        tap((countries) => {
          this.defaultItem = countries.find(({ value }) => value === country);
          if (this.defaultItem) { this.writeValue(this.defaultItem.value); }
        })
      ))
    );
  }

  private mapCountryToSelectOption(country: Array<Country>): Array<IInputSelectData>;
  private mapCountryToSelectOption(country: Country): IInputSelectData;
  private mapCountryToSelectOption(country: OrArray<Country>): OrArray<IInputSelectData> {
    if (Array.isArray(country)) { return country.map((c) => this.mapCountryToSelectOption(c)); }
    return { value: country.isoCode, description: country.name, icon: this.icons ? country.src : null };
  }
}
