import moment, { Moment } from 'moment';
import { interval, map, Observable, startWith } from 'rxjs';

export type UnixTimer = { time: Moment; expired: boolean };

export function unixTimer$(time: number | string): Observable<UnixTimer> {
  return interval(1000).pipe(
    startWith(0),
    map(() => {
      const expireTime = Number(time) - moment().unix();
      return { time: moment(expireTime * 1000), expired: (Number(time) - moment().unix()) <= 0 };
    })
  );
}
