import { SpAuthorizationService } from '@libs/authorization';
import { SpConnectorConfig, SpLocalization, SpNavigation, SpStorage, SpViewport } from '@libs/cdk';
import { ReferralQuery } from '@portal/authorization/data';
import { ConfigQuery } from '@portal/config';
import { LayoutData } from '@portal/layout/data';
import { connectorInfo } from '@portal/shared/constants';
import { UserQuery } from '@portal/user';
import { ConfigCollector, LocalizationCollector, ParamsCollector, TokensCollector, UserCollector } from './collectors';
import { FrameExecutor, PageExecutor } from './executors';
import { BtMessageTransformer, PGMessageTransformer } from './transformers';

export const spConnectorConfig: SpConnectorConfig = {
  parseMessages: true,
  info: connectorInfo,
  collectors: [
    { useClass: UserCollector, deps: [ UserQuery ] },
    { useClass: LocalizationCollector, deps: [ SpLocalization ] },
    { useClass: ConfigCollector, deps: [ ConfigQuery ] },
    { useClass: ParamsCollector, deps: [ ReferralQuery ] },
    { useClass: TokensCollector, deps: [ SpAuthorizationService, ReferralQuery ] },
  ],
  transformers: [
    { useClass: PGMessageTransformer },
    { useClass: BtMessageTransformer },
  ],
  executors: [
    { useClass: PageExecutor, deps: [ SpViewport, SpNavigation ] },
    { useClass: FrameExecutor, deps: [ SpNavigation, LayoutData, SpStorage ] },
  ],
};
