import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  private readonly location = inject(LOCATION);

  private readonly errorPagePath = '/error';
  private readonly noCheckURLs = [
    '/api/2/cms/seo/text/game'
  ];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<void>> {
    const isSkipURL = this.noCheckURLs.some((filteredUrl) => request.url.includes(filteredUrl));

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 500 && this.location.pathname !== this.errorPagePath && !isSkipURL) {
          this.location.href = this.errorPagePath;
        }
        return throwError(() => error);
      })
    );
  }
}
