import { Validators } from '@angular/forms';

export const USER_DATA_INFO = {
  first_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  last_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  zip_code: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(10)
    ]
  ],
  country: [
    '', [Validators.required]
  ],
  phone: [
    '',
    [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(15)
    ]
  ],
  city: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  address: [
    '', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ]
};






