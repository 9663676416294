import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VerificationQuery } from '@portal/verification/data';

@Injectable()
export class UserConfirmedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userInfoQuery: UserQuery,
    private readonly verificationQuery: VerificationQuery,
    private readonly source: ConfigQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (route.params['type'] !== CashierSections.Withdrawal) {
      return of(true);
    }

    return combineLatest([
      this.userInfoQuery.confirmation$,
      of(true),
      this.verificationQuery.isKycEnable$
    ]).pipe(map(([ confirmation, isKycEnable ]) => {
        const withEmailStatement = this.source.modules.authentication.showEmailConfirmation
          && !isKycEnable
          && confirmation.email
          && confirmation.phone;

        const withoutEmailStatement = !this.source.modules.authentication.showEmailConfirmation
          && !isKycEnable
          && confirmation.phone;

        const withoutEmailStatementKyc = !this.source.modules.authentication.showEmailConfirmation
          && isKycEnable
          && confirmation.phone
          && confirmation.kyc;

        const withEmailStatementKyc = this.source.modules.authentication.showEmailConfirmation
          && isKycEnable
          && confirmation.email
          && confirmation.phone
          && confirmation.kyc;

        return withEmailStatement || withoutEmailStatement || withoutEmailStatementKyc || withEmailStatementKyc
          ? true
          : this.router.createUrlTree([ 'user', 'verification', 'warning' ]);
      })
    );
  }
}
