import { FormGroup, ValidationErrors } from '@angular/forms';
import { ErrorObject, Errors } from './types/error';

export class FormProcessor {
  private ignoreTouch = false;

  run(elements: FormGroup, ignoreTouch?: boolean): Errors {
    this.ignoreTouch = Boolean(ignoreTouch);
    return this.makePlainErrors(elements);
  }

  private makePlainErrors(form: FormGroup): Errors {
    return Object.entries(form.controls).reduce((acc, [ formElement, formControl ]) => {
      let errors = {};

      if (!!formControl.errors && (this.ignoreTouch || formControl.touched)) {
        errors = { ...errors, [formElement]: this.makeErrorObject(formControl.errors) };
      }

      if (formControl instanceof FormGroup) {
        errors = { ...errors, ...this.makePlainErrors(formControl) };
      }

      return { ...acc, ...errors };
    }, {});
  }

  private makeErrorObject(errors: ValidationErrors): Array<ErrorObject> {
    return Object.entries(errors).map(([ code, additionalProps ]) => ({ code, props: additionalProps }));
  }
}
