import { Component, inject } from '@angular/core';
import { BonusesData } from '@portal/bonuses/data';
import {
  CashierDataService,
  PaymentMethod,
  PaymentMethodsQuery,
  UserBankCardQuery
} from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { CashierSections } from '../../types/enums/sections.enum';
import { CashierLoaderService } from '@portal/cashier';

@Component({
  selector: 'pd-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: [ './withdrawal.component.scss' ]
})
export class WithdrawalComponent {
  private readonly cashierLoader = inject(CashierLoaderService);

  readonly section: CashierSections = CashierSections.Withdrawal;
  readonly paymentMethods$: Observable<Array<PaymentMethod>>;
  readonly isCashierLoading$: Observable<boolean>;

  constructor(
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly userCardsQuery: UserBankCardQuery,
    private readonly bonusCommand: BonusesData,
  ) {
    this.isCashierLoading$ = this.cashierLoader.isCashierLoading$ || this.paymentMethodsQuery.loading$;

    this.bonusCommand.synchronize();

    if (this.userCardsQuery.selectedCard) {
      this.cashierCommands.synchronize();
    }

    this.paymentMethods$ = paymentMethodsQuery.withdrawals$;
  }
}
