import { ByLanguage, Language } from '@libs/cdk';
import { Nullable } from '@libs/utils';

export class Seo {
  constructor(
    readonly id: string,
    readonly text: ByLanguage<string>,
    readonly h1: ByLanguage<string>,
    readonly tags: {
      title: Nullable<ByLanguage<string>>;
      description: Nullable<ByLanguage<string>>;
      keywords: Nullable<ByLanguage<string>>;
    }
  ) {}

  getHeading<Lang extends Language>(language: Lang): Seo['h1'][Lang] {
    return this.h1[ language ] || '';
  }

  getText<Lang extends Language>(language: Lang): Seo['text'][Lang] {
    return this.text[ language ] || '';
  }

  getTags<Lang extends Language>(language: Lang): Record<keyof Seo['tags'], ByLanguage<string>[Lang]> {
    return {
      title: this.tags.title?.[ language ] || '',
      description: this.tags.description?.[ language ] || '',
      keywords: this.tags.keywords?.[ language ] || ''
    };
  }
}
