import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { SegmentComponent } from '@portal/layout/components/breadcrubs';
import { Breadcrumb, BreadcrumbsData, BreadcrumbsQuery } from '@portal/layout/data';
import { Observable } from 'rxjs';

@Component({
  selector: 'gg-layout-breadcrumbs',
  standalone: true,
  templateUrl: './breadcrumbs.component.html',
  styleUrls: [ './breadcrumbs.component.scss' ],
  imports: [ SegmentComponent, SpCDKModule, ThemeModule, RouterLink ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly destroy = inject(DestroyRef);
  private readonly navigation = inject(SpNavigation);
  private readonly breadcrumbsData = inject(BreadcrumbsData);
  private readonly breadcrumbsQuery = inject(BreadcrumbsQuery);

  readonly breadcrumbs$: Observable<Array<Breadcrumb>> = this.breadcrumbsQuery.breadcrumbs$;

  ngOnInit(): void {
    this.navigation.event(NavigationEnd).pipe(
      takeUntilDestroyed(this.destroy)
    ).subscribe(() => this.breadcrumbsData.set(this.router.routerState.snapshot.url));
  }
}
