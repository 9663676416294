import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SummaryComponent } from '@portal/payment';
import { CommissionModule, GeneratedFormComponent, LoaderComponent } from '@portal/payment/components';
import { P2pCardComponent } from '@portal/payment/components/p2p-card/p2p-card.component';
import { PaymentFlowAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { StepperModule } from '@portal/shared/components/content/stepper';
import { SpCommonModule } from 'libs/cdk/src/lib/common';
import { SpLocalizationModule } from 'libs/cdk/src/lib/localization';

@Component({
  standalone: true,
  templateUrl: './p2p-flow.component.html',
  styleUrl: './p2p-flow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCommonModule, ControlsModule, SpLocalizationModule, StepperModule, SummaryComponent, P2pCardComponent, GeneratedFormComponent, LoaderComponent, CommissionModule ],
})
export class P2pFlowComponent extends PaymentFlowAbstract {
  constructor() { super('additional', 'payment'); }
}
