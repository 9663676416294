import { Injectable } from '@angular/core';

@Injectable()
export class SpWebApiStorage implements Storage {

  constructor(private readonly webApiStorage: Storage) {}

  get length(): number { return this.webApiStorage.length; }

  clear(): void { this.webApiStorage.clear(); }

  getItem(key: string): string | null { return this.webApiStorage.getItem(key); }

  removeItem(key: string): void { this.webApiStorage.removeItem(key); }

  setItem(key: string, value: string): void { this.webApiStorage.setItem(key, value); }

  key(index: number): string | null { return this.webApiStorage.key(index); }
}
