<section *spLet="userBankCards$ | spAsync as cardsList" class="user-bank-card">
  <div *ngIf="cardsList.length" class="user-bank-card__cards-list">
    <h4 class="user-bank-card__form-title">
      <sp-msg spMsg="CASHIER.CARD.SELECT_CARD"></sp-msg>
    </h4>
    <div
      *ngFor="let card of cardsList"
      class="user-bank-card__card"
      [ngClass]="{'user-bank-card__card_active': isCardActive(card.id)}"
      (click)="selectCard(card.id)"
    >
      <span class="user-bank-card__select"></span>
      {{card.mask}}
      <img
        class="user-bank-card__delete-card"
        src="assets/images/icons/cross2.svg"
        alt="close-icon"
        (click)="openDeleteCardDialog($event, card)"
      />
    </div>
    <gg-button
      *ngIf="!isNewCardFormShown"
      class="user-bank-card__add-new-card-button"
      [settings]="newCardButton"
      (buttonClicked)="showAddNewCardForm()">
      <sp-msg spMsg="CASHIER.CARD_VALIDATION.ADD_NEW_CARD"></sp-msg>
    </gg-button>
  </div>
  <form
    *ngIf="isNewCardFormShown"
    class="user-bank-card__new-card"
    [formGroup]="newCardForm"
  >
    <h4 class="user-bank-card__form-title">
      <sp-msg spMsg="CASHIER.METHODS_TITLE.ENTER_CARD_NUMBER"></sp-msg>
    </h4>
    <div class="user-bank-card__form-wrapper">
      <div class="user-bank-card__new-card-data">
        <div class="user-bank-card__new-card-header">
          <img class="user-bank-card__new-card-header-img" alt="card-icon" src="assets/images/icons/credit-card.svg"/>
          <h5 class="user-bank-card__new-card-header-title">
            <sp-msg spMsg="CASHIER.CARD_VALIDATION.BANK_CARD"></sp-msg>
          </h5>
        </div>
        <gg-input
          formControlName="account"
          class="user-bank-card__account"
          [placeholder]="'CASHIER.CARD.CARD_NUMBER' | translate"
          [autocomplete]="'cc-number'"
          [mask]="'0000 0000 0000 0000 0000'"
        ></gg-input>
        <gg-input
          formControlName="holder"
          class="user-bank-card__card-holder-name"
          [autocomplete]="'cc-name'"
          [placeholder]="'CASHIER.CARD.CARD_HOLDER_NAME' | translate"
        ></gg-input>
      </div>
      <div class="user-bank-card__exp-date">
        <p class="user-bank-card__exp-text">
          <sp-msg spMsg="CASHIER.CARD.CARD_EXP"></sp-msg>
        </p>
        <div class="user-bank-card__exp-date-inputs">
          <gg-input-card-month
            class="user-bank-card__exp-date-input"
            formControlName="exp_month"
          ></gg-input-card-month>
          <span class="user-bank-card__exp-date-inputs-divider">/</span>
          <gg-input-card-year
            class="user-bank-card__exp-date-input"
            formControlName="exp_year"
          ></gg-input-card-year>
        </div>
      </div>
    </div>
    <p *ngIf="newCardForm.invalid && newCardForm.touched" class="user-bank-card__form-error-message">
      <sp-msg spMsg="CASHIER.CARD_VALIDATION.FORM_ERROR"></sp-msg>
    </p>
  </form>
</section>


