import { ValidationErrors } from '@angular/forms';
import { excludeNullish } from '@libs/utils';
import cloneDeep from 'lodash.clonedeep';

type NullableValidation = ValidationErrors | null;

export const toggleError = (statement: boolean, error: string, errors: NullableValidation): NullableValidation => {
  let err = cloneDeep(errors) || {};
  err = statement ? { ...err, [ error ]: true } : excludeNullish({ ...err, [ error ]: undefined });

  return !Object.keys(err).length ? null : err;
};
