export const GROUPS_METHODS_TITLES: Record<string, { deposit: string; withdrawal: string }> = {
  'crypto': {
    deposit: 'CASHIER.SWIPER.SELECT_CRYPTOCURRENCY',
    withdrawal: 'CASHIER.SWIPER.SELECT_CRYPTOCURRENCY_WITHDRAWAL',
  },
  'generic': {
    deposit: 'CASHIER.SWIPER.SELECT_WALLET',
    withdrawal: 'CASHIER.SWIPER.SELECT_WALLET_WITHDRAWAL',
  },
  'bank_card': {
    deposit: 'CASHIER.SWIPER.SELECT_METHOD',
    withdrawal: 'CASHIER.SWIPER.SELECT_METHOD_WITHDRAWAL',
  },
  'p2p_card': {
    deposit: 'CASHIER.SWIPER.SELECT_P2P_CARD',
    withdrawal: 'CASHIER.SWIPER.SELECT_P2P_CARD_WITHDRAWAL',
  },
  'p2p_sbp': {
    deposit: 'CASHIER.SWIPER.SELECT_P2P_SBP',
    withdrawal: 'CASHIER.SWIPER.SELECT_P2P_SBP_WITHDRAWAL',
  },
};
