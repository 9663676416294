import { Directive, inject, Input } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Banner } from '@portal/banners/data';
import { ConfigQuery } from '@portal/config';

@Directive()
export abstract class BannerComponent {
  private readonly navigate = inject(SpNavigation);
  readonly palette = inject(ConfigQuery).theme.palette;

  @Input() banner: Nullable<Banner>;

  goToUrl(url?: string): void {
    const redirectUrl = url || this.banner?.settings.url;
    if (redirectUrl) { this.navigate.open(redirectUrl); }
  }
}
