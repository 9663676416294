import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  templateUrl: './empty.component.html',
  styleUrls: [ './empty.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyComponent {
  readonly button: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Medium };
}
