import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'gg-notification-bullet',
  templateUrl: './notification-bullet.component.html',
  styleUrls: [ './notification-bullet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBulletComponent {}
