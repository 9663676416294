import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { SelectCryptoCurrencies } from '@portal/currencies/commands';
import { CryptoCurrency } from '@portal/currencies/data';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';

@Component({
  selector: 'gg-currencies-crypto-selector',
  templateUrl: './crypto-selector.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CryptoSelectorComponent), multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CryptoSelectorComponent extends InputAbstract implements AfterViewInit {
  private readonly selectCryptoCurrencies = inject(SelectCryptoCurrencies);

  protected readonly cryptoActive = new FormControl();
  protected list: Array<IInputSelectData> = [];
  protected defaultItem: Nullable<IInputSelectData>;

  @Input() method: Nullable<string>;

  constructor(private readonly cd: ChangeDetectorRef) { super(); }

  override ngAfterViewInit(): void {
    if (!this.method) { return; }
    this.selectCryptoCurrencies.receive(this.method).subscribe((crypto: Array<CryptoCurrency>) => {
      this.list = this.mapCryptoSelectOption(crypto);
      this.defaultItem = this.getDefaultCryptoActive(crypto[ 0 ]?.name);
      this.defaultItem ? this.writeValue(this.defaultItem.value) : this.writeValue('');
      this.cd.detectChanges();
    });

    this.cryptoActive.valueChanges.subscribe((value) => {
      this.writeValue(value);
    });
  }

  getDefaultCryptoActive(cryptoActive: string): Nullable<IInputSelectData> {
    return this.list.find(crypto => crypto.value === cryptoActive);
  }

  private mapCryptoSelectOption(crypto: Array<CryptoCurrency>): Array<IInputSelectData>;
  private mapCryptoSelectOption(crypto: CryptoCurrency): IInputSelectData;
  private mapCryptoSelectOption(crypto: Array<CryptoCurrency> | CryptoCurrency): Array<IInputSelectData> | IInputSelectData {
    return Array.isArray(crypto)
      ? crypto.map((b) => this.mapCryptoSelectOption(b))
      : { value: crypto.name, description: crypto.name, icon: `/assets/images/cashier/logos/${crypto.name}.svg` };
  }

}
