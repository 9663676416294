<div class="bonuses-selector">
  <h4 class="bonuses-selector__title" spMsg="CASHIER.CHOOSE_OPERATOR"></h4>
  <div class="bonuses-selector__wrapper">
    <gg-input-radio/>
  <gg-radio-group>
    @for (bonus of bonusesList | async; track $index) {
    <gg-radio-button
      class="bonuses-selector__card"
      [value]="bonus"
      [active]="activeBonus?.id === bonus?.id"
      (click)="bonusSelected(bonus)"
      [ngClass]="{'selected' : activeBonus?.id === bonus?.id}">
      <div class="bonuses-selector__card_container">
        <div class="bonus-icon" [ngClass]="bonus?.type">
          <gg-bonus-card-icon [bonus]="bonus"></gg-bonus-card-icon>
        </div>
        <div class="bonus-title">{{ bonus | bonusTitle }}</div>
      </div>
      <div class="bonus-extra">
        <gg-bonus-card-activation class="bonus-deposit" [bonus]="bonus"/>
        <gg-bonus-card-wager-info class="bonus-wager" [bonus]="bonus"/>
        <gg-bonus-card-max-sum [bonus]="bonus"/>
        <gg-bonus-card-max-bet [bonus]="bonus"/>
        <gg-bonus-card-max-win class="bonus-max-win" [bonus]="bonus"/>
      </div>
    </gg-radio-button>
    }
    <gg-radio-button class="bonuses-selector__card no-bonus-card"
                     (click)="bonusSelected(null)"
                     [active]="!activeBonus"
                     [ngClass]="{'selected' : !activeBonus}">
      <div class="bonuses-selector__card_container">
        <div class="bonus-icon">
          <img class="no-bonus" [src]="'{project}/images/bonuses/{palette}/no-bonus.svg' | path"/>
        </div>
        <span class="bonus-title"><sp-msg msg="BONUSES.NO_BONUS"/></span>
      </div>
    </gg-radio-button>
  </gg-radio-group>
    <div class="apply-button">
      <gg-button [fullWidth]="true" (buttonClicked)="apply()" [settings]="applyButton">
        <sp-msg msg="CASHIER.CARD.APPLY"/>
      </gg-button>
    </div>
  </div>
</div>
