import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CardPartsModule } from '@portal/bonuses/components';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { CleanAndReplacePipe } from '@portal/shared/pipes';
import { WelcomeContainerComponent } from './welcome-container.component';
import { OpenPaymentsDirective } from "@portal/payment/features/open-payments/open-payments.directive";

@NgModule({
  imports: [ SpCDKModule, CardPartsModule, BonusPipesModule, CleanAndReplacePipe, OpenPaymentsDirective, ThemeModule ],
  declarations: [ WelcomeContainerComponent ],
  exports: [ WelcomeContainerComponent ]
})
export class WelcomeContainerModule {}
