import {Validators} from '@angular/forms';
import { USER_DATA_INFO_NAME_PART } from './user-data-form-part-name.constant';

export const USER_DATA_FN_ADDR_CITY_DOC = {
  ...USER_DATA_INFO_NAME_PART,
  city: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  address: [
    '', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(512)
    ]
  ],
  address_number: [
    '', [ Validators.required ]
  ],
  document_number: [
    '', [ Validators.required ]
  ]
};
