import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { Country } from '@portal/countries/data';

export type CountriesState = {
  country: Nullable<Country['isoCode']>;
  countries: Nullable<Array<Country>>;
  sync: boolean;
};

const initial: SteroidConfig<CountriesState> = {
  options: { name: 'countries' },
  initial: { countries: [], sync: false, country: null }
};

@Injectable({ providedIn: 'root' })
export class CountriesStore extends SteroidStore<CountriesState> {
  constructor() { super(initial); }
}
