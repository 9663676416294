import { inject } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { GlobalWindow, Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { createVerboxClientSettings, createVerboxConfig } from '@portal/chats/data';
import { InitConfig, verboxInit, VerboxWindow } from '@portal/chats/shared';
import { ChatAdapter } from '@portal/chats/shared/types/chat-adapter';
import { UserQuery } from '@portal/user';
import { ScriptService } from 'ngx-script-loader';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class VerboxAdapter extends ChatAdapter {
  private readonly localization = inject(SpLocalization);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly scriptLoader = inject(ScriptService);
  private readonly apiMethod = 'Verbox';
  private readonly id = '8d87a3657659f51cc400cc26e02d18aa';
  private readonly window: GlobalWindow<VerboxWindow> = inject(WINDOW) as GlobalWindow<VerboxWindow>;

  constructor(readonly domain: string, readonly scriptLink: string) {
    super();
  }

  init(userId: Nullable<string>, { manual = false }: InitConfig): void {
    this.window.supportAPIMethod = this.apiMethod;
    this.window.VerboxSetup = createVerboxConfig(this.domain, this.localization.currentLanguage, userId);
    this.createVerboxScript().pipe(
      tap(() => verboxInit(this.apiMethod, this.window)),
    ).subscribe(() => {
      this.setClientId();

      if (manual) {
        this.hide();
      }
    });
  }

  open(): void {
    this.window.Verbox('openSupport');
  }

  close(): void {
    //todo not implemented
  }

  show(): void {
    const verbox = this.window.Verbox;
    if (!verbox) { return; }
    verbox('showTrigger');
  }

  hide(): void {
    this.window.Verbox('hideTrigger');
  }

  chatName(): string {
    return 'Verbox';
  }

  private createVerboxScript(): Observable<Event> {
    const link = `${this.scriptLink}?h=${this.id}`;
    const attrs = { id: 'supportScript', charset: 'utf-8', async: 'true' };
    return this.scriptLoader.loadScript(link, attrs);
  }

  private setClientId(): void {
    combineLatest([
      this.userInfoQuery.id$,
      this.userInfoQuery.blocking$,
      this.userInfoQuery.personal$,
      this.userInfoQuery.isEmailFaked$,
      this.userInfoQuery.playerStatus$,
    ]).subscribe(([ userId, blocking, personal, isEmailFaked, playerStatus ]) => {
      const verbox = this.window.Verbox;
      if (!verbox || !userId) { return; }

      verbox('setClientInfo', createVerboxClientSettings({
        email: !isEmailFaked ? personal?.email : '',
        phone: personal?.phone,
        name: `${personal?.firstName} ${personal?.lastName}`,
        custom: {
          id: userId,
          vip: playerStatus,
          casino: !blocking?.slots,
          betting: !blocking?.betting,
        },
      }));
    });
  }
}
