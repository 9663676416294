import { DOCUMENT, LocationStrategy } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { parseUrl, SpPlatform, SpStorage } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { StorageKey } from '@portal/shared/types';

@Injectable({ providedIn: 'root' })
export class ReferralData {
  private readonly isServer = inject(SpPlatform).server;
  private readonly storage = inject(SpStorage);
  private readonly path = inject(LocationStrategy).path();
  private readonly document = inject(DOCUMENT);

  collect(): void {
    const [ , extra ] = parseUrl(this.path);
    const params = extra?.queryParams;

    this.setUriInfo(params);
    this.setFToken(params);

    !this.isServer && !params?.[ 'authToken' ] && this.setReferrer();
  }

  clear(): void {
    const keys = [ StorageKey.Cpa, StorageKey.UriParams, StorageKey.Referer, StorageKey.RegistrationSteps ];
    keys.forEach((key) => this.storage.removeItem(key));
  }

  private setUriInfo(params: Nullable<Params>): void {
    if (!params) { return; }
    const paramsString = JSON.stringify(params);
    params && this.storage.setItem(StorageKey.UriParams, paramsString);
    params[ 'click_id' ] && this.storage.setItem(StorageKey.Cpa, paramsString);
  }

  private setFToken(params: Nullable<Params>): void {
    if (!params?.[ 'ftoken' ]) { return; }

    this.storage.setItem(StorageKey.AppFToken, params[ 'ftoken' ]);
  }

  private setReferrer(): void {
    const { referrer, location: { host } } = this.document;
    const isDifferentDomain = referrer?.split('//')[ 1 ]?.split('/')[ 0 ] !== host;
    const isDifferentRef = this.storage.getItem(StorageKey.Referer) !== referrer;

    isDifferentRef && isDifferentDomain && this.storage.setItem(StorageKey.Referer, referrer);
  }
}
