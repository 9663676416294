import { HttpRequest } from '@angular/common/http';
import { Nullable } from '@libs/utils';
import { TokensPair } from './authorization.service';

export abstract class SpAuthorizationCommands<User = unknown, T = TokensPair> {

  abstract parseExpiresIn(response: unknown): Nullable<number>;

  abstract parseTokens(response: unknown): T;

  abstract mapDiscoveredUser(response: unknown): User;

  // eslint-disable-next-line
  patchDiscoveryRequest(request: HttpRequest<unknown>, tokens?: T): HttpRequest<unknown> { return request; }

  // eslint-disable-next-line
  patchRefreshRequest(request: HttpRequest<unknown>, tokens?: T): HttpRequest<unknown> { return request; }

  // eslint-disable-next-line
  patchSignOutRequest(request: HttpRequest<unknown>, tokens?: T): HttpRequest<unknown> { return request; }

  // eslint-disable-next-line
  patchSignInRequest(request: HttpRequest<unknown>, tokens?: T): HttpRequest<unknown> { return request; }

  // eslint-disable-next-line
  patchAuthorizedRequest(request: HttpRequest<unknown>, tokens?: T): HttpRequest<unknown> { return request; }
}
