<section [formGroup]="form" class="form" *spLet="method$ | spAsync as active">
  <ng-container *ngFor="let control of declaration">
    <ng-container *ngIf="control.type === 'checkbox' else otherFields">
      <gg-input-checkbox class="form__control" [formControlName]="control.name"><sp-msg [msg]="control.title"/></gg-input-checkbox>
    </ng-container>
    <ng-template #otherFields>
      <ng-container [ngSwitch]="control.type">
        <span *ngIf="control.title" [spMsg]="control.title" class="form__label"></span>
        <gg-input-date *ngSwitchCase="'date'" class="form__control" [formControlName]="control.name" [errors]="errors.get(control.name) | async"/>
        <gg-input-email *ngSwitchCase="'email'" class="form__control" [noLabelPlaceholder]="!control.placeholder"
                        [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [errors]="errors.get(control.name) | async"/>
        <gg-country-phone-input *ngSwitchCase="'phone'" class="form__control" [noLabelPlaceholder]="!control.placeholder" [initialValue]="control.value"
                                [formControlName]="control.name" [placeholder]="(control.placeholder | translate)" [errors]="errors.get(control.name) | async"/>
        <gg-payment-country-selector *ngSwitchCase="'country'" class="form__control" [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [paymentMethodId]="active.id" [errors]="errors.get(control.name) | async"/>
        <gg-country-selector *ngSwitchCase="'nationality'" class="form__control" [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [errors]="errors.get(control.name) | async"/>
        <gg-country-states-selector *ngSwitchCase="'state'" class="form__control" [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [errors]="errors.get(control.name) | async"/>
        <gg-input-zip-code *ngSwitchCase="'zip_code'" [noLabelPlaceholder]="!control.placeholder" class="form__control"
                           [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [errors]="errors.get(control.name) | async"/>
        <gg-dropdown-document-selector *ngSwitchCase="'document_type'" class="form__control" [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [errors]="errors.get(control.name) | async"/>
        <gg-dropdown-bank-selector *ngSwitchCase="'bank_id'" class="form__control" [formControlName]="control.name" [paymentMethodId]="active.id" [errors]="errors.get(control.name) | async"/>
        <gg-dropdown-operator-selector *ngSwitchCase="'operator_id'" class="form__control" [formControlName]="control.name" [paymentMethodId]="active.id" [errors]="errors.get(control.name) | async"/>
        <gg-currencies-crypto-selector *ngSwitchCase="'cryptocurrency'" class="form__control" [formControlName]="control.name" [method]="active.id" [errors]="errors.get(control.name) | async"/>
        <gg-input-bank-card *ngSwitchCase="'card'" class="form__control"
                            [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''"
                            [noLabelPlaceholder]="!control.placeholder" mask="0000 0000 0000 0000 0000" [errors]="errors.get(control.name) | async"></gg-input-bank-card>
        <gg-dropdown-messenger-selector *ngSwitchCase="'messenger'" class="form__control" [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''"/>
        <gg-input-card-month class="form__control_card-date form__control_card-date--mm" *ngSwitchCase="'expiry_month'"
                             [noLabelPlaceholder]="true"
                             [formControlName]="control.name" [errors]="errors.get(control.name) | async"/>
        <gg-input-card-year class="form__control_card-date" *ngSwitchCase="'expiry_year'" [noLabelPlaceholder]="true" [formControlName]="control.name" [errors]="errors.get(control.name) | async"/>
        <gg-input class="form__control" *ngSwitchDefault [formControlName]="control.name" [placeholder]="(control.placeholder | translate) || ''" [noLabelPlaceholder]="!control.placeholder" [errors]="errors.get(control.name) | async"/>
      </ng-container>
    </ng-template>
  </ng-container>
</section>
