import { interval, map, Observable, scan, switchMap, takeWhile } from 'rxjs';

export const timer$ = (delayInSeconds: number): (source$: Observable<unknown>) => Observable<number> => {
  return (source$) => source$.pipe(
    switchMap(() => interval(1000)),
    map(() => -1),
    scan((acc, cur) => acc + cur, delayInSeconds),
    takeWhile(value => value >= 0)
  );
};

