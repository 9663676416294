import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { register } from 'swiper/element/bundle';
import { SwiperDirective } from './swiper.directive';

register();

@NgModule({
  imports: [ SpCDKModule ],
  declarations: [ SwiperDirective ],
  exports: [ SwiperDirective ]
})
export class SwiperModule {}
