import { excludeNullish, GlobalWindow } from '@libs/utils';
import { CreateDepositDto } from '@portal/payment/data';
import { collectBrowserInfo, Payment } from '@portal/payment/shared';

export function createDepositRequest(payment: Payment, window: GlobalWindow): CreateDepositDto {
  const location = `${window.location.origin}/?section=deposit&method=${payment.method?.id}&amount=${payment.amount?.toString()}`;
  const { cvv, expMonth, expYear } = payment.data?.card || {};

  return {
    payment_method: Number(payment.method?.id),
    account: payment.data?.additional?.account || payment.data?.card?.account || null,
    amount: payment.amount?.toString(),
    browser_info: collectBrowserInfo(window, payment.data?.additional?.fingerprint),
    error_url: `${location}&status=error`,
    pending_url: `${location}&status=pending`,
    success_url: `${location}&status=success`,
    cancel_url: location.replace('status', 'deposit'),
    page_url: window.location.origin,
    card_data: excludeNullish({ cvv, exp_month: expMonth, exp_year: expYear }),
    code: payment.data?.additional?.code || null,
    initiatorID: payment.data?.additional?.initiatorId || null,
    present_id: payment.bonus?.id || '',
    present_type: payment.bonus?.type || '',
    transaction_id: payment.data?.additional?.transactionId || null,
    payment_data: { ...payment.data?.payment },
    operator: payment.data?.additional?.operator || null,
    user_card_id: payment.data?.card?.id || '',
    bank_id: payment.data?.additional?.bank_id,
    address: payment.data?.additional?.address || null,
    city: payment.data?.additional?.city || null,
    messenger: payment.data?.additional?.messenger || null,
    username: payment.data?.additional?.username || null,
  };
}
