import { ErrorResponse } from '@portal/shared/helpers/responses';
import { ErrorObject, Errors, ProcessServerErrors } from './types';

export class ServerProcessor implements ProcessServerErrors {

  run(response: ErrorResponse): Errors {
    return Array.isArray(response.issues) ? this.makeErrors(response.issues) : {};
  }

  private makeErrors(issues: ErrorResponse['issues']): Errors {
    return issues.reduce((acc, issue) => ({
      ...acc, [issue.field]: this.makeErrorObject(issue)
    }), {});
  }

  private makeErrorObject(issue: ErrorResponse['issues'][number]): Array<ErrorObject> {
    return [ { code: issue.code } ];
  }
}
