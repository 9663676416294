import { GamesWithTotal } from '@portal/games/data';
import { createGame } from '@portal/games/data/mappers/create-game';
import { SectionGamesResponse } from '@portal/games/shared';

export function createCasinoGames(games: SectionGamesResponse, source: string): GamesWithTotal {
  return {
    games: games._items.map(createGame),
    total: { [source]: games._meta.total }
  };
}
