<form [formGroup]="form" class="pd-widget-user-data">
  <h4 class="pd-widget-user-data__title">
    <sp-msg [spMsg]="title"></sp-msg>
  </h4>
  <gg-input
    *ngIf="userForm.first_name"
    class="pd-widget-user-data__input"
    placeholder="{{'PROFILE.PERSONAL_INFO.FIRST_NAME' | translate}}"
    formControlName="first_name"
    [isTrim]="true"
    [errors]="errors.get('first_name') | async"
  ></gg-input>
  <gg-input
    *ngIf="userForm.middle_name"
    class="pd-widget-user-data__input"
    placeholder="{{'PROFILE.PERSONAL_INFO.MIDDLE_NAME' | translate}}"
    formControlName="middle_name"
    [isTrim]="true"
    [errors]="errors.get('middle_name') | async"
  ></gg-input>
  <gg-input
    *ngIf="userForm.last_name"
    class="pd-widget-user-data__input"
    placeholder="{{'PROFILE.PERSONAL_INFO.LAST_NAME' | translate}}"
    formControlName="last_name"
    [isTrim]="true"
    [errors]="errors.get('last_name') | async"
  ></gg-input>
  <gg-input-date
    *ngIf="userForm.birth_date"
    class="pd-widget-user-data__input pd-widget-user-data__input_date"
    formControlName="birth_date"
    [errors]="errors.get('birth_date') | async"
    [placeholder]="'COMMON.DATE_OF_BIRTH' | translate"
  ></gg-input-date>
  <gg-input-email
    *ngIf="userForm.email"
    class="pd-widget-user-data__input"
    placeholder="{{'PROFILE.CONTACT_DETAILS.EMAIL' | translate}}"
    formControlName="email"
    [isTrim]="true"
    [errors]="errors.get('email') | async"
  ></gg-input-email>
  <gg-country-phone-input
    *ngIf="userForm.phone"
    class="pd-widget-user-data__input"
    placeholder="{{'AUTH.REGISTRATION.PHONE' | translate}}"
    formControlName="phone"
    [initialValue]="paymentUserData?.phone"
    [mask]="phoneMask || ''"
    [isTrim]="true"
    [errors]="errors.get('phone') | async"
  ></gg-country-phone-input>
  <div class="pd-widget-user-data__diver"></div>
  <gg-payment-country-selector
    *ngIf="userForm.country"
    class="pd-widget-user-data__input"
    formControlName="country"
    placeholder="{{'AUTH.REGISTRATION.COUNTRY' | translate}}"
    [paymentMethodId]="paymentMethodIdForSelector"
    [isTrim]="true"
    [errors]="errors.get('country') | async"
  ></gg-payment-country-selector>
  <gg-country-selector
    *ngIf="userForm.nationality"
    class="pd-widget-user-data__input"
    formControlName="nationality"
    placeholder="{{'PROFILE.CONTACT_DETAILS.NATIONALITY' | translate}}"
    [isTrim]="true"
    [errors]="errors.get('nationality') | async"
  ></gg-country-selector>
  <gg-input
    *ngIf="userForm.city"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.CITY' | translate}}"
    formControlName="city"
    [isTrim]="true"
    [errors]="errors.get('city') | async"
  ></gg-input>
  <gg-country-states-selector
    *ngIf="userForm.state"
    formControlName="state"
    class="pd-widget-user-data__input"
    placeholder="{{'AUTH.REGISTRATION.STATE' | translate}}"
    defaultPlaceholder="{{'AUTH.REGISTRATION.STATE_CHOSE' | translate}}"
    [country]="form.get('country').value"
    [errors]="errors.get('state') | async"
  ></gg-country-states-selector>
  <gg-input
    *ngIf="userForm.address"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.ADDRESS' | translate}}"
    formControlName="address"
    [isTrim]="true"
    [errors]="errors.get('address') | async"
  ></gg-input>
  <gg-input
    *ngIf="userForm.address_number"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.ADDRESS_NUMBER' | translate}}"
    formControlName="address_number"
    [isTrim]="true"
    [errors]="errors.get('address_number') | async"
  ></gg-input>
  <gg-input-zip-code
    *ngIf="userForm.zip_code"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.ZIP_CODE' | translate}}"
    formControlName="zip_code"
    [isTrim]="true"
    [errors]="errors.get('zip_code') | async"
    [mask]="zipCodeMask"
  ></gg-input-zip-code>
  <gg-dropdown-document-selector
    *ngIf="userForm.document_type"
    formControlName="document_type"
    class="pd-widget-user-data__input"
    [initialValue]="paymentUserData?.document_type"
    [isTrim]="true"
    [errors]="errors.get('document_type') | async"
  ></gg-dropdown-document-selector>
  <gg-input
    *ngIf="userForm.document_serial"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.DOCUMENT_SERIAL_NUMBER' | translate}}"
    formControlName="document_serial"
    [isTrim]="true"
    [errors]="errors.get('document_serial') | async"
  ></gg-input>
  <gg-input
    *ngIf="userForm.document_number"
    class="pd-widget-user-data__input"
    placeholder="{{'CASHIER.CARD.DOCUMENT_NUMBER' | translate}}"
    formControlName="document_number"
    [isTrim]="true"
    [errors]="errors.get('document_number') | async"
  ></gg-input>
  <gg-button
    type="submit"
    class="cashier__button-green"
    [disabled]="!form.valid || showLoading"
    [loading]="showLoading && isThisUserFormLast"
    [fullWidth]="true"
    (click)="saveForm()"
  >
    <sp-msg [spMsg]="buttonName"></sp-msg>
  </gg-button>
  <span class="trade-mark-info" *ngIf="showTradeMark()">
    ®Trade-mark of Interac Corp. Used under license
  </span>
</form>
