<div class="bonus-account" [class.bonus-account-detailed]="isExpanded$ | async">
  <span class="bonus-account-title" (click)="toggleExpand()">
    <sp-msg msg="BONUSES.BONUS_ACCOUNT_BALANCE"/>:&nbsp;
    <span class="bonus-account-balance">
      {{balance$ | async | priceNormalizer: (currency$ | async)}}
    </span>
  </span>
  <div class="bonus-account-description">
    <div class="bonus-account-description-p1" spMsg="BONUSES.BONUS_ACCOUNT_DESCRIPTION_P1"></div>
    <div class="bonus-account-description-p2" spMsg="BONUSES.BONUS_ACCOUNT_DESCRIPTION_P2"></div>
    <gg-button class="hide-bonus-account-description" [settings]="button" (click)="toggleExpand(false)">
      <sp-msg msg="COMMON.CLOSE"/>
    </gg-button>
  </div>
</div>
