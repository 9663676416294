// @ts-nocheck

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpNavigation } from '@libs/cdk';
import { CashierDataService, CashierQuery } from '@portal/cashier/data';
import { ConfigQuery } from '@portal/config';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { combineLatest, interval, Observable, Subscription, timer } from 'rxjs';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { CashierRequestStatus } from '../../types/enums/cashier-request-status.enum';
import { CashierSections } from '../../types/enums/sections.enum';
import { TransactionStatus } from '../../types/enums/transaction-status.enum';

@Component({
  templateUrl: './status.component.html',
  styleUrls: [ './status.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent implements OnInit, AfterViewInit, OnDestroy {
  private canUpdateStatus = true;
  private watchers: Subscription = new Subscription();
  private statusSubs: Subscription;

  readonly errorMessage$: Observable<string> = this.cashierQuery.errorMessage$;
  readonly TransactionStatus: typeof TransactionStatus = TransactionStatus;
  readonly supportEmail: string = this.source.modules.contacts.support;
  readonly sections = this.source.modules.cashier.buttonsOnStatusPage;
  readonly commonStatusButton: IButton = { size: ButtonSizes.Large };
  readonly reloadInfoButton: IButton = { size: ButtonSizes.Large };
  readonly showWithdrawalExtraInfo = this.activatedRoute.snapshot.queryParams.showWithdrawalExtraInfo;
  readonly showWithdrawalCactusInfo = this.activatedRoute.snapshot.queryParams.showWithdrawalCactusInfo;
  readonly linkToNewBankCardPDF: string = `${this.source.modules.cashier.newBankCardPDF}`;

  statusIcon = this.getStatusIcon(this.activatedRoute.snapshot.params.status, this.activatedRoute.snapshot.data.section);
  status = this.getStatus(this.activatedRoute.snapshot.params.status, this.activatedRoute.snapshot.data.section);
  bankCardMethod: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly navigationService: SpNavigation,
    private readonly cashierCommands: CashierDataService,
    private readonly cashierQuery: CashierQuery,
    private readonly source: ConfigQuery
  ) {}

  ngOnInit(): void {
    const queryParamsSub = this.activatedRoute.queryParams.subscribe((params) => this.bankCardMethod = params.bankCardMethodId);
    this.watchers.add(queryParamsSub);

    this.statusSubs = combineLatest([
      this.activatedRoute.params.pipe(pluck('status')),
      this.activatedRoute.data.pipe(pluck('section'))
    ]).pipe(
      tap(([ status, type ]) => {
        this.status = this.getStatus(status, type);
        this.statusIcon = this.getStatusIcon(status, type);

        if (this.status !== CashierRequestStatus.Progress) {
          this.cancelStream();
        }
      }),
      switchMap(() => interval(10000))
    ).subscribe(() => this.canUpdateStatus && this.getNewStatus());
  }

  ngAfterViewInit(): void {
    const urlExtras = this.activatedRoute.queryParams.pipe(
      filter((params) => !!params.PaRes),
      switchMap((params) => {
        return this.cashierCommands.depositUpdate(
          params.invoice,
          null,
          params.PaRes
        );
      })
    ).subscribe();
    this.watchers.add(urlExtras);
  }

  ngOnDestroy(): void {
    this.cashierCommands.resetErrorMessage();
    !!this.watchers && this.watchers.unsubscribe();
    !!this.statusSubs && this.statusSubs.unsubscribe();
  }

  back(): void {
    if (this.bankCardMethod) {
      this.navigationService.navigate([ 'cashier', 'deposit', 'bank-cards' ]);
      return;
    }
    const section = this.activatedRoute.snapshot.data.section;
    this.navigationService.navigate([ 'cashier', section ]);
  }

  updateStatus(): void {
    this.reloadInfoButton.loading = true;
    this.canUpdateStatus = false;
    this.getNewStatus();
    timer(10000).pipe(
      tap(() => {
        this.reloadInfoButton.loading = false;
        this.cd.detectChanges();
      })
    ).subscribe(() => {
      this.canUpdateStatus = true;
    });
  }

  private getStatus(status, type): string {
    switch (true) {
      case status === CashierRequestStatus.Going:
        return TransactionStatus.Going;
      case status === CashierRequestStatus.Progress:
        return TransactionStatus.Progress;
      case status === CashierRequestStatus.Error:
        return TransactionStatus.Error;
      case type === CashierSections.Withdrawal:
        return TransactionStatus.SuccessWithdraw;
      default:
        return TransactionStatus.SuccessDeposit;
    }
  }

  private getStatusIcon(status, type): string {
    switch (true) {
      case status === CashierRequestStatus.Progress:
        return `./assets/images/cashier/${this.source.modules.images.progressImage}`;
      case status === CashierRequestStatus.Error && !!this.bankCardMethod:
        return './assets/images/cashier/error-by-bank-card.svg';
      case status === CashierRequestStatus.Error:
        return './assets/images/cashier/error.svg';
      case status === CashierRequestStatus.Success && type === CashierSections.Deposit:
        return './assets/images/cashier/success.svg';
      default:
        return './assets/images/cashier/ok.svg';
    }
  }

  private getNewStatus(): void {
    const section = this.activatedRoute.snapshot.data.section;
    const invoice = this.activatedRoute.snapshot.queryParams.invoice;
    if (!!invoice) {
      this.cashierCommands.getTransactionStatus(section, invoice).subscribe();
    }
  }

  private cancelStream(): void {
    !!this.statusSubs && this.statusSubs.unsubscribe();
    this.cd.detectChanges();
  }
}
