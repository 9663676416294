export enum XpatePaymentMethods {
  BankCardDeposit = 96,
  NewBankCardDeposit = 152,
  NewBankCardWithdrawal = 153,
  NewBankCardDepositKzt = 174,
  NewBankCardDepositUntrusted = 195,
  NewBankCardWithdrawalKzt = 198,
  BankCardWithdrawalTransfer = 210,
  BankCardWithdrawalTransferKzt = 211,
  BankCardDepositInterac = 223,
  BankCardWithdrawalInterac = 224,
  BankCardWithdrawalFixed = 253,
  NewBankCardDepositEU = 254,
  BankCardWithdrawalTransferEU = 255,
  NewBankCardDepositUntrustedEU = 262,
  BankCardDepositH2hKzt = 373,
  BankCardDepositH2h = 374,
}
