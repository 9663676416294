<section class="field">
  <div class="{{ additionalClass }} search" [ngClass]="{collapsed: collapsed}" (click)="expand()">
    <div class="search__box">
      <img [src]="'{project}/images/input/input-search/search.svg' | path" *ngIf="icon" class="search__icon" alt=""/>
      <div class="search__title">{{'COMMON.SEARCH' | translate}}</div>
    </div>
    <input type="text" [placeholder]="placeholder" [disabled]="disabled" class="search__input field__input" [(ngModel)]="value" [ngClass]="{ error: errors && !!errors.length }" #input (blur)="collapse()"/>
    <img src="assets/images/icons/cross.svg" *ngIf="showClearButton" (click)="clear()" class="clear" alt=""/>
  </div>
  <gg-input-error [errors]="errors"></gg-input-error>
</section>
