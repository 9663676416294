import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { SocketComponent } from '@portal/banners';
import { AccountComponent, BonusesListModule, WelcomeContainerModule } from '@portal/bonuses';
import { ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { BonusesRouting } from './bonuses.routing';
import { CodeComponent } from './code/code.component';
import { EmptyComponent } from './empty/empty.component';
import { ListComponent } from './list/list.component';

@NgModule({
  imports: [ SpCDKModule, BonusesRouting, AccountComponent, SocketComponent, ControlsModule, WelcomeContainerModule, BonusesListModule, ThemeModule ],
  declarations: [ ListComponent, EmptyComponent, CodeComponent ]
})
export class BonusesModule {}
