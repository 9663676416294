// @ts-nocheck

import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { ErrorManager } from '@portal/shared/helpers';
import { DEFAULT_MESSENGER, MESSENGER_LIST } from './constans/messenger-list';

@Component({
  selector: 'pd-widget-cactus',
  templateUrl: './pd-widget-cactus.component.html',
  styleUrls: [ './pd-widget-cactus.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdWidgetCactusComponent implements AfterContentInit {
  readonly messengers = MESSENGER_LIST;
  readonly defaultMessenger: IInputSelectData = DEFAULT_MESSENGER;
  @Input() form: FormGroup;
  errors: ErrorManager;

  constructor(readonly errorManager: ErrorManager) {}

  ngAfterContentInit(): void {
    this.errors = this.errorManager.setUp({ form: this.form });
    this.form.get('messenger').patchValue(this.defaultMessenger.value);
  }
}
