import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { PWAService } from '@portal/banners/shared';
import { Observable } from 'rxjs';

@Injectable()
export class FrontConfigInterceptor implements HttpInterceptor {
  private readonly PWAService = inject(PWAService);
  private readonly platform = inject(SpPlatform);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<void>> {
    const checkURL = req.url.includes('/api/2/get_front_config');
    if (!checkURL || !this.PWAService.inPWA) { return next.handle(req); }

    const headers = req.headers.set('X-Request-Source', 'PWA').set('X-OS', this.platform.currentOS).set('X-Platform', this.platform.currentDeviceType);
    return next.handle(req.clone({ headers }));
  }
}
