// @ts-nocheck
import { ViewportScroller } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserPaymentDataForm } from '@portal/cashier/data';
import { ConfigQuery } from '@portal/config';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { FormsService } from '@portal/shared/components/controls/services';
import { ErrorManager } from '@portal/shared/helpers';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { XpatePaymentMethods } from '../../../../types/enums/payment-methods/xpate-payment-methods.enum';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'pd-widget-user-data',
  templateUrl: './pd-widget-user-data.component.html',
  styleUrls: [ './pd-widget-user-data.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdWidgetUserDataComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input() userForm: Partial<UserPaymentDataForm>;
  @Input() userCountryCode: Nullable<string>;
  @Input() userNationality: Nullable<string>;
  @Input() showLoading: boolean;
  @Input() paymentUserData: UserPaymentDataForm;
  @Input() isThisUserFormLast: boolean;
  @Input() buttonName: string;
  @Input() title: string;
  @Input() method: number;
  @Input() phoneMask: Nullable<string>;
  @Input() zipCodeMask: string;
  @Input() isCAD: boolean;
  @Input() originalCopiedMethodId: Nullable<number>;

  @Output() isUserFormSaved = new EventEmitter();
  @Output() userFormData = new EventEmitter<UserPaymentDataForm>();

  private formWithoutDate: Partial<UserPaymentDataForm>;
  private countrySubscription: Subscription;
  private phoneSubscription: Subscription;

  errors: ErrorManager;

  form: FormGroup;
  paymentMethodIdForSelector: number;

  constructor(
    readonly errorManager: ErrorManager,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly viewportScroller: ViewportScroller,
    private readonly formsService: FormsService,
    private readonly source: ConfigQuery
  ) {}

  ngAfterContentInit(): void {
    this.form = this.fb.group(this.userForm);
    this.errors = this.errorManager.setUp({ form: this.form });

    this.paymentMethodIdForSelector = this.originalCopiedMethodId ? this.originalCopiedMethodId : this.method;

    if (this.isCAD) {
      this.setAsyncPhoneValidator();
    }

    if (!!this.userForm.birth_date) {
      const date = this.paymentUserData.birth_date || '';
      this.form.get('birth_date').patchValue(date, { emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    const { birth_date, ...paymentDataWithoutDate } = this.paymentUserData;
    this.formWithoutDate = paymentDataWithoutDate;

    this.form.patchValue(this.formWithoutDate, { emitEvent: false });

    if (this.userForm.nationality) {
      this.form.get('nationality').patchValue(this.userNationality, { emitEvent: false });
    }

    if (this.userForm.country) {
      this.form.get('country').patchValue(this.userCountryCode, { emitEvent: false });
      this.getCountryStates();
      this.countrySubscription = this.form.get('country').valueChanges.subscribe((country) => {
        this.getCountryStates();
      });
    }

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    !!this.countrySubscription && this.countrySubscription.unsubscribe();
    !!this.phoneSubscription && this.phoneSubscription.unsubscribe();
  }

  saveForm(): void {
    if (!this.form.valid) { return null; }
    if (!this.isThisUserFormLast) { this.isUserFormSaved.emit(); }

    this.userFormData.emit(this.form.value);
    this.viewportScroller.scrollToPosition([ 0, 0 ]);
  }

  showTradeMark(): boolean {
    // TODO need remove project name
    return (this.method === XpatePaymentMethods.BankCardWithdrawalInterac) && (this.source.config.project === 'zaza');
  }

  private setAsyncPhoneValidator(): void {
    const phoneField = this.form.get('phone');
    this.phoneSubscription = phoneField.valueChanges.pipe(
      debounceTime(150),
      switchMap((val) => this.formsService.validatePhoneNumber(val))
    ).subscribe((response) => {
      if ('error' in response) {
        phoneField.setErrors({ phone: true });
        this.errors.setServerErrors({ phone: [ { code: response.error.code } ] });
      } else {
        phoneField.setErrors(null);
        this.errors.set({ phone: [] });
      }
    });
  }

  private getCountryStates(): void {
    if (!this.userForm.state) { return; }
  }
}
