<div class="authorization" [ggCreateThemeClass]="'authorization'">
  <gg-button
    class="authorization__sign-in"
    [settings]="buttonSettings('gray') | spAsync"
    [routerLink]="['/auth', 'login']"
  >
    <sp-msg msg="HEADER.SIGN_IN"/>
  </gg-button>
  @if (hasRegistration) {
    <gg-button
      class="authorization__sign-up"
      [settings]="buttonSettings() | spAsync"
      [routerLink]="['/auth', 'registration']"
    >
      <sp-msg msg="HEADER.SIGN_UP"/>
    </gg-button>
  }
</div>
