import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSizes, ButtonThemes } from './button.enums';
import { IButton } from './button.interfaces';

@Component({
  selector: 'gg-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ]
})
export class ButtonComponent {
  @Input() settings: IButton = {};
  @Input() type: 'button' | 'submit' = 'button';
  @Input() additionalClasses: string | null = null;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Output() buttonClicked: EventEmitter<MouseEvent> = new EventEmitter();

  get buttonClasses(): Array<string> {
    const classes: Array<string> = [ 'button', `button--${this.settings.size || ButtonSizes.Medium}`, `button--${this.settings.theme || ButtonThemes.Green}` ];
    if (this.settings.loading) { classes.push('button--loading'); }
    if (this.settings.disabled) { classes.push('button--disabled'); }
    if (this.settings.icon) { classes.push('button--icon'); }
    if (this.fullWidth || this.settings.fullWidth) { classes.push('button--full-width'); }
    if (this.additionalClasses) { classes.push(...this.additionalClasses.split(' ')); }
    return classes;
  }

  handleClick(event: MouseEvent): void {
    this.buttonClicked.emit(event);
  }

  isDisabled(): boolean {
    return this.settings.disabled || this.disabled || this.settings.loading || this.loading;
  }
}
