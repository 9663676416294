import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Nullable} from "@libs/utils";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {
    OperatorData,
    OperatorDetails,
    PaymentsOperatorsResponse
} from "@portal/shared/components/controls/interfaces/operators.interface";

@Injectable({ providedIn: 'root' })
export class OperatorsService {
    constructor(private readonly http: HttpClient) {}

    getOperatorList(paymentMethodId: Nullable<string>): Observable<Array<OperatorDetails>> {
        return this.http.get<PaymentsOperatorsResponse>(`/api/payments/operators/list/${paymentMethodId}`).pipe(
            take(1),
            map((response: PaymentsOperatorsResponse) => {
                const prefix = '/assets/images/shared/operators';
                return response.operators.map((operator: OperatorData) => ({
                    ...operator, src: `${prefix}/${operator.operator_id}.svg`
                }));
            })
        );
    }
}
