import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { FileViewer } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import {
  ALLOWED_FILE_SIZE,
  ALLOWED_FYLE_TYPES,
  BAD_FORMAT,
  DOCUMENTS_TYPE_FORM,
  DOCUMENTS_TYPES,
  DOCUMENTS_UPLOAD_FORM,
  DocumentUpload,
  LARGE_SIZE,
  UserDocument
} from '@portal/verification/shared';
import { DocumentVerificationUploadingState } from '@portal/verification/shared/enums';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'gg-verification-document-upload-form',
  templateUrl: './document-upload-form.component.html',
  styleUrls: [ './document-upload-form.component.scss' ],
  providers: [ DocumentUpload ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentUploadFormComponent implements OnInit, OnDestroy {
  private readonly fb = inject(FormBuilder);
  private readonly fileViewer = inject(FileViewer);
  private readonly documentUpload = inject(DocumentUpload);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly eventsSubscription: Subscription = new Subscription();
  private readonly notifications = inject(SpNotification);

  readonly documentUploadingState$: BehaviorSubject<DocumentVerificationUploadingState> = new BehaviorSubject(
    DocumentVerificationUploadingState.Upload as DocumentVerificationUploadingState
  );
  readonly selectedDocument$ = this.fileViewer.file$.pipe(
    tap((fileInfo) => {
      const type = fileInfo.type?.split('/')[ 1 ];
      const size = fileInfo.size;
      let error: Nullable<string> = null;

      if (type && !ALLOWED_FYLE_TYPES.includes(type)) { error = BAD_FORMAT; }
      if (size && size > ALLOWED_FILE_SIZE) { error = LARGE_SIZE; }

      if (error) {
        this.notifications.error(error);
        this.documentUploadingState$.next(DocumentVerificationUploadingState.Error);
      }
    })
  );
  readonly documents$: Observable<Nullable<Array<UserDocument>>>;
  readonly documentUploadForm: FormGroup = this.fb.group(DOCUMENTS_UPLOAD_FORM);
  readonly documentTypeForm: FormGroup = this.fb.group(DOCUMENTS_TYPE_FORM);
  readonly documentUploadingState: typeof DocumentVerificationUploadingState = DocumentVerificationUploadingState;
  readonly documentList: Array<IInputSelectData> = [];
  readonly documentsTypes = DOCUMENTS_TYPES;
  readonly editButton: IButton = { theme: ButtonThemes.Gray, size: ButtonSizes.Medium };
  readonly saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Medium };

  selectedDocumentType: string = '';

  constructor() {
    this.documents$ = this.userInfoQuery.documents$.pipe(
      map((documents) => documents?.map((document) => ({
        ...document,
        name: this.documentsTypes[ document.name as keyof typeof DOCUMENTS_TYPES ]
      })))
    );

    this.eventsSubscription.add(this.documentUpload.uploadDocumentSuccess$.subscribe((md5) => {
      this.documentUpload.saveDocument(md5, this.documentTypeForm.get('documentType')?.value);
    }));

    this.eventsSubscription.add(this.documentUpload.failureNotification$.subscribe(() =>
        this.documentUploadingState$.next(DocumentVerificationUploadingState.Error)
      )
    );

    this.eventsSubscription.add(this.documentUpload.successNotification$.subscribe(() =>
        this.documentUploadingState$.next(DocumentVerificationUploadingState.Upload)
      )
    );
  }

  ngOnInit(): void {
    Object.keys(DOCUMENTS_TYPES).map((document) => {
      const docTypeItem: IInputSelectData = {
        value: document,
        description: DOCUMENTS_TYPES[ document as keyof typeof DOCUMENTS_TYPES ]
      };
      this.documentList.push(docTypeItem);
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  fileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target?.files;

    if (files && files?.length > 0) {
      const file = files[ 0 ];
      this.fileViewer.readFileData(file);
      this.documentUploadingState$.next(DocumentVerificationUploadingState.TypeSelection);
      this.documentUploadForm.get('document')?.setValue(file);
    }
  }

  summary(): void {
    const docType = this.documentTypeForm.get('documentType')?.value.toUpperCase();
    this.documentUploadingState$.next(DocumentVerificationUploadingState.UploadConfirmation);
    this.selectedDocumentType = `PROFILE.DOCUMENTS_VERIFICATION.DOCUMENT_TYPES.${docType}`;
  }

  confirm(): void {
    this.documentUploadingState$.next(DocumentVerificationUploadingState.Progress);
    this.documentUpload.uploadDocument(this.documentUploadForm.get('document')?.value);
  }

  cancel(): void {
    this.documentUploadForm.get('document')?.setValue(null);
    this.documentUploadingState$.next(DocumentVerificationUploadingState.Upload);
  }
}
