import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { Country } from '@portal/countries';
import { ExchangeRatesDto } from '@portal/currencies/data';
import { CryptoCurrency, FiatCurrency } from '@portal/currencies/data/entities';

export type CurrenciesState = {
  fiat: Record<'all' | Country['name'], Nullable<Array<FiatCurrency>>>;
  crypto: Record<string, Nullable<Array<CryptoCurrency>>>;
  rates: Nullable<ExchangeRatesDto>;
};

const initial: SteroidConfig<CurrenciesState> = {
  options: { name: 'currencies' },
  initial: { fiat: {}, crypto: {}, rates: null },
};

@Injectable({ providedIn: 'root' })
export class CurrenciesRepository extends SteroidStore<CurrenciesState> {
  constructor() { super(initial); }
}
