<section class="field">
  <div class="{{ additionalClass }} placeholder__wrap zip-code">
    <input type="text"
           class="zip-code__input field__input"
           [disabled]="disabled"
           [(ngModel)]="value"
           [ngClass]="{ error: errors && !!errors.length, 'field__input--dirty': value, 'field__input--no-label': noLabelPlaceholder}"
           [placeholder]="defaultPlaceholder"
           [autocomplete]="autocomplete"
           [maxlength]="maxlength"
           [mask]="mask"
    />
    <span class="placeholder__text">{{placeholder}}</span>
  </div>
  <p *ngIf="showInfoText; else errorBlock" class="zip-code__text" [ngClass]="{ 'error': errors && !!errors.length}">
    <sp-msg msg="{{infoTextKey}}"></sp-msg>
  </p>
  <ng-template #errorBlock><gg-input-error [errors]="errors"/></ng-template>
</section>
