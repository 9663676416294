<div *ngIf="banner?.content as content" class="banner">
  <img
    class="banner__img"
    [src]="content | byLanguage : 'image' | fallback: ''"
    [alt]="content.imageAlt | byLanguage"
  />
  <div class="banner__texts">
    <span class="banner__label" [spMsg]="content.label | byLanguage"></span>
    <span class="banner__title" [spMsg]="content.title | byLanguage"></span>
  </div>
  <gg-button [settings]="goToLinkButton" [fullWidth]="true" (click)="goToUrl()" class="banner__button">
    <sp-msg [msg]="content | byLanguage : 'callToAction'"/>
  </gg-button>
</div>
