import { Injectable, OnDestroy } from '@angular/core';
import { Nullable } from '@libs/utils';
import { BehaviorSubject } from 'rxjs';

type FileType = Partial<{
  name: Nullable<string>;
  image: Nullable<string>;
  type: Nullable<string>;
  size: Nullable<number>;
}>

@Injectable({ providedIn: 'root' })
export class FileViewer implements OnDestroy {
  private readonly reader: FileReader = new FileReader();
  private readonly fileSubject: BehaviorSubject<FileType> = new BehaviorSubject({});
  private file: Nullable<File>;
  readonly file$ = this.fileSubject?.asObservable();

  constructor() {
    this.reader.addEventListener('load', this.loaded.bind(this));
  }

  ngOnDestroy(): void {
    this.reader.removeEventListener('load', this.loaded.bind(this));
  }

  readFileData(file: File): void {
    this.file = file;
    this.reader.readAsDataURL(file);
  }

  private loaded(): void {
    this.fileSubject.next({
      name: this.file?.name,
      image: this.reader.result as string,
      type: this.file?.type,
      size: this.file?.size
    });
  }
}
