// @ts-nocheck

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { PaymentMethodViewInterface } from '@portal/cashier/types/interfaces/payment-method-view.interface';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'pd-item',
  templateUrl: './item.component.html',
  styleUrls: [ './item.component.scss', './alternative.item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemComponent implements OnInit {
  @Input() paymentMethod: PaymentMethodViewInterface & { id: number; };
  @Input() section: CashierSections = null;

  isRecommended: boolean;
  isPopular: boolean;
  isInternational: boolean;
  currency$ = this.userInfoQuery.currency$;

  constructor(
    private readonly source: ConfigQuery,
    private readonly userInfoQuery: UserQuery,
  ) {}

  ngOnInit(): void {
    if (!!this.source.modules.cashier.recommendedPaymentMethod) {
      this.isRecommended = Number(this.source.modules.cashier.recommendedPaymentMethod.id) === Number(this.paymentMethod.id);
    }

    if (!!this.source.modules.cashier.popularPaymentMethod) {
      this.isPopular = this.source.modules.cashier.popularPaymentMethod.includes(Number(this.paymentMethod.id));
    }

    if (!!this.source.modules.cashier.internationalPaymentMethod) {
      this.isInternational = this.source.modules.cashier.internationalPaymentMethod.includes(Number(this.paymentMethod.id));
    }
  }

  isShowTitle(): boolean {
    return this.paymentMethod.name && this.paymentMethod.category !== 'cryptocurrency';
  }

  addIconClass(logo: string): string {
    if (logo === 'vs-ms-apple.svg' || logo === 'sbp.svg') {
      return 'item__icon_high';
    }
    return '';
  }
}
