import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { commonDirectives } from './directives';
import { commonPipes } from './pipes';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ commonDirectives, commonPipes ],
  exports: [ CommonModule, commonDirectives, commonPipes ],
  providers: [ commonPipes ]
})
export class SpCommonModule {}
