export class SpMemoryStorage implements Storage {
  private readonly inMemoryStorage = new Map<string, string>();

  get length(): number { return this.inMemoryStorage.size; }

  clear(): void { this.inMemoryStorage.clear(); }

  getItem(key: string): string | null { return this.inMemoryStorage.get(key) || null; }

  removeItem(key: string): void { this.inMemoryStorage.delete(key); }

  setItem(key: string, value: string): void { this.inMemoryStorage.set(key, value); }

  key(index: number): string | null {
    const keys = Object.keys(this.inMemoryStorage);
    return index >= 0 && keys.length < index ? keys[index] : null;
  }
}
