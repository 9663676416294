import {
  BonusCasinoReloadDto,
  mapActivation,
  mapExpiration,
  mapInformation,
  mapInStatus,
  mapWager
} from '@portal/bonuses/data';
import { AbstractBonus } from '@portal/bonuses/data/abstracts';
import { mapMaxWinType } from '@portal/bonuses/data/mappers/partials/max-win';
import {
  BonusActivation,
  BonusExpiration,
  BonusInformation,
  BonusInStatus,
  BonusStatus,
  BonusWager
} from '@portal/bonuses/shared';
import { BonusMaxWinDto } from '@portal/bonuses/shared/types/bonus-max-win';

export class BonusCasinoReload extends AbstractBonus<BonusCasinoReloadDto> {
  readonly label = 'casino';
  readonly rate: number = this.source.rate;

  readonly status: BonusInStatus = this.createStatusFromState(this.source);
  readonly activation: BonusActivation = mapActivation(this.source);
  readonly wager: BonusWager = mapWager(this.source);
  readonly expiration: BonusExpiration = mapExpiration(this.source);
  readonly information: BonusInformation = mapInformation(this.source);
  readonly maxWin: BonusMaxWinDto = mapMaxWinType(this.source);

  private createStatusFromState({ state, status }: BonusCasinoReloadDto): BonusInStatus {
    if (state === BonusStatus.New && status === BonusStatus.Canceled) {
      return mapInStatus(BonusStatus.Canceled);
    } else if (state === BonusStatus.New) {
      return mapInStatus(BonusStatus.Waiting);
    } else if (state === BonusStatus.Active && status === BonusStatus.Active) {
      return mapInStatus(status);
    }

    return mapInStatus(this.source.status);
  }
}
