import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import { Breadcrumb } from '@portal/layout/data';
import { BREADCRUMBS_MAP } from '@portal/layout/shared';

@Component({
  standalone: true,
  selector: 'gg-header-breadcrumbs-segment',
  templateUrl: './segment.component.html',
  styleUrls: [ './segment.component.scss' ],
  imports: [ SpCDKModule, RouterLink, ThemeModule ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentComponent implements OnChanges {
  private current: Nullable<string> = null;

  @Input() breadcrumb: Nullable<Breadcrumb>;
  @Input() withLink = false;

  ngOnChanges({ breadcrumb: { currentValue: breadcrumb } }: SimpleChanges): void {
    this.current = breadcrumb ? BREADCRUMBS_MAP[ breadcrumb.label ] : null;
  }

  get isSegmentVisible(): boolean {
    return !!this.breadcrumb?.link || !!BREADCRUMBS_MAP[ this.breadcrumb?.label as keyof Breadcrumb ];
  }

  get title(): string {
    return BREADCRUMBS_MAP[ this.breadcrumb?.label as keyof Breadcrumb ] || decodeURIComponent(this.breadcrumb?.label as keyof Breadcrumb);
  }

  get link(): Nullable<Array<string>> {
    return this.breadcrumb?.link;
  }
}
