import { PWATextLine } from '@portal/banners/shared';
import { ONE_DAY } from '@portal/shared/constants';

const IOS_SAFARI: Array<PWATextLine> = [
  { text: 'PWA.SHARE_IOS_SAFARI', icon: 'share.svg' },
  { text: 'PWA.ADD_IOS', icon: 'add.svg' },
  { text: 'PWA.APPROVE_ADD' }
];

const IOS_CHROME: Array<PWATextLine> = [
  { text: 'PWA.SHARE_IOS_CHROME', icon: 'share.svg' },
  { text: 'PWA.ADD_IOS', icon: 'add.svg' },
  { text: 'PWA.APPROVE_ADD' }
];

const IOS_FIREFOX: Array<PWATextLine> = [
  { text: 'PWA.FF_MENU', icon: 'menu.svg' },
  { text: 'PWA.SHARE_IOS_FF', icon: 'share-ff.svg' },
  { text: 'PWA.ADD_IOS', icon: 'add.svg' },
  { text: 'PWA.APPROVE_ADD' }
];

const DESKTOP_CHROME: Array<PWATextLine> = [
  { text: 'PWA.INSTALL_URL_ICON', icon: 'install.svg' },
  { text: 'PWA.PRESS_INSTALL' },
  { text: 'PWA.INSTALLED' }
];

const DESKTOP_SAFARI: Array<PWATextLine> = [
  { text: 'PWA.OPEN_CHROME' },
  ...DESKTOP_CHROME
];

const DESKTOP_EDGE: Array<PWATextLine> = [
  { text: 'PWA.INSTALL_URL_ICON', icon: 'install-edge.svg' },
  { text: 'PWA.PRESS_INSTALL' },
  { text: 'PWA.INSTALLED' }
];

export const PWADialogInfo: Record<string, Record<string, Array<PWATextLine>>> = {
  'desktop': {
    'chrome': DESKTOP_CHROME,
    'safari': DESKTOP_SAFARI,
    'ms-edge-chromium': DESKTOP_EDGE
  },
  'mobile': {
    'chrome': IOS_CHROME,
    'safari': IOS_SAFARI,
    'firefox': IOS_FIREFOX
  }
};

export const PWA_DIALOG_DELAY_DAYS = ONE_DAY * 1000 * 14;

export const PWA_APPLE_OS = [ 'ios', 'macos', 'mac' ];

export const PWA_AVAILABLE_COMBINATION = [ 'ios+safari', 'ios+firefox', 'ios+chrome', 'windows+chrome', 'windows+ms-edge-chromium', 'mac+chrome', 'mac+ms-edge-chromium', 'mac+safari' ];
