<section class="wallet-info" *ngIf="wallet else noWallet">
  <div class="wallet-info__wrapper">
    <h3 class="wallet-info__title"><sp-msg spMsg="CASHIER.CARD.MAKE_DEPOSIT"/></h3>
    <div class="wallet-info__currency">
      <img class="wallet-info__qrcode" [src]="'data:image/png;base64,' + wallet.qrcode" alt=""/>
      <img class="wallet-info__crypto-icon" [src]="logo" alt=""/>
      <div class="wallet-info__pay-block">
        <div class="wallet-info__currency-block">
          <p class="wallet-info__top-text">
            1 {{ wallet.cryptoCurrency }}
            = {{ wallet.exchangeRate }}{{ wallet.currency | currencySymbol }}
          </p>
          <p class="wallet-info__bottom-text">
            <sp-msg spMsg="CASHIER.CARD.MIN" spaces="end"/>
            {{ 'CASHIER.METHODS.DEPOSIT' | translate | lowercase }}
            : {{ wallet.minDep }} {{ wallet.cryptoCurrency }}
          </p>
        </div>
        <div class="wallet-info__currency-block">
          <p class="wallet-info__top-text">
            <span>{{ 'CASHIER.CARD.ADDRESS' |  translate | uppercase }}</span>
          </p>
          <div class="wallet-info__copy-block">
            <p class="wallet-info__bottom-text wallet-info__bottom-text--account">{{ wallet.account }}</p>
            <gg-clipboard-copy [showTextButton]="true" [copyValue]="wallet.account"/>
          </div>
        </div>
      </div>
    </div>
    <div class="wallet-info__text">
      <div class="wallet-info__banner">
        <img class="wallet-info__img" [src]="'{project}/images/icons/info-icon.svg' | path" alt="info-icon"/>
        <sp-msg msg="CASHIER.CARD.CRYPTO_INFO1" [params]="{currency:wallet.cryptoCurrency}"/>
      </div>
      <div class="wallet-info__second-banner"><sp-msg msg="CASHIER.CARD.CRYPTO_INFO2"/></div>
    </div>
  </div>
</section>
<ng-template #noWallet>
  <div class="wallet-info__no-data">
    <div class="wallet-info__no-data-wrapper" spMsg="CASHIER.CHOOSE_CRYPTO_CURRENCY"></div>
  </div>
</ng-template>

