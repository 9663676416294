// eslint-disable-next-line
// @ts-ignore
declare let window;

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

// eslint-disable-next-line
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loadLiveChat = (id, user_id): void => {
  window.__lc = window.__lc || {};
  window.__lc.license = id;
  window.__lc.user = user_id;

  (function (n, t, c): void {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-shadow
    function i(n): void {
      // eslint-disable-next-line
      // @ts-ignore
      return e._h ? e._h.apply(null, n) : e._q.push(n);
    }

    // eslint-disable-next-line
    let e = {
      _q: [],
      _h: null,
      _v: '2.0',
      // eslint-disable-next-line prefer-rest-params
      on: function (): void {i([ 'on', c.call(arguments) ]);},
      // eslint-disable-next-line prefer-rest-params
      once: function (): void {i([ 'once', c.call(arguments) ]);},
      // eslint-disable-next-line prefer-rest-params
      off: function (): void {i([ 'off', c.call(arguments) ]);},
      get: function (): void {
        if (!e._h) throw new Error('[LiveChatWidget] You can\'t use getters before load.');
        // eslint-disable-next-line
        return i([ 'get', c.call(arguments) ]);
      },
      // eslint-disable-next-line prefer-rest-params
      call: function (): void {i([ 'call', c.call(arguments) ]);},
      init: function (): void {
        // eslint-disable-next-line
        let n = t.createElement('script');
        n.async = !0;
        n.type = 'text/javascript';
        n.src = 'https://cdn.livechatinc.com/tracking.js';
        t.head.appendChild(n);
      }
    };

    !n.__lc.asyncInit && e.init();
    n.LiveChatWidget = n.LiveChatWidget || e;

    if (user_id) {
      n.LiveChatWidget.call('set_session_variables', {
        user_id: user_id
      });
    }

  }(window, document, [].slice));
};
