import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { DeepNullable, Nullable, UnixTimer, unixTimer$, watch } from '@libs/utils';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PostActionAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';
import { MomentModule } from 'ngx-moment';
import { filter, merge, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  templateUrl: './approved-dialog.component.html',
  styleUrl: './approved-dialog.component.scss',
  imports: [ SpCDKModule, ControlsModule, ClipboardCopyComponent, CurrenciesModule, MomentModule ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveDialogActionComponent extends PostActionAbstract implements OnInit, OnDestroy {
  private readonly dialog = inject(Dialog);
  private readonly watcher = watch();

  private ref: Nullable<DialogRef<boolean>> = null;

  readonly order = this.collectOrder();
  readonly timer$: Nullable<Observable<UnixTimer>> = this.order.till ? unixTimer$(this.order.till) : null;

  @ViewChild('tpl', { read: TemplateRef, static: true }) tpl: Nullable<TemplateRef<unknown>>;

  ngOnInit(): void {
    if (this.processErrors()) { return; }

    this.ref = this.tpl ? this.dialog.open(this.tpl, { panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ] }) : null;

    this.watcher.add(
      this.createDepositHandlers().subscribe(() => this.paramsData.setPayment({ section: 'deposit' })),
    );
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  checkTransfer(): void {
    this.watcher.add(
      this.paymentData.checkStatus(this.order.id).pipe(
        tap(() => this.close(false)),
      ).subscribe(({ status, url, initiatorId }) => {
        !this.processUrl(url) && this.setStatus({ status, invoice: initiatorId || undefined });
      }),
    );
  }

  close(showDeposit = true): void { this.ref?.close(showDeposit); }

  private createDepositHandlers(handlers: Array<Observable<boolean>> = []): Observable<boolean> {
    this.timer$ && handlers.push(this.timer$.pipe(map(({ expired }) => expired)));
    this.ref && handlers.push(this.ref.closed.pipe(map(Boolean)));

    return merge(...handlers).pipe(filter(Boolean));
  }

  private collectOrder(): DeepNullable<{ amount: number; card: string; till: number; id: string }> {
    const { amount } = this.builder.snapshot;
    const { target_card_number, order_id, valid_till } = this.result.data;
    return { amount, card: target_card_number, till: valid_till, id: order_id };
  }
}
