// @ts-nocheck

import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TRANSFER_FORM_DECLARATION } from '@portal/cashier/constants/transfer-form.declaration';
import { CashierDataService, CashierQuery } from '@portal/cashier/data';
import { ErrorManager } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import { Observable, Subscription } from 'rxjs';

@Component({
  templateUrl: './transfers-form.component.html',
  styleUrls: [ '../../transfers.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransfersFormComponent implements OnDestroy {
  private readonly watchers: Subscription = new Subscription();

  readonly form: FormGroup = this.fb.group(TRANSFER_FORM_DECLARATION);
  readonly errors: ErrorManager;
  readonly userCurrency$: Observable<string> = this.userInfoQuery.currency$;
  readonly loading$: Observable<boolean> = this.cashierQuery.loading$;

  constructor(
    private readonly fb: FormBuilder,
    private readonly errorManager: ErrorManager,
    private readonly userInfoQuery: UserQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly cashierQuery: CashierQuery
  ) {
    this.errors = this.errorManager.setUp({ form: this.form });
  }

  makeTransfer(): void {
    if (this.form.invalid) { return; }
    const nick = this.form.get('nick').value;
    const amount = this.form.get('amount').value;
    const cashierCommandsSub = this.cashierCommands.makeTransfer(nick, amount).subscribe(
      () => this.form.reset(),
      (error) => this.errorManager.setServerErrors(error)
    );
    this.watchers.add(cashierCommandsSub);
  }

  ngOnDestroy(): void {
    this.watchers.unsubscribe();
  }
}
