import { ByLanguage } from '@libs/cdk';
import { CommonPromotionDescription } from '@portal/promotions/data/dto';
import { PromotionBanner } from '@portal/promotions/data/core/partials';

export class CommonPromotion {
  readonly type = 'common';

  constructor(
    readonly i18n: ByLanguage<CommonPromotionDescription>,
    readonly slug: string,
    readonly id: string,
    readonly banner: PromotionBanner,
    readonly isDepositButtonVisible: boolean
  ) {}
}
