import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CryptoSelectorComponent, CurrencyByCountrySelectorComponent, CurrencyNormalizerPipe, CurrencyRoundPipe, CurrencySymbolPipe, ExchangeRatesComponent, PriceNormalizerPipe, RateNormalizerPipe } from '@portal/currencies/application';
import { GetDefaultCurrency, SelectCryptoCurrencies, SelectExchangeRates, SelectFiatCurrencies } from '@portal/currencies/commands';
import { ControlsModule } from '@portal/shared/components';

const components = [ CryptoSelectorComponent, CurrencyByCountrySelectorComponent, ExchangeRatesComponent ];
const pipes = [ CurrencyNormalizerPipe, CurrencyRoundPipe, CurrencySymbolPipe, PriceNormalizerPipe, RateNormalizerPipe ];
const commands = [ GetDefaultCurrency, SelectExchangeRates, SelectCryptoCurrencies, SelectFiatCurrencies ];

@NgModule({
  imports: [ SpCDKModule, ControlsModule ],
  declarations: [ components, pipes ],
  exports: [ components, pipes ],
  providers: [ commands, pipes ],
})

export class CurrenciesModule {}
