import { FormControl, FormGroup } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';

export const createEmailRecoveryForm = (): FormGroup<{
  email: FormControl<string>;
  recaptchaToken: FormControl<string>;
}> => new FormGroup({
  email: new FormControl<string>('', { nonNullable: true, validators: validationRules.email }),
  recaptchaToken: new FormControl<string>('', { nonNullable: true })
});

export const createEmailNewPasswordForm = (): FormGroup<{
  password_new: FormControl<string>;
  password_confirm: FormControl<string>;
}> => new FormGroup({
  password_new: new FormControl('', { nonNullable: true, validators: validationRules.newPassword }),
  password_confirm: new FormControl('', { nonNullable: true, validators: validationRules.newPassword })
}, { validators: validationRules.matchFields('password', 'passwordRepeat') });
