import { Validators } from '@angular/forms';

export const USER_DATA_INFO_NAME_PART = {
  first_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ],
  last_name: [
    '',
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(64)
    ]
  ]
};
