<section class="delete-card-dialog">
  <img
    src="assets/images/icons/cross2.svg"
    class="delete-card-dialog__close-icon"
    alt="close-icon"
    (click)="close()"/>
  <h5 class="delete-card-dialog__title" spMsg="CASHIER.CARD.DELETE_CARD"></h5>
  <span class="delete-card-dialog__card-number">
    {{ cardNumber?.mask }}
  </span>
  <div class="delete-card-dialog__buttons">
    <gg-button
      class="delete-card-dialog__button"
      [settings]="cancelButton"
      (buttonClicked)="close()"
    >
      <sp-msg spMsg="COMMON.NO"></sp-msg>
    </gg-button>
    <gg-button
      class="delete-card-dialog__button"
      [settings]="submitButton"
      [loading]="loading$ | async"
      [disabled]="loading$ | async"
      (buttonClicked)="deleteBankCard()"
    >
      <sp-msg spMsg="COMMON.YES"></sp-msg>
    </gg-button>
  </div>
</section>
