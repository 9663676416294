import { MobileOperatorDto } from '@portal/cashier/data';

export class MobileOperator {
  constructor(
    readonly name: string,
    readonly code: string
  ) {}

  static create(operators: MobileOperatorDto): MobileOperator {
    return new MobileOperator(operators.name, operators.code);
  }
}
