import { AuthMethodDetails, AuthMethods } from '@portal/user/shared';

export const AUTH_METHODS_DETAILS: Map<AuthMethods, AuthMethodDetails> = new Map<AuthMethods, AuthMethodDetails>([
  [
    AuthMethods.Google,
    {
      appBlockTitle: 'PROFILE.TWO_FACTOR_AUTH.GOOGLE_APP',
      appTitle: 'PROFILE.TWO_FACTOR_AUTH.GOOGLE_AUTH',
      appDescription: 'PROFILE.TWO_FACTOR_AUTH.GOOGLE_AUTH_DESCRIPTION',
      appIOsUrl: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
      appAndroidUrl: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
      appExampleImg: '/assets/images/profile/2fa/phone.svg',
      qrDescription: 'PROFILE.TWO_FACTOR_AUTH.STEP_1_DESCRIPTION',
      value: AuthMethods.Google
    }
  ],
  [
    AuthMethods.Yandex,
    {
      appBlockTitle: 'PROFILE.TWO_FACTOR_AUTH.YANDEX_APP',
      appTitle: 'PROFILE.TWO_FACTOR_AUTH.YNDX',
      appDescription: 'PROFILE.TWO_FACTOR_AUTH.YANDEX_AUTH_DESCRIPTION',
      appIOsUrl: 'https://apps.apple.com/ru/app/id957324816?l=ru',
      appAndroidUrl: 'https://play.google.com/store/apps/details?id=ru.yandex.key',
      appExampleImg: '/assets/images/profile/2fa/phone-ya.svg',
      qrDescription: 'PROFILE.TWO_FACTOR_AUTH.STEP_1_DESCRIPTION_YANDEX',
      value: AuthMethods.Yandex
    }
  ]
]);
