<section
  class="verification-card"
  *ngIf="documentUploadingState$ | async as state"
  [ngClass]="state"
>
  <ng-container *ngIf="state === documentUploadingState.Upload">
    <form
      [formGroup]="cardUploadForm"
      class="verification-card__upload-documents"
    >
      <gg-verification-upload-card-info/>
      <span class="verification-card__file-formats">
        <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.FORMATS"/>
      </span>
      <gg-button class="verification-card__upload-button" [fullWidth]="true" (buttonClicked)="filePicker.click()">
        <sp-msg msg="PROFILE.CARD_VERIFICATION.UPLOAD_BUTTON"/>
      </gg-button>
      <input
        #filePicker
        class="verification-card__input-file"
        type="file"
        id="downloadFile"
        accept=".jpg, .jpeg, .png"
        (change)="fileSelected($event)"
      />
    </form>
    <gg-verification-uploaded-docs *ngIf="documents$ | async as docs" [documents]="docs"/>
  </ng-container>
  <ng-container *ngIf="selectedDocument$ | async as document">
    <ng-container *ngIf="state === documentUploadingState.TypeSelection">
      <div class="verification-card__loading-text">
        <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.UPLOADING_END"/>
      </div>
      <img class="verification-card__file-preview" [src]="document.image" alt="document-image"/>
      <div class="verification-card__file-name">{{document.name}}</div>
      <div class="verification-card__preview-buttons">
        <gg-button
          class="verification-card__preview-cancel-button"
          [settings]="editButton"
          (buttonClicked)="cancel()"
        >
          <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.CANCEL"/>
        </gg-button>
        <gg-button
          [settings]="saveButton"
          (buttonClicked)="confirm()"
        >
          <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.OK"/>
        </gg-button>
      </div>
    </ng-container>
  </ng-container>
  <gg-verification-progress-bar *ngIf="state === documentUploadingState.Progress"/>
  <gg-verification-document-error *ngIf="state === documentUploadingState.Error" (cancel)="cancel()"/>
</section>
