import { Banner, ReceivedBannerDto } from '@portal/banners/data';

const getVisibility = (banner: ReceivedBannerDto): Banner['visibility']['showTo'] => {
  const { showToAuthorized, showToAnonymous } = banner;

  if (showToAuthorized && !showToAnonymous) { return 'authorized'; }
  if (!showToAuthorized && showToAnonymous) { return 'anonymous'; }
  if (!showToAuthorized && !showToAnonymous) { return 'hidden'; }
  return 'both';
};

export const createBannerFromReceived = (banner: ReceivedBannerDto): Banner => {
  const { _id: id } = banner;

  const { type, url, additionalUrl, dateExp: expirationDate } = banner;
  const settings = { type, url, additionalUrl, expirationDate };

  const { page, section, order } = banner.template;
  const showTo = getVisibility(banner);
  const visibility = { page, section, order: Number(order), showTo };

  const {
    title, label, description, image, mobileImage, callToAction,
    additionalAction, imageAlt
  } = banner;
  const contents = { title, label, description, image, mobileImage, callToAction, additionalAction, imageAlt };

  return new Banner(id, settings, visibility, contents);
};
