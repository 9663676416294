import { AuthMethod, AuthMethods } from '@portal/user/shared';

export const AUTH_METHODS: Array<AuthMethod> = [
  {
    title: 'PROFILE.TWO_FACTOR_AUTH.GOOGLE_AUTH',
    icon: 'assets/images/profile/2fa/google-auth.svg',
    value: AuthMethods.Google
  },
  {
    title: 'PROFILE.TWO_FACTOR_AUTH.YNDX',
    icon: 'assets/images/profile/2fa/ya-auth.svg',
    value: AuthMethods.Yandex
  }
];
