<section class="upload-doc-info">
  <span class="upload-doc-info__upload-documents-title">
    <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.SELECT_OR_DRAG_FILE"/>
  </span>
  <div class="upload-doc-info__upload-documents-container">
    <img
      class="upload-doc-info__upload-documents-icon"
      [src]="'{project}/images/verification/document-photo.svg' | path"
      alt="doc-icon"
    >
    <span class="upload-doc-info__text">
      <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.DOCUMENTS_TYPES_PASSPORT"/>
    </span>
  </div>
  <div class="upload-doc-info__upload-documents-container">
    <img
      class="upload-doc-info__upload-documents-icon"
      [src]="'{project}/images/verification/document-card.svg' | path"
      alt="card-icon"
    >
    <div class="upload-doc-info__upload-documents-text">
      <span class="upload-doc-info__text upload-doc-info__text_no-margin">
        <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.DOCUMENTS_TYPES_CARD" spaces="end"/>
      </span>
      <span class="upload-doc-info__text upload-doc-info__text_no-bold upload-doc-info__text_no-margin">
        <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.EXAMPLE_BANK_CARD"/>
      </span>
    </div>
  </div>
  <span class="upload-doc-info__formats">
    <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.FORMATS"/>
  </span>
  <span class="upload-doc-info__button">
    <sp-msg msg="PROFILE.DOCUMENTS_VERIFICATION.ADD_PHOTO"/>
  </span>
</section>
