import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

type CardState = 'normal' | 'flipped' | 'cancelling';

@Component({
  selector: 'gg-bonus-card',
  templateUrl: './card.component.html',
  styleUrls: [ './purple.card.component.scss', './silver.card.component.scss', './card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  private readonly language = inject(SpLocalization).currentLanguage;
  private readonly cardState: BehaviorSubject<CardState> = new BehaviorSubject<CardState>('normal');

  readonly bonusesEnabled$ = inject(UserQuery).restrictedPages$
    .pipe(map(restrictedPage => restrictedPage?.bonusesEnabled));
  readonly cardState$ = this.cardState.asObservable();

  @Input() bonus: Nullable<Bonus> = null;

  get hasBonusDescription(): boolean {
    return !!this.bonus && 'description' in this.bonus.information
           && !!this.bonus.information.description
           && !!this.bonus.information.description[this.language];
  }

  get isWagerActive(): boolean {
    return this.bonus?.status.is.inProgress  || (this.bonus?.status.is.success && this.bonus.type !== 'sportsbook') || (this.bonus?.status.is.failed && this.bonus.type !== 'sportsbook') || false;
  }

  toggleCardDescription(): void {
    this.cardState.next(this.cardState.getValue() === 'normal' ? 'flipped' : 'normal');
  }

  toggleCardCancellation(): void {
    this.cardState.next(this.cardState.getValue() === 'normal' ? 'cancelling' : 'normal');
  }
}
