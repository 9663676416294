import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  templateUrl: './verification-prompt.component.html',
  styleUrls: [ './verification-prompt.component.scss' ],
  imports: [ ControlsModule, SpCDKModule, RouterModule ]
})
export class VerificationPromptComponent {
  readonly theme = this.source.project.name || '';

  constructor(private readonly source: ConfigQuery) {}
}
