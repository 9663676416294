<section class="no-bonuses">
  <gg-banner-socket page="all" section="notification" class="banner-notification"></gg-banner-socket>
  <div class="no-bonuses__wrapper">
    <div class="no-bonuses__title" spMsg="BONUSES.NO_BONUSES_TITLE"></div>
    <p class="no-bonuses__text" spMsg="BONUSES.NO_BONUSES_TEXT"></p>
    <gg-button class="no-bonuses__button" [settings]="button" [routerLink]="['/bonuses', 'promo']">
      <sp-msg msg="BONUSES.USE_PROMO_CODE"/>
    </gg-button>
  </div>
</section>
