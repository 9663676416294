import { Bonus } from '@portal/bonuses/data';
import { DEFAULT_SWIPER_CONFIG } from '@portal/shared/components';
import { SwiperOptions } from 'swiper/types';

export interface BonusListConfig {
  title: 'BONUSES.AVAILABLE' | 'BONUSES.ACTIVATED';
  slider: SwiperOptions;
  bonuses?: Array<Bonus>;
}

export const enum BonusListFilter {
  Available = 'available',
  Activated = 'activated'
}

export const AVAILABLE_BONUSES_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  observer: true,
  navigation: { prevEl: '.available-prev-btn', nextEl: '.available-next-btn' }
};

export const ACTIVATED_BONUSES_SWIPER_CONFIG: SwiperOptions = {
  ...DEFAULT_SWIPER_CONFIG,
  observer: true,
  navigation: { prevEl: '.activated-prev-btn', nextEl: '.activated-next-btn' }
};

export const BONUS_LIST_CONFIG: Record<BonusListFilter, BonusListConfig> = {
  [BonusListFilter.Available]: { title: 'BONUSES.AVAILABLE', slider: AVAILABLE_BONUSES_SWIPER_CONFIG },
  [BonusListFilter.Activated]: { title: 'BONUSES.ACTIVATED', slider: ACTIVATED_BONUSES_SWIPER_CONFIG }
};
