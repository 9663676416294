import { PromotionBanner } from '@portal/promotions/data/core/partials';
import { CommonPromotionDto, PromotionDto, WelcomePromotionDto } from '../dto';
import { CommonPromotion, Promotion, WelcomePromotion } from '../entities';

function createPromotionBanner(banner: PromotionBanner): PromotionBanner {
  return new PromotionBanner(
    banner.isBannerEnabled,
    banner.bannerType,
    banner.bannerOrder,
    banner.bannerButtonUrl,
    banner.bannerBackgroundMobile,
    banner.bannerBackground
  );
}

function createWelcomePromotion(dto: WelcomePromotionDto): WelcomePromotion {
  const i18n = Object.entries(dto.i18n).reduce((acc, [ lang, props ]) => {
    return { ...acc, [ lang ]: { ...props, steps: Object.values(props.steps) } };
  }, {});

  const slash = dto.bannerButtonUrl.startsWith('/') ? '' : '/';
  const banner: PromotionBanner = {
    isBannerEnabled: dto.isBannerEnabled,
    bannerType: dto.bannerType,
    bannerOrder: dto.bannerOrder,
    bannerButtonUrl: `${slash}${dto.bannerButtonUrl}`,
    bannerBackgroundMobile: dto.bannerBackgroundMobile,
    bannerBackground: dto.bannerBackground
  };

  return new WelcomePromotion(
    i18n, dto.slug, dto._id, createPromotionBanner(banner)
  );
}

function createCommonPromotion(dto: CommonPromotionDto): CommonPromotion {

  const slash = dto.bannerButtonUrl.startsWith('/') ? '' : '/';
  const banner: PromotionBanner = {
    isBannerEnabled: dto.isBannerEnabled,
    bannerType: dto.bannerType,
    bannerOrder: dto.bannerOrder,
    bannerButtonUrl: `${slash}${dto.bannerButtonUrl}`,
    bannerBackgroundMobile: dto.bannerBackgroundMobile,
    bannerBackground: dto.bannerBackground
  };

  return new CommonPromotion(dto.i18n, dto.slug, dto._id, createPromotionBanner(banner), dto.isDepositButtonVisible);
}

export function createPromotion(promotions: Array<PromotionDto>): Array<Promotion> {
  const sortedPromos = promotions.sort((a, b) => a.bannerOrder - b.bannerOrder);
  return sortedPromos.map((promotion) => {
    return promotion.type === 'common'
      ? createCommonPromotion(promotion)
      : createWelcomePromotion(promotion);
  });
}
