import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { IsRecaptchaEnabledDirective } from './is-recaptcha-enabled.directive';

@NgModule({
  imports: [ SpCDKModule, RecaptchaModule, RecaptchaFormsModule ],
  declarations: [ IsRecaptchaEnabledDirective ],
  exports: [ RecaptchaModule, RecaptchaFormsModule, IsRecaptchaEnabledDirective ]
})
export class RecaptchaEnabledModule {}
