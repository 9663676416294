import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@libs/utils';
import { currencySymbols } from '@portal/currencies/shared';

@Pipe({ name: 'currencySymbol' })
export class CurrencySymbolPipe implements PipeTransform {
  transform(currencyKey: Nullable<string>): string {
    return currencyKey ? currencySymbols[ currencyKey ] || currencyKey : '';
  }
}
