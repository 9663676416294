import { Component, Input, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { SpCDKModule, SpNavigation } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';
import { VERIFICATION_TABS } from '@portal/verification/shared';
import { Dialog } from '@angular/cdk/dialog';
import { LOCATION } from '@ng-web-apis/common';
import { InfoPopupComponent } from '@portal/verification/components';
import { Tab } from '@portal/shared/components/controls/buttons/button-group/button-group.component';
import { VerificationSectionType } from '@portal/verification/shared/enums';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-verification-header',
  templateUrl: './verification-header.component.html',
  styleUrls: [ './verification-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationHeaderComponent implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly navigation = inject(SpNavigation);
  private readonly location = inject(LOCATION);

  readonly tabs: Array<Tab> = [];

  @Input() activeTab: string = '';
  @Input() isKycEnabled = true;

  ngOnInit(): void {
    this.tabs.push(VERIFICATION_TABS[VerificationSectionType.KYC] as Tab);
    this.tabs.push(VERIFICATION_TABS[VerificationSectionType.Basic] as Tab);
  }

  changeSection(section: string): void {
    const cpa = Object.entries(new URLSearchParams(this.location.search))
      .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), {});

    this.navigation.navigate([ 'user', 'verification', ...section.split('/') ], cpa);
  }

  nav(section: string): void { this.navigation.navigate([ 'user', 'verification', section ]); }

  openPopup(): void {
    this.dialog.open(InfoPopupComponent, { panelClass: 'bottom-center' });
  }
}
