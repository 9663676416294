import { Observable, Subscription } from 'rxjs';
import { Nullable, OrArray } from '../types';

type Watcher = Nullable<Subscription | Observable<unknown>>

export const watch = (): { add(watchers: Nullable<OrArray<Watcher>>): void; unsubscribe(): void } => {
  const watcher = new Subscription();
  const add = (watchers: OrArray<Watcher>): void =>
    Array.isArray(watchers)
      ? watchers.forEach((w) => w && w instanceof Subscription ? watcher.add(w) : watcher.add(w?.subscribe()))
      : watchers && watchers instanceof Subscription ? watcher.add(watchers) : watcher.add(watchers?.subscribe());

  const unsubscribe = (): void => watcher.unsubscribe();

  return { add, unsubscribe };
};
