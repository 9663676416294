import { HttpEvent, HttpEventType } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { SpNotification } from '@libs/cdk';
import { VerificationData } from '@portal/verification/data';
import {
  BAD_FORMAT,
  DOCUMENT_IS_NOT_VALID,
  FILE_EXIST,
  FILE_NOT_SAVED,
  LARGE_SIZE
} from '@portal/verification/shared/constants';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DocumentUpload implements OnDestroy {
  private readonly verificationCommands = inject(VerificationData);
  private readonly notifications = inject(SpNotification);

  private readonly uploadDocumentProcess: Subject<number> = new Subject<number>();
  private readonly watchers: Subscription = new Subscription();

  readonly uploadDocumentProcess$ = this.uploadDocumentProcess.asObservable();
  readonly uploadDocumentFailure$: Subject<string> = new Subject<string>();
  readonly uploadDocumentSuccess$: Subject<string> = new Subject<string>();
  readonly savingDocumentSuccess$: Subject<void> = new Subject<void>();

  successNotification$: Observable<void>;
  failureNotification$: Observable<string>;

  constructor() {
    this.successNotification$ = this.savingDocumentSuccess$.pipe(
      tap(() => this.notifications.success('NOTIFICATIONS.MESSAGES.FILES_SAVED'))
    );

    this.failureNotification$ = this.uploadDocumentFailure$.pipe(
      tap((error) => this.notifications.error(error))
    );
  }

  uploadDocument(file: File): void {
    const verificationCommandsSubscription: Subscription = this.verificationCommands.uploadDocument(file).pipe(
      switchMap((event: HttpEvent<any>) => event.type === HttpEventType.Response && !!event.body.errors?.length
        ? throwError(() => event.body.errors[ 0 ])
        : of(event))
    ).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
          this.uploadDocumentProcess.next(progress);
        } else if (event.type === HttpEventType.Response) {
          this.uploadDocumentSuccess$.next(event.body.document_md5);
        }
      },
      error: (err) => {
        const errorMessage = err.error.errors;
        if (errorMessage.includes('Such file has been already uploaded')) { this.uploadDocumentFailure$.next(FILE_EXIST); }
        if (errorMessage.includes('Unknown image format') || err.status === 415) { this.uploadDocumentFailure$.next(BAD_FORMAT); }
        if (errorMessage.includes('File size exceeds')) { this.uploadDocumentFailure$.next(LARGE_SIZE); }
        if (errorMessage.includes('Document is not valid')) { this.uploadDocumentFailure$.next(DOCUMENT_IS_NOT_VALID); }
      }
    });

    this.watchers.add(verificationCommandsSubscription);
  }

  saveDocument(md5: string, name: string): void {
    const verificationCommandsSubscription: Subscription = this.verificationCommands.saveDocument(md5, name).subscribe({
      next: () => this.savingDocumentSuccess$.next(),
      error: () => this.uploadDocumentFailure$.next(FILE_NOT_SAVED)
    });

    this.watchers.add(verificationCommandsSubscription);
  }

  ngOnDestroy(): void {
    !!this.watchers && this.watchers.unsubscribe();
  }
}
