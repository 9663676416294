import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { SeoState, SeoStore } from '@portal/seo/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SeoQuery extends SteroidQuery<SeoState> {
  readonly synchronized$: Observable<boolean> = this.select('sync');

  readonly active$ = this.entity('texts').selectActive();

  constructor(store: SeoStore) { super(store); }
}
