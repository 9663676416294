import { PromotionsBannerType } from '@portal/promotions/shared';

export class PromotionBanner {
  constructor(
    readonly isBannerEnabled: boolean,
    readonly bannerType: PromotionsBannerType,
    readonly bannerOrder: number,
    readonly bannerButtonUrl: string,
    readonly bannerBackgroundMobile: string,
    readonly bannerBackground: string
  ) {}
}
