<section *ngIf="kycVerificationMethods.length" class="identity-verification">
  <div class="identity-verification__title">
    <sp-msg msg="PROFILE.KYCAID.VERIFICATION_TYPE"/>
  </div>
  <div
    class="identity-verification__method-cards"
    [class.kycaid-verification__method_one]="kycVerificationMethods.length === 1"
  >
    <div
      *ngFor="let kycVerificationMethod of kycVerificationMethods; index as i"
      class="identity-verification__method"
      (click)="selectMethod(kycVerificationMethod.method, i)"
    >
      <div *ngIf="(loading$ | async) && i === methodIndex" class="identity-verification__preloader">
        <img
          class="identity-verification__preloader-img"
          src="assets/images/loader/preloader.png"
          alt="preloader"
        />
      </div>
      <div *ngIf="i !== methodIndex || ((loading$ | async) !== true)" class="identity-verification__method-content">
        <img [src]="kycVerificationMethod.logo" alt="type-img">
        <p *ngIf="kycVerificationMethod.title" class="identity-verification__method-title">
          <sp-msg [msg]="kycVerificationMethod.title"/>
        </p>
      </div>
    </div>
  </div>
</section>
