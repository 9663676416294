import { GlobalWindow } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { LoaderAnalyticsHandler } from './abstract.handler';

type HJWindow = { hj: any; _hjSettings: any };

export class HjHandler extends LoaderAnalyticsHandler {
  private readonly userId = this.injector.get(UserQuery).id;
  private readonly id = this.injector.get(ConfigQuery).modules.analytics.trackers.hjId;
  private readonly window = this.injector.get<GlobalWindow<HJWindow>>(WINDOW);

  override create(): void {
    // @ts-expect-error third-party pixel lib lives its own life;
    this.window.hj = this.window.hj || function (...args: any): void { (this.window.hj.q = this.window.hj.q || []).push(args); };
    this.window._hjSettings = { hjid: this.id, hjsv: 6 };

    this.loadScript(`https://static.hotjar.com/c/hotjar-${this.id}.js?sv=6`, () => {
      this.userId && this.window.hj('identify', this.userId);
    });

  }

  override onNavigationEnds(): void { !this.window.hj && this.create(); }
}
