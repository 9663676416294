<section class="cashier">
  <div class="wrapper">
    <div class="header">
      <div class="header__left">
        <gg-button-back (click)="back()"></gg-button-back>
      </div>
      <div class="header__middle">
        <img alt="status-icon" src="{{ statusIcon }}">
      </div>
    </div>
    <ng-container *ngIf="status === TransactionStatus.SuccessDeposit">
      <div class="info info--deposit">
        <span class="info__text info__text--success" spMsg="CASHIER.STATUS.MONEY_ADDED" spaces="end"></span>
        <span class="info__text info__text--success" spMsg="CASHIER.STATUS.GOOD_GAME"></span>
      </div>
      <div class="info__buttons">
        <div *ngIf="sections === 'sections'" class="info__buttons">
          <a class="info__button--link info__button--purple" routerLink="/">
            <img src="./assets/images/cashier/cherry.svg"/>
            <span spMsg="COMMON.SECTIONS.GAMES"></span>
          </a>
          <a class="info__button--link info__button--yellow" routerLink="/sports">
            <img src="./assets/images/cashier/ball.svg"/>
            <span spMsg="COMMON.SECTIONS.SPORTS"></span>
          </a>
          <a class="info__button--link info__button--green" routerLink="/poker">
            <img src="./assets/images/cashier/spades.svg"/>
            <span spMsg="COMMON.SECTIONS.POKER"></span>
          </a>
        </div>
        <div *ngIf="sections === 'play'">
          <gg-button [settings]="commonStatusButton" class="play__button" routerLink="/">
            <sp-msg spMsg="GAMES.PLAY"></sp-msg>
          </gg-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="status === TransactionStatus.SuccessWithdraw">
      <ng-container *ngIf="showWithdrawalExtraInfo || showWithdrawalCactusInfo; else simpleWithdrawal">
        <div *ngIf="showWithdrawalExtraInfo === true" class="info info--withdraw">
          <span class="info__text info__text--success--withdraw">
            <sp-msg spMsg="CASHIER.STATUS.REQUEST_ACCEPTED" spaces="end"></sp-msg>
          </span>
          <span class="info__text--no-bold info__text--wide">
            <span class="info__text--important" spMsg="CASHIER.STATUS.IMPORTANT"></span>
            <sp-msg spMsg="CASHIER.STATUS.WITHDRAWAL_SPLIT_INFO" spaces="start"></sp-msg>
          </span>
          <span class="info__text--no-bold info__text--wide">
            <sp-msg spMsg="CASHIER.STATUS.TRACK_IN_HISTORY"></sp-msg>
          </span>
        </div>

        <div *ngIf="showWithdrawalCactusInfo">
          <div class="info info--withdraw">
            <span class="info__text info__text--success--withdraw" spMsg="CASHIER.CACTUS.TITLE" spaces="end"></span>
            <span class="info__text--no-bold">
              <sp-msg spMsg="CASHIER.CACTUS.SUBTITLE" spaces="end"></sp-msg>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #simpleWithdrawal>
        <div class="info info--withdraw">
          <span class="info__text info__text--success--withdraw" spMsg="CASHIER.STATUS.REQUEST_ACCEPTED" spaces="end"></span>
          <span class="info__text--no-bold">
            <sp-msg spMsg="CASHIER.STATUS.REQUEST_ACCEPTED_AND_PROCESSED" spaces="end"></sp-msg>
            <span><sp-msg spMsg="CASHIER.STATUS.REQUEST_24"></sp-msg></span>
          </span>
        </div>
      </ng-template>

      <gg-button [routerLink]="['/cashier', 'history', 'withdrawal']" [settings]="commonStatusButton"
                 class="play__button">
        <sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.GO_TO_HISTORY"></sp-msg>
      </gg-button>
    </ng-container>

    <ng-container *ngIf="status === TransactionStatus.Error">
      <ng-container *ngIf="bankCardMethod; else regularError">
        <span class="info__text--no-bold" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_TITLE" spaces="end"></span>
        <span class="info__text--no-bold info__middle-text" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_QUESTION" spaces="end"></span>
        <a [href]="linkToNewBankCardPDF" class="info__status_support--link" spMsg="CASHIER.STATUS.BANK_CARD_FAILED_HOW_TO_GET_NEW_CARD" target="_blank"></a>
      </ng-container>
      <ng-template #regularError>
        <div class="info">
          <span class="info__text" spMsg="CASHIER.STATUS.TRY_AGAIN" spaces="end"></span>
          <span *ngIf="errorMessage$ | async" class="info__text--no-bold">{{errorMessage$ | async}}</span>
        </div>
        <gg-button (click)="back()" [settings]="commonStatusButton" class="info__button--back">
          <sp-msg spMsg="CASHIER.STATUS.RETRY"></sp-msg>
        </gg-button>
        <div class="info__status__support">
          <sp-msg spMsg="CASHIER.STATUS.ASK_SUPPORT" spaces="end"></sp-msg>
          <a class="info__status_support--link" href="mailto:{{ supportEmail }}" spMsg="CASHIER.STATUS.SUPPORT_LINK"></a>.
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="status === TransactionStatus.Progress">
      <div class="info__status">
        <div class="info__status__title" spMsg="CASHIER.STATUS.PAYMENT_PENDING"></div>
        <p class="info__status__text" spMsg="CASHIER.STATUS.CHECK_BALANCE"></p>
      </div>
      <gg-button class="play__button info__button--progress" (click)="updateStatus()"
                [settings]="reloadInfoButton">
        <sp-msg spMsg="COMMON.RELOAD_PAGE"></sp-msg>
      </gg-button>
      <div class="info__status__support">
        <sp-msg spMsg="CASHIER.STATUS.ASK_SUPPORT" spaces="end"></sp-msg>
        <a class="info__status_support--link" href="mailto:{{ supportEmail }}" spMsg="CASHIER.STATUS.SUPPORT_LINK"></a>.
      </div>
    </ng-container>

    <ng-container *ngIf="status === TransactionStatus.Going">
      <div class="info__status">
        <div class="info__status__title" spMsg="CASHIER.STATUS.PAYMENT_PENDING"></div>
        <p class="info__status__text" spMsg="CASHIER.STATUS.MONEY_ADDING"></p>
      </div>
      <gg-button [settings]="commonStatusButton" class="play__button info__button--progress" routerLink="/">
        <sp-msg spMsg="NOT_FOUND.GO_HOME"></sp-msg>
      </gg-button>
      <div class="info__status__support">
        <sp-msg spMsg="CASHIER.STATUS.ASK_SUPPORT" spaces="end"></sp-msg>
        <a class="info__status_support--link" href="mailto:{{ supportEmail }}" spMsg="CASHIER.STATUS.SUPPORT_LINK"></a>.
      </div>
    </ng-container>
  </div>
</section>
