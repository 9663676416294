import { inject, Pipe, PipeTransform } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { Nullable, pluralize } from '@libs/utils';
import { Bonus, BonusCasinoCash, BonusCasinoCashback, BonusCasinoCashbackExternal, BonusCasinoFreebet, BonusCasinoFreespin, BonusCasinoReload, BonusCasinoWelcome, BonusPokerReload } from '@portal/bonuses/data';
import { CurrencySymbolPipe } from '@portal/currencies/application';
import { UserQuery } from '@portal/user';

@Pipe({ name: 'bonusTitle', pure: false })
export class BonusTitlePipe implements PipeTransform {

  private readonly userQuery = inject(UserQuery);
  private readonly localization = inject(SpLocalization);

  constructor(
    private readonly currencySymbol: CurrencySymbolPipe,
  ) {}

  transform(bonusOrBonuses: Nullable<Bonus>): string {
    const bonuses: Array<Nullable<Bonus>> | Nullable<Bonus> = bonusOrBonuses && 'bonuses' in bonusOrBonuses ? bonusOrBonuses.bonuses : [ bonusOrBonuses ];

    return bonuses?.reduce((title, bonus, index) => {
      return !index ? `${this.getBonusTitleFor(bonus)}` : `${title} + ${this.getBonusTitleFor(bonus)}`;
    }, '');
  }

  private getBonusTitleFor(bonus: Nullable<Bonus>): string {
    if ((bonus instanceof BonusCasinoFreespin || bonus instanceof BonusCasinoWelcome) && bonus.game) {
      const plural = {
        one: 'BONUSES.FREESPIN', few: 'BONUSES.FREESPIN_FEW', many: 'BONUSES.FREESPIN_MANY',
      };
      const postfix = this.localization.translate(pluralize(bonus.game.rounds, plural));
      return `${bonus.game.rounds} ${postfix}`;
    }

    if (bonus instanceof BonusCasinoReload) {
      const postfix = this.localization.translate('BONUSES.BONUS_FOR_DEPOSIT');
      return `${Math.round(bonus.rate * 100)}% ${postfix}`;
    }

    if (bonus instanceof BonusCasinoCashback || bonus instanceof BonusCasinoCashbackExternal) {
      const postfix = this.localization.translate('BONUSES.CASHBACK_BONUS');
      return `${Math.round(bonus.rate * 100)}% ${postfix}`;
    }

    if (bonus instanceof BonusPokerReload) {
      return `${bonus.title[ this.localization.currentLanguage ]}`;
    }

    if (bonus instanceof BonusCasinoCash) {
      const postfix = this.localization.translate('BONUSES.CASINO_CASH_BONUS');
      const currencySymbol = this.currencySymbol.transform(this.userQuery.currency);
      return `${postfix} ${bonus.bonusTitle} ${currencySymbol}`;
    }

    if (bonus instanceof BonusCasinoFreebet) {
      return `${bonus.title[ this.localization.currentLanguage ]}`;
    }

    return '';
  }
}
