import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { SelectFiatCurrencies } from '@portal/currencies/commands';
import { CURRENCIES } from '@portal/currencies/shared';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';

import { BehaviorSubject, OperatorFunction, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gg-currency-by-country-selector',
  templateUrl: './currency-by-country-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencyByCountrySelectorComponent), multi: true },
  ],
})
export class CurrencyByCountrySelectorComponent extends InputAbstract implements OnInit {
  private readonly selectFiatCurrencies = inject(SelectFiatCurrencies);
  private readonly destroy = inject(DestroyRef);
  private readonly selectedCountry: BehaviorSubject<string> = new BehaviorSubject('');

  readonly list$ = this.selectedCountry.pipe(this.getCurrenciesByCountry$());
  readonly currency = new FormControl();

  defaultItem: Nullable<IInputSelectData>;

  @Input() country: Nullable<string> = null;

  override ngOnInit(): void {
    this.currency.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe((v) => this.writeValue(v));
  }

  private getCurrenciesByCountry$(): OperatorFunction<string, Array<IInputSelectData>> {
    return (source) => source.pipe(
      switchMap((country) => this.selectFiatCurrencies.receive({ list: country, registration: true })),
      map((currencies) => {
        return currencies.map((currency) => {
          const currencyItem = CURRENCIES[ currency.name ];
          if (currency.is.default) {
            this.defaultItem = currencyItem;
            this.currency.setValue(currencyItem.value, { emitEvent: true });
            this.writeValue(this.defaultItem.value);
          }
          return currencyItem;
        });
      }),
    );
  }
}
