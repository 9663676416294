// @ts-nocheck

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  CashierState,
  CashierStore,
  filterDepositMethods,
  filterWithdrawalMethods,
  PaymentMethod
} from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentMethodsQuery extends QueryEntity<CashierState> {
  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly paymentMethods$: Observable<Array<PaymentMethod>> = this.selectAll();
  readonly selectedMethod$: Observable<PaymentMethod> = this.selectActive();
  readonly loading$: Observable<boolean> = this.selectLoading();

  readonly deposits$: Observable<Array<PaymentMethod>> = this.paymentMethods$.pipe(map(filterDepositMethods));
  readonly withdrawals$: Observable<Array<PaymentMethod>> = this.paymentMethods$.pipe(map(filterWithdrawalMethods));

  get defaultAmount(): string { return this.getValue().defaultAmount; }

  get selectedMethod(): PaymentMethod { return this.getActive(); }

  constructor(protected repository: CashierStore) { super(repository); }
}
