import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { createPaymentHistory, PaymentHistory } from '@portal/payment/data';
import { CancelHistoryResponse, HistoryParams, PaymentHistoryResponse } from '@portal/payment/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentHistoryData {
  private readonly http = inject(HttpClient);

  load(filter: HistoryParams['show'], date?: string): Observable<Array<PaymentHistory>> {
    const type = filter && filter !== 'all' ? `&type=${filter}` : '';

    return this.http.get<PaymentHistoryResponse>(`/api/payments/payments_history?skip=0&limit=20${type}`).pipe(
      map(({ _items }) => _items.filter((i) => (Number(i.createDate) * 1000) >= Number(date))),
      map((history) => history.map(createPaymentHistory)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  cancel(id: PaymentHistory['id'], filter?: HistoryParams['show'], date?: string): Observable<unknown> {
    return this.http.post<CancelHistoryResponse>(`/api/payments/payments_history/${id}/cancel`, null).pipe(
      switchMap(({ _status: s }) => s === 'OK' ? this.load(filter, date) : throwError(() => new Error())),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }
}
