import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { SpCDKModule, SpLocalization } from '@libs/cdk';
import { ImageComponent } from '@portal/banners/components/slide-banner/image/image.component';
import { Banner } from '@portal/banners/data';
import { BannerComponent } from '@portal/banners/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThemeModule } from '@portal/config';
import { RemovePlaceholdersPipe } from "@portal/shared/pipes";

@Component({
  standalone: true,
  imports: [ SpCDKModule, ImageComponent, ThemeModule, RemovePlaceholdersPipe ],
  selector: 'gg-banners-slide',
  templateUrl: './slide-banner.component.html',
  styleUrls: [ './slide-banner.component.scss', './flat.slide-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SlideBannerComponent extends BannerComponent {
  private readonly currentLanguage = inject(SpLocalization).currentLanguage;
  private readonly breakpoints = inject(BreakpointObserver);
  readonly image$ = this.getImageContent();

  private getImageContent(): Observable<Extract<keyof Banner['content'], 'mobileImage' | 'image'>> {
    return this.breakpoints.observe([Breakpoints.XSmall]).pipe(map(({matches}) =>
      matches && this.banner?.content.mobileImage?.[this.currentLanguage] ? 'mobileImage' : 'image'
    ));
  }
}
