import { ClassConstructor } from '@libs/utils';
import { PaymentMessage } from '@portal/payment/shared';
import { CryptoVerificationComponent, DepositVerificationComponent, WithdrawVerificationComponent } from './verification';
import { ActiveBonusWarningComponent, InsufficientFundsWarningComponent } from './warning';

export * from './verification';
export * from './warning';

export const paymentMessages: Record<PaymentMessage, ClassConstructor> = {
  'active-bonus': ActiveBonusWarningComponent,
  'insufficient-funds': InsufficientFundsWarningComponent,
  'withdraw-verification': WithdrawVerificationComponent,
  'deposit-verification': DepositVerificationComponent,
  'crypto-verification': CryptoVerificationComponent
};
