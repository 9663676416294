import { CryptoMethodDto } from '@portal/cashier/data';

export class CryptoCurrency {
  constructor(
    readonly name: string,
    readonly min: string
  ) {}

  static create(cryptoMethod: CryptoMethodDto): CryptoCurrency {
    return new CryptoCurrency(cryptoMethod.name, cryptoMethod. min);
  }
}
