import { Params } from '@angular/router';
import { parseUrl } from '@libs/cdk';
import { ExtractedTokenObject } from '@portal/authorization/shared';

export function tokenExtractor(params: Params): ExtractedTokenObject {
  const [ , data ] = params['token'].split('.');
  const [ path, extra ] = parseUrl(params['next']);

  try {
    const tokenData = JSON.parse(atob(data));
    return {
      token: params['token'],
      next: { path, extra: extra?.queryParams || {} },
      email: tokenData.confirm_email || '',
      is2FAEnabled: tokenData.is2FAEnabled,
      loginMeta: tokenData.loginMeta,
      userId: tokenData.sub,
      verified_poker_user: tokenData.verified_poker_user
    };
  } catch (error) {
    return {
      token: params['token'],
      next: { path, extra: extra?.queryParams || {} },
      email: '',
      is2FAEnabled: false,
      loginMeta: '',
      userId: '',
      verified_poker_user: false
    };
  }
}
