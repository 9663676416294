import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-max-sum',
  templateUrl: './max-sum.component.html',
  styleUrls: [ './max-sum.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxSumComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus>;

  get maxAmount(): Nullable<number> {
    return this.bonus?.wager?.account ? Number(this.bonus.wager.account.maxAmount) : null;
  }
}
