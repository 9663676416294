<section class="method-status">
  <ng-container *ngIf="!isConfirmationScreen else confirmationScreen">
    <div class="method-status__wrapper">
      <img src="assets/images/profile/2fa/enable-status.svg" alt="enable-status-icon">
      <div class="method-status__title" spMsg="PROFILE.TWO_FACTOR_AUTH.ENABLED"></div>
      <gg-button class="method-status__button" [settings]="grayButton" (buttonClicked)="showConfirmationScreen()">
        <sp-msg msg="PROFILE.TWO_FACTOR_AUTH.DISABLE"></sp-msg>
      </gg-button>
    </div>
  </ng-container>
  <ng-template #confirmationScreen>
    <div class="method-status__wrapper">
      <img src="assets/images/profile/2fa/enable-status.svg" alt="enable-status-icon">
      <div class="method-status__disabling-title" spMsg="PROFILE.TWO_FACTOR_AUTH.ATTENTION"></div>
      <div class="method-status__disabling-subtitle" spMsg="PROFILE.TWO_FACTOR_AUTH.DISABLING_WARNING"></div>
      <form [formGroup]="form">
        <gg-input
          placeholder="{{'PROFILE.TWO_FACTOR_AUTH.AUTH_2_CODE' | translate}}"
          class="method-status__input"
          id="code"
          formControlName="code"
        ></gg-input>
      </form>
      <gg-button
        class="method-status__button method-status__button_disabling"
        [disabled]="form.invalid"
        [settings]="grayButton"
        (buttonClicked)="disableAuth()"
      >
        {{'PROFILE.TWO_FACTOR_AUTH.DISABLE' | translate}}
      </gg-button>
    </div>
  </ng-template>
</section>
