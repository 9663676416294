import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { SpPlatform } from '@libs/cdk';
import { USER_AGENT } from '@ng-web-apis/common';
import { Banner, BannersStore, createBannerFromReceived } from '@portal/banners/data';
import { ANDROID_USER_AGENTS, APP_SIDEBAR_BANNER, APP_TOP_BANNER, LoadBannersResponse } from '@portal/banners/shared';
import { first, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BannersData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(BannersStore);
  private readonly authorized$ = inject(SpAuthorizationService).authorized$;
  private readonly agent = inject(USER_AGENT);
  private readonly platform = inject(SpPlatform);

  private readonly banners = this.store.entity('banners');

  constructor() { this.updateAppBannersVisibility(); }

  synchronize(): void {
    this.http.get<LoadBannersResponse>('/api/2/banners').pipe(
      map(({ _items }) => [ ..._items, APP_SIDEBAR_BANNER, APP_TOP_BANNER ]),
      map((banners) => banners.map(createBannerFromReceived)),
      tap((banners) => this.banners.set(banners))
    ).subscribe(() => this.store.update({ sync: true }));
  }

  addBanner(banner: Banner): void {
    this.banners.add(banner);
  }

  private updateAppBannersVisibility(): void {
    this.authorized$.pipe(
      first(),
      map((authorized) => authorized && this.platform.device.android && !ANDROID_USER_AGENTS.includes(this.agent))
    ).subscribe((isAppBannerVisible) => {
      this.store.update({ areAppBannersAvailable: isAppBannerVisible });
    });
  }
}
