import { Component, Input } from '@angular/core';

@Component({
  selector: 'gg-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: [ './button-back.component.scss', './button-back.purple.component.scss' ]
})
export class ButtonBackComponent {
  @Input() isTextShow = true;
}
