export enum SeoType {
  Main = 'main',
  CasinoSection = 'casino_section',
  CasinoProvider = 'casino_provider',
  Game = 'game',
  PromoList = 'promo_list',
  PromoDetail = 'promo_detail',
  NewsList = 'news_list',
  NewsDetail = 'news_detail',
  Page = 'page',
  Fuq = 'fuq',
  FuqDetail = 'fuq_detail',
  Login = 'login',
  Registration = 'registration',
  Poker = 'poker',
  Tournaments = 'tournaments',
  Rewards = 'rewards',
  Promotions = 'promotions'
}
