import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';

const SIZES = {
  320: 280,
  375: 335,
  400: 360,
  420: 380,
  520: 480,
  620: 580,
  768: 728,
  880: 840,
  992: 912,
  1200: 1160
};

@Component({
  standalone: true,
  selector: 'gg-banners-slide-image',
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  @Input() image: Nullable<string> = null;
  @Input() imageAlt: Nullable<string> = null;

  get src(): string {
    if (!this.image) { return ''; }
    return `${this.image}1160/`;
  }

  get srcset(): string {
    if (!this.image) { return ''; }
    return Object.values(SIZES).map(size => `${this.image}${size}/ ${size}w`).join();
  }

  get sizes(): string {
    if (!this.image) { return ''; }
    const width = Object.entries(SIZES).reduce((acc, [ k, v ]) => `${acc},(max-width: ${k}px) ${v}px`, '');
    return `${width},${SIZES['1200']}px`;
  }
}
