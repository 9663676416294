import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Banner, BannersQuery } from '@portal/banners';
import { SlideBannerComponent } from '@portal/banners/components';
import { BannerPage, BannerSection } from '@portal/banners/shared';
import { FLAT_BANNERS_SLIDER_CONFIG } from '@portal/banners/shared/constants/flat-banners-slider-config';
import { MAIN_BANNERS_SLIDER_CONFIG } from '@portal/banners/shared/constants/main-banners-slider-config';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ContentModule } from '@portal/shared/components';
import { Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'gg-banners-main-slider',
  standalone: true,
  imports: [ SpCDKModule, ThemeModule, ContentModule, SlideBannerComponent ],
  templateUrl: './main-banner.component.html',
  styleUrls: [ './main-banner.component.scss', './flat.main-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MainBannerComponent {
  private readonly query = inject(BannersQuery);
  private readonly authorized$ = inject(SpAuthorizationService).authorized$;
  private readonly theme = inject(ConfigQuery).theme;

  readonly isBrowser = inject(SpPlatform).browser;
  readonly bannersSliderConfig: SwiperOptions = this.theme.isMatch('purple') ? FLAT_BANNERS_SLIDER_CONFIG : MAIN_BANNERS_SLIDER_CONFIG;

  banners$: Observable<Array<Banner>>;

  @Input() section: Nullable<BannerSection> = null;
  @Input() page: Nullable<BannerPage> = null;

  constructor() {
    this.banners$ = this.authorized$.pipe(
      map((authorized) => ({ page: this.page, section: this.section, authorized })),
      switchMap((filter) => this.query.selectVisible(filter)),
      distinctUntilChanged((curr, prev) => curr.length === prev.length),
      map((banners) => banners.sort((a, b) => Number(a.visibility.order) - Number(b.visibility.order))),
      map((banners) => this.isBrowser ? banners : [ banners[ 0 ] ])
    );
  }
}
