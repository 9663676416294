import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationQuery } from '@portal/verification/data';
import { VerificationSectionType } from '@portal/verification/shared/enums';

@Component({
  selector: 'gg-documents-verification',
  templateUrl: './documents-verification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsVerificationComponent {
  private readonly verificationQuery = inject(VerificationQuery);

  readonly isKycEnable$: Observable<boolean> = this.verificationQuery.isKycEnable$;
  readonly currentTab: typeof VerificationSectionType = VerificationSectionType;
}
