<ng-container *ngIf="phoneVerificationState$ | async as state">
    <div
      *ngIf="userVM | async as user"
      class="contact-data"
      [class.contact-data_no-phone]="!isMobilePhoneEnabled"
      [ngClass]="state"
    >
        <ng-container *ngIf="state !== verificationState.CodeResent && state !== verificationState.CodeSent">
            <gg-user-settings-contact-details-email-confirmation
              [isOnlyEmailConfirmation]="!isMobilePhoneEnabled"
              [user]="user"
              [saveButton]="saveButton"
            ></gg-user-settings-contact-details-email-confirmation>
        </ng-container>
        <ng-container *ngIf="isMobilePhoneEnabled">
            <gg-user-settings-contact-details-phone-not-verified
              *ngIf="state === verificationState.NotVerified"
              [isFlashCallOption]="isFlashCallOption"
              [saveButton]="saveButton"
            ></gg-user-settings-contact-details-phone-not-verified>
            <gg-user-settings-contact-details-phone-verification-in-progress
              *ngIf="state === verificationState.CodeSent || state === verificationState.CodeResent"
              [isFlashCallOption]="isFlashCallOption"
              [saveButton]="saveButton"
            ></gg-user-settings-contact-details-phone-verification-in-progress>
            <gg-user-settings-contact-details-phone-verified
              *ngIf="state === verificationState.Verified"
              [userPhone]="user['phone']"
            ></gg-user-settings-contact-details-phone-verified>
        </ng-container>
    </div>
</ng-container>
