import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { PWADialogAbstract, PWADialogInfo, PWATextLine } from '@portal/banners/shared';

@Component({
  standalone: true,
  selector: 'gg-user-pwa-ios-instruction-dialog',
  templateUrl: './ios-pwa-instruction-dialog.component.html',
  styleUrls: [ './ios-pwa-instruction-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule ]
})
export class IOSPWAInstructionDialogComponent extends PWADialogAbstract {
  readonly lines: Array<PWATextLine> = PWADialogInfo[ this.device ][ this.browser ];
}
