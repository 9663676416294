<ng-container *ngIf="isDeposit$ | spAsync else steps">
  <gg-payment-crypto-wallet-info *spLet="activeMethod$ | spAsync as method" [wallet]="method?.data.wallet" [logo]="method?.view.logo.secondary"/>
</ng-container>
<ng-template #steps>
  <ng-container *spLet="activeMethod$ | spAsync as method">
    <ng-container *spLet="commissionWarning$ | spAsync as commissionWarning">
      <ng-container *spLet="commissionInfo$ | spAsync as commissionInfo">
        <gg-payment-commission-warning *ngIf="commissionWarning" [amount]="builder.snapshot.amount"
          (closeCommission)="closeCommissionWarning($event)"/>
        <gg-payment-commission-info *ngIf="commissionInfo" (closeCommission)="closeCommissionInfo()"/>
        <ng-container stepsFlow *ngIf="method && !commissionWarning && !commissionInfo">
          <gg-stepper [(selectedIndex)]="step" class="crypto-flow__stepper" [beforeChange]="submitStep.bind(this)">
            <cdk-step *ngFor="let control of form?.controls">
              <gg-payment-generated-form *spLet="isGenerated(control) | spAsync as gen" class="crypto-flow__form" [declaration]="gen?.form" [formControl]="gen?.control"/>
            </cdk-step>
            <ng-container ggStepperProvide>
              <gg-payment-summary (showCommissionInfo)="showCommissionInfo()"/>
              <gg-button class="crypto-flow__button crypto-flow__button_cancel" [settings]="cancelButton" (click)="resetMethod()"><sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.CANCEL"/></gg-button>
            </ng-container>
            <gg-button *ngIf="stepsCount" class="crypto-flow__button" ggStepperNext [settings]="submitButton" [disabled]="invalid"><sp-msg msg="CASHIER.CARD.MAKE_WITHDRAW"/></gg-button>
          </gg-stepper>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
