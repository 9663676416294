import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationData, VerificationQuery } from '@portal/verification/data';
import { synchronize$ } from '@libs/store';

export function verificationResolver(): Observable<boolean> {
  const verificationQuery = inject(VerificationQuery);
  const verificationCommands = inject(VerificationData);

  return verificationQuery.synchronized$.pipe(
    synchronize$(verificationCommands, verificationQuery)
  )
}
