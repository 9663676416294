import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';

@Component({
  standalone: true,
  selector: 'gg-layout-sidebar-icon',
  templateUrl: './sidebar-icon.component.html',
  styleUrls: [ './sidebar-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ThemeModule ]
})
export class SidebarIconComponent {
}
