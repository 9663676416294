import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { PaymentGroupsQuery } from '@portal/payment/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';


@Component({
  selector: 'gg-payment-commission-info',
  templateUrl: './commission-info.component.html',
  styleUrls: [ './commission-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionInfoComponent {
  private readonly groupsQuery = inject(PaymentGroupsQuery);

  readonly method$ = this.groupsQuery.activeMethod$;
  readonly closeButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };

  @Output() closeCommission: EventEmitter<void> = new EventEmitter();

  closePopup(): void { this.closeCommission.emit() }
}
