import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { createSeo, SeoStore } from '@portal/seo/data';
import { ReceivedSeoInformation, SeoType } from '@portal/seo/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SeoData {

  private readonly store: SeoStore = inject(SeoStore);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly seo = this.store.entity('texts');

  load(type: SeoType, id: string = 'default'): void {
    const incomingId = `${id}|${type}`;

    if (this.seo.getValue().ids?.find((savedId) => incomingId === savedId)) {
      this.seo.setActive(incomingId);
      return;
    }

    this.http.get<ReceivedSeoInformation>(`/api/2/cms/seo/text/${type}/${id}`).pipe(
      map(({ _item }) => createSeo(_item)),
      catchError((error) => throwError(ResponseFactory.error(error))),
      tap(pageSeo => this.seo.upsert(pageSeo.id, pageSeo))
    ).subscribe({
      next: (pageSeo) => this.seo.setActive(pageSeo.id),
      error: () => this.seo.setActive(null)
    });
  }

  reset(): void {
    this.seo.setActive(null);
  }
}
