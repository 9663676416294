import { Validators } from '@angular/forms';
import { DEPOSIT_FORM } from './default-form-declaration.constant';

export const WITHDRAWAL_NOT_CARD_LENGTH_FORM = {
  ...DEPOSIT_FORM,
  account: [
    '',
    [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(36)
    ]
  ]
};
