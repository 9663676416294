import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { excludeSpecSymbols } from '@libs/utils';
import moment from 'moment';
import { toggleError } from './toggle-error.helper';

type CustomValidation<T extends string> = Partial<Record<T, true>> | null;
type ComplicatedPasswordValidation = CustomValidation<'strong'>;
type AmountValidation = (control: AbstractControl) => CustomValidation<'int' | 'double' | 'intAndAsterisk'>;
type PhoneValidation = CustomValidation<'phone'>;
type RequiredCheckboxValidation = CustomValidation<'required'>;
type ExpMonthValidation = (control: AbstractControl) => CustomValidation<'expM'>;
type ExpYearValidation = (control: AbstractControl) => CustomValidation<'expY'>;
type BirthdateValidation = CustomValidation<'birthday'>;
type UsernameValidation = CustomValidation<'username'>;

export class CustomValidators {

  static complicatedPassword(control: AbstractControl): ComplicatedPasswordValidation {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const minLength = 7;

    return hasNumber && hasUpper && hasLower && control.value.length > minLength
      ? null
      : { strong: true };
  }

  static lowerCase(control: AbstractControl): { lowercase: true } | null {
    return (/[A-ZА-Я]+/.test(control.value)) ? { lowercase: true } : null;
  }

  static nickName(control: AbstractControl): { incorrectFormat: true } | null {
    return (/[а-яА-ЯёЁ\s\W]+/g).test(control.value) ? { incorrectFormat: true } : null;
  }

  static matchFields(matcherName: string, matchingName: string): (form: AbstractControl) => null {
    return (form: AbstractControl) => {
      const matcher = form.get(matcherName);
      const matching = form.get(matchingName);

      if (!matcher || !matching) { return null; }

      const errors = toggleError(matching.value !== matcher.value, 'notMatch', matching.errors);
      matching.setErrors(errors);

      return null;
    };
  }

  static amount(type: 'int' | 'double' | 'intAndAsterisk'): AmountValidation {
    return (control: AbstractControl) => {
      if (!control.value) { return null; }

      if (type === 'double' && !(/^[0-9]+(\.[0-9]{1,2})?$/.test(control.value))) { return { double: true }; }
      if (type === 'int' && !(/^\d+$/.test(control.value))) { return { int: true }; }
      if (type === 'intAndAsterisk' && !(/^\+?(?:\**\d){2,}\**$/g.test(control.value))) { return { intAndAsterisk: true }; }

      return null;
    };
  }

  static phone(control: AbstractControl): PhoneValidation {
    const value = excludeSpecSymbols(control.value, '+');
    const regExp = /^[+\d]?(?:[\d]{10,15})$/;
    return regExp.test(value) ? null : { phone: true };
  }

  static requiredCheckbox(control: AbstractControl): RequiredCheckboxValidation {
    return control.value ? null : { required: true };
  }

  static expM(): ExpMonthValidation {
    return (control: AbstractControl) => {
      const parent = control.parent as FormGroup<{ expY: FormControl<string> }>;

      const expY = Number(parent.controls.expY.value);
      const expM = Number(control.value);
      const year = Number(moment(Date.now()).format('YY'));
      const month = Number(moment(Date.now()).format('MM'));
      let isRightMonthOfCurrYear = true;
      if (year === expY) { isRightMonthOfCurrYear = expM >= month; }

      return (expM > 0 && expM < 13 && isRightMonthOfCurrYear) ? null : { expM: true };
    }
  }

  static expY(): ExpYearValidation {
    return (control: AbstractControl) => {
      const parent = control.parent as FormGroup<{
        expM: FormControl<string>;
      }>;

      const expM = Number(parent.controls.expM.value);
      const month = Number(moment(Date.now()).format('MM'));
      const expY = Number(control.value);
      const year = Number(moment(Date.now()).format('YY'));
      let isRightMonthOfCurrYear = true;
      if (year === expY) { isRightMonthOfCurrYear = expM >= month; }

      return (expY >= year && isRightMonthOfCurrYear) ? null : {expY: true};
    }
  }

  static birthday(control: AbstractControl): BirthdateValidation {
    if (isNaN(new Date(control.value).getDate())) { return { birthday: true }; }
    const date = moment(control.value);

    return date.isValid() && moment().diff(date, 'year') >= 18 ? null : { birthday: true };
  }

  static username(control: AbstractControl): UsernameValidation {
    const value = control.value?.trim();
    const regExp = /^@[^\s]+$/;

    return value && regExp.test(value) ? null : { username: true };
  }
}
