import { HttpClient, HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { ResponseFactory } from '@portal/shared/helpers';
import { VerificationStore } from '@portal/verification/data';
import { KycaidVerificationSendMethodType, VerificationDocumentData } from '@portal/verification/data/dto';
import { KycaidVerification } from '@portal/verification/data/entities';
import { createKycaidVerification, createVerificationMethods } from '@portal/verification/data/mappers';
import { KycaidVerificationMethodDetails, KycaidVerificationMethodsList } from '@portal/verification/shared';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VerificationData {
  private readonly store = inject(VerificationStore);
  private readonly http = inject(HttpClient);
  private readonly authorization = inject(SpAuthorizationService);

  synchronize(): void {
    this.http.get<KycaidVerificationMethodsList>('/api/2/verification/').pipe(
      map((methods: KycaidVerificationMethodsList) => createVerificationMethods(methods._items)),
      catchError((error) => throwError(() => ResponseFactory.error(error))),
    ).subscribe((methods) => this.store.update({ methods, sync: true }));
  }

  sendKycaidVerification(data: KycaidVerificationSendMethodType): Observable<KycaidVerification> {
    this.store.update((state) => {
      const verification = { ...state, qrCode: null, loading: true };

      return { ...state, verification };
    });

    return this.http.post<KycaidVerificationMethodDetails>('/api/2/verification/', data).pipe(
      map(({ _item }: KycaidVerificationMethodDetails) => createKycaidVerification(_item)),
      tap({
        next: ({ qrCode }) => this.store.update((state) => {
          const verification = { ...state, qrCode, loading: false };

          return { ...state, verification };
        }),
        error: (error) => this.store.update((state) => ({
          ...state,
          errorType: ResponseFactory.error(error),
          loading: false,
        })),
      }),
    );
  }

  resetQrCode(): void {
    this.store.update((state) => {
      const verification = { ...state, qrCode: null, loading: false };

      return { ...state, verification };
    });
  }

  uploadDocument(file: File): Observable<HttpEvent<unknown>> {
    return this.http.post(`/document/upload/${file.name}`, file, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Content-Type': file.type },
    }).pipe(
      filter(response => !!response),
    );
  }

  saveDocument(documentMd5: string, documentType: string): Observable<unknown> {
    const body = new FormData();
    body.append('document_md5', documentMd5);
    body.append('document_type', documentType);

    return this.http.post<HttpEvent<VerificationDocumentData>>('/document/save', body).pipe(
      switchMap(() => this.authorization.discover()),
    );
  }
}
