import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ByLanguage, SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Seo, SeoQuery } from '@portal/seo/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type SeoContent = {
  text: Nullable<ByLanguage<string>>;
  h1: Nullable<ByLanguage<string>>;
} | null;

@Component({
  standalone: true,
  imports: [ SpCDKModule ],
  selector: 'gg-seo-text',
  templateUrl: './seo-text.component.html',
  styleUrls: [ './seo-text.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoTextComponent {

  private readonly router: Router = inject(Router);
  private readonly activeSeo$: Observable<Nullable<Seo>> = inject(SeoQuery).active$;

  readonly content$: Observable<SeoContent> = this.activeSeo$.pipe(map((seo) => this.setSeoContent(seo)));

  readonly isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isPlayPage(): boolean { return this.router.url.includes('slots'); }

  expand(): void { this.isExpanded$.next(!this.isExpanded$.getValue()); }

  setSeoContent(seo: Nullable<Seo>): SeoContent {
    if (!seo) { return null; }

    return {
      text: seo.text || null,
      h1: ![ 'news_list', 'promo_list' ].includes(seo.id) ? seo.h1 : null
    };
  }
}
