import { Nullable } from '@libs/utils';

export class SuccessResponse<Data, Meta = unknown> {
  _item?: Nullable<Data> = null;
  _items?: Array<Data> = [];
  _meta: Nullable<Meta> = null;
  _status: Nullable<string> = null;

  constructor(response: Partial<SuccessResponse<Data, Meta>>) {
    if (!response) { return; }

    this._item = response._item;
    this._items = response._items;
    this._meta = response._meta;
    this._status = response._status;

  }
}
