import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { synchronize } from '@libs/store';
import { ConfigQuery } from '@portal/config';
import { RecaptchaData, RecaptchaQuery } from '@portal/recaptcha';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {
  const isSynced$ = inject(ConfigQuery).synchronized$.pipe(first(Boolean));
  const data = inject(RecaptchaData), query = inject(RecaptchaQuery);

  return () => isSynced$.pipe(tap(() => synchronize(data, query)), first());
};

export const recaptchaInitializer: Provider = { provide: APP_INITIALIZER, useFactory: init, multi: true };
