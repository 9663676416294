import { UserBankCardDetailsDto } from '@portal/cashier/data';
import { BankCardsAge } from '@portal/cashier/shared';

export class BankCardDetails {
  constructor(
    readonly id: string,
    readonly mask: string,
    readonly exp_month: string,
    readonly exp_year: string,
    readonly card_status: BankCardsAge
  ) {}

  static create(bankCardDetails: UserBankCardDetailsDto): BankCardDetails {
    return new BankCardDetails(
      bankCardDetails.id,
      bankCardDetails.mask,
      bankCardDetails.exp_month,
      bankCardDetails.exp_year,
      bankCardDetails.card_status
    );
  }
}
