import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Nullable } from '@libs/utils';
import {
  ActivatedBonus,
  Bonus,
  BonusesStore,
  BonusFreebetWithoutBonusAccountsDto,
  createActivatedBonus,
  createBonusesOfType,
  mergeBonusAccountsFromSportsbook,
  PromoCodeDto
} from '@portal/bonuses/data';
import {
  ActivatedBonusResponse,
  BonusesListResponse,
  CryptoActiveBonusResponse,
  WagerListResponse
} from '@portal/bonuses/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BonusesData {
  private readonly http = inject(HttpClient);
  private readonly store = inject(BonusesStore);

  private readonly bonuses = this.store.entity('bonuses');

  synchronize(): void {
    this.loadBonuses().subscribe();
  }

  loadCryptoActiveBonus(): Observable<Nullable<string>> {
    return this.http.get<CryptoActiveBonusResponse>('/api/2/bonus/').pipe(first(), map((res) => res._item.result.bonus_id));
  }

  setCryptoActiveBonus({ id, type }: Bonus | { id: string; type: string }): void {
    this.http.post('/api/2/bonus/', { bonusId: id, bonusType: type }).pipe(first()).subscribe();
  }

  cancelCryptoActiveBonus(): void {
    this.http.put('/api/2/bonus/', { bonusId: null }).pipe(first()).subscribe();
  }

  activateBonus({ id, type }: Bonus): Observable<unknown> {
    return this.http.post('/api/2/bonus/activate', { bonusId: id, bonusType: type }).pipe(
      switchMap((res) => this.loadBonuses(res)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  cancelBonus({ id, type }: Bonus): Observable<unknown> {
    return this.http.put('/api/presents/bonus/disable', { id, type }).pipe(
      switchMap((res) => this.loadBonuses(res)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  activatePromoCode(promoCode: PromoCodeDto): Observable<Nullable<ActivatedBonus>> {
    return this.http.post<ActivatedBonusResponse>('/api/2/promo-code/activate/', { promoCode }).pipe(
      map(({ _item }) => createActivatedBonus(_item.bonus)),
      switchMap((bonus) => this.loadBonuses(bonus)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  selectBonus({ id, type = '' }: Bonus | { id: string; type?: string }, persistOnServer?: Nullable<boolean>): void {
    this.bonuses.setActive(id);
    persistOnServer && this.setCryptoActiveBonus({ id, type });
  }

  unselectBonus(persistOnServer?: Nullable<boolean>): void {
    this.bonuses.setActive(null);
    persistOnServer && this.cancelCryptoActiveBonus();
  }

  clear(): void { this.bonuses.reset(); }

  clearStore(): void {
    this.store.update({ sync: false });
    this.clear();
  }

  private loadBonuses(): Observable<Nullable<Array<Bonus>>>;
  private loadBonuses<T>(fallThroughResponse: T): Observable<T>;
  private loadBonuses<T>(fallThroughResponse?: T): Observable<Nullable<Array<Bonus>> | T> {
    return this.http.get<BonusesListResponse>('/api/2/bonus/list/').pipe(
      tap(({ _meta }: BonusesListResponse) => this.store.update({ depBonusActivated: _meta.depBonusActivated })),
      switchMap(({ _item }) => {
        const sportsbookBonuses = _item['sportsbook'];

        if (sportsbookBonuses && sportsbookBonuses.length > 0) {
          return this.loadSportsbookWager().pipe(
            map((sportsbooks) => mergeBonusAccountsFromSportsbook(sportsbookBonuses as Array<BonusFreebetWithoutBonusAccountsDto>, sportsbooks._item.sportsbook)),
            map((extendedBonuses) => ({ ..._item, sportsbook: extendedBonuses })),
            map((updatedItem) => createBonusesOfType(updatedItem))
          );
        }

        return of(createBonusesOfType(_item));
      }),
      tap((bonuses) => !!bonuses && this.bonuses.set(bonuses)),
      map((originalResponse) => fallThroughResponse || originalResponse),
      tap(() => this.store.update({ sync: true }))
    );
  }

  private loadSportsbookWager(): Observable<WagerListResponse> {
    return this.http.get<WagerListResponse>('/api/2/bonus/sportsbook_wager_list');
  }
}
