import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedBonus, Bonus } from '@portal/bonuses/data';

@Pipe({ name: 'bonusIcon' })
export class BonusIconPipe implements PipeTransform {
  transform({ type }: Bonus | ActivatedBonus, useNewIcons?: boolean): string {
    // TODO: re-implementation when bonuses improve
    return useNewIcons
      ? `{project}/images/bonuses/{palette}/icons/${type}.svg`
      : `{project}/images/bonuses/{palette}/${type}.svg`;
  }
}
