import { CryptoCurrencyDto } from '@portal/currencies/data/dto';

type CryptoCurrencyProps = CryptoCurrencyDto;

export class CryptoCurrency {
  readonly name = this.source.name;
  readonly min = this.source.min;

  constructor(
    private readonly source: CryptoCurrencyProps,
  ) {}

  isEqual(currency: CryptoCurrency | CryptoCurrency['name']): boolean {
    return currency instanceof CryptoCurrency ? this.source.name === currency.name : this.source.name === currency;
  }

  toString(): string {
    return this.source.name;
  }
}
