import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { SpAuthorizationService } from '@libs/authorization';
import { SpNavigation, SpPlatform, SpStorage } from '@libs/cdk';
import { ClassConstructor } from '@libs/utils';
import { PWADialogs } from '@portal/banners/components';
import { PWA_DIALOG_DELAY_DAYS, PWAService } from '@portal/banners/shared';
import { ConfigQuery } from '@portal/config';
import { StorageKey } from '@portal/shared/types';
import { filter, NEVER, Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PWADialogService {
  private readonly dialog = inject(Dialog);
  private readonly platform = inject(SpPlatform);
  private readonly PWAService = inject(PWAService);
  private readonly storage = inject(SpStorage);
  private readonly authorized = inject(SpAuthorizationService).authorized;
  private readonly navigation = inject(SpNavigation);
  private readonly enabledPwa = inject(ConfigQuery).modules.pwa?.enabled;

  init(): void {
    this.navigation.event(NavigationEnd).pipe(
      filter((navigation) => navigation.url === '/' && this.authorized && this.enabledPwa),
      switchMap(() => this.PWAService.isAvailable({ storage: true, bets: true })),
      filter((isAvailable) => Boolean(isAvailable)),
      switchMap(() => this.open()),
    ).subscribe(() => this.setDelayDate());
  }

  open(): Observable<unknown> {
    const component = this.getComponent();
    return component ? this.dialog.open(component, { panelClass: 'bottom-center' }).closed : NEVER;
  }

  private getComponent(): ClassConstructor {
    const device = this.platform.currentDeviceType;
    const dialogType = this.PWAService.canInstall && device === 'desktop' ? 'install' : 'instruction';

    return PWADialogs[ device ][ dialogType ];
  }

  private setDelayDate(): void {
    const currentDate = new Date();
    const futureTimestamp = currentDate.getTime() + PWA_DIALOG_DELAY_DAYS;
    this.storage.setItem(StorageKey.PWA, String(futureTimestamp));
  }
}
