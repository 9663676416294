export enum EcommpayPaymentMethods {
  BankCardDeposit = 43,
  BankCardDepositKzt = 92,
  BankCardDepositTrusted = 94,
  BankCardWithdrawal = 52,
  NetellerDeposit = 44,
  NetellerWithdrawal = 55,
  MonetaruDeposit = 46,
  MonetaruWithdrawal = 56,
  SkrillDeposit = 47,
  MobileDeposit = 48,
  SkrillWithdrawal = 54,
  WebmoneyDeposit = 49,
  WebmoneyLightDeposit = 49,
  WebmoneyWithdrawal = 53,
  MonetixDeposit = 62,
  YandexMoneyDeposit = 64,
  YandexMoneyWithdrawal = 63,
  MonetixDepositCardUntrusted = 107,
  MonetixDepositQiwi = 108,
  BankCardDepositTrustedVip = 117,
  BankCardDepositUZ = 138,
  BankCardWithdrawalUZ = 139,
  MonetixWithdrawal = 145,
  BankCardWithdrawalFixedAmount = 173,
  SbpDeposit = 218,
  WithoutTokenWithdrawal = 269,
  SofortDeposit = 276,
  GiropayDeposit = 307,
  RapidTransferDeposit = 308,
  PaySafeCashDeposit = 309,
  PaySafeCardDeposit = 310,
  BinancePayDeposit = 311,
  SepaWithdrawal = 312,
  MonetixNetellerDeposit = 315,
  MonetixNetellerWithdrawal = 316,
  MonetixSkrillDeposit = 317,
  MonetixSkrillWithdrawal = 318,
  SbpQrDeposit = 319,
  OpenBankingEUDeposit = 335,
  DepositTrustedVipH2h = 347,
  DepositTrustedKztH2h = 348,
  DepositTrustedH2h = 349,
  SbpWithdrawal = 341,
  BankCardNewUzWithdrawal = 350,
  InteracDeposit = 384,
  InteracWithdrawal = 387,
  MobileDepositTrust = 399,
  MobileWithdrawal = 433
}
