<section class="field" #select ggCreateThemeClass="selection">
  <div class="field__wrapper">
    <div class="field__select field__input" [ngClass]="inputClass" (click)="showOptions = !showOptions">
      <div class="field__select-chosen-option" data-testid="chosen-option">
        <ng-container *ngIf="chosenOption else holder">
          <div *ngIf="chosenOption.icon" class="field__select__img-wrapper">
            <img [ngClass]="additionalClass" class="field__select-img" alt="" [src]="chosenOption.icon"/>
          </div>
          <div
            class="field__select-text field__select-text_default"
            [ngClass]="{'field__select-text_default--margin': !chosenOption.icon}"
          >
            <span *ngIf="placeholder" class="field__select__title" data-testid="selector-title">{{placeholder}}</span>
            <div class="field__select__text-wrapper" data-testid="chosen-name">
              {{chosenOption.description | translate | truncate: truncateSelectedTo}}
              <span *ngIf="chosenOption.suffix" class="field__select-text field__select-suffix">
                {{chosenOption.suffix}}
              </span>
            </div>
          </div>

        </ng-container>
        <ng-template #holder>
          <span
            class="field__select-text field__select-text_default field__placeholder"
            [ngClass]="{'field__select-text_default--margin': !chosenOption?.icon}"
          >
            {{defaultPlaceholder}}
          </span>
        </ng-template>
        <img src="assets/images/icons/arrow-small.svg" class="field__select-arrow" [ngClass]="{'field__select-arrow_up': showOptions}" alt=""/>
      </div>
    </div>
    <div class="field__select-wrapper" [ngClass]="optionClass">
      <ul class="field__select-options">
        <li *ngIf="isSearch" class="field__search">
          <input #search class="field__search-input" [placeholder]="'COMMON.SEARCH' | translate" [formControl]="searchControl" data-testid="search-input"/>
        </li>
        <li class="field__select-option" *ngFor="let option of optionsList$ | spAsync" (click)="newOption(option)" [attr.data-testid]="'option-' + option.value">
          <img *ngIf="option.icon" [src]="option.icon" alt="" class="field__select-img" [ngClass]="[option.description, additionalClass]" data-testid="option-image"/>
          <span class="field__select-text" data-testid="option-text">{{option.description | translate | truncate: truncateTo}}</span>
          <span *ngIf="option.suffix" class="field__select-text field__select-suffix" data-testid="option-suffix">{{option.suffix}}</span>
        </li>
      </ul>
    </div>
  </div>
  <gg-input-error [errors]="errors"/>
</section>
