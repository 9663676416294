import { Directive, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { synchronize } from '@libs/store';
import { RecaptchaData, RecaptchaQuery } from '@portal/recaptcha/data';
import { RecaptchaSection } from '@portal/recaptcha/shared';

@Directive({ selector: '[ggHasRecaptcha]' })
export class IsRecaptchaEnabledDirective implements OnInit {
  private readonly recaptchaData: RecaptchaData = inject(RecaptchaData);
  private readonly recaptchaQuery: RecaptchaQuery = inject(RecaptchaQuery);
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private readonly templateRef: TemplateRef<TemplateRef<unknown>> = inject(TemplateRef);

  @Input('ggHasRecaptcha') hasRecaptchaFor: RecaptchaSection = 'login';

  constructor() { synchronize(this.recaptchaData, this.recaptchaQuery); }

  ngOnInit(): void {
    if (!this.recaptchaQuery.isRecaptchaEnabledFor(this.hasRecaptchaFor)) { return; }
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
