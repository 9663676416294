import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { excludeNullish, Nullable } from '@libs/utils';
import { ResponseFactory } from '@portal/shared/helpers';
import {
  createTruePlayBalance,
  createUser,
  PasswordUpdateDto,
  PhoneVerificationDto,
  TwoFactorSettingsDto,
  UserInfo,
  UserInfoDto,
  UserStore,
  UserTruePlayTokenBalanceBalanceDto,
  verificationCodeDto
} from '@portal/user/data';
import { updateUserBalance } from '@portal/user/data/mappers/update-user-balance';
import { PersonalInfo, UserBalanceResponse, UserLevelResponse } from '@portal/user/shared';
import { ChangeEmailResponse } from '@portal/user/shared/types/responses/change-email.response';
import { CodeVerificationResponse } from '@portal/user/shared/types/responses/code-verification.response';
import { CreatePasswordResponse } from '@portal/user/shared/types/responses/create-password.response';
import { F2ASettingsResponse } from '@portal/user/shared/types/responses/f2a-settings.response';
import { PasswordUpdateResponse } from '@portal/user/shared/types/responses/update-password.response';
import { filter, interval, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserData {
  private readonly authData: SpAuthorizationService = inject(SpAuthorizationService);
  private readonly http = inject(HttpClient);
  private readonly store = inject(UserStore);

  private readonly user$ = this.authData.user$ as Observable<UserInfoDto>;

  createUserHandler(lang: string): void {
    this.user$.pipe(
      filter((info) => Boolean(info)),
      tap((info) => this.store.update(createUser(info, null))),
      this.updateUserLevel(), this.updateUserLocale(lang), this.setUserBalanceUpdateInterval()
    ).subscribe();
  }

  createPassword(newPassword: string): Observable<CreatePasswordResponse> {
    const body = { newPassword, refreshToken: this.authData.tokens?.refreshToken };
    return this.http.post<CreatePasswordResponse>('/api/2/auth/password/create/', body).pipe(
      tap((response) => this.authData.set(response)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  updatePassword({ oldPassword, newPassword }: PasswordUpdateDto): Observable<void> {
    const body = { newPassword, oldPassword, refreshToken: this.authData.tokens?.refreshToken };
    return this.http.post<PasswordUpdateResponse>('/api/2/auth/password/change/', body).pipe(
      map((response) => this.authData.set(response)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  updatePokerNickAndPass(nick: string, password = ''): Observable<unknown> {
    return this.http.post('/profile/change/nick', { nick, password }).pipe(
      switchMap(() => this.authData.discover())
    );
  }

  updatePersonalInfo(credentials: PersonalInfo): Observable<unknown> {
    return this.http.post('/api/2/user/set_personal_info', credentials).pipe(
      switchMap(() => this.authData.discover()),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyPhone(credentials: PhoneVerificationDto): Observable<unknown> {
    return this.http.post('/api/2/phone/verification', credentials).pipe(
      switchMap(() => of(null)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  verifyCode({ code }: verificationCodeDto): Observable<unknown> {
    return this.http.post<CodeVerificationResponse>('/code/verification', { code }).pipe(
      switchMap((response) => response.errors
        ? throwError(() => ResponseFactory.error(response)) : this.authData.discover()),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  changeEmail(email: string, recaptchaToken: Nullable<string>): Observable<unknown> {
    return this.http.post<ChangeEmailResponse>('/api/2/change_email', { email, recaptchaToken }).pipe(
      switchMap((response) => response.error?.length
        ? throwError(() => ResponseFactory.error(response))
        : this.authData.discover()
      ),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  getQr(): Observable<TwoFactorSettingsDto> {
    return this.http.get<F2ASettingsResponse>('/api/2/auth/2fa/qr/code/', {}).pipe(
      map(({ _item }) => ({ secretCode: _item.otpSecret, qrCode: _item.qrCodeData })),
      catchError(err => throwError(() => ResponseFactory.error({ errors: err })))
    );
  }

  setTwoFactorAuth(code: string, state: string): Observable<unknown> {
    return this.http.post('/api/2/auth/2fa/state/change/', { state, code }).pipe(
      tap((res) => this.authData.set(res)),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }

  selfBlocking(endDate: string): Observable<unknown> {
    return this.http.post('/api/2/user/set_blocked', { blockedUntil: endDate }).pipe(
      switchMap(() => this.authData.discover()),
      catchError(err => throwError(() => ResponseFactory.error({ errors: err })))
    );
  }

  resendVerificationEmail(recaptchaToken: Nullable<string>): Observable<unknown> {
    const payload = excludeNullish({ recaptchaToken });

    return this.http.post('/api/2/auth/email/confirm/resend/', payload).pipe(
      catchError(err => throwError(() => ResponseFactory.error({ errors: err })))
    );
  }

  updateUserTruePlayBalance(balance: UserTruePlayTokenBalanceBalanceDto): void {
    this.store.update((state) => {
      return {
        balance: {
          ...state.balance as UserInfo['balance'],
          ...createTruePlayBalance(balance)
        }
      };
    });
  }

  private updateUserLocale(lang: string): (source$: Observable<UserInfo>) => Observable<UserInfo> {
    return (source$) => source$.pipe(
      switchMap((info) => this.http.put<void>('/api/2/user/', { lang }).pipe(map(() => info))),
      catchError(err => throwError(() => ResponseFactory.error(err)))
    );
  }

  private updateUserLevel(): (source$: Observable<UserInfoDto>) => Observable<UserInfo> {
    return (source$) => source$.pipe(
      switchMap((info) => this.http.get<UserLevelResponse>('/api/2/user/level/').pipe(
        map(({ _item: level }) => createUser(info, level)),
        tap((user) => this.store.update(user))
      )),
      catchError(err => throwError(() => ResponseFactory.error(err)))
    );
  }

  private setUserBalanceUpdateInterval(): (source$: Observable<UserInfo>) => Observable<UserInfo> {
    return (source$) => source$.pipe(
      switchMap((user) => interval(10000).pipe(
        switchMap(() => this.http.get<UserBalanceResponse>('/profile/user/balance/')),
        map((balance) => updateUserBalance(user, balance)),
        tap((info) => {
          this.store.update((state) => {
            const truePlayBalance = {
              truePlayTokens: state.balance?.truePlayTokens,
              truePlayBalance: state.balance?.truePlayBalance
            };
            return { balance: { ...truePlayBalance, ...info.balance } };
          });
        })
      )),
      catchError(err => throwError(() => ResponseFactory.error(err)))
    );
  }
}
