import { APP_INITIALIZER, Provider } from '@angular/core';
import { catchError, combineLatest, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { SpAuthorizationService } from './authorization.service';
import { SP_AUTHORIZATION_CONFIG } from './config';

export function renewInit(service: SpAuthorizationService): () => Observable<boolean | null> {
  return () => service.refresh().pipe(
    catchError(() => of(null)),
    tap({ next: (tokens) => service.set(tokens), error: () => service.set(null) }),
    switchMap(() => combineLatest([ service.authorized$, service.user$ ])),
    map(([ isAuthorized, user ]) => {
      return typeof isAuthorized === 'boolean' && (!isAuthorized || (isAuthorized && user !== null));
    }),
    first(Boolean)
  );
}

export const renewInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: renewInit,
  deps: [ SpAuthorizationService, SP_AUTHORIZATION_CONFIG ],
  multi: true
};
