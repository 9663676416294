import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { ControlAdapter, watch } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { CountrySelectorComponent, InputPhoneWithCountryModule, PaymentCountrySelectorComponent, StatesSelectorComponent } from '@portal/countries';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentGroupsQuery } from '@portal/payment/data';
import { FormDeclaration } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { validationRules } from '@portal/shared/constants';
import { ErrorManager } from '@portal/shared/helpers';

@Component({
  selector: 'gg-payment-generated-form',
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, InputPhoneWithCountryModule, PaymentCountrySelectorComponent, CountrySelectorComponent, StatesSelectorComponent, CurrenciesModule ],
  templateUrl: './generated-form.component.html',
  styleUrls: [ './generated-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ErrorManager ],
})
export class GeneratedFormComponent implements OnChanges, OnDestroy {
  private readonly groupsQuery = inject(PaymentGroupsQuery);
  private readonly zipCodeInfo = inject(ConfigQuery).modules.forms.zipCodeInfo;
  private readonly errorManager = inject(ErrorManager);

  private readonly watcher = watch();
  private readonly adapter: ControlAdapter<object> = new ControlAdapter(inject(NgControl, { self: true, optional: true }));

  readonly method$ = this.groupsQuery.activeMethod$;
  readonly errors: ErrorManager;

  form: FormGroup = new FormGroup({});

  @Input({ required: true }) declaration: FormDeclaration = [];

  constructor() {
    this.adapter.registerOnModelChange((value) => this.form.patchValue(value, { emitEvent: false }));
    const formChange = this.form.valueChanges.subscribe((value) => this.adapter.change(value));
    const statusChange = this.form.statusChanges.subscribe(() => {
      const hasErrors = Object.values(this.form.controls).some((control) => !!control.errors);
      this.adapter.setErrors(hasErrors ? { invalid: true } : null);
    });
    this.errors = this.errorManager.setUp({ form: this.form });
    this.watcher.add([ formChange, statusChange ]);
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  ngOnChanges({ declaration: { currentValue: declaration, firstChange } }: SimpleChanges): void {
    if (!declaration) { return; }

    firstChange ? this.createDeclaration(declaration) : this.recreateDeclaration(declaration);
  }

  private createDeclaration(declaration: FormDeclaration): void {
    declaration.forEach((control: FormDeclaration[number]) => {
      this.form.addControl(control.name, this.createDeclaredControl(control));
    });
  }

  private recreateDeclaration(declaration: FormDeclaration): void {
    const controls = Object.keys(this.form.controls);
    controls.length && controls.forEach((control) => this.form.removeControl(control));
    this.createDeclaration(declaration);
  }

  private createDeclaredControl(declaration: FormDeclaration[number]): FormControl {
    if (declaration.name === 'account' && declaration.type === 'phone') { return new FormControl(declaration.value, { validators: validationRules.phone }); }
    if (declaration.name === 'account' && declaration.type === 'email') { return new FormControl(declaration.value, { validators: validationRules.email }); }
    if (declaration.name === 'account' && declaration.type === 'card') { return new FormControl(declaration.value, { validators: validationRules.accountBankCard }); }

    if (declaration.name === 'account') { return new FormControl(declaration.value, { validators: validationRules.account }); }
    if (declaration.name === 'email') { return new FormControl(declaration.value, { validators: validationRules.email }); }
    if (declaration.name === 'firstName') { return new FormControl(declaration.value, { validators: validationRules.firstName }); }
    if (declaration.name === 'middleName') { return new FormControl(declaration.value, { validators: validationRules.middleName }); }
    if (declaration.name === 'lastName') { return new FormControl(declaration.value, { validators: validationRules.lastName }); }
    if (declaration.name === 'city') { return new FormControl(declaration.value, { validators: validationRules.city }); }
    if (declaration.name === 'phone') { return new FormControl(declaration.value, { validators: validationRules.phone }); }
    if (declaration.name === 'address') { return new FormControl(declaration.value, { validators: validationRules.address }); }
    if (declaration.name === 'documentSerial') { return new FormControl(declaration.value, { validators: validationRules.documentSerial }); }
    if (declaration.name === 'documentNumber') { return new FormControl(declaration.value, { validators: validationRules.documentNumber }); }
    if (declaration.name === 'data') { return new FormControl(declaration.value, { validators: validationRules.birthDate }); }
    if (declaration.name === 'username') { return new FormControl(declaration.value, { validators: validationRules.username }); }
    if (declaration.name === 'zip_code') { return new FormControl(declaration.value, this.getZipCodeValidators()); }
    if (declaration.name === 'expiry_month') { return new FormControl(declaration.value, { validators: validationRules.expirationMonth }); }
    if (declaration.name === 'expiry_year') { return new FormControl(declaration.value, { validators: validationRules.expirationYear }); }

    return new FormControl<unknown>(declaration.value, { validators: Validators.required });
  }

  private getZipCodeValidators(): Array<ValidatorFn> {
    const validators = [];

    validators.push(Validators.required);

    if (this.zipCodeInfo?.validators?.min) {
      validators.push(Validators.minLength(this.zipCodeInfo.validators.min));
    }
    if (this.zipCodeInfo?.validators?.max) {
      validators.push(Validators.maxLength(this.zipCodeInfo.validators.max));
    }

    return validators;
  }
}
