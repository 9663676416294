import { Query, Store } from '@datorama/akita';
import { generateStoreName } from './generate-names';
import { Steroid, SteroidOptions, SteroidProps, SteroidState } from './types';

export const createStore = <S extends SteroidState>(
  options: SteroidOptions,
  initial?: SteroidProps<S>
): Omit<Steroid<S>, 'entities'> => {
  const storeOptions: SteroidOptions = { ...options, name: generateStoreName(options.name), resettable: true };
  const store: Store<SteroidProps<S>> = new Store<SteroidProps<S>>(initial || {}, storeOptions);
  const query: Query<SteroidProps<S>> = new Query<SteroidProps<S>>(store);

  return { store, query };
};
