<section class="verification">
  <div class="verification__wrapper">
    <h4 class="verification__text" spMsg="CASHIER.VERIFICATION.PHONE"></h4>
    <div class="verification__checklist">
      <div class="verification__number">
        <span class="verification__green">0</span> / 1
        <span class="verification__ended" spMsg="CASHIER.VERIFICATION.ENDED"></span>
      </div>
      <div class="verification__options">
        <div class="verification__option">
          <div class="verification__option-title">
            <sp-msg msg="PROFILE.CONTACT_DETAILS.PHONE"/>
          </div>
          <gg-button *ngIf="!isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button">
            <sp-msg msg="CASHIER.VERIFICATION.GO_TO_VERIFICATION"/>
          </gg-button>
          <div *ngIf="isMobile" [routerLink]="['/user', 'settings', 'contact-details']" class="verification__option-button-mobile">
            <img src="assets/images/icons/plus.svg" class="verification__option-button-mobile-icon icon__plus" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
