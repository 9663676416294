import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isUrl, Nullable } from '@libs/utils';
import { LOCATION } from '@ng-web-apis/common';
import { GetDefaultCurrency } from '@portal/currencies';
import { UserQuery } from '@portal/user';
import flow from 'lodash.flow';

@Pipe({ name: 'cleanAndReplace', standalone: true })
export class CleanAndReplacePipe implements PipeTransform {

  private readonly location = inject(LOCATION) as Location;
  private readonly sanitizer = inject(DomSanitizer);
  private readonly userQuery = inject(UserQuery);
  private readonly getDefaultCurrency = inject(GetDefaultCurrency);

  transform(value: Nullable<string>): string {
    if (!value || typeof value as unknown !== 'string') { return ''; }

    value = this.cleanStyles(value);
    value = flow(this.replaceDomain.bind(this), this.replaceCurrency.bind(this), this.replaceBreakline)(value);
    value = flow(this.replaceYt, this.replaceLink, this.replaceBold, this.replaceItalic, this.replaceSup)(value);

    if (value) {
      if (isUrl(value)) { return this.sanitizer.bypassSecurityTrustResourceUrl(value) as string; }
      return this.sanitizer.bypassSecurityTrustHtml(value) as string;
    }

    return '';
  }

  private cleanStyles(value: Nullable<string>): string {
    return value?.match(/style="(.*?)"/g)?.reduce((acc, style) => {
      return (acc).replace(style, '');
    }, value) || value || '';
  }

  private replaceYt(value: string): string {
    return value?.match(/{youtube}(.*?){\/youtube}/g)?.reduce((acc, placeholder) => {
      const src = placeholder.replace(/{\/?youtube}/g, '');
      const tag = `<iframe webkitallowfullscreen mozallowfullscreen allowfullscreen src="${src.trim()}"></iframe>`;

      return acc.replace(placeholder, tag);
    }, value) || value;
  }

  private replaceLink(value: string): string {
    return value?.match(/{link}(.*?){\/link}/g)?.reduce((acc, placeholder) => {
      let tag = '';
      const [ link, description, target = 'self' ] = placeholder.replace(/{\/?link}/g, '').split('||');

      if ([ link, description, target ].every((elem) => !!elem)) {
        tag = `<a href="${link?.trim()}" target="_${target}">${description?.trim()}</a>`;
      }

      return acc.replace(placeholder, tag);
    }, value) || value;
  }

  private replaceBold(value: string): string {
    return value?.match(/{b}(.*?){\/b}/g)?.reduce((acc, placeholder) => {
      const text = placeholder.replace(/{\/?b}/g, '');
      const tag = `<strong>${text.trim()}</strong>`;

      return acc.replace(placeholder, tag);
    }, value) || value;
  }

  private replaceItalic(value: string): string {
    return value?.match(/{i}(.*?){\/i}/g)?.reduce((acc, placeholder) => {
      const text = placeholder.replace(/{\/?i}/g, '');
      const tag = `<i>${text.trim()}</i>`;

      return acc.replace(placeholder, tag);
    }, value) || value;
  }

  private replaceSup(value: string): string {
    return value?.match(/{sup}(.*?){\/sup}/g)?.reduce((acc, placeholder) => {
      const text = placeholder.replace(/{\/?sup}/g, '');
      const tag = `<sup>${text.trim()}</sup>`;

      return acc.replace(placeholder, tag);
    }, value) || value;
  }

  private replaceDomain(value: string): string {
    const regex = /{domain}/g;
    return regex.test(value) ? value.replace(regex, this.location.hostname) : value;
  }

  private replaceBreakline(value: string): string {
    const regex = /{br}/g;
    return regex.test(value) ? value.replace(regex, '<br/>') : value;
  }

  private replaceCurrency(value: string): string {
    const currencyRegex = /(?:<\/?[^>]+>)*{(.*?)currency(.*?)currency(?:<\/?[^>]+>)*}/g;
    const userCurrency = this.userQuery.currency || this.getDefaultCurrency.receive()?.name;

    return value?.replace(currencyRegex, (placeholder) => {
      const htmlTagPattern = /<\/?[^>]+(>|$)/g;

      const stringWithoutTags = placeholder.replace(htmlTagPattern, '');

      const currencies = stringWithoutTags.replace(/{\/?currency}/g, '');

      const htmlSymbolReg = /(&\w+;)/ig;

      const replaceCurSymbol = currencies.replace(htmlSymbolReg, (match) => {
        return `{htmlSymbol}${match.replace(';', '')}{htmlSymbol}`;
      }).split(';');

      const currencyMap = replaceCurSymbol.reduce((map: Record<string, string>, currency) => {
        const [ symbol, text ] = currency.split(':');

        if (symbol && text) {
          const customIconReg = /{htmlSymbol}(&\w+){htmlSymbol}/ig;

          map[ symbol.trim() ] = text.replace(customIconReg, (match) => match.replace(/{htmlSymbol}/g, '')
            .replace(/(&\w+)/ig, (matchSymbol) => `${matchSymbol};`),
          ).trim();
        }
        return map;
      }, {});

      return userCurrency ? currencyMap[ userCurrency ] : '';
    }) || '';
  }
}
