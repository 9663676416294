import { ByLanguage } from '@libs/cdk';
import { WelcomePromotionDescription } from '@portal/promotions/data/dto';
import { PromotionBanner } from '@portal/promotions/data/core/partials';

export class WelcomePromotion {
  readonly type = 'welcome';

  constructor(
    readonly i18n: ByLanguage<WelcomePromotionDescription>,
    readonly slug: string,
    readonly id: string,
    readonly banner: PromotionBanner
  ) {}
}
