// @ts-nocheck

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { BonusesQuery } from '@portal/bonuses/data';
import { DEFAULT_PAYMENT_METHOD_VIEW } from '@portal/cashier/constants/default-payment-method-view.constant';
import { CashierDataService, PaymentMethod } from '@portal/cashier/data';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CashierSections } from '../../types/enums/sections.enum';
import { PaymentMethodViewInterface } from '../../types/interfaces/payment-method-view.interface';

@Component({
  selector: 'pd-grid',
  templateUrl: './grid.component.html',
  styleUrls: [ './grid.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
  private allPaymentMethods: Array<PaymentMethod>;

  @Input() section: CashierSections = null;
  @Input() paymentMethods: Observable<Array<PaymentMethod>>;
  @Input() showBankCardMethodsButton: boolean;

  paymentMethods$: Observable<Array<PaymentMethodViewInterface & { id: number; }>>;
  showNoPaymentsMsg = false;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly bonusQuery: BonusesQuery,
    private readonly cashierCommands: CashierDataService,
    private readonly navigationService: SpNavigation,
  ) {}

  ngOnInit(): void {
    this.paymentMethods$ = this.paymentMethods.pipe(
      tap((paymentMethods) => {
        this.allPaymentMethods = paymentMethods;
        this.showNoPaymentsMsg = Array.isArray(paymentMethods) && !paymentMethods.length && !this.showBankCardMethodsButton;
        this.cd.markForCheck();
      }),
      filter((paymentMethods) => !!paymentMethods?.length),
      map((paymentMethods) => {
        return paymentMethods.reduce((accumulator, paymentMethod) => {
          const selectedBonus = this.bonusQuery.active;

          const { logo_primary: logo, logo_secondary: secondaryLogo, custom_name: name } = paymentMethod;
          let viewSettings: PaymentMethodViewInterface = DEFAULT_PAYMENT_METHOD_VIEW;

          if (logo && secondaryLogo) {
            viewSettings = { ...viewSettings, logo, secondaryLogo, name };
          }

          const {
            fee,
            fee_rate: feeRate,
            id,
            max_value,
            min_value,
            category,
            enabled_for_non_confirmed_phone,
          } = paymentMethod;

          return Number(selectedBonus?.activation.price || 0) <= Number(paymentMethod.max_value)
            ? [ ...accumulator, {
              ...viewSettings,
              fee,
              feeRate,
              id,
              max_value,
              min_value,
              category,
              enabled_for_non_confirmed_phone,
            } ]
            : [ ...accumulator ];
        }, []);
      }),
    );
  }

  setActiveMethod(id: number, showMethod: boolean): void {
    if (!showMethod) {
      this.navigationService.navigate([ 'user', 'verification', 'phone' ]);
      return;
    }
    const selectedMethod = this.allPaymentMethods.find((method) => method.id === id);
    this.cashierCommands.setPaymentMethod(selectedMethod);
    this.navigationService.navigate([ '/cashier', this.section, id.toString() ]);
  }
}
