import { GameSourceInfo } from '@portal/games/shared';

type GameSettings = {
  readonly available: boolean;
  readonly demo: boolean;
  readonly fullscreen: boolean;
  readonly ratio: string;
};

type GameInfo = {
  readonly name: string;
  readonly image: string;
  readonly background: string;
};

export class Game {
  constructor(
    readonly id: string,
    readonly gid: string,
    readonly extId: string,
    readonly slug: string,
    readonly settings: GameSettings,
    readonly source: Record<GameSourceInfo['key'], number>,
    readonly info: GameInfo,
    readonly imageAlt: string
  ) {}
}
