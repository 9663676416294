import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SpNavigation } from '@libs/cdk';
import { CashierDataService, PaymentMethodsQuery, UserBankCardQuery } from '@portal/cashier/data';
import { CashierSections } from '@portal/cashier/types/enums/sections.enum';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EXIST_CARD_VALIDATION_FORM } from './constants/exist-card-form-declaration.constant';
import { NEW_CARD_VALIDATION_FORM } from './constants/new-card-form-declaration.constant';
import { Nullable } from '@libs/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gg-card-validation',
  templateUrl: './card-validation.component.html',
  styleUrls: [ './card-validation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardValidationComponent implements OnDestroy {
  private readonly destroy$ = inject(DestroyRef);
  private bankCardSubscriptions: Subscription = new Subscription();

  readonly newCardForm: FormGroup = this.fb.group(NEW_CARD_VALIDATION_FORM);
  readonly existCardForm: FormGroup = this.fb.group(EXIST_CARD_VALIDATION_FORM);
  readonly errors: ErrorManager;
  readonly continueButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };

  loading$: Nullable<Observable<boolean>>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly errorManager: ErrorManager,
    private readonly cashierCommands: CashierDataService,
    private readonly paymentMethodsQuery: PaymentMethodsQuery,
    private readonly userCardsQuery: UserBankCardQuery,
    private readonly navigationService: SpNavigation
  ) {
    this.errors = this.errorManager.setUp({ form: this.newCardForm });
  }

  ngOnDestroy(): void {
    !!this.bankCardSubscriptions && this.bankCardSubscriptions.unsubscribe();
  }

  validateBankCard(): void {
    if (this.newCardForm.invalid && this.existCardForm.invalid) { return; }

    this.loading$ = this.paymentMethodsQuery.loading$;

    if (this.newCardForm.valid) {
      let account: string;
      const newBankCardSubscription = this.cashierCommands.addUserBankCards(this.newCardForm.value).pipe(
        tap(() => account = this.userCardsQuery.newCardId),
        switchMap(async () => this.getPayments(account)),
        takeUntilDestroyed(this.destroy$)
      ).subscribe();
      this.bankCardSubscriptions.add(newBankCardSubscription);
    }
    else if (this.existCardForm.valid) {
      const account = this.existCardForm.get('selectedCard')?.value;
      this.getPayments(account);
    }
  }

  private getPayments(account: string): void {
    const bankCardMethodsSubscription = this.cashierCommands.getPaymentsMethods(account).pipe(
      switchMap(() => this.cashierCommands.getUserCardInfo(account)),
      takeUntilDestroyed(this.destroy$)
    ).subscribe(() => this.navigateToBankCardMethods());
    this.bankCardSubscriptions.add(bankCardMethodsSubscription);
  }

  private navigateToBankCardMethods(): void {
    this.navigationService.navigate([ 'cashier', CashierSections.Deposit, 'bank-cards' ]);
  }
}
