<section class="transfers">
  <div class="wrapper wrapper--success">
    <div class="header">
      <div class="left">
        <gg-button-back (click)="backPage()"></gg-button-back>
      </div>
      <div class="middle">
        <img class="img" src="assets/images/icons/ok.svg" alt=""/>
        <div class="title" spMsg="CASHIER.STATUS.REQUEST_ACCEPTED"></div>
      </div>
      <div class="right"></div>
    </div>
    <p class="text">
      <sp-msg spMsg="CASHIER.STATUS.REQUEST_ACCEPTED_AND_PROCESSED" spaces="end"></sp-msg>
      <span><sp-msg spMsg="CASHIER.STATUS.REQUEST_24"></sp-msg></span>
    </p>
    <div class="footer" *ngIf="transferInfo$ | async as info">
      <p class="text text--green">
        <sp-msg spMsg="CASHIER.TRANSFERS.SUM"></sp-msg>:
        <span>{{info.amount}} {{userCurrency$ | async | currencySymbol}}</span>
      </p>
      <p class="text text--black">
        <sp-msg spMsg="CASHIER.TRANSFERS.RECIPIENT"></sp-msg>:
        <span>{{info.nick}}</span></p>
    </div>
  </div>
</section>
