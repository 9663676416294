import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { passOnly } from '@libs/authorization';
import { accessBonusGuard, loadBonusesGuard } from '@portal/bonuses/shared';
import { CodeComponent } from './code/code.component';
import { EmptyComponent } from './empty/empty.component';
import { ListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: '', children: [
      { path: '', component: ListComponent, canActivate: [ passOnly('authorized', [ '/auth/login' ]), loadBonusesGuard ] },
      { path: 'promo', component: CodeComponent, canActivate: [ passOnly('authorized', [ '/auth/login' ], '/bonuses/promo') ] },
      { path: 'empty', component: EmptyComponent, canActivate: [ passOnly('authorized', [ '/auth/login' ]) ] }
    ],
    runGuardsAndResolvers: 'always',
    canActivate: [ passOnly('authorized', [ '/auth', 'login' ], '/bonuses'), accessBonusGuard ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class BonusesRouting {}
