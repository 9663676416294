import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { CountriesStore, Country, createCountryFromReceived, createStateSelectorOptions } from '@portal/countries/data';
import {
  CountriesListForPaymentsResponse,
  CountriesListResponse,
  StatesListResponse,
  UserCountryResponse
} from '@portal/countries/shared';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { ResponseFactory } from '@portal/shared/helpers';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CountriesData {
  private readonly http = inject(HttpClient);
  private readonly currentLang = inject(SpLocalization).currentLanguage;
  private readonly store = inject(CountriesStore);

  synchronize(): void {
    forkJoin([
      this.http.get<UserCountryResponse>('/api/2/user/country/'),
      this.http.get<CountriesListResponse>(`/api/2/country/info/${this.currentLang}`)
    ]).pipe(
      tap(([ { _item: { countryCode } }, { _items } ]) => {
        const country = _items.find(c => c.isoCode === countryCode)?.isoCode || _items[0].isoCode;
        this.store.update({ country, countries: _items.map(createCountryFromReceived) })
      })
    ).subscribe(() => this.store.update({ sync: true }));
  }

  getCountryStatesList(countryCode: Country['isoCode']): Observable<Array<IInputSelectData>> {
    return this.http.get<StatesListResponse>(`/api/2/country/${countryCode.toUpperCase()}/states`).pipe(
      map(({ _item: states }) => createStateSelectorOptions(states)),
      catchError((error) => throwError(() => ResponseFactory.error(error)))
    );
  }

  loadCountriesListForPayments(paymentMethodId: Nullable<string>): Observable<Array<string>> {
    return this.http.get<CountriesListForPaymentsResponse>(`/api/payments/countries/list/${paymentMethodId}`).pipe(
      map((countriesList) => countriesList.countries),
      catchError(error => throwError(() => ResponseFactory.error(error)))
    );
  }
}
