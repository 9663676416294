<section class="p2p-action">
  <gg-payment-p2p-card [card]="cardData" [timer]="timer$ | spAsync"></gg-payment-p2p-card>
  <gg-payment-summary [initAmount]="cardData?.initAmount"/>
  <gg-button
    class="p2p-action__button"
    [settings]="submitButton"
    [fullWidth]="true"
    (click)="approvePayment()"
  ><sp-msg spMsg="CASHIER.CARD.CONFIRM_TRANSFER"/></gg-button>
  <gg-button
    class="p2p-action__button"
    [settings]="cancelButton"
    [fullWidth]="true"
    (click)="resetMethod()"
  ><sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.CANCEL"/></gg-button>
</section>
