import { inject, Injectable } from '@angular/core';
import { SpPlatform, SpStorage } from '@libs/cdk';
import { GlobalWindow, Nullable } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { PWA_AVAILABLE_COMBINATION, PWACheckOptions } from '@portal/banners/shared';
import { StorageKey } from '@portal/shared/types';
import { UserQuery } from '@portal/user';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

interface PWAEvent extends Event {
  prompt(): void;
}

type OPRWindow = { opr: unknown; opera: unknown }

@Injectable({ providedIn: 'root' })
export class PWAService {
  private installPrompt!: Nullable<PWAEvent>;
  private readonly window = inject<GlobalWindow<OPRWindow>>(WINDOW);
  private readonly storage = inject(SpStorage);
  private readonly platform = inject(SpPlatform);
  private readonly userQuery = inject(UserQuery);

  get inPWA(): boolean { return this.platform.browser && this.window.matchMedia('(display-mode: standalone)').matches; }

  get inOpera(): boolean { return !!this.window.opr || !!this.window.opera || this.platform.currentBrowser === 'opera'; }

  get canInstall(): boolean { return !!this.installPrompt?.prompt; }

  constructor() {
    this.window.addEventListener('beforeinstallprompt' as keyof WindowEventMap, (event: Event) => {
      event.preventDefault();
      this.installPrompt = event as PWAEvent;
    });
  }

  install(): void {
    this.installPrompt?.prompt();
  }

  isAvailable({ storage, bets }: PWACheckOptions): Observable<boolean> {
    return this.userQuery.isSpentMoney$.pipe(
      first(),
      map(isSpentMoney => {
        const currentTime = new Date().getTime();
        const conditions = [
          !this.inPWA,
          !this.inOpera,
          !this.platform.device.android,
          !storage || currentTime > Number(this.storage.getItem(StorageKey.PWA)),
          !bets || Boolean(isSpentMoney),
          PWA_AVAILABLE_COMBINATION.includes(this.platform.userPlatformInfo),
        ];
        return conditions.every(Boolean);
      }),
    );
  }
}
