import { inject, Injectable } from '@angular/core';
import { OrArray } from '@libs/utils';
import { Breadcrumb, createBreadcrumb, createBreadcrumbsFrom, LayoutStore } from '@portal/layout/data';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsData {
  private readonly store = inject(LayoutStore);

  add(breadcrumb: Breadcrumb | { label: string; link?: Array<string> }): void {
    if (this.isBreadcrumbAlreadyExists(breadcrumb.label)) { return; }

    breadcrumb = breadcrumb instanceof Breadcrumb ? breadcrumb : createBreadcrumb(breadcrumb.label, breadcrumb.link);

    this.store.update({ breadcrumbs: [ ...this.store.get('breadcrumbs'), breadcrumb ] });
  }

  set(breadcrumbs: OrArray<string>): void {
    this.store.update({ breadcrumbs: createBreadcrumbsFrom(breadcrumbs) });
  }

  private isBreadcrumbAlreadyExists(label: string): boolean {
    const existingBreadcrumbs = this.store.get('breadcrumbs');
    const breadcrumbsCount = existingBreadcrumbs.length;

    if (!breadcrumbsCount) { return false; }

    return existingBreadcrumbs[ breadcrumbsCount - 1 ].label === label;
  }
}
