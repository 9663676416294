import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getFromWindow } from '@libs/utils';
import { BrowserTracing, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';

const sentry = getFromWindow<{ host: string; project: string; enabled: boolean }>('logger');

sentry?.enabled && init({
  dsn: sentry.host,
  environment: sentry.project,
  integrations: [ new BrowserTracing({ routingInstrumentation: instrumentAngularRouting }) ],
  attachStacktrace: true,
  tracesSampleRate: 1
});

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: false })
  .catch((err) => console.error(err));
