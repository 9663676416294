<div class="bank-card-input" *ngIf="form">
  <form [formGroup]="form" class="bank-card-input__form-wrapper">
    <div class="bank-card-input__new-card-data">
      <gg-input noLabelPlaceholder='true' formControlName="account" class="bank-card-input__account"
                [defaultPlaceholder]="'CASHIER.CARD.CARD_NUMBER' | translate" mask="0000 0000 0000 0000 0000"/>
      <gg-input noLabelPlaceholder='true' formControlName="holder" class="bank-card-input__card-holder-name"
                [defaultPlaceholder]="'CASHIER.CARD.CARD_HOLDER_NAME' | translate"/>
    </div>
    <div class="bank-card-input__exp-date">
      <div class="bank-card-input__exp-date-inputs">
        <gg-input-card-month class="bank-card-input__exp-date" formControlName="expMonth"/>
        <span class="bank-card-input__exp-date-inputs-divider">/</span>
        <gg-input-card-year class="bank-card-input__exp-date" formControlName="expYear"/>
      </div>
      <gg-input-card-cvv class="bank-card-input__cvv" formControlName="cvv"/>
    </div>
  </form>
  <gg-button class="bank-card-input__button" *ngIf="isExistButtonShown" [settings]="cancelButton" (click)="reset()">
    <sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.CANCEL"/>
  </gg-button>
</div>
