import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [ SpCDKModule, RouterModule, ControlsModule ],
  templateUrl: './deposit-verification.component.html',
  styleUrl: './deposit-verification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositVerificationComponent {
  readonly dialog = inject(Dialog);
  readonly theme = inject(ConfigQuery).project.name || '';
}
