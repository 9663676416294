import { ChangeDetectionStrategy, Component, inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CashierDataService, CashierQuery, HistoryBySection, HistorySections } from '@portal/cashier/data';
import { TransactionsHistory } from '@portal/cashier/data/entities/history';
import { ConfigQuery } from '@portal/config';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pd-history',
  templateUrl: './history.component.html',
  styleUrls: [ './history.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HistoryComponent implements OnDestroy {
  private readonly source = inject(ConfigQuery).modules;
  private readonly watchers: Subscription = new Subscription();

  readonly section$: BehaviorSubject<HistorySections> = new BehaviorSubject(HistorySections.All as HistorySections);
  readonly operations$: Observable<HistoryBySection> = this.cashierQuery.history$;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly cashierCommands: CashierDataService,
    private readonly cashierQuery: CashierQuery,
  ) {
    const paramsSubs = this.activatedRoute.params.pipe(
      map(({ section }) => section)).subscribe(section => {
      this.section$.next(section);
      const historySubs = this.cashierCommands.loadHistory(section, this.source.cashier.showTransactionsAfter).subscribe();
      this.watchers.add(historySubs);
    });
    this.watchers.add(paramsSubs);
  }

  cancel(operation: TransactionsHistory): void {
    const cashierCommandsSub = this.cashierCommands.cancelHistoryOperation(
      operation,
      this.section$.getValue(),
      this.source?.cashier.showTransactionsAfter,
    ).subscribe();

    this.watchers.add(cashierCommandsSub);
  }

  ngOnDestroy(): void {
    !!this.watchers && this.watchers.unsubscribe();
  }
}
