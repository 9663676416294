import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';
import { CardMaskTransformPipe } from '@portal/shared/pipes';
import { CardChangeComponent } from './card-change';
import { CardDeleteComponent } from './card-delete';
import { CardDetailsComponent } from './card-details';
import { CardOptionsComponent } from './card-options';
import { NewCardComponent } from './new-card';

const components = [ CardChangeComponent, CardDeleteComponent, CardDetailsComponent, CardOptionsComponent, NewCardComponent ];

@NgModule({
  imports: [ SpCDKModule, ControlsModule, CardMaskTransformPipe ],
  declarations: [ components ],
  exports: [ components ]
})
export class CardComponentsModule {}
