import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject, OnDestroy } from '@angular/core';
import { CardPartsModule } from '@portal/bonuses/components';
import { BonusPipesModule } from '@portal/bonuses/shared';

@Component({
  standalone: true,
  templateUrl: './bonus-card-modal.component.html',
  imports: [
    CardPartsModule, BonusPipesModule
  ],
  styleUrls: [ './bonus-card-modal.component.scss' ]
})
export class BonusCardModalComponent implements OnDestroy {
  private readonly dialog = inject(DialogRef);
  private readonly data = inject(DIALOG_DATA);
  readonly bonus = this.data.bonus;
  readonly isWelcomeBonus = this.data.isWelcomeBonus;

  closePopup(): void {
    this.dialog.close();
  }

  ngOnDestroy(): void {
    this.closePopup();
  }
}
