import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { RecaptchaConfig, RecaptchaState, RecaptchaStore } from '@portal/recaptcha/data';
import { RecaptchaSection } from '@portal/recaptcha/shared';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RecaptchaQuery extends SteroidQuery<RecaptchaState> {
  readonly synchronized$: Observable<boolean> = this.select('sync');

  get recaptcha(): Nullable<RecaptchaConfig> { return this.get('config'); }

  get recaptchaKey(): Nullable<RecaptchaConfig['key']> { return this.recaptcha?.key; }

  constructor(store: RecaptchaStore) { super(store); }

  isRecaptchaEnabledFor(section: RecaptchaSection): boolean {
    return Boolean(this.recaptcha?.config[section]);
  }
}
