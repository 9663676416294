import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpNotification } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import { UserData } from '@portal/user/data';
import { createPersonalInfoForm, PersonalInfo } from '@portal/user/shared';
import { combineLatest, startWith, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'gg-user-settings-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: [ './personal-info.component.scss', './purple.personal-info.component.scss', './silver.personal-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ErrorManager ]
})
export class PersonalInfoComponent implements OnDestroy {
  private readonly errorManager = inject(ErrorManager);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly userSettingsCommands = inject(UserData);
  private readonly notifications = inject(SpNotification);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly watchers: Subscription = new Subscription();
  private isSubmitted = false;
  readonly errors: ErrorManager;
  readonly saveButton: IButton = { theme: ButtonThemes.Green, size: ButtonSizes.Large };
  readonly form: FormGroup = createPersonalInfoForm();
  userId: Nullable<string> = '';
  isEditSection: Record<string, boolean> = {
    first_name: false,
    last_name: false
  };

  constructor() {
    this.errors = this.errorManager.setUp({ form: this.form });
    this.initUserData();
    this.checkFormTouched();
  }

  isInitialForm(): boolean {
    return !this.isSubmitted;
  }

  showEditContainer(section: string): void {
    this.isEditSection[section] = true;
  }

  save(section?: string): void {
    if (this.form.invalid) return;

    const userSettingsCommandsSub = this.userSettingsCommands.updatePersonalInfo(this.form.getRawValue()).subscribe({
      next: () => {
        this.notifications.success('NOTIFICATIONS.MESSAGES.SETTINGS_INFO_CHANGED');
        this.isSubmitted = true;
        this.initUserData();
        if (section) {
          this.isEditSection[section] = false;
        }

        this.cd.detectChanges();
      },
      error: (err) => {
        this.notifications.error('NOTIFICATIONS.MESSAGES.SETTINGS_INFO_CHANGE_FAIL');
        this.errors.setServerErrors(err);
      }
    });
    this.watchers.add(userSettingsCommandsSub);
  }

  ngOnDestroy(): void {
    !!this.watchers && this.watchers.unsubscribe();
  }

  private initUserData(): void {
    const changeSubs: Subscription = combineLatest([
      this.userInfoQuery.personal$,
      this.userInfoQuery.id$
    ]).pipe(first()).subscribe(([ personal, userId ]) => {
      this.userId = userId;

      const userInfo: PersonalInfo = {
        first_name: personal?.firstName,
        last_name: personal?.lastName,
        nick: personal?.nick
      };

      if (userInfo.nick && !personal?.mustChangeNick) {
        this.form.controls['nick'].disable();
      }

      this.form.setValue(userInfo);
      this.isSubmitted = this.form.valid;
    });

    this.watchers.add(changeSubs);
  }

  private checkFormTouched(): void {
   const formTouchedSubs =  this.form.statusChanges.pipe(startWith(this.form.status)).subscribe((status) =>
      status === 'INVALID' ? this.form.markAllAsTouched() : null
    );

    this.watchers.add(formTouchedSubs);
  }
}
