import { DeepRequired } from '@libs/utils';
import { ConfigDto } from '@portal/config/data';

export const placeholder = {
  config: {
    project: '',
    theme: { structure: 'rounded', palette: 'silver' },
    source: '',
    localization: { showLocalePopup: false, list: [], default: '' },
  },
  modules: {
    recaptcha: {
      key: '',
      recoveryByEmail: false,
      login: false,
      regByPhone: false,
      recoveryByPhone: false,
      regByEmail: false,
      userProfileEmail: false,
      userProfilePhone: false,
    },
    header: {
      mobileNavigation: [
        {
          url: '',
          text: '',
          activeOptions: { exact: false },
        },
      ],
    },
    analytics: {
      trackers: {
        gaId: '',
        ga4Id: '',
        hjId: 0,
        yndxMetrikId: 0,
        fbPixel: '',
        gtmId: '',
      },
    },
    sections: {
      casino: {
        tournamentFrameUrl: ''
      }
    },
    contacts: {
      support: '',
      supportChatInSidebar: false,
      social: [],
      verbox: {
        domain: '',
        scriptLink: '',
      },
      liveChat: {
        id: '',
      },
    },
    cashier: {
      depositCardPrevalidation: false,
      enableStubForCryptocurrency: false,
      enableHistoryTab: false,
      enableHistoryCardInfo: false,
      showTransactionsAfter: '',
      newBankCardPDF: '',
      availableSections: [],
      tranzooWidgetAvailable: false,
      tranzooWidgetUrl: '',
      popularPaymentMethod: [],
      internationalPaymentMethod: [],
      recommendedPaymentMethod: {
        id: 0,
        title: '',
      },
      operationAgreementCheckbox: false,
      verificationParams: [],
      buttonsOnStatusPage: '',
    },
    footer: {
      license: {
        ageWarningShield: 0,
        generalTerms: [],
        licenses: {
          curacao: {
            iconLink: '',
            pageLink: '',
          },
          gamingLabs: '',
          iTech: '',
        },
        rtp: '',
      },
      menu: {
        list: [],
      },
      security: {
        title: '',
        list: [],
      },
      contacts: [],
      paymentSystems: [],
      providers: [],
      timer: false,
    },
    forms: {
      zipCodeInfo: {
        infoTextKey: '',
        mask: '',
        validators: {
          max: 0,
          min: 0,
          required: false,
        },
      },
      enableMobilePhone: false,
      isComplicatedPassword: false,
      checkboxes: {
        ontario: { show: false, name: '', checked: false, required: true },
        adult: { show: false, name: '', checked: true, required: true },
        privacyPolicy: { show: false, name: '', checked: true, required: true },
        newsSubscription: { show: false, name: '', checked: true, required: false },
      },
    },
    sidebar: {
      menu: [
        {
          hidden: false,
          hasSeparator: false,
          items: [
            { url: '', image: '', icon: '', label: '', key: '', hidden: false, amount: 0, activeOptions: { exact: false } },
          ],
        },
      ],
      isLightBalance: false,
    },
    toolbar: {
      menu: [
        { url: '', image: '', icon: '', label: '', key: '', hidden: false, amount: 0, activeOptions: { exact: false } },
      ],
    },
    verification: {
      enablePaymentConfirmation: false,
      enablePromotionFilters: false,
    },
    bonuses: {
      enableWelcomeBonusFooter: false,
      strokeColor: '',
    },
    games: {
      sectionSortedIconPath: '',
    },
    images: {
      progressImage: '',
    },
    access: {
      blockedSectionRedirectionPaths: {
        slots: '',
        lobby: '',
        bonus: '',
        deposit: '',
        withdrawal: '',
      },
    },
    authentication: {
      requestAdditionalRegistrationData: false,
      showEmailConfirmation: false,
      isOntarioConsent: false,
      mailRegNoDepPopup: false,
      ads: {
        regByPhonePromoUrl: '',
      },
      registrationMethods: [],
      phoneVerificationMethod: '',
    },
    settings: {
      enableYa2fa: false,
      projectAddress: '',
    },
  },
} as unknown as DeepRequired<ConfigDto>;
