import { Component, forwardRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-zip-code',
  templateUrl: './input-zip-code.component.html',
  styleUrls: [ './input-zip-code.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputZipCodeComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputZipCodeComponent extends InputAbstract implements OnInit {
  private readonly zipInfo = inject(ConfigQuery).modules.forms.zipCodeInfo;

  infoTextKey: Nullable<string>;
  showInfoText: boolean = false;

  override ngOnInit(): void {
    const { mask, infoTextKey } = this.zipInfo;
    this.showInfoText = Boolean(mask);

    if (!this.showInfoText) { return; }

    this.mask = mask;
    this.infoTextKey = infoTextKey;
  }
}
