import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-login',
  templateUrl: './input-login.component.html',
  styleUrls: [ './input-login.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputLoginComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputLoginComponent extends InputAbstract {

  override clear(): void { this.writeValue(''); }
}
