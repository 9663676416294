import { Nullable } from '@libs/utils';
import {
  Bonus,
  BonusCasinoCash,
  BonusCasinoCashback,
  BonusCasinoCashbackExternal,
  BonusCasinoFreebet,
  BonusCasinoFreespin,
  BonusCasinoReload,
  BonusCasinoWelcome,
  BonusContainerWelcome,
  BonusDto,
  BonusPokerReload
} from '@portal/bonuses/data';

type BonusesByTypes = Partial<Record<BonusDto['type'], Array<BonusDto>>>;

const createBonus = (bonus: BonusDto): Nullable<Bonus> => {
  if (bonus.type === 'casino_cashback') { return new BonusCasinoCashback(bonus); }
  if (bonus.type === 'casino_cashback_external') { return new BonusCasinoCashbackExternal(bonus); }
  if (bonus.type === 'casino_freespin') { return new BonusCasinoFreespin(bonus); }
  if (bonus.type === 'casino_welcome') { return new BonusCasinoWelcome(bonus); }
  if (bonus.type === 'casino_reload') { return new BonusCasinoReload(bonus); }
  if (bonus.type === 'casino_linked') { return new BonusContainerWelcome(bonus); }
  if (bonus.type === 'poker_reload') { return new BonusPokerReload(bonus); }
  if (bonus.type === 'casino_cash') { return new BonusCasinoCash(bonus); }
  if (bonus.type === 'sportsbook') { return new BonusCasinoFreebet(bonus); }

  return null;
};

export const createBonuses = (bonuses: Array<BonusDto>): Array<Bonus> => {
  return bonuses.map(createBonus).filter((b): b is Bonus => Boolean(b));
};

export const createBonusesOfType = (input: BonusesByTypes): Nullable<Array<Bonus>> => {
  if (!input) { return null; }

  return Object.values(input).reduce((acc: Array<Bonus>, bonuses) => {
    return acc.concat(createBonuses(bonuses));
  }, []);
};

