import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { MobileContainerNavComponent } from '@portal/payment/components';
import { PaymentGroupsQuery, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { PaymentContainerType } from '@portal/payment/shared/types/payment-container-type';
import { ContentModule } from '@portal/shared/components';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-payment-containers-navigation',
  templateUrl: './containers-navigation.component.html',
  styleUrls: [ './containers-navigation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ContentModule, MobileContainerNavComponent, NgScrollbar ],
})
export class ContainersNavigationComponent {
  private readonly groupsQuery = inject(PaymentGroupsQuery);
  private readonly paramsData = inject(PaymentParamsData);

  protected readonly isMobileDevice$ = inject(BreakpointObserver).observe('(max-width: 767px)').pipe(map(({ matches }) => matches));
  protected readonly isDeposit$ = inject(PaymentParamsQuery).isDeposit$;

  readonly containers$ = this.groupsQuery.containers$;
  readonly availableContainers$ = this.createContainerList();
  readonly active$ = this.groupsQuery.container$.pipe(map((group) => group?.id));

  select(container: PaymentContainerType['id']): void {
    this.paramsData.setPayment({
      container,
      group: undefined,
      method: undefined,
      isContainerSelected: true,
    });
  }

  private createContainerList(): Observable<Array<PaymentContainerType>> {
    return this.containers$.pipe(
      distinctUntilChanged(),
      map((containers) => {
        return containers.map(({ id, name, icon }) => {
          return { name, id, icon };
        });
      }),
    );
  }
}

