<main class="profile-settings">
    <div class="profile-settings__wrapper">
        <div class="profile-settings__profile-notification">
          <gg-banner-socket page="profile" section="notification"></gg-banner-socket>
        </div>
        <h1 *ngIf="true" class="profile-settings__title">
            <sp-msg msg="PROFILE.PROFILE_SETTINGS"></sp-msg>
        </h1>
        <gg-accordion class="profile-settings__accordion">
              <gg-accordion-expansion-panel
                [id]="'personal-info'"
                linkToRoute="personal-info"
                titleInfo="PROFILE.PERSONAL_INFO.TITLE"
                [titleIcon]="'{project}/images/profile-settings/user-setting.svg' | path"
                [isExpanded]="urlSection === 'personal-info'"
                (expandChanged)="scrollToItem('personal-info')"
              >
                  <gg-user-settings-personal-info></gg-user-settings-personal-info>
              </gg-accordion-expansion-panel>
              <p class="profile-settings__accordion-section" spMsg="PROFILE.VERIFICATION"></p>
              <gg-accordion-expansion-panel
                [id]="'contact-details'"
                linkToRoute="contact-details"
                [titleIcon]="'{project}/images/profile-settings/email-setting.svg' | path"
                titleInfo="PROFILE.CONTACT_DETAILS.TITLE"
                [isExpanded]="urlSection === 'contact-details'"
                (expandChanged)="scrollToItem('contact-details')"
              >
                  <gg-user-settings-contact-details></gg-user-settings-contact-details>
              </gg-accordion-expansion-panel>
              <p class="profile-settings__accordion-section" spMsg="PROFILE.SAFETY"></p>
              <gg-accordion-expansion-panel
                [id]="'two-factor-auth'"
                linkToRoute="two-factor-auth"
                [titleIcon]="'{project}/images/profile-settings/shield-setting.svg' | path"
                titleInfo="PROFILE.TWO_FACTOR_AUTH.TITLE"
                [isExpanded]="urlSection === 'two-factor-auth'"
                (expandChanged)="scrollToItem('two-factor-auth')"
              >
                  <gg-user-settings-two-factor-authentication></gg-user-settings-two-factor-authentication>
              </gg-accordion-expansion-panel>
              <gg-accordion-expansion-panel
                [id]="'change-password'"
                linkToRoute="change-password"
                titleInfo="PROFILE.CHANGE_PASSWORD.TITLE"
                [titleIcon]="'{project}/images/profile-settings/lock-setting.svg' | path"
                [isExpanded]="urlSection === 'change-password'"
                (expandChanged)="scrollToItem('change-password')"
              >
                  <gg-user-settings-change-password></gg-user-settings-change-password>
              </gg-accordion-expansion-panel>
              <p class="profile-settings__accordion-section" spMsg="PROFILE.GAME_SETTINGS"></p>
              <gg-accordion-expansion-panel
                [id]="'responsible-gaming'"
                linkToRoute="responsible-gaming"
                titleInfo="PROFILE.RESPONSIBLE_GAMING.TITLE"
                [titleIcon]="'{project}/images/profile-settings/heart-setting.svg' | path"
                [isExpanded]="urlSection === 'responsible-gaming'"
                (expandChanged)="scrollToItem('responsible-gaming')"
              >
                <gg-user-settings-responsible-gaming></gg-user-settings-responsible-gaming>
              </gg-accordion-expansion-panel>
        </gg-accordion>
    </div>
</main>
