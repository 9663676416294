import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CroppedCardComponent } from '@portal/bonuses/components';
import { BonusPipesModule } from '@portal/bonuses/shared';
import { ThemeModule } from '@portal/config';
import { ContentModule } from '@portal/shared/components';
import { BlockBonusesComponent } from "@portal/bonuses/components/block-bonuses/block-bonuses.component";
import { SliderBonusesComponent } from './slider-bonuses.component';

@NgModule({
  imports: [ SpCDKModule, BonusPipesModule, CroppedCardComponent, ThemeModule, ContentModule, BlockBonusesComponent ],
  declarations: [ SliderBonusesComponent ],
  exports: [ SliderBonusesComponent ]
})
export class SliderBonusesModule {}
