import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, UnixTimer } from '@libs/utils';
import { CurrencyNormalizerPipe } from '@portal/currencies/application';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { MomentModule } from 'ngx-moment';
import { filter, Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, MomentModule ],
  templateUrl: './p2p-lifetime-dialog.component.html',
  styleUrl: './p2p-lifetime-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pLifetimeDialogComponent implements OnInit {
  private readonly dialog = inject(DialogRef);
  private readonly currencyNormalizer = inject(CurrencyNormalizerPipe);
  private readonly data = inject(DIALOG_DATA);
  private readonly destroy = inject(DestroyRef);

  readonly timer$: Nullable<Observable<UnixTimer>> = this.data.timer;
  readonly okButton: IButton = { size: ButtonSizes.Medium };

  readonly summary = this.data.amount && this.currencyNormalizer.transform(this.data.amount);

  ngOnInit(): void {
    this.timer$?.pipe(
      filter(timer => timer.expired),
      takeUntilDestroyed(this.destroy)
    ).subscribe(() => this.close());
  }

  close(): void { this.dialog.close(); }
}
