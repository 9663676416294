import { inject, Injectable, OnDestroy } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { GameProvider, GamesQuery } from '@portal/games/data';
import { SeoData, SeoType } from '@portal/seo';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GameProvidersService implements OnDestroy {
  private readonly gamesQuery = inject(GamesQuery);
  private readonly navigation = inject(SpNavigation);
  private readonly seoData = inject(SeoData);

  private readonly isBlockVisible: BehaviorSubject<Nullable<boolean>> = new BehaviorSubject<Nullable<boolean>>(false);
  private activeProvider: GameProvider['key'] | null = null;

  readonly isBlockVisible$: Observable<Nullable<boolean>> = this.isBlockVisible.asObservable();
  readonly list$: Observable<Array<GameProvider>> = this.gamesQuery.providers$;

  ngOnDestroy(): void { this.isBlockVisible.complete(); }

  isActive({ key }: GameProvider): boolean { return this.activeProvider === key; }

  select({ key }: GameProvider): void {
    this.activeProvider = key;
    this.seoData.load(SeoType.CasinoProvider, key);
    this.navigation.navigate([ key ]).then(() => this.toggleBlockVisibility(false));
  }

  private toggleBlockVisibility(state?: boolean): void {
    this.isBlockVisible.next(state);
  }
}
