import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SpStorage } from '@libs/cdk';
import { first, map, Observable, tap, withLatestFrom } from 'rxjs';
import { AuthorizationCheck } from './authorization-check.type';
import { SpAuthorizationService } from './authorization.service';
import { SP_AUTHORIZATION_CONFIG } from './config';

export function passOnly(check: AuthorizationCheck, restrict: Array<string>, returnPath?: string): () => Observable<true | UrlTree> {
  return () => {
    const router = inject(Router);
    const service = inject(SpAuthorizationService);
    const storage = inject(SpStorage);
    const config = inject(SP_AUTHORIZATION_CONFIG);

    const stream$ = config.checkBy === 'authorization'
      ? service.authorized$
      : service.user$.pipe(withLatestFrom(service.authorized$), first(([ u, a ]) => !a || (a && !!u)), map(([ user ]) => Boolean(user)));

    return stream$.pipe(
      map((state) => (check === 'authorized' && state) || (check === 'anonymous' && !state)),
      tap((state) => !state && returnPath && config.returnKey && storage.setItem(config.returnKey, returnPath)),
      map((state) => state || router.createUrlTree(restrict))
    );
  };
}
