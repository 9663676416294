import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ActivatedBonus, BonusesData } from '@portal/bonuses/data';
import { createPromoCodeSubmitForm } from '@portal/bonuses/shared';
import { ErrorManager } from '@portal/shared/helpers';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './code.component.html',
  styleUrls: [ './code.component.scss' ],
  providers: [ ErrorManager ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeComponent {
  private readonly bonusesData = inject(BonusesData);
  private readonly navigation = inject(SpNavigation);
  readonly form = createPromoCodeSubmitForm();
  readonly errors: ErrorManager = inject(ErrorManager).setUp({ form: this.form });

  activatedBonus: Nullable<ActivatedBonus> = null;

  sendCode(): void {
    if (this.form.invalid) { return; }

    const { promoCode } = this.form.value;

    if (!promoCode) { return; }

    this.bonusesData.activatePromoCode(promoCode).pipe(
      tap((activatedBonus) => this.activatedBonus = activatedBonus)
    ).subscribe({
      next: (redirect) => { redirect && this.returnToBonusesList(); },
      error: (error) => { this.errors.setServerErrors(error); }
    });
  }

  returnToBonusesList(): void { this.navigation.navigate([ '/bonuses' ]);}
}
