// @ts-nocheck

import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input
} from '@angular/core';
import { ConfigQuery } from '@portal/config';
import { CashierSectionInterface } from '../../types/interfaces/section.interface';

@Component({
  selector: 'pd-cashier-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements AfterContentChecked {
  @Input() active: string;
  @Input() sections: Array<CashierSectionInterface>;

  hasStubForCryptocurrency = false;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly source: ConfigQuery,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngAfterContentChecked(): void {
    if (this.source.modules.cashier.enableStubForCryptocurrency) {
      this.hasStubForCryptocurrency = !!this.document.getElementById('stubBlock');
      this.cd.detectChanges();
    }
  }

  isActive(sectionValue: string): boolean {
    return this.active === sectionValue;
  }
}
