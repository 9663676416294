<div class="games-access" *spLet="isSelfBlocked$ | async as selfBlocked">
    <div class="info__title">
        <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.SELF_EXCLUDING"></sp-msg>
    </div>
    <div class="info__text">
        <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.PARAGRAPH_1"></sp-msg>
    </div>
    <div class="info__text">
        <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.PARAGRAPH_2"></sp-msg>
    </div>
    <ng-container *ngIf="!selfBlocked">
        <div class="info__text">
            <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.PARAGRAPH_3"></sp-msg>
        </div>
        <div class="info__text">
            <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.PARAGRAPH_4"></sp-msg>
        </div>
    </ng-container>
    <div class="info__blocks" [formGroup]="form">
        <div *ngIf="!selfBlocked" class="info__blocks-wrapper">
            <div class="info__blocks-title">
                <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.CHOSE_PERIOD"></sp-msg>
            </div>
            <div class="info__blocks-select">
                <gg-input-select
                  [options]="blockingPeriods"
                  formControlName="period"
                  [defaultOption]="blockingPeriodsDefault"
                ></gg-input-select>
            </div>
            <gg-button [settings]="greenButton" (click)="selfBlocking()" [disabled]="!form.valid" [fullWidth]="true">
                <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.BLOCK"></sp-msg>
            </gg-button>
        </div>
        <ng-container *ngIf="selfBlocked">
      <span class="games-access__blocked-title">
        <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.BLOCKED"></sp-msg>
      </span>
            <div *ngIf="!showNotification" class="games-access__blocked-timer">
                <gg-timer header="PROFILE.RESPONSIBLE_GAMING.TIMER" [expiredDate]="selfBlockedTimer | async"
                          (expired)="updateBlockingStatus()"></gg-timer>
            </div>
            <div *ngIf="showNotification" class="games-access__notification">
                <sp-msg msg="PROFILE.RESPONSIBLE_GAMING.NOTIFICATION" spaces="end"></sp-msg>
                <span class="bold" spMsg="PROFILE.RESPONSIBLE_GAMING.NOTIFICATION_TIME"></span>
            </div>
        </ng-container>
    </div>
    <div class="info__text-bottom">
        <img src="assets/images/responsible-gaming/attantion.svg" alt="icon"/>
        <div>
            <sp-msg
              msg="PROFILE.RESPONSIBLE_GAMING.BLOCK_FOREVER"
              [params]="{supportEmail: supportEmail, responsibleGamingLink: responsibleGamingLink}"
            ></sp-msg>
        </div>
    </div>
</div>
