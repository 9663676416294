import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentMethod } from '@portal/payment/data';
import { CryptoWallet } from '@portal/payment/shared';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';

@Component({
  standalone: true,
  imports: [ SpCDKModule, CurrenciesModule, ThemeModule, ClipboardCopyComponent ],
  selector: 'gg-payment-crypto-wallet-info',
  templateUrl: './wallet-info.component.html',
  styleUrls: [ './wallet-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CryptoWalletInfoComponent {
  @Input({ required: true }) wallet: Nullable<CryptoWallet> = null;
  @Input({ required: true }) logo: Nullable<PaymentMethod['view']['logo']['secondary']>;
}
