<gg-verification-header [activeTab]="currentTab.KYC"/>
<section *ngIf="currentKycaidStatus$ | async as currentKycaidStatus" class="kyc-verification">
  <ng-container *ngIf="qrCode$ | async as qrCode; else kycStatus">
    <gg-verification-qr-code [qrCode]="qrCode"/>
  </ng-container>
  <ng-template #kycStatus>
    <gg-kyc-verification-status class="kyc-verification__status" [currentKycaidStatus]="currentKycaidStatus"/>
  </ng-template>
  <gg-identity-verification *ngIf="(qrCode$ | async) === null" class="kyc-verification__identity"/>
  <gg-payment-method-verification class="kyc-verification__payment-method"/>
</section>

