import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { CountriesData, CountriesQuery, Country } from '@portal/countries';
import { ControlsModule } from '@portal/shared/components';
import { IInputSelectData } from '@portal/shared/components/controls/interfaces';
import { InputAbstract } from 'apps/portal/src/app/shared/components/controls/input.abstract';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule ],
  selector: 'gg-country-states-selector',
  templateUrl: './states-selector.component.html',
  styleUrls: [ './states-selector.component.scss' ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StatesSelectorComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatesSelectorComponent extends InputAbstract implements OnInit, OnChanges {
  private readonly data = inject(CountriesData);
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly query = inject(CountriesQuery);
  private readonly selectedCountry: BehaviorSubject<Nullable<Country['isoCode']>> = new BehaviorSubject<Nullable<Country['isoCode']>>(null);

  readonly states: Observable<Array<IInputSelectData>> = this.selectedCountry.pipe(
    distinctUntilChanged(),
    switchMap((country) => {
      return country ? this.data.getCountryStatesList(country) : of([]);
    }),
    tap((states) => {
      const placeholder = { value: '', description: 'AUTH.REGISTRATION.STATE_CHOSE' };
      this.defaultItem = states.find(({ value }) => value === this.value) || placeholder;
      this.writeValue(this.defaultItem?.value);
    })
  );

  readonly state = new FormControl();

  defaultItem: Nullable<IInputSelectData>;
  truncateSelectedTo = 22;

  @Input() country: Nullable<Country['isoCode']> = null;

  override ngOnInit(): void {
    const country$ = this.country ? of(this.country) : this.query.country$;
    country$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((country) => this.selectedCountry.next(country));
    this.state.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((value) => this.writeValue(value));
  }

  ngOnChanges({ country }: SimpleChanges): void {
    country?.currentValue && this.selectedCountry.next(country.currentValue);
  }
}
