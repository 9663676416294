import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { ContentModule } from '@portal/shared/components';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-user-quick-access-links',
  standalone: true,
  imports: [ SpCDKModule, ContentModule, RouterLink, ThemeModule ],
  templateUrl: './quick-access-links.component.html',
  styleUrls: [ './quick-access-links.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickAccessLinksComponent {
  private readonly userQuery = inject(UserQuery);

  protected readonly count$ = this.userQuery.bonusCount$;
}
