<div class="qr-code">
  <gg-button-back
    class="qr-code__back-button"
    (click)="resetQrCode()"
  ></gg-button-back>
  <div class="qr-code__content">
    <img
      *ngIf="isDesktop"
      [src]="'data:image/png;base64,' + qrCode"
      class="qr-code__img"
      alt="qr-code"
    />
    <div class="qr-code__description">
      <img
        src="assets/images/profile/kycaid-verification/diya-logo.svg"
        class="qr-code__logo"
        alt="diya-logo"
      />
      <p class="qr-code__text">
        <sp-msg msg="PROFILE.KYCAID.DIYA_QR_CODE"/>
      </p>
    </div>
  </div>
</div>
