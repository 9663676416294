import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { CurrenciesModule } from '@portal/currencies';
import { ControlsModule } from '@portal/shared/components';

import { CommissionInfoComponent } from './commission-info';
import { CommissionWarningComponent } from './commission-warning';

@NgModule({
  imports: [ SpCDKModule, ControlsModule, CurrenciesModule ],
  declarations: [ CommissionInfoComponent, CommissionWarningComponent ],
  exports: [ CommissionInfoComponent, CommissionWarningComponent ],
})
export class CommissionModule {}
