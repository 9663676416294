import { Nullable } from '@libs/utils';
import { PaymentStatus } from '@portal/payment/shared';

export class ActionPollingData {
  constructor(
    readonly status: PaymentStatus,
    readonly code: string,
    readonly url: Nullable<string>,
    readonly initiatorId: Nullable<string>
  ) {}
}
