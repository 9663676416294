// @ts-nocheck

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BankCard, BankCardDetails, BankCardsAge, CashierState, CashierStore } from '@portal/cashier/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserBankCardQuery extends Query<CashierState> {
  readonly loading$: Observable<boolean> = this.selectLoading();
  readonly availableCards$: Observable<Array<BankCard>> = this.select('cards');
  readonly selectedCard$: Observable<BankCardDetails> = this.select('selectedCard');

  get newCardId(): string { return this.getValue().newCardId; }

  get selectedCardAge(): BankCardsAge { return this.getValue().selectedCard?.card_status; }

  get selectedCard(): any { return this.getValue().selectedCard; }

  constructor(repository: CashierStore) {
    super(repository);
  }
}
