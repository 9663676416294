import { GlobalWindow, Nullable } from '@libs/utils';
import { BrowserInfo } from '@portal/payment/shared';

export const collectBrowserInfo = (window: GlobalWindow, fingerprint?: Nullable<number>): BrowserInfo => ({
  browser_tz: new Date().getTimezoneOffset(),
  browser_screen_width: window.screen.availWidth,
  browser_screen_height: window.screen.availHeight,
  browser_color_depth: window.screen.colorDepth,
  browser_language: window.navigator.language,
  browser_java_enabled: false,
  browser_user_agent: window.navigator.userAgent,
  window_width: window.screen.width,
  window_height: window.screen.height,
  browser_javascript_enabled: true,
  browser_iframe_width: window.innerWidth,
  browser_iframe_height: window.innerHeight,
  browser_fingerprint: fingerprint
});
