import { ChangeDetectionStrategy, Component, inject, Injector, Input, OnInit, Provider } from '@angular/core';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config/data';
import { THEME_CONTEXT, ThemeContainerConfig } from '@portal/config/shared';
import { DynamicComponent } from '@portal/shared/components/content/dynamic/component';
import { NEVER, Observable } from 'rxjs';

@Component({
  selector: 'gg-themed-component',
  template: '<ng-container [ggDynamicComponent]="component"/>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemedComponent<Context = unknown> implements OnInit {
  private readonly theme = inject(ConfigQuery).theme;

  protected component: Nullable<DynamicComponent> = null;

  @Input({ required: true }) config: Nullable<ThemeContainerConfig> = null;
  @Input() context: Observable<Context> = NEVER;

  ngOnInit(): void {
    if (!this.config) { return; }

    const context: Provider = { provide: THEME_CONTEXT, useValue: this.context };
    const injector = Injector.create({ providers: [ context ] });
    this.component = new DynamicComponent(this.config[ this.theme.structure ], injector);
  }
}
