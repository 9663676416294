import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { CountryCodeSelectorComponent } from './components/country-code-selector.component';
import { InputPhoneWithCountryComponent } from './input-phone-with-country.component';

@NgModule({
  imports: [ SpCDKModule, ControlsModule, ThemeModule ],
  declarations: [ InputPhoneWithCountryComponent, CountryCodeSelectorComponent ],
  exports: [ InputPhoneWithCountryComponent, CountryCodeSelectorComponent ]
})
export class InputPhoneWithCountryModule {}
