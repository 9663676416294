import { camelToSnakeCase, Nullable } from '@libs/utils';
import { UserInfo, UserInfoDto, UserLevelDto } from '@portal/user/data';

const collectRegistrationInfo = (dto: UserInfoDto): UserInfo['registration'] => {
  const { regByPhone: registeredByPhone, passwordExists, isPokerUser, emailFaked } = dto;
  return { registeredByPhone, passwordExists, isPokerUser, emailFaked };
};

const collectSettings = (dto: UserInfoDto): UserInfo['settings'] => {
  const { lang, currency } = dto;
  return { lang, currency };
};

const collectPersonalInfo = (dto: UserInfoDto): UserInfo['personal'] => {
  const { nick, firstName, lastName, birthday, avatar, phone, email, isSpentMoney, mustChangeNick } = dto;
  return { nick, firstName, lastName, birthday, avatar, phone, email, isSpentMoney, mustChangeNick };
};

const collectVerificationInfo = (dto: UserInfoDto): UserInfo['verification'] => {
  const phone = { confirmed: dto.phoneConfirmed, ...dto.verification };
  const email = { confirmed: dto.emailConfirmed };
  const otp = { ...dto.otpVerification };
  const kycaid = { status: dto.verificationKYCAIDStatus };
  const documents = { verified: dto.isDepositsEnabled, list: dto.documents };
  return { phone, email, otp, kycaid, documents };
};

const collectRestrictedPages = (dto: UserInfoDto): UserInfo['restrictedPages'] => {
  return { ...dto.restrictedPages };
};

const collectPaymentData = (dto: UserInfoDto): UserInfo['paymentData'] => {
  return Object.entries(dto.paymentData).reduce((acc, [ name, value ]) => {
    return { ...acc, [ camelToSnakeCase(name) ]: value };
  }, {} as UserInfo['paymentData']);
};

const collectBalance = (dto: UserInfoDto): UserInfo['balance'] => {
  const { balance, bonusBalance, bonusBalanceBlocked, bonusCount, hasBonusBalanceBlocked, totalBalance } = dto;
  return {
    balance,
    bonusBalance,
    bonusBalanceBlocked,
    bonusCount,
    hasBonusBalanceBlocked,
    totalBalance
  };
};

const collectBlocking = (dto: UserInfoDto): UserInfo['blocking'] => {
  const betting = !dto.isBettingEnabled;
  const { isSlotsBlocked: slots, isLobbyBlocked: lobby, isBonusBlocked: bonuses, newCashierEnabled: paymentsWidget } = dto;
  const { isDepositBlocked: deposit, isWithdrawalBlocked: withdrawal } = dto;
  const { isSelfBlocked: selfBlocked, selfBlockedUntilDt: selfBlockedUntil } = dto;

  return { betting, slots, lobby, bonuses, deposit, withdrawal, selfBlocked, selfBlockedUntil, paymentsWidget };
};

const collectLevel = (dto: Nullable<UserLevelDto>): Nullable<UserInfo['level']> => {
  if (!dto) { return; }
  const { current, progress } = dto;
  return { current, progress };
};

export const createUser = (dto: UserInfoDto, levelDto: Nullable<UserLevelDto>): UserInfo => {
  const id = dto.userId;
  const status = dto.playerStatus;
  const info = collectRegistrationInfo(dto);
  const settings = collectSettings(dto);
  const personal = collectPersonalInfo(dto);
  const verification = collectVerificationInfo(dto);
  const restrictedPages = collectRestrictedPages(dto);
  const paymentData = collectPaymentData(dto);
  const balance = collectBalance(dto);
  const blocking = collectBlocking(dto);
  const level = collectLevel(levelDto);

  return new UserInfo(id, status, info, settings, personal, verification, restrictedPages, paymentData, balance, blocking, level);
};
