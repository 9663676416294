import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { ChatAdapter } from '@portal/chats/shared';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-chat-sidebar',
  templateUrl: './sidebar-chat.component.html',
  styleUrl: './sidebar-chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarChatComponent {
  private readonly userInfoQuery = inject(UserQuery);
  private readonly chatAdapter = inject(ChatAdapter);

  constructor() {
    if (inject(SpPlatform).server) { return; }
    this.chatAdapter.init(this.userInfoQuery.id, { manual: true });
  }

  makeId(): string {
    return `${this.chatAdapter.chatName()}-chat-btn`;
  }

  openChat(): void {
    this.chatAdapter.open();
  }
}
