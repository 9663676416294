import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';

@Component({
  selector: 'gg-input-search',
  templateUrl: './input-search.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputSearchComponent),
    multi: true
  } ]
})
export class InputSearchComponent extends InputAbstract {
  @Input() isCollapsible = false;
  @Input() override active = false;
  @Input() override icon = true;
  @Input() formControl: FormControl = new FormControl();
}
