import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[spPrevent]' })
export class SpPreventDirective {
  @Input('spPrevent') disable: 'propagation' | 'default' | 'all' = 'all';

  @HostListener('click', [ '$event' ]) click(event: MouseEvent): void {
    [ 'propagation', 'all' ].includes(this.disable) && event.stopPropagation();
    [ 'default', 'all' ].includes(this.disable) && event.preventDefault();
  }
}
