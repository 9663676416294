// @ts-nocheck

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpNavigation } from '@libs/cdk';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'pd-payment-header',
  templateUrl: './payment-header.component.html',
  styleUrls: [ './payment-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentHeaderComponent {
  @Input() secondaryLogo: Nullable<string>;

  constructor(private readonly navigationService: SpNavigation) {}

  backPage(): void {
    this.navigationService.back(['cashier', 'deposit']);
  }
}
