import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Nullable } from '@libs/utils';
import { AuthorizationQuery } from '@portal/authorization/data';
import { ConfigQuery } from '@portal/config';
import { UserQuery } from '@portal/user';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

type ConfirmationState = 'notConfirmed' | 'emailResent' | 'changeEmail' | 'confirmed' | 'disabled' | 'enabled';

@Injectable()
export class EmailConfirmationService {
  private readonly userQuery = inject(UserQuery);
  private readonly authorizationQuery = inject(AuthorizationQuery);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly showEmailConfirmation = inject(ConfigQuery).modules.authentication.showEmailConfirmation;

  private readonly cardState: BehaviorSubject<Nullable<ConfirmationState>> = new BehaviorSubject<Nullable<ConfirmationState>>('disabled');
  readonly view$ = this.cardState.asObservable();
  readonly email$: Observable<Nullable<string>> = this.userQuery.email$;

  createViewStateWatcher(): Subscription {
    const { confirmation$, isEmailFaked$ } = this.userQuery;
    const authorized$ = this.authorizationQuery.authorized$;

    return combineLatest([ authorized$, confirmation$, isEmailFaked$ ]).pipe(
      distinctUntilChanged((prev, curr) => prev[1].email === curr[1].email && !curr[2]),
      map(([ authorized, confirmation, isEmailFaked ]): Nullable<ConfirmationState> => {
        if (this.activatedRoute.snapshot.params['confirmed']) { return 'confirmed'; }
        if (!this.showEmailConfirmation) {return 'disabled'; }
        if (!authorized || (authorized && confirmation.email)) { return 'disabled'; }
        if (!confirmation.email && !isEmailFaked) { return 'notConfirmed'; }
        if (confirmation.phone) {return 'disabled'; }
        return 'disabled';
      })
    ).subscribe((view) => this.changeViewState(view));
  }

  changeViewState(view: Nullable<ConfirmationState>): void {
    this.cardState.next(view);
  }
}
