import { Nullable } from '@libs/utils';

export type InitConfig = {
  manual: boolean;
}

export abstract class ChatAdapter {
  abstract init(userId: Nullable<string>, config?: InitConfig): void;

  abstract show(): void;

  abstract hide(): void;

  abstract open(): void;

  abstract close(): void;

  abstract chatName(): string;
}
