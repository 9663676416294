export enum LinkSizes {
  XS = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

export enum LinkThemes {
  Green = 'green',
  Gray = 'gray',
  White = 'white',
  Icon = 'icon'
}
