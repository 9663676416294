import { APP_INITIALIZER, inject } from '@angular/core';
import { SpAuthorizationService } from '@libs/authorization';
import { synchronize } from '@libs/store';
import { GamesData, GamesQuery } from '@portal/games/data';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

const init = (): () => Observable<unknown> => {
  const isAuthorized$ = inject(SpAuthorizationService).authorized$;
  const data = inject(GamesData), query = inject(GamesQuery);

  return () => isAuthorized$.pipe(tap(() => synchronize(data, query)), first());
};

export const gamesAndSectionsInitializer = { provide: APP_INITIALIZER, useFactory: init, multi: true };

