import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Breadcrumb } from '@portal/layout/data';

export type LayoutState = {
  app: 'ready' | 'error' | 'hidden' | null;
  loading: boolean;
  sidebar: boolean;
  breadcrumbs: Array<Breadcrumb>;
};

export const config: SteroidConfig<LayoutState> = {
  options: { name: 'layout-store' },
  initial: { app: null, loading: false, sidebar: false, breadcrumbs: [] }
};

@Injectable({ providedIn: 'root' })
export class LayoutStore extends SteroidStore<LayoutState> {
  constructor() { super(config); }
}
