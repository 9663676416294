import { EcommpayPaymentMethods } from '../../../types/enums/payment-methods/ecommpay-methods.enum';
import { PayhubPaymentMethods } from '../../../types/enums/payment-methods/payhub-methods.enum';
import { PaysagePaymentMethods } from '../../../types/enums/payment-methods/paysage-methods.enum';
import { PraxisMethods } from '../../../types/enums/payment-methods/praxis-methods.enum';

export const SAVE_CARDS_METHODS = [
  EcommpayPaymentMethods.BankCardWithdrawal,
  EcommpayPaymentMethods.BankCardWithdrawalFixedAmount,
  PaysagePaymentMethods.BankCardWithdrawal,
  PaysagePaymentMethods.BankCardWithdrawalKZT,
  EcommpayPaymentMethods.BankCardWithdrawalUZ,
  PayhubPaymentMethods.BankCardWithdrawal,
  PraxisMethods.Withdrawal
];
