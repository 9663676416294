import { Nullable } from '@libs/utils';
import { PaymentStatus } from '@portal/payment/shared';

export class Action3DSData {
  constructor(
    readonly exist: boolean,
    readonly status: Nullable<PaymentStatus>,
    readonly value: Nullable<string>,
    readonly url: Nullable<string>
  ) {}
}
