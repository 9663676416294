import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { switchTap } from '@libs/utils';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { PaymentData, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { BehaviorSubject, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, ThemeModule ],
  templateUrl: './pending.component.html',
  styleUrls: [ './pending.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingStatusComponent {
  private readonly configQuery = inject(ConfigQuery);
  private readonly paymentData = inject(PaymentData);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly paramsQuery = inject(PaymentParamsQuery);

  readonly invoice$ = this.paramsQuery.paymentParams$.pipe(map((params) => params.invoice));

  readonly button: IButton = { size: ButtonSizes.Large };
  readonly supportEmail = this.configQuery.modules.contacts.support;
  readonly loading = new BehaviorSubject(false);

  updateStatus(): void {
    this.loading.next(true);
    this.invoice$.pipe(
      first(),
      switchTap((invoice) => this.paymentData.updateStatus(invoice).pipe(
        tap((status) => this.paramsData.setPayment({ status, invoice }))
      ))
    ).subscribe(() => this.loading.next(false));
  }

}
