import { CustomClientDto } from '@portal/chats/data/verbox';
import { Nullable } from '@libs/utils';

export class VerboxClientSettings {
  constructor(
    readonly email: Nullable<string>,
    readonly phone: Nullable<string>,
    readonly name: Nullable<string>,
    readonly custom: CustomClientDto['custom']
  ) {}
}
