import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { CardPartsModule, CashierBonusCardComponent } from '@portal/bonuses/components';
import { CashierPromoCodeComponent } from '@portal/bonuses/components/cashier-promo-code';
import { BonusButtonComponent } from '@portal/bonuses/features/bonuses-selector/bonus-button/bonus-button.component';
import { ControlsModule, SwiperModule } from '@portal/shared/components';
import { RadioModule } from '@portal/shared/components/controls-reimplement/radio/radio.module';
import { BonusCardModule } from 'apps/portal/src/app/bonuses/features/card/card.module';
import { BonusesSelectorComponent } from '@portal/bonuses/features/bonuses-selector/bonuses-selector.component';
import { ThemeModule } from '@portal/config';
import { BonusPipesModule } from '@portal/bonuses/shared';

@NgModule({
  imports: [ SpCDKModule, BonusCardModule, SwiperModule, ThemeModule, CashierBonusCardComponent, ControlsModule, CardPartsModule, BonusPipesModule, RadioModule, RouterLink, CashierPromoCodeComponent ],
  declarations: [ BonusesSelectorComponent, BonusButtonComponent ],
  exports: [ BonusesSelectorComponent, BonusButtonComponent ]
})
export class BonusesSelectorModule {}
