import { Component } from '@angular/core';
import { ConfigQuery } from '@portal/config';

@Component({
  selector: 'gg-deposit-verification-kyc',
  templateUrl: './deposit-verification-kyc.component.html',
  styleUrls: [ './deposit-verification-kyc.component.scss' ]
})
export class DepositVerificationKycComponent {
  readonly theme = this.source.project.name || '';

  constructor(private readonly source: ConfigQuery) {}
}
