<div class="pwa-install">
  <img src="assets/images/icons/cross.svg" class="pwa-install__close" (click)="close()" alt=""/>
  <div class="pwa-install__logo__wrap">
    <img [src]="logo" class="pwa-install__logo" alt=""/>
  </div>
  <span class="pwa-install__title" spMsg="COMMON.INSTALL_PWA"></span>
  <gg-button class="pwa-install__btn" (click)="openPWAInstallPrompt()">
    <span spMsg="HEADER.INSTALL"></span>
  </gg-button>
  <span class="pwa-install__not-now" spMsg="COMMON.NOT_NOW" (click)="close()"></span>
</div>
