import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Page } from '@portal/static/data';

export type PageState = { entities: { pages: SteroidEntity<Page, string> } };

const config: SteroidConfig<PageState> = {
  options: { name: 'pages' },
  entities: { pages: { idKey: 'slug' } }
};

@Injectable({ providedIn: 'root' })
export class PageStore extends SteroidStore<PageState> {
  constructor() { super(config); }
}
