import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [ SpCDKModule, RouterModule, ControlsModule ],
  templateUrl: './crypto-verification.component.html',
  styleUrl: './crypto-verification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CryptoVerificationComponent {
  readonly dialog = inject(Dialog);
}
