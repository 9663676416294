import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable, Provider } from '@angular/core';
import { arrayalize, isUrlMatched, Nullable } from '@libs/utils';
import { first, Observable, switchMap } from 'rxjs';
import { SpAuthorizationCommands } from './abstract.commands';
import { SpAuthorizationService, TokensPair } from './authorization.service';
import { SP_AUTHORIZATION_CONFIG } from './config';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  private readonly commands = inject(SpAuthorizationCommands, { optional: true });
  private readonly service = inject(SpAuthorizationService);
  private readonly config = inject(SP_AUTHORIZATION_CONFIG, { optional: true });

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config || !this.commands) { return next.handle(req); }

    return this.service.tokens$.pipe(
      first(), switchMap((tokens) => next.handle(this.mapRequest(req, tokens)))
    );
  }

  private mapRequest(req: HttpRequest<unknown>, tokens: Nullable<TokensPair>): HttpRequest<unknown> {
    if (!this.commands || !this.config) { return req; }

    const { signIn, signOut, refresh, discovery } = this.config.routes;

    const isSignInRequest = isUrlMatched({ urls: arrayalize(signIn) })(req.url);
    const isSignOutRequest = isUrlMatched({ urls: arrayalize(signOut) })(req.url);
    const isRefreshRequest = isUrlMatched({ urls: arrayalize(refresh) })(req.url);
    const isDiscoveryRequest = isUrlMatched({ urls: arrayalize(discovery) })(req.url);
    const isRegularRequest = !isSignInRequest && !isSignOutRequest && !isRefreshRequest && !isDiscoveryRequest;

    if (isSignInRequest) { return this.commands.patchSignInRequest(req, tokens); }
    if (isSignOutRequest) { return this.commands.patchSignOutRequest(req, tokens);}
    if (isRefreshRequest) { return this.commands.patchRefreshRequest(req, tokens); }
    if (isDiscoveryRequest) { return this.commands.patchDiscoveryRequest(req, tokens); }
    if (isRegularRequest && tokens?.accessToken) { return this.commands.patchAuthorizedRequest(req, tokens); }

    return req;
  }
}

export const authorizeInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthorizeInterceptor,
  multi: true
};
