import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Host, inject, Input, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpCDKModule } from '@libs/cdk';
import { WINDOW } from '@ng-web-apis/common';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { OpenPaymentsDirective } from '@portal/payment';
import { ControlsModule } from '@portal/shared/components';
import { UserQuery } from '@portal/user';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Component({
  standalone: true,
  selector: 'gg-user-balance',
  templateUrl: './balance.component.html',
  styleUrls: [
    './balance.component.scss',
    './balance-purple.component.scss',
    './balance-sidebar-light.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, CurrenciesModule, ThemeModule, ControlsModule, OpenPaymentsDirective ],
})
export class BalanceComponent {
  private readonly destroy = inject(DestroyRef);
  private readonly window = inject(WINDOW);
  private readonly expanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly detailsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly userQuery = inject(UserQuery);
  private readonly configQuery = inject(ConfigQuery);

  protected readonly balance$ = this.userQuery.balance$;
  protected readonly expanded$ = this.expanded.asObservable();
  protected readonly detailsOpened$ = this.detailsOpened.asObservable();
  protected readonly isLight = this.configQuery.modules.sidebar.isLightBalance;
  protected readonly enableTruePlayBalance = this.configQuery.modules.cashier.enableTruePlayBalance;

  @Input() section: 'header' | 'sidebar' = 'header';

  @ViewChild('options') options: ElementRef | undefined;

  constructor(@Host() private readonly host: ElementRef) {
    fromEvent<PointerEvent>(this.window, 'click').pipe(
      takeUntilDestroyed(this.destroy),
    ).subscribe((event) => this.toggleExpand(event));
  }

  toggleDetails(): void {
    const state = this.detailsOpened.getValue();
    this.detailsOpened.next(!state);
  }

  private toggleExpand(event: PointerEvent): void {
    if (event.composedPath().includes(this.host.nativeElement)) {
      if (event.composedPath().includes(this.options?.nativeElement)) {
        this.expanded.next(true);
      } else {
        this.expanded.next(!this.expanded.getValue());
      }
    } else {
      this.expanded.next(false);
    }
  }
}
