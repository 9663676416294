<div class="card-details" *ngIf="card" [ngClass]="'card-details_' + card.type">
  <img src="./assets/images/cashier/bank-card/{{card.type}}-logo.svg" alt="card-logo" class="card-details__logo">
  <div class="card-details__mask">{{ card.account | cardMaskTransform }}</div>
  <div class="card-details__block">
    <div class="card-details__exp-date" *ngIf="card.expMonth && card.expYear">
      {{ card.expMonth }}
      <span class="card-details__exp-date-divider">/</span>
      {{ card.expYear }}
    </div>
    <gg-input-card-cvv *ngIf="withCVVControl" class="card-details__cvv" [formControl]="control"/>
  </div>
</div>
