import { Language } from '@libs/cdk';

export const LANGUAGE_NAMES: Record<Language, string> = {
  en: 'English',
  ru: 'Русский',
  uk: 'Українська',
  kk: 'Қазақша',
  fr: 'French',
  uz: 'O’zbekcha',
  de: 'Deutsch',
  it: 'Italian'
};
