import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import { CurrencyNormalizerPipe } from '@portal/currencies/application';
import { CurrenciesModule } from '@portal/currencies/currencies.module';
import { PaymentHistory } from '@portal/payment/data';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gg-payment-history-operation',
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, CurrenciesModule, ThemeModule ],
  templateUrl: './history-operation.component.html',
  styleUrls: [ './history-operation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryOperationComponent {
  private readonly normalizer = inject(CurrencyNormalizerPipe);
  private readonly isCancelling: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly isCancelling$ = this.isCancelling.asObservable();

  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly yesButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly noButton: IButton = { size: ButtonSizes.Medium };

  @Input({ required: true }) operation: Nullable<PaymentHistory> = null;
  @Input() extended: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  get date(): string { return moment(Number(this.operation?.info.created) * 1000).format('DD.MM.YYYY'); }

  get canBeCancelled(): boolean { return this.operation?.info.operation === 'withdrawal' && this.operation?.status === 'new'; }

  get amount(): string { return this.normalizer.transform(`${this.operation?.info.amount} ${this.operation?.info.currency}`); }

  setCancelState(state: boolean): void { this.isCancelling.next(state); }

  cancelTransaction(): void {
    this.cancel.emit();
    this.setCancelState(false);
  }
}
