import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import { Page, PageState, PageStore } from '@portal/static/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageQuery extends SteroidQuery<PageState> {
  private readonly pages = this.entity('pages');

  constructor(store: PageStore) { super(store); }

  selectPage(slug: Page['slug']): Observable<Nullable<Page>> { return this.pages.selectEntity(slug); }
}
