@if(bonus?.maxWin.isActive && !isLinkedBonus) {
  <div class="bonus-max-win">
    @if(bonus?.maxWin; as maxWin) {
      @if(maxWin.number) {
        <span [ngClass]="{ 'pre-wrap': isPreWrap }">
          <sp-msg msg="BONUSES.MAX_WIN.TITLE"/>:
          <span class="bonus-max-win-amount">{{bonus?.maxWin.amount}} {{currency$ | async | currencySymbol}}</span>
        </span>
      }
      @if(maxWin.maxWinAmount) {
        <span [ngClass]="{ 'pre-wrap': isPreWrap }">
          <sp-msg msg="BONUSES.MAX_WIN.TITLE"/>:
          <span class="bonus-max-win-amount">{{bonus?.maxWin.maxWinAmount}} {{currency$ | async | currencySymbol}}</span>
        </span>
      }
      @if(maxWin.deposit && !maxWin.maxWinAmount) {
        <span [ngClass]="{ 'pre-wrap': isPreWrap }">
          <sp-msg msg="BONUSES.MAX_WIN.TITLE"/>:
          <span class="bonus-max-win-amount"><sp-msg msg="BONUSES.MAX_WIN.TITLE_DEPOSIT" spaces="end"/>х{{bonus?.maxWin.multiplier}}</span>
        </span>
      }
      @if(maxWin.bonus && !maxWin.maxWinAmount) {
        <span [ngClass]="{ 'pre-wrap': isPreWrap }">
          <sp-msg msg="BONUSES.MAX_WIN.TITLE"/>:
          <span class="bonus-max-win-amount"><sp-msg msg="BONUSES.MAX_WIN.TITLE_BONUS" spaces="end"/>х{{bonus?.maxWin.multiplier}}</span>
        </span>
      }
    }
  </div>
}
@if(isLinkedBonus) {
  <div class="bonus-max-win">
    <span class="bonus-max-win-title"><sp-msg msg="BONUSES.MAX_WIN.TITLE"/>: </span>
    <span class="bonus-max-win-value"><sp-msg msg="BONUSES.MAX_WIN.VALUE"/></span>
  </div>
}
