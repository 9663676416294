<div class="cactus-dialog-wrapper">
  <img (click)="close()" alt="" class="cactus-dialog-wrapper__close" src="assets/images/icons/cross.svg"/>
  <div class="cactus-dialog">
   <div class="cactus-dialog__code-banner">
     <sp-msg spMsg="CASHIER.CARD.VERIFICATION_CODE">:</sp-msg>
     <div *ngIf="code" class="cactus-dialog__code-banner__wrap">
       <div [innerHTML]="code" class="cactus-dialog__code-banner__code"></div>
       <gg-clipboard-copy [copyValue]="code" class="copy-past"></gg-clipboard-copy>
     </div>
   </div>

    <div class="cactus-dialog__info-banner-content">
      <sp-msg spMsg="CASHIER.CARD.CACTUS_WARNING"></sp-msg>
      <gg-input-checkbox [(ngModel)]="saved" class="checkbox__item" for="saved">
        <sp-msg spMsg="CASHIER.CARD.SAVED_CODE"></sp-msg>
      </gg-input-checkbox>
    </div>

    <div class="cactus-dialog__info-address">
      <sp-msg spMsg="CASHIER.CARD.DELIVERY_ADDRESS">:</sp-msg>
      <div class="address">{{ address }}</div>
      <a (click)="close()">{{ "CASHIER.CARD.CHANGE" | translate }}</a>
    </div>

    <div class="cactus-dialog__commission-info">
      <pd-dot-separated [value]="amountForWidget" label="CASHIER.SUM_WITHDRAWAL"/>
      <pd-dot-separated [value]="commission" label="CASHIER.COMMISSION"/>
    </div>

  <gg-button (click)="confirm()" [disabled]="!saved" class="cactus-dialog__btn">
    <sp-msg spMsg="CASHIER.CARD.CONFIRM"></sp-msg>
  </gg-button>
    </div>
</div>

