import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { CashierBonusCardComponent } from '@portal/bonuses/components';
import { PaymentTilesComponent } from '@portal/payment/components';
import { PaymentGroup, PaymentGroupsQuery, PaymentMethod, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { GROUPS_METHODS_TITLES, PaymentContainerType } from '@portal/payment/shared';
import { ContentModule } from '@portal/shared/components';
import { combineLatest, map, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-payment-methods-selector',
  templateUrl: './methods-selector.component.html',
  styleUrls: [ './methods-selector.component.scss' ],
  imports: [ SpCDKModule, RouterModule, CashierBonusCardComponent, ContentModule, PaymentTilesComponent ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MethodsSelectorComponent {
  private readonly paramsData = inject(PaymentParamsData);
  private readonly groupsQuery = inject(PaymentGroupsQuery);
  private readonly paramsQuery = inject(PaymentParamsQuery);
  private readonly isDeposit$ = inject(PaymentParamsQuery).isDeposit$;

  readonly titleByFlow = this.getTitleByFlowType();
  readonly activeMethod$ = this.groupsQuery.activeMethod$.pipe(map((method) => method?.id));
  readonly activeGroup$ = this.groupsQuery.group$.pipe(map((group) => group?.id));
  readonly availableEntities$ = combineLatest([ this.createMethodList(), this.createGroupsList() ]).pipe(
    map(([ methods, groups ]) => {
      if (methods.length > 1) {
        return { tiles: methods, type: 'method' };
      } else if (groups && groups.length > 1) {
        return { tiles: groups, type: 'group' };
      } else {
        return { tiles: null };
      }
    }),
  );

  setSelected(id: PaymentMethod['id'] | PaymentGroup['id'], type: 'method' | 'group'): void { this.paramsData.setPayment({ [ type ]: id }); }

  private getTitleByFlowType(): Observable<string> {
    return combineLatest([ this.groupsQuery.group$, this.isDeposit$ ]).pipe(
      map(([ group, isDeposit ]) => {
        const groupType = group?.type || '';
        const flowType = isDeposit ? 'deposit' : 'withdrawal';
        return GROUPS_METHODS_TITLES[ groupType ]?.[ flowType ] || '';
      }),
    );
  }

  private createMethodList(): Observable<Array<PaymentContainerType>> {
    return combineLatest([ this.groupsQuery.methods$, this.groupsQuery.group$ ]).pipe(
      distinctUntilChanged((previous, current) => previous[ 1 ]?.id === current[ 1 ]?.id),
      map(([ { active, methods }, group ]) => {
        return methods.map(({ id, amounts: { limits: { min, max } }, view: { name, logo } }) => {
          const limits = group?.type !== 'crypto' || this.paramsQuery.isWithdrawal ? { min, max } : null;
          return { name, active: active?.id === id, limits, id, icon: logo.primary };
        });
      }));
  }

  private createGroupsList(): Observable<Nullable<Array<PaymentContainerType>>> {
    return this.groupsQuery.groups$.pipe(
      distinctUntilChanged((previous, current) => {
        const saved = new Set(previous?.map((group) => group.id));
        return Boolean(current?.every((group) => saved.has(group.id)));
      }),
      map((groups) => {
        return groups?.map(({ id, limits: { min, max }, icon, name }) => {
          return { name, limits: { min, max }, id, icon };
        });
      }),
    );
  }
}
