import { concat, Observable, share, take, tap } from 'rxjs';
import { NoOp } from '../types';

export function tapOnce<T>(action: NoOp): (source$: Observable<T>) => Observable<T> {
  return (source$) => {
    const sharedSource$ = source$.pipe(share());
    const tapped$ = sharedSource$.pipe(tap((action)), take(1));

    return concat(tapped$, sharedSource$);
  };
}
