<section class="promo-code-state">
  <div class="promo-code-state__wrapper">
    <gg-button-back class="promo-code-state__back-btn" (click)="returnToBonusesList()"></gg-button-back>
    <div class="promo-code-state__form" [formGroup]="form">
      <img class="promo-code-state__img" src="assets/images/icons/promo-code.svg" alt="promo-code-icon">
      <h4 class="promo-code-state__input-label" spMsg="BONUSES.INPUT_PROMO_TITLE"></h4>
      <gg-input
        formControlName="promoCode"
        class="promo-code-state__input"
        placeholder="{{'BONUSES.PROMO_CODE_PLACEHOLDER' | translate}}"
        autocomplete="off"
        [isTrim]="true"
        [errors]="errors.get('promoCode') | async"
      />
      <gg-button
        class="promo-code-state__button"
        [disabled]="form.invalid"
        [fullWidth]="true"
        (buttonClicked)="sendCode()"
      ><sp-msg msg="BONUSES.USE"/></gg-button>
    </div>
  </div>
</section>
