import { Validators } from '@angular/forms';
import { CustomValidators } from '@portal/shared/helpers';

export const NEW_CARD_VALIDATION_FORM = {
  account: ['', [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(19),
    CustomValidators.amount('int')
  ] ],
  holder: ['', [Validators.required]],
  exp_month: ['', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(2)
  ] ],
  exp_year: ['', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(2)
  ] ]
};
