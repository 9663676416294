import { FormControl, FormGroup } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';

export const createPaymentForm = (bonus: Nullable<Bonus>): FormGroup<{
  amount: FormControl<number>;
  bonus: FormControl<Nullable<Bonus>>;
}> => new FormGroup({
  amount: new FormControl(0, { nonNullable: true }),
  bonus: new FormControl(bonus)
});
