import { GameSourceInfo } from '@portal/games/shared';

export class GameProvider implements GameSourceInfo {
  readonly type = 'provider';

  constructor(
    readonly key: string,
    readonly name: string,
    readonly image: string,
    readonly imageAlt: string
  ) {}
}
