import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpLocalization } from '@libs/cdk';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PromotionsResponse } from './responses';
import { PromotionStore } from '@portal/promotions/data/store/promotion.store';
import { ResponseFactory } from '@portal/shared/helpers';
import { createPromotion } from 'apps/portal/src/app/promotions/data/mappers';
import { Promotion } from '@portal/promotions/data/entities';
import { PromotionTypes } from '@portal/promotions/shared/types/promotion-types';

@Injectable({ providedIn: 'root' })
export class PromotionsData {
  private readonly http = inject(HttpClient);
  private readonly promotionStore = inject(PromotionStore);
  readonly language = inject(SpLocalization).currentLanguage;

  synchronize(): void {
    this.http.get<PromotionsResponse>('/api/2/promotions').pipe(
      catchError((error) => throwError(ResponseFactory.error(error))),
      map(({ _items }) => createPromotion(_items))
    ).subscribe((promotions: Array<Promotion>) => {
      this.promotionStore.entity('promotions').set(promotions);
      this.promotionStore.update({ sync: true });
    });
  }

  updateFilters(filters: PromotionTypes): void {
    this.promotionStore.update(state => ({ ...state, filters }));
  }

  getFile(promotion: Promotion): Observable<Blob> {
    return this.http.get(`/api/2/cms/download/promotions/${promotion.slug}?lang=${this.language}`, { responseType: 'blob' });
  }
}
