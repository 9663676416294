import { Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { ConfigModules, Project, Theme } from '../';
import { ConfigState, ConfigStore } from './';

@Injectable({ providedIn: 'root' })
export class ConfigQuery extends SteroidQuery<ConfigState> {
  readonly synchronized$ = this.select('sync');

  get theme(): NonNullable<Theme> { return this.get('theme') as NonNullable<Theme>; }

  get project(): NonNullable<Project> { return this.get('project') as NonNullable<Project>; }

  get modules(): NonNullable<ConfigModules> { return this.get('modules') as NonNullable<ConfigModules>; }

  constructor(store: ConfigStore) {super(store);}
}
