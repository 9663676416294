<ng-container *spLet="copied$ | spAsync as copied">
  <div
    class="copy-clipboard"
    [cdkCopyToClipboard]="copyValue"
    (cdkCopyToClipboardCopied)=showCopyPlank()
    [ggCreateThemeClass]="'copy-clipboard'"
    [ngClass]="{'copy-active': copied}"
  >
    @if (showTextButton) {
      <gg-button [settings]="copyButton">
        <sp-msg msg="BONUSES.COPY_CODE"></sp-msg>
      </gg-button>
    } @else {
      <div class="copy-icon" [class.copy-icon_small]="isSmallButton">
        <svg class="copy-icon__svg">
          @if (isSmallButton) {
            <use xlink:href="assets/images/icons/copy-clipboard-small.svg#clipboardCopy"></use>
          } @else {
            <use xlink:href="assets/images/icons/copy-clipboard.svg#clipboardCopy"></use>
          }
        </svg>
      </div>
    }
    @if (copied) {
      <sp-msg class="copy-planck" msg="PROMOTIONS.COPIED"/>
    }
  </div>
</ng-container>
