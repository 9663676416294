<div class="cropped-bonus {{bonus?.type}}" [ngClass]="{'welcome-bonus': isWelcomeBonus}" ggCreateThemeClass="cropped-bonus">
  <div class="cropped-bonus__content">
    <div class="cropped-bonus__title">{{ bonus | bonusTitle }}</div>
    <gg-bonus-card-game class="cropped-bonus__info cropped-bonus__info_game" [bonus]="bonus" [ignoreLink]="true"/>
    <gg-bonus-card-activation class="cropped-bonus__info" [bonus]="bonus"/>
    <gg-bonus-card-wager-info class="cropped-bonus__info" [bonus]="bonus"/>
    <gg-bonus-card-max-bet [bonus]="bonus"/>
    <gg-bonus-card-max-win class="cropped-bonus__info" [bonus]="bonus"/>
    <gg-bonus-card-promo class="cropped-bonus__promo" [bonus]="bonus"/>
  </div>
  <div class="cropped-bonus__icons" [ngClass]="{ 'welcome-bonus-icons': isWelcomeBonus }">
    <gg-bonus-card-icon [bonus]="bonus" [isUseNewIcon]="isWelcomeBonus"/>
  </div>
</div>
