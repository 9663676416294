import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeneratedFormComponent } from '@portal/payment/components';
import { FormDeclaration, PostActionAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { IButton } from '@portal/shared/components/controls';
import { SpLocalizationModule } from 'libs/cdk/src/lib/localization';

@Component({
  selector: 'gg-payment-data',
  standalone: true,
  imports: [ CommonModule, GeneratedFormComponent, ControlsModule, SpLocalizationModule ],
  templateUrl: './payment-data.component.html',
  styleUrl: './payment-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDataComponent extends PostActionAbstract {
  readonly control: FormControl<object | null> = new FormControl(null);
  readonly submitButton: IButton = { loading: false };
  readonly form: FormDeclaration = this.result.data.payment_data || [];

  submit(): void {
    if (this.control.invalid) { return; }

    this.builder.updatePayment({
      data: { payment: this.control.value },
      method: { id: this.result.data.actual_payment_id },
    });

    this.builder.complete();
  }
}
