import { NgModule } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { LiveChatAdapter, VerboxAdapter } from '@portal/chats';
import { ChatAdapter } from '@portal/chats/shared';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ChatsComponent } from './chats.component';
import { SidebarChatComponent } from './sidebar-chat/sidebar-chat.component';

const chatAdapterFactory = (config: ConfigQuery): ChatAdapter => {
  const liveChatId = config.modules.contacts.liveChat.id;
  const { scriptLink, domain } = config.modules.contacts.verbox;

  if (scriptLink) {
    return new VerboxAdapter(domain, scriptLink);
  } else if (liveChatId) {
    return new LiveChatAdapter(liveChatId);
  } else {
    throw new Error('Unsupported chat');
  }
};

@NgModule({
  imports: [ SpCDKModule, ThemeModule ],
  declarations: [ ChatsComponent, SidebarChatComponent ],
  exports: [ ChatsComponent, SidebarChatComponent ],
  providers: [ { provide: ChatAdapter, useFactory: chatAdapterFactory, deps: [ ConfigQuery ] } ],
})
export class ChatsModule {}
