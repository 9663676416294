import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { passOnly } from '@libs/authorization';
import { loadBonusesGuard } from '@portal/bonuses/shared';
import { BankCardsComponent } from '@portal/cashier/components/bank-cards/bank-cards.component';
import { CardValidationComponent } from '@portal/cashier/components/card-validation/card-validation.component';
import {
  AccessCardGuard,
  AccessDepositsGuard,
  AccessWithdrawalGuard,
  BankCardsPaymentMethodsGuard,
  CardsResolver,
  CryptocurrenciesResolver,
  DepositsEnabledGuard,
  HasActivatedBonusesGuard, OpenNewCashierGuard,
  PaymentMethodGuard,
  PaymentMethodsGuard,
  UserConfirmedGuard
} from '@portal/cashier/shared';
import { kycaidVerificationGuard } from '@portal/verification/shared/providers/guards/kycaid-verification.guard';
import { CashierComponent } from './cashier.component';
import { ActiveBonusWarningComponent } from './components/active-bonus-warning/active-bonus-warning.component';
import { CardComponent } from './components/card/card.component';
import { DepositVerificationKycComponent } from './components/deposit-verification-kyc/deposit-verification-kyc.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { HistoryComponent } from './components/history/history.component';
import { StatusComponent } from './components/status/status.component';
import { TransfersFormComponent } from './components/transfers/components/form/transfers-form.component';
import { SuccessTransferComponent } from './components/transfers/components/success/success-transfer.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { CashierSections } from './types/enums/sections.enum';

export const routes: Routes = [
  {
    path: '',
    component: CashierComponent,
    canActivate: [ passOnly('authorized', [ '/auth/login' ], '/cashier/deposit'), PaymentMethodsGuard ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/cashier/deposit'
      },
      {
        path: 'deposit-verification',
        component: DepositVerificationKycComponent
      },
      {
        path: 'active-bonus',
        component: ActiveBonusWarningComponent
      },
      {
        path: 'deposit',
        canActivate: [ AccessDepositsGuard, DepositsEnabledGuard, OpenNewCashierGuard ],
        children: [
          {
            path: '',
            component: DepositComponent
          },
          {
            path: 'transaction/:status',
            component: StatusComponent,
            data: { section: CashierSections.Deposit }
          },
          {
            path: 'bank-cards',
            component: BankCardsComponent,
            canActivate: [ BankCardsPaymentMethodsGuard ]
          },
          {
            path: 'card-validation',
            component: CardValidationComponent
          }
        ]
      },
      {
        path: 'withdrawal',
        canActivate: [ AccessWithdrawalGuard, OpenNewCashierGuard ],
        children: [
          {
            path: '',
            component: WithdrawalComponent
          },
          {
            path: 'transaction/:status',
            component: StatusComponent,
            data: { section: CashierSections.Withdrawal }
          }
        ]
      },
      {
        path: 'transfers',
        children: [
          {
            path: '',
            component: TransfersFormComponent
          },
          {
            path: 'success',
            component: SuccessTransferComponent
          }
        ]
      },
      {
        path: 'history',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/cashier/history/all'
          },
          {
            path: ':section',
            component: HistoryComponent
          }
        ]
      },
      {
        path: ':type/:method',
        component: CardComponent,
        canActivate: [
          AccessCardGuard,
          UserConfirmedGuard,
          HasActivatedBonusesGuard,
          kycaidVerificationGuard,
          PaymentMethodGuard,
          loadBonusesGuard
        ],
        pathMatch: 'full',
        resolve: {
          cards: CardsResolver,
          cryptocurrency: CryptocurrenciesResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
  providers: [
    CardsResolver,
    CryptocurrenciesResolver,
    UserConfirmedGuard,
    HasActivatedBonusesGuard,
    AccessDepositsGuard,
    AccessWithdrawalGuard,
    AccessCardGuard,
    DepositsEnabledGuard,
    BankCardsPaymentMethodsGuard,
    PaymentMethodGuard,
    PaymentMethodsGuard
  ]
})
export class CashierRoutingModule {
}
