import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpAuthorizationService } from '@libs/authorization';
import { SpCDKModule, SpLocalization, SpNavigation } from '@libs/cdk';
import { SocketComponent } from '@portal/banners';
import { ChatsModule } from '@portal/chats';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { SidebarNavigationSection } from '@portal/config/shared';
import { GamesQuery } from '@portal/games/data';
import { SectionsComponent, UserBalanceComponent } from '@portal/layout/components';
import { LayoutData, LayoutQuery } from '@portal/layout/data';
import { ControlsModule } from '@portal/shared/components';
import { UserInfo, UserQuery } from '@portal/user';
import { combineLatest, NEVER, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-layout-sidebar',
  imports: [ SpCDKModule, ControlsModule, SocketComponent, ThemeModule, UserBalanceComponent, SectionsComponent, ChatsModule ],
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly destroy = inject(DestroyRef);
  private readonly userQuery = inject(UserQuery);
  private readonly layoutData = inject(LayoutData);
  private readonly layoutQuery = inject(LayoutQuery);
  private readonly gamesQuery = inject(GamesQuery);
  private readonly configQuery = inject(ConfigQuery);
  private readonly navigation = inject(SpNavigation);
  private readonly localization = inject(SpLocalization);
  private readonly authorization = inject(SpAuthorizationService);
  private readonly isRightSidebar: boolean = this.configQuery.theme.palette === 'purple' && this.configQuery.theme.structure === 'flat';
  protected readonly authorized = this.authorization.authorized;
  protected readonly nickname$ = this.getUserNickname$();
  protected readonly sections$ = this.buildNavigation();

  readonly isShowChat = inject(ConfigQuery).modules.contacts.supportChatInSidebar;

  @ViewChild('sidebar', { read: TemplateRef, static: true }) ref!: TemplateRef<unknown>;

  ngOnInit(): void {
    const sideBarType: string = this.isRightSidebar ? 'sidebar-left' : 'sidebar';

    this.layoutQuery.sidebar$.pipe(
      switchMap((state) => state ? this.dialog.open(this.ref, { panelClass: sideBarType }).closed : NEVER),
      takeUntilDestroyed(this.destroy),
    ).subscribe(() => this.layoutData.toggleSidebar(false));
  }

  close(route?: Parameters<SpNavigation['navigate']>[0]): void {
    route ? this.navigation.navigate(route).then(() => this.dialog.closeAll()) : this.dialog.closeAll();
  }

  private getUserNickname$(): Observable<UserInfo['personal']['nick']> {
    return this.userQuery.isEmailFaked$.pipe(
      switchMap((isFaked) => isFaked ? NEVER : this.userQuery.nickname$),
    );
  }

  private buildNavigation(): Observable<Array<SidebarNavigationSection>> {
    const { blocking$, balance$ } = this.userQuery;
    const { sidebarSections$ } = this.gamesQuery;
    const config = this.configQuery.modules.sidebar.menu;

    return combineLatest([ blocking$, balance$, sidebarSections$ ]).pipe(
      map(([ blocking, balance, gameSections ]) => {
        const availableSections = { games: !blocking?.lobby, poker: true, sports: !blocking?.betting };

        return config.reduce<Array<SidebarNavigationSection>>((acc, section: SidebarNavigationSection) => {
          // add game sections
          if (section.isAdminSection) {
            section.items = !availableSections.games ? [] : gameSections.map(({ key, image, name }) => ({
              url: key, icon: `${key}.svg`, image, label: name[ this.localization.currentLanguage ],
            }));
          }

          // check game blocked
          section.items = section.items.filter((item) => {
            if (!this.authorized || !item.key || !(item.key in availableSections)) { return section; }
            return availableSections[ item.key.toLowerCase() as keyof typeof availableSections ];
          });

          // test on reward
          if (this.authorized && !this.userQuery.level) {
            section.items = section.items.filter((item) => item.key !== 'rewards');
          }

          // check for self blocking
          if (blocking?.selfBlocked) {
            section.items = section.items.filter((item) => !item.selfBlocking);
          }

          // show balance
          if (balance) {
            section.items = section.items.map((item) => {
              return item.key === 'bonuses' ? { ...item, amount: balance.bonusCount || null } : item;
            });
          }

          // auth check
          if (!this.authorized) {
            section.items = section.items.filter((item) => !item.forAuthorized);
          }

          section.items = section.items.map((item) => {
            return item.url.includes('rewards') ? { ...item, activeOptions: { exact: true } } : item;
          });

          return [ ...acc, section ];
        }, []);
      }),
    );
  }
}
