@if (rates(); as rates) {
  <section class="exchange__rates">
  <div class="exchange__scroll">
    @for (currency of rates.currencies; track currency.name) {
      <div class="exchange__rate">
        <span class="currency">{{ currency.name }}</span> {{ currency.rate | rateNormalizer : rates.default?.name }}
      </div>
      <div class="exchange__rate exchange__rate--extra">
        <span class="currency">{{ currency.name }}</span> {{ currency.rate | rateNormalizer : rates.default?.name }}
      </div>
    }
  </div>
</section>
}
