export enum WinpayPaymentMethods {
  BankCardDeposit = 113,
  BankCardWithdrawal = 114,
  ApplePayDeposit = 121,
  GooglePayDeposit = 122,
  SamsungPayDeposit = 123,
  BankCardVisaWithdrawal = 161,
  CopyBankCardDeposit = 185,
  SepaWithdrawal = 263,
  DepositH2H = 457
}
