import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { BankCard } from '@portal/payment/data';
import { CardInfo, PaymentCardForm } from '@portal/payment/shared';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  selector: 'gg-payment-cards-card-change',
  templateUrl: './card-change.component.html',
  styleUrls: [ './card-change.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardChangeComponent {
  readonly submitButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };

  @Input({ required: true }) cards: Nullable<Array<BankCard>>;
  @Input({ required: true }) form: Nullable<PaymentCardForm>;
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  get activeCard(): Nullable<string> { return this.form?.controls.id?.value; }

  selectCard(card: CardInfo): void {
    if (!this.form) { return; }

    this.form.reset();
    this.form.patchValue(card);
  }
}
