import { Validators } from '@angular/forms';
import { USER_DATA_INFO } from '@portal/cashier/components/card/constants/user-data-form-declaration.constant';
import { CustomValidators } from '@portal/shared/helpers';

export const USER_DATA_INFO__FULL_INFO_BIRTH = {
  ...USER_DATA_INFO,
  birth_date: [
    '',
    [
      Validators.required,
      CustomValidators.birthday
    ]
  ],
};
