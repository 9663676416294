import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpPlatform } from '@libs/cdk';
import { setLoading } from '@libs/store';
import { arrayalize, Nullable } from '@libs/utils';
import {
  createCasinoGames,
  createGame,
  createGameProviders,
  createGameSearch,
  createGameSections,
  createInitialGameBatch,
  createRecentGames,
  Game,
  GameProvider,
  GameQuery,
  GameSection,
  GamesStore,
  GamesWithTotal
} from '@portal/games/data';
import {
  GameProvidersResponse,
  GameSectionsResponse,
  MainGamesResponse,
  RecentGamesResponse,
  SectionGamesResponse,
  SingleGameResponse
} from '@portal/games/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GamesData {

  private readonly http = inject(HttpClient);
  private readonly store = inject(GamesStore);
  private readonly platform = inject(SpPlatform);

  private readonly games = this.store.entity('games');

  synchronize(): void {
    forkJoin([ this.loadGameSections(), this.loadGameProviders() ]).subscribe(() => {
      this.store.update({ sync: true });
    });
  }

  loadGames(query: GameQuery, loadMore?: boolean): Observable<GamesWithTotal> {
    const source = query.section || query.provider || query.all;
    return this.http.get<SectionGamesResponse>(this.createQueryUrl('/api/2/casino_games', query)).pipe(
      map((res) => createCasinoGames(res, source ?? '')),
      tap((games) => this.updateGamesAndTotal(games, loadMore))
    );
  }

  findGames(query: Omit<GameQuery, 'section' | 'provider'>): Observable<Omit<GamesWithTotal, 'total'> & {
    total: number;
  }> {
    return this.http.get<SectionGamesResponse>(this.createQueryUrl('/api/2/casino_games', query)).pipe(
      setLoading(this.games),
      map((res) => createGameSearch(res)),
      tap(({ games }) => this.games.add(games))
    );
  }

  loadGame(query: string): Observable<Game> {
    return this.http.get<SingleGameResponse>(`/api/2/casino_games/slug/${query}`).pipe(
      catchError(() => this.http.get<SingleGameResponse>(`/api/2/casino_games/ext_id/${query}`).pipe(
        catchError((err) => throwError(() => ResponseFactory.error(err))))
      ),
      map(({ _item }) => createGame(_item)),
      tap((game) => this.saveSelectedGame(game))
    );
  }

  startGame(id: Nullable<string>, section: string, index: Nullable<number>): Observable<unknown> {
    return this.http.post('/api/2/casino_main_page_games', {
      game_id: id,
      game_category: section,
      game_index: index
    }).pipe(first());
  }

  saveSelectedGame(currentGame: Nullable<Game>): void {
    this.store.update((state) => ({
      ...state,
      currentGame
    }));
  }

  clearSelectedGame(): void {
    this.store.update((state) => ({
      ...state,
      currentGame: null
    }));
  }

  loadGameSections(): Observable<Array<GameSection>> {
    return this.http.get<GameSectionsResponse>('/api/2/casino_sections').pipe(
      map(({ _items }) => createGameSections(_items)),
      tap((sections) => this.store.update({ sections }))
    );
  }

  loadGameProviders(): Observable<Array<GameProvider>> {
    return this.http.get<GameProvidersResponse>('/api/2/casino_providers_new').pipe(
      map(({ _items }) => createGameProviders(_items)),
      tap((providers) => this.store.update({ providers }))
    );
  }

  loadInitialGamesBatch(): Observable<GamesWithTotal> {
    return this.http.get<MainGamesResponse>(this.createQueryUrl('/api/2/casino_main_page_games')).pipe(
      map((allGames) => createInitialGameBatch(allGames)),
      tap((games) => this.updateGamesAndTotal(games))
    );
  }

  loadRecentGames(): Observable<GamesWithTotal> {
    return this.http.get<RecentGamesResponse>(this.createQueryUrl('/api/2/casino_recent_games')).pipe(
      map(({ _item: { last_games } }) => last_games),
      map((res) => createRecentGames(res)),
      tap((recent) => this.store.update({ recent: recent.games }))
    );
  }

  sendSectionOnScrollEvent(): void {
    this.http.post('/api/2/casino_main_page_games_events', {
      is_scrolled_to_recommended: true
    }).pipe(first()).subscribe();
  }

  private createQueryUrl(url: string, query?: GameQuery): string {
    url = `${url}?device=${this.platform.type.mobile ? 'mobile' : 'web'}`;
    if (!query) { return url; }
    return Object.entries(query).reduce((acc, [ key, value ]) => `${acc}&${key}=${value}`, url);
  }

  private updateGamesAndTotal(response: GamesWithTotal | Array<GamesWithTotal>, loadMore?: boolean): void {
    arrayalize(response).forEach(({ games, total, limit }) => {
      this.store.update((state) => ({
        ...state,
        total: { ...state.total, ...total },
        limit: { ...state.limit, ...limit }
      }));

      if (loadMore) {
        this.games.add(games);
      } else {
        this.games.set(games);
      }
    });
  }
}
