import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SpCDKModule, SpConnector } from '@libs/cdk';
import { GlobalWindow, Nullable, watch } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { ThemeModule } from '@portal/config';
import { SummaryComponent } from '@portal/payment';
import { PostActionAbstract } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';
import { DottedLineComponent } from '@portal/shared/components/content/dotted-line';
import { ConnectedEvents } from '@portal/shared/types';
import { switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  templateUrl: './verification-dialog.component.html',
  styleUrls: [ './verification-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ ControlsModule, SpCDKModule, ClipboardCopyComponent, SummaryComponent, DottedLineComponent, ThemeModule ],
})
export class VerificationDialogComponent extends PostActionAbstract implements OnInit, OnDestroy {
  private readonly dialog = inject(Dialog);
  private readonly window = inject(WINDOW) as GlobalWindow;
  private readonly connector = inject(SpConnector<ConnectedEvents>);

  private readonly watcher = watch();

  private ref: Nullable<DialogRef<Nullable<string>>> = null;
  readonly address = `${this.builder.snapshot.data?.additional?.address} ${this.builder.snapshot.data?.additional?.city}`;

  code: Nullable<string> = null;
  saved = false;

  @ViewChild('tpl', { static: true, read: TemplateRef }) tpl: Nullable<TemplateRef<unknown>> = null;

  ngOnInit(): void {
    this.code = this.result.data.code;

    if (this.processErrors(!this.code)) { return; }

    this.createDialog();
  }

  ngOnDestroy(): void { this.watcher.unsubscribe(); }

  closeDialog(): void {
    this.builder.reopen();
    this.close();
  }

  close(code?: string): void { this.ref?.close(code || null); }

  private createDialog(): void {
    this.ref = this.tpl ? this.dialog.open(this.tpl, { panelClass: [ 'bottom-center' ] }) : null;
    this.watcher.add(this.ref?.closed.pipe(
      filter(Boolean),
      tap((code) => this.builder.updatePayment({ data: { additional: { verification_code: code } } })),
      switchMap(() => this.paymentData.create(this.builder.snapshot, this.window)),
    ).subscribe(({ status }) => {
      this.setStatus({ status });
      if (this.builder.snapshot.operation === 'deposit') {
        this.connector.sendEvent({ event: 'analytics:create-custom-pixel', payload: { type: 'cashier' } });
      }
    }));
  }
}
