import { FormControl, FormGroup } from '@angular/forms';
import { validationRules } from '@portal/shared/constants';

export const createLoginForm = (): FormGroup<{
  login: FormControl<string>;
  password: FormControl<string>;
  recaptchaToken: FormControl<string>;
}> => new FormGroup({
  login: new FormControl<string>('', { nonNullable: true, validators: validationRules.login }),
  password: new FormControl<string>('', { nonNullable: true, validators: validationRules.password }),
  recaptchaToken: new FormControl<string>('', { nonNullable: true })
});
