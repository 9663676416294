import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Nullable } from '@libs/utils';
import { PaymentCardsData } from '@portal/payment/data';
import { CardInfo } from '@portal/payment/shared';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  selector: 'gg-payment-cards-card-delete',
  templateUrl: './card-delete.component.html',
  styleUrls: [ './card-delete.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDeleteComponent {
  private readonly destroy$ = inject(DestroyRef);
  private readonly cardsData = inject(PaymentCardsData);

  readonly cancelButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Green };
  readonly submitButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.White };

  @Input({ required: true }) card: Nullable<CardInfo>;
  @Input() index: Nullable<number>;

  @Output() slideDeleted: EventEmitter<void> = new EventEmitter();

  deleteBankCard(id: Nullable<string>): void {
    if (!id) { return; }
    this.cardsData.delete(id).pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => {
      this.slideDeleted.emit();
    });
  }

  close(id: Nullable<string>): void {
    this.cardsData.updateCardState(id, 'default');
  }
}
