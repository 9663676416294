<section *spLet="paymentMethods | async as pm" class="wrapper" [ngClass]="{'grid': !showNoPaymentsMsg}">
  <ng-container *ngIf="!showNoPaymentsMsg; else noPaymentsMsg">
    <pd-payment-button
      *ngIf="showBankCardMethodsButton"
      [routerLink]="['/cashier', section, 'card-validation']"
    ></pd-payment-button>
    <pd-item
      *ngFor="let method of paymentMethods$ | async"
      [paymentMethod]="method"
      [section]="section"
      (click)="setActiveMethod(method.id, method.enabled_for_non_confirmed_phone)"
    ></pd-item>
  </ng-container>
  <ng-template #noPaymentsMsg>
    <p spMsg="CASHIER.NO_PAYMENTS" class="title-no-payments"></p>
  </ng-template>
</section>

