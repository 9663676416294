import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule, SpNotification } from '@libs/cdk';
import { ConfigQuery } from '@portal/config';
import { SectionNavigationComponent } from '@portal/payment';
import { HistoryOperationComponent, NavigationComponent } from '@portal/payment/components';
import { PaymentHistory, PaymentHistoryData, PaymentParamsData, PaymentParamsQuery } from '@portal/payment/data';
import { HistoryParams } from '@portal/payment/shared';
import { ControlsModule } from '@portal/shared/components';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, NavigationComponent, HistoryOperationComponent, ControlsModule, SectionNavigationComponent ],
  templateUrl: './payment-history.component.html',
  styleUrls: [ './payment-history.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentHistoryComponent {
  private readonly configQuery = inject(ConfigQuery);
  private readonly notification = inject(SpNotification);
  private readonly paramsData = inject(PaymentParamsData);
  private readonly historyData = inject(PaymentHistoryData);
  private readonly paramsQuery = inject(PaymentParamsQuery);

  private readonly cashier = this.configQuery.modules.cashier;

  readonly show$ = this.paramsQuery.historyParams$.pipe(map(({ show }) => show));
  readonly history$ = this.show$.pipe(this.getHistoryByParams());

  readonly isExtended = this.cashier.enableHistoryCardInfo;

  changeSection(show: HistoryParams['show']): void { this.paramsData.setHistory({ show }); }

  makeDeposit(): void { this.paramsData.setPayment({ section: 'deposit' }); }

  cancel(history: PaymentHistory, show: HistoryParams['show']): void {
    this.historyData.cancel(history.id).subscribe({
      next: () => this.changeSection(show),
      error: () => this.notification.error('NOTIFICATIONS.MESSAGES.CANCEL_TRANSACTION_ERROR'),
    });
  }

  private getHistoryByParams(): (show$: Observable<HistoryParams['show']>) => Observable<Array<PaymentHistory>> {
    return (show$) => show$.pipe(
      switchMap((show) => this.historyData.load(show, this.cashier.showTransactionsAfter)),
    );
  }
}
