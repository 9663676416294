import { ReceivedBannerDto } from '@portal/banners/data';

export const APP_SIDEBAR_BANNER: ReceivedBannerDto = {
  _id: 'app-sidebar-banners',
  type: 'download_app_menu',
  template: { page: 'menu', section: 'bottom', order: 0 },
  showToAnonymous: true,
  showToAuthorized: true,
  url: '',
  additionalUrl: '',
  dateExp: null,
  title: null,
  label: null,
  description: null,
  image: null,
  mobileImage: null,
  callToAction: null,
  additionalAction: null,
  imageAlt: null
};
