// @ts-nocheck

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  Cashier,
  CashierStore,
  CryptoCurrency,
  HistoryBySection,
  ThreeDSecurePaymentData
} from '@portal/cashier/data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CashierQuery extends Query<Cashier> {
  readonly threeDSPaymentData$: Observable<ThreeDSecurePaymentData> = this.select('threeDSPaymentData');
  readonly loading$: Observable<boolean> = this.select('loading');
  readonly errorMessage$: Observable<string> = this.select('errorMessage');
  readonly cryptoCurrency$: Observable<Array<CryptoCurrency>> = this.select('cryptoCurrencies');
  readonly activeCryptoCurrency$: Observable<CryptoCurrency> = this.select('selectedCryptoCurrencies');
  readonly conversionRate$: Observable<string> = this.select('conversionRate');
  readonly history$: Observable<HistoryBySection> = this.select('history');

  get activeCryptoCurrency(): CryptoCurrency {
    return this.getValue().selectedCryptoCurrencies;
  }

  constructor(protected repository: CashierStore) {
    super(repository);
  }
}
