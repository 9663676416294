<ng-template #tpl>
  <section class="approve">
    <div class="approve__wrapper">
      <img src="assets/images/icons/cross.svg" class="approve__close" (click)="close()" alt=""/>
      <span class="approve__title" spMsg="CASHIER.CARD.MAKE_TRANSFER"></span>
      <p class="approve__info" spMsg="CASHIER.CARD.OPEN_BANK_APP"></p>
      <div class="approve__amount">{{ order?.amount | priceNormalizer }}</div>
      <div class="approve__card">
        <span class="approve__card__title" spMsg="CASHIER.CARD.NUMBER"></span>
        <div class="approve__card__num">
          <span>{{ order.card }}</span>
          <gg-clipboard-copy [copyValue]="order.card"/>
        </div>
        <div class="approve__card__time-block" *ngIf="order.till">
          <span spMsg="CASHIER.CARD.TIME_LEFT"></span>
          <div class="approve__card__timer" *spLet="timer$ | spAsync as timer">{{ timer.time | amDateFormat: 'mm:ss' }}</div>
        </div>
      </div>
      <p class="approve__card__info" spMsg="CASHIER.CARD.UNIQUE_NUM"></p>
      <div class="approve__extra-info">
        <img src="assets/images/cashier/info.svg" alt=""/>
        <span spMsg="CASHIER.CARD.NO_COMMENTS"></span>
      </div>
      <gg-button class="approve__button" (click)="checkTransfer()"><sp-msg msg="CASHIER.CARD.CONFIRM_TRANSFER"/></gg-button>
    </div>
  </section>
</ng-template>
