import { isDefined, Nullable } from '@libs/utils';
import { ThemeComparer } from '@portal/config/shared';

export class Theme {
  constructor(
    readonly palette: 'purple' | 'silver',
    readonly structure: 'rounded' | 'flat'
  ) {}

  isMatch(theme?: Nullable<ThemeComparer>): boolean {
    if (!isDefined(theme)) { return false; }

    return typeof theme === 'string'
      ? this.palette === theme || this.structure === theme
      : this.palette === theme.palette && this.structure === theme.structure;
  }
}
