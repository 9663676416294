import { inject, Injectable } from '@angular/core';
import { PaymentStore } from '@portal/payment/data';
import { emptyParams, HistoryParams, isFinancialOperation, PaymentParams, WidgetParams } from '@portal/payment/shared';

@Injectable({ providedIn: 'root' })
export class PaymentParamsData {
  private readonly store = inject(PaymentStore);
  private readonly empty = emptyParams;

  update(params?: WidgetParams): void {
    if (!params?.section) { params = { section: 'deposit', container: '', group: 'bank_card' }; }

    params && ((isFinancialOperation(params.section)) || 'method' in params) && this.setPayment(params as PaymentParams);
    params && params.section === 'history' && this.setHistory(params);
    params && params.section === 'transfers' && this.setTransfer();
  }

  clear(): void { this.store.update({ params: this.empty as WidgetParams }); }

  setPayment(params?: Partial<PaymentParams>): void {
    const current = this.store.get('params') as PaymentParams;

    let result: PaymentParams = { ...this.empty, section: params?.section || current.section || 'deposit' };

    if (current.status && !params?.status) { result = { ...result, method: undefined }; }

    const isInitial = !current.section;

    result.group = isInitial ? params?.group : params?.group || current.group;
    result.container = isInitial ? params?.container : params?.container || current.container;

    const containers = this.store.get('container')[ result.section ];
    const activeContainer = containers?.length ? containers?.find(({ id }) => id === result.container) || containers[ 0 ] : null;

    const sameContainer = !isInitial && current.container === activeContainer?.id;

    const groups = activeContainer?.groups;

    if (params?.group === null) { result.group = undefined; }

    const activeGroup = groups?.length
      ? groups.length === 1 ? groups[ 0 ] : sameContainer && groups.find(({ id }) => id === result.group) || null
      : null;

    result = { ...result, container: activeContainer?.id, group: activeGroup?.id, method: params?.method };

    const firstMethodInGroup = activeGroup?.methods[ 0 ];
    const isSingleMethodGroup = Number(activeGroup?.methods.length) === 1;
    const inGroupMethod = result?.method && activeGroup?.methods.find(({ id }) => result.method === id);

    if (isInitial && activeGroup?.type === 'generic' && result.section === 'deposit') { result.method = undefined; }
    if (isInitial && activeGroup?.type === 'p2p_sbp' && result.section === 'deposit') { result.method = undefined; }
    if (isInitial && activeGroup?.type === 'p2p_card' && result.section === 'deposit') { result.method = undefined; }
    if (!isInitial && activeGroup?.id !== result.group) { result.method = undefined; }
    if (result.method && !inGroupMethod) { result.method = undefined; }

    if (!result.method && isSingleMethodGroup) { result.method = firstMethodInGroup?.id; }
    if (!result.method && !isSingleMethodGroup && result.section === 'deposit' && activeGroup?.type === 'crypto') { result.method = firstMethodInGroup?.id; }
    if (params?.isContainerSelected && groups && groups?.length > 1) {
      result.method = undefined;
      result.group = undefined;
    }

    result = { ...result, amount: params?.amount, status: params?.status, invoice: params?.invoice };

    this.store.update({ params: { ...result } });
  }

  setHistory(params?: Partial<HistoryParams>): void {
    this.store.update({ params: { ...this.empty, section: 'history', show: 'all', ...params } });
  }

  setTransfer(): void {
    this.store.update({ params: { ...this.empty, section: 'transfers' } });
  }
}
