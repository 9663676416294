import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidStore } from '@libs/store';
import { Nullable } from '@libs/utils';
import { ErrorResponse } from '@portal/shared/helpers';
import { VerificationMethod } from '@portal/verification/data/core';

export type VerificationState = {
  methods: Array<Nullable<VerificationMethod>>;
  qrCode: Nullable<string>;
  loading: boolean;
  errorType: Nullable<ErrorResponse>;
  sync: boolean;
};

const initial: SteroidConfig<VerificationState> = {
  options: { name: 'verification' },
  initial: {
    methods: [],
    qrCode: null,
    loading: false,
    errorType: null,
    sync: false
  }
};

@Injectable({ providedIn: 'root' })
export class VerificationStore extends SteroidStore<VerificationState> {
  constructor() { super(initial); }
}
