export enum OneTnMethods {
  DepositBTC = 362,
  Withdrawal = 351,
  DepositLTC = 355,
  DepositETH = 356,
  DepositUSDTE = 357,
  DepositUSDTT = 358,
  DepositTest = 359,
  WithdrawalTest = 367,
  WithdrawalUSDTT = 368,
  WithdrawalUSDTE = 369
}
