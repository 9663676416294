<div class="history-block">
  <div class="history-block__header">
    <div class="history-block__type" [ngClass]="operation.operationType">
      <img class="history-block__img history-block__img--{{operation.operationType}}"
           [src]="'{project}/images/cashier/arrow-' + operation.operationType +'.svg' | path"
           [alt]="operation.operationType"/>
      <sp-msg [spMsg]="'CASHIER.HISTORY.TRANSACTION.' + operation.operationType.toUpperCase()"></sp-msg>
    </div>
    <div class="history-block__date">{{operation.date}}</div>
  </div>
  <div *ngIf="(state$ | async) === 'normal' else cancelScreen">
    <div class="history-block__amount">{{operation.readableAmount | currencyNormalizer}}</div>
    <div class="history-block__card-info" *ngIf="enableHistoryCardInfo">
      <div class="history-block__method">{{operation.paymentMethodName}}</div>
      <div class="history-block__card">{{operation.paymentAccount}}</div>
    </div>
    <div class="history-block__status history-block__status--{{operation.status}}" *ngIf="!!operation.status">
      <sp-msg [spMsg]="'CASHIER.HISTORY.TRANSACTION.STATUSES.' + operation.status.toUpperCase()"></sp-msg>
    </div>
    <div class="history-block__declined-info" *ngIf="operation.declined">{{operation.reason}}</div>
    <gg-button
      *ngIf="operation.cancellable"
      class="history-block__cancel-button"
      [settings]="cancelButton"
      (click)="toggleCancel(true)"
    >
      <sp-msg spMsg="CASHIER.HISTORY.TRANSACTION.CANCEL"></sp-msg>
    </gg-button>
  </div>
  <ng-template #cancelScreen>
    <img src="assets/images/icons/question.svg" class="history-block__question-icon" alt=""/>
    <div class="history-block__cancel-question" spMsg="CASHIER.HISTORY.TRANSACTION.APPROVE_CANCEL"></div>
    <div class="history-block__cancel-buttons">
      <gg-button class="history-block__cancel-buttons--yes" [settings]="questionButtonYes" (click)="cancel()">
        <sp-msg spMsg="COMMON.YES"></sp-msg>
      </gg-button>
      <gg-button [settings]="questionButtonNo" (click)="toggleCancel(false)">
        <sp-msg spMsg="COMMON.NO"></sp-msg>
      </gg-button>
    </div>
  </ng-template>
</div>
