import { FairPokerRule } from '@portal/poker';

export const FAIR_POKER_RULES: Array<FairPokerRule> = [
  {
    title: 'POKER.FAIR_COMPETITION',
    description: 'POKER.FAIR_COMPETITION_DESCRIPTION'
  },
  {
    title: 'POKER.BAN_ON_ADDITIONAL_SOFTWARE',
    description: 'POKER.BAN_ON_ADDITIONAL_SOFTWARE_DESCRIPTION'
  },
  {
    title: 'POKER.GAME_WITHOUT_BOTS',
    description: 'POKER.GAME_WITHOUT_BOTS_DESCRIPTION'
  }
];
