import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CreateTransferDto } from '@portal/payment/data';
import { PaymentCreateTransferResponse } from '@portal/payment/shared';
import { ResponseFactory } from '@portal/shared/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentTransfersData {
  private readonly http = inject(HttpClient);

  createTransfer(transfer: CreateTransferDto): Observable<string> {
    return this.http.post<PaymentCreateTransferResponse>('/api/payments/transfers/create/', transfer).pipe(
      map((response) => response._status),
      catchError((err) => throwError(() => ResponseFactory.error(err)))
    );
  }
}
