import { inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Nullable } from '@libs/utils';

@Injectable()
export class SpMetaService {
  private readonly meta = inject(Meta);
  private readonly title = inject(Title);

  setTitle(title: string): void { this.title.setTitle(title); }

  updateTag(def: MetaDefinition, selector?: string): void { this.meta.updateTag(def, selector); }

  updateTags(defs: Array<MetaDefinition>): void { defs.forEach(def => this.updateTag(def)); }

  setMeta(title: Nullable<string>, defs?: Array<MetaDefinition>): void {
    title && this.setTitle(title);
    defs && this.updateTags(defs);
  }
}
