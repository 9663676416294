<ng-container *spLet="isDeposit$ | spAsync as deposit">
  <ng-container *spLet="activeMethod$ | spAsync as method">
    @if (stepsCount) {
      <ng-container *spLet="commissionWarning$ | spAsync as commissionWarning">
        <ng-container *spLet="commissionInfo$ | spAsync as commissionInfo">
          <gg-payment-commission-warning *ngIf="commissionWarning" [amount]="builder.snapshot.amount" (closeCommission)="closeCommissionWarning($event)"/>
          <gg-payment-commission-info *ngIf="commissionInfo" (closeCommission)="closeCommissionInfo()"/>
          <ng-container *ngIf="method && !commissionWarning && !commissionInfo">
            <gg-stepper [(selectedIndex)]="step" class="р2р-flow__stepper" [beforeChange]="submitStep.bind(this)">
              <cdk-step *ngFor="let control of form?.controls">
                <ng-container *ngIf="isGenerated(control) | spAsync as gen">
                  <sp-msg
                    *ngIf="method.data?.payment === gen.form"
                    class="р2р-flow__title"
                    [spMsg]="deposit ? 'CASHIER.CARD.USER_DATA_FORM_TITLE_DEPOSIT' : 'CASHIER.CARD.USER_PAYMENT_WITHDRAWAL_DATA_TITLE'"
                  />
                  <gg-payment-generated-form [declaration]="gen?.form" [formControl]="gen?.control" class="р2р-flow__form"/>
                </ng-container>
              </cdk-step>
              <gg-button
                *ngIf="stepsCount"
                ggStepperNext
                class="р2р-flow__button"
                [settings]="submitButton"
                [disabled]="invalid"
              ><sp-msg msg="CASHIER.CARD.MAKE_{{deposit ? 'DEPOSIT' : 'WITHDRAW'}}"/></gg-button>
            </gg-stepper>
          </ng-container>
        </ng-container>
      </ng-container>
    }
  </ng-container>
</ng-container>
