import { Injectable } from '@angular/core';
import { SteroidConfig, SteroidEntity, SteroidStore } from '@libs/store';
import { Seo } from '@portal/seo/data';

export type SeoState = {
  entities: {
    texts: SteroidEntity<Seo>;
  };
  sync: boolean;
};

const initial: SteroidConfig<SeoState> = {
  options: { name: 'seo' },
  initial: { sync: false },
  entities: { texts: { idKey: 'id' } }
};

@Injectable({ providedIn: 'root' })
export class SeoStore extends SteroidStore<SeoState> {
  constructor() { super(initial); }
}
