
<div class="commission-info">
  <img src="assets/images/icons/cross.svg" alt="" class="commission-info__close" (click)="close()"/>

  <div class="commission-info__title" spMsg="CASHIER.CARD.DYNAMIC_COMMISSION"></div>

  <p class="commission-info__text">
    <sp-msg msg="CASHIER.CARD.FROM_LAST_DEPOSIT" spaces="end"></sp-msg>
    <span class="commission-info__text commission-info__text--bold">
      <sp-msg msg="CASHIER.CARD.LESS_HOURS"
            [params]="$any({from: fee.now.last_deposit_date_in_hours})"></sp-msg>
    </span>,
    <sp-msg msg="CASHIER.CARD.COMMISSION_FEE_IS" spaces="wrap"></sp-msg>
    {{commission}}%
  </p>

  <p class="commission-info__text" spMsg="CASHIER.CARD.ASK_WITHDRAW_LATER"></p>

  <ng-container *ngFor="let rule of $any(commissionRules.rules)">
    <span class="commission-info__text commission-info__text--bold commission-info__text--margin">
      <sp-msg msg="CASHIER.CARD.FEE_RULE" [params]="{from: rule.left, rate: rule.rate}"></sp-msg>
    </span>
  </ng-container>

  <gg-button class="commission-info__btn" [settings]="closeButton" (click)="close()">
    <sp-msg msg="COMMON.CLOSE"></sp-msg>
  </gg-button>
</div>
