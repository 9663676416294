import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { normalizeFloatAmount, Nullable } from '@libs/utils';
import { InputAbstract } from '@portal/shared/components/controls/input.abstract';
import { fromEvent, merge, Subscription } from 'rxjs';

export interface ICashierStep {
  from: number;
  to?: number;
  step: number;
}

export interface ICashInputSettings {
  min: string | number;
  max: string | number;
  currencySymbol: string;
  steps: Array<ICashierStep>;
  roundingStep: Nullable<number>;
  isPlusCommission?: boolean;
  rate?: number;
  isWithdraw?: boolean;
}

@Component({
  selector: 'gg-input-cash',
  templateUrl: './input-cash.component.html',
  styleUrls: [ './input-cash.component.scss', './input-cash.purple.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputCashComponent),
    multi: true
  } ],
  encapsulation: ViewEncapsulation.None
})
export class InputCashComponent extends InputAbstract implements OnInit, AfterViewInit, OnDestroy {
  private readonly numbersArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 0 ];
  private events: Nullable<Subscription> = null;
  private maxAmountWithoutCommission = 0;
  private maxAmountRoundingWithoutCommission = 0;

  @Input() settings: Nullable<ICashInputSettings>;
  @Output() changeAmount = new EventEmitter();
  @Output() makeRequest = new EventEmitter();

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.settings?.isPlusCommission && !!this.settings.rate) {
      this.makeMaxAmountWithoutCommission();

      if (this.settings.roundingStep) { this.makeMaxAmountRoundingWithoutCommission(); }
    }
  }

  makeMaxAmountWithoutCommission(): void {
    let max = Number(this.settings?.max);
    let maxAmountWithCommission = this.maxAmountWithoutCommission;
    do {
      maxAmountWithCommission = max + (max * Number(this.settings?.rate));
      this.maxAmountWithoutCommission = max;
      max -= this.currentStep;
    }
    while (maxAmountWithCommission > Number(this.settings?.max));
  }

  makeMaxAmountRoundingWithoutCommission(): void {
    const roundingStep = this.settings?.roundingStep ? this.settings?.roundingStep : 1;
    let maxRound = Math.floor(Number(this.settings?.max) / roundingStep) * roundingStep;
    let maxAmountRoundingWithCommission = this.maxAmountRoundingWithoutCommission;

    do {
      maxAmountRoundingWithCommission = Math.floor(maxRound + (maxRound * Number(this.settings?.rate)));
      this.maxAmountRoundingWithoutCommission = maxRound;
      maxRound -= roundingStep;
    }
    while (maxAmountRoundingWithCommission > Number(this.settings?.max));
  }

  override ngAfterViewInit(): void {
    if (!this.input) { return; }
    this.events = merge(
      fromEvent(this.input.nativeElement, 'focus'),
      fromEvent(this.input.nativeElement, 'blur'),
      fromEvent(this.input.nativeElement, 'input')
    ).subscribe((e: any) => {
      const isSymbolNumber = this.numbersArray.includes(Number(e.data));
      if (e.inputType === 'deleteContentBackward' || isSymbolNumber) { return; }
      this.writeValue(this.getCorrectValue(this.input?.nativeElement.value));
      if (e.type === 'blur') { this.changeAmount.emit(); }
    });
    super.ngAfterViewInit();
  }

  makeTransaction(): void {
    this.input?.nativeElement.blur();
    this.makeRequest.emit();
  }

  override ngOnDestroy(): void {
    this.events?.unsubscribe();
    super.ngOnDestroy();
  }

  increase(): void {
    this.writeValue(`${parseFloat(this.value) + this.currentStep}`);
    this.changeAmount.emit();
  }

  decrease(): void {
    this.writeValue(`${parseFloat(this.value) - this.currentStep}`);
    this.changeAmount.emit();
  }

  override writeValue(value: string): void {
    const val = this.getCorrectValue(value);
    if (this.input) {
      this.input.nativeElement.value = val;
    }
    super.writeValue(val);
  }

  get currentStep(): number {
    const value = parseFloat(this.value);
    const stepSetting = this.settings?.steps.find((step) => value >= step.from && (!step.to || step.to > value));
    return this.settings?.roundingStep ? this.settings?.roundingStep : stepSetting!.step;
  }

  getCorrectValue(value: string): string {
    const floatValue = Math.ceil(normalizeFloatAmount(value));
    const roundingStep = this.settings?.roundingStep ? this.settings?.roundingStep : 1;
    const floatRoundingBySettings = Math.floor(floatValue / roundingStep) * roundingStep;
    let floatValueMax = Number(this.settings?.max);
    let floatRoundingBySettingsMax = Math.floor(floatValueMax / roundingStep) * roundingStep;

    if (this.settings?.isPlusCommission && !!this.settings.rate) {
      const valueWithCommission = Number(value) + (Number(value) * this.settings.rate);

      if (valueWithCommission <= Number(this.settings.max)) {
        floatValueMax = Number(value);
      } else {
        floatValueMax = this.maxAmountWithoutCommission;
      }
      if (this.settings.roundingStep) {
        floatRoundingBySettingsMax = this.maxAmountRoundingWithoutCommission;
      }
    }

    switch (true) {
      case floatValue < Number(this.settings?.min):
        return `${this.settings?.min}`;
      case floatValue > floatValueMax:
        return this.settings?.roundingStep ? `${floatRoundingBySettingsMax}` : `${floatValueMax}`;
      case isNaN(floatValue):
        return this.settings?.isWithdraw ? `${this.settings.min}` : `${floatValueMax}`;
      case this.settings?.roundingStep !== null:
        return `${floatRoundingBySettings}`;
      default:
        return `${floatValue}`;
    }
  }
}
