<section *ngIf="isPaymentVerificationEnabled" class="payment-method-verification">
  <div class="payment-method-verification__title">
    <sp-msg msg="PROFILE.CARD_VERIFICATION.TITLE"/>
  </div>
  <a [routerLink]="['/user', 'verification', 'card']" class="payment-method-verification__method-cards">
    <div class="payment-method-verification__method">
      <img
        src="assets/images/profile/kycaid-verification/card-verification-icon.svg"
        alt="verification-by-card"
      />
      <p class="payment-method-verification__method-title">
        <sp-msg msg="PROFILE.CARD_VERIFICATION.CARD_DESCRIPTION"/>
      </p>
    </div>
  </a>
</section>
