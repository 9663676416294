import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommissionWarningDialogInterface } from '@portal/cashier/types/interfaces/dialog-data.interface';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { FeeInterface } from '../../../../types/interfaces/fee.interface';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-dynamic-commission-warning',
  templateUrl: './commission-warning-dialog.component.html',
  styleUrls: [ './commission-warning-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CommissionWarningDialogComponent {
  amount: number;
  fee: FeeInterface;
  amountWithCommission = false;
  commission: number;
  userCurrency: Nullable<string>;
  commissionRules;
  commissionAmount: string;
  amountAfterCommission: string;
  closeButton: IButton = { size: ButtonSizes.Large };

  get amountAfterCommissions(): Nullable<string> {
    return this.amountWithCommission ?
      `${Number(this.amount) + Number(this.commissionAmount)} ${this.userCurrency}` :
      `${Number(this.amountAfterCommission)} ${this.userCurrency}`;
  }

  constructor(
    private readonly userInfoQuery: UserQuery,
    private readonly dialogService: DialogRef,
    @Inject(DIALOG_DATA) private readonly data: CommissionWarningDialogInterface
  ) {
    this.userInfoQuery.currency$.subscribe((currency) => {
      this.userCurrency = currency;
    });
    this.amount = this.data.amount;
    this.amountWithCommission = this.data.amountWithCommission;
    this.fee = this.data.fee;
    this.commission = this.data.commission;
    this.commissionRules = this.data.commissionRules;
    this.commissionAmount = this.data.commissionAmount;
    this.amountAfterCommission = this.data.amountAfterCommission;
  }

  close(): void {
    this.dialogService.close();
  }

  paymentConfirmed(): void {
    this.dialogService.close('hide_dynamic');
  }
}
