import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromotionTypes } from '@portal/promotions/shared/types/promotion-types';
import { Promotion } from '@portal/promotions/data/entities';

export function filterPromotions(filters: PromotionTypes, promotions: Observable<Array<Promotion>>): Observable<Array<Promotion>> {
  const selectedFilters = Object.keys(filters).filter((promoFilter: string) => filters[promoFilter as keyof PromotionTypes]);
  return promotions.pipe(
    map((promos) => promos.filter((promotion: Promotion) => promotion.banner.isBannerEnabled && selectedFilters.includes(promotion.banner.bannerType)))
  );
}
