// @ts-nocheck

import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeleteCardDialogComponent } from '@portal/cashier/components/card-validation/components/delete-card-dialog/delete-card-dialog.component';
import { BankCard, CashierDataService, UserBankCardQuery } from '@portal/cashier/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { ErrorManager } from '@portal/shared/helpers';
import { Observable, Subscription } from 'rxjs';
import { Nullable } from '@libs/utils';

@Component({
  selector: 'gg-user-bank-card',
  templateUrl: './user-bank-card.component.html',
  styleUrls: [ './user-bank-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBankCardComponent implements OnDestroy {
  private readonly dialogService = inject(Dialog);
  private readonly cashierDataService = inject(CashierDataService);
  private readonly userCardsQuery = inject(UserBankCardQuery);

  private readonly subscriptions: Subscription = new Subscription();

  readonly newCardButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Gray };

  @Input() newCardForm: FormGroup;
  @Input() existCardForm: Nullable<FormGroup>;
  @Input() errors: Nullable<ErrorManager>;

  userBankCards$: Observable<Array<BankCard>> = this.userCardsQuery.availableCards$;
  isNewCardFormShown = false;
  selectedDeleteCard: Nullable<BankCard>;

  constructor() {
    const availableCards: Subscription = this.userCardsQuery.availableCards$.subscribe((cards) => {
      cards.length ? this.isNewCardFormShown = false : this.isNewCardFormShown = true;
    });
    this.subscriptions.add(availableCards);
  }

  ngOnDestroy(): void {
    !!this.subscriptions && this.subscriptions.unsubscribe();
  }

  selectCard(cardId: string): void {
    this.existCardForm?.get('selectedCard')?.setValue(cardId);
  }

  isCardActive(cardId: string): boolean {
    return this.existCardForm?.get('selectedCard')?.value === cardId;
  }

  showAddNewCardForm(): void {
    this.isNewCardFormShown = true;
    this.existCardForm?.get('selectedCard')?.setValue('');
  }

  openDeleteCardDialog(event: MouseEvent, cardData: BankCard): void {
    event.stopPropagation();
    this.selectedDeleteCard = cardData;
    const dialogRefSubscription: Subscription = this.dialogService.open(DeleteCardDialogComponent, { panelClass: 'bottom-center', data: cardData }).closed.subscribe(
      (cardId) => this.deleteCard(cardId)
    );
    this.subscriptions.add(dialogRefSubscription);
  }

  deleteCard(cardId: unknown): void {
    const userBankCardDeleting = this.cashierDataService.deleteUserBankCard(cardId).subscribe(
      () => this.closeDeleteDialog()
    );

    this.subscriptions.add(userBankCardDeleting);
  }

  closeDeleteDialog(): void {
    this.dialogService.closeAll();
  }
}
