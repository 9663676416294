export enum DocumentsTypes {
  passport_internal_1 = 'passport_internal_1',
  passport_internal_2 = 'passport_internal_2',
  id_card_1 = 'id_card_1',
  id_card_2 = 'id_card_2',
  ipn = 'ipn',
  DriverLicense = 'DRIVER_LICENSE_DOC',
  PaymentMethod = 'PAYMENT_METHOD_DOC',
  Selfie = 'SELFIE',
  ProofOfAddress = 'proof_of_address'
}
