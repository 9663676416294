import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Nullable } from '@libs/utils';
import { ConfigQuery } from '@portal/config';
import { ErrorManager } from '@portal/shared/helpers';
import { UserQuery } from '@portal/user';
import { UserData } from '@portal/user/data';
import {
  AUTH_METHODS,
  AUTH_METHODS_DETAILS,
  AuthMethod,
  AuthMethodDetails,
  AuthMethods,
  AuthQrCode,
  createTwoFactorAuthForm,
  TwoFactorState
} from '@portal/user/shared';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'gg-user-settings-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: [ './two-factor-authentication.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorAuthenticationComponent implements OnDestroy, OnInit {
  private readonly errorManager = inject(ErrorManager);
  private readonly userInfoQuery = inject(UserQuery);
  private readonly source = inject(ConfigQuery);
  private readonly userSettingsCommands = inject(UserData);

  private readonly watchers: Subscription = new Subscription();
  private readonly form: FormGroup = createTwoFactorAuthForm();
  private readonly errors: ErrorManager;

  readonly allMethods: Array<AuthMethod> = AUTH_METHODS;
  readonly isYaMethodEnabled = this.source.modules.settings.enableYa2fa;

  twoFactorState$: Observable<TwoFactorState.Enabled | TwoFactorState.Disabled> = new BehaviorSubject(TwoFactorState.Disabled);
  isMethodShown: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentMethod: Nullable<AuthMethodDetails> = null;
  methodIndex: number = 0;
  twoFactorSettings$: BehaviorSubject<AuthQrCode> = new BehaviorSubject<AuthQrCode>({
    secretCode: '',
    qrCode: ''
  });

  constructor() {
    this.errors = this.errorManager.setUp({ form: this.form });

    if (!this.isYaMethodEnabled) {
      this.isMethodShown.next(true);
      this.currentMethod = AUTH_METHODS_DETAILS.get(AuthMethods.Google);
    }
  }

  ngOnInit(): void {
    this.twoFactorState$ = this.userInfoQuery.otpVerification$.pipe(
      distinctUntilChanged((prev, curr) => prev?.isActive === curr?.isActive),
      map((verification) => {
        if (verification && verification.isActive) {
          return TwoFactorState.Enabled
        } else {
          const userSettingsCommandsSub = this.userSettingsCommands.getQr().subscribe((res) => {
            this.twoFactorSettings$.next(res);
          });

          this.watchers.add(userSettingsCommandsSub);

          return TwoFactorState.Disabled;
        }
      })
    )
  }

  changeMethod(methodType: AuthMethods, index: number): void {
    this.isMethodShown.next(true);
    this.currentMethod = AUTH_METHODS_DETAILS.get(methodType);
    this.methodIndex = index;
  }

  ngOnDestroy(): void {
    this.watchers.unsubscribe();
  }
}
