import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'gg-payment-bonuses-selector',
  templateUrl: './bonuses-selector.component.html',
  styleUrls: [ 'bonuses-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusesSelectorComponent {
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly bonusesData = inject(BonusesData);
  private readonly isCryptoMethodSubject = new BehaviorSubject<Nullable<boolean>>(false);

  readonly applyButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };

  bonusesList: Nullable<Observable<Array<Bonus>>> = null;

  @Input()
  set maxMethodPrice(value: Nullable<number>) {
    this.bonusesList = this.bonusesQuery.selectAvailableDepositBonuses(String(value));
  }

  @Input()
  set isCryptoMethod(value: Nullable<boolean>) {
    this.isCryptoMethodSubject.next(value || false);
  }

  @Output() selected: EventEmitter<Nullable<Bonus>> = new EventEmitter<Nullable<Bonus>>();

  get isCryptoMethod(): Nullable<boolean> {
    return this.isCryptoMethodSubject.value;
  }

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  apply(): void {
    this.selected.emit(this.activeBonus);
  }

  bonusSelected(bonus?: Nullable<Bonus>): void {
    bonus ? this.bonusesData.selectBonus(bonus) : this.bonusesData.unselectBonus()
  }
}
