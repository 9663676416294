<ng-container *spLet="isCrypto$ | spAsync as crypto">
  <ng-container *spLet="isDeposit$ | spAsync as deposit">
    <ng-container *spLet="limits$ | spAsync as limits">
      <mat-sidenav-container class="payment-financial {{statusClass}}">
        <mat-sidenav-content class="payment-financial__content">
          <ng-container *ngIf="status$ | spAsync as status else page" [ggDynamicComponent]="status"/>
          <ng-template #page>
            <ng-container *spLet="message$ | spAsync as message">
              @if (!deposit && isMobile() && !message) {
                <gg-payment-user-balance/>
              }
              @if (!(depositsDisabled$ | spAsync) && deposit || !(message && isMobile())) {
                <gg-payment-containers-navigation class="payment-financial__containers-navigation"/>
              }
              <ng-container *ngIf="message else input" [ggDynamicComponent]="message"/>
            </ng-container>
            <ng-template #input>
              <form [formGroup]="form" class="payment-financial__form" [ngClass]="{ 'payment-financial__form--deposit': deposit }">
                @if (!deposit && !isMobile()) {
                  <gg-payment-user-balance/>
                }
                <gg-payment-methods-selector class="payment-financial__methods-selector"/>
                <div
                  class="payment-financial__amount-block"
                  [ngClass]="{ 'payment-financial__amount-block_split': (deposit && !crypto && (hasGroupsOrMethods$ | spAsync)) }"
                >
                  @if (!deposit || (deposit && !crypto)) {
                    <gg-payment-amount-selector [isDeposit]="deposit" [limits]="limits" formControlName="amount"/>
                  }
                  @if (deposit) {
                    <gg-payment-bonus-button formControlName="bonus" [maxMethodPrice]="limits.max" [isCryptoMethod]="crypto" [selectedBonus]="selectedBonus" (buttonClicked)="openSidenav('bonus')"/>
                  }
                </div>
                <ng-container *spLet="activeMethod$ | spAsync as method">
                  @if (method?.view.banner) {
                    <div class="payment-financial__info-banner">
                      <img class="payment-financial__info-banner__img" src="./assets/images/icons/info-icon.svg" alt="info-icon"/>
                      <sp-msg [msg]="method?.view.banner"/>
                    </div>
                  }
                </ng-container>
                <gg-button class="payment-financial__button" [fullWidth]="true" [settings]="submitButton" [disabled]="isButtonDisabled()" (buttonClicked)="openSidenav('flow')">
                  <sp-msg [msg]="'CASHIER.CARD.MAKE_' + (deposit ? 'DEPOSIT' : 'WITHDRAW')"/>
                </gg-button>
              </form>
            </ng-template>
          </ng-template>
        </mat-sidenav-content>
        <mat-sidenav #paymentSidenav class="payment-financial__sidenav" mode="over" position="end" [class.payment-financial__sidenav_mobile]="isMobile()">
          <div class="payment-financial__flow">
            @if (isMobile()) {
              <img src="assets/images/icons/cross.svg" alt="" class="payment-financial__close-flow-mob" (click)="closeSidenav()"/>
            } @else {
              <gg-button-back class="payment-financial__close-flow" [isTextShow]="false" (click)="closeSidenav()"/>
            }
            <ng-container *spLet="action$ |spAsync as action">
              <ng-container *spLet="flow$ | spAsync as flow">
                <ng-container *spLet="isLoaderVisible$ | spAsync as loader">
                  <ng-container *spLet="bonusSidenavOpened$ | spAsync as bonusSidenav">
                    @if (bonusSidenav) {
                      <gg-payment-bonuses-selector [maxMethodPrice]="limits.max" [isCryptoMethod]="crypto" (selected)="selectBonus($event)"/>
                    } @else if (action) {
                      <ng-container [ggDynamicComponent]="action"/>
                    } @else if (loader) {
                      <gg-payment-loader [title]="deposit ? 'CASHIER.LOADER.CHECK_PAYMENT' : 'CASHIER.LOADER.CHECK_PAYMENT_WITHDRAWAL'"/>
                    } @else if (flow) {
                      <ng-container [ggDynamicComponent]="flow"/>
                    }
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </mat-sidenav>
      </mat-sidenav-container>
    </ng-container>
  </ng-container>
</ng-container>

