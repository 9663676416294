import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { BehaviorSubject, timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-clipboard-copy',
  templateUrl: './clipboard-copy.component.html',
  styleUrls: [ './clipboard-copy.component.scss', './flat.clipboard-copy.component.scss', './rounded.clipboard-copy.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ThemeModule, ControlsModule ]
})
export class ClipboardCopyComponent implements OnDestroy {
  private readonly copied = new BehaviorSubject(false);
  readonly copied$ = this.copied.asObservable();
  readonly copyButton: IButton = { size: ButtonSizes.Small, theme: ButtonThemes.Gray };

  @Input() copyValue: string = '';
  @Input() showTextButton: boolean = false;
  @Input() isSmallButton: boolean = false;

  showCopyPlank(): void {
    this.copied.next(true);
    timer(500).pipe(first()).subscribe(() => this.copied.next(false));
  }

  ngOnDestroy(): void { this.copied.complete(); }
}
