<section class="field field__phone" ggCreateThemeClass="field__phone">
  <div
    class="field__input placeholder__wrap"
    [class.no-selector]="!showSelector"
    [ngClass]="{ error: errors && !!errors.length, 'field__phone--dirty': value, 'placeholder__wrap--no-label': noLabelPlaceholder}"
  >
    <ng-container *ngIf="showSelector">
      <gg-country-code-selector (selected$)="selectCountry($event)" [initialValue]="initialValue"></gg-country-code-selector>
      <span class="country-code">{{ prefix }}</span>
    </ng-container>

    <input
      #input
      type="number"
      autocomplete="false"
      [maxlength]="maxlength"
      [placeholder]="defaultPlaceholder"
      [formControl]="control"
    />

    <span
      *ngIf="!noLabelPlaceholder"
      class="placeholder__text"
      [class.no-selector]="!showSelector"
    >{{ placeholder }}</span>
  </div>
  <gg-input-error [errors]="errors"></gg-input-error>
</section>
