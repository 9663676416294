import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { ThemeModule } from '@portal/config';
import { ToolbarNavigationItem } from '@portal/config/shared';

@Component({
  standalone: true,
  selector: 'gg-layout-toolbar-item',
  templateUrl: './item.component.html',
  styleUrls: [ './item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ThemeModule, RouterLinkActive, RouterLink ]
})
export class ToolbarItemComponent {
  @Input() item: Nullable<ToolbarNavigationItem> = null;
}
