import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusCasinoCash } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-max-bet',
  templateUrl: './max-bet.component.html',
  styleUrls: [ './max-bet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxBetComponent {
  readonly currency$ = inject(UserQuery).currency$;

  @Input() bonus: Nullable<Bonus>;

  get maxBet(): Nullable<number> {
    return this.bonus instanceof BonusCasinoCash ? Number(this.bonus.maxbet) : null;
  }
}
