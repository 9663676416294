import { Nullable } from '@libs/utils';
import { ArticleTimingInfo } from '../core/partials';
import { ArticleDto } from '../dto';

export function createTimingInfo(article: ArticleDto): Nullable<ArticleTimingInfo> {
  const start = 'start_date' in article && article.start_date !== 'None'
    ? article.start_date
    : null;
  const end = 'end_date' in article && article.end_date !== 'None'
    ? article.end_date
    : null;

  return start && end ? new ArticleTimingInfo(start, end) : null;
}
