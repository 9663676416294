import { inject, Pipe, PipeTransform } from '@angular/core';
import { GlobalWindow, isUrl } from '@libs/utils';
import { WINDOW } from '@ng-web-apis/common';
import { SP_HTTP_CONFIG, SP_HTTP_SERVER_HOST } from './utils';

@Pipe({ name: 'hosted' })
export class HostedPipe implements PipeTransform {
  private readonly host: string;

  constructor() {
    const project = (inject(WINDOW) as GlobalWindow<{ p_h: string }>).p_h;
    const server = inject(SP_HTTP_SERVER_HOST, { optional: true });
    const config = inject(SP_HTTP_CONFIG, { optional: true })?.host?.url;

    this.host = project || server || config || '';
  }

  transform(value: string): string { return isUrl(value) ? value : `${this.host}${value}`; }
}
