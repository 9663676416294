import { InjectionToken } from '@angular/core';
import { BaseProvider, OrArray } from '@libs/utils';
import { SpAuthorizationCommands } from './abstract.commands';

export type SpAuthorizationConfig = {
  refreshKey: string;
  returnKey?: string;
  checkOnInitialize?: boolean;
  checkBy?: 'authorization' | 'discovery';
  commands: BaseProvider<SpAuthorizationCommands>;
  routes: {
    discovery: string;
    refresh: string;
    signIn: OrArray<string>;
    signOut: string;
  };
}

export const SP_AUTHORIZATION_CONFIG = new InjectionToken<SpAuthorizationConfig>('auth config');
