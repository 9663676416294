export enum PaymegaPaymentMethods {
  Deposit = 76,
  DepositFirst = 78,
  Withdrawal = 77,
  DepositApplePay = 89,
  DepositGPay = 88,
  DepositJoker = 183,
  WithdrawalJoker = 184,
  DepositGPayJoker = 181,
  DepositApplePayJoker = 182,
  P2pDeposit = 302
}
