import { MessageTransformer } from '@libs/cdk';
import { ConnectedEvents } from '@portal/shared/types';

export class PGMessageTransformer implements MessageTransformer<ConnectedEvents> {
  transform(message: MessageEvent): ConnectedEvents | false {
    if (!message.data) { return false; }

    const { type: event, data } = message.data;

    if (event === 'scroll') { return { event: 'page:scroll', payload: { position: [ 0, 0 ] } }; }
    if (event === 'set_url') { return { event: 'page:redirect', payload: { url: data.to } }; }
    if (event === 'gameFrameReady') { return { event: 'frame:ready' }; }
    if (event === 'resize') { return { event: 'frame:resize', payload: data }; }
    if (event === 'redirect') { return { event: 'frame:redirect', payload: data }; }

    return false;
  }
}

export class BtMessageTransformer implements MessageTransformer<ConnectedEvents> {
  transform(msg: MessageEvent): ConnectedEvents | false {
    if (!msg.data?.data) { return false; }

    const { name, message } = msg.data.data;

    return name === 'spt_message' && message.name === 'callback' && message.payload.callbackName === 'onAppLoaded'
      ? { event: 'frame:ready' }
      : false;
  }
}
