import { inject, Injector } from '@angular/core';
import { Nullable } from '@libs/utils';
import { ConfigModules, ConfigQuery } from '@portal/config';
import { AnalyticsHandler, CustomHandler, FbHandler, GaHandler, GtmHandler, HjHandler, YaHandler } from './handlers';

export class AnalyticsHandlerFactory {
  private readonly injector = inject(Injector);
  private readonly trackers: ConfigModules['analytics']['trackers'] = inject(ConfigQuery).modules.analytics.trackers;

  createAnalyticHandlers(): Array<AnalyticsHandler> {
    return Object.entries(this.trackers).map<Nullable<AnalyticsHandler>>(([ tracker, id ]) => {
      if (tracker === 'ga4Id' && id) { return new GaHandler(this.injector); }
      if (tracker === 'gtmId' && id) { return new GtmHandler(this.injector); }
      if (tracker === 'hjId' && id) { return new HjHandler(this.injector); }
      if (tracker === 'yndxMetrikId' && id) { return new YaHandler(this.injector); }
      if (tracker === 'fbPixel' && id) { return new FbHandler(this.injector); }
      if (tracker === 'customPixel' && id) { return new CustomHandler(this.injector); }
      return null;
    }).filter((strategy): strategy is AnalyticsHandler => !!strategy);
  }
}
