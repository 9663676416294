// @ts-nocheck
import { HistoryDto } from '@portal/cashier/data';
import moment from 'moment/moment';
import { Nullable } from '@libs/utils';

export class TransactionsHistory {
  readonly date: string;
  readonly cancellable: boolean;
  readonly declined: boolean;
  readonly readableAmount: Nullable<string>;

  constructor(
    readonly id: string,
    readonly createDate: string,
    readonly operationType: string,
    readonly amount: number,
    readonly currency: string,
    readonly status: string,
    readonly paymentAccount: string,
    readonly paymentMethodName: string,
    readonly type: string,
    readonly reason: string
  ) {
    this.date = moment(Number(this.createDate) * 1000).format('DD.MM.YYYY');
    this.cancellable = this.operationType === 'withdrawal' && this.status === 'new';
    this.declined = this.status === 'declined';
    this.readableAmount = `${this.amount} ${this.currency}`;
  }

  static create(history: HistoryDto): TransactionsHistory {
    const { _id, createDate, operationType, amount, currency, status, paymentAccount, paymentMethodName, type, reason } = history;
    return new TransactionsHistory(_id, createDate, operationType, amount, currency, status, paymentAccount, paymentMethodName, type, reason);
  }
}
