<div class="commission-warning">
  <img src="assets/images/icons/cross.svg" class="commission-warning__close" (click)="close()" alt=""/>

  <div class="commission-warning__title">
    <sp-msg spMsg="CASHIER.CARD.SUM_WITHDRAW"></sp-msg>
  </div>
  <p class="commission-warning__amount">
    {{ amountAfterCommissions | currencyNormalizer }}
  </p>
  <p class="commission-warning__text">
    <sp-msg spMsg="CASHIER.COMMISSION" spaces="end"></sp-msg>
    {{ commission }}%
  </p>

  <div class="commission-warning__rules-block">
    <p class="commission-warning__text commission-warning__text--rules-title">
      <sp-msg spMsg="CASHIER.CARD.FROM_LAST_DEPOSIT" spaces="end"></sp-msg>
      <span class="commission-warning__text commission-warning__text--bold">
        <sp-msg spMsg="CASHIER.CARD.LESS_HOURS" [params]="{from: fee.now.last_deposit_date_in_hours}"></sp-msg>
      </span>,
      <sp-msg spMsg="CASHIER.CARD.COMMISSION_FEE_IS" spaces="wrap"></sp-msg>
      {{ commission }}%
    </p>

    <ng-container *ngFor="let rule of commissionRules">
      <span class="commission-warning__text commission-warning__text--bold commission-warning__text--margin">
        <sp-msg spMsg="CASHIER.CARD.FEE_RULE" [params]="{from: rule.left, rate: rule.rate}"></sp-msg>
      </span>
    </ng-container>

    <span class="commission-warning__text commission-warning__text--bold" spMsg="CASHIER.CARD.CRYPTO.NO_COMMISSION_INFO"></span>
  </div>

  <gg-button class="commission-warning__btn" [settings]="closeButton" (click)="paymentConfirmed()">
    <sp-msg spMsg="CASHIER.CARD.GET_NOW"></sp-msg>
  </gg-button>

  <a class="commission-warning__close-btn" (click)="close()">
    <sp-msg spMsg="CASHIER.CARD.GET_LATER"></sp-msg>
  </a>
</div>
