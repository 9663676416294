<div class="email-confirmation__wrapper">
  <div class="email-confirmation__top-block" *spLet="email$ | async as email">
    <div class="email-confirmation__title">
      <sp-msg msg="CONFIRM_EMAIL.CONFIRM_EMAIL_TITLE" spaces="end"></sp-msg>
      {{email}}
    </div>
    <gg-button class="email-confirmation__go-to-mail" [settings]="actionButtonConfig" (click)="redirectOn(email)">
      <sp-msg msg="CONFIRM_EMAIL.GO_TO_MAIL"></sp-msg>
    </gg-button>
  </div>

  <div class="email-confirmation__not-received">
    <sp-msg msg="CONFIRM_EMAIL.MAIL_NOT_RECEIVED"></sp-msg>

    <div class="email-confirmation__actions">
      <a class="email-confirmation__resend" (click)="resendEmailVerification()">
        <sp-msg msg="CONFIRM_EMAIL.RESEND"></sp-msg>
      </a>
      <div class="email-confirmation__separator"></div>
      <a class="email-confirmation__change-email" (click)="showEmailForm()">
        <sp-msg msg="CONFIRM_EMAIL.CHANGE_EMAIL"></sp-msg>
      </a>
    </div>
  </div>
</div>


