import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FrontConfigInterceptor } from '@portal/core/interceptors/front-config.interceptor';
import { ServerErrorInterceptor } from './errors.interceptor';
import { PaymentCheckInterceptor } from './payment-check.interceptor';

export * from './errors.interceptor';
export * from './payment-check.interceptor';

export const interceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: PaymentCheckInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FrontConfigInterceptor, multi: true }
];
