<ng-container *ngIf="(content$ | async) as content">
  <div class="pd-page__seo" [class.game-play__seo]="isPlayPage" *ngIf="(content.text | byLanguage) || (content.h1 | byLanguage)">
    <ng-container *spLet="isExpanded$ | async as expanded">
      <div class="pd-page__seo-text" [class.expanded]="!expanded">
        <ng-template [ngIf]="content.h1 | byLanguage">
          <h1 class="pd-page__seo-title" [spMsg]="content.h1 | byLanguage"></h1>
        </ng-template>

        <ng-template [ngIf]="content.text | byLanguage">
          <div class="pd-page__seo-content" [spMsg]="content.text | byLanguage"></div>
        </ng-template>

        <div class="pd-page__seo-gradient" *ngIf="!expanded"></div>

        <a class="pd-page__seo-expand" (click)="expand()">
          <sp-msg [msg]="expanded ? 'COMMON.COLLAPSE' : 'COMMON.EXPAND'"></sp-msg>
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>
