import { Component, Input } from '@angular/core';

@Component({
  selector: 'gg-promo-code-button',
  templateUrl: './promo-code-button.component.html',
  styleUrls: [ './promo-code-button.component.scss' ]
})
export class PromoCodeButtonComponent {
  @Input() disabled = false;
  @Input() type: 'button' | 'submit' = 'button';
}
