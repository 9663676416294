import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { BonusAction } from '@portal/bonuses/shared';
import { ButtonThemes, IButton } from '@portal/shared/components/controls';

@Component({
  selector: 'gg-bonus-card-cta',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  readonly actionButton: IButton = { fullWidth: true };
  readonly cancelButton: IButton = { theme: ButtonThemes.Gray, fullWidth: true };

  @Input() bonus: Nullable<Bonus>;
  @Output() action: EventEmitter<BonusAction> = new EventEmitter();

}
