import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpCDKModule } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [ SpCDKModule, RouterModule, ControlsModule ],
  templateUrl: './insufficient-funds-warning.component.html',
  styleUrl: './insufficient-funds-warning.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsufficientFundsWarningComponent {}
