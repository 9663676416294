import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { DocumentUpload } from '@portal/verification/shared';

@Component({
  selector: 'gg-verification-progress-bar',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
  private readonly documentUpload = inject(DocumentUpload);

  readonly documentUploadingProcess$ = this.documentUpload.uploadDocumentProcess$;
}
