<section *ngIf="currentKycVerificationStatus as status" class="status">
  <div class="status__content">
    <div class="status__text">
      <p class="status__title">
        <sp-msg msg="PROFILE.KYCAID.STATUS"/>
      </p>
      <h4 class="status__subtitle">
        <sp-msg [msg]="status.title"/>
      </h4>
    </div>
    <img [src]="status.icon" class="status__icon" alt="status-icon" />
  </div>
  <div
    *ngIf="status.description || status.subTitle"
    class="status__sub-status"
    [ngClass]="{
      'status__sub-status_verified': status.status === 'success',
      'status__sub-status_failure': status.status === 'failure',
      'status__sub-status_pending': status.status === 'pending'
    }"
  >
    <p *ngIf="status.description as description" class="status__description">
      <sp-msg [msg]="description"/>
    </p>
    <p *ngIf="status.subTitle as subTitle" class="status__status-subtitle">
      <sp-msg [msg]="subTitle"/>
    </p>
  </div>
</section>
