import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { createPromoCodeSubmitForm } from '@portal/bonuses/shared';
import { ControlsModule } from '@portal/shared/components';
import { ErrorManager } from '@portal/shared/helpers';
import { first, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'gg-bonuses-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: [ './promo-code.component.scss' ],
  imports: [ SpCDKModule, ControlsModule ],
  providers: [ ErrorManager ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodeComponent implements OnDestroy {
  private readonly bonusData = inject(BonusesData);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly destroyRef$ = inject(DestroyRef);

  readonly form: FormGroup = createPromoCodeSubmitForm();
  readonly errors = inject(ErrorManager).setUp({ form: this.form });

  @Input() methodMaxValue: Nullable<string> = null;
  @Input() noLabelPlaceholder: Nullable<boolean> = true;
  @Output() checkChanges: EventEmitter<void> = new EventEmitter<void>();

  get isValidPromoCode(): boolean {
    const code = this.form.controls['promoCode'];
    if (!code.value.length && code.valid) {
      this.form.reset()
    }
    return code.valid && code.value.length > 1;
  }

  ngOnDestroy(): void { this.form.reset(); }

  checkPromoCode(): void {
    const promoCodeControl = this.form.controls[ 'promoCode' ];

    this.bonusData.activatePromoCode(promoCodeControl.value).pipe(
      first(), takeUntilDestroyed(this.destroyRef$),
      map(() => this.bonusesQuery.getAvailableDepositBonuses(this.methodMaxValue))
    ).subscribe({
      next: (bonuses: Array<Bonus>) => {
        this.bonusData.selectBonus(bonuses[ 0 ]);
        promoCodeControl.setValue('');
        this.checkChanges.emit();
      },
      error: (res) => {
        this.errors.set({ promoCode: [ res.issues[ 0 ] ] });
        promoCodeControl.setErrors({});
      }
    });
  }
}
