import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { Nullable, switchTap } from '@libs/utils';
import { BonusPipesModule } from '@portal/bonuses';
import { CardSelectorComponent, SummaryComponent } from '@portal/payment';
import { GeneratedFormComponent, LoaderComponent } from '@portal/payment/components';
import { CommissionModule } from '@portal/payment/components/commission';
import { PaymentCardsData, PaymentParamsQuery } from '@portal/payment/data';
import { PaymentFlowAbstract } from '@portal/payment/shared';
import { ContentModule, ControlsModule } from '@portal/shared/components';
import { Observable, of, switchMap, tap } from 'rxjs';
import { first } from 'rxjs/operators';

const controlFactory = (paramsQuery: PaymentParamsQuery): Nullable<FormControl> => {
  return paramsQuery.section === 'deposit' ? new FormControl() : undefined;
};

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, ContentModule, CardSelectorComponent, SummaryComponent, BonusPipesModule, GeneratedFormComponent, LoaderComponent, CommissionModule ],
  templateUrl: './bank-card-flow.component.html',
  styleUrls: [ './bank-card-flow.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ { provide: 'control', useFactory: controlFactory, deps: [ PaymentParamsQuery ] } ],
})
export class BankCardFlowComponent extends PaymentFlowAbstract {
  private readonly cardsData = inject(PaymentCardsData);

  constructor(@Inject('control') readonly card: FormControl) { super('additional', card, 'payment'); }

  override submitStep(): Observable<unknown> {
    return this.isDeposit$.pipe(
      first(),
      switchTap((deposit) => deposit ? this.updatePaymentCard() : of(null)),
      switchMap(() => super.submitStep()),
    );
  }

  private updatePaymentCard(): Observable<unknown> {
    return this.cardsData.update(this.card.value).pipe(
      tap((card) => {
        this.card.patchValue({ ...this.card.value, ...card });
        this.builder.updatePayment({ data: { card: this.card.value } });
      }));
  }
}
