import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { ControlsModule } from '@portal/shared/components';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, RouterLink ],
  templateUrl: './verification-phone.component.html',
  styleUrls: [ '../verification-warning/verification-warning.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationPhoneComponent {
  readonly isMobile = inject(SpPlatform).type.mobile;
}
