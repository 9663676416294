import { bannersInitializer } from './banners.initializer';
import { configInitializer } from './config.initializer';
import { gamesAndSectionsInitializer } from './games-and-sections.initializer';
import { recaptchaInitializer } from './recaptcha.initializer';
import { sentryTraceInitializer } from './sentry-trace.initializer';
import { translationInitializer } from './translations.initializer';

export const initializers = [
  configInitializer, sentryTraceInitializer, bannersInitializer, gamesAndSectionsInitializer, translationInitializer, recaptchaInitializer,
];
