import { inject } from '@angular/core';
import { THEME_CONTEXT, ThemeContainerConfig } from '@portal/config/shared';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class ThemeContainer<Context = never> {
  private readonly context: BehaviorSubject<Context>;

  protected abstract config: ThemeContainerConfig;

  protected readonly context$: Observable<Context>;

  protected get currentContext(): Context { return this.context.getValue(); }

  protected constructor(initial: Partial<Context> = undefined as Context) {
    this.context = new BehaviorSubject<Context>(initial as Context);
    this.context$ = this.context.asObservable();
  }

  protected updateContext(next: Partial<Context>): void {
    this.context.next({ ...this.currentContext, ...next });
  }
}

export abstract class Themed<Context = never> {
  protected readonly context = inject<Observable<Context>>(THEME_CONTEXT);
}
