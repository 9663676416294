import { HttpClient } from '@angular/common/http';
import { SpLocalizationConfig } from '@libs/cdk';
import { LOCATION } from '@ng-web-apis/common';
import { CleanAndReplacePipe } from '@portal/shared/pipes';
import { LocalizationLoader } from './localization.loader';

export const localizationConfig: SpLocalizationConfig = {
  useBaseHref: 'not-default',
  loader: { useClass: LocalizationLoader, deps: [ HttpClient, LOCATION ] },
  transform: { useClass: CleanAndReplacePipe }
};
