@if (card) {
  <div class="delete-card" [ngClass]="'delete-card_' + card.type">
    <h5 class="delete-card__title" spMsg="CASHIER.CARD.DELETE_CARD"></h5>
    <span class="delete-card__number">{{ card.account | cardMaskTransform }}</span>
    <div class="delete-card__buttons">
      <gg-button class="delete-card__button" [settings]="cancelButton" (buttonClicked)="close(card.id)">
        <sp-msg spMsg="COMMON.NO"></sp-msg>
      </gg-button>
      <gg-button class="delete-card__button" [settings]="submitButton" (buttonClicked)="deleteBankCard(card.id)">
        <sp-msg spMsg="COMMON.YES"></sp-msg>
      </gg-button>
    </div>
  </div>
}
