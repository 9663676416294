import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-bonus-card-activation',
  templateUrl: './activation.component.html',
  styleUrls: [ './activation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivationComponent {
  readonly currency$ = this.userQuery.currency$;

  @Input() bonus: Nullable<Bonus> = null;

  constructor(private readonly userQuery: UserQuery) {}

  get isShowDeposit(): boolean {
    return this.bonus?.status.current === 'waiting'
           || this.bonus?.status.current === 'expired'
           && this.bonus?.type !== 'casino_cashback'
           && this.bonus?.type !== 'casino_cashback_external';
  }

  get wholeValue(): number { return Math.round(Number(this.bonus?.activation.price)); }
}
