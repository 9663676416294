import { ByLanguage } from '@libs/cdk';
import { GameSourceInfo } from '@portal/games/shared';

type GameSectionVisibility = { sidebar: boolean; topMenu: boolean };

export class GameSection implements GameSourceInfo {
  readonly type = 'section';

  constructor(
    readonly key: string,
    readonly name: ByLanguage<string>,
    readonly id: string,
    readonly display: GameSectionVisibility,
    readonly image: string
  ) {}
}
