import { BonusDto } from '@portal/bonuses/data';
import { BonusGame } from '@portal/bonuses/shared';

export const mapGame = (dto: BonusDto): BonusGame => {
  if (!('game' in dto)) { return null; }

  const { game, rounds } = dto;
  const link = `/slots/${game.slug}`;

  return { link, rounds, slug: game.slug, name: game.name };
};
