import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule } from '@libs/cdk';
import { PWADialogAbstract, PWAService } from '@portal/banners/shared';
import { ConfigQuery } from '@portal/config';
import { ControlsModule } from '@portal/shared/components/controls';

@Component({
  standalone: true,
  selector: 'gg-user-pwa-install-dialog',
  templateUrl: './desktop-pwa-install-dialog.component.html',
  styleUrls: [ './desktop-pwa-install-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCDKModule, ControlsModule ]
})
export class DesktopPWAInstallDialogComponent extends PWADialogAbstract {
  private readonly PWAService = inject(PWAService);
  readonly logo = inject(ConfigQuery).project.logo.mobile;

  openPWAInstallPrompt(): void {
    this.PWAService.install();
    this.close();
  }
}
