<ng-container *spLet="isVisible$ | async as isVisible">
  <section *ngIf="isVisible" class="download-app">
    <div class="download-app__logo">
      <img [src]="logo.mobile" alt=""/>
    </div>
    <div class="download-app__block">
      <div class="download-app__title" spMsg="HEADER.DOWNLOAD"></div>
      <a class="download-app__button" spPrevent="all" (click)="close('processed')">
        <sp-msg msg="HEADER.INSTALL"></sp-msg>
      </a>
    </div>
    <img src="assets/images/icons/cross2.svg" class="download-app__close" alt="" (click)="close('closed')"/>
  </section>
</ng-container>
