import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule, SpStorage } from '@libs/cdk';
import { HostedPipe, SpHttpModule } from '@libs/http';
import { BannersQuery } from '@portal/banners/data';
import { BannerComponent } from '@portal/banners/shared';
import { ConfigQuery, ThemeModule } from '@portal/config';
import { ONE_DAY } from '@portal/shared/constants';
import { StorageKey } from '@portal/shared/types';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [ SpCDKModule, SpHttpModule, ThemeModule ],
  selector: 'gg-banners-download-header',
  templateUrl: './download-app-header-banner.component.html',
  styleUrls: [ './download-app-header-banner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppHeaderBannerComponent extends BannerComponent {
  private readonly showAppBanners$ = inject(BannersQuery).appBannersAvailable$;
  private readonly hosted = inject(HostedPipe);
  private readonly downloadLink = inject(ConfigQuery).modules.apk.appDownloadLink;
  private readonly enabledApk = inject(ConfigQuery).modules.apk.enabled;
  private readonly storage = inject(SpStorage);
  private readonly source = inject(ConfigQuery);

  private readonly isVisible: BehaviorSubject<boolean> = new BehaviorSubject(this.getVisibility());
  readonly isVisible$: Observable<boolean>;
  readonly logo = this.source.project.logo;

  constructor() {
    super();
    this.isVisible$ = combineLatest([ this.showAppBanners$, this.isVisible ]).pipe(map((checkers) => [ ...checkers, this.enabledApk ].every(Boolean)));
  }

  close(info: 'processed' | 'closed'): void {
    const mark = info === 'closed' ? String(Date.now() / 1000) : info;
    this.storage.setItem(StorageKey.TopAppDownloadBanner, mark);
    this.isVisible.next(false);
    info === 'processed' && this.goToUrl(this.hosted.transform(this.downloadLink));
  }

  private getVisibility(): boolean {
    const info = this.storage.getItem(StorageKey.TopAppDownloadBanner);
    if (info === 'processed') { return false; }
    if (!info) { return true; }

    return (Date.now() / 1000) - Number(info) > ONE_DAY;
  }
}
