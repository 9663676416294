import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { noOp } from 'libs/utils/src/lib/helpers/no-op';
import { Nullable } from 'libs/utils/src/lib/types';

class ValueAccessor<T> implements ControlValueAccessor {
  change: (v: T) => void = noOp;
  touch: () => void = noOp;
  modelChange: (v: T) => void = noOp;

  registerOnChange(fn: (v: T) => void): void { this.change = fn; }

  registerOnTouched(fn: () => void): void { this.touch = fn; }

  writeValue(v: T): void { this.modelChange(v); }
}

export class ControlAdapter<T> {
  readonly accessor: ValueAccessor<T> = new ValueAccessor();

  get control(): Nullable<AbstractControl> { return this.ngControl?.control; }

  constructor(private readonly ngControl?: Nullable<NgControl>) {
    if (this.ngControl) { this.ngControl.valueAccessor = this.accessor; }
  }

  change(v: T): void { this.accessor.change(v); }

  touch(): void {this.accessor.touch(); }

  setErrors(errors?: Nullable<ValidationErrors>): void { this.control?.setErrors(errors || null); }

  registerOnModelChange(fn: (v: T) => void): void { this.accessor.modelChange = fn; }
}
