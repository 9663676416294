import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PaymentMethod } from '@portal/cashier/data';

export type Cashier = EntityState<PaymentMethod, number> & { sync: boolean };

export interface CashierState extends Cashier, ActiveState<number> {}

const initialState: CashierState = {
  active: null,
  sync: false,
  threeDSPaymentData: {
    exist: false,
    auth3dsValue: null,
    redirectUrl: null
  },
  error: null,
  errorMessage: null,
  history: null,
  cards: [],
  selectedCard: null,
  newCardId: null,
  cryptoCurrencies: [],
  selectedCryptoCurrencies: null,
  conversionRate: '0.0000',
  loading: false
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cashier', idKey: 'id', resettable: true })
export class CashierStore extends EntityStore<CashierState> {
  constructor() { super(initialState); }
}
