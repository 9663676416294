import { ArticleDto } from '@portal/articles/data';

export class ArticleSystemInfo {
  constructor(
    readonly consecutiveNumber: ArticleDto['consecutive_number'],
    readonly featured: ArticleDto['is_featured'],
    readonly createdAt: ArticleDto['created_at']
  ) {}

  static create(article: ArticleDto): ArticleSystemInfo {
    const { consecutive_number, created_at, is_featured } = article;
    return new ArticleSystemInfo(consecutive_number, is_featured, created_at);
  }
}
