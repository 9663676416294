<section class="active-bonus-verification">
  <div class="active-bonus-verification__wrapper">
    <img src="assets/images/cashier/gift.svg" class="active-bonus-verification__img" alt=""/>
    <div class="active-bonus-verification__information">
      <h3 class="active-bonus-verification__information-title" spMsg="CASHIER.VERIFICATION.ACTIVE_BONUS"></h3>
      <div class="active-bonus-verification__information-description" spMsg="CASHIER.VERIFICATION.CANCEL_BONUS"></div>
    </div>
    <gg-button  class="active-bonus-verification__button" routerLink="/bonuses" (click)="dialog.closeAll()">
      <sp-msg msg="CASHIER.VERIFICATION.GO_TO_BONUS"/>
    </gg-button>
  </div>
</section>
