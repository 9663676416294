import { inject, Injectable } from '@angular/core';
import { SteroidQuery } from '@libs/store';
import { Nullable } from '@libs/utils';
import {
  Bonus,
  BonusesData,
  BonusesState,
  BonusesStore,
  getActivatedBonuses,
  getAvailableBonuses,
  getAvailableDepositBonuses,
  getWelcomePackBonuses
} from '@portal/bonuses/data';
import { getActiveStatusBonuses } from '@portal/bonuses/data/mappers/get-active-status-bonuses';
import { map, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BonusesQuery extends SteroidQuery<BonusesState> {
  private readonly bonuses = this.entity('bonuses');
  private readonly bonusesData = inject(BonusesData);

  readonly synchronized$: Observable<boolean> = this.select('sync');
  readonly hasBonuses$ = this.bonuses.selectCount().pipe(map(Boolean));
  readonly active$: Observable<Nullable<Bonus>> = this.bonuses.selectActive();
  readonly availableBonuses$: Observable<Array<Bonus>> = this.bonuses.selectAll().pipe(map(getAvailableBonuses));
  readonly activatedBonuses$: Observable<Array<Bonus>> = this.bonuses.selectAll().pipe(map(getActivatedBonuses));
  readonly welcomeContainer$: Observable<Nullable<Bonus>> = this.bonuses.selectAll().pipe(map(getWelcomePackBonuses));
  readonly getActiveStatusBonuses$: Observable<Array<Bonus>> = this.bonuses.selectAll().pipe(map(getActiveStatusBonuses));

  readonly depBonusActivated$: Observable<boolean> = this.select('depBonusActivated');

  get hasDepositBonuses(): boolean { return Boolean(getAvailableDepositBonuses(this.bonuses.getAll()).length); }

  get active(): Nullable<Bonus> { return this.bonuses.getActive(); }

  constructor(store: BonusesStore) { super(store); }

  getBonus(bonus: Bonus): Nullable<Bonus> {
    return this.bonuses.getAll().find(b => bonus.id === b.id);
  }

  selectAvailableDepositBonuses(maxPrice?: Nullable<string>): Observable<Array<Bonus>> {
    return this.bonuses.selectAll().pipe((map((bonuses) => getAvailableDepositBonuses(bonuses, maxPrice))));
  }

  selectAvailableCryptoDepositBonuses(isCryptoMethod: Nullable<boolean>): Observable<Nullable<string>> {
    return isCryptoMethod ? this.bonusesData.loadCryptoActiveBonus() : of(null);
  }

  getAvailableDepositBonuses(maxPrice?: Nullable<string>): Array<Bonus> {
    return getAvailableDepositBonuses(this.bonuses.getAll(), maxPrice);
  }
}
