<div class="pwa-desktop">
  <img src="assets/images/icons/cross.svg" class="pwa-desktop__close" (click)="close()" alt=""/>
  <img src="assets/images/pwa/logo.svg" class="pwa-desktop__logo" alt=""/>
  <span class="pwa-desktop__title" spMsg="COMMON.INSTALL_APP"></span>
  <ng-container *ngFor="let line of lines; index as i;">
    <div class="pwa-desktop__line">
      <span class="pwa-desktop__line__num">{{ i + 1 }}.</span>
      <span class="pwa-desktop__line__text" [spMsg]="line.text"></span>
      <img *ngIf="line.icon" class="pwa-desktop__line__img" [src]="'assets/images/pwa/' + line.icon" alt=""/>
    </div>
  </ng-container>
</div>
