import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SpCDKModule } from '@libs/cdk';
import { RecaptchaInputDialogFeatureModule } from '@portal/recaptcha';
import { ControlsModule } from '@portal/shared/components';
import { EmailResentComponent } from '@portal/user/features/email-confirmation/resent/email-resent.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { ConfirmedComponent } from './confirmed/confirmed.component';
import { EmailConfirmationComponent } from './email-confirmation.component';
import { NotConfirmedComponent } from './not-confirmed/not-confirmed.component';

const viewComponents = [ NotConfirmedComponent, ChangeEmailComponent, EmailResentComponent, ConfirmedComponent ];

@NgModule({
  imports: [ SpCDKModule, ReactiveFormsModule, RecaptchaInputDialogFeatureModule, ControlsModule ],
  declarations: [ EmailConfirmationComponent, viewComponents ],
  exports: [ EmailConfirmationComponent ]
})
export class EmailConfirmationPlugFeatureModule {}
