const FIRST_STEP_ORDERS = {
  phone: 0,
  recaptcha_token: 2
};

const SECOND_STEP_ORDERS = {
  code: 0,
  password: 1,
  currency: 2,
  promo_code: 3,
  hasPrivacyPolicy: 4,
  isAdult: 5,
  subscription: 6,
  lang: 7,
  recaptcha_token: 8,
};

export const PHONE_REGISTRATION_ORDERING = [ FIRST_STEP_ORDERS, SECOND_STEP_ORDERS ];
