@if (item && item.url) {
  <a [routerLink]="item.url" [routerLinkActiveOptions]="item.activeOptions | fallback: { exact: false }"
routerLinkActive="active">
    <ng-container [ngTemplateOutlet]="icon"/>
  </a>
} @else if (item && !item.url) {
  <ng-container [ngTemplateOutlet]="icon"/>
}

<ng-template #icon>
  <div class="toolbar-item">
    <img [src]="'{project}/images/toolbar/' + item.icon + '.svg' | path" alt="" class="toolbar-item__icon"/>
    <img [src]="'{project}/images/toolbar/active/' + item.icon + '.svg' | path" alt="" class="toolbar-item__icon-active"/>
    <span [spMsg]="item.label" class="toolbar-item__label"></span>
    <span class="toolbar-item__underline" ggCreateThemeClass="toolbar-item__underline" [style]="{background: item.color}"></span>
  </div>
</ng-template>
